import { React, useContext, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import step1 from "../../../assets/images/step1.svg";
import step2 from "../../../assets/images/step2.svg";
import laststep from "../../../assets/images/laststep.svg";
import borderStar from "../../../assets/images/borderStar.svg";
import fillStar from "../../../assets/images/fillStar.svg";
import flagLine from "../../../assets/images/flagLine.svg";
import flagFill from "../../../assets/images/flagFill.svg";
import { Tooltip } from "react-bootstrap";
import { motion } from "framer-motion";
import MapComponent from "../../../sharedComponent/MapComponent";
import { useTranslation } from "react-i18next";
const ViewOrders = () => {
  const { sidebar, setSidebar, Dark, setDark } = useContext(AppContext);
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 }
  };
  const { t, i18n } = useTranslation();

  return (
    <>
      <motion.div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }}
      >
        <div id="cx-wrapper" className="View_Orders">
          <div
            className="main-master-wrapper form_input_main"
            id="View_Dispatch_main"
          >
            <div className="headingDetails">
              <div className="headingTxt">
                <p className="heading">{t("Order Summary")}</p>
              </div>
            </div>
            {/* ====================== Stepper start ===================== */}
            <div className="vertical-stepper-main-wrapper">
              <div class="">
                {/* <!-- completed --> */}
                <div class="step completed">
                  <div class="v-stepper">
                    <div class="circle">
                      <img src={step1} alt="" />
                    </div>
                    <div class="line"></div>
                  </div>

                  <div class="content">
                  <label className="head">{t("Pick-Up Location")}</label>
                    <p className="Value">
                      Chala, Thiruvananthapuram - 695001, Kerala, India.
                    </p>
                    <span className="DispatchSPan">
                      Ordered On 22-01-2023, 04:04:58 AM
                    </span>                  </div>
                </div>
                <div class="step completed">
                  <div class="v-stepper">
                    <div class="circle">
                      <img src={step2} alt="" />
                    </div>
                    <div class="line"></div>
                  </div>

                  <div class="content">
                  <label className="head">{t("Current Location")}</label>
                    <p className="Value">
                      Chala, Thiruvananthapuram - 695001, Kerala, India.
                    </p>
                    <span className="DispatchSPan">
                      Reached, 25-01-2023
                    </span>                  </div>
                </div>
                <div class="step completed">
                  <div class="v-stepper">
                    <div class="circle">
                      <img src={step2} alt="" />
                    </div>
                    <div class="line"></div>
                  </div>

                  <div class="content">
                  <label className="head">{t("Current Location")}</label>
                    <p className="Value">
                      Chala, Thiruvananthapuram - 695001, Kerala, India.
                    </p>
                    <span className="DispatchSPan">
                      Reached, 25-01-2023
                    </span>                  </div>
                </div><div class="step completed">
                  <div class="v-stepper">
                    <div class="circle">
                      <img src={step2} alt="" />
                    </div>
                    <div class="line"></div>
                  </div>

                  <div class="content">
                  <label className="head">{t("Current Location")}</label>
                    <p className="Value">
                      Chala, Thiruvananthapuram - 695001, Kerala, India.
                    </p>
                    <span className="DispatchSPan">
                      Reached, 25-01-2023
                    </span>
                  </div>
                </div>
                <div class="step completed">
                  <div class="v-stepper">
                    <div class="circle">
                      <img src={step1} alt="" />
                    </div>
                    <div class="line"></div>
                  </div>

                  <div class="content">
                  <label className="head">{t("Drop Location")}</label>
                    <p className="Value">
                      Chala, Thiruvananthapuram - 695001, Kerala, India.
                    </p>
                    <span className="DispatchSPan">
                      Expected Delivery by 25-01-2023
                    </span>
                  </div>
                </div>

                

                

                
              </div>
            </div>
            {/* ====================== Stepper end ===================== */}

            <div className="DetailsSec  trip-details-timeline">
              <div className="stepperScroller">
                <div className="row">
                  <div className="col-md-12 col-lg-12 col-xl-3 form_input_main">
                    <label className="head">{t("Pick-Up Location")}</label>
                    <p className="Value">
                      Chala, Thiruvananthapuram - 695001, Kerala, India.
                    </p>
                    <span className="DispatchSPan">
                      Ordered On 22-01-2023, 04:04:58 AM
                    </span>
                  </div>
                  <div className="col-md-12 col-lg-8  col-xl-6 form_input_main">
                    <div className="td-middle-section">
                      <div class="horizontal timeline">
                        <div class="steps">
                          <div class="step">
                            <img src={step1} alt="" />
                          </div>
                          <div class="step">
                            <img src={step2} alt="" />
                          </div>
                          <div class="step current">
                            <img src={step2} alt="" />
                          </div>
                          <div class="step">
                            <img src={step2} alt="" />
                          </div>
                          <div class="step">
                            <img src={laststep} alt="" />
                          </div>
                        </div>

                        <div class="line"></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-12 col-xl-3 form_input_main">
                    <label className="head">{t("Drop Location")}</label>
                    <p className="Value">
                      Chala, Thiruvananthapuram - 695001, Kerala, India.
                    </p>
                    <span className="DispatchSPan">
                      Expected Delivery by 25-01-2023
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="main-master-wrapper form_input_main"
            id="View_Dispatch_main"
          >
            <div className="headingDetails">
              <div className="headingTxt">
                <p className="heading">{t("Customer Details")}</p>
              </div>
            </div>
            <div className="DetailsSec">
              <div className="row">
                <div className="col-md-3">
                  <label className="head">{t("Customer Name")}</label>
                  <p className="Value">Mr. John Doe</p>
                </div>
                <div className="col-md-3">
                  <label className="head">{t("Customer Mobile")}</label>
                  <p className="Value">+91 99 99 9999 99</p>
                </div>
                <div className="col-md-2">
                  <label className="head">{t("Customer Email")}</label>
                  <p className="Value">john@gmail.com</p>
                </div>
                <div className="col-md-4">
                  <label className="head">
                    {t("Customer Rating")} (*{t("After Delivery of Product")})
                  </label>
                  <div className="d-flex">
                    <img src={fillStar} className="me-1" alt="" />
                    <img src={fillStar} className="me-1" alt="" />
                    <img src={fillStar} className="me-1" alt="" />
                    <img src={borderStar} className="me-1" alt="" />
                    <img src={borderStar} className="me-1" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="main-master-wrapper form_input_main"
            id="View_Dispatch_main"
          >
            <div className="headingDetails">
              <div className="headingTxt">
                <p className="heading">{t("Order Details")}</p>
              </div>
            </div>
            <div className="DetailsSec">
              <div className="row">
                <div className="col-md-3">
                  <label className="head">{t("Order Number")}</label>
                  <p className="Value">521645763</p>
                </div>
                <div className="col-md-3">
                  <label className="head">{t("Order Date & Time")}</label>
                  <p className="Value">22-01-2023, 04:04:58 PM</p>
                </div>
                <div className="col-md-2">
                  <label className="head">{t("Amount")}</label>
                  <p className="Value">$1000</p>
                </div>
                <div className="col-md-4">
                  <label className="head">{t("Payment Type")}</label>
                  <p className="Value">Cash / Online</p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="main-master-wrapper form_input_main"
            id="View_Dispatch_main"
          >
            <div className="headingDetails">
              <div className="headingTxt">
                <p className="heading">
                  {t("Assigned Driver & Vehicle Details")}
                </p>
              </div>
            </div>
            <div className="DetailsSec">
              <div className="row">
                <div className="col-md-3">
                  <label className="head">{t("Driver Name")}</label>
                  <p className="Value">Mr. John Doe</p>
                </div>
                <div className="col-md-3">
                  <label className="head">{t("Driver Contact")}</label>
                  <p className="Value">+91 999 999 9999</p>
                </div>
                <div className="col-md-2">
                  <label className="head">{t("Vehicle Category")}</label>
                  <p className="Value">Pick-Up Truck</p>
                </div>
                <div className="col-md-4">
                  <label className="head">{t("Vehicle Name")}</label>
                  <p className="Value">Maruti PickUp</p>
                </div>
              </div>
            </div>
          </div>
          {/* Order menu Map */}
          <div className="DispatchMenuMap">
            <div className="mainMape">
              <div className="map-main">
                <MapComponent />
                <img src={flagFill} className="imgFill" alt="" />
                <img src={flagLine} alt="" className="imgLine" />
                <div className="flagComments">
                  <div className="me-2">
                    <img src={flagLine} alt="" />
                    <label>{t("Start Point")}</label>
                  </div>
                  <img src={flagFill} alt="" />
                  <label>{t("End Point")}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default ViewOrders;
