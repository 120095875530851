

import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../context/AppContext";

const DropdownLanguage = () => {
  const { SetChangeLang } = useContext(AppContext);

  const { i18n, t } = useTranslation();

  const [language, setLanguage] = useState("en");
  SetChangeLang(t);

   const handleLangChange = (status) => {
    console.log(status);
     const lang = status;
     console.log(lang);
    setLanguage(status);
     i18n.changeLanguage(status);
   };

  return (
    <>
  
          <button  onClick={(e)=>{handleLangChange("en")}}>{t("English")}</button>
       <button onClick={(e)=>{handleLangChange("ar")}}>{t("Arabic")}</button>
    </>
  );
};

export default DropdownLanguage;

