import React from "react";
import { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import Import from "../../assets/images/ic-Import.svg";
import Feature_CarLock from "../../assets/images/Feature_CarLock.svg";
import Feature_CarLock_active from "../../assets/images/Feature_CarLock_active.svg";
import Feature_Fuel_active from "../../assets/images/Feature_Fule_active.svg";
import Feature_Fuel from "../../assets/images/Feature_Fule.svg";
import Feature_temp_active from "../../assets/images/Feature_temp_active.svg";
import Feature_temp from "../../assets/images/Feature_temp1.svg";
import Feature_I_active from "../../assets/images/Feature_i_active.svg";
import Feature_I from "../../assets/images/Feature_I.svg";
import Feature_Echo_active from "../../assets/images/Feature_Echo_active.svg";
import Feature_Echo from "../../assets/images/Feture_echo.svg";
import Feature_Seat_active from "../../assets/images/Feature_Seat_active.svg";
import Feature_Seat from "../../assets/images/Feature_Seat.svg";
import Feature_IVMS_active from "../../assets/images/Feature_IVMS_active.svg";
import Feature_IVMS from "../../assets/images/Feature_IVMS.svg";
import Feature_Card_active from "../../assets/images/Feature_Card_active.svg";
import Feature_Card from "../../assets/images/Feature_Card.svg";
import Feature_Overspeed_active from "../../assets/images/Feature_Overspeed_active.svg";
import Feature_Overspeed from "../../assets/images/Feature_overspeed.svg";
import Feature_Crash_active from "../../assets/images/Feature_Crash_active.svg";
import Feature_Crash from "../../assets/images/Feature_creash.svg";
import Feature_Towing_active from "../../assets/images/Feature_Towing_active.svg";
import Feature_Towing from "../../assets/images/Feature_Towing.svg";
import Feature_Unplag_active from "../../assets/images/Feature_Unplag_active.svg";
import Feature_Unplag from "../../assets/images/Feature_Unplag.svg";
import Feature_Exicess_active from "../../assets/images/Feature_Exicess_active.svg";
import Feature_Exicess from "../../assets/images/Feature_Exicess.svg";
import DDlogo from "../../assets/images/DDlogo.png";
import { useState } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import PreviousButton from "../../sharedComponent/PreviousButton";
import NextButton from "../../sharedComponent/Nextbutton";
import ExistButton from "../../sharedComponent/ExistButton";
import InfoComponent from "../../sharedComponent/SupportHelp/InfoComponent";

const IVMS = ({ onClick }) => {
  const { sidebar, slider, setSlider } = useContext(AppContext);
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };

  const [feature_list, setFeature_list] = useState(false);
  console.log(feature_list);
  const { t, i18n } = useTranslation();

  const [Immobilization, setImmobilization] = useState(true);
  const [commomText, setCommomText] = useState("");
  const [toggleLogo, setToggleLogo] = useState({
    January: false,
    Februvary: false,
    March: false,
    April: false,
    May: false,
    June: false,
    July: false,
    August: false,
    September: false,
    October: false,
    November: false,
    December: false,
  });
  const handleToggleChange = (id) => {
    setToggleLogo((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };
  const [logoActive, setLogoActive] = useState(Feature_CarLock_active);
  const [logoUnActive, setLogoUnActive] = useState(Feature_CarLock);
  console.log(logoActive);
  console.log(logoUnActive);
  const navigate = useNavigate();
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    navigate("/AddVehicle");
  };
  const handleClickPrevious = () => {
    navigate("/Merchant");
  };

  return (
    <motion.div
      className={sidebar ? "taskMain " : "cx-active taskMain"}
      id="cx-main"
      variants={aninations}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.1 }}
    >
      <div id="cx-wrapper" className="Feature_set">
        <div className="haeder haeder_new">
          <div className="Feature_select">
            <label
              htmlFor=""
              onClick={() => {
                setFeature_list(!feature_list);
              }}
            >
              <input
                type="text"
                className="form-control"
                readOnly
                placeholder="Select Hardware Feature Set"
                value={commomText}
              />
              <img
                src={DDlogo}
                alt=""
                id={feature_list === true ? "UP" : "Down"}
              />
            </label>
            {feature_list === true ? (
              <div id="Feature_menu">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                />
                <div className="list">
                  <div
                    className="single_list"
                    onClick={() => {
                      setCommomText("Immobilizer");
                      setFeature_list(false);
                    }}
                  >
                    <img src={Feature_CarLock_active} alt="" />
                    <p>Immobilizer</p>
                  </div>
                  <div
                    className="single_list"
                    onClick={() => {
                      setCommomText("Fuel sensor");
                      setFeature_list(false);
                      setLogoActive(Feature_Fuel_active);
                      setLogoUnActive(Feature_Fuel);
                    }}
                  >
                    <img src={Feature_Fuel_active} alt="" />
                    <p>Fuel sensor</p>
                  </div>
                  <div
                    className="single_list"
                    onClick={() => {
                      setCommomText("I button");
                      setFeature_list(false);
                      setLogoActive(Feature_I_active);
                      setLogoUnActive(Feature_I);
                    }}
                  >
                    <img src={Feature_I_active} alt="" />
                    <p>I button</p>
                  </div>
                  <div
                    className="single_list"
                    onClick={() => {
                      setCommomText("Echo Driving");
                      setFeature_list(false);
                      setLogoActive(Feature_Echo_active);
                      setLogoUnActive(Feature_Echo);
                    }}
                  >
                    <img src={Feature_Echo_active} alt="" />
                    <p>Echo Driving</p>
                  </div>
                  <div
                    className="single_list"
                    onClick={() => {
                      setCommomText("Seat Belt sensor");
                      setFeature_list(false);
                      setLogoActive(Feature_Seat_active);
                      setLogoUnActive(Feature_Seat);
                    }}
                  >
                    <img src={Feature_Seat_active} alt="" />
                    <p>Seat Belt sensor</p>
                  </div>
                  <div
                    className="single_list"
                    onClick={() => {
                      setCommomText("IVMS");
                      setFeature_list(false);
                      setLogoActive(Feature_IVMS_active);
                      setLogoUnActive(Feature_IVMS);
                    }}
                  >
                    <img src={Feature_IVMS_active} alt="" />
                    <p>IVMS</p>
                  </div>
                  <div
                    className="single_list"
                    onClick={() => {
                      setCommomText("Card reader");
                      setFeature_list(false);
                      setLogoActive(Feature_Card_active);
                      setLogoUnActive(Feature_Card);
                    }}
                  >
                    <img src={Feature_Card_active} alt="" />
                    <p>Card reader</p>
                  </div>
                  <div
                    className="single_list"
                    onClick={() => {
                      setCommomText("Over Speeding");
                      setFeature_list(false);
                      setLogoActive(Feature_Overspeed_active);
                      setLogoUnActive(Feature_Overspeed);
                    }}
                  >
                    <img src={Feature_Overspeed_active} alt="" />
                    <p>Over Speeding</p>
                  </div>
                  <div
                    className="single_list"
                    onClick={() => {
                      setCommomText("Crash Detection");
                      setFeature_list(false);
                      setLogoActive(Feature_Crash_active);
                      setLogoUnActive(Feature_Crash);
                    }}
                  >
                    <img src={Feature_Crash_active} alt="" />
                    <p>Crash Detection</p>
                  </div>
                  <div
                    className="single_list"
                    onClick={() => {
                      setCommomText("Excessive Idling");
                      setFeature_list(false);
                      setLogoActive(Feature_Exicess_active);
                      setLogoUnActive(Feature_Exicess);
                    }}
                  >
                    <img src={Feature_Exicess_active} alt="" />
                    <p>Excessive Idling</p>
                  </div>
                  <div
                    className="single_list"
                    onClick={() => {
                      setCommomText("Towing detection");
                      setFeature_list(false);
                      setLogoActive(Feature_Towing_active);
                      setLogoUnActive(Feature_Towing);
                    }}
                  >
                    <img src={Feature_Towing_active} alt="" />
                    <p>Towing detection</p>
                  </div>
                  <div
                    className="single_list"
                    onClick={() => {
                      setCommomText("Temperature Sensors");
                      setFeature_list(false);
                      setLogoActive(Feature_temp_active);
                      setLogoUnActive(Feature_temp);
                    }}
                  >
                    <img src={Feature_Towing_active} alt="" />
                    <p>Temperature Sensors</p>
                  </div>
                  <div
                    className="single_list"
                    onClick={() => {
                      setCommomText("un plug detection");
                      setFeature_list(false);
                      setLogoActive(Feature_Unplag_active);
                      setLogoUnActive(Feature_Unplag);
                    }}
                  >
                    <img src={Feature_Unplag_active} alt="" />
                    <p>un plug detection</p>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "flex-end",
              flex: 1,
            }}
          >
            <Link to="/AddVehicle" className="d-flex justify-content-end">
              <button className="cx-btn-3 tbBtn">+ {t("Add Vehicle")}</button>
            </Link>
            <div style={{ marginRight: "10px" }} />
            <div className="right-export-btn-section-wrapper">
              <div className="c-pointer me-2"></div>

              <div className="md_dropdown">
                <Dropdown>
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    className="export-toggle"
                  >
                    <p className="Export_Text">{t("Export")}</p>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <Link
                        // onClick={() => getExportChat()}
                        className="d-block"
                      >
                        {t("PDF")}
                      </Link>
                    </Dropdown.Item>

                    <Dropdown.Item>
                      <Link
                        // onClick={() => downLoadExcelSheet()}
                        className="d-block"
                      >
                        {t("Excel")}
                      </Link>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
        <div className="Vehcle-main-tabs" id="cutomScroll">
          <div className="main-master-wrapper mb-0 inner-tabs-section tabs-custom-width-33">
            <div id="scroll_insideThe_Padding53">
              <div className="all-vehicle-main">
                <div className="all-vehical-head row vehicle-top-inputs">
                  <div className="input-section-wrapper">
                    <div className="row">
                      <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Trip Name, Driver Name..."
                        />
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Email"
                        />
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Contact Number"
                        />
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Start Time & End Time"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="right-export-btn-section-wrapper">
                    <div className="c-pointer me-2"></div>
                    {/* <div className="c-pointer">
                      <img src={Import} alt="" />
                    </div> */}
                  </div>
                </div>
                <div className="yauto" id="TransportMananger_height">
                  <div className="row gx-3 main-cards-wrapper">
                    <div
                      className={
                        sidebar
                          ? "col-xl-4 col-lg-6 col-md-6"
                          : "col-xl-3 col-lg-4 col-md-6"
                      }
                      id="fetureset_single_card"
                    >
                      <div className={"common-vehical-card-inner"}>
                        <div className="vehical-card-head">
                          <div className="heading">
                            {toggleLogo["January"] ? (
                              <img src={logoActive} alt="" />
                            ) : (
                              <img src={logoUnActive} alt="" />
                            )}
                            <div className="">
                              <p className="sub-heading">
                                {t("Vehicle Number")}
                              </p>
                              <p className="title">Volvo Transport</p>
                            </div>
                          </div>
                          <div
                            className="form-check form-switch"
                            id="custom_switch"
                          >
                            <div className="d-flex innerFlexTog">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="Tuesday"
                                checked={toggleLogo["January"]}
                                onChange={() => handleToggleChange("January")}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="vehical-card-body row">
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">{t("Vehicle Number")}</p>
                            <p className="title">MH-12-5022</p>
                          </div>
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">{t("Driver Name")}</p>
                            <p className="title">Mr. John Doe</p>
                          </div>
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">{t("IMEI No.")}</p>
                            <p className="title">1234567894561230</p>
                          </div>
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">{t("Vehicle Type")}</p>
                            <p className="title">Pick-Up Truck</p>
                          </div>
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">
                              {t("Transportation Type")}
                            </p>
                            <p className="title">Goods</p>
                          </div>
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">{t("Contact Driver")}</p>
                            <p className="title">9922405367</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        sidebar
                          ? "col-xl-4 col-lg-6 col-md-6"
                          : "col-xl-3 col-lg-4 col-md-6"
                      }
                      id="fetureset_single_card"
                    >
                      <div className={"common-vehical-card-inner"}>
                        <div className="vehical-card-head">
                          <div className="heading">
                            {toggleLogo["Februvary"] ? (
                              <img src={logoActive} alt="" />
                            ) : (
                              <img src={logoUnActive} alt="" />
                            )}
                            <div className="">
                              <p className="sub-heading">
                                {t("Vehicle Number")}
                              </p>
                              <p className="title">Volvo Transport</p>
                            </div>
                          </div>
                          <div
                            className="form-check form-switch"
                            id="custom_switch"
                          >
                            <div className="d-flex innerFlexTog">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="Tuesday"
                                checked={toggleLogo["Februvary"]}
                                onChange={() => handleToggleChange("Februvary")}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="vehical-card-body row">
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">{t("Vehicle Number")}</p>
                            <p className="title">MH-12-5022</p>
                          </div>
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">{t("Driver Name")}</p>
                            <p className="title">Mr. John Doe</p>
                          </div>
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">{t("IMEI No.")}</p>
                            <p className="title">1234567894561230</p>
                          </div>
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">{t("Vehicle Type")}</p>
                            <p className="title">Pick-Up Truck</p>
                          </div>
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">
                              {t("Transportation Type")}
                            </p>
                            <p className="title">Goods</p>
                          </div>
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">{t("Contact Driver")}</p>
                            <p className="title">9922405367</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        sidebar
                          ? "col-xl-4 col-lg-6 col-md-6"
                          : "col-xl-3 col-lg-4 col-md-6"
                      }
                      id="fetureset_single_card"
                    >
                      <div className={"common-vehical-card-inner"}>
                        <div className="vehical-card-head">
                          <div className="heading">
                            {toggleLogo["March"] ? (
                              <img src={logoActive} alt="" />
                            ) : (
                              <img src={logoUnActive} alt="" />
                            )}
                            <div className="">
                              <p className="sub-heading">
                                {t("Vehicle Number")}
                              </p>
                              <p className="title">Volvo Transport</p>
                            </div>
                          </div>
                          <div
                            className="form-check form-switch"
                            id="custom_switch"
                          >
                            <div className="d-flex innerFlexTog">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="Tuesday"
                                checked={toggleLogo["March"]}
                                onChange={() => handleToggleChange("March")}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="vehical-card-body row">
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">{t("Vehicle Number")}</p>
                            <p className="title">MH-12-5022</p>
                          </div>
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">{t("Driver Name")}</p>
                            <p className="title">Mr. John Doe</p>
                          </div>
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">{t("IMEI No.")}</p>
                            <p className="title">1234567894561230</p>
                          </div>
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">{t("Vehicle Type")}</p>
                            <p className="title">Pick-Up Truck</p>
                          </div>
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">
                              {t("Transportation Type")}
                            </p>
                            <p className="title">Goods</p>
                          </div>
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">{t("Contact Driver")}</p>
                            <p className="title">9922405367</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        sidebar
                          ? "col-xl-4 col-lg-6 col-md-6"
                          : "col-xl-3 col-lg-4 col-md-6"
                      }
                      id="fetureset_single_card"
                    >
                      <div className={"common-vehical-card-inner"}>
                        <div className="vehical-card-head">
                          <div className="heading">
                            {toggleLogo["April"] ? (
                              <img src={logoActive} alt="" />
                            ) : (
                              <img src={logoUnActive} alt="" />
                            )}
                            <div className="">
                              <p className="sub-heading">
                                {t("Vehicle Number")}
                              </p>
                              <p className="title">Volvo Transport</p>
                            </div>
                          </div>
                          <div
                            className="form-check form-switch"
                            id="custom_switch"
                          >
                            <div className="d-flex innerFlexTog">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="Tuesday"
                                checked={toggleLogo["April"]}
                                onChange={() => handleToggleChange("April")}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="vehical-card-body row">
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">{t("Vehicle Number")}</p>
                            <p className="title">MH-12-5022</p>
                          </div>
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">{t("Driver Name")}</p>
                            <p className="title">Mr. John Doe</p>
                          </div>
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">{t("IMEI No.")}</p>
                            <p className="title">1234567894561230</p>
                          </div>
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">{t("Vehicle Type")}</p>
                            <p className="title">Pick-Up Truck</p>
                          </div>
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">
                              {t("Transportation Type")}
                            </p>
                            <p className="title">Goods</p>
                          </div>
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">{t("Contact Driver")}</p>
                            <p className="title">9922405367</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        sidebar
                          ? "col-xl-4 col-lg-6 col-md-6"
                          : "col-xl-3 col-lg-4 col-md-6"
                      }
                      id="fetureset_single_card"
                    >
                      <div className={"common-vehical-card-inner"}>
                        <div className="vehical-card-head">
                          <div className="heading">
                            {toggleLogo["May"] ? (
                              <img src={logoActive} alt="" />
                            ) : (
                              <img src={logoUnActive} alt="" />
                            )}
                            <div className="">
                              <p className="sub-heading">
                                {t("Vehicle Number")}
                              </p>
                              <p className="title">Volvo Transport</p>
                            </div>
                          </div>
                          <div
                            className="form-check form-switch"
                            id="custom_switch"
                          >
                            <div className="d-flex innerFlexTog">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="Tuesday"
                                checked={toggleLogo["May"]}
                                onChange={() => handleToggleChange("May")}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="vehical-card-body row">
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">{t("Vehicle Number")}</p>
                            <p className="title">MH-12-5022</p>
                          </div>
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">{t("Driver Name")}</p>
                            <p className="title">Mr. John Doe</p>
                          </div>
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">{t("IMEI No.")}</p>
                            <p className="title">1234567894561230</p>
                          </div>
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">{t("Vehicle Type")}</p>
                            <p className="title">Pick-Up Truck</p>
                          </div>
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">
                              {t("Transportation Type")}
                            </p>
                            <p className="title">Goods</p>
                          </div>
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">{t("Contact Driver")}</p>
                            <p className="title">9922405367</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        sidebar
                          ? "col-xl-4 col-lg-6 col-md-6"
                          : "col-xl-3 col-lg-4 col-md-6"
                      }
                      id="fetureset_single_card"
                    >
                      <div className={"common-vehical-card-inner"}>
                        <div className="vehical-card-head">
                          <div className="heading">
                            {toggleLogo["June"] ? (
                              <img src={logoActive} alt="" />
                            ) : (
                              <img src={logoUnActive} alt="" />
                            )}
                            <div className="">
                              <p className="sub-heading">
                                {t("Vehicle Number")}
                              </p>
                              <p className="title">Volvo Transport</p>
                            </div>
                          </div>
                          <div
                            className="form-check form-switch"
                            id="custom_switch"
                          >
                            <div className="d-flex innerFlexTog">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="Tuesday"
                                checked={toggleLogo["June"]}
                                onChange={() => handleToggleChange("June")}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="vehical-card-body row">
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">{t("Vehicle Number")}</p>
                            <p className="title">MH-12-5022</p>
                          </div>
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">{t("Driver Name")}</p>
                            <p className="title">Mr. John Doe</p>
                          </div>
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">{t("IMEI No.")}</p>
                            <p className="title">1234567894561230</p>
                          </div>
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">{t("Vehicle Type")}</p>
                            <p className="title">Pick-Up Truck</p>
                          </div>
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">
                              {t("Transportation Type")}
                            </p>
                            <p className="title">Goods</p>
                          </div>
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">{t("Contact Driver")}</p>
                            <p className="title">9922405367</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        sidebar
                          ? "col-xl-4 col-lg-6 col-md-6"
                          : "col-xl-3 col-lg-4 col-md-6"
                      }
                      id="fetureset_single_card"
                    >
                      <div className={"common-vehical-card-inner"}>
                        <div className="vehical-card-head">
                          <div className="heading">
                            {toggleLogo["July"] ? (
                              <img src={logoActive} alt="" />
                            ) : (
                              <img src={logoUnActive} alt="" />
                            )}
                            <div className="">
                              <p className="sub-heading">
                                {t("Vehicle Number")}
                              </p>
                              <p className="title">Volvo Transport</p>
                            </div>
                          </div>
                          <div
                            className="form-check form-switch"
                            id="custom_switch"
                          >
                            <div className="d-flex innerFlexTog">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="Tuesday"
                                checked={toggleLogo["July"]}
                                onChange={() => handleToggleChange("July")}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="vehical-card-body row">
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">{t("Vehicle Number")}</p>
                            <p className="title">MH-12-5022</p>
                          </div>
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">{t("Driver Name")}</p>
                            <p className="title">Mr. John Doe</p>
                          </div>
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">{t("IMEI No.")}</p>
                            <p className="title">1234567894561230</p>
                          </div>
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">{t("Vehicle Type")}</p>
                            <p className="title">Pick-Up Truck</p>
                          </div>
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">
                              {t("Transportation Type")}
                            </p>
                            <p className="title">Goods</p>
                          </div>
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">{t("Contact Driver")}</p>
                            <p className="title">9922405367</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        sidebar
                          ? "col-xl-4 col-lg-6 col-md-6"
                          : "col-xl-3 col-lg-4 col-md-6"
                      }
                      id="fetureset_single_card"
                    >
                      <div className={"common-vehical-card-inner"}>
                        <div className="vehical-card-head">
                          <div className="heading">
                            {toggleLogo["August"] ? (
                              <img src={logoActive} alt="" />
                            ) : (
                              <img src={logoUnActive} alt="" />
                            )}
                            <div className="">
                              <p className="sub-heading">
                                {t("Vehicle Number")}
                              </p>
                              <p className="title">Volvo Transport</p>
                            </div>
                          </div>
                          <div
                            className="form-check form-switch"
                            id="custom_switch"
                          >
                            <div className="d-flex innerFlexTog">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="Tuesday"
                                checked={toggleLogo["August"]}
                                onChange={() => handleToggleChange("August")}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="vehical-card-body row">
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">{t("Vehicle Number")}</p>
                            <p className="title">MH-12-5022</p>
                          </div>
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">{t("Driver Name")}</p>
                            <p className="title">Mr. John Doe</p>
                          </div>
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">{t("IMEI No.")}</p>
                            <p className="title">1234567894561230</p>
                          </div>
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">{t("Vehicle Type")}</p>
                            <p className="title">Pick-Up Truck</p>
                          </div>
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">
                              {t("Transportation Type")}
                            </p>
                            <p className="title">Goods</p>
                          </div>
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">{t("Contact Driver")}</p>
                            <p className="title">9922405367</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        sidebar
                          ? "col-xl-4 col-lg-6 col-md-6"
                          : "col-xl-3 col-lg-4 col-md-6"
                      }
                      id="fetureset_single_card"
                    >
                      <div className={"common-vehical-card-inner"}>
                        <div className="vehical-card-head">
                          <div className="heading">
                            {toggleLogo["September"] ? (
                              <img src={logoActive} alt="" />
                            ) : (
                              <img src={logoUnActive} alt="" />
                            )}
                            <div className="">
                              <p className="sub-heading">
                                {t("Vehicle Number")}
                              </p>
                              <p className="title">Volvo Transport</p>
                            </div>
                          </div>
                          <div
                            className="form-check form-switch"
                            id="custom_switch"
                          >
                            <div className="d-flex innerFlexTog">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="Tuesday"
                                checked={toggleLogo["September"]}
                                onChange={() => handleToggleChange("September")}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="vehical-card-body row">
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">{t("Vehicle Number")}</p>
                            <p className="title">MH-12-5022</p>
                          </div>
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">{t("Driver Name")}</p>
                            <p className="title">Mr. John Doe</p>
                          </div>
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">{t("IMEI No.")}</p>
                            <p className="title">1234567894561230</p>
                          </div>
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">{t("Vehicle Type")}</p>
                            <p className="title">Pick-Up Truck</p>
                          </div>
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">
                              {t("Transportation Type")}
                            </p>
                            <p className="title">Goods</p>
                          </div>
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">{t("Contact Driver")}</p>
                            <p className="title">9922405367</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        sidebar
                          ? "col-xl-4 col-lg-6 col-md-6"
                          : "col-xl-3 col-lg-4 col-md-6"
                      }
                      id="fetureset_single_card"
                    >
                      <div className={"common-vehical-card-inner"}>
                        <div className="vehical-card-head">
                          <div className="heading">
                            {toggleLogo["October"] ? (
                              <img src={logoActive} alt="" />
                            ) : (
                              <img src={logoUnActive} alt="" />
                            )}
                            <div className="">
                              <p className="sub-heading">
                                {t("Vehicle Number")}
                              </p>
                              <p className="title">Volvo Transport</p>
                            </div>
                          </div>
                          <div
                            className="form-check form-switch"
                            id="custom_switch"
                          >
                            <div className="d-flex innerFlexTog">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="Tuesday"
                                checked={toggleLogo["October"]}
                                onChange={() => handleToggleChange("October")}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="vehical-card-body row">
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">{t("Vehicle Number")}</p>
                            <p className="title">MH-12-5022</p>
                          </div>
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">{t("Driver Name")}</p>
                            <p className="title">Mr. John Doe</p>
                          </div>
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">{t("IMEI No.")}</p>
                            <p className="title">1234567894561230</p>
                          </div>
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">{t("Vehicle Type")}</p>
                            <p className="title">Pick-Up Truck</p>
                          </div>
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">
                              {t("Transportation Type")}
                            </p>
                            <p className="title">Goods</p>
                          </div>
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">{t("Contact Driver")}</p>
                            <p className="title">9922405367</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        sidebar
                          ? "col-xl-4 col-lg-6 col-md-6"
                          : "col-xl-3 col-lg-4 col-md-6"
                      }
                      id="fetureset_single_card"
                    >
                      <div className={"common-vehical-card-inner"}>
                        <div className="vehical-card-head">
                          <div className="heading">
                            {toggleLogo["November"] ? (
                              <img src={logoActive} alt="" />
                            ) : (
                              <img src={logoUnActive} alt="" />
                            )}
                            <div className="">
                              <p className="sub-heading">
                                {t("Vehicle Number")}
                              </p>
                              <p className="title">Volvo Transport</p>
                            </div>
                          </div>
                          <div
                            className="form-check form-switch"
                            id="custom_switch"
                          >
                            <div className="d-flex innerFlexTog">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="Tuesday"
                                checked={toggleLogo["November"]}
                                onChange={() => handleToggleChange("November")}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="vehical-card-body row">
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">{t("Vehicle Number")}</p>
                            <p className="title">MH-12-5022</p>
                          </div>
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">{t("Driver Name")}</p>
                            <p className="title">Mr. John Doe</p>
                          </div>
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">{t("IMEI No.")}</p>
                            <p className="title">1234567894561230</p>
                          </div>
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">{t("Vehicle Type")}</p>
                            <p className="title">Pick-Up Truck</p>
                          </div>
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">
                              {t("Transportation Type")}
                            </p>
                            <p className="title">Goods</p>
                          </div>
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">{t("Contact Driver")}</p>
                            <p className="title">9922405367</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        sidebar
                          ? "col-xl-4 col-lg-6 col-md-6"
                          : "col-xl-3 col-lg-4 col-md-6"
                      }
                      id="fetureset_single_card"
                    >
                      <div className={"common-vehical-card-inner"}>
                        <div className="vehical-card-head">
                          <div className="heading">
                            {toggleLogo["December"] ? (
                              <img src={logoActive} alt="" />
                            ) : (
                              <img src={logoUnActive} alt="" />
                            )}
                            <div className="">
                              <p className="sub-heading">
                                {t("Vehicle Number")}
                              </p>
                              <p className="title">Volvo Transport</p>
                            </div>
                          </div>
                          <div
                            className="form-check form-switch"
                            id="custom_switch"
                          >
                            <div className="d-flex innerFlexTog">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="Tuesday"
                                checked={toggleLogo["December"]}
                                onChange={() => handleToggleChange("December")}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="vehical-card-body row">
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">{t("Vehicle Number")}</p>
                            <p className="title">MH-12-5022</p>
                          </div>
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">{t("Driver Name")}</p>
                            <p className="title">Mr. John Doe</p>
                          </div>
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">{t("IMEI No.")}</p>
                            <p className="title">1234567894561230</p>
                          </div>
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">{t("Vehicle Type")}</p>
                            <p className="title">Pick-Up Truck</p>
                          </div>
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">
                              {t("Transportation Type")}
                            </p>
                            <p className="title">Goods</p>
                          </div>
                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                            <p className="sub-heading">{t("Contact Driver")}</p>
                            <p className="title">9922405367</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p className="reg-color mt-3">{t("Showing")} 1 - 10 of 200</p>
          </div>
        </div>
        {slider ? <ExistButton onClose={() => setSlider(false)} /> : null}
        {!slider && <NextButton onClick={handleClick} />}
        {!slider && <InfoComponent />}
      </div>
    </motion.div>
  );
};

export default IVMS;
