import React, { useContext, useState } from "react";

import logo from "../../assets/images/Web-Application-Logo.svg";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
const Login = () => {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  const aninations = {
    initial: { opacity: 0, x: -400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  return (
    <motion.div
      className="main-auth"
      variants={aninations}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.5 }}
    >
      <div className="login-wrapper">
        <div className="row height-style">
          <div className="col-md-6 left">
            <div className="bg-img">
              <h3>
                A New Way <br /> To Track Your <br /> Vehicle
              </h3>
            </div>
          </div>

          <div className="col-md-6 right cx-relative">
            <div className="wrapper row">
              <div>
                <div className="top-logo">
                  <img src={logo} alt="" />
                </div>
                <h3>Welcome Back !</h3>
                <div className="auth-form">
                  <Form
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                  >
                    <div className="mb-4">
                      <Form.Label className="common-labels">
                        Email ID / Mobile Number
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Email ID / Mobile Number"
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Email ID / Mobile Number.
                      </Form.Control.Feedback>
                    </div>
                    <div className="mb-2">
                      <Form.Label className="common-labels">
                        Password
                      </Form.Label>
                      <Form.Control
                        required
                        type="password"
                        placeholder="Enter your password "
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter password.
                      </Form.Control.Feedback>
                    </div>
                    <div className="forgot-link">
                      <Link to="/ForgetPassword">Forgot Password?</Link>
                    </div>
                    <div className="btn-auth">
                      <Link to="/Dashboard">
                        <button type="submit" className="filled-btn">
                          Log In
                        </button>
                      </Link>
                      <div className="or-section-main">
                        <div className="left"></div>
                        <div className="or-text">
                          <span>OR</span>
                        </div>
                        <div className="right"></div>
                      </div>
                      <Link to="/LoginWithOTP">
                        <button type="submit" className="filled-btn">
                          Log In with OTP
                        </button>
                      </Link>
                      <Link to="/Registration">
                        <button className="unfilled-btn">
                          Register As Customer
                        </button>
                      </Link>
                      <div className="link-style">
                        <Link to="/DemoAccount">
                          Try Our Free Demo (No Credit Card Details Required )
                        </Link>
                      </div>
                      <div className="link-style">
                        <Link to="#">2023 @ Vehicle Tracking</Link>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Login;
