import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import ic_info from "../../assets/images/ic_info.svg";
import { useLocation } from "react-router-dom";
import { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { useTranslation } from "react-i18next";
import redCar from "../../assets/images/red-car-map.svg";
import Accordion from "react-bootstrap/Accordion";
import VehicleG from "../../assets/images/vehicle-green.svg";
import VehicleR from "../../assets/images/vehicle-red.svg";
import route from "../../assets/images/route.svg";
import tool from "../../assets/images/tool.svg";
import customer from "../../assets/images/customer.svg";
import running from "../../assets/images/running.svg";
import parked from "../../assets/images/parked.svg";
import untracked from "../../assets/images/untracked.svg";
import idle from "../../assets/images/idle.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const InfoComponent = () => {
  const handleClose = () => setInfoOpen(false);
  const handleShow = () => setInfoOpen(true);
  const { t, i18n } = useTranslation();
  const { sidebar, infoOpen, setInfoOpen } = useContext(AppContext);
  const currentRoute = useLocation().pathname;
  console.log(currentRoute);

  const infoArray = [
    {
      route: "/Dashboard",

      parent: [
        {
          title: "Dashboard information",

          description: "",
        },
        {
          title: "Dashboard information 252898259",
          description: "Dashboard Dashboard 75757 Dashboard.",
          img: redCar,
        },
      ],
    },

    {
      route: "/CustomerProfile",

      parent: [
        {
          title: "profile customer",
          description: "CustomerProfile CustomerProfile CustomerProfile.",
        },
      ],
    },
  ];
  const url = window.location.href;
  const parts = url.split("/");
  const token1 = parts[parts.length - 1];
  const handleYesClick = () => {
    toast.success("Thanks for your feedback!");
  };
  return (
    <div className="wrapper-info-main">
      <button className="info-btns" onClick={() => setInfoOpen(!infoOpen)}>
        <img src={ic_info} alt="" className="h-40 w-40" />
      </button>

      <div>
        <Offcanvas
          show={infoOpen}
          onHide={handleClose}
          placement="end"
          className="main-info-wrapper"
        >
          <button className="info-btns" onClick={() => setInfoOpen(!infoOpen)}>
            <img src={ic_info} alt="" />
          </button>

          <Offcanvas.Body>
            <div className="body-wrapper">
              {currentRoute === "/Dashboard" && (
                <div className="" id="help-main-wrapper">
                  <p className="help-paragraph mb-2">
                    Welcome to the Dashboard help module! Here, we'll guide you
                    through the various features and functions available on the
                    dashboard of our software.
                  </p>
                  <p className="help-head-sub">Location Overview:</p>
                  <p className="help-paragraph">
                    On the dashboard, you'll find five different kinds of icons
                    representing vehicles:
                  </p>
                  <ul className="help-ol-list mt-2">
                    <li>
                      <img src={customer} alt="" /> Customer{" "}
                    </li>
                    <li>
                      <img src={running} alt="" /> Running{" "}
                    </li>
                    <li>
                      <img src={parked} alt="" /> Parked{" "}
                    </li>
                    <li>
                      <img src={untracked} alt="" /> Untracked{" "}
                    </li>
                    <li>
                      <img src={idle} alt="" /> Idle
                    </li>
                  </ul>
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        Viewing and Searching Vehicles on the Live Map
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          You can view and search for vehicles based on their
                          colors and groups on the live map. The map allows you
                          to zoom in, zoom out, and even go full screen. Simply
                          click on the desired vehicle on the map to view its
                          status, temperature, last signal received, and last
                          seen location. Additionally, you can access more
                          details by clicking on dots representing the vehicle.
                          These details include options such as track, playback,
                          geofence, alerts, and sharing.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Groups</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          To manage and organize your vehicles, you'll find a
                          groups dropdown menu. Here, you can select specific
                          vehicle groups to filter the displayed data.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>Vehicle</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          The vehicle dropdown menu allows you to select
                          individual vehicles from the available list.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>Status</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          Using the status dropdown, you can filter vehicles
                          based on their status, such as running, idle, or
                          parked.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header>
                        <img src={VehicleG} alt="" className="ms-4" />
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          Clicking on a green vehicle icon will show you
                          information about running vehicles, idle vehicles, and
                          parked vehicles.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                      <Accordion.Header>
                        <img src={VehicleR} alt="" className="ms-4" />
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          Clicking on a red vehicle icon will display
                          information about currently untracked and not yet
                          tracked vehicles.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                      <Accordion.Header>
                        <img src={route} alt="" className="ms-4" />
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          By clicking on a yellow vehicle icon, you can access
                          information about scheduled trips and dispatched
                          trips. Clicking on a specific trip provides options to
                          share the trip, track its location, show it on the
                          map, view device and vehicle details, access trip
                          details, and edit trips.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="7">
                      <Accordion.Header>
                        <img src={tool} alt="" className="ms-4" />
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          Clicking on the tools icon will open a menu where you
                          can find various useful tools, including the ability
                          to find a specific vehicle, set boundaries, and check
                          traffic information. We hope this guide helps you
                          navigate and utilize the different features available
                          on the dashboard of our software. If you have any
                          further questions or need additional assistance, feel
                          free to reach out to our support team.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              )}
              {currentRoute === `/Dashboard/${token1}` && (
                <div className="" id="help-main-wrapper">
                  <p className="help-paragraph mb-2">
                    Welcome to the Dashboard help module! Here, we'll guide you
                    through the various features and functions available on the
                    dashboard of our software.
                  </p>
                  <p className="help-head-sub">Location Overview:</p>
                  <p className="help-paragraph">
                    On the dashboard, you'll find five different kinds of icons
                    representing vehicles:
                  </p>
                  <ul className="help-ol-list mt-2">
                    <li>
                      <img src={customer} alt="" /> Customer{" "}
                    </li>
                    <li>
                      <img src={running} alt="" /> Running{" "}
                    </li>
                    <li>
                      <img src={parked} alt="" /> Parked{" "}
                    </li>
                    <li>
                      <img src={untracked} alt="" /> Untracked{" "}
                    </li>
                    <li>
                      <img src={idle} alt="" /> Idle
                    </li>
                  </ul>
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        Viewing and Searching Vehicles on the Live Map
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          You can view and search for vehicles based on their
                          colors and groups on the live map. The map allows you
                          to zoom in, zoom out, and even go full screen. Simply
                          click on the desired vehicle on the map to view its
                          status, temperature, last signal received, and last
                          seen location. Additionally, you can access more
                          details by clicking on dots representing the vehicle.
                          These details include options such as track, playback,
                          geofence, alerts, and sharing.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Groups</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          To manage and organize your vehicles, you'll find a
                          groups dropdown menu. Here, you can select specific
                          vehicle groups to filter the displayed data.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>Vehicle</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          The vehicle dropdown menu allows you to select
                          individual vehicles from the available list.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>Status</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          Using the status dropdown, you can filter vehicles
                          based on their status, such as running, idle, or
                          parked.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header>
                        <img src={VehicleG} alt="" className="ms-4" />
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          Clicking on a green vehicle icon will show you
                          information about running vehicles, idle vehicles, and
                          parked vehicles.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                      <Accordion.Header>
                        <img src={VehicleR} alt="" className="ms-4" />
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          Clicking on a red vehicle icon will display
                          information about currently untracked and not yet
                          tracked vehicles.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                      <Accordion.Header>
                        <img src={route} alt="" className="ms-4" />
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          By clicking on a yellow vehicle icon, you can access
                          information about scheduled trips and dispatched
                          trips. Clicking on a specific trip provides options to
                          share the trip, track its location, show it on the
                          map, view device and vehicle details, access trip
                          details, and edit trips.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="7">
                      <Accordion.Header>
                        <img src={tool} alt="" className="ms-4" />
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          Clicking on the tools icon will open a menu where you
                          can find various useful tools, including the ability
                          to find a specific vehicle, set boundaries, and check
                          traffic information. We hope this guide helps you
                          navigate and utilize the different features available
                          on the dashboard of our software. If you have any
                          further questions or need additional assistance, feel
                          free to reach out to our support team.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              )}
              {currentRoute === "/CustomerProfile" && (
                <div className="" id="help-main-wrapper">
                  <p className="help-paragraph mb-2">
                    Customer Settings provides you with the necessary
                    information to manage and customize your customer settings
                    effectively.
                  </p>

                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Profile</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          In the Profile section, you can view your profile
                          information. You also have the option to update your
                          profile details and upload a new logo if needed.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Contact Information</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          Here, you can manage your contact information. Update
                          your email address, phone number.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>General Information</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          This section allows you to modify general information
                          related to your account. You can update your Address,
                          City, Country, Currency, Timezone, Website, and
                          status.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>Subscribe Information</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          Manage your subscription information in this section.
                          You can review your Plancurrent subscription plan,
                          Account type, and Expiry date.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header>Server Information</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          Access and update your server information in this
                          section. You can modify server settings, including
                          ports and IP.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                      <Accordion.Header>API Information</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          Here, you can find and manage your API information.
                          You can view Customer ID, and API Key which are
                          essential for integrating our software with other
                          systems or applications.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                      <Accordion.Header>
                        List of Transport Managers
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          View a list of your transport managers. Clicking on
                          "View All" will display all the transport managers
                          associated with your account.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="7">
                      <Accordion.Header>List of Vehicles</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          Access a comprehensive list of your vehicles. Clicking
                          on "View All" will display all the vehicles linked to
                          your account.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="8">
                      <Accordion.Header>Map</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          Utilize the map functionality to mark zones or areas.
                          You can mark areas by drawing freehand,or selecting by
                          square, or by a circle. Furthermore, you have the
                          ability to edit or delete existing marks on the Google
                          Map.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="9">
                      <Accordion.Header>
                        Application Statistics
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          This section provides you with application statistics.
                          You can view relevant data and metrics related to the
                          usage and performance of the software.
                        </p>
                        <p className="help-paragraph mt-2">
                          We hope this guide helps you navigate and utilize the
                          various features available in the Customer Settings
                          module. If you have any further questions or need
                          additional assistance, feel free to reach out to our
                          support team.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              )}

              {currentRoute === "/GeneralSetting" && (
                <div className="" id="help-main-wrapper">
                  <p className="help-paragraph mb-2">
                    Welcome to the Help section! Here, you'll find information
                    about various settings and options available in the
                    software. We have organized them into different categories
                    for easy navigation. Let's explore!
                  </p>

                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Settings</Accordion.Header>
                      <Accordion.Body>
                        <p className="heading-list">Working Days:</p>
                        <p className="help-paragraph mb-2">
                          You can customize the working days by toggling the
                          on/off switches for Monday, Tuesday, Wednesday,
                          Thursday, Friday, and Saturday.
                        </p>
                        <p className="heading-list">Speed Limit:</p>
                        <p className="help-paragraph mb-2">
                          Choose your preferred speed limit from the dropdown
                          menu.
                        </p>
                        <p className="heading-list">
                          Customer Geofence Radius (meter):
                        </p>
                        <p className="help-paragraph mb-2">
                          Select the desired radius for customer geofences from
                          the dropdown.
                        </p>
                        <p className="heading-list">
                          Pickup Point Geofence Radius (meter):
                        </p>
                        <p className="help-paragraph mb-2">
                          Choose the radius for pickup point geofences from the
                          dropdown.
                        </p>
                        <p className="heading-list">
                          Transportation Geofence (KM):
                        </p>
                        <p className="help-paragraph mb-2">
                          Set the transportation geofence radius by selecting an
                          option from the dropdown.
                        </p>
                        <p className="heading-list">Data Records per Page:</p>
                        <p className="help-paragraph mb-2">
                          Customize the number of records displayed per page
                          using the dropdown.
                        </p>
                        <p className="heading-list">Date Format:</p>
                        <p className="help-paragraph mb-2">
                          Select your preferred date format from the dropdown.
                        </p>
                        <p className="heading-list">
                          Duration to Consider Vehicle as Untracked (Minute):
                        </p>
                        <p className="help-paragraph mb-2">
                          Choose the duration threshold, in minutes, for
                          considering a vehicle as untracked from the dropdown.
                        </p>
                        <p className="heading-list">
                          Duration to Consider Vehicle as Idle (Parked):
                        </p>
                        <p className="help-paragraph mb-2">
                          Set the duration threshold, in minutes, for
                          considering a vehicle as idle from the dropdown.
                        </p>
                        <p className="heading-list">Acceleration Threshold:</p>
                        <p className="help-paragraph mb-2">
                          Customize the acceleration threshold by selecting an
                          option from the dropdown.
                        </p>
                        <p className="heading-list">Deceleration Threshold:</p>
                        <p className="help-paragraph mb-2">
                          Choose the deceleration threshold from the dropdown.
                        </p>
                        <p className="heading-list">Historical Movement Map:</p>
                        <p className="help-paragraph mb-2">
                          Select the desired option from the dropdown to view
                          historical movement on the map.
                        </p>
                        <p className="heading-list">
                          Minutes to Consider Trip Historical Movement:
                        </p>
                        <p className="help-paragraph mb-2">
                          Set the duration, in minutes, to consider the trip
                          historical movement from the dropdown.
                        </p>
                        <p className="heading-list">
                          Show Vehicle Label in Google Map:
                        </p>
                        <p className="help-paragraph mb-2">
                          Toggle this switch to display vehicle labels on the
                          Google Map.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Automation Settings</Accordion.Header>
                      <Accordion.Body>
                        <p className="heading-list">
                          Automation Data Collection:
                        </p>
                        <p className="help-paragraph mb-2">
                          Enable or disable automatic data collection by
                          toggling the on/off switch. You can also specify the
                          number of suggestions or anomaly counts by entering
                          them in the text box.
                        </p>
                        <p className="heading-list">
                          Automation Data Optimization:
                        </p>
                        <p className="help-paragraph mb-2">
                          Toggle this switch to optimize data automatically.
                          Specify the minimum number of vehicles in an area to
                          consider it as a parking slot in the text box.
                        </p>
                        <p className="heading-list">Automation Data Update:</p>
                        <p className="help-paragraph mb-2">
                          Toggle this switch to update data automatically.
                          Specify the maximum radius for parking slot creation
                          in the text box.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>Help Settings</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          Toggle this switch to enable or disable the help
                          setting.
                        </p>
                        <p className="help-paragraph mt-2">
                          We hope this information helps you navigate and
                          customize the software settings according to your
                          preferences. If you have any further questions, feel
                          free to reach out to our support team for assistance.
                          Happy exploring
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              )}

              {currentRoute === "/IntegrationSetting" && (
                <div className="" id="help-main-wrapper">
                  <p className="help-paragraph mb-2">
                    Welcome to the Help section! Here, you'll find information
                    about the Integration Settings available in the software.
                    These settings allow you to configure the SMS Gateway for
                    sending messages. Let's explore!
                  </p>

                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        SMS Gateway Toggle (On/Off)
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="heading-list mb-2">
                          Toggle this switch to enable or disable the SMS
                          Gateway functionality. When enabled, you can perform
                          the following actions:
                        </p>
                        <p className="heading-list">Recharge:</p>
                        <p className="help-paragraph mb-2">
                          Clicking on this option will redirect you to the
                          payment integration page where you can recharge your
                          SMS Gateway account.
                        </p>
                        <p className="heading-list">Available Messages:</p>
                        <p className="help-paragraph mb-2">
                          View the number of available messages in your account.
                        </p>
                        <p className="heading-list">Account Information:</p>
                        <p className="help-paragraph mb-2">
                          Get an overview of your SMS Gateway account.
                        </p>
                        <p className="heading-list">SMS Gateway Status ID:</p>
                        <p className="help-paragraph mb-2">
                          View the status ID of your SMS Gateway.
                        </p>
                        <p className="heading-list">Balance:</p>
                        <p className="help-paragraph mb-2">
                          Check the balance of your SMS Gateway account.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>SMS Gateway ID</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          Select your SMS Gateway ID from the dropdown menu.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>SMS Gateway Username</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          Enter your SMS Gateway username in the provided
                          textbox.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>SMS Gateway Password</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          Enter your SMS Gateway password in the provided
                          textbox.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header>SMS Gateway Sender ID</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph mb-2">
                          Specify your desired Sender ID for outgoing messages
                          by entering it in the provided textbox.
                        </p>
                        <p className="help-paragraph">
                          By configuring these Integration Settings, you can
                          effectively use the SMS Gateway feature to send
                          messages. If you encounter any issues or have further
                          questions, please don't hesitate to reach out to our
                          support team. They will be happy to assist you.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              )}

              {currentRoute === "/TransportationSetting" && (
                <div className="" id="help-main-wrapper">
                  <p className="help-head-sub">Map</p>
                  <p className="help-paragraph mt-2">
                    Utilize the map functionality to mark zones or areas. You
                    can mark areas by drawing freehand,or selecting by square,
                    or by a circle. Furthermore, you have the ability to edit or
                    delete existing marks on the Google Map.
                  </p>
                </div>
              )}

              {currentRoute === "/NotificationSetting" && (
                <div className="" id="help-main-wrapper">
                  <p className="help-paragraph mb-2">
                    Welcome to the Help section! Here, you'll find information
                    about the Notification Settings available in the software.
                    These settings allow you to configure notifications for
                    various events and activities. Let's explore!
                  </p>

                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Notification Table</Accordion.Header>
                      <Accordion.Body>
                        <p className="heading-list">
                          Notification Related Announcements:
                        </p>
                        <p className="help-paragraph mb-2">
                          Choose your preferred method of notification (Push
                          notification, email, or SMS) for receiving
                          announcements.
                        </p>
                        <p className="heading-list">
                          Notification Related Holidays & Vacation:
                        </p>
                        <p className="help-paragraph mb-2">
                          Select how you would like to receive notifications
                          (Push notification, email, or SMS) for holiday and
                          vacation-related information.
                        </p>
                        <p className="heading-list">
                          Notification Related Pickup Points:
                        </p>
                        <p className="help-paragraph mb-2">
                          Configure your notification preferences (Push
                          notification, email, or SMS) for updates related to
                          pickup points.
                        </p>
                        <p className="heading-list">
                          Notify When Vehicle Breaks Speed Limit:
                        </p>
                        <p className="help-paragraph mb-2">
                          Select the notification method (Push notification,
                          email, or SMS) for receiving alerts when a vehicle
                          exceeds the speed limit.
                        </p>
                        <p className="heading-list">
                          Notify When Vehicle Breaks Geofence Boundary:
                        </p>
                        <p className="help-paragraph mb-2">
                          Choose how you want to be notified (Push notification,
                          email, or SMS) when a vehicle crosses the geofence
                          boundary.
                        </p>
                        <p className="heading-list">
                          Notify When Vehicle Tracker External Power is On:
                        </p>
                        <p className="help-paragraph mb-2">
                          Select the desired method of notification (Push
                          notification, email, or SMS) for being alerted when
                          the vehicle tracker's external power is turned on.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        Vehicle Related Notification Setting
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="heading-list">
                          Vehicle Maintenance Notification (Prior Mileage):
                        </p>
                        <p className="help-paragraph mb-2">
                          Select the mileage threshold from the dropdown to
                          receive maintenance notifications.
                        </p>
                        <p className="heading-list">
                          Vehicle Maintenance Notification (Prior Days):
                        </p>
                        <p className="help-paragraph mb-2">
                          Choose the number of days in advance from the dropdown
                          to receive maintenance notifications.
                        </p>
                        <p className="heading-list">
                          Notification of Vehicle Insurance Expiry (Prior Day):
                        </p>
                        <p className="help-paragraph mb-2">
                          Select the number of days in advance from the dropdown
                          to receive alerts about upcoming vehicle insurance
                          expirations.
                        </p>
                        <p className="heading-list">
                          {" "}
                          Notification of Vehicle Permit Expiry (Prior Day):
                        </p>
                        <p className="help-paragraph mb-2">
                          Choose the number of days in advance from the dropdown
                          to receive notifications regarding upcoming vehicle
                          permit expirations.
                        </p>
                        <p className="heading-list">
                          Vehicle Tax Expiry Notification (Prior Day):
                        </p>
                        <p className="help-paragraph mb-2">
                          Select the number of days in advance from the dropdown
                          to receive alerts about upcoming vehicle tax
                          expirations.
                        </p>
                        <p className="heading-list">
                          Notification of Vehicle Next Inspection Date (Prior
                          Day):
                        </p>
                        <p className="help-paragraph mb-2">
                          Choose the number of days in advance from the dropdown
                          to receive notifications about upcoming vehicle
                          inspection dates.
                        </p>

                        <p className="help-paragraph ">
                          By customizing these Notification Settings, you can
                          stay informed about important events and activities
                          related to your vehicles. If you have any questions or
                          need further assistance, please reach out to our
                          support team. They will be glad to help you
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              )}

              {currentRoute === "/DispatchSetting" && (
                <div className="" id="help-main-wrapper">
                  <p className="help-paragraph mb-2">
                    Welcome to the Help section! Here, you'll find information
                    about the Dispatch Settings available in the software. These
                    settings allow you to configure dispatch-related options for
                    efficient trip planning. Let's explore!
                  </p>

                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        Dispatch Auto Routing Type
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph ">
                          Select the desired auto routing type from the dropdown
                          menu. This option determines how the system
                          automatically routes trips for optimal efficiency.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        Select Dispatch Trip Days
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph ">
                          Customize the days for dispatching trips by toggling
                          the on/off switches for Monday, Tuesday, Wednesday,
                          Thursday, Friday, and Saturday. Enable the switches
                          for the specific days you want to include for trip
                          dispatch.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>Periodic Trip Times</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph mb-2">
                          Choose the periodic trip times by selecting options
                          from the dropdown menu. You can add multiple times by
                          clicking the "Add" button, and the added times will be
                          displayed below the listing.
                        </p>
                        <p className="help-paragraph">
                          By configuring these Dispatch Settings, you can
                          optimize the routing of trips and customize the days
                          and times for dispatching trips according to your
                          specific requirements. If you have any questions or
                          need further assistance, please don't hesitate to
                          reach out to our support team. They will be happy to
                          help you.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              )}

              {currentRoute === "/AcessRole" && (
                <div className="" id="help-main-wrapper">
                  <p className="help-paragraph mb-2">
                    Welcome to the Help section! Here, you'll find information
                    about the Access Rights settings available in the software.
                  </p>

                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Access Rights</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph mb-2">
                          These settings allow you to manage user roles and
                          their corresponding access to specific features. Let's
                          explore!
                        </p>
                        <p className="heading-list">User Role:</p>
                        <p className="help-paragraph mb-2">
                          Click on the "User Role" option to view a list of
                          existing roles. This list displays the role name,
                          Features, and available actions such as editing and
                          deleting.
                        </p>
                        <p className="heading-list">Create Role:</p>
                        <p className="help-paragraph mb-2">
                          To create a new role, click on the "Create Role"
                          button located on the right side of the screen. You
                          can select a role from the dropdown menu and proceed
                          with creating the role.
                        </p>

                        <p className="help-paragraph mb-2">
                          By using these features, you can manage user roles and
                          their access to different features within the
                          software. This allows you to control the level of
                          access and permissions for each role based on their
                          responsibilities and requirements.
                        </p>

                        <p className="help-paragraph mb-2">
                          If you have any questions or need further assistance
                          regarding Access Rights or managing user roles, please
                          don't hesitate to reach out to our support team. They
                          will be happy to assist you.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              )}

              {currentRoute === "/AddOnSettings" && (
                <div className="" id="help-main-wrapper">
                  <p className="help-paragraph mb-2">
                    Welcome to the Help section! Here, you'll find information
                    about the Add-on Settings available in the software. These
                    settings allow you to enhance your web application by adding
                    various add-ons. Let's explore!
                  </p>

                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>My Add-ons</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph ">
                          In this section, you can view all the add-ons that you
                          currently have and are actively using in your
                          Software. This provides an overview of the add-ons you
                          have.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Premium Add-ons</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph ">
                          Browse through the premium add-ons available for
                          purchase. You can explore the different options and
                          add the desired add-ons to your cart by clicking the
                          "Add to Cart" button.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>Free Add-ons</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph ">
                          Here, you can find all the add-ons that are available
                          for free. You can view the list of free add-ons and
                          activate them as per your requirements.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>Add to Cart</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph mb-2">
                          In the "Add to Cart" section, you can view the list of
                          add-ons that you have added. You have the option to
                          delete any unwanted add-ons from the cart. The total
                          value of the cart is displayed, and by clicking the
                          "Checkout Now" button, you will be redirected to the
                          payment integration to complete the purchase.
                        </p>
                        <p className="help-paragraph ">
                          By utilizing these Add-on Settings, you can enhance
                          the functionality and features of your Software by
                          adding premium or free add-ons. If you have any
                          questions or need further assistance regarding the
                          Add-on Settings or the installation process, please
                          feel free to contact our support team. They will be
                          delighted to assist you.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              )}

              {currentRoute === "/LogChanges" && (
                <div className="" id="help-main-wrapper">
                  <p className="help-paragraph mb-2">
                    Welcome to the Help section! Here, you'll find information
                    about the Log Changes feature available in the software.
                    This feature allows you to track and view the changes made
                    by users. Let's explore!
                  </p>

                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Search User</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph ">
                          You can search for a specific user by entering their
                          username in the search bar. This will help you filter
                          the log changes related to that particular user.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Select Date</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph ">
                          You can select a specific date from the calendar to
                          narrow down the log changes to a particular day. This
                          helps in pinpointing the changes made on a specific
                          date.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>View Log Listings</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph ">
                          Once you have entered the user name and selected the
                          date, you will see a list of log entries. Each entry
                          will display the user name, date and time of the
                          change, and a brief log description.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>View Changes</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph mb-2">
                          In the "Action" column, you will find a field labeled
                          "View Changes." By clicking on this field, you can see
                          the detailed changes made by the user. This provides a
                          comprehensive view of the modifications and updates
                          made to the system.
                        </p>
                        <p className="help-paragraph ">
                          By utilizing the Log Changes feature, you can track
                          user activities, view log entries, and access detailed
                          information about the changes made. This helps in
                          monitoring and maintaining an audit trail of system
                          modifications. If you have any questions or need
                          further assistance, please don't hesitate to reach out
                          to our support team. They will be happy to assist you.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              )}

              {currentRoute === "/Vehicle" && (
                <div className="" id="help-main-wrapper">
                  <p className="help-paragraph mb-2">
                    Welcome to the Help section! Here, you'll find information
                    about the Vehicle-related features available in the
                    software. These features allow you to manage and track
                    vehicles efficiently. Let's explore!
                  </p>

                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Add Vehicle</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph mb-2">
                          To add a new vehicle, click on the "Add Vehicle"
                          button located on the right side of the screen. This
                          will open a form where you can enter the vehicle
                          details.
                        </p>

                        <p className="heading-list">Adding a Vehicle:</p>
                        <p className="help-paragraph mb-2">
                          When adding a vehicle, you will need to provide the
                          following details:
                        </p>

                        <p className="help-paragraph mb-2">
                          Vehicle Information: Enter the relevant information
                          about the vehicle.
                        </p>

                        <p className="help-paragraph mb-2">
                          Tracking Device: Provide details about the tracking
                          device associated with the vehicle.
                        </p>

                        <p className="help-paragraph mb-2">
                          Temperature Sensor: Specify if the vehicle has a
                          temperature sensor.
                        </p>

                        <p className="help-paragraph mb-2">
                          Validity Information: Set the validity information for
                          the vehicle.
                        </p>
                        <p className="help-paragraph mb-2">
                          Vehicle Geo-fence: Define the geo-fence settings for
                          the vehicle.
                        </p>
                        <p className="help-paragraph mb-2">
                          By utilizing these vehicle-related features, you can
                          efficiently manage and track your vehicles within the
                          software. If you have any questions or need further
                          assistance, Please don't hesitate to reach out to our
                          support team. They will be happy to assist you.
                        </p>

                        <p className="heading-list">Vehicle Listings:</p>

                        <p className="help-paragraph mb-2">
                          You can view vehicle listings categorized into
                          sections: All, Online, Offline, and Untracked/Not
                          Tracked Yet. Each section offers different filters to
                          help you find specific vehicles.
                        </p>
                        <p className="help-paragraph">All Section:</p>
                        <p className="help-paragraph mb-2">
                          "All" section, you can view all vehicles. Use the
                          search bar to search for vehicles by entering details
                          such as vehicle name, vehicle number, and vehicle IM.
                          You can also search by vehicle category, vehicle
                          capacity, and transportation type using the provided
                          filters.
                        </p>
                        <p className="help-paragraph">Online Section:</p>
                        <p className="help-paragraph mb-2">
                          "Online" section displays all vehicles that are
                          currently online. Use the search bar and filters to
                          narrow down your search and find specific online
                          vehicles.
                        </p>
                        <p className="help-paragraph ">Offline Section:</p>
                        <p className="help-paragraph mb-2">
                          "Offline" section, you can view all vehicles that are
                          currently offline. Similar to the previous sections,
                          you can use the search bar and filters to find
                          specific offline vehicles.
                        </p>
                        <p className="help-paragraph">
                          Untracked/Not Tracked Yet Section:
                        </p>
                        <p className="help-paragraph mb-2">
                          "Untracked/Not Tracked Yet" section shows vehicles
                          that have not been tracked yet. You can search for
                          these vehicles using the search bar and filters.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Vehicle category</Accordion.Header>
                      <Accordion.Body>
                        <p className="heading-list">Add Vehicle category:</p>

                        <p className="help-paragraph mb-2">
                          To add a new vehicle category, click on the "Add
                          Vehicle Category" button. You will be presented with a
                          form where you need to fill in the required
                          information. The form typically includes details such
                          as the category name, description, and any additional
                          relevant information.
                        </p>
                        <p className="help-paragraph mb-2">
                          Once you have filled out the form, submit it to add
                          the vehicle category to the system.
                        </p>
                        <p className="heading-list">
                          Vehicle category listings:
                        </p>

                        <p className="help-paragraph mb-2">
                          You can view vehicle listings categorized into
                          sections: All, Online, Offline, and Untracked/Not
                          Tracked Yet. Each section offers different filters to
                          help you find specific vehicles.
                        </p>

                        <p className="help-paragraph ">All Section:</p>
                        <p className="help-paragraph mb-2">
                          "All" section, you can view all vehicles. Use the
                          search bar to search for vehicles by entering details
                          such as vehicle name, vehicle number, and vehicle IM.
                          You can also search by vehicle category, vehicle
                          capacity, and transportation type using the provided
                          filters.
                        </p>
                        <p className="help-paragraph ">Online Section:</p>
                        <p className="help-paragraph mb-2">
                          "Online" section displays all vehicles that are
                          currently online. Use the search bar and filters to
                          narrow down your search and find specific online
                          vehicles.
                        </p>
                        <p className="help-paragraph ">Offline Section:</p>
                        <p className="help-paragraph mb-2">
                          "Offline" section, you can view all vehicles that are
                          currently offline. Similar to the previous sections,
                          you can use the search bar and filters to find
                          specific offline vehicles.
                        </p>
                        <p className="help-paragraph ">
                          Untracked/Not Tracked Yet Section:
                        </p>
                        <p className="help-paragraph mb-2">
                          "Untracked/Not Tracked Yet" section shows vehicles
                          that have not been tracked yet. You can search for
                          these vehicles using the search bar and filters.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>Vehicle Group</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph ">
                          Add a new vehicle group, click on the "Add Vehicle
                          Group" button. You will be directed to a form where
                          you need to provide the necessary details. The form
                          usually includes fields such as the group name,
                          description, and other relevant information.
                        </p>
                        <p className="help-paragraph mb-2">
                          After filling in the required information, submit the
                          form to add the vehicle group to the system.
                        </p>
                        <p className="heading-list">Vehicle Group Listings:</p>
                        <p className="help-paragraph mb-2">
                          You can view vehicle Group listings categorized into
                          sections: All, Online, Offline, and Untracked/Not
                          Tracked Yet. Each section offers different filters to
                          help you find specific vehicles.
                        </p>
                        <p className="help-paragraph ">All Section:</p>
                        <p className="help-paragraph mb-2">
                          "All" section, you can view all vehicles. Use the
                          search bar to search for vehicles by entering details
                          such as vehicle name, vehicle number, and vehicle IM.
                          You can also search by vehicle category, vehicle
                          capacity, and transportation type using the provided
                          filters.
                        </p>
                        <p className="help-paragraph ">Online Section:</p>

                        <p className="help-paragraph mb-2">
                          "Online" section displays all vehicles that are
                          currently online. Use the search bar and filters to
                          narrow down your search and find specific online
                          vehicles.
                        </p>

                        <p className="help-paragraph ">Offline Section:</p>

                        <p className="help-paragraph mb-2">
                          "Offline" section, you can view all vehicles that are
                          currently offline. Similar to the previous sections,
                          you can use the search bar and filters to find
                          specific offline vehicles.
                        </p>
                        <p className="help-paragraph ">
                          Untracked/Not Tracked Yet Section:
                        </p>
                        <p className="help-paragraph mb-2">
                          "Untracked/Not Tracked Yet" section shows vehicles
                          that have not been tracked yet. You can search for
                          these vehicles using the search bar and filters.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>Sync</Accordion.Header>
                      <Accordion.Body>
                        <p className="heading-list">Add New Sync Request:</p>

                        <p className="help-paragraph ">
                          If you need to synchronize data or perform a data sync
                          operation, you can use the "Add New Sync Request"
                          option.
                        </p>
                        <p className="help-paragraph ">
                          Click on the option to access the form for adding a
                          new sync request.
                        </p>
                        <p className="help-paragraph ">
                          Fill out the form with the relevant details for the
                          sync operation.
                        </p>

                        <p className="help-paragraph mb-2">
                          Once you have filled in the necessary information,
                          submit the form to initiate the sync request
                        </p>
                        <p className="heading-list">Sync Listings:</p>
                        <p className="help-paragraph mb-2">
                          You can view Sync listings categorized into sections:
                          All, Online, Offline, and Untracked/Not Tracked Yet.
                          Each section offers different filters to help you find
                          specific vehicles.
                        </p>

                        <p className="help-paragraph ">All Section:</p>
                        <p className="help-paragraph mb-2">
                          {" "}
                          "All" section, you can view all vehicles. Use the
                          search bar to search for vehicles by entering details
                          such as vehicle name, vehicle number, and vehicle IM.
                          You can also search by vehicle category, vehicle
                          capacity, and transportation type using the provided
                          filters.
                        </p>
                        <p className="help-paragraph ">Online Section:</p>
                        <p className="help-paragraph mb-2">
                          "Online" section displays all vehicles that are
                          currently online. Use the search bar and filters to
                          narrow down your search and find specific online
                          vehicles.
                        </p>
                        <p className="help-paragraph">Offline Section:</p>
                        <p className="help-paragraph mb-2">
                          "Offline" section, you can view all vehicles that are
                          currently offline. Similar to the previous sections,
                          you can use the search bar and filters to find
                          specific offline vehicles
                        </p>
                        <p className="help-paragraph ">
                          Untracked/Not Tracked Yet Section:
                        </p>
                        <p className="help-paragraph mb-2">
                          "Untracked/Not Tracked Yet" section shows vehicles
                          that have not been tracked yet. You can search for
                          these vehicles using the search bar and filters.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              )}

              {currentRoute === "/Holidays" && (
                <div className="" id="help-main-wrapper">
                  <p className="help-paragraph mb-2">
                    Welcome to the Help module for our software's Holidays
                    feature!
                  </p>

                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        Searching for Holidays
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph mb-2">
                          To search for a specific holiday, simply type the
                          holiday name in the search box. The results will be
                          displayed accordingly.
                        </p>
                        <p className="help-paragraph">Selecting Dates:</p>
                        <p className="help-paragraph ">
                          To search for a specific holiday, simply type the
                          holiday name in the search box. The results will be
                          displayed accordingly.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Holiday Listings</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph ">
                          When you search for holidays, you will see a list of
                          results. Each holiday listing includes the date,
                          description, status, and actions for editing or
                          deleting the holiday.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>Add Holiday</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph mb-2">
                          To add a new holiday, click on the "Add Holiday"
                          button located on the right side of the screen. A form
                          will appear.
                        </p>
                        <p className="heading-list">
                          Fill in the required details:
                        </p>
                        <p className="help-paragraph ">
                          Holiday Name: Enter the name of the holiday.
                        </p>
                        <p className="help-paragraph ">
                          Holiday Date: Select the date of the holiday from the
                          calendar.
                        </p>
                        <p className="help-paragraph mb-2">
                          Holiday Description: Provide a brief description of
                          the holiday.
                        </p>

                        <p className="help-paragraph mb-2">
                          Once you've entered all the necessary information,
                          click the "Submit" button to add the holiday to the
                          system.
                        </p>
                        <p className="help-paragraph">
                          We hope this guide helps you navigate and utilize the
                          Holidays feature effectively. If you have any further
                          questions or need assistance, feel free to reach out
                          to our support team.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              )}

              {currentRoute === "/Vacations" && (
                <div className="" id="help-main-wrapper">
                  <p className="help-paragraph mb-2">
                    Welcome to the Help module for our software's Vacation
                    feature!
                  </p>

                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        Searching for Vacations
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph mb-2">
                          To search for a specific vacation, simply type the
                          vacation name in the search box. The results will be
                          displayed accordingly.
                        </p>
                        <p className="help-paragraph">
                          Selecting Dates: You can also search for vacations by
                          selecting a start date and an end date from the
                          calendar. Click on the desired start date and then the
                          end date, and the software will show you the vacations
                          within that time frame.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Vacation Listings</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph ">
                          When you search for vacations, you will see a list of
                          results. Each vacation listing includes the start
                          date, end date, description, status, and actions for
                          editing or deleting the vacation.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>Adding a Vacation</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph mb-2">
                          To add a new vacation, click on the "Add Vacation"
                          button located on the right side of the screen. A form
                          will appear.
                        </p>
                        <p className="heading-list">
                          Fill in the required details:
                        </p>
                        <p className="help-paragraph ">
                          Start Date & End Date: Select the start and end dates
                          of the vacation from the calendar.
                        </p>

                        <p className="help-paragraph mb-2">
                          Vacation Description: Provide a brief description of
                          the vacation.
                        </p>

                        <p className="help-paragraph mb-2">
                          Once you've entered all the necessary information,
                          click the "Submit" button to add the vacation to the
                          system.
                        </p>

                        <p className="help-paragraph">
                          We hope this guide helps you navigate and utilize the
                          Vacation feature effectively. If you have any further
                          questions or need assistance, feel free to reach out
                          to our support team.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              )}

              {currentRoute === "/PointOfIntrest" && (
                <div className="" id="help-main-wrapper">
                  <p className="help-paragraph mb-2">
                    Welcome to the Help module for our software's Point of
                    Interest feature!
                  </p>

                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        Searching for Locations
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          You can search for specific locations by entering the
                          location name, latitude, longitude, or address in the
                          respective text boxes. The results will be displayed
                          accordingly.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Mapping the Location</Accordion.Header>
                      <Accordion.Body>
                        <p className="heading-list ">
                          You have the option to map the location on Google
                          Maps. There are three ways to mark the location:
                        </p>
                        <p className="help-paragraph ">
                          Freehand Marking: Use your cursor to draw a shape
                          representing the area of interest on the map.
                        </p>
                        <p className="help-paragraph ">
                          Placing a Square: Place a square marker on the map to
                          indicate the location.
                        </p>
                        <p className="help-paragraph ">
                          Placing a Circle: Place a circular marker on the map
                          to indicate the location.
                        </p>
                        <p className="help-paragraph ">
                          You can also edit or delete the mapped location if
                          needed.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        Viewing Point of Interest Listings
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          You can view a list of all the points of interest
                          available. Each listing includes the location name,
                          address, latitude, and longitude.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>Adding a Location</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph mb-2">
                          Add new location: To add a new location, click on the
                          "Add Location" button located on the right side of the
                          page. A form will appear.
                        </p>
                        <p className="heading-list">
                          Fill in the required details:
                        </p>
                        <p className="help-paragraph ">
                          Location Name: Enter the name of the location.
                        </p>
                        <p className="help-paragraph ">
                          Location Address: Provide the address of the location.
                        </p>
                        <p className="help-paragraph mb-2">
                          Map Location: Map the location on Google Maps using
                          any of the available methods.
                        </p>
                        <p className="help-paragraph mb-2">
                          Once you've entered all the necessary information and
                          mapped the location, click the "Submit" button to add
                          the location to the system.
                        </p>
                        <p className="help-paragraph ">
                          We hope this guide helps you navigate and utilize the
                          Point of Interest feature effectively. If you have any
                          further questions or need assistance, feel free to
                          reach out to our support team.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              )}

              {currentRoute === "/GeofenceAreas" && (
                <div className="" id="help-main-wrapper">
                  <p className="help-paragraph mb-2">
                    Welcome to the Help module for our software's Geofence Areas
                    feature! Here's a user-friendly guide to help you navigate
                    through the different functionalities:
                  </p>

                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        Searching Geofence Areas
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          To search for a specific location, enter the name,
                          latitude, longitude, or address in the provided text
                          box. The system will display the results.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        Mapping Locations on Google Maps
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="heading-list ">
                          You can mark locations on Google Maps in three
                          different ways:
                        </p>
                        <p className="help-paragraph ">
                          Freehand marking: Use your mouse or touch input to
                          draw a shape directly on the map.
                        </p>
                        <p className="help-paragraph ">
                          Square placement: Place a square-shaped marker on the
                          map by clicking on the desired location.
                        </p>
                        <p className="help-paragraph ">
                          Circle placement: Similarly, you can place a circular
                          marker on the map.
                        </p>
                        <p className="help-paragraph ">
                          Editing and Deleting Geofence Locations: If you need
                          to make changes, you have the option to edit or delete
                          the mapped geofence areas. Simply select the specific
                          location on the map and choose the appropriate action.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        Viewing All Geofence Areas
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          To see a comprehensive list of all geofence areas,
                          navigate to the designated section. You'll find the
                          location name, address, latitude, and longitude
                          displayed for each area.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>Adding Geofence Areas</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph mb-2">
                          To add a new geofence area, click on the "Add
                          Geofence" button located on the right side of the
                          page.
                        </p>

                        <p className="help-paragraph mb-2">
                          Fill in the required information, such as Parent Area,
                          Geofence areas, address, Landmark, City/state,
                          Country, and Area speed limit.
                        </p>
                        <p className="help-paragraph mb-2">
                          Use the mapping tools, as mentioned earlier, to mark
                          the location on the Google Map.
                        </p>
                        <p className="help-paragraph mb-2">
                          After mapping, you can further edit or delete the
                          location if needed. Once you're satisfied with the
                          details and the mapped area, submit the form to save
                          the geofence area.
                        </p>
                        <p className="help-paragraph ">
                          We hope this user-friendly guide helps you navigate
                          and utilize the Geofence Areas feature effectively. If
                          you have any further questions or need additional
                          assistance, feel free to reach out to our support
                          team.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              )}

              {currentRoute === "/DispatchCustomer" && (
                <div className="" id="help-main-wrapper">
                  <p className="help-paragraph mb-2">
                    Welcome to the "Help" module of our software! Here, we will
                    guide you on how to effectively use the Dispatch Customer
                    feature. This feature allows you to manage and search for
                    customers easily. Let's get started!
                  </p>

                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        Searching for Customers:
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          <p>To search for a customer, you have two options:</p>

                          <p>
                            a) By Vehicle Name: You can select the desired
                            vehicle name from the dropdown menu.{" "}
                          </p>
                          <p>
                            {" "}
                            b) By Customer Code: You can enter the customer code
                            directly into the search field.
                          </p>
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        b. Viewing Customer Listings:
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="heading-list ">
                          Once you have performed a search, you will be
                          presented with a list of customers. The customer
                          listings will include essential information such as
                          the customer's name, customer code, email address, and
                          mobile number. You can conveniently view, edit, or
                          delete customer details using the available action
                          buttons.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        Adding a New Customer:
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          To add a new customer, simply click on the "Add
                          Customer" button. A form will appear, prompting you to
                          fill in the necessary information. Additionally, you
                          can add multiple addresses for a customer, ensuring
                          accurate delivery details. Once you have completed the
                          form, click on the "Submit" button to save the
                          customer's information.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <p className="help-paragraph" style={{ marginTop: "10px" }}>
                      That's it! You are now equipped with the knowledge to
                      effectively use the Dispatch Customer feature. If you have
                      any further questions or need additional assistance,
                      please don't hesitate to reach out.
                    </p>
                  </Accordion>
                </div>
              )}
              {currentRoute === "/VehicleBooking" && (
                <div className="" id="help-main-wrapper">
                  <p className="help-paragraph mb-2">
                    Welcome to the "Help" module of our software! In this
                    section, we will guide you on how to effectively use the
                    Vehicle Booking feature. This feature allows you to easily
                    request vehicle bookings and manage them efficiently. Let's
                    get started!
                  </p>

                  <p className="help-paragraph mb-2">
                    To make a vehicle booking request, you need to fill out the
                    provided form with the necessary details. This includes the
                    pickup location, drop-off location, and any other relevant
                    information. Once you have filled in the required fields,
                    click on the "Submit" button to submit your booking request.
                    Your request will then be listed in the Vehicle Booking List
                    for further processing.
                  </p>
                  <p className="help-paragraph mb-2">
                    That's it! You now have a better understanding of how to use
                    the Vehicle Booking request our software. If you have any
                    further questions or need additional assistance, please
                    don't hesitate to reach out to our support team.
                  </p>
                </div>
              )}
              {currentRoute === `/VehicleBooking/${token1}` && (
                <div className="" id="help-main-wrapper">
                  <p className="help-paragraph mb-2">
                    Welcome to the "Help" module of our software! In this
                    section, we will guide you on how to effectively use the
                    Vehicle Booking feature. This feature allows you to easily
                    request vehicle bookings and manage them efficiently. Let's
                    get started!
                  </p>

                  <p className="help-paragraph mb-2">
                    To make a vehicle booking request, you need to fill out the
                    provided form with the necessary details. This includes the
                    pickup location, drop-off location, and any other relevant
                    information. Once you have filled in the required fields,
                    click on the "Submit" button to submit your booking request.
                    Your request will then be listed in the Vehicle Booking List
                    for further processing.
                  </p>
                  <p className="help-paragraph mb-2">
                    That's it! You now have a better understanding of how to use
                    the Vehicle Booking request our software. If you have any
                    further questions or need additional assistance, please
                    don't hesitate to reach out to our support team.
                  </p>
                </div>
              )}
              {currentRoute === "/VehicleBookingList" && (
                <div className="" id="help-main-wrapper">
                  <p className="help-paragraph mb-2">
                    Welcome to the "Help" module of our software! In this
                    section, we will guide you on how to effectively use the
                    Vehicle Booking list feature. This feature allows you to
                    easily request vehicle bookings and manage them efficiently.
                    Let's get started!
                  </p>

                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Vehicle Booking List:</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          <p>
                            In the Vehicle Booking List, you can conveniently
                            search for specific bookings using various filters:
                          </p>

                          <p>
                            a) Order Number: Enter the order number directly
                            into the search field to find a specific booking.
                          </p>
                          <p>
                            b) Customer Name: Select the desired customer name
                            from the dropdown menu to filter the bookings.
                          </p>
                          <p>
                            c) Order Status: Choose the appropriate order status
                            from the dropdown menu to narrow down the search.
                          </p>
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <p className="help-paragraph">
                      2. Once you have applied the desired filters, you will see
                      a list of vehicle bookings that match your search
                      criteria. The list will display important information such
                      as the order number, customer name, booking amount, order
                      status, and order date & time. To view more details about
                      a specific booking, simply click on the respective booking
                      in the list.
                    </p>
                    <p className="help-paragraph">
                      3. Manual Routing: If you need to manually route an order,
                      you can click on the "Manual Routing" button. This feature
                      allows you to visualize the customer and merchant loctions
                      on a map. Utilize the map functionality to mark zones or
                      areas. You can mark areas by drawing freehand,or selecting
                      by square, or by a circle. Furthermore, you have the
                      ability to edit or delete existing marks on the Google
                      Map. you can create a trip by providing detailed trip
                      information. This way, you can effectively plan and manage
                      your deliveries.
                    </p>
                    <p className="help-paragraph">
                      That's it! You now have a better understanding of how to
                      use the Vehicle Booking list in our software. If you have
                      any further questions or need additional assistance,
                      please don't hesitate to reach out to our support team. We
                      are here to help you make the most out of our software.
                    </p>
                  </Accordion>
                </div>
              )}

              {currentRoute === "/DeliveryRequest" && (
                <div className="" id="help-main-wrapper">
                  <p className="help-paragraph mb-2">
                    Welcome to the "Help" module of our software! In this
                    section, we will guide you on how to effectively use the
                    feature. This feature allows you to easily request Delivery
                    request and manage them efficiently. Let's get started!
                  </p>

                  <p className="help-paragraph mb-2">
                    To make a Delivery request, you need to fill out the
                    provided form with the necessary details. This includes the
                    pickup location, drop-off location, and any other relevant
                    information. Once you have filled in the required fields,
                    click on the "Submit" button to submit your booking request.
                    Your request will then be listed in the Orders List for
                    further processing.
                  </p>

                  <p className="help-paragraph mb-2">
                    That's it! You now have a better understanding of how to use
                    the Delivery request software. If you have any further
                    questions or need additional assistance, please don't
                    hesitate to reach out to our support team.
                  </p>
                </div>
              )}
              {currentRoute === "/TripManagement" && (
                <div className="" id="help-main-wrapper">
                  <p className="help-paragraph mb-2">
                    Under the Trip section, you will find three subsections:
                    Planned, Unplanned, and Completed.
                  </p>
                  <li
                    style={{
                      color: "#703c19",

                      alignItems: "center",
                    }}
                  >
                    <p
                      className="help-paragraph mb-2"
                      style={{ marginTop: -20, marginLeft: 10 }}
                    >
                      Planned Trips:
                    </p>
                  </li>
                  <p className="help-paragraph mb-2">
                    In this section, you can view a list of all the planned
                    trips. Each trip is listed with details such as the trip
                    name, assigned vehicle, trip category, start time, end time,
                    and driver's name. You can search for specific trips by
                    entering the trip name, driver's name, email, contact
                    number, start time, or end time in their respective text
                    boxes.
                  </p>
                  <li
                    style={{
                      color: "#703c19",

                      alignItems: "center",
                    }}
                  >
                    <p
                      className="help-paragraph mb-2"
                      style={{ marginTop: -20, marginLeft: 10 }}
                    >
                      Unplanned Trips:
                    </p>
                  </li>
                  <p className="help-paragraph mb-2">
                    In this section, you can view a list of all the Unplanned
                    trips. Each trip is listed with details such as the trip
                    name, assigned vehicle, trip category, start time, end time,
                    and driver's name. You can search for specific trips by
                    entering the trip name, driver's name, email, contact
                    number, start time, or end time in their respective text
                    boxes.
                  </p>
                  <li
                    style={{
                      color: "#703c19",

                      alignItems: "center",
                    }}
                  >
                    <p
                      className="help-paragraph mb-2"
                      style={{ marginTop: -20, marginLeft: 10 }}
                    >
                      Completed Trips:
                    </p>
                  </li>
                  <p className="help-paragraph mb-2">
                    In this section, you can view a list of all the Completed
                    trips. Each trip is listed with details such as the trip
                    name, assigned vehicle, trip category, start time, end time,
                    and driver's name. You can search for specific trips by
                    entering the trip name, driver's name, email, contact
                    number, start time, or end time in their respective text
                    boxes.
                  </p>
                </div>
              )}
              {currentRoute === "/VehicleInspectionDashboard" && (
                <div className="" id="help-main-wrapper">
                  <p className="help-paragraph mb-2">
                    The Dashboard is designed to provide you with a
                    comprehensive overview of your inspection-related data. Here
                    are the key components you'll find on the dashboard:
                  </p>
                  <li
                    style={{
                      color: "#703c19",

                      alignItems: "center",
                    }}
                  >
                    <p
                      className="help-paragraph mb-2"
                      style={{ marginTop: -20, marginLeft: 10 }}
                    >
                      Inspection Summary
                    </p>
                  </li>

                  <li
                    style={{
                      color: "#703c19",

                      alignItems: "center",
                    }}
                  >
                    <p
                      className="help-paragraph mb-2"
                      style={{ marginTop: -20, marginLeft: 10 }}
                    >
                      Inspection Status
                    </p>
                  </li>

                  <li
                    style={{
                      color: "#703c19",

                      alignItems: "center",
                    }}
                  >
                    <p
                      className="help-paragraph mb-2"
                      style={{ marginTop: -20, marginLeft: 10 }}
                    >
                      Inspection This Month
                    </p>
                  </li>
                  <li
                    style={{
                      color: "#703c19",

                      alignItems: "center",
                    }}
                  >
                    <p
                      className="help-paragraph mb-2"
                      style={{ marginTop: -20, marginLeft: 10 }}
                    >
                      Top Inspector
                    </p>
                  </li>

                  <li
                    style={{
                      color: "#703c19",

                      alignItems: "center",
                    }}
                  >
                    <p
                      className="help-paragraph mb-2"
                      style={{ marginTop: -20, marginLeft: 10 }}
                    >
                      Top Spend Categories
                    </p>
                  </li>
                  <li
                    style={{
                      color: "#703c19",

                      alignItems: "center",
                    }}
                  >
                    <p
                      className="help-paragraph mb-2"
                      style={{ marginTop: -20, marginLeft: 10 }}
                    >
                      Top Condition Vehicle Type
                    </p>
                  </li>
                  <li
                    style={{
                      color: "#703c19",

                      alignItems: "center",
                    }}
                  >
                    <p
                      className="help-paragraph mb-2"
                      style={{ marginTop: -20, marginLeft: 10 }}
                    >
                      Inspection Log
                    </p>
                  </li>
                  <li
                    style={{
                      color: "#703c19",

                      alignItems: "center",
                    }}
                  >
                    <p
                      className="help-paragraph mb-4"
                      style={{ marginTop: -20, marginLeft: 10 }}
                    >
                      Inspection Repair
                    </p>
                  </li>
                  <p
                    className="help-paragraph mb-2"
                    style={{ marginTop: -20, marginLeft: 10 }}
                  >
                    a. Adding a New Inspection To add a new inspection, simply
                    click on the "New Inspection" button. You'll be directed to
                    a form where you can enter the necessary details about the
                    inspection. After filling out the form, click on the
                    "Submit" button. The system will then redirect you to view
                    the inspection details, allowing you to review the
                    information you entered.
                  </p>
                </div>
              )}

              {currentRoute === `/VehicleInspectionDashboard/${token1}` && (
                <div className="" id="help-main-wrapper">
                  <p className="help-paragraph mb-2">
                    The Dashboard is designed to provide you with a
                    comprehensive overview of your inspection-related data. Here
                    are the key components you'll find on the dashboard:
                  </p>
                  <li
                    style={{
                      color: "#703c19",

                      alignItems: "center",
                    }}
                  >
                    <p
                      className="help-paragraph mb-2"
                      style={{ marginTop: -20, marginLeft: 10 }}
                    >
                      Inspection Summary
                    </p>
                  </li>

                  <li
                    style={{
                      color: "#703c19",

                      alignItems: "center",
                    }}
                  >
                    <p
                      className="help-paragraph mb-2"
                      style={{ marginTop: -20, marginLeft: 10 }}
                    >
                      Inspection Status
                    </p>
                  </li>

                  <li
                    style={{
                      color: "#703c19",

                      alignItems: "center",
                    }}
                  >
                    <p
                      className="help-paragraph mb-2"
                      style={{ marginTop: -20, marginLeft: 10 }}
                    >
                      Inspection This Month
                    </p>
                  </li>
                  <li
                    style={{
                      color: "#703c19",

                      alignItems: "center",
                    }}
                  >
                    <p
                      className="help-paragraph mb-2"
                      style={{ marginTop: -20, marginLeft: 10 }}
                    >
                      Top Inspector
                    </p>
                  </li>

                  <li
                    style={{
                      color: "#703c19",

                      alignItems: "center",
                    }}
                  >
                    <p
                      className="help-paragraph mb-2"
                      style={{ marginTop: -20, marginLeft: 10 }}
                    >
                      Top Spend Categories
                    </p>
                  </li>
                  <li
                    style={{
                      color: "#703c19",

                      alignItems: "center",
                    }}
                  >
                    <p
                      className="help-paragraph mb-2"
                      style={{ marginTop: -20, marginLeft: 10 }}
                    >
                      Top Condition Vehicle Type
                    </p>
                  </li>
                  <li
                    style={{
                      color: "#703c19",

                      alignItems: "center",
                    }}
                  >
                    <p
                      className="help-paragraph mb-2"
                      style={{ marginTop: -20, marginLeft: 10 }}
                    >
                      Inspection Log
                    </p>
                  </li>
                  <li
                    style={{
                      color: "#703c19",

                      alignItems: "center",
                    }}
                  >
                    <p
                      className="help-paragraph mb-4"
                      style={{ marginTop: -20, marginLeft: 10 }}
                    >
                      Inspection Repair
                    </p>
                  </li>
                  <p
                    className="help-paragraph mb-2"
                    style={{ marginTop: -20, marginLeft: 10 }}
                  >
                    a. Adding a New Inspection To add a new inspection, simply
                    click on the "New Inspection" button. You'll be directed to
                    a form where you can enter the necessary details about the
                    inspection. After filling out the form, click on the
                    "Submit" button. The system will then redirect you to view
                    the inspection details, allowing you to review the
                    information you entered.
                  </p>
                </div>
              )}

              {currentRoute === "/VehicleInspection" && (
                <div className="" id="help-main-wrapper">
                  <p className="help-paragraph mb-2">
                    In the Vehicle Inspection section, you can conveniently view
                    a comprehensive list of vehicles along with their relevant
                    details. Here's what you can do: Vehicle Name, Driver Name,
                    Inspection Done By, Vehicle Compliances Actions: Perform
                    actions such as viewing and editing the vehicle details.
                  </p>

                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        Searching for Vehicles
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          <p>
                            Our software provides a convenient search feature to
                            help you find specific vehicles. You can search by:
                          </p>

                          <li
                            style={{
                              color: "#703c19",

                              alignItems: "center",
                            }}
                          >
                            <p
                              className="help-paragraph mb-2"
                              style={{ marginTop: -20, marginLeft: 10 }}
                            >
                              Vehicle Name: Choose a vehicle name from the
                              dropdown list to quickly locate the desired
                              vehicle.
                            </p>
                          </li>
                          <li
                            style={{
                              color: "#703c19",

                              alignItems: "center",
                            }}
                          >
                            <p
                              className="help-paragraph mb-2"
                              style={{ marginTop: -20, marginLeft: 10 }}
                            >
                              Service Date: Select a service date from the
                              calendar to find vehicles serviced on a specific
                              day.
                            </p>
                          </li>
                          <li
                            style={{
                              color: "#703c19",

                              alignItems: "center",
                            }}
                          >
                            <p
                              className="help-paragraph mb-2"
                              style={{ marginTop: -20, marginLeft: 10 }}
                            >
                              Order Number: Enter an order number to search for
                              a particular vehicle.
                            </p>
                          </li>
                          <li
                            style={{
                              color: "#703c19",

                              alignItems: "center",
                            }}
                          >
                            <p
                              className="help-paragraph mb-2"
                              style={{ marginTop: -20, marginLeft: 10 }}
                            >
                              Due Date: Select a due date from the calendar to
                              find vehicles with pending tasks or obligations.
                            </p>
                          </li>
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        Adding a New Inspection
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="heading-list ">
                          To add a new inspection, simply click on the "New
                          Inspection" button. You'll be directed to a form where
                          you can enter the necessary details about the
                          inspection. After filling out the form, click on the
                          "Submit" button. The system will then redirect you to
                          view the inspection details, allowing you to review
                          the information you entered.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>

                    <p className="help-paragraph">
                      We aim to provide you with a user-friendly experience to
                      effectively manage and search for vehicles. If you have
                      any further questions or require additional assistance,
                      please don't hesitate to reach out to our support team.
                    </p>
                  </Accordion>
                </div>
              )}

              {currentRoute === "/ReplayPlayback" && (
                <div className="" id="help-main-wrapper">
                  <p className="help-paragraph mb-2">
                    In the Vehicle Inspection section, you can conveniently view
                    a comprehensive list of vehicles along with their relevant
                    details. Here's what you can do: Vehicle Name, Driver Name,
                    Inspection Done By, Vehicle Compliances Actions: Perform
                    actions such as viewing and editing the vehicle details.
                  </p>

                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        Searching for Vehicles
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          <p>
                            Our software provides a convenient search feature to
                            help you find specific vehicles. You can search by:
                          </p>

                          <li
                            style={{
                              color: "#703c19",

                              alignItems: "center",
                            }}
                          >
                            <p
                              className="help-paragraph mb-2"
                              style={{ marginTop: -20, marginLeft: 10 }}
                            >
                              Vehicle Name: Choose a vehicle name from the
                              dropdown list to quickly locate the desired
                              vehicle.
                            </p>
                          </li>
                          <li
                            style={{
                              color: "#703c19",

                              alignItems: "center",
                            }}
                          >
                            <p
                              className="help-paragraph mb-2"
                              style={{ marginTop: -20, marginLeft: 10 }}
                            >
                              Service Date: Select a service date from the
                              calendar to find vehicles serviced on a specific
                              day.
                            </p>
                          </li>
                          <li
                            style={{
                              color: "#703c19",

                              alignItems: "center",
                            }}
                          >
                            <p
                              className="help-paragraph mb-2"
                              style={{ marginTop: -20, marginLeft: 10 }}
                            >
                              Order Number: Enter an order number to search for
                              a particular vehicle.
                            </p>
                          </li>
                          <li
                            style={{
                              color: "#703c19",

                              alignItems: "center",
                            }}
                          >
                            <p
                              className="help-paragraph mb-2"
                              style={{ marginTop: -20, marginLeft: 10 }}
                            >
                              Due Date: Select a due date from the calendar to
                              find vehicles with pending tasks or obligations.
                            </p>
                          </li>
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        Adding a New Inspection
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="heading-list ">
                          To add a new inspection, simply click on the "New
                          Inspection" button. You'll be directed to a form where
                          you can enter the necessary details about the
                          inspection. After filling out the form, click on the
                          "Submit" button. The system will then redirect you to
                          view the inspection details, allowing you to review
                          the information you entered.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>

                    <p className="help-paragraph">
                      We aim to provide you with a user-friendly experience to
                      effectively manage and search for vehicles. If you have
                      any further questions or require additional assistance,
                      please don't hesitate to reach out to our support team.
                    </p>
                  </Accordion>
                </div>
              )}
              {currentRoute === `/ReplayPlayback/${token1}` && (
                <div className="" id="help-main-wrapper">
                  <p className="help-paragraph mb-2">
                    In the Vehicle Inspection section, you can conveniently view
                    a comprehensive list of vehicles along with their relevant
                    details. Here's what you can do: Vehicle Name, Driver Name,
                    Inspection Done By, Vehicle Compliances Actions: Perform
                    actions such as viewing and editing the vehicle details.
                  </p>

                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        Searching for Vehicles
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          <p>
                            Our software provides a convenient search feature to
                            help you find specific vehicles. You can search by:
                          </p>

                          <li
                            style={{
                              color: "#703c19",

                              alignItems: "center",
                            }}
                          >
                            <p
                              className="help-paragraph mb-2"
                              style={{ marginTop: -20, marginLeft: 10 }}
                            >
                              Vehicle Name: Choose a vehicle name from the
                              dropdown list to quickly locate the desired
                              vehicle.
                            </p>
                          </li>
                          <li
                            style={{
                              color: "#703c19",

                              alignItems: "center",
                            }}
                          >
                            <p
                              className="help-paragraph mb-2"
                              style={{ marginTop: -20, marginLeft: 10 }}
                            >
                              Service Date: Select a service date from the
                              calendar to find vehicles serviced on a specific
                              day.
                            </p>
                          </li>
                          <li
                            style={{
                              color: "#703c19",

                              alignItems: "center",
                            }}
                          >
                            <p
                              className="help-paragraph mb-2"
                              style={{ marginTop: -20, marginLeft: 10 }}
                            >
                              Order Number: Enter an order number to search for
                              a particular vehicle.
                            </p>
                          </li>
                          <li
                            style={{
                              color: "#703c19",

                              alignItems: "center",
                            }}
                          >
                            <p
                              className="help-paragraph mb-2"
                              style={{ marginTop: -20, marginLeft: 10 }}
                            >
                              Due Date: Select a due date from the calendar to
                              find vehicles with pending tasks or obligations.
                            </p>
                          </li>
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        Adding a New Inspection
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="heading-list ">
                          To add a new inspection, simply click on the "New
                          Inspection" button. You'll be directed to a form where
                          you can enter the necessary details about the
                          inspection. After filling out the form, click on the
                          "Submit" button. The system will then redirect you to
                          view the inspection details, allowing you to review
                          the information you entered.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>

                    <p className="help-paragraph">
                      We aim to provide you with a user-friendly experience to
                      effectively manage and search for vehicles. If you have
                      any further questions or require additional assistance,
                      please don't hesitate to reach out to our support team.
                    </p>
                  </Accordion>
                </div>
              )}
              {currentRoute === "/FuelManagementDashbord" && (
                <div className="" id="help-main-wrapper">
                  <p className="help-paragraph mb-2">
                    The Dashboard provides a comprehensive overview of your
                    data. You can view the following sections: Summary: Get your
                    overall performance and key metrics.
                  </p>
                  <li
                    style={{
                      color: "#703c19",

                      alignItems: "center",
                    }}
                  >
                    <p
                      className="help-paragraph mb-2"
                      style={{ marginTop: -20, marginLeft: 10 }}
                    >
                      Top Spends
                    </p>
                  </li>
                  <li
                    style={{
                      color: "#703c19",

                      alignItems: "center",
                    }}
                  >
                    <p
                      className="help-paragraph mb-2"
                      style={{ marginTop: -20, marginLeft: 10 }}
                    >
                      Top Spends by Vehicle
                    </p>
                  </li>
                  <li
                    style={{
                      color: "#703c19",

                      alignItems: "center",
                    }}
                  >
                    <p
                      className="help-paragraph mb-2"
                      style={{ marginTop: -20, marginLeft: 10 }}
                    >
                      Top Spends by Drivers
                    </p>
                  </li>
                  <li
                    style={{
                      color: "#703c19",

                      alignItems: "center",
                    }}
                  >
                    <p
                      className="help-paragraph mb-2"
                      style={{ marginTop: -20, marginLeft: 10 }}
                    >
                      Top Spend Categories
                    </p>
                  </li>
                  <li
                    style={{
                      color: "#703c19",

                      alignItems: "center",
                    }}
                  >
                    <p
                      className="help-paragraph mb-2"
                      style={{ marginTop: -20, marginLeft: 10 }}
                    >
                      Top Spend Groups
                    </p>
                  </li>
                  <li
                    style={{
                      color: "#703c19",

                      alignItems: "center",
                    }}
                  >
                    <p
                      className="help-paragraph mb-2"
                      style={{ marginTop: -20, marginLeft: 10 }}
                    >
                      Top Spend Fuels
                    </p>
                  </li>
                </div>
              )}

              {currentRoute === `/FuelManagementDashbord/${token1}` && (
                <div className="" id="help-main-wrapper">
                  <p className="help-paragraph mb-2">
                    The Dashboard provides a comprehensive overview of your
                    data. You can view the following sections: Summary: Get your
                    overall performance and key metrics.
                  </p>
                  <li
                    style={{
                      color: "#703c19",

                      alignItems: "center",
                    }}
                  >
                    <p
                      className="help-paragraph mb-2"
                      style={{ marginTop: -20, marginLeft: 10 }}
                    >
                      Top Spends
                    </p>
                  </li>
                  <li
                    style={{
                      color: "#703c19",

                      alignItems: "center",
                    }}
                  >
                    <p
                      className="help-paragraph mb-2"
                      style={{ marginTop: -20, marginLeft: 10 }}
                    >
                      Top Spends by Vehicle
                    </p>
                  </li>
                  <li
                    style={{
                      color: "#703c19",

                      alignItems: "center",
                    }}
                  >
                    <p
                      className="help-paragraph mb-2"
                      style={{ marginTop: -20, marginLeft: 10 }}
                    >
                      Top Spends by Drivers
                    </p>
                  </li>
                  <li
                    style={{
                      color: "#703c19",

                      alignItems: "center",
                    }}
                  >
                    <p
                      className="help-paragraph mb-2"
                      style={{ marginTop: -20, marginLeft: 10 }}
                    >
                      Top Spend Categories
                    </p>
                  </li>
                  <li
                    style={{
                      color: "#703c19",

                      alignItems: "center",
                    }}
                  >
                    <p
                      className="help-paragraph mb-2"
                      style={{ marginTop: -20, marginLeft: 10 }}
                    >
                      Top Spend Groups
                    </p>
                  </li>
                  <li
                    style={{
                      color: "#703c19",

                      alignItems: "center",
                    }}
                  >
                    <p
                      className="help-paragraph mb-2"
                      style={{ marginTop: -20, marginLeft: 10 }}
                    >
                      Top Spend Fuels
                    </p>
                  </li>
                </div>
              )}

              {currentRoute === "/FuelManagementDetails" && (
                <div className="" id="help-main-wrapper">
                  <p className="help-paragraph mb-2">
                    The Fuel Detail section focuses specifically on fuel-related
                    information. It includes: Summary: Get an overview of your
                    fuel-related data, such as total fuel consumption and
                    expenses.
                  </p>
                  <li
                    style={{
                      color: "#703c19",

                      alignItems: "center",
                    }}
                  >
                    <p
                      className="help-paragraph mb-2"
                      style={{ marginTop: -20, marginLeft: 10 }}
                    >
                      Top Spends
                    </p>
                  </li>
                  <li
                    style={{
                      color: "#703c19",

                      alignItems: "center",
                    }}
                  >
                    <p
                      className="help-paragraph mb-2"
                      style={{ marginTop: -20, marginLeft: 10 }}
                    >
                      Top Spends by Vehicle
                    </p>
                  </li>
                  <li
                    style={{
                      color: "#703c19",

                      alignItems: "center",
                    }}
                  >
                    <p
                      className="help-paragraph mb-2"
                      style={{ marginTop: -20, marginLeft: 10 }}
                    >
                      Top Spends by Drivers
                    </p>
                  </li>
                </div>
              )}

              {currentRoute === "/FuelAlerts" && (
                <div className="" id="help-main-wrapper">
                  <p className="help-paragraph mb-2">
                    The Fuel Alerts section notifies you about abnormal fuel
                    consumption and expenses. It includes:
                  </p>

                  <p className="help-paragraph mb-2">
                    Abnormal Fuel Consumption Listings: View a detailed list of
                    instances where fuel consumption deviates from the expected
                    patterns. Each listing includes information about the
                    vehicle, driver, abnormal activity date, and status.
                  </p>
                  <p className="help-paragraph mb-2">
                    Abnormal Group Expense Listings: Identify abnormal expense
                    patterns within specific groups. Each listing includes
                    information about the vehicle, driver, abnormal activity
                    date, and status.
                  </p>
                  <p className="help-paragraph mb-2">
                    We aim to provide you with a user-friendly experience to
                    effectively monitor and manage your data. If you have any
                    further questions or require additional assistance, please
                    don't hesitate to reach out to our support team.
                  </p>
                </div>
              )}
              {currentRoute === "/VehicleAccident" && (
                <div className="" id="help-main-wrapper">
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header> a.Accident:</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          <p style={{ marginBottom: "10px" }}>
                            Welcome to the Accident Management module! Here, you
                            can efficiently handle and keep track of accidents
                            related to vehicles. Let's explore the key
                            functionalities:
                          </p>
                          <li
                            style={{
                              color: "#703c19",

                              alignItems: "center",
                            }}
                          >
                            <p
                              className="help-paragraph mb-2"
                              style={{ marginTop: -20, marginLeft: 10 }}
                            >
                              View Accidents:
                            </p>
                          </li>
                          <p style={{ marginBottom: "10px" }}>
                            You can conveniently browse through a list of
                            Vehicle accidents, which includes essential details
                            such as the vehicle name, location, description,
                            expenses, and date. In the "Action" column, you have
                            the option to view the complete accident details,
                            make edits if necessary, or delete accidents as
                            required.
                          </p>
                          <li
                            style={{
                              color: "#703c19",

                              alignItems: "center",
                            }}
                          >
                            <p
                              className="help-paragraph mb-2"
                              style={{ marginTop: -20, marginLeft: 10 }}
                            >
                              Search vehicle Accidents:
                            </p>
                          </li>
                          <p style={{ marginBottom: "10px" }}>
                            To find specific accidents, simply utilize the
                            search feature. Enter the vehicle name, vehicle
                            trip, location description, or select a date from
                            the calendar. The system will then display the
                            relevant accident listings based on your search
                            criteria.
                          </p>
                          <li
                            style={{
                              color: "#703c19",

                              alignItems: "center",
                            }}
                          >
                            <p
                              className="help-paragraph mb-2"
                              style={{ marginTop: -20, marginLeft: 10 }}
                            >
                              Add Accident:
                            </p>
                          </li>
                          <p style={{ marginBottom: "10px" }}>
                            If you need to add a new accident, click on the "Add
                            Accident" button. This will open a form where you
                            can enter all the relevant information about the
                            accident. Additionally, you have the option to
                            upload any related documents for reference. Once you
                            have filled out the form and uploaded the necessary
                            files, click on the "Submit" button to save the
                            accident entry.
                          </p>
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>

                    <p className="help-paragraph">
                      By using the Accident Management module, you can
                      effectively manage vehicle accidents, access detailed
                      information, perform searches, and maintain an organized
                      record of all incidents.
                    </p>
                  </Accordion>
                </div>
              )}

              {currentRoute === `/VehicleAccident/${token1}` && (
                <div className="" id="help-main-wrapper">
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header> a.Accident:</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          <p style={{ marginBottom: "10px" }}>
                            Welcome to the Accident Management module! Here, you
                            can efficiently handle and keep track of accidents
                            related to vehicles. Let's explore the key
                            functionalities:
                          </p>
                          <li
                            style={{
                              color: "#703c19",

                              alignItems: "center",
                            }}
                          >
                            <p
                              className="help-paragraph mb-2"
                              style={{ marginTop: -20, marginLeft: 10 }}
                            >
                              View Accidents:
                            </p>
                          </li>
                          <p style={{ marginBottom: "10px" }}>
                            You can conveniently browse through a list of
                            Vehicle accidents, which includes essential details
                            such as the vehicle name, location, description,
                            expenses, and date. In the "Action" column, you have
                            the option to view the complete accident details,
                            make edits if necessary, or delete accidents as
                            required.
                          </p>
                          <li
                            style={{
                              color: "#703c19",

                              alignItems: "center",
                            }}
                          >
                            <p
                              className="help-paragraph mb-2"
                              style={{ marginTop: -20, marginLeft: 10 }}
                            >
                              Search vehicle Accidents:
                            </p>
                          </li>
                          <p style={{ marginBottom: "10px" }}>
                            To find specific accidents, simply utilize the
                            search feature. Enter the vehicle name, vehicle
                            trip, location description, or select a date from
                            the calendar. The system will then display the
                            relevant accident listings based on your search
                            criteria.
                          </p>
                          <li
                            style={{
                              color: "#703c19",

                              alignItems: "center",
                            }}
                          >
                            <p
                              className="help-paragraph mb-2"
                              style={{ marginTop: -20, marginLeft: 10 }}
                            >
                              Add Accident:
                            </p>
                          </li>
                          <p style={{ marginBottom: "10px" }}>
                            If you need to add a new accident, click on the "Add
                            Accident" button. This will open a form where you
                            can enter all the relevant information about the
                            accident. Additionally, you have the option to
                            upload any related documents for reference. Once you
                            have filled out the form and uploaded the necessary
                            files, click on the "Submit" button to save the
                            accident entry.
                          </p>
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>

                    <p className="help-paragraph">
                      By using the Accident Management module, you can
                      effectively manage vehicle accidents, access detailed
                      information, perform searches, and maintain an organized
                      record of all incidents.
                    </p>
                  </Accordion>
                </div>
              )}

              {currentRoute === "/VehicleAccesory" && (
                <div className="" id="help-main-wrapper">
                  <p className="help-paragraph">
                    <p style={{ marginBottom: "10px" }}>
                      Welcome to the Accessory module! Here, you can efficiently
                      handle and keep track of accessory related to vehicles.
                      Let's explore the key functionalities:
                    </p>
                    <li
                      style={{
                        color: "#703c19",

                        alignItems: "center",
                      }}
                    >
                      <p
                        className="help-paragraph mb-2"
                        style={{ marginTop: -20, marginLeft: 10 }}
                      >
                        View Accessory:
                      </p>
                    </li>
                    <p style={{ marginBottom: "10px" }}>
                      You can conveniently browse through a list of Vehicle
                      accessory, which includes essential details such as the
                      vehicle name,Accessory item, Quantity, price, purchase
                      date. In the "Action" column, you have the option to view
                      the complete accessory details, make edits if necessary,
                      or delete accessory as required.
                    </p>
                    <li
                      style={{
                        color: "#703c19",

                        alignItems: "center",
                      }}
                    >
                      <p
                        className="help-paragraph mb-2"
                        style={{ marginTop: -20, marginLeft: 10 }}
                      >
                        Search Accessory:
                      </p>
                    </li>
                    <p style={{ marginBottom: "10px" }}>
                      To find specific accessory, simply utilize the search
                      feature. Enter the vehicle name, Accessory item, quantity,
                      price, or select a date from the calendar. The system will
                      then display the relevant accessory listings based on your
                      search criteria.
                    </p>
                    <li
                      style={{
                        color: "#703c19",

                        alignItems: "center",
                      }}
                    >
                      <p
                        className="help-paragraph mb-2"
                        style={{ marginTop: -20, marginLeft: 10 }}
                      >
                        Add Accessory:
                      </p>
                    </li>
                    <p style={{ marginBottom: "10px" }}>
                      If you need to add a new accessory, click on the "Add
                      Accessory" button. This will open a form where you can
                      enter all the relevant information about the accessory.
                      Additionally, you have the option to upload any related
                      documents for reference. Once you have filled out the form
                      and uploaded the necessary files, click on the "Submit"
                      button to save the accessory entry.
                    </p>
                  </p>

                  <p className="help-paragraph">
                    By using the Accessory Management module, you can
                    effectively manage vehicle accessory, access detailed
                    information, perform searches, and maintain an organized
                    record of all incidents.
                  </p>
                </div>
              )}
              {currentRoute === "/VehicleSpareParts" && (
                <div className="" id="help-main-wrapper">
                  <p className="help-paragraph">
                    <p style={{ marginBottom: "10px" }}>
                      Welcome to the Spare parts module! Here, you can
                      efficiently handle and keep track of Spare parts related
                      to vehicles. Let's explore the key functionalities:
                    </p>
                    <li
                      style={{
                        color: "#703c19",

                        alignItems: "center",
                      }}
                    >
                      <p
                        className="help-paragraph mb-2"
                        style={{ marginTop: -20, marginLeft: 10 }}
                      >
                        View Spare parts:
                      </p>
                    </li>
                    <p style={{ marginBottom: "10px" }}>
                      You can conveniently browse through a list of Vehicle
                      Spare parts, which includes essential details such as the
                      vehicle name, spare part, warranty, quantity, amount,
                      purchase date. In the "Action" column, you have the option
                      to view the complete spare parts details, make edits if
                      necessary, or delete spare parts as required.
                    </p>
                    <li
                      style={{
                        color: "#703c19",

                        alignItems: "center",
                      }}
                    >
                      <p
                        className="help-paragraph mb-2"
                        style={{ marginTop: -20, marginLeft: 10 }}
                      >
                        Search Spare parts:
                      </p>
                    </li>
                    <p style={{ marginBottom: "10px" }}>
                      To find a specific spare part, simply utilize the search
                      feature. Enter the vehicle name, Spare parts, Warranty,
                      quantity, Amount. The system will then display the
                      relevant spare parts listings based on your search
                      criteria.
                    </p>
                    <li
                      style={{
                        color: "#703c19",

                        alignItems: "center",
                      }}
                    >
                      <p
                        className="help-paragraph mb-2"
                        style={{ marginTop: -20, marginLeft: 10 }}
                      >
                        Add Spare parts:
                      </p>
                    </li>
                    <p style={{ marginBottom: "10px" }}>
                      If you need to add a new Spare parts, click on the "Add
                      Spare Part" button. This will open a form where you can
                      enter all the relevant information about the spare part.
                      Additionally, you have the option to upload any related
                      documents for reference. Once you have filled out the form
                      and uploaded the necessary files, click on the "Submit"
                      button to save the spare part entry.
                    </p>
                  </p>

                  <p className="help-paragraph">
                    By using the spare part Management module, you can
                    effectively manage vehicle spare part, access detailed
                    information, perform searches, and maintain an organized
                    record of all incidents.
                  </p>
                </div>
              )}
              {currentRoute === "/VehicleFine" && (
                <div className="" id="help-main-wrapper">
                  <p className="help-paragraph">
                    <p style={{ marginBottom: "10px" }}>
                      Welcome to the Fine module! Here, you can efficiently
                      handle and keep track of Fine related to vehicles. Let's
                      explore the key functionalities:
                    </p>
                    <li
                      style={{
                        color: "#703c19",

                        alignItems: "center",
                      }}
                    >
                      <p
                        className="help-paragraph mb-2"
                        style={{ marginTop: -20, marginLeft: 10 }}
                      >
                        View Fine:
                      </p>
                    </li>
                    <p style={{ marginBottom: "10px" }}>
                      You can conveniently browse through a list of Vehicle
                      Fine, which includes essential details such as the vehicle
                      name, Reason, Status, Amount date. In the "Action" column,
                      you have the option to view the complete Fine details,
                      make edits if necessary, or delete Fines as required.
                    </p>
                    <li
                      style={{
                        color: "#703c19",

                        alignItems: "center",
                      }}
                    >
                      <p
                        className="help-paragraph mb-2"
                        style={{ marginTop: -20, marginLeft: 10 }}
                      >
                        Search Fine:
                      </p>
                    </li>
                    <p style={{ marginBottom: "10px" }}>
                      To find specific Fine, simply utilize the search feature.
                      Enter the vehicle name, Reason, status, amount or select a
                      date from the calendar. The system will then display the
                      relevant fine listings based on your search criteria.
                    </p>
                    <li
                      style={{
                        color: "#703c19",

                        alignItems: "center",
                      }}
                    >
                      <p
                        className="help-paragraph mb-2"
                        style={{ marginTop: -20, marginLeft: 10 }}
                      >
                        Add Fine:
                      </p>
                    </li>
                    <p style={{ marginBottom: "10px" }}>
                      If you need to add a new Fine, click on the "Add Fine"
                      button. This will open a form where you can enter all the
                      relevant information about the Fine. Additionally, you
                      have the option to upload any related documents for
                      reference. Once you have filled out the form and uploaded
                      the necessary files, click on the "Submit" button to save
                      the Fine entry.
                    </p>
                  </p>

                  <p className="help-paragraph">
                    By using the Fine Management module, you can effectively
                    manage vehicle Fine, access detailed information, perform
                    searches, and maintain an organized record of all incidents.
                  </p>
                </div>
              )}
              {currentRoute === "/VehicleMaintenance" && (
                <div className="" id="help-main-wrapper">
                  <p className="help-paragraph">
                    <p style={{ marginBottom: "10px" }}>
                      Welcome to the Vehicle Maintenance module! Here, you can
                      easily manage and keep track of maintenance tasks for your
                      vehicles. Let's explore the key features:
                    </p>
                    <p style={{ marginBottom: "10px" }}>
                      {" "}
                      a. View Maintenance Sections: The module is divided into
                      four sections: All, Dues, Upcoming, and History.
                    </p>
                    <li
                      style={{
                        color: "#703c19",

                        alignItems: "center",
                      }}
                    >
                      <p
                        className="help-paragraph mb-2"
                        style={{ marginTop: -20, marginLeft: 10 }}
                      >
                        All: In this section, you can view a comprehensive list
                        of all vehicles along with their respective details such
                        as vehicle name, service date, kilometers driven, due
                        date, and service cost. In the "Action" column, you have
                        the option to view the complete maintenance details for
                        each vehicle and make edits if necessary.
                      </p>
                    </li>

                    <li
                      style={{
                        color: "#703c19",

                        alignItems: "center",
                      }}
                    >
                      <p
                        className="help-paragraph mb-2"
                        style={{ marginTop: -20, marginLeft: 10 }}
                      >
                        Dues: In this section, you can view a comprehensive list
                        of dues of vehicles along with their respective details
                        such as vehicle name, service date, kilometers driven,
                        due date, and service cost. In the "Action" column, you
                        have the option to view the complete maintenance details
                        for each vehicle and make edits if necessary.
                      </p>
                    </li>

                    <li
                      style={{
                        color: "#703c19",

                        alignItems: "center",
                      }}
                    >
                      <p
                        className="help-paragraph mb-2"
                        style={{ marginTop: -20, marginLeft: 10 }}
                      >
                        Upcoming: In this section, you can view a comprehensive
                        list of all upcoming vehicles along with their
                        respective details such as vehicle name, service date,
                        kilometers driven, due date, and service cost. In the
                        "Action" column, you have the option to view the
                        complete maintenance details for each vehicle and make
                        edits if necessary.
                      </p>
                    </li>
                    <li
                      style={{
                        color: "#703c19",

                        alignItems: "center",
                      }}
                    >
                      <p
                        className="help-paragraph mb-2"
                        style={{ marginTop: -20, marginLeft: 10 }}
                      >
                        History: In this section, you can view a comprehensive
                        list of all history of vehicles along with their
                        respective details such as vehicle name, service date,
                        kilometers driven, due date, and service cost. In the
                        "Action" column, you have the option to view the
                        complete maintenance details for each vehicle and make
                        edits if necessary.
                      </p>
                    </li>
                    <li
                      style={{
                        color: "#703c19",

                        alignItems: "center",
                      }}
                    >
                      <p
                        className="help-paragraph mb-2"
                        style={{ marginTop: -20, marginLeft: 10 }}
                      >
                        b. Search Functionality: To quickly find specific
                        vehicles or maintenance records, you can utilize the
                        search feature. You can search by vehicle name from the
                        dropdown list, select a date from the calendar, or enter
                        the kilometers driven. The system will then display the
                        relevant results based on your search criteria.
                      </p>
                    </li>
                    <li
                      style={{
                        color: "#703c19",

                        alignItems: "center",
                      }}
                    >
                      <p
                        className="help-paragraph mb-2"
                        style={{ marginTop: -20, marginLeft: 10 }}
                      >
                        c. Add New Maintenance: To add a new maintenance entry,
                        simply click on the "Add New" button. This will open a
                        form where you can fill in all the necessary information
                        regarding the maintenance task. Once you have provided
                        the required details, click on the "Submit" button to
                        save the maintenance entry.
                      </p>
                    </li>
                  </p>

                  <p className="help-paragraph">
                    By using the Vehicle Maintenance module, you can efficiently
                    monitor and manage the maintenance needs of your vehicles.
                    Stay on top of upcoming tasks, track service history, and
                    ensure that your vehicles are well-maintained for optimal
                    performance.
                  </p>
                </div>
              )}
              {currentRoute === "/FuelExpenses" && (
                <div className="" id="help-main-wrapper">
                  <p className="help-paragraph">
                    <p style={{ marginBottom: "10px" }}>
                      Welcome to the Fuel Expense module! Here, you can
                      efficiently handle and keep track of Fine related to
                      vehicles. Let's explore the key functionalities:
                    </p>

                    <li
                      style={{
                        color: "#703c19",

                        alignItems: "center",
                      }}
                    >
                      <p
                        className="help-paragraph mb-2"
                        style={{ marginTop: -20, marginLeft: 10 }}
                      >
                        View Fuel Expense:
                      </p>
                    </li>
                    <p className="help-paragraph mb-2">
                      You can conveniently browse through a list of Vehicle Fuel
                      Expense, which includes essential details such as the
                      vehicle number, current odometer, quantity, amount, date.
                      In the "Action" column, you have the option to view the
                      complete Fuel Expense details, make edits if necessary, or
                      delete Fuel Expense as required.
                    </p>
                    <li
                      style={{
                        color: "#703c19",

                        alignItems: "center",
                      }}
                    >
                      <p
                        className="help-paragraph mb-2"
                        style={{ marginTop: -20, marginLeft: 10 }}
                      >
                        Search Fuel Expense:
                      </p>
                    </li>
                    <p className="help-paragraph mb-2">
                      To find specific Fuel Expense, simply utilize the search
                      feature. Enter the vehicle name, Current Odometer,
                      quantity, amount or select a date from the calendar. The
                      system will then display the relevant Fuel Expense
                      listings based on your search criteria.
                    </p>
                    <li
                      style={{
                        color: "#703c19",

                        alignItems: "center",
                      }}
                    >
                      <p
                        className="help-paragraph mb-2"
                        style={{ marginTop: -20, marginLeft: 10 }}
                      >
                        Add Fuel Expense:
                      </p>
                    </li>
                    <p className="help-paragraph mb-2">
                      If you need to add a new Fuel Expenses, click on the "Add
                      Fuel Expense" button. This will open a form where you can
                      enter all the relevant information about the Fuel Expense.
                      Additionally, you have the option to upload any related
                      documents for reference. Once you have filled out the form
                      and uploaded the necessary files, click on the "Submit"
                      button to save the Fuel Expense entry.
                    </p>
                  </p>

                  <p className="help-paragraph">
                    By using the Fuel Expense Management module, you can
                    effectively manage vehicle Fuel Expense, access detailed
                    information, perform searches, and maintain an organized
                    record of all incidents.
                  </p>
                </div>
              )}
              {currentRoute === "/Merchant" && (
                <div className="" id="help-main-wrapper">
                  <p className="help-paragraph mb-2">
                    Welcome to the "Help" module of our software! In this
                    section, we will guide you on how to effectively use the
                    Merchant feature. The Merchant feature allows you to search
                    for and manage merchants within the system. Let's explore
                    the different functionalities available:
                  </p>

                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        Searching for Merchants:
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          <p>
                            You can easily search for merchants using various
                            search criteria:
                          </p>

                          <p>
                            a) Merchant Name: Enter the name of the merchant you
                            are looking for in the search field.
                          </p>
                          <p>
                            b) Merchant ID: If you know the ID of the merchant,
                            enter it in the search field.
                          </p>
                          <p>
                            {" "}
                            b) By Customer Code: You can enter the customer code
                            directly into the search field.
                          </p>
                          <p>
                            c) Warehouse Name: If you want to find merchants
                            associated with a specific warehouse, enter the
                            warehouse name in the search field.
                          </p>
                          <p>
                            d) Warehouse ID: If you have the ID of a warehouse,
                            you can search for merchants linked to that
                            warehouse.
                          </p>
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        b. Viewing Merchant Listings:
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="heading-list ">
                          When you perform a search, you will see a list of
                          matching merchants. The list will display important
                          information such as the merchant name, ID, and the
                          number of associated warehouses. To access more
                          options, click on the three dots next to a merchant's
                          listing. From here, you can choose to view more
                          details, edit merchant details, or delete the merchant
                          if needed.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        c. Creating a New Merchant:
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          To create a new merchant, click on the "Add Merchant"
                          button. This will open a form where you can enter all
                          the necessary details about the merchant. Fill in the
                          required information and click on the "Submit" button
                          to create the merchant. The newly created merchant
                          will then be included in the merchant listings.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <p className="help-paragraph" style={{ marginTop: "10px" }}>
                      That's it! You now have a better understanding of how to
                      use the Merchant feature in our software. If you have any
                      further questions or need additional assistance, please
                      don't hesitate to reach out to our support team.
                    </p>
                  </Accordion>
                </div>
              )}
              {currentRoute === "/Dispatchcustumor" && (
                <div className="" id="help-main-wrapper">
                  <p className="help-paragraph mb-2">
                    Welcome to the "Help" module of our software! Here, we will
                    guide you on how to effectively use the Dispatch Customer
                    feature. This feature allows you to manage and search for
                    customers easily. Let's get started!
                  </p>

                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        Searching for Customers:
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          <p>To search for a customer, you have two options:</p>

                          <p>
                            a) By Vehicle Name: You can select the desired
                            vehicle name from the dropdown menu.{" "}
                          </p>
                          <p>
                            {" "}
                            b) By Customer Code: You can enter the customer code
                            directly into the search field.
                          </p>
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        b. Viewing Customer Listings:
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="heading-list ">
                          Once you have performed a search, you will be
                          presented with a list of customers. The customer
                          listings will include essential information such as
                          the customer's name, customer code, email address, and
                          mobile number. You can conveniently view, edit, or
                          delete customer details using the available action
                          buttons.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        Adding a New Customer:
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          To add a new customer, simply click on the "Add
                          Customer" button. A form will appear, prompting you to
                          fill in the necessary information. Additionally, you
                          can add multiple addresses for a customer, ensuring
                          accurate delivery details. Once you have completed the
                          form, click on the "Submit" button to save the
                          customer's information.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <p className="help-paragraph" style={{ marginTop: "10px" }}>
                      That's it! You are now equipped with the knowledge to
                      effectively use the Dispatch Customer feature. If you have
                      any further questions or need additional assistance,
                      please don't hesitate to reach out.
                    </p>
                  </Accordion>
                </div>
              )}

              {currentRoute === "/DispatchOrder" && (
                <div className="" id="help-main-wrapper">
                  <p className="help-paragraph mb-2">
                    Welcome to the "Help" module of our software! In this
                    section, we will guide you on how to effectively use the
                    Orders feature. This feature allows you to manage and track
                    your orders easily. Let's dive in!
                  </p>

                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        {" "}
                        a. Searching for Orders:
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          <p>
                            To search for a specific order, you have two
                            options:
                          </p>

                          <p>
                            a) By Order Number: You can enter the order number
                            directly into the search field.
                          </p>
                          <p>
                            b) By Customer Name and Order Status: You can select
                            the desired customer name and order status from the
                            dropdown menus.
                          </p>
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        b. Viewing Order Listings:
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="heading-list ">
                          Once you have performed a search, you will be
                          presented with a list of orders. The order listings
                          will include important details such as the order
                          number, customer name, order amount, order status, and
                          order date & time. You can conveniently view the order
                          details by clicking on the respective order.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>c. Manual Routing:</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          If you need to manually route an order, you can click
                          on the "Manual Routing" button. This feature allows
                          you to visualize the customer and merchant locations
                          on a map. Utilize the map functionality to mark zones
                          or areas. You can mark areas by drawing freehand,or
                          selecting by square, or by a circle. Furthermore, you
                          have the ability to edit or delete existing marks on
                          the Google Map. you can create a trip by providing
                          detailed trip information. This way, you can
                          effectively plan and manage your deliveries.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <p className="help-paragraph" style={{ marginTop: "10px" }}>
                      That's it! You now have a better understanding of how to
                      use the Orders feature in our software. If you have any
                      further questions or need additional assistance, please
                      feel free to reach out. We are here to help you make the
                      most out of our software.
                    </p>
                  </Accordion>
                </div>
              )}
              {currentRoute === "/Reports" && (
                <div className="" id="help-main-wrapper">
                  <p className="help-paragraph mb-2">
                    This module is divided into three sections to cater to your
                    reporting needs: Default Reports, Scheduled Reports, and
                    Customized Reports. Let's explore each section:
                  </p>

                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header> A. Default Reports</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          <p>
                            To search for a specific order, you have two
                            options:
                          </p>

                          <p>
                            a) By Order Number: You can enter the order number
                            directly into the search field.
                          </p>
                          <p>
                            b) By Customer Name and Order Status: You can select
                            the desired customer name and order status from the
                            dropdown menus.
                          </p>
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        b. Viewing Order Listings:
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="heading-list ">
                          Once you have performed a search, you will be
                          presented with a list of orders. The order listings
                          will include important details such as the order
                          number, customer name, order amount, order status, and
                          order date & time. You can conveniently view the order
                          details by clicking on the respective order.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>c. Manual Routing:</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          If you need to manually route an order, you can click
                          on the "Manual Routing" button. This feature allows
                          you to visualize the customer and merchant locations
                          on a map. Utilize the map functionality to mark zones
                          or areas. You can mark areas by drawing freehand,or
                          selecting by square, or by a circle. Furthermore, you
                          have the ability to edit or delete existing marks on
                          the Google Map. you can create a trip by providing
                          detailed trip information. This way, you can
                          effectively plan and manage your deliveries.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <p className="help-paragraph" style={{ marginTop: "10px" }}>
                      That's it! You now have a better understanding of how to
                      use the Orders feature in our software. If you have any
                      further questions or need additional assistance, please
                      feel free to reach out. We are here to help you make the
                      most out of our software.
                    </p>
                  </Accordion>
                </div>
              )}

              {currentRoute === `/Reports/${token1}` && (
                <div className="" id="help-main-wrapper">
                  <p className="help-paragraph mb-2">
                    This module is divided into three sections to cater to your
                    reporting needs: Default Reports, Scheduled Reports, and
                    Customized Reports. Let's explore each section:
                  </p>

                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header> A. Default Reports</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          <p>
                            To search for a specific order, you have two
                            options:
                          </p>

                          <p>
                            a) By Order Number: You can enter the order number
                            directly into the search field.
                          </p>
                          <p>
                            b) By Customer Name and Order Status: You can select
                            the desired customer name and order status from the
                            dropdown menus.
                          </p>
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        b. Viewing Order Listings:
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="heading-list ">
                          Once you have performed a search, you will be
                          presented with a list of orders. The order listings
                          will include important details such as the order
                          number, customer name, order amount, order status, and
                          order date & time. You can conveniently view the order
                          details by clicking on the respective order.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>c. Manual Routing:</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          If you need to manually route an order, you can click
                          on the "Manual Routing" button. This feature allows
                          you to visualize the customer and merchant locations
                          on a map. Utilize the map functionality to mark zones
                          or areas. You can mark areas by drawing freehand,or
                          selecting by square, or by a circle. Furthermore, you
                          have the ability to edit or delete existing marks on
                          the Google Map. you can create a trip by providing
                          detailed trip information. This way, you can
                          effectively plan and manage your deliveries.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <p className="help-paragraph" style={{ marginTop: "10px" }}>
                      That's it! You now have a better understanding of how to
                      use the Orders feature in our software. If you have any
                      further questions or need additional assistance, please
                      feel free to reach out. We are here to help you make the
                      most out of our software.
                    </p>
                  </Accordion>
                </div>
              )}

              {currentRoute === "/IVMS" && (
                <div className="" id="help-main-wrapper">
                  <p className="help-paragraph mb-2">
                    You can view Sync listings categorized into sections:Echo
                    driving, IVMS, Card Reader, Over speeding, Crash detection,
                    Excessive Idling, Towing detection, un plug detection,
                    Immobilization, Immobilization 1, Temperature Sensors, Fuel
                    sensor.
                  </p>

                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Echo Driving:</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          <p>
                            Use the search bar to search for vehicles by
                            entering details such as vehicle name, vehicle
                            number, and vehicle IM. You can also search by
                            vehicle category, vehicle capacity, and
                            transportation type using the provided filters. In
                            this section, you can view all the vehicles
                            associated with the software. To add the Echo
                            Driving feature to a specific vehicle, simply toggle
                            the feature on/off for that vehicle.
                          </p>
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        IVMS (In-Vehicle Monitoring System):
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="heading-list ">
                          Use the search bar to search for vehicles by entering
                          details such as vehicle name, vehicle number, and
                          vehicle IM. You can also search by vehicle category,
                          vehicle capacity, and transportation type using the
                          provided filters. Similar to Echo Driving, you can
                          view all the vehicles in this section. Toggle the IVMS
                          feature on/off for a particular vehicle to enable or
                          disable it.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>Card Reader:</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          Use the search bar to search for vehicles by entering
                          details such as vehicle name, vehicle number, and
                          vehicle IM. You can also search by vehicle category,
                          vehicle capacity, and transportation type using the
                          provided filters. In the Card Reader section, you can
                          see a list of vehicles. Toggle the Card Reader feature
                          on/off for a specific vehicle to activate or
                          deactivate it.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="3">
                      <Accordion.Header>Over Speeding:</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          Use the search bar to search for vehicles by entering
                          details such as vehicle name, vehicle number, and
                          vehicle IM. You can also search by vehicle category,
                          vehicle capacity, and transportation type using the
                          provided filters. This section displays all the
                          vehicles available. Toggle the Over Speeding feature
                          on/off for a particular vehicle to enable or disable
                          it.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header>Crash Detection:</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          Use the search bar to search for vehicles by entering
                          details such as vehicle name, vehicle number, and
                          vehicle IM. You can also search by vehicle category,
                          vehicle capacity, and transportation type using the
                          provided filters. Here, you can view the list of
                          vehicles. Toggle the Crash Detection feature on/off
                          for a specific vehicle to activate or deactivate it.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="5">
                      <Accordion.Header>Excessive Idling:</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          Use the search bar to search for vehicles by entering
                          details such as vehicle name, vehicle number, and
                          vehicle IM. You can also search by vehicle category,
                          vehicle capacity, and transportation type using the
                          provided filters. In the Excessive Idling section,
                          you'll find the vehicles listed. Toggle the Excessive
                          Idling feature on/off for a particular vehicle to
                          enable or disable it.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                      <Accordion.Header>Towing Detection:</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          Use the search bar to search for vehicles by entering
                          details such as vehicle name, vehicle number, and
                          vehicle IM. You can also search by vehicle category,
                          vehicle capacity, and transportation type using the
                          provided filters. Similarly, you can view the vehicles
                          in this section. Toggle the Towing Detection feature
                          on/off for a specific vehicle to activate or
                          deactivate it.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="7">
                      <Accordion.Header>Unplug Detection:</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          Use the search bar to search for vehicles by entering
                          details such as vehicle name, vehicle number, and
                          vehicle IM. You can also search by vehicle category,
                          vehicle capacity, and transportation type using the
                          provided filters. Here, you'll see the list of
                          vehicles. Toggle the Unplug Detection feature on/off
                          for a particular vehicle to enable or disable it.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="8">
                      <Accordion.Header>Immobilization:</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          Use the search bar to search for vehicles by entering
                          details such as vehicle name, vehicle number, and
                          vehicle IM. You can also search by vehicle category,
                          vehicle capacity, and transportation type using the
                          provided filters. In this section, you can view all
                          the vehicles. Toggle the Immobilization feature on/off
                          for a specific vehicle to activate or deactivate it.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="9">
                      <Accordion.Header>Immobilization 1:</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          Use the search bar to search for vehicles by entering
                          details such as vehicle name, vehicle number, and
                          vehicle IM. You can also search by vehicle category,
                          vehicle capacity, and transportation type using the
                          provided filters. Similar to the previous feature, you
                          can view the vehicles in this section. Toggle the
                          Immobilization 1 feature on/off for a particular
                          vehicle to enable or disable it.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="10">
                      <Accordion.Header>Temperature Sensors:</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          Use the search bar to search for vehicles by entering
                          details such as vehicle name, vehicle number, and
                          vehicle IM. You can also search by vehicle category,
                          vehicle capacity, and transportation type using the
                          provided filters. Here, you can see the list of
                          vehicles. Toggle the Temperature Sensors feature
                          on/off for a specific vehicle to activate or
                          deactivate it.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="11">
                      <Accordion.Header>Fuel Sensor:</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          Use the search bar to search for vehicles by entering
                          details such as vehicle name, vehicle number, and
                          vehicle IM. You can also search by vehicle category,
                          vehicle capacity, and transportation type using the
                          provided filters. In the Fuel Sensor section, you'll
                          find the vehicles listed. Toggle the Fuel Sensor
                          feature on/off for a particular vehicle to enable or
                          disable it.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <p className="help-paragraph" style={{ marginTop: "10px" }}>
                      We hope this user-friendly guide helps you navigate and
                      utilize the Hardware Feature-Set effectively. If you have
                      any further questions or need additional assistance, feel
                      free to reach out to our support team.
                    </p>
                  </Accordion>
                </div>
              )}
              {currentRoute === `/IVMS/${token1}` && (
                <div className="" id="help-main-wrapper">
                  <p className="help-paragraph mb-2">
                    You can view Sync listings categorized into sections:Echo
                    driving, IVMS, Card Reader, Over speeding, Crash detection,
                    Excessive Idling, Towing detection, un plug detection,
                    Immobilization, Immobilization 1, Temperature Sensors, Fuel
                    sensor.
                  </p>

                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Echo Driving:</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          <p>
                            Use the search bar to search for vehicles by
                            entering details such as vehicle name, vehicle
                            number, and vehicle IM. You can also search by
                            vehicle category, vehicle capacity, and
                            transportation type using the provided filters. In
                            this section, you can view all the vehicles
                            associated with the software. To add the Echo
                            Driving feature to a specific vehicle, simply toggle
                            the feature on/off for that vehicle.
                          </p>
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        IVMS (In-Vehicle Monitoring System):
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="heading-list ">
                          Use the search bar to search for vehicles by entering
                          details such as vehicle name, vehicle number, and
                          vehicle IM. You can also search by vehicle category,
                          vehicle capacity, and transportation type using the
                          provided filters. Similar to Echo Driving, you can
                          view all the vehicles in this section. Toggle the IVMS
                          feature on/off for a particular vehicle to enable or
                          disable it.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>Card Reader:</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          Use the search bar to search for vehicles by entering
                          details such as vehicle name, vehicle number, and
                          vehicle IM. You can also search by vehicle category,
                          vehicle capacity, and transportation type using the
                          provided filters. In the Card Reader section, you can
                          see a list of vehicles. Toggle the Card Reader feature
                          on/off for a specific vehicle to activate or
                          deactivate it.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="3">
                      <Accordion.Header>Over Speeding:</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          Use the search bar to search for vehicles by entering
                          details such as vehicle name, vehicle number, and
                          vehicle IM. You can also search by vehicle category,
                          vehicle capacity, and transportation type using the
                          provided filters. This section displays all the
                          vehicles available. Toggle the Over Speeding feature
                          on/off for a particular vehicle to enable or disable
                          it.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header>Crash Detection:</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          Use the search bar to search for vehicles by entering
                          details such as vehicle name, vehicle number, and
                          vehicle IM. You can also search by vehicle category,
                          vehicle capacity, and transportation type using the
                          provided filters. Here, you can view the list of
                          vehicles. Toggle the Crash Detection feature on/off
                          for a specific vehicle to activate or deactivate it.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="5">
                      <Accordion.Header>Excessive Idling:</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          Use the search bar to search for vehicles by entering
                          details such as vehicle name, vehicle number, and
                          vehicle IM. You can also search by vehicle category,
                          vehicle capacity, and transportation type using the
                          provided filters. In the Excessive Idling section,
                          you'll find the vehicles listed. Toggle the Excessive
                          Idling feature on/off for a particular vehicle to
                          enable or disable it.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                      <Accordion.Header>Towing Detection:</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          Use the search bar to search for vehicles by entering
                          details such as vehicle name, vehicle number, and
                          vehicle IM. You can also search by vehicle category,
                          vehicle capacity, and transportation type using the
                          provided filters. Similarly, you can view the vehicles
                          in this section. Toggle the Towing Detection feature
                          on/off for a specific vehicle to activate or
                          deactivate it.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="7">
                      <Accordion.Header>Unplug Detection:</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          Use the search bar to search for vehicles by entering
                          details such as vehicle name, vehicle number, and
                          vehicle IM. You can also search by vehicle category,
                          vehicle capacity, and transportation type using the
                          provided filters. Here, you'll see the list of
                          vehicles. Toggle the Unplug Detection feature on/off
                          for a particular vehicle to enable or disable it.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="8">
                      <Accordion.Header>Immobilization:</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          Use the search bar to search for vehicles by entering
                          details such as vehicle name, vehicle number, and
                          vehicle IM. You can also search by vehicle category,
                          vehicle capacity, and transportation type using the
                          provided filters. In this section, you can view all
                          the vehicles. Toggle the Immobilization feature on/off
                          for a specific vehicle to activate or deactivate it.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="9">
                      <Accordion.Header>Immobilization 1:</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          Use the search bar to search for vehicles by entering
                          details such as vehicle name, vehicle number, and
                          vehicle IM. You can also search by vehicle category,
                          vehicle capacity, and transportation type using the
                          provided filters. Similar to the previous feature, you
                          can view the vehicles in this section. Toggle the
                          Immobilization 1 feature on/off for a particular
                          vehicle to enable or disable it.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="10">
                      <Accordion.Header>Temperature Sensors:</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          Use the search bar to search for vehicles by entering
                          details such as vehicle name, vehicle number, and
                          vehicle IM. You can also search by vehicle category,
                          vehicle capacity, and transportation type using the
                          provided filters. Here, you can see the list of
                          vehicles. Toggle the Temperature Sensors feature
                          on/off for a specific vehicle to activate or
                          deactivate it.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="11">
                      <Accordion.Header>Fuel Sensor:</Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          Use the search bar to search for vehicles by entering
                          details such as vehicle name, vehicle number, and
                          vehicle IM. You can also search by vehicle category,
                          vehicle capacity, and transportation type using the
                          provided filters. In the Fuel Sensor section, you'll
                          find the vehicles listed. Toggle the Fuel Sensor
                          feature on/off for a particular vehicle to enable or
                          disable it.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <p className="help-paragraph" style={{ marginTop: "10px" }}>
                      We hope this user-friendly guide helps you navigate and
                      utilize the Hardware Feature-Set effectively. If you have
                      any further questions or need additional assistance, feel
                      free to reach out to our support team.
                    </p>
                  </Accordion>
                </div>
              )}
              {currentRoute === "/Drivers" && (
                <div className="" id="help-main-wrapper">
                  <p className="help-paragraph mb-2">
                    Welcome to the Help module for our software'sDrivers
                    feature! Here's a user-friendly guide to help you understand
                    and use the different functionalities:
                  </p>

                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        {" "}
                        Add Driver: Add a new Driver, click on the "Add Driver"
                        button.
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          <p>
                            You will be directed to a form where you need to
                            provide the necessary details. After filling in the
                            required information, submit the form to add the
                            vehicle group to the system. You can view Driver
                            listings categorized into sections: All, Online,
                            Offline, and Untracked/Not Tracked Yet. Each section
                            offers different filters to help you find specific
                            driver.
                          </p>
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <li
                      style={{
                        color: "#703c19",

                        alignItems: "center",
                      }}
                    >
                      <p
                        className="help-paragraph mb-2"
                        style={{ marginTop: -20, marginLeft: 10 }}
                      >
                        All Section:
                      </p>
                    </li>
                    <p className="help-paragraph mb-2">
                      "All" section, you can view all drivers. Use the search
                      bar to search for drivers by entering details such as
                      vehicle name, vehicle number, and vehicle IM. You can also
                      search by vehicle category, vehicle capacity, and
                      transportation type using the provided filters.
                    </p>

                    <li
                      style={{
                        color: "#703c19",

                        alignItems: "center",
                      }}
                    >
                      <p
                        className="help-paragraph mb-2"
                        style={{ marginTop: -20, marginLeft: 10 }}
                      >
                        Online Section:
                      </p>
                    </li>
                    <p className="help-paragraph mb-2">
                      "Online" section, you can view all online drivers. Use the
                      search bar to search for online drivers by entering
                      details such as vehicle name, vehicle number, and vehicle
                      IM. You can also search by vehicle category, vehicle
                      capacity, and transportation type using the provided
                      filters.
                    </p>

                    <li
                      style={{
                        color: "#703c19",

                        alignItems: "center",
                      }}
                    >
                      <p
                        className="help-paragraph mb-2"
                        style={{ marginTop: -20, marginLeft: 10 }}
                      >
                        Offline Section:
                      </p>
                    </li>
                    <p className="help-paragraph mb-2">
                      "Offline" section, you can view all offline drivers. Use
                      the search bar to search for offline drivers by entering
                      details such as vehicle name, vehicle number, and vehicle
                      IM. You can also search by vehicle category, vehicle
                      capacity, and transportation type using the provided
                      filters.
                    </p>

                    <li
                      style={{
                        color: "#703c19",

                        alignItems: "center",
                      }}
                    >
                      <p
                        className="help-paragraph mb-2"
                        style={{ marginTop: -20, marginLeft: 10 }}
                      >
                        Untracked/Not Tracked Yet Section:
                      </p>
                    </li>
                    <p className="help-paragraph mb-2">
                      "Untracked/Not Tracked Yet" section, you can view all
                      Untracked/Not Tracked Yet drivers. Use the search bar to
                      search for Untracked/Not Tracked Yet drivers by entering
                      details such as vehicle name, vehicle number, and vehicle
                      IM. You can also search by vehicle category, vehicle
                      capacity, and transportation type using the provided
                      filters.
                    </p>

                    <p className="help-paragraph" style={{ marginTop: "10px" }}>
                      If you have any questions or need further assistance,
                      please don't hesitate to reach out to our support team.
                    </p>
                  </Accordion>
                </div>
              )}

              {currentRoute === "/ParkingManagement" && (
                <div className="" id="help-main-wrapper">
                  <p className="help-paragraph mb-2">
                    Welcome to the Help module for our software's Parking
                    Station feature!
                  </p>

                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        Searching for Parking Stations
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          <p>
                            You can search for parking stations by entering the
                            slot name, slot, and address in the respective text
                            boxes. The results will be displayed accordingly.
                          </p>
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Mapping the Location</Accordion.Header>
                      <Accordion.Body>
                        <p className="heading-list ">
                          You have the option to map the location of the parking
                          station on Google Maps. There are three ways to mark
                          the location:
                        </p>
                        <p className="help-paragraph ">
                          Freehand Marking: Use your cursor to draw a shape
                          representing the parking station area on the map.
                        </p>
                        <p className="help-paragraph ">
                          Placing a Square: Place a square marker on the map to
                          indicate the parking station location.
                        </p>
                        <p className="help-paragraph ">
                          Placing a Circle: Place a circular marker on the map
                          to indicate the parking station location.
                        </p>
                        <p className="help-paragraph ">
                          You can also edit or delete the mapped location if
                          needed.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        Parking Station Listings
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph mb-2">
                          All: You can view a list of all parking stations
                          available. Each listing includes the station name,
                          station code, and address.
                        </p>

                        <p className="help-paragraph ">
                          Suggested Parking Stations: You can see suggested
                          parking station addresses that match your search
                          criteria.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>
                        Adding a Parking Station
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph mb-2">
                          To add a new parking station, click on the "Add
                          Parking Station" button located on the right side of
                          the page. A form will appear.
                        </p>
                        <p className="heading-list">
                          Fill in the required details:
                        </p>
                        <p className="help-paragraph ">
                          Station Code: Enter the code for the parking station.
                        </p>
                        <p className="help-paragraph ">
                          Station Name: Provide the name of the parking station.
                        </p>
                        <p className="help-paragraph ">
                          Address: Enter the address where the parking station
                          is located.
                        </p>
                        <p className="help-paragraph ">
                          Parking Capacity: Specify the number of available
                          parking spaces.
                        </p>
                        <p className="help-paragraph ">
                          Vehicles: Indicate the types of vehicles allowed in
                          the parking station.
                        </p>
                        <p className="help-paragraph mb-2">
                          Map Location: Map the location of the parking station
                          on Google Maps using any of the available methods.
                        </p>
                        <p className="help-paragraph mb-2">
                          Once you've entered all the necessary information and
                          mapped the location, click the "Submit" button to add
                          the parking station to the system.
                        </p>
                        <p className="help-paragraph ">
                          We hope this guide helps you navigate and utilize the
                          Parking Station feature effectively. If you have any
                          further questions or need assistance, feel free to
                          reach out to our support team.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              )}

              {currentRoute === `/ParkingManagement/${token1}` && (
                <div className="" id="help-main-wrapper">
                  <p className="help-paragraph mb-2">
                    Welcome to the Help module for our software's Parking
                    Station feature!
                  </p>

                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        Searching for Parking Stations
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          <p>
                            You can search for parking stations by entering the
                            slot name, slot, and address in the respective text
                            boxes. The results will be displayed accordingly.
                          </p>
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Mapping the Location</Accordion.Header>
                      <Accordion.Body>
                        <p className="heading-list ">
                          You have the option to map the location of the parking
                          station on Google Maps. There are three ways to mark
                          the location:
                        </p>
                        <p className="help-paragraph ">
                          Freehand Marking: Use your cursor to draw a shape
                          representing the parking station area on the map.
                        </p>
                        <p className="help-paragraph ">
                          Placing a Square: Place a square marker on the map to
                          indicate the parking station location.
                        </p>
                        <p className="help-paragraph ">
                          Placing a Circle: Place a circular marker on the map
                          to indicate the parking station location.
                        </p>
                        <p className="help-paragraph ">
                          You can also edit or delete the mapped location if
                          needed.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        Parking Station Listings
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph mb-2">
                          All: You can view a list of all parking stations
                          available. Each listing includes the station name,
                          station code, and address.
                        </p>

                        <p className="help-paragraph ">
                          Suggested Parking Stations: You can see suggested
                          parking station addresses that match your search
                          criteria.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>
                        Adding a Parking Station
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph mb-2">
                          To add a new parking station, click on the "Add
                          Parking Station" button located on the right side of
                          the page. A form will appear.
                        </p>
                        <p className="heading-list">
                          Fill in the required details:
                        </p>
                        <p className="help-paragraph ">
                          Station Code: Enter the code for the parking station.
                        </p>
                        <p className="help-paragraph ">
                          Station Name: Provide the name of the parking station.
                        </p>
                        <p className="help-paragraph ">
                          Address: Enter the address where the parking station
                          is located.
                        </p>
                        <p className="help-paragraph ">
                          Parking Capacity: Specify the number of available
                          parking spaces.
                        </p>
                        <p className="help-paragraph ">
                          Vehicles: Indicate the types of vehicles allowed in
                          the parking station.
                        </p>
                        <p className="help-paragraph mb-2">
                          Map Location: Map the location of the parking station
                          on Google Maps using any of the available methods.
                        </p>
                        <p className="help-paragraph mb-2">
                          Once you've entered all the necessary information and
                          mapped the location, click the "Submit" button to add
                          the parking station to the system.
                        </p>
                        <p className="help-paragraph ">
                          We hope this guide helps you navigate and utilize the
                          Parking Station feature effectively. If you have any
                          further questions or need assistance, feel free to
                          reach out to our support team.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              )}

              {currentRoute === `/DeliveryDispatchManagement/${token1}` && (
                <div className="" id="help-main-wrapper">
                  <p className="help-paragraph mb-2">
                    Welcome to the "Help" module of our software! Here, we will
                    guide you on how to effectively use the Dispatch Customer
                    feature. This feature allows you to manage and search for
                    customers easily. Let's get started!
                  </p>

                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        a. Searching for Customers:
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          <p>To search for a customer, you have two options:</p>
                          <p>
                            a) By Vehicle Name: You can select the desired
                            vehicle name from the dropdown menu.
                          </p>
                          <p>
                            b) By Customer Code: You can enter the customer code
                            directly into the search field.
                          </p>
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        b. Viewing Customer Listings:
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="heading-list ">
                          Once you have performed a search, you will be
                          presented with a list of customers. The customer
                          listings will include essential information such as
                          the customer's name, customer code, email address, and
                          mobile number. You can conveniently view, edit, or
                          delete customer details using the available action
                          buttons.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        c. Adding a New Customer:
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph mb-2">
                          To add a new customer, simply click on the "Add
                          Customer" button. A form will appear, prompting you to
                          fill in the necessary information. Additionally, you
                          can add multiple addresses for a customer, ensuring
                          accurate delivery details. Once you have completed the
                          form, click on the "Submit" button to save the
                          customer's information.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <p className="heading-list ">
                      That's it! You are now equipped with the knowledge to
                      effectively use the Dispatch Customer feature. If you have
                      any further questions or need additional assistance,
                      please don't hesitate to reach out.
                    </p>
                  </Accordion>
                </div>
              )}
              {currentRoute === "/DeliveryDispatchManagement" && (
                <div className="" id="help-main-wrapper">
                  <p className="help-paragraph mb-2">
                    Welcome to the "Help" module of our software! Here, we will
                    guide you on how to effectively use the Dispatch Customer
                    feature. This feature allows you to manage and search for
                    customers easily. Let's get started!
                  </p>

                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        a. Searching for Customers:
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph">
                          <p>To search for a customer, you have two options:</p>
                          <p>
                            a) By Vehicle Name: You can select the desired
                            vehicle name from the dropdown menu.
                          </p>
                          <p>
                            b) By Customer Code: You can enter the customer code
                            directly into the search field.
                          </p>
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        b. Viewing Customer Listings:
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="heading-list ">
                          Once you have performed a search, you will be
                          presented with a list of customers. The customer
                          listings will include essential information such as
                          the customer's name, customer code, email address, and
                          mobile number. You can conveniently view, edit, or
                          delete customer details using the available action
                          buttons.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        c. Adding a New Customer:
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="help-paragraph mb-2">
                          To add a new customer, simply click on the "Add
                          Customer" button. A form will appear, prompting you to
                          fill in the necessary information. Additionally, you
                          can add multiple addresses for a customer, ensuring
                          accurate delivery details. Once you have completed the
                          form, click on the "Submit" button to save the
                          customer's information.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <p className="heading-list ">
                      That's it! You are now equipped with the knowledge to
                      effectively use the Dispatch Customer feature. If you have
                      any further questions or need additional assistance,
                      please don't hesitate to reach out.
                    </p>
                  </Accordion>
                </div>
              )}
            </div>
            <div className="footer">
              <label htmlFor="">{t("Was this information helpful")} ?</label>
              <div className="inner-footer">
                <button className="cx-btn-2" onClick={handleYesClick}>
                  {t("Yes")}
                </button>
                <button className="cx-btn-1" onClick={handleClose}>
                  {t("No")}
                </button>
              </div>
              <ToastContainer />
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </div>
  );
};

export default InfoComponent;
