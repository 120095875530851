import React, { useContext, useState } from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import DDlogo from "../../assets/images/DDlogo.png";
import { AppContext } from "../../context/AppContext";
import Calendar from "../../assets/images/calendar.svg";
import DatePicker from "react-datepicker";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

const NewSyncRequest = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [validated, setValidated] = useState(false);
  const { t, i18n } = useTranslation();

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };
  const { sidebar, setSidebar, Dark, setDark } = useContext(AppContext);
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  return (
    <motion.div
      variants={aninations}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.1 }}
      className={sidebar ? "taskMain " : "cx-active taskMain"}
      id="cx-main"
    >
      <div id="cx-wrapper">
        <div className="main-master-wrapper">
          <div className="Heading">
            <p>{t("Add Vehicle Sync Request")}</p>
          </div>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <div className="information-card innerInputsGen row mt-4">
              <div className="col-md-6 mb-4">
                <div className="innerSelectBox ">
                  <Form.Label className="common-labels me-0">
                    {t("Vehicle")} <span>&#42;</span>
                  </Form.Label>
                  <select
                  required
                    className="form-select"
                    aria-label="Default select example"
                    placeholder="Select Vehicle ..."
                  >
                    <option selected value="">Open this select menu</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6 mb-4">
                <div className="innerSelectBox ">
                  <Form.Label className="common-labels me-0">
                    {t("Sync Period")} <span>&#42;</span>
                  </Form.Label>
                  <select
                  required
                    className="form-select"
                    aria-label="Default select example"
                  >
                    <option selected value="">Data Range</option>
                    <option value="1">Complete</option>
                    <option value="2">Specific Date</option>
                    <option value="3">Range</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6 mb-4">
                <Form.Label className="common-labels">
                  {t("Sync Start Date")}
                </Form.Label>
                <div className="innerSelectBox weekCounter datepicker-main">
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    className="form-control"
                  />
                  <img src={Calendar} className="calendarLogo" alt="" />
                </div>

                <Form.Control.Feedback type="invalid">
                  Please Enter Sync Start Date.
                </Form.Control.Feedback>
              </div>
              <div className="col-md-6 mb-4">
                <Form.Label className="common-labels">{t("Sync End Date")}</Form.Label>
                <div className="innerSelectBox weekCounter datepicker-main">
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    className="form-control"
                  />
                  <img src={Calendar} className="calendarLogo" alt="" />
                </div>
                <Form.Control.Feedback type="invalid">
                  Please Enter Sync End Date
                </Form.Control.Feedback>
              </div>
              <div className="botttom-btn btn-wrapper">
                <Link to="/Vehicle">
                  <button className="cx-btn-1">{t("Cancel")}</button>
                </Link>
                <Link to="/Vehicle">
                  <button className="cx-btn-2">{t("Submit")}</button>
                </Link>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </motion.div>
  );
};

export default NewSyncRequest;
