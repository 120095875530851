import { React, useContext, useState, useEffect } from "react";
import { AppContext } from "../../../context/AppContext";
import Form from "react-bootstrap/Form";
import Save from "../../../assets/images/save.svg";
import Inspect from "../../../assets/images/inspect.svg";
import Right from "../../../assets/images/right.svg";
import Invoice from "../../../assets/images/invoice.svg";
import DatePicker from "react-datepicker";
import Calendar from "../../../assets/images/calendar.svg";
import uploadIcon from "../../../assets/images/uploadIcon.svg";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PreviousButton from "../../../sharedComponent/PreviousButton";
import NextButton from "../../../sharedComponent/Nextbutton";
import ExistButton from "../../../sharedComponent/ExistButton";
// Types of files

const AddVehicleAccesory = ({ onClick }) => {
  const { sidebar, setSidebar, Dark, setDark, slider, setSlider } =
    useContext(AppContext);
  const [validated, setValidated] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    navigate("/VehicleSpareParts");
  };
  const handleClickPrevious = () => {
    navigate("/VehicleAccesory");
  };
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
  };
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  return (
    <>
      <motion.div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }}
      >
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <div id="cx-wrapper" className="AddVehicle_Accesory">
            <div className="main-master-wrapper">
              <div className="vehicleHead">
                <p>{t("Vehicle Accessory Details")}</p>
              </div>
              <div className="innerInputsGen mainVehAccident ">
                <div className="addVehicleSe">
                  <div className="addVehicleLeftSec">
                    <div className="row">
                      <div className="col-md-12 form_input_main">
                        <div className="innerSelectBox weekCounter">
                          <Form.Label className="common-labels">
                            {" "}
                            {t("Vehicle Name")} <span>&#42;</span>{" "}
                          </Form.Label>
                          <Form.Control
                            required
                            type="text"
                            placeholder="Enter Your vehicle Name"
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter vehicle Name.
                          </Form.Control.Feedback>
                        </div>
                      </div>
                      <div className="col-md-12 form_input_main">
                        <div className="innerSelectBox weekCounter">
                          <Form.Label className="common-labels">
                            {" "}
                            {t("Accessory Name")} <span>&#42;</span>{" "}
                          </Form.Label>
                          <Form.Control
                            required
                            type="text"
                            placeholder="Enter Accessory Name"
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Accessory Name.
                          </Form.Control.Feedback>
                        </div>
                      </div>
                      <div className="col-md-12 form_input_main">
                        <div className="innerSelectBox weekCounter">
                          <Form.Label className="common-labels">
                            {" "}
                            {t("Quantity")} <span>&#42;</span>{" "}
                          </Form.Label>
                          <Form.Control
                            required
                            type="text"
                            placeholder="Enter Quantity"
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Quantity.
                          </Form.Control.Feedback>
                        </div>
                      </div>
                      <div className="col-md-12 form_input_main">
                        <div className="innerSelectBox weekCounter">
                          <Form.Label className="common-labels">
                            {" "}
                            {t("Price")} <span>&#42;</span>{" "}
                          </Form.Label>
                          <Form.Control
                            required
                            type="text"
                            placeholder="Enter Price"
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Price.
                          </Form.Control.Feedback>
                        </div>
                      </div>
                      <div className="col-md-12 form_input_main">
                        <div className="innerSelectBox weekCounter">
                          <Form.Label className="common-labels">
                            {" "}
                            {t("Purchase From")} <span>&#42;</span>{" "}
                          </Form.Label>
                          <Form.Control
                            required
                            type="text"
                            placeholder="Enter The Name Of Supplier"
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter The Name Of Supplier.
                          </Form.Control.Feedback>
                        </div>
                      </div>
                      <div className="col-md-12 form_input_main">
                        <div className="innerSelectBox weekCounter">
                          <Form.Label className="common-labels">
                            {" "}
                            {t("Purchase Date")} <span>&#42;</span>
                          </Form.Label>
                          <DatePicker
                            // selected={startDate}
                            placeholderText="Select Date"
                            onChange={(date) => setStartDate(date)}
                            className="form-control"
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Select Date.
                          </Form.Control.Feedback>
                          <img
                            src={Calendar}
                            className="addVehCalender"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* This section is for image addidtion in an array on every selection */}
                  <div className="imagesRightSec">
                    <div className="row">
                      <div className="col-md-12 form_input_main">
                        <div className="innerSelectBox weekCounter">
                          <label className="common-labels">
                            {t("Invoice")}
                          </label>
                          {/* File name which user will select name will show below */}
                          <div className="savedInvoice">
                            <label>Example Document-Invoice.pdf</label>
                          </div>
                          <img src={Save} className="save" alt="" />
                          {/* below two images will show conditionally */}
                          <img src={Inspect} className="Inspect" alt="" />
                          <img src={Right} className="right" alt="" />
                          <label className="invalidText">
                            {t("Failed to upload")}
                          </label>
                          <label className="retry">{t("Retry")}</label>
                        </div>
                      </div>
                      <div className="col-md-12 form_input_main">
                        <div className="innerSelectBox weekCounter form_input_main">
                          <label className="common-labels">
                            {t("Invoice")} 2
                          </label>
                          {/* File name which user will select name will show below */}
                          <div className="savedInvoice">
                            <label>Example Document-Invoice.pdf</label>
                          </div>
                          <img src={Save} className="save" alt="" />
                          {/* below two images will show conditionally */}
                          <img src={Inspect} className="Inspect" alt="" />
                          <img src={Right} className="right" alt="" />
                          <label className="invalidText">
                            {t("Failed to upload")}
                          </label>
                          <label className="retry">{t("Retry")}</label>
                        </div>
                        {/* Selected image preview here */}
                        <div className="previewImg">
                          <img src={Invoice} className="InvoiceImg" alt="" />
                        </div>
                      </div>
                      <div className="col-md-12 form_input_main">
                        <div className="fileDropper">
                          <label htmlFor="file" className="imageHolder">
                            <input type="file" id="file" className="d-none" />
                            <div className="innerFlex">
                              <img
                                src={uploadIcon}
                                className="browseImg"
                                alt=""
                              />
                              <p className="innerTxt">Drag & Drop Your File</p>
                              <p className="innerTxt">Or</p>
                              <label htmlFor="file2" className="browseBtn">
                                <input
                                  type="file"
                                  id="file2"
                                  className="d-none"
                                />
                                <p className="mt-1">Browse File</p>
                              </label>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Two Bottom Buttons */}
              <div className="d-flex justify-content-end align-items-center belowBtns">
                <button className="cx-btn-1">{t("Cancel")}</button>
                <button className="cx-btn-2">{t("Submit")}</button>
              </div>
            </div>
          </div>
        </Form>
        {slider ? <ExistButton onClose={() => setSlider(false)} /> : null}
        {!slider && <PreviousButton onBack={handleClickPrevious} />}
        {!slider && <NextButton onClick={handleClick} />}
      </motion.div>
    </>
  );
};

export default AddVehicleAccesory;
