// import React from 'react'

// const ParkingManagement = () => {
//   return (
//     <div>ParkingManagement</div>
//   )
// }

// export default ParkingManagement

import { React, useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { Link, useNavigate } from "react-router-dom";
import Logout from "../../assets/images/import_icon.svg";
import Share from "../../assets/images/XMLID_1022_.svg";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Nav from "react-bootstrap/Nav";
import option from "../../assets/images/option-three-dot.svg";
import { Accordion, Col, Dropdown, Modal } from "react-bootstrap";
import { motion } from "framer-motion";
import MapComponent from "../../sharedComponent/MapComponent";
import { useTranslation } from "react-i18next";
import ExistButton from "../../sharedComponent/ExistButton";
import NextButton from "../../sharedComponent/Nextbutton";
import InfoComponent from "../../sharedComponent/SupportHelp/InfoComponent";
import PreviousButton from "../../sharedComponent/PreviousButton";

const ParkingManagementone = ({ onClick }) => {
  const { sidebar, setSidebar, Dark, setDark, slider, setSlider } =
    useContext(AppContext);
  const [tabClicked, setTabClicked] = useState(false);
  const [navClicked, setNavClicked] = useState(false);
  const [isView, setIsView] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const { t, i18n } = useTranslation();

  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  const navigate = useNavigate();
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    navigate("/AddParkingSlot");
  };
  const handleClickPrevious = () => {
    navigate("/ReplayPlayback");
  };
  return (
    <>
      <motion.div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }}
      >
        <div id="cx-wrapper" className="Parking_Slot ParkingManagement">
          <div className="upperMainSet">
            <div className="row top-content ">
              <div className="col-lg-8 col-md-12 arrange-margin left">
                <div className="row p-0">
                  <div className="col-md-4">
                    <div className="weekCounter">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Station Name"
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="weekCounter">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Station Code"
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="weekCounter">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Address"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-12 mainCol4 right">
                <div className="leftSideSec">
                  <Link to="/AddParkingSlot" className="addParkBtn">
                    <button>+ {t("Add Parking Station")}</button>
                  </Link>
                  <Link to="#">
                    <div className="inconMain">
                      <img src={Logout} alt="" />
                    </div>
                  </Link>
                  <Link to="#">
                    <div className="inconMain left-margin me-0">
                      <img src={Share} alt="" />
                    </div>
                  </Link>
                </div>
              </div>
            </div>

            <div className="row body-content g-3">
              <div className="col-lg-8 col-md-12">
                <div className="mainMape" id="parkinManager1">
                  <MapComponent />
                  {tabClicked ? (
                    <div
                      className="innerMapDetails "
                      id="model-responsive-on-map parkinManager"
                    >
                      <div className="headingDetails">
                        <div className="headingTxt">
                          <p className="heading">
                            {t("Parking Station Details")}
                          </p>
                        </div>
                        <div className="customer-option">
                          <Dropdown className={isView ? `d-none` : ""}>
                            <Dropdown.Toggle id="dropdown-basic">
                              <img src={option} alt="" />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item>
                                {" "}
                                <Link to="/EditParkingSlot" className="d-block">
                                  {t("Edit")}
                                </Link>
                              </Dropdown.Item>
                              <Dropdown.Item>
                                <Link
                                  to="#"
                                  onClick={() => setShow(true)}
                                  className="d-block"
                                >
                                  {t("Delete")}
                                </Link>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                      <div className="innerCOntent">
                        <div className="row">
                          <div className="col-md-3 dividedCol form_input_main">
                            <p className="paraHead">{t("Station Name")}</p>
                            <p className="paraVal">Parking Slot 1</p>
                          </div>
                          <div className="col-md-3 dividedCol form_input_main">
                            <p className="paraHead">{t("Station Code")}</p>
                            <p className="paraVal">456879</p>
                          </div>
                          <div className="col-md-3 dividedCol form_input_main">
                            <p className="paraHead">{t("Parking Capacity")}</p>
                            <p className="paraVal">10</p>
                          </div>
                          <div className="col-md-12 dividedCol form_input_main">
                            <p className="paraHead">{t("Address")}</p>
                            <p className="paraVal">
                              SGRA 82, Shanti Gardens Third St, Thrimoorthy
                              Nagar, Kamaleshwaram, Thiruvananthapuram, Kerala
                              695009, India
                            </p>
                          </div>
                          <div className="col-md-3 dividedCol form_input_main">
                            <p className="paraHead">
                              {t("Slot GPS Lattitude")}
                            </p>
                            <p className="paraVal">8.465844813567859</p>
                          </div>
                          <div className="col-md-3 dividedCol form_input_main">
                            <p className="paraHead">
                              {t("Slot GPS Longitude")}
                            </p>
                            <p className="paraVal">76.9438642156782</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-lg-4 col-md-12">
                <div className="tabsCon">
                  {/* <Tabs
                    defaultActiveKey="All"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                  >

                    <Tab eventKey="All" title="All">
                      <div id="navPills">
                        <Nav variant="pills" className="flex-column">
                          <Nav.Item
                            onClick={() => {
                              setTabClicked(true);
                              setNavClicked(false);
                            }}
                          >
                            <Nav.Link eventKey="first">
                              <div className="AllFlexNav">
                                <div>
                                  <p className="paraNav">Slot Name</p>
                                  <p className="paraNavTxt">
                                    Redbytes Parking
                                  </p>
                                </div>
                                <div>
                                  <p className="paraNav">Slot Code</p>
                                  <p className="paraNavTxt">Test1234</p>
                                </div>
                                <div>
                                  <p className="paraNav">Address</p>
                                  <p className="paraNavTxt">
                                    Vishrantwadi, Kalas...
                                  </p>
                                </div>
                              </div>
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item
                            onClick={() => {
                              setTabClicked(true);
                              setNavClicked(false);
                            }}
                          >
                            <Nav.Link eventKey="two">
                              <div className="AllFlexNav">
                                <div>
                                  <p className="paraNav">Slot Name</p>
                                  <p className="paraNavTxt">
                                    Redbytes Parking
                                  </p>
                                </div>
                                <div>
                                  <p className="paraNav">Slot Code</p>
                                  <p className="paraNavTxt">Test1234</p>
                                </div>
                                <div>
                                  <p className="paraNav">Address</p>
                                  <p className="paraNavTxt">
                                    Vishrantwadi, Kalas...
                                  </p>
                                </div>
                              </div>
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item
                            onClick={() => {
                              setTabClicked(true);
                              setNavClicked(false);
                            }}
                          >
                            <Nav.Link eventKey="three">
                              <div className="AllFlexNav">
                                <div>
                                  <p className="paraNav">Slot Name</p>
                                  <p className="paraNavTxt">
                                    Redbytes Parking
                                  </p>
                                </div>
                                <div>
                                  <p className="paraNav">Slot Code</p>
                                  <p className="paraNavTxt">Test1234</p>
                                </div>
                                <div>
                                  <p className="paraNav">Address</p>
                                  <p className="paraNavTxt">
                                    Vishrantwadi, Kalas...
                                  </p>
                                </div>
                              </div>
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item
                            onClick={() => {
                              setTabClicked(true);
                              setNavClicked(false);
                            }}
                          >
                            <Nav.Link eventKey="four">
                              <div className="AllFlexNav">
                                <div>
                                  <p className="paraNav">Slot Name</p>
                                  <p className="paraNavTxt">
                                    Redbytes Parking
                                  </p>
                                </div>
                                <div>
                                  <p className="paraNav">Slot Code</p>
                                  <p className="paraNavTxt">Test1234</p>
                                </div>
                                <div>
                                  <p className="paraNav">Address</p>
                                  <p className="paraNavTxt">
                                    Vishrantwadi, Kalas...
                                  </p>
                                </div>
                              </div>
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item
                            onClick={() => {
                              setTabClicked(true);
                              setNavClicked(false);
                            }}
                          >
                            <Nav.Link eventKey="five">
                              <div className="AllFlexNav">
                                <div>
                                  <p className="paraNav">Slot Name</p>
                                  <p className="paraNavTxt">
                                    Redbytes Parking
                                  </p>
                                </div>
                                <div>
                                  <p className="paraNav">Slot Code</p>
                                  <p className="paraNavTxt">Test1234</p>
                                </div>
                                <div>
                                  <p className="paraNav">Address</p>
                                  <p className="paraNavTxt">
                                    Vishrantwadi, Kalas...
                                  </p>
                                </div>
                              </div>
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item
                            onClick={() => {
                              setTabClicked(true);
                              setNavClicked(false);
                            }}
                          >
                            <Nav.Link eventKey="six">
                              <div className="AllFlexNav">
                                <div>
                                  <p className="paraNav">Slot Name</p>
                                  <p className="paraNavTxt">
                                    Redbytes Parking
                                  </p>
                                </div>
                                <div>
                                  <p className="paraNav">Slot Code</p>
                                  <p className="paraNavTxt">Test1234</p>
                                </div>
                                <div>
                                  <p className="paraNav">Address</p>
                                  <p className="paraNavTxt">
                                    Vishrantwadi, Kalas...
                                  </p>
                                </div>
                              </div>
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item
                            onClick={() => {
                              setTabClicked(true);
                              setNavClicked(false);
                            }}
                          >
                            <Nav.Link eventKey="seven">
                              <div className="AllFlexNav">
                                <div>
                                  <p className="paraNav">Slot Name</p>
                                  <p className="paraNavTxt">
                                    Redbytes Parking
                                  </p>
                                </div>
                                <div>
                                  <p className="paraNav">Slot Code</p>
                                  <p className="paraNavTxt">Test1234</p>
                                </div>
                                <div>
                                  <p className="paraNav">Address</p>
                                  <p className="paraNavTxt">
                                    Vishrantwadi, Kalas...
                                  </p>
                                </div>
                              </div>
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item
                            onClick={() => {
                              setTabClicked(true);
                              setNavClicked(false);
                            }}
                          >
                            <Nav.Link eventKey="eight">
                              <div className="AllFlexNav">
                                <div>
                                  <p className="paraNav">Slot Name</p>
                                  <p className="paraNavTxt">
                                    Redbytes Parking
                                  </p>
                                </div>
                                <div>
                                  <p className="paraNav">Slot Code</p>
                                  <p className="paraNavTxt">Test1234</p>
                                </div>
                                <div>
                                  <p className="paraNav">Address</p>
                                  <p className="paraNavTxt">
                                    Vishrantwadi, Kalas...
                                  </p>
                                </div>
                              </div>
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item
                            onClick={() => {
                              setTabClicked(true);
                              setNavClicked(false);
                            }}
                          >
                            <Nav.Link eventKey="nine">
                              <div className="AllFlexNav">
                                <div>
                                  <p className="paraNav">Slot Name</p>
                                  <p className="paraNavTxt">
                                    Redbytes Parking
                                  </p>
                                </div>
                                <div>
                                  <p className="paraNav">Slot Code</p>
                                  <p className="paraNavTxt">Test1234</p>
                                </div>
                                <div>
                                  <p className="paraNav">Address</p>
                                  <p className="paraNavTxt">
                                    Vishrantwadi, Kalas...
                                  </p>
                                </div>
                              </div>
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item
                            onClick={() => {
                              setTabClicked(true);
                              setNavClicked(false);
                            }}
                          >
                            <Nav.Link eventKey="ten">
                              <div className="AllFlexNav">
                                <div>
                                  <p className="paraNav">Slot Name</p>
                                  <p className="paraNavTxt">
                                    Redbytes Parking
                                  </p>
                                </div>
                                <div>
                                  <p className="paraNav">Slot Code</p>
                                  <p className="paraNavTxt">Test1234</p>
                                </div>
                                <div>
                                  <p className="paraNav">Address</p>
                                  <p className="paraNavTxt">
                                    Vishrantwadi, Kalas...
                                  </p>
                                </div>
                              </div>
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item
                            onClick={() => {
                              setTabClicked(true);
                              setNavClicked(false);
                            }}
                          >
                            <Nav.Link eventKey="ten">
                              <div className="AllFlexNav">
                                <div>
                                  <p className="paraNav">Slot Name</p>
                                  <p className="paraNavTxt">
                                    Redbytes Parking
                                  </p>
                                </div>
                                <div>
                                  <p className="paraNav">Slot Code</p>
                                  <p className="paraNavTxt">Test1234</p>
                                </div>
                                <div>
                                  <p className="paraNav">Address</p>
                                  <p className="paraNavTxt">
                                    Vishrantwadi, Kalas...
                                  </p>
                                </div>
                              </div>
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item
                            onClick={() => {
                              setTabClicked(true);
                              setNavClicked(false);
                            }}
                          >
                            <Nav.Link eventKey="ten">
                              <div className="AllFlexNav">
                                <div>
                                  <p className="paraNav">Slot Name</p>
                                  <p className="paraNavTxt">
                                    Redbytes Parking
                                  </p>
                                </div>
                                <div>
                                  <p className="paraNav">Slot Code</p>
                                  <p className="paraNavTxt">Test1234</p>
                                </div>
                                <div>
                                  <p className="paraNav">Address</p>
                                  <p className="paraNavTxt">
                                    Vishrantwadi, Kalas...
                                  </p>
                                </div>
                              </div>
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item
                            onClick={() => {
                              setTabClicked(true);
                              setNavClicked(false);
                            }}
                          >
                            <Nav.Link eventKey="ten">
                              <div className="AllFlexNav">
                                <div>
                                  <p className="paraNav">Slot Name</p>
                                  <p className="paraNavTxt">
                                    Redbytes Parking
                                  </p>
                                </div>
                                <div>
                                  <p className="paraNav">Slot Code</p>
                                  <p className="paraNavTxt">Test1234</p>
                                </div>
                                <div>
                                  <p className="paraNav">Address</p>
                                  <p className="paraNavTxt">
                                    Vishrantwadi, Kalas...
                                  </p>
                                </div>
                              </div>
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </div>
                    </Tab>

                    <Tab
                      eventKey="Suggested  Parking Slot"
                      title="Suggested  Parking Slot"
                    >
                      <Nav
                        variant="pills"
                        className="flex-column"
                        id="navPills"
                      >
                        <Nav.Item
                          onClick={() => {
                            setTabClicked(false);
                            setNavClicked(true);
                          }}
                        >
                          <Nav.Link eventKey="first">
                            <div className="row">
                              <div className="col-md-12 parkingSLotCOl">
                                <p className="paraNav">Address</p>
                                <p className="paraNavTxt">
                                  Vishrantwadi, Kalas Road, Pune, Maharashtra.
                                </p>
                              </div>
                            </div>
                          </Nav.Link>
                        </Nav.Item>

                        <Nav.Item
                          onClick={() => {
                            setTabClicked(false);
                            setNavClicked(true);
                          }}
                        >
                          <Nav.Link eventKey="second">
                            <div className="row">
                              <div className="col-md-12 parkingSLotCOl">
                                <p className="paraNav">Address</p>
                                <p className="paraNavTxt">
                                  Vishrantwadi, Kalas Road, Pune, Maharashtra.
                                </p>
                              </div>
                            </div>
                          </Nav.Link>
                        </Nav.Item>

                        <Nav.Item
                          onClick={() => {
                            setTabClicked(false);
                            setNavClicked(true);
                          }}
                        >
                          <Nav.Link eventKey="third">
                            <div className="row">
                              <div className="col-md-12 parkingSLotCOl">
                                <p className="paraNav">Address</p>
                                <p className="paraNavTxt">
                                  Vishrantwadi, Kalas Road, Pune, Maharashtra.
                                </p>
                              </div>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Tab>
                  </Tabs> */}
                  <Tab.Container
                    id="left-tabs-example"
                    className="td-tab-wrapper"
                    defaultActiveKey="0"
                  >
                    <Nav
                      variant="pills"
                      className="td-nav"
                      id="InnerTabNew_Two"
                    >
                      <Nav.Item className="td-tab">
                        <Nav.Link className="td-link" eventKey="0">
                          {t("Stations")}
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="td-tab">
                        <Nav.Link className="td-link" eventKey="1">
                          {t("Suggested Cars")}
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>

                    <Col sm={12} className="">
                      <Tab.Content>
                        <Tab.Pane eventKey="0">
                          <div id="navPills">
                            <Nav
                              variant="pills"
                              className="flex-column"
                              id="ParkingManagment"
                            >
                              <Accordion>
                                <Accordion.Item eventKey="0">
                                  <Accordion.Header>
                                    <Nav.Item
                                      onClick={() => {
                                        setTabClicked(!tabClicked);
                                        setNavClicked(!navClicked);
                                      }}
                                    >
                                      <Nav.Link eventKey="first">
                                        <div className="AllFlexNav">
                                          <div>
                                            <p className="paraNav">
                                              {t("Station Name")}
                                            </p>
                                            <p className="paraNavTxt">
                                              Redbytes Parking
                                            </p>
                                          </div>
                                          <div>
                                            <p className="paraNav">
                                              {t("Station Code")}
                                            </p>
                                            <p className="paraNavTxt">
                                              Test1234
                                            </p>
                                          </div>
                                          <div>
                                            <p className="paraNav">
                                              {t("Parking Capacity")}
                                            </p>
                                            <p className="paraNavTxt">10/10</p>
                                          </div>
                                        </div>
                                      </Nav.Link>
                                    </Nav.Item>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <div
                                      className="main-master-wrapper mb-0 p-0"
                                      id="CustomerSupport"
                                    >
                                      <table className="holiday-table">
                                        <thead className="ht-head">
                                          <tr>
                                            <td>{t("Driver Name")}</td>
                                            <td>{t("Vehicle")}</td>
                                            <td>{t("Parking Code")}</td>
                                            <td>{t("Action")}</td>
                                          </tr>
                                        </thead>
                                        <tbody className="ht-body">
                                          <tr className="table-row-custom">
                                            <td>Mr. John Doe</td>
                                            <td>Pickup-Truck</td>
                                            <td>P-101</td>
                                            <td className="De-assign">
                                              <p>{t("De-assign")}</p>
                                            </td>
                                          </tr>
                                          <tr className="table-row-custom">
                                            <td>Mr. John Doe</td>
                                            <td>Pickup-Truck</td>
                                            <td>P-101</td>
                                            <td className="De-assign">
                                              <p>{t("De-assign")}</p>
                                            </td>
                                          </tr>
                                          <tr className="table-row-custom">
                                            <td>Mr. John Doe</td>
                                            <td>Pickup-Truck</td>
                                            <td>P-101</td>
                                            <td className="De-assign">
                                              <p>{t("De-assign")}</p>
                                            </td>
                                          </tr>
                                          <tr className="table-row-custom">
                                            <td>Mr. John Doe</td>
                                            <td>Pickup-Truck</td>
                                            <td>P-101</td>
                                            <td className="De-assign">
                                              <p>{t("De-assign")}</p>
                                            </td>
                                          </tr>
                                          <tr className="table-row-custom">
                                            <td>Mr. John Doe</td>
                                            <td>Pickup-Truck</td>
                                            <td>P-101</td>
                                            <td className="De-assign">
                                              <p>{t("De-assign")}</p>
                                            </td>
                                          </tr>
                                          <tr className="table-row-custom">
                                            <td>Mr. John Doe</td>
                                            <td>Pickup-Truck</td>
                                            <td>P-101</td>
                                            <td className="De-assign">
                                              <p>{t("De-assign")}</p>
                                            </td>
                                          </tr>
                                          <tr className="table-row-custom">
                                            <td>Mr. John Doe</td>
                                            <td>Pickup-Truck</td>
                                            <td>P-101</td>
                                            <td className="De-assign">
                                              <p>{t("De-assign")}</p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                  <Accordion.Header>
                                    <Nav.Item
                                      onClick={() => {
                                        setTabClicked(!tabClicked);
                                        setNavClicked(!navClicked);
                                      }}
                                    >
                                      <Nav.Link eventKey="second">
                                        <div className="AllFlexNav">
                                          <div>
                                            <p className="paraNav">
                                              {t("Station Name")}
                                            </p>
                                            <p className="paraNavTxt">
                                              Redbytes Parking
                                            </p>
                                          </div>
                                          <div>
                                            <p className="paraNav">
                                              {t("Station Code")}
                                            </p>
                                            <p className="paraNavTxt">
                                              Test1234
                                            </p>
                                          </div>
                                          <div>
                                            <p className="paraNav">
                                              {t("Parking Capacity")}
                                            </p>
                                            <p className="paraNavTxt">10/10</p>
                                          </div>
                                        </div>
                                      </Nav.Link>
                                    </Nav.Item>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <div
                                      className="main-master-wrapper mb-0 p-0"
                                      id="CustomerSupport"
                                    >
                                      <table className="holiday-table">
                                        <thead className="ht-head">
                                          <tr>
                                            <td>{t("Driver Name")}</td>
                                            <td>{t("Vehicle")}</td>
                                            <td>{t("Parking Code")}</td>
                                            <td>{t("Action")}</td>
                                          </tr>
                                        </thead>
                                        <tbody className="ht-body">
                                          <tr className="table-row-custom">
                                            <td>Mr. John Doe</td>
                                            <td>Pickup-Truck</td>
                                            <td>P-101</td>
                                            <td className="De-assign">
                                              <p>{t("De-assign")}</p>
                                            </td>
                                          </tr>
                                          <tr className="table-row-custom">
                                            <td>Mr. John Doe</td>
                                            <td>Pickup-Truck</td>
                                            <td>P-101</td>
                                            <td className="De-assign">
                                              <p>{t("De-assign")}</p>
                                            </td>
                                          </tr>
                                          <tr className="table-row-custom">
                                            <td>Mr. John Doe</td>
                                            <td>Pickup-Truck</td>
                                            <td>P-101</td>
                                            <td className="De-assign">
                                              <p>{t("De-assign")}</p>
                                            </td>
                                          </tr>
                                          <tr className="table-row-custom">
                                            <td>Mr. John Doe</td>
                                            <td>Pickup-Truck</td>
                                            <td>P-101</td>
                                            <td className="De-assign">
                                              <p>{t("De-assign")}</p>
                                            </td>
                                          </tr>
                                          <tr className="table-row-custom">
                                            <td>Mr. John Doe</td>
                                            <td>Pickup-Truck</td>
                                            <td>P-101</td>
                                            <td className="De-assign">
                                              <p>{t("De-assign")}</p>
                                            </td>
                                          </tr>
                                          <tr className="table-row-custom">
                                            <td>Mr. John Doe</td>
                                            <td>Pickup-Truck</td>
                                            <td>P-101</td>
                                            <td className="De-assign">
                                              <p>{t("De-assign")}</p>
                                            </td>
                                          </tr>
                                          <tr className="table-row-custom">
                                            <td>Mr. John Doe</td>
                                            <td>Pickup-Truck</td>
                                            <td>P-101</td>
                                            <td className="De-assign">
                                              <p>{t("De-assign")}</p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                  <Accordion.Header>
                                    <Nav.Item
                                      onClick={() => {
                                        setTabClicked(!tabClicked);
                                        setNavClicked(!navClicked);
                                      }}
                                    >
                                      <Nav.Link eventKey="three">
                                        <div className="AllFlexNav">
                                          <div>
                                            <p className="paraNav">
                                              {t("Station Name")}
                                            </p>
                                            <p className="paraNavTxt">
                                              Redbytes Parking
                                            </p>
                                          </div>
                                          <div>
                                            <p className="paraNav">
                                              {t("Station Code")}
                                            </p>
                                            <p className="paraNavTxt">
                                              Test1234
                                            </p>
                                          </div>
                                          <div>
                                            <p className="paraNav">
                                              {t("Parking Capacity")}
                                            </p>
                                            <p className="paraNavTxt">10/10</p>
                                          </div>
                                        </div>
                                      </Nav.Link>
                                    </Nav.Item>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <div
                                      className="main-master-wrapper mb-0 p-0"
                                      id="CustomerSupport"
                                    >
                                      <table className="holiday-table">
                                        <thead className="ht-head">
                                          <tr>
                                            <td>{t("Driver Name")}</td>
                                            <td>{t("Vehicle")}</td>
                                            <td>{t("Parking Code")}</td>
                                            <td>{t("Action")}</td>
                                          </tr>
                                        </thead>
                                        <tbody className="ht-body">
                                          <tr className="table-row-custom">
                                            <td>Mr. John Doe</td>
                                            <td>Pickup-Truck</td>
                                            <td>P-101</td>
                                            <td className="De-assign">
                                              <p>{t("De-assign")}</p>
                                            </td>
                                          </tr>
                                          <tr className="table-row-custom">
                                            <td>Mr. John Doe</td>
                                            <td>Pickup-Truck</td>
                                            <td>P-101</td>
                                            <td className="De-assign">
                                              <p>{t("De-assign")}</p>
                                            </td>
                                          </tr>
                                          <tr className="table-row-custom">
                                            <td>Mr. John Doe</td>
                                            <td>Pickup-Truck</td>
                                            <td>P-101</td>
                                            <td className="De-assign">
                                              <p>{t("De-assign")}</p>
                                            </td>
                                          </tr>
                                          <tr className="table-row-custom">
                                            <td>Mr. John Doe</td>
                                            <td>Pickup-Truck</td>
                                            <td>P-101</td>
                                            <td className="De-assign">
                                              <p>{t("De-assign")}</p>
                                            </td>
                                          </tr>
                                          <tr className="table-row-custom">
                                            <td>Mr. John Doe</td>
                                            <td>Pickup-Truck</td>
                                            <td>P-101</td>
                                            <td className="De-assign">
                                              <p>{t("De-assign")}</p>
                                            </td>
                                          </tr>
                                          <tr className="table-row-custom">
                                            <td>Mr. John Doe</td>
                                            <td>Pickup-Truck</td>
                                            <td>P-101</td>
                                            <td className="De-assign">
                                              <p>{t("De-assign")}</p>
                                            </td>
                                          </tr>
                                          <tr className="table-row-custom">
                                            <td>Mr. John Doe</td>
                                            <td>Pickup-Truck</td>
                                            <td>P-101</td>
                                            <td className="De-assign">
                                              <p>{t("De-assign")}</p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                  <Accordion.Header>
                                    <Nav.Item
                                      onClick={() => {
                                        setTabClicked(!tabClicked);
                                        setNavClicked(!navClicked);
                                      }}
                                    >
                                      <Nav.Link eventKey="four">
                                        <div className="AllFlexNav">
                                          <div>
                                            <p className="paraNav">
                                              {t("Station Name")}
                                            </p>
                                            <p className="paraNavTxt">
                                              Redbytes Parking
                                            </p>
                                          </div>
                                          <div>
                                            <p className="paraNav">
                                              {t("Station Code")}
                                            </p>
                                            <p className="paraNavTxt">
                                              Test1234
                                            </p>
                                          </div>
                                          <div>
                                            <p className="paraNav">
                                              {t("Parking Capacity")}
                                            </p>
                                            <p className="paraNavTxt">10/10</p>
                                          </div>
                                        </div>
                                      </Nav.Link>
                                    </Nav.Item>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <div
                                      className="main-master-wrapper mb-0 p-0"
                                      id="CustomerSupport"
                                    >
                                      <table className="holiday-table">
                                        <thead className="ht-head">
                                          <tr>
                                            <td>{t("Driver Name")}</td>
                                            <td>{t("Vehicle")}</td>
                                            <td>{t("Parking Code")}</td>
                                            <td>{t("Action")}</td>
                                          </tr>
                                        </thead>
                                        <tbody className="ht-body">
                                          <tr className="table-row-custom">
                                            <td>Mr. John Doe</td>
                                            <td>Pickup-Truck</td>
                                            <td>P-101</td>
                                            <td className="De-assign">
                                              <p>{t("De-assign")}</p>
                                            </td>
                                          </tr>
                                          <tr className="table-row-custom">
                                            <td>Mr. John Doe</td>
                                            <td>Pickup-Truck</td>
                                            <td>P-101</td>
                                            <td className="De-assign">
                                              <p>{t("De-assign")}</p>
                                            </td>
                                          </tr>
                                          <tr className="table-row-custom">
                                            <td>Mr. John Doe</td>
                                            <td>Pickup-Truck</td>
                                            <td>P-101</td>
                                            <td className="De-assign">
                                              <p>{t("De-assign")}</p>
                                            </td>
                                          </tr>
                                          <tr className="table-row-custom">
                                            <td>Mr. John Doe</td>
                                            <td>Pickup-Truck</td>
                                            <td>P-101</td>
                                            <td className="De-assign">
                                              <p>{t("De-assign")}</p>
                                            </td>
                                          </tr>
                                          <tr className="table-row-custom">
                                            <td>Mr. John Doe</td>
                                            <td>Pickup-Truck</td>
                                            <td>P-101</td>
                                            <td className="De-assign">
                                              <p>{t("De-assign")}</p>
                                            </td>
                                          </tr>
                                          <tr className="table-row-custom">
                                            <td>Mr. John Doe</td>
                                            <td>Pickup-Truck</td>
                                            <td>P-101</td>
                                            <td className="De-assign">
                                              <p>{t("De-assign")}</p>
                                            </td>
                                          </tr>
                                          <tr className="table-row-custom">
                                            <td>Mr. John Doe</td>
                                            <td>Pickup-Truck</td>
                                            <td>P-101</td>
                                            <td className="De-assign">
                                              <p>{t("De-assign")}</p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                  <Accordion.Header>
                                    <Nav.Item
                                      onClick={() => {
                                        setTabClicked(!tabClicked);
                                        setNavClicked(!navClicked);
                                      }}
                                    >
                                      <Nav.Link eventKey="five">
                                        <div className="AllFlexNav">
                                          <div>
                                            <p className="paraNav">
                                              {t("Station Name")}
                                            </p>
                                            <p className="paraNavTxt">
                                              Redbytes Parking
                                            </p>
                                          </div>
                                          <div>
                                            <p className="paraNav">
                                              {t("Station Code")}
                                            </p>
                                            <p className="paraNavTxt">
                                              Test1234
                                            </p>
                                          </div>
                                          <div>
                                            <p className="paraNav">
                                              {t("Parking Capacity")}
                                            </p>
                                            <p className="paraNavTxt">10/10</p>
                                          </div>
                                        </div>
                                      </Nav.Link>
                                    </Nav.Item>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <div
                                      className="main-master-wrapper mb-0 p-0"
                                      id="CustomerSupport"
                                    >
                                      <table className="holiday-table">
                                        <thead className="ht-head">
                                          <tr>
                                            <td>{t("Driver Name")}</td>
                                            <td>{t("Vehicle")}</td>
                                            <td>{t("Parking Code")}</td>
                                            <td>{t("Action")}</td>
                                          </tr>
                                        </thead>
                                        <tbody className="ht-body">
                                          <tr className="table-row-custom">
                                            <td>Mr. John Doe</td>
                                            <td>Pickup-Truck</td>
                                            <td>P-101</td>
                                            <td className="De-assign">
                                              <p>{t("De-assign")}</p>
                                            </td>
                                          </tr>
                                          <tr className="table-row-custom">
                                            <td>Mr. John Doe</td>
                                            <td>Pickup-Truck</td>
                                            <td>P-101</td>
                                            <td className="De-assign">
                                              <p>{t("De-assign")}</p>
                                            </td>
                                          </tr>
                                          <tr className="table-row-custom">
                                            <td>Mr. John Doe</td>
                                            <td>Pickup-Truck</td>
                                            <td>P-101</td>
                                            <td className="De-assign">
                                              <p>{t("De-assign")}</p>
                                            </td>
                                          </tr>
                                          <tr className="table-row-custom">
                                            <td>Mr. John Doe</td>
                                            <td>Pickup-Truck</td>
                                            <td>P-101</td>
                                            <td className="De-assign">
                                              <p>{t("De-assign")}</p>
                                            </td>
                                          </tr>
                                          <tr className="table-row-custom">
                                            <td>Mr. John Doe</td>
                                            <td>Pickup-Truck</td>
                                            <td>P-101</td>
                                            <td className="De-assign">
                                              <p>{t("De-assign")}</p>
                                            </td>
                                          </tr>
                                          <tr className="table-row-custom">
                                            <td>Mr. John Doe</td>
                                            <td>Pickup-Truck</td>
                                            <td>P-101</td>
                                            <td className="De-assign">
                                              <p>{t("De-assign")}</p>
                                            </td>
                                          </tr>
                                          <tr className="table-row-custom">
                                            <td>Mr. John Doe</td>
                                            <td>Pickup-Truck</td>
                                            <td>P-101</td>
                                            <td className="De-assign">
                                              <p>{t("De-assign")}</p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                  <Accordion.Header>
                                    <Nav.Item
                                      onClick={() => {
                                        setTabClicked(!tabClicked);
                                        setNavClicked(!navClicked);
                                      }}
                                    >
                                      <Nav.Link eventKey="six">
                                        <div className="AllFlexNav">
                                          <div>
                                            <p className="paraNav">
                                              {t("Station Name")}
                                            </p>
                                            <p className="paraNavTxt">
                                              Redbytes Parking
                                            </p>
                                          </div>
                                          <div>
                                            <p className="paraNav">
                                              {t("Station Code")}
                                            </p>
                                            <p className="paraNavTxt">
                                              Test1234
                                            </p>
                                          </div>
                                          <div>
                                            <p className="paraNav">
                                              {t("Parking Capacity")}
                                            </p>
                                            <p className="paraNavTxt">10/10</p>
                                          </div>
                                        </div>
                                      </Nav.Link>
                                    </Nav.Item>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <div
                                      className="main-master-wrapper mb-0 p-0"
                                      id="CustomerSupport"
                                    >
                                      <table className="holiday-table">
                                        <thead className="ht-head">
                                          <tr>
                                            <td>{t("Driver Name")}</td>
                                            <td>{t("Vehicle")}</td>
                                            <td>{t("Parking Code")}</td>
                                            <td>{t("Action")}</td>
                                          </tr>
                                        </thead>
                                        <tbody className="ht-body">
                                          <tr className="table-row-custom">
                                            <td>Mr. John Doe</td>
                                            <td>Pickup-Truck</td>
                                            <td>P-101</td>
                                            <td className="De-assign">
                                              <p>{t("De-assign")}</p>
                                            </td>
                                          </tr>
                                          <tr className="table-row-custom">
                                            <td>Mr. John Doe</td>
                                            <td>Pickup-Truck</td>
                                            <td>P-101</td>
                                            <td className="De-assign">
                                              <p>{t("De-assign")}</p>
                                            </td>
                                          </tr>
                                          <tr className="table-row-custom">
                                            <td>Mr. John Doe</td>
                                            <td>Pickup-Truck</td>
                                            <td>P-101</td>
                                            <td className="De-assign">
                                              <p>{t("De-assign")}</p>
                                            </td>
                                          </tr>
                                          <tr className="table-row-custom">
                                            <td>Mr. John Doe</td>
                                            <td>Pickup-Truck</td>
                                            <td>P-101</td>
                                            <td className="De-assign">
                                              <p>{t("De-assign")}</p>
                                            </td>
                                          </tr>
                                          <tr className="table-row-custom">
                                            <td>Mr. John Doe</td>
                                            <td>Pickup-Truck</td>
                                            <td>P-101</td>
                                            <td className="De-assign">
                                              <p>{t("De-assign")}</p>
                                            </td>
                                          </tr>
                                          <tr className="table-row-custom">
                                            <td>Mr. John Doe</td>
                                            <td>Pickup-Truck</td>
                                            <td>P-101</td>
                                            <td className="De-assign">
                                              <p>{t("De-assign")}</p>
                                            </td>
                                          </tr>
                                          <tr className="table-row-custom">
                                            <td>Mr. John Doe</td>
                                            <td>Pickup-Truck</td>
                                            <td>P-101</td>
                                            <td className="De-assign">
                                              <p>{t("De-assign")}</p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="6">
                                  <Accordion.Header>
                                    <Nav.Item
                                      onClick={() => {
                                        setTabClicked(!tabClicked);
                                        setNavClicked(!navClicked);
                                      }}
                                    >
                                      <Nav.Link eventKey="seven">
                                        <div className="AllFlexNav">
                                          <div>
                                            <p className="paraNav">
                                              {t("Station Name")}
                                            </p>
                                            <p className="paraNavTxt">
                                              Redbytes Parking
                                            </p>
                                          </div>
                                          <div>
                                            <p className="paraNav">
                                              {t("Station Code")}
                                            </p>
                                            <p className="paraNavTxt">
                                              Test1234
                                            </p>
                                          </div>
                                          <div>
                                            <p className="paraNav">
                                              {t("Parking Capacity")}
                                            </p>
                                            <p className="paraNavTxt">10/10</p>
                                          </div>
                                        </div>
                                      </Nav.Link>
                                    </Nav.Item>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <div
                                      className="main-master-wrapper mb-0 p-0"
                                      id="CustomerSupport"
                                    >
                                      <table className="holiday-table">
                                        <thead className="ht-head">
                                          <tr>
                                            <td>{t("Driver Name")}</td>
                                            <td>{t("Vehicle")}</td>
                                            <td>{t("Parking Code")}</td>
                                            <td>{t("Action")}</td>
                                          </tr>
                                        </thead>
                                        <tbody className="ht-body">
                                          <tr className="table-row-custom">
                                            <td>Mr. John Doe</td>
                                            <td>Pickup-Truck</td>
                                            <td>P-101</td>
                                            <td className="De-assign">
                                              <p>{t("De-assign")}</p>
                                            </td>
                                          </tr>
                                          <tr className="table-row-custom">
                                            <td>Mr. John Doe</td>
                                            <td>Pickup-Truck</td>
                                            <td>P-101</td>
                                            <td className="De-assign">
                                              <p>{t("De-assign")}</p>
                                            </td>
                                          </tr>
                                          <tr className="table-row-custom">
                                            <td>Mr. John Doe</td>
                                            <td>Pickup-Truck</td>
                                            <td>P-101</td>
                                            <td className="De-assign">
                                              <p>{t("De-assign")}</p>
                                            </td>
                                          </tr>
                                          <tr className="table-row-custom">
                                            <td>Mr. John Doe</td>
                                            <td>Pickup-Truck</td>
                                            <td>P-101</td>
                                            <td className="De-assign">
                                              <p>{t("De-assign")}</p>
                                            </td>
                                          </tr>
                                          <tr className="table-row-custom">
                                            <td>Mr. John Doe</td>
                                            <td>Pickup-Truck</td>
                                            <td>P-101</td>
                                            <td className="De-assign">
                                              <p>{t("De-assign")}</p>
                                            </td>
                                          </tr>
                                          <tr className="table-row-custom">
                                            <td>Mr. John Doe</td>
                                            <td>Pickup-Truck</td>
                                            <td>P-101</td>
                                            <td className="De-assign">
                                              <p>{t("De-assign")}</p>
                                            </td>
                                          </tr>
                                          <tr className="table-row-custom">
                                            <td>Mr. John Doe</td>
                                            <td>Pickup-Truck</td>
                                            <td>P-101</td>
                                            <td className="De-assign">
                                              <p>{t("De-assign")}</p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="7">
                                  <Accordion.Header>
                                    <Nav.Item
                                      onClick={() => {
                                        setTabClicked(!tabClicked);
                                        setNavClicked(!navClicked);
                                      }}
                                    >
                                      <Nav.Link eventKey="eight">
                                        <div className="AllFlexNav">
                                          <div>
                                            <p className="paraNav">
                                              {t("Station Name")}
                                            </p>
                                            <p className="paraNavTxt">
                                              Redbytes Parking
                                            </p>
                                          </div>
                                          <div>
                                            <p className="paraNav">
                                              {t("Station Code")}
                                            </p>
                                            <p className="paraNavTxt">
                                              Test1234
                                            </p>
                                          </div>
                                          <div>
                                            <p className="paraNav">
                                              {t("Parking Capacity")}
                                            </p>
                                            <p className="paraNavTxt">10/10</p>
                                          </div>
                                        </div>
                                      </Nav.Link>
                                    </Nav.Item>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <div
                                      className="main-master-wrapper mb-0 p-0"
                                      id="CustomerSupport"
                                    >
                                      <table className="holiday-table">
                                        <thead className="ht-head">
                                          <tr>
                                            <td>{t("Driver Name")}</td>
                                            <td>{t("Vehicle")}</td>
                                            <td>{t("Parking Code")}</td>
                                            <td>{t("Action")}</td>
                                          </tr>
                                        </thead>
                                        <tbody className="ht-body">
                                          <tr className="table-row-custom">
                                            <td>Mr. John Doe</td>
                                            <td>Pickup-Truck</td>
                                            <td>P-101</td>
                                            <td className="De-assign">
                                              <p>{t("De-assign")}</p>
                                            </td>
                                          </tr>
                                          <tr className="table-row-custom">
                                            <td>Mr. John Doe</td>
                                            <td>Pickup-Truck</td>
                                            <td>P-101</td>
                                            <td className="De-assign">
                                              <p>{t("De-assign")}</p>
                                            </td>
                                          </tr>
                                          <tr className="table-row-custom">
                                            <td>Mr. John Doe</td>
                                            <td>Pickup-Truck</td>
                                            <td>P-101</td>
                                            <td className="De-assign">
                                              <p>{t("De-assign")}</p>
                                            </td>
                                          </tr>
                                          <tr className="table-row-custom">
                                            <td>Mr. John Doe</td>
                                            <td>Pickup-Truck</td>
                                            <td>P-101</td>
                                            <td className="De-assign">
                                              <p>{t("De-assign")}</p>
                                            </td>
                                          </tr>
                                          <tr className="table-row-custom">
                                            <td>Mr. John Doe</td>
                                            <td>Pickup-Truck</td>
                                            <td>P-101</td>
                                            <td className="De-assign">
                                              <p>{t("De-assign")}</p>
                                            </td>
                                          </tr>
                                          <tr className="table-row-custom">
                                            <td>Mr. John Doe</td>
                                            <td>Pickup-Truck</td>
                                            <td>P-101</td>
                                            <td className="De-assign">
                                              <p>{t("De-assign")}</p>
                                            </td>
                                          </tr>
                                          <tr className="table-row-custom">
                                            <td>Mr. John Doe</td>
                                            <td>Pickup-Truck</td>
                                            <td>P-101</td>
                                            <td className="De-assign">
                                              <p>{t("De-assign")}</p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="8">
                                  <Accordion.Header>
                                    <Nav.Item
                                      onClick={() => {
                                        setTabClicked(!tabClicked);
                                        setNavClicked(!navClicked);
                                      }}
                                    >
                                      <Nav.Link eventKey="nine">
                                        <div className="AllFlexNav">
                                          <div>
                                            <p className="paraNav">
                                              {t("Station Name")}
                                            </p>
                                            <p className="paraNavTxt">
                                              Redbytes Parking
                                            </p>
                                          </div>
                                          <div>
                                            <p className="paraNav">
                                              {t("Station Code")}
                                            </p>
                                            <p className="paraNavTxt">
                                              Test1234
                                            </p>
                                          </div>
                                          <div>
                                            <p className="paraNav">
                                              {t("Parking Capacity")}
                                            </p>
                                            <p className="paraNavTxt">10/10</p>
                                          </div>
                                        </div>
                                      </Nav.Link>
                                    </Nav.Item>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <div
                                      className="main-master-wrapper mb-0 p-0"
                                      id="CustomerSupport"
                                    >
                                      <table className="holiday-table">
                                        <thead className="ht-head">
                                          <tr>
                                            <td>{t("Driver Name")}</td>
                                            <td>{t("Vehicle")}</td>
                                            <td>{t("Parking Code")}</td>
                                            <td>{t("Action")}</td>
                                          </tr>
                                        </thead>
                                        <tbody className="ht-body">
                                          <tr className="table-row-custom">
                                            <td>Mr. John Doe</td>
                                            <td>Pickup-Truck</td>
                                            <td>P-101</td>
                                            <td className="De-assign">
                                              <p>{t("De-assign")}</p>
                                            </td>
                                          </tr>
                                          <tr className="table-row-custom">
                                            <td>Mr. John Doe</td>
                                            <td>Pickup-Truck</td>
                                            <td>P-101</td>
                                            <td className="De-assign">
                                              <p>{t("De-assign")}</p>
                                            </td>
                                          </tr>
                                          <tr className="table-row-custom">
                                            <td>Mr. John Doe</td>
                                            <td>Pickup-Truck</td>
                                            <td>P-101</td>
                                            <td className="De-assign">
                                              <p>{t("De-assign")}</p>
                                            </td>
                                          </tr>
                                          <tr className="table-row-custom">
                                            <td>Mr. John Doe</td>
                                            <td>Pickup-Truck</td>
                                            <td>P-101</td>
                                            <td className="De-assign">
                                              <p>{t("De-assign")}</p>
                                            </td>
                                          </tr>
                                          <tr className="table-row-custom">
                                            <td>Mr. John Doe</td>
                                            <td>Pickup-Truck</td>
                                            <td>P-101</td>
                                            <td className="De-assign">
                                              <p>{t("De-assign")}</p>
                                            </td>
                                          </tr>
                                          <tr className="table-row-custom">
                                            <td>Mr. John Doe</td>
                                            <td>Pickup-Truck</td>
                                            <td>P-101</td>
                                            <td className="De-assign">
                                              <p>{t("De-assign")}</p>
                                            </td>
                                          </tr>
                                          <tr className="table-row-custom">
                                            <td>Mr. John Doe</td>
                                            <td>Pickup-Truck</td>
                                            <td>P-101</td>
                                            <td className="De-assign">
                                              <p>{t("De-assign")}</p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            </Nav>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="1">
                          <Nav
                            variant="pills"
                            className="flex-column"
                            id="navPills"
                          >
                            <div id="ParkingManagment">
                              <div
                                className="main-master-wrapper mb-0 p-0 rounded-0"
                                id="CustomerSupport"
                              >
                                <input
                                  type="search"
                                  className="form-control"
                                  placeholder="Search Driver or Vehicle to assign the parking station "
                                />
                                <table className="holiday-table">
                                  <tbody className="ht-body">
                                    <tr className="table-row-custom">
                                      <td>Mr. John Doe</td>
                                      <td>Pickup-Truck</td>
                                      <td className="Assign">
                                        <p>{t("Assign")}</p>
                                      </td>
                                    </tr>
                                    <tr className="table-row-custom">
                                      <td>Mr. John Doe</td>
                                      <td>Pickup-Truck</td>
                                      <td className="Assign">
                                        <p>{t("Assign")}</p>
                                      </td>
                                    </tr>
                                    <tr className="table-row-custom">
                                      <td>Mr. John Doe</td>
                                      <td>Pickup-Truck</td>
                                      <td className="Assign">
                                        <p>{t("Assign")}</p>
                                      </td>
                                    </tr>
                                    <tr className="table-row-custom">
                                      <td>Mr. John Doe</td>
                                      <td>Pickup-Truck</td>
                                      <td className="Assign">
                                        <p>{t("Assign")}</p>
                                      </td>
                                    </tr>
                                    <tr className="table-row-custom">
                                      <td>Mr. John Doe</td>
                                      <td>Pickup-Truck</td>
                                      <td className="Assign">
                                        <p>{t("Assign")}</p>
                                      </td>
                                    </tr>
                                    <tr className="table-row-custom">
                                      <td>Mr. John Doe</td>
                                      <td>Pickup-Truck</td>
                                      <td className="Assign">
                                        <p>{t("Assign")}</p>
                                      </td>
                                    </tr>
                                    <tr className="table-row-custom">
                                      <td>Mr. John Doe</td>
                                      <td>Pickup-Truck</td>
                                      <td className="Assign">
                                        <p>{t("Assign")}</p>
                                      </td>
                                    </tr>
                                    <tr className="table-row-custom">
                                      <td>Mr. John Doe</td>
                                      <td>Pickup-Truck</td>
                                      <td className="Assign">
                                        <p>{t("Assign")}</p>
                                      </td>
                                    </tr>
                                    <tr className="table-row-custom">
                                      <td>Mr. John Doe</td>
                                      <td>Pickup-Truck</td>
                                      <td className="Assign">
                                        <p>{t("Assign")}</p>
                                      </td>
                                    </tr>
                                    <tr className="table-row-custom">
                                      <td>Mr. John Doe</td>
                                      <td>Pickup-Truck</td>
                                      <td className="Assign">
                                        <p>{t("Assign")}</p>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </Nav>
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Tab.Container>
                </div>
              </div>
            </div>
          </div>
          {slider ? <ExistButton onClose={() => setSlider(false)} /> : null}
          {!slider && <PreviousButton onBack={handleClickPrevious} />}
          {!slider && <NextButton onClick={handleClick} />}
          {!slider && <InfoComponent />}
        </div>
      </motion.div>

      {/* Delete Modal Start */}
      <Modal show={show} onHide={handleClose} centered className="common-model">
        <Modal.Header closeButton>
          <Modal.Title>{t("Delete Parking Management")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("Are you sure you want to delete this parking Management")} ?
        </Modal.Body>
        <Modal.Footer className="pop-up-modal-footer btn-wrapper">
          <div class="btn-wrapper">
            <button className="cx-btn-1" onClick={handleClose}>
              {t("Cancel")}
            </button>
            <button className="cx-btn-2" onClick={handleClose}>
              {t("Yes")}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* Delete Modal End */}
    </>
  );
};

export default ParkingManagementone;
