// Usama 09-02-2023
import React, { useContext, useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Carousel, Col, Dropdown, Modal, Tab, Tabs } from "react-bootstrap";
import { AppContext } from "../../context/AppContext";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import DDlogo from "../../assets/images/DDlogo.png";
import Export from "../../assets/images/ic-Export.svg";
import Import from "../../assets/images/ic-Import.svg";
import Gcar from "../../assets/images/Green-car-logo.svg";
import option from "../../assets/images/option-three-dot.svg";
import { motion } from "framer-motion";
import Nav from "react-bootstrap/Nav";
import Gbike from "../../assets/images/green-bike-map.svg";
import Rbike from "../../assets/images/red-bike-map.svg";
import Bcar from "../../assets/images/Blue-car-logo.svg";
import Rcar from "../../assets/images/red-car-logo.svg";
import BKcar from "../../assets/images/black-car-logo.svg";
import GRbike from "../../assets/images/Grey-bike-logo.svg";
import GRcar from "../../assets/images/Grey-car-logo.svg";
import Ycar from "../../assets/images/yellow-car-logo.svg";
import Ybike from "../../assets/images/yellow-bike-map.svg";
import YQcar from "../../assets/images/yellow-qution-car.svg";
import YQbike from "../../assets/images/yellow-qution-bike.svg";
import Cat_ye_car from "../../assets/images/Catagiry_yellow_car.svg";
import Cat_ye_bike from "../../assets/images/Catagiry_yellow_bike.svg";
import Feature_CarLock_active from "../../assets/images/Feature_CarLock_active.svg";
import Feature_Fuel_active from "../../assets/images/Feature_Fule_active.svg";
import Feature_Fuel from "../../assets/images/Feature_Fule.svg";
import Feature_temp_active from "../../assets/images/Feature_temp_active.svg";
import Feature_temp from "../../assets/images/Feature_temp1.svg";
import Feature_I_active from "../../assets/images/Feature_i_active.svg";
import Feature_I from "../../assets/images/Feature_I.svg";
import Feature_Echo_active from "../../assets/images/Feature_Echo_active.svg";
import Feature_Echo from "../../assets/images/Feture_echo.svg";
import Feature_Seat_active from "../../assets/images/Feature_Seat_active.svg";
import Feature_Seat from "../../assets/images/Feature_Seat.svg";
import Feature_IVMS_active from "../../assets/images/Feature_IVMS_active.svg";
import Feature_IVMS from "../../assets/images/Feature_IVMS.svg";
import Feature_Card_active from "../../assets/images/Feature_Card_active.svg";
import Feature_Card from "../../assets/images/Feature_Card.svg";
import Feature_Overspeed_active from "../../assets/images/Feature_Overspeed_active.svg";
import Feature_Overspeed from "../../assets/images/Feature_overspeed.svg";
import Feature_Crash_active from "../../assets/images/Feature_Crash_active.svg";
import Feature_Crash from "../../assets/images/Feature_creash.svg";
import Feature_Towing_active from "../../assets/images/Feature_Towing_active.svg";
import Feature_Towing from "../../assets/images/Feature_Towing.svg";
import Feature_Unplag_active from "../../assets/images/Feature_Unplag_active.svg";
import Feature_Unplag from "../../assets/images/Feature_Unplag.svg";
import Feature_Exicess_active from "../../assets/images/Feature_Exicess_active.svg";
import Feature_Exicess from "../../assets/images/Feature_Exicess.svg";
import Feature_CarLock from "../../assets/images/Feature_CarLock.svg";
import Pump from "../../assets/images/Petrol-Pump.svg";
import route from "../../assets/images/Road_Route.svg";
import Seats from "../../assets/images/Seats.svg";
import Repair from "../../assets/images/Repair.svg";
import weight from "../../assets/images/weight.svg";
import EVlogo from "../../assets/images/EV-logo.svg";
import Qustiontracking from "../../assets/images/Qustion-tracking.svg";
import arrow from "../../assets/images/ic_line_arrow_left.svg";
import Grouplogo from "../../assets/images/Group-logo.svg";
import { Link, useNavigate } from "react-router-dom";
import { useNavItem } from "@restart/ui/esm/NavItem";
import idle from "../../assets/images/idle.svg";
import whiteTrash from "../../assets/images/whiteTrash.svg";
import whiteHand from "../../assets/images/whiteHand.svg";
import whiteBox from "../../assets/images/whiteBox.svg";
import whiteBin from "../../assets/images/whiteBin.svg";
import blue_box from "../../assets/images/blue_box.svg";
import green_box from "../../assets/images/green_box.svg";
import Pagenation from "../../sharedComponent/Pagenation";
import MapComponent from "../../sharedComponent/MapComponent";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";

const Vehicle = () => {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [view, setView] = useState(false);
  const [vCars, setVCars] = useState(false);
  const [vBike, setVBike] = useState(false);
  const [state, setState] = useState(false);

  const showView = () => {
    setView(true);
  };
  const hideView = () => {
    setView(false);
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  //category
  const [category, setCategory] = useState(false);
  const DeleteCategory = () => setShow(false);
  const showCategory = () => setShow(true);
  //group
  const [group, setGroup] = useState(false);
  const DeleteGroup = () => setShow(false);
  const showGroup = () => setShow(true);

  const { sidebar, setSidebar, Dark, setDark } = useContext(AppContext);

  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  const [Immobilization, setImmobilization] = useState(true);
  const [Immobilization1, setImmobilization1] = useState(true);
  const [Tempr, setTempr] = useState(true);
  const [Fuel, setFuel] = useState(true);
  const [ibtn, setIbtn] = useState(true);
  const [seat, setSeat] = useState(true);
  const [echo, setEcho] = useState(true);
  const [ivms, setIVMS] = useState(true);
  const [card, setCard] = useState(true);
  const [speed, setSpeed] = useState(true);
  const [cresh, setCresh] = useState(true);
  const [exicess, setExicess] = useState(true);
  const [towing, setTowing] = useState(true);
  const [plug, setPlug] = useState(true);

  return (
    <motion.div
      variants={aninations}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.1 }}
      className={sidebar ? "taskMain " : "cx-active taskMain"}
      id="cx-main"
    >
      <div id="cx-wrapper" className="Vehicle_Main">
        <div className="Vehcle-main-tabs">
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Nav variant="pills" id="newTabMai" className="tob_nav_pills">
              <Nav.Item>
                <Nav.Link eventKey="first">{t("Vehicle")}</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="second">{t("Vehicle Category")}</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="three">{t("Vehicle Group")}</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="four">{t("Sync")}</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Five">{t("Hardware Feature-Set")}</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="first">
                <Link to="/AddVehicle">
                  <button className="cx-btn-1 tbBtn">
                    + {t("Add Vehicle")}
                  </button>
                </Link>
                <div className="main-master-wrapper mb-0 inner-tabs-section overflow-hidden length-height">
                  <div id="scroll_insideThe_Padding">
                    <Tab.Container
                      id="left-tabs-example"
                      className="td-tab-wrapper"
                      defaultActiveKey="0"
                    >
                      <Nav
                        variant="pills"
                        className="td-nav"
                        id="InnerTabNew_Foure"
                      >
                        <Nav.Item className="td-tab">
                          <Nav.Link className="td-link" eventKey="0">
                            {t("All")}
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="td-tab">
                          <Nav.Link className="td-link" eventKey="1">
                            {t("Online")}
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="td-tab">
                          <Nav.Link className="td-link" eventKey="2">
                            {t("Offline")}
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="td-tab">
                          <Nav.Link className="td-link" eventKey="3">
                            {t("Untracked/Not Tracked Yet")}
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <Col sm={12} className="">
                        <Tab.Content>
                          <Tab.Pane eventKey="0">
                            <div className="all-vehicle-main">
                              <div className="all-vehical-head row vehicle-top-inputs">
                                <div className="input-section-wrapper">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Vehicle Name,No., Reg. No, IMEI..."
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Vehicle Category"
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Vehicle Capacity"
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                                      <select
                                        required
                                        className="form-select"
                                        aria-label="Default select example"
                                        placeholder="Transportation Type"
                                      >
                                        <option value="">
                                          Transportation Type
                                        </option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="right-export-btn-section-wrapper">
                                  <div className="c-pointer me-2">
                                    <img src={Export} alt="" />
                                  </div>
                                  <div className="c-pointer">
                                    <img src={Import} alt="" />
                                  </div>
                                </div>
                              </div>
                              <div className="yauto" id="arrange-paading">
                                <div
                                  className="row main-cards-wrapper gx-3"
                                  data-aos="zoom-in"
                                  data-aos-duration="500"
                                >
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Gcar} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}
                                            </p>
                                            <p className="title">Utility CAB</p>
                                          </div>
                                        </div>
                                        <div className="option customer-option">
                                          <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                              <img src={option} alt="" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/VehicleDetails"
                                                  className="d-block"
                                                >
                                                  {t("View")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/EditVehicle"
                                                  className="d-block"
                                                >
                                                  {t("Edit")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={handleShow}
                                              >
                                                <Link
                                                  to="#"
                                                  className="d-block"
                                                >
                                                  {t("Delete")}
                                                </Link>
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Number")}
                                          </p>
                                          <p className="title">MH-12-5022</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Type")}
                                          </p>
                                          <p className="title">Car</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Transportation Type")}
                                          </p>
                                          <p className="title">Passenger</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Capacity / Passenger")}
                                          </p>
                                          <p className="title">60</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Gbike} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}
                                            </p>
                                            <p className="title">Honda Bike</p>
                                          </div>
                                        </div>
                                        <div className="option customer-option">
                                          <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                              <img src={option} alt="" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/VehicleDetails"
                                                  className="d-block"
                                                >
                                                  {t("View")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/EditVehicle"
                                                  className="d-block"
                                                >
                                                  {t("Edit")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={handleShow}
                                              >
                                                <Link
                                                  to="#"
                                                  className="d-block"
                                                >
                                                  {t("Delete")}
                                                </Link>
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Number")}
                                          </p>
                                          <p className="title">MH-12-5022</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Type")}
                                          </p>
                                          <p className="title">Car</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Transportation Type")}
                                          </p>
                                          <p className="title">Passenger</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Capacity / Passenger")}
                                          </p>
                                          <p className="title">60</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Gcar} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div className="option customer-option">
                                          <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                              <img src={option} alt="" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/VehicleDetails"
                                                  className="d-block"
                                                >
                                                  {t("View")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/EditVehicle"
                                                  className="d-block"
                                                >
                                                  {t("Edit")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={handleShow}
                                              >
                                                <Link
                                                  to="#"
                                                  className="d-block"
                                                >
                                                  {t("Delete")}
                                                </Link>
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Number")}
                                          </p>
                                          <p className="title">MH-12-5022</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Type")}
                                          </p>
                                          <p className="title">Car</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Transportation Type")}
                                          </p>
                                          <p className="title">Passenger</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Capacity / Passenger")}
                                          </p>
                                          <p className="title">60</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Bcar} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div className="option customer-option">
                                          <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                              <img src={option} alt="" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/VehicleDetails"
                                                  className="d-block"
                                                >
                                                  {t("View")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/EditVehicle"
                                                  className="d-block"
                                                >
                                                  {t("Edit")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={handleShow}
                                              >
                                                <Link
                                                  to="#"
                                                  className="d-block"
                                                >
                                                  {t("Delete")}
                                                </Link>
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Number")}
                                          </p>
                                          <p className="title">MH-12-5022</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Type")}
                                          </p>
                                          <p className="title">Car</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Transportation Type")}
                                          </p>
                                          <p className="title">Passenger</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Capacity / Passenger")}
                                          </p>
                                          <p className="title">60</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Bcar} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}
                                            </p>
                                            <p className="title">
                                              Mercedes Bus{" "}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="option customer-option">
                                          <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                              <img src={option} alt="" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/VehicleDetails"
                                                  className="d-block"
                                                >
                                                  {t("View")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/EditVehicle"
                                                  className="d-block"
                                                >
                                                  {t("Edit")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={handleShow}
                                              >
                                                <Link
                                                  to="#"
                                                  className="d-block"
                                                >
                                                  {t("Delete")}
                                                </Link>
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Number")}
                                          </p>
                                          <p className="title">MH-12-5022</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Type")}
                                          </p>
                                          <p className="title">Car</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Transportation Type")}
                                          </p>
                                          <p className="title">Passenger</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Capacity / Passenger")}
                                          </p>
                                          <p className="title">60</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Bcar} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div className="option customer-option">
                                          <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                              <img src={option} alt="" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/VehicleDetails"
                                                  className="d-block"
                                                >
                                                  {t("View")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/EditVehicle"
                                                  className="d-block"
                                                >
                                                  {t("Edit")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={handleShow}
                                              >
                                                <Link
                                                  to="#"
                                                  className="d-block"
                                                >
                                                  {t("Delete")}
                                                </Link>
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Number")}
                                          </p>
                                          <p className="title">MH-12-5022</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Type")}
                                          </p>
                                          <p className="title">Car</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Transportation Type")}
                                          </p>
                                          <p className="title">Passenger</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Capacity / Passenger")}
                                          </p>
                                          <p className="title">60</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Rcar} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div className="option customer-option">
                                          <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                              <img src={option} alt="" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item>
                                                <Link to="/VehicleDetails">
                                                  {t("View")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item>
                                                <Link to="/EditVehicle">
                                                  {t("Edit")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={handleShow}
                                              >
                                                <Link to="#">
                                                  {t("Delete")}
                                                </Link>
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Number")}
                                          </p>
                                          <p className="title">MH-12-5022</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Type")}
                                          </p>
                                          <p className="title">Car</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Transportation Type")}
                                          </p>
                                          <p className="title">Passenger</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Capacity / Passenger")}
                                          </p>
                                          <p className="title">60</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={BKcar} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}
                                            </p>
                                            <p className="title">
                                              Mercedes Bus{" "}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="option customer-option">
                                          <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                              <img src={option} alt="" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item>
                                                <Link to="/VehicleDetails">
                                                  {t("View")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item>
                                                <Link to="/EditVehicle">
                                                  {t("Edit")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={handleShow}
                                              >
                                                <Link to="#">
                                                  {t("Delete")}
                                                </Link>
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Number")}
                                          </p>
                                          <p className="title">MH-12-5022</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Type")}
                                          </p>
                                          <p className="title">Car</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Transportation Type")}
                                          </p>
                                          <p className="title">Passenger</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Capacity / Passenger")}
                                          </p>
                                          <p className="title">60</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Rbike} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}
                                            </p>
                                            <p className="title">Honda Bike</p>
                                          </div>
                                        </div>
                                        <div className="option customer-option">
                                          <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                              <img src={option} alt="" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item>
                                                <Link to="/VehicleDetails">
                                                  {t("View")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item>
                                                <Link to="/EditVehicle">
                                                  {t("Edit")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={handleShow}
                                              >
                                                <Link to="#">
                                                  {t("Delete")}
                                                </Link>
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Number")}
                                          </p>
                                          <p className="title">MH-12-5022</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Type")}
                                          </p>
                                          <p className="title">Car</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Transportation Type")}
                                          </p>
                                          <p className="title">Passenger</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Capacity / Passenger")}
                                          </p>
                                          <p className="title">60</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="1">
                            <div className="all-vehicle-main">
                              <div className="all-vehical-head row vehicle-top-inputs">
                                <div className="input-section-wrapper">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Vehicle Name,No., Reg. No, IMEI..."
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Vehicle Category"
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Vehicle Capacity"
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                                      <select
                                        required
                                        className="form-select"
                                        aria-label="Default select example"
                                        placeholder="Transportation Type"
                                      >
                                        <option value="">
                                          Transportation Type
                                        </option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="right-export-btn-section-wrapper">
                                  <div className="c-pointer me-2">
                                    <img src={Export} alt="" />
                                  </div>
                                  <div className="c-pointer">
                                    <img src={Import} alt="" />
                                  </div>
                                </div>
                              </div>
                              <div className="yauto" id="arrange-paading">
                                <div
                                  className="row main-cards-wrapper gx-3"
                                  data-aos="zoom-in"
                                  data-aos-duration="500"
                                >
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Gcar} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}
                                            </p>
                                            <p className="title">Utility CAB</p>
                                          </div>
                                        </div>
                                        <div className="option customer-option">
                                          <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                              <img src={option} alt="" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/VehicleDetails"
                                                  className="d-block"
                                                >
                                                  {t("View")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/EditVehicle"
                                                  className="d-block"
                                                >
                                                  {t("Edit")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={handleShow}
                                              >
                                                <Link
                                                  to="#"
                                                  className="d-block"
                                                >
                                                  {t("Delete")}
                                                </Link>
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Number")}
                                          </p>
                                          <p className="title">MH-12-5022</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Type")}
                                          </p>
                                          <p className="title">Car</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Transportation Type")}
                                          </p>
                                          <p className="title">Passenger</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Capacity / Passenger")}
                                          </p>
                                          <p className="title">60</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Gbike} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}
                                            </p>
                                            <p className="title">Honda Bike</p>
                                          </div>
                                        </div>
                                        <div className="option customer-option">
                                          <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                              <img src={option} alt="" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/VehicleDetails"
                                                  className="d-block"
                                                >
                                                  {t("View")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/EditVehicle"
                                                  className="d-block"
                                                >
                                                  {t("Edit")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={handleShow}
                                              >
                                                <Link
                                                  to="#"
                                                  className="d-block"
                                                >
                                                  {t("Delete")}
                                                </Link>
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Number")}
                                          </p>
                                          <p className="title">MH-12-5022</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Type")}
                                          </p>
                                          <p className="title">Car</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Transportation Type")}
                                          </p>
                                          <p className="title">Passenger</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Capacity / Passenger")}
                                          </p>
                                          <p className="title">60</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Gcar} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div className="option customer-option">
                                          <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                              <img src={option} alt="" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/VehicleDetails"
                                                  className="d-block"
                                                >
                                                  {t("View")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/EditVehicle"
                                                  className="d-block"
                                                >
                                                  {t("Edit")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={handleShow}
                                              >
                                                <Link
                                                  to="#"
                                                  className="d-block"
                                                >
                                                  {t("Delete")}
                                                </Link>
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Number")}
                                          </p>
                                          <p className="title">MH-12-5022</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Type")}
                                          </p>
                                          <p className="title">Car</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Transportation Type")}
                                          </p>
                                          <p className="title">Passenger</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Capacity / Passenger")}
                                          </p>
                                          <p className="title">60</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Bcar} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div className="option customer-option">
                                          <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                              <img src={option} alt="" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/VehicleDetails"
                                                  className="d-block"
                                                >
                                                  {t("View")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/EditVehicle"
                                                  className="d-block"
                                                >
                                                  {t("Edit")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={handleShow}
                                              >
                                                <Link
                                                  to="#"
                                                  className="d-block"
                                                >
                                                  {t("Delete")}
                                                </Link>
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Number")}
                                          </p>
                                          <p className="title">MH-12-5022</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Type")}
                                          </p>
                                          <p className="title">Car</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Transportation Type")}
                                          </p>
                                          <p className="title">Passenger</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Capacity / Passenger")}
                                          </p>
                                          <p className="title">60</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Bcar} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}
                                            </p>
                                            <p className="title">
                                              Mercedes Bus{" "}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="option customer-option">
                                          <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                              <img src={option} alt="" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/VehicleDetails"
                                                  className="d-block"
                                                >
                                                  {t("View")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/EditVehicle"
                                                  className="d-block"
                                                >
                                                  {t("Edit")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={handleShow}
                                              >
                                                <Link
                                                  to="#"
                                                  className="d-block"
                                                >
                                                  {t("Delete")}
                                                </Link>
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Number")}
                                          </p>
                                          <p className="title">MH-12-5022</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Type")}
                                          </p>
                                          <p className="title">Car</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Transportation Type")}
                                          </p>
                                          <p className="title">Passenger</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Capacity / Passenger")}
                                          </p>
                                          <p className="title">60</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Bcar} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div className="option customer-option">
                                          <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                              <img src={option} alt="" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/VehicleDetails"
                                                  className="d-block"
                                                >
                                                  {t("View")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/EditVehicle"
                                                  className="d-block"
                                                >
                                                  {t("Edit")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={handleShow}
                                              >
                                                <Link
                                                  to="#"
                                                  className="d-block"
                                                >
                                                  {t("Delete")}
                                                </Link>
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Number")}
                                          </p>
                                          <p className="title">MH-12-5022</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Type")}
                                          </p>
                                          <p className="title">Car</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Transportation Type")}
                                          </p>
                                          <p className="title">Passenger</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Capacity / Passenger")}
                                          </p>
                                          <p className="title">60</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Rcar} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div className="option customer-option">
                                          <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                              <img src={option} alt="" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item>
                                                <Link to="/VehicleDetails">
                                                  {t("View")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item>
                                                <Link to="/EditVehicle">
                                                  {t("Edit")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={handleShow}
                                              >
                                                <Link to="#">
                                                  {t("Delete")}
                                                </Link>
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Number")}
                                          </p>
                                          <p className="title">MH-12-5022</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Type")}
                                          </p>
                                          <p className="title">Car</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Transportation Type")}
                                          </p>
                                          <p className="title">Passenger</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Capacity / Passenger")}
                                          </p>
                                          <p className="title">60</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={BKcar} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}
                                            </p>
                                            <p className="title">
                                              Mercedes Bus{" "}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="option customer-option">
                                          <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                              <img src={option} alt="" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item>
                                                <Link to="/VehicleDetails">
                                                  {t("View")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item>
                                                <Link to="/EditVehicle">
                                                  {t("Edit")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={handleShow}
                                              >
                                                <Link to="#">
                                                  {t("Delete")}
                                                </Link>
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Number")}
                                          </p>
                                          <p className="title">MH-12-5022</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Type")}
                                          </p>
                                          <p className="title">Car</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Transportation Type")}
                                          </p>
                                          <p className="title">Passenger</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Capacity / Passenger")}
                                          </p>
                                          <p className="title">60</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Rbike} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}
                                            </p>
                                            <p className="title">Honda Bike</p>
                                          </div>
                                        </div>
                                        <div className="option customer-option">
                                          <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                              <img src={option} alt="" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item>
                                                <Link to="/VehicleDetails">
                                                  {t("View")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item>
                                                <Link to="/EditVehicle">
                                                  {t("Edit")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={handleShow}
                                              >
                                                <Link to="#">
                                                  {t("Delete")}
                                                </Link>
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Number")}
                                          </p>
                                          <p className="title">MH-12-5022</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Type")}
                                          </p>
                                          <p className="title">Car</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Transportation Type")}
                                          </p>
                                          <p className="title">Passenger</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Capacity / Passenger")}
                                          </p>
                                          <p className="title">60</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="2">
                            <div className="all-vehicle-main">
                              <div className="all-vehical-head row vehicle-top-inputs">
                                <div className="input-section-wrapper">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Vehicle Name,No., Reg. No, IMEI..."
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Vehicle Category"
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Vehicle Capacity"
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                                      <select
                                        required
                                        className="form-select"
                                        aria-label="Default select example"
                                        placeholder="Transportation Type"
                                      >
                                        <option value="">
                                          Transportation Type
                                        </option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="right-export-btn-section-wrapper">
                                  <div className="c-pointer me-2">
                                    <img src={Export} alt="" />
                                  </div>
                                  <div className="c-pointer">
                                    <img src={Import} alt="" />
                                  </div>
                                </div>
                              </div>
                              {/* ===================== Ofline cards =========== */}
                              <div className="yauto" id="arrange-paading">
                                <div className="row main-cards-wrapper gx-3">
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={GRcar} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}{" "}
                                            </p>
                                            <p className="title">Utility CAB</p>
                                          </div>
                                        </div>
                                        <div className="option customer-option">
                                          <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                              <img src={option} alt="" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/VehicleDetails"
                                                  className="d-block"
                                                >
                                                  {t("View")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={handleShow}
                                              >
                                                <Link
                                                  to="#"
                                                  className="d-block"
                                                >
                                                  {t("Delete")}
                                                </Link>
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Number")}
                                          </p>
                                          <p className="title">MH-12-5022</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Type")}
                                          </p>
                                          <p className="title">Car</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Transportation Type")}
                                          </p>
                                          <p className="title">Passenger</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Capacity / Passenger")}
                                          </p>
                                          <p className="title">60</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={GRbike} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}{" "}
                                            </p>
                                            <p className="title">Honda Bike</p>
                                          </div>
                                        </div>
                                        <div className="option customer-option">
                                          <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                              <img src={option} alt="" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/VehicleDetails"
                                                  className="d-block"
                                                >
                                                  {t("View")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={handleShow}
                                              >
                                                <Link
                                                  to="#"
                                                  className="d-block"
                                                >
                                                  {t("Delete")}
                                                </Link>
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Number")}
                                          </p>
                                          <p className="title">MH-12-5022</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Type")}
                                          </p>
                                          <p className="title">Car</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Transportation Type")}
                                          </p>
                                          <p className="title">Passenger</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Capacity / Passenger")}
                                          </p>
                                          <p className="title">60</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={GRcar} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              Vehicle Name
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div className="option customer-option">
                                          <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                              <img src={option} alt="" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/VehicleDetails"
                                                  className="d-block"
                                                >
                                                  {t("View")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={handleShow}
                                              >
                                                <Link
                                                  to="#"
                                                  className="d-block"
                                                >
                                                  {t("Delete")}
                                                </Link>
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Number")}
                                          </p>
                                          <p className="title">MH-12-5022</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Type")}
                                          </p>
                                          <p className="title">Car</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Transportation Type")}
                                          </p>
                                          <p className="title">Passenger</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Capacity / Passenger")}
                                          </p>
                                          <p className="title">60</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={GRcar} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              Vehicle Name
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div className="option customer-option">
                                          <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                              <img src={option} alt="" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/VehicleDetails"
                                                  className="d-block"
                                                >
                                                  {t("View")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={handleShow}
                                              >
                                                <Link
                                                  to="#"
                                                  className="d-block"
                                                >
                                                  {t("Delete")}
                                                </Link>
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Number")}
                                          </p>
                                          <p className="title">MH-12-5022</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Type")}
                                          </p>
                                          <p className="title">Car</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Transportation Type")}
                                          </p>
                                          <p className="title">Passenger</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Capacity / Passenger")}
                                          </p>
                                          <p className="title">60</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={GRcar} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}{" "}
                                            </p>
                                            <p className="title">
                                              Mercedes Bus{" "}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="option customer-option">
                                          <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                              <img src={option} alt="" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/VehicleDetails"
                                                  className="d-block"
                                                >
                                                  {t("View")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={handleShow}
                                              >
                                                <Link
                                                  to="#"
                                                  className="d-block"
                                                >
                                                  {t("Delete")}
                                                </Link>
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Number")}
                                          </p>
                                          <p className="title">MH-12-5022</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Type")}
                                          </p>
                                          <p className="title">Car</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Transportation Type")}
                                          </p>
                                          <p className="title">Passenger</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Capacity / Passenger")}
                                          </p>
                                          <p className="title">60</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={GRcar} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}{" "}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div className="option customer-option">
                                          <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                              <img src={option} alt="" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/VehicleDetails"
                                                  className="d-block"
                                                >
                                                  {t("View")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={handleShow}
                                              >
                                                <Link
                                                  to="#"
                                                  className="d-block"
                                                >
                                                  {t("Delete")}
                                                </Link>
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Number")}
                                          </p>
                                          <p className="title">MH-12-5022</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Type")}
                                          </p>
                                          <p className="title">Car</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Transportation Type")}
                                          </p>
                                          <p className="title">Passenger</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Capacity / Passenger")}
                                          </p>
                                          <p className="title">60</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={GRcar} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}{" "}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div className="option customer-option">
                                          <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                              <img src={option} alt="" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/VehicleDetails"
                                                  className="d-block"
                                                >
                                                  {t("View")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={handleShow}
                                              >
                                                <Link
                                                  to="#"
                                                  className="d-block"
                                                >
                                                  {t("Delete")}
                                                </Link>
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Number")}
                                          </p>
                                          <p className="title">MH-12-5022</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Type")}
                                          </p>
                                          <p className="title">Car</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Transportation Type")}
                                          </p>
                                          <p className="title">Passenger</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Capacity / Passenger")}
                                          </p>
                                          <p className="title">60</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={GRcar} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}{" "}
                                            </p>
                                            <p className="title">
                                              Mercedes Bus{" "}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="option customer-option">
                                          <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                              <img src={option} alt="" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/VehicleDetails"
                                                  className="d-block"
                                                >
                                                  {t("View")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={handleShow}
                                              >
                                                <Link
                                                  to="#"
                                                  className="d-block"
                                                >
                                                  {t("Delete")}
                                                </Link>
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Number")}
                                          </p>
                                          <p className="title">MH-12-5022</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Type")}
                                          </p>
                                          <p className="title">Car</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Transportation Type")}
                                          </p>
                                          <p className="title">Passenger</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Capacity / Passenger")}
                                          </p>
                                          <p className="title">60</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={
                                        sidebar
                                          ? "common-vehical-card"
                                          : "common-vehical-card-inner"
                                      }
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={GRbike} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}
                                            </p>
                                            <p className="title">Honda Bike</p>
                                          </div>
                                        </div>
                                        <div className="option customer-option">
                                          <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                              <img src={option} alt="" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/VehicleDetails"
                                                  className="d-block"
                                                >
                                                  {t("View")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={handleShow}
                                              >
                                                <Link
                                                  to="#"
                                                  className="d-block"
                                                >
                                                  {t("Delete")}
                                                </Link>
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Number")}
                                          </p>
                                          <p className="title">MH-12-5022</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Type")}
                                          </p>
                                          <p className="title">Car</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Transportation Type")}
                                          </p>
                                          <p className="title">Passenger</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Capacity / Passenger")}
                                          </p>
                                          <p className="title">60</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="3">
                            <div className="all-vehicle-main">
                              <div className="all-vehical-head row vehicle-top-inputs">
                                <div className="input-section-wrapper">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Vehicle Name,No., Reg. No, IMEI..."
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Vehicle Category"
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Vehicle Capacity"
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                                      <select
                                        required
                                        className="form-select"
                                        aria-label="Default select example"
                                        placeholder="Transportation Type"
                                      >
                                        <option value="">
                                          Transportation Type
                                        </option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="right-export-btn-section-wrapper">
                                  <div className="c-pointer me-2">
                                    <img src={Export} alt="" />
                                  </div>
                                  <div className="c-pointer">
                                    <img src={Import} alt="" />
                                  </div>
                                </div>
                              </div>
                              <div className="yauto" id="arrange-paading">
                                <div
                                  className="row main-cards-wrapper gx-3"
                                  id="Custon_Height_for_UntrackAll"
                                >
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={YQcar} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}
                                            </p>
                                            <p className="title">Utility CAB</p>
                                          </div>
                                        </div>
                                        <div className="option customer-option">
                                          <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                              <img src={option} alt="" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/VehicleDetails"
                                                  className="d-block"
                                                >
                                                  {t("View")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={handleShow}
                                              >
                                                <Link
                                                  to="#"
                                                  className="d-block"
                                                >
                                                  {t("Delete")}
                                                </Link>
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Number")}
                                          </p>
                                          <p className="title">MH-12-5022</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Type")}
                                          </p>
                                          <p className="title">Car</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Transportation Type")}
                                          </p>
                                          <p className="title">Passenger</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Capacity / Passenger")}
                                          </p>
                                          <p className="title">60</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Ybike} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}
                                            </p>
                                            <p className="title">Honda Bike</p>
                                          </div>
                                        </div>
                                        <div className="option customer-option">
                                          <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                              <img src={option} alt="" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item>
                                                <Link to="/VehicleDetails">
                                                  View
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={handleShow}
                                              >
                                                <Link to="#">
                                                  {t("Delete")}
                                                </Link>
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Number")}
                                          </p>
                                          <p className="title">MH-12-5022</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Type")}
                                          </p>
                                          <p className="title">Car</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Transportation Type")}
                                          </p>
                                          <p className="title">Passenger</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Capacity / Passenger")}
                                          </p>
                                          <p className="title">60</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Ycar} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div className="option customer-option">
                                          <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                              <img src={option} alt="" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item>
                                                <Link to="/VehicleDetails">
                                                  View
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={handleShow}
                                              >
                                                <Link to="#">
                                                  {t("Delete")}
                                                </Link>
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Number")}
                                          </p>
                                          <p className="title">MH-12-5022</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Type")}
                                          </p>
                                          <p className="title">Car</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Transportation Type")}
                                          </p>
                                          <p className="title">Passenger</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Capacity / Passenger")}
                                          </p>
                                          <p className="title">60</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Ycar} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div className="option customer-option">
                                          <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                              <img src={option} alt="" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item>
                                                <Link to="/VehicleDetails">
                                                  View
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={handleShow}
                                              >
                                                <Link to="#">
                                                  {t("Delete")}
                                                </Link>
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Number")}
                                          </p>
                                          <p className="title">MH-12-5022</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Type")}
                                          </p>
                                          <p className="title">Car</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Transportation Type")}
                                          </p>
                                          <p className="title">Passenger</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Capacity / Passenger")}
                                          </p>
                                          <p className="title">60</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={YQcar} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}
                                            </p>
                                            <p className="title">
                                              Mercedes Bus{" "}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="option customer-option">
                                          <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                              <img src={option} alt="" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item>
                                                <Link to="/VehicleDetails">
                                                  View
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={handleShow}
                                              >
                                                <Link to="#">
                                                  {t("Delete")}
                                                </Link>
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Number")}
                                          </p>
                                          <p className="title">MH-12-5022</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Type")}
                                          </p>
                                          <p className="title">Car</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Transportation Type")}
                                          </p>
                                          <p className="title">Passenger</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Capacity / Passenger")}
                                          </p>
                                          <p className="title">60</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Ycar} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div className="option customer-option">
                                          <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                              <img src={option} alt="" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item>
                                                <Link to="/VehicleDetails">
                                                  View
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={handleShow}
                                              >
                                                <Link to="#">
                                                  {t("Delete")}
                                                </Link>
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Number")}
                                          </p>
                                          <p className="title">MH-12-5022</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Type")}
                                          </p>
                                          <p className="title">Car</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Transportation Type")}
                                          </p>
                                          <p className="title">Passenger</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Capacity / Passenger")}
                                          </p>
                                          <p className="title">60</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Ycar} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div className="option customer-option">
                                          <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                              <img src={option} alt="" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item>
                                                <Link to="/VehicleDetails">
                                                  View
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={handleShow}
                                              >
                                                <Link to="#">
                                                  {t("Delete")}
                                                </Link>
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Number")}
                                          </p>
                                          <p className="title">MH-12-5022</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Type")}
                                          </p>
                                          <p className="title">Car</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Transportation Type")}
                                          </p>
                                          <p className="title">Passenger</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Capacity / Passenger")}
                                          </p>
                                          <p className="title">60</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Ycar} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}
                                            </p>
                                            <p className="title">
                                              Mercedes Bus{" "}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="option customer-option">
                                          <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                              <img src={option} alt="" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item>
                                                <Link to="/VehicleDetails">
                                                  View
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={handleShow}
                                              >
                                                <Link to="#">
                                                  {t("Delete")}
                                                </Link>
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Number")}
                                          </p>
                                          <p className="title">MH-12-5022</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Type")}
                                          </p>
                                          <p className="title">Car</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Transportation Type")}
                                          </p>
                                          <p className="title">Passenger</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Capacity / Passenger")}
                                          </p>
                                          <p className="title">60</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={YQbike} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}
                                            </p>
                                            <p className="title">Honda Bike</p>
                                          </div>
                                        </div>
                                        <div className="option customer-option">
                                          <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                              <img src={option} alt="" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item>
                                                <Link to="/VehicleDetails">
                                                  View
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={handleShow}
                                              >
                                                <Link to="#">
                                                  {t("Delete")}
                                                </Link>
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Number")}
                                          </p>
                                          <p className="title">MH-12-5022</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Type")}
                                          </p>
                                          <p className="title">Car</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Transportation Type")}
                                          </p>
                                          <p className="title">Passenger</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Capacity / Passenger")}
                                          </p>
                                          <p className="title">60</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Tab.Pane>
                        </Tab.Content>
                      </Col>
                    </Tab.Container>
                  </div>
                  <p className="reg-color mt-3">{t("Showing")} 1 - 10 of 200</p>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <Link to="/AddVehicleCategory">
                  <button className="cx-btn-1 tbBtn">
                    +{t("Add Vehicle Category")}
                  </button>
                </Link>
                <div className="main-master-wrapper mb-0 inner-tabs-section overflow-hidden">
                  <div id="scroll_insideThe_Padding">
                    <Tab.Container
                      id="left-tabs-example"
                      className="td-tab-wrapper"
                      defaultActiveKey="0"
                    >
                      <Nav
                        variant="pills"
                        className="td-nav"
                        id="InnerTabNew_Foure"
                      >
                        <Nav.Item className="td-tab">
                          <Nav.Link className="td-link" eventKey="0">
                            {t("All")}
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="td-tab">
                          <Nav.Link className="td-link" eventKey="1">
                            {t("Online")}
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="td-tab">
                          <Nav.Link className="td-link" eventKey="2">
                            {t("Offline")}
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="td-tab">
                          <Nav.Link className="td-link" eventKey="3">
                            {t("Untracked/Not Tracked Yet")}
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <Col sm={12} className="">
                        <Tab.Content>
                          <Tab.Pane eventKey="0">
                            <div className="all-vehicle-main">
                              <div className="all-vehical-head row vehicle-top-inputs">
                                <div className="input-section-wrapper">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Vehicle Name,No., Reg. No, IMEI..."
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Vehicle Category"
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Vehicle Capacity"
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                                      <select
                                        required
                                        className="form-select"
                                        aria-label="Default select example"
                                        placeholder="Transportation Type"
                                      >
                                        <option value="">
                                          Transportation Type
                                        </option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="right-export-btn-section-wrapper">
                                  <div className="c-pointer me-2">
                                    <img src={Export} alt="" />
                                  </div>
                                  <div className="c-pointer">
                                    <img src={Import} alt="" />
                                  </div>
                                </div>
                              </div>

                              {vCars === false ? (
                                <div className="yauto" id="arrange-paading">
                                  <div className="row main-cards-wrapper gx-3">
                                    <div
                                      className={
                                        sidebar
                                          ? "col-lg-6 col-md-6"
                                          : "col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={
                                          "common-cat-vehical-card-inner"
                                        }
                                        onClick={() => setVCars(true)}
                                      >
                                        <div className="cat-head">
                                          <img src={Cat_ye_car} alt="" />
                                        </div>
                                        <div className="cat-body w-100">
                                          <div className="cat-body-head">
                                            <p>{t("Luxury Car")}</p>
                                            <p className="count">60</p>
                                          </div>
                                          <div className="cat-body-details">
                                            <label htmlFor="">
                                              <img src={route} alt="" />
                                              2.5K
                                            </label>
                                            <label htmlFor="">
                                              <img src={Repair} alt="" />
                                              5$/m
                                            </label>
                                            <label htmlFor="">
                                              <img src={Pump} alt="" />
                                              25$/ltr
                                            </label>
                                            <label htmlFor="">
                                              <img src={Seats} alt="" />5
                                            </label>
                                          </div>
                                          <div className="cat-body-discription">
                                            <label htmlFor="">
                                              {t("Vehicle Description")}
                                            </label>
                                            <p>
                                              This is a small vehicle with
                                              capacity of 3 passengers at a
                                              time, 300 ltr boot-space
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        sidebar
                                          ? "col-lg-6 col-md-6"
                                          : "col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={
                                          "common-cat-vehical-card-inner"
                                        }
                                        onClick={() => {
                                          setVBike(true);
                                        }}
                                      >
                                        <div className="cat-head">
                                          <img src={Cat_ye_bike} alt="" />
                                        </div>
                                        <div className="cat-body w-100">
                                          <div className="cat-body-head">
                                            <p>{t("Bike")}</p>
                                            <p className="count">60</p>
                                          </div>
                                          <div className="cat-body-details">
                                            <label htmlFor="">
                                              <img src={route} alt="" />
                                              2.5K
                                            </label>
                                            <label htmlFor="">
                                              <img src={Repair} alt="" />
                                              5$/m
                                            </label>
                                            <label htmlFor="">
                                              <img src={Pump} alt="" />
                                              25$/ltr
                                            </label>
                                            <label htmlFor="">
                                              <img src={Seats} alt="" />1
                                            </label>
                                          </div>
                                          <div className="cat-body-discription">
                                            <label htmlFor="">
                                              {t("Vehicle Description")}
                                            </label>
                                            <p>
                                              This is a small vehicle with
                                              capacity of 3 passengers at a
                                              time, 300 ltr boot-space
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        sidebar
                                          ? "col-lg-6 col-md-6"
                                          : "col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={
                                          "common-cat-vehical-card-inner"
                                        }
                                      >
                                        <div className="cat-head">
                                          <img src={Cat_ye_car} alt="" />
                                        </div>
                                        <div className="cat-body w-100">
                                          <div className="cat-body-head">
                                            <p>Pick-Up Truck</p>
                                            <p className="count">60</p>
                                          </div>
                                          <div className="cat-body-details">
                                            <label htmlFor="">
                                              <img src={route} alt="" />
                                              2.5K
                                            </label>
                                            <label htmlFor="">
                                              <img src={Repair} alt="" />
                                              5$/m
                                            </label>
                                            <label htmlFor="">
                                              <img src={Pump} alt="" />
                                              25$/ltr
                                            </label>
                                            <label htmlFor="">
                                              <img src={weight} alt="" />
                                              500Kg
                                            </label>
                                          </div>
                                          <div className="cat-body-discription">
                                            <label htmlFor="">
                                              {t("Vehicle Description")}
                                            </label>
                                            <p>
                                              This is a small vehicle with
                                              capacity of 3 passengers at a
                                              time, 300 ltr boot-space
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        sidebar
                                          ? "col-lg-6 col-md-6"
                                          : "col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={
                                          "common-cat-vehical-card-inner"
                                        }
                                      >
                                        <div className="cat-head">
                                          <img src={Cat_ye_car} alt="" />
                                        </div>
                                        <div className="cat-body w-100">
                                          <div className="cat-body-head">
                                            <p>Truck</p>
                                            <p className="count">60</p>
                                          </div>
                                          <div className="cat-body-details">
                                            <label htmlFor="">
                                              <img src={route} alt="" />
                                              2.5K
                                            </label>
                                            <label htmlFor="">
                                              <img src={Repair} alt="" />
                                              5$/m
                                            </label>
                                            <label htmlFor="">
                                              <img src={Pump} alt="" />
                                              25$/ltr
                                            </label>
                                            <label htmlFor="">
                                              <img src={weight} alt="" />
                                              500Kg
                                            </label>
                                          </div>
                                          <div className="cat-body-discription">
                                            <label htmlFor="">
                                              {t("Vehicle Description")}n
                                            </label>
                                            <p>
                                              This is a small vehicle with
                                              capacity of 3 passengers at a
                                              time, 300 ltr boot-space
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        sidebar
                                          ? "col-lg-6 col-md-6"
                                          : "col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={
                                          "common-cat-vehical-card-inner"
                                        }
                                      >
                                        <div className="cat-head">
                                          <img src={Cat_ye_bike} alt="" />
                                        </div>
                                        <div className="cat-body w-100">
                                          <div className="cat-body-head">
                                            <p>Scooter</p>
                                            <p className="count">60</p>
                                          </div>
                                          <div className="cat-body-details">
                                            <label htmlFor="">
                                              <img src={route} alt="" />
                                              2.5K
                                            </label>
                                            <label htmlFor="">
                                              <img src={Repair} alt="" />
                                              5$/m
                                            </label>
                                            <label htmlFor="">
                                              <img src={Pump} alt="" />
                                              25$/ltr
                                            </label>
                                            <label htmlFor="">
                                              <img src={Seats} alt="" />1
                                            </label>
                                          </div>
                                          <div className="cat-body-discription">
                                            <label htmlFor="">
                                              {t("Vehicle Description")}
                                            </label>
                                            <p>
                                              This is a small vehicle with
                                              capacity of 3 passengers at a
                                              time, 300 ltr boot-space
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        sidebar
                                          ? "col-lg-6 col-md-6"
                                          : "col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={
                                          "common-cat-vehical-card-inner"
                                        }
                                      >
                                        <div className="cat-head">
                                          <img src={Cat_ye_car} alt="" />
                                        </div>
                                        <div className="cat-body w-100">
                                          <div className="cat-body-head">
                                            <p>Utility Cab</p>
                                            <p className="count">60</p>
                                          </div>
                                          <div className="cat-body-details">
                                            <label htmlFor="">
                                              <img src={route} alt="" />
                                              2.5K
                                            </label>
                                            <label htmlFor="">
                                              <img src={Repair} alt="" />
                                              5$/m
                                            </label>
                                            <label htmlFor="">
                                              <img src={Pump} alt="" />
                                              25$/ltr
                                            </label>
                                            <label htmlFor="">
                                              <img src={Seats} alt="" />3
                                            </label>
                                          </div>
                                          <div className="cat-body-discription">
                                            <label htmlFor="">
                                              {t("Vehicle Description")}
                                            </label>
                                            <p>
                                              This is a small vehicle with
                                              capacity of 3 passengers at a
                                              time, 300 ltr boot-space
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        sidebar
                                          ? "col-lg-6 col-md-6"
                                          : "col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={
                                          "common-cat-vehical-card-inner"
                                        }
                                      >
                                        <div className="cat-head">
                                          <img src={Cat_ye_car} alt="" />
                                        </div>
                                        <div className="cat-body w-100">
                                          <div className="cat-body-head">
                                            <p>Pick-Up Truck</p>
                                            <p className="count">60</p>
                                          </div>
                                          <div className="cat-body-details">
                                            <label htmlFor="">
                                              <img src={route} alt="" />
                                              2.5K
                                            </label>
                                            <label htmlFor="">
                                              <img src={Repair} alt="" />
                                              5$/m
                                            </label>
                                            <label htmlFor="">
                                              <img src={Pump} alt="" />
                                              25$/ltr
                                            </label>
                                            <label htmlFor="">
                                              <img src={Seats} alt="" />5
                                            </label>
                                          </div>
                                          <div className="cat-body-discription">
                                            <label htmlFor="">
                                              {t("Vehicle Description")}
                                            </label>
                                            <p>
                                              This is a small vehicle with
                                              capacity of 3 passengers at a
                                              time, 300 ltr boot-space
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        sidebar
                                          ? "col-lg-6 col-md-6"
                                          : "col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={
                                          "common-cat-vehical-card-inner"
                                        }
                                      >
                                        <div className="cat-head">
                                          <img src={Cat_ye_bike} alt="" />
                                        </div>
                                        <div className="cat-body w-100">
                                          <div className="cat-body-head">
                                            <p>Bike-EV</p>
                                            <p className="count">60</p>
                                          </div>
                                          <div className="cat-body-details">
                                            <label htmlFor="">
                                              <img src={route} alt="" />
                                              2.5K
                                            </label>
                                            <label htmlFor="">
                                              <img src={Repair} alt="" />
                                              5$/m
                                            </label>
                                            <label htmlFor="">
                                              <img src={EVlogo} alt="" />
                                              25$
                                            </label>
                                            <label htmlFor="">
                                              <img src={Seats} alt="" />1
                                            </label>
                                          </div>
                                          <div className="cat-body-discription">
                                            <label htmlFor="">
                                              {t("Vehicle Description")}
                                            </label>
                                            <p>
                                              This is a small vehicle with
                                              capacity of 3 passengers at a
                                              time, 300 ltr boot-space
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        sidebar
                                          ? "col-lg-6 col-md-6"
                                          : "col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={
                                          "common-cat-vehical-card-inner"
                                        }
                                      >
                                        <div className="cat-head">
                                          <img src={Cat_ye_car} alt="" />
                                        </div>
                                        <div className="cat-body w-100">
                                          <div className="cat-body-head">
                                            <p>Van</p>
                                            <p className="count">60</p>
                                          </div>
                                          <div className="cat-body-details">
                                            <label htmlFor="">
                                              <img src={route} alt="" />
                                              2.5K
                                            </label>
                                            <label htmlFor="">
                                              <img src={Repair} alt="" />
                                              5$/m
                                            </label>
                                            <label htmlFor="">
                                              <img src={Pump} alt="" />
                                              25$/ltr
                                            </label>
                                            <label htmlFor="">
                                              <img src={weight} alt="" />
                                              500Kg
                                            </label>
                                          </div>
                                          <div className="cat-body-discription">
                                            <label htmlFor="">
                                              {t("Vehicle Description")}
                                            </label>
                                            <p>
                                              This is a small vehicle with
                                              capacity of 3 passengers at a
                                              time, 300 ltr boot-space
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <>
                                  <img
                                    src={arrow}
                                    alt=""
                                    className="c-pointer"
                                    onClick={() => {
                                      setVCars(false);
                                    }}
                                  />
                                  <div className="yauto" id="arrange-paading">
                                    <div className="all-vehical-body main-master-wrapper row  g-0">
                                      <div
                                        className={
                                          sidebar
                                            ? "common-vehical-card"
                                            : "common-vehical-card-inner"
                                        }
                                      >
                                        <div className="vehical-card-head">
                                          <div className="heading">
                                            <img src={Gcar} alt="" />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Vehicle Name")}
                                              </p>
                                              <p className="title">
                                                Utility CAB
                                              </p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              <Dropdown.Toggle id="dropdown-basic">
                                                <img src={option} alt="" />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                <Dropdown.Item>
                                                  <Link
                                                    to="/VehicleDetails"
                                                    className="d-block"
                                                  >
                                                    {t("View")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                  {" "}
                                                  <Link
                                                    to="/EditVehicle"
                                                    className="d-block"
                                                  >
                                                    {t("Edit")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={handleShow}
                                                >
                                                  <Link
                                                    to="#"
                                                    className="d-block"
                                                  >
                                                    {" "}
                                                    {t("Delete")}
                                                  </Link>
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">MH-12-5022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Driver Name")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("IMEI No.")}
                                            </p>
                                            <p className="title">
                                              1234567894561230
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Type")}
                                            </p>
                                            <p className="title">Car</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">Passenger</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t(
                                                "Vehicle Capacity / Passenger"
                                              )}
                                            </p>
                                            <p className="title">60</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className={
                                          sidebar
                                            ? "common-vehical-card"
                                            : "common-vehical-card-inner"
                                        }
                                      >
                                        <div className="vehical-card-head">
                                          <div className="heading">
                                            <img src={Gcar} alt="" />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Vehicle Name")}
                                              </p>
                                              <p className="title">
                                                Volvo Transport
                                              </p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              <Dropdown.Toggle id="dropdown-basic">
                                                <img src={option} alt="" />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                <Dropdown.Item>
                                                  <Link to="/VehicleDetails">
                                                    View
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                  <Link to="/EditVehicle">
                                                    {t("Edit")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={handleShow}
                                                >
                                                  <Link to="#">
                                                    {t("Delete")}
                                                  </Link>
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">MH-12-5022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Driver Name")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("IMEI No.")}
                                            </p>
                                            <p className="title">
                                              1234567894561230
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Type")}
                                            </p>
                                            <p className="title">Car</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">Passenger</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t(
                                                "Vehicle Capacity / Passenger"
                                              )}
                                            </p>
                                            <p className="title">60</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className={
                                          sidebar
                                            ? "common-vehical-card"
                                            : "common-vehical-card-inner"
                                        }
                                      >
                                        <div className="vehical-card-head">
                                          <div className="heading">
                                            <img src={Bcar} alt="" />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Vehicle Name")}
                                              </p>
                                              <p className="title">
                                                Mercedes Bus{" "}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              <Dropdown.Toggle id="dropdown-basic">
                                                <img src={option} alt="" />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                <Dropdown.Item>
                                                  <Link to="/VehicleDetails">
                                                    View
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                  <Link to="/EditVehicle">
                                                    {t("Edit")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={handleShow}
                                                >
                                                  <Link to="#">
                                                    {t("Delete")}
                                                  </Link>
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">MH-12-5022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Driver Name")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("IMEI No.")}
                                            </p>
                                            <p className="title">
                                              1234567894561230
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Type")}
                                            </p>
                                            <p className="title">Car</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">Passenger</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t(
                                                "Vehicle Capacity / Passenger"
                                              )}
                                            </p>
                                            <p className="title">60</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className={
                                          sidebar
                                            ? "common-vehical-card"
                                            : "common-vehical-card-inner"
                                        }
                                      >
                                        <div className="vehical-card-head">
                                          <div className="heading">
                                            <img src={Bcar} alt="" />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Vehicle Name")}
                                              </p>
                                              <p className="title">
                                                Volvo Transport
                                              </p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              <Dropdown.Toggle id="dropdown-basic">
                                                <img src={option} alt="" />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                <Dropdown.Item>
                                                  <Link to="/VehicleDetails">
                                                    View
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                  <Link to="/EditVehicle">
                                                    {t("Edit")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={handleShow}
                                                >
                                                  <Link to="#">
                                                    {t("Delete")}
                                                  </Link>
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">MH-12-5022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Driver Name")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("IMEI No.")}
                                            </p>
                                            <p className="title">
                                              1234567894561230
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Type")}
                                            </p>
                                            <p className="title">Car</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">Passenger</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t(
                                                "Vehicle Capacity / Passenger"
                                              )}
                                            </p>
                                            <p className="title">60</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className={
                                          sidebar
                                            ? "common-vehical-card"
                                            : "common-vehical-card-inner"
                                        }
                                      >
                                        <div className="vehical-card-head">
                                          <div className="heading">
                                            <img src={Rcar} alt="" />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Vehicle Name")}
                                              </p>
                                              <p className="title">
                                                Volvo Transport
                                              </p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              <Dropdown.Toggle id="dropdown-basic">
                                                <img src={option} alt="" />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                <Dropdown.Item>
                                                  <Link to="/VehicleDetails">
                                                    View
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                  <Link to="/EditVehicle">
                                                    {t("Edit")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={handleShow}
                                                >
                                                  <Link to="#">
                                                    {t("Delete")}
                                                  </Link>
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">MH-12-5022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Driver Name")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("IMEI No.")}
                                            </p>
                                            <p className="title">
                                              1234567894561230
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Type")}
                                            </p>
                                            <p className="title">Car</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">Passenger</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t(
                                                "Vehicle Capacity / Passenger"
                                              )}
                                            </p>
                                            <p className="title">60</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className={
                                          sidebar
                                            ? "common-vehical-card"
                                            : "common-vehical-card-inner"
                                        }
                                      >
                                        <div className="vehical-card-head">
                                          <div className="heading">
                                            <img src={BKcar} alt="" />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Vehicle Name")}
                                              </p>
                                              <p className="title">
                                                Mercedes Bus{" "}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              <Dropdown.Toggle id="dropdown-basic">
                                                <img src={option} alt="" />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                <Dropdown.Item>
                                                  <Link to="/VehicleDetails">
                                                    View
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                  <Link to="/EditVehicle">
                                                    {t("Edit")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={handleShow}
                                                >
                                                  <Link to="#">
                                                    {t("Delete")}
                                                  </Link>
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">MH-12-5022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Driver Name")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("IMEI No.")}
                                            </p>
                                            <p className="title">
                                              1234567894561230
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Type")}
                                            </p>
                                            <p className="title">Car</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">Passenger</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t(
                                                "Vehicle Capacity / Passenger"
                                              )}
                                            </p>
                                            <p className="title">60</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className={
                                          sidebar
                                            ? "common-vehical-card"
                                            : "common-vehical-card-inner"
                                        }
                                      >
                                        <div className="vehical-card-head">
                                          <div className="heading">
                                            <img src={Rbike} alt="" />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Vehicle Name")}
                                              </p>
                                              <p className="title">
                                                Honda Bike
                                              </p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              <Dropdown.Toggle id="dropdown-basic">
                                                <img src={option} alt="" />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                <Dropdown.Item>
                                                  <Link to="/VehicleDetails">
                                                    View
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                  <Link to="/EditVehicle">
                                                    {t("Edit")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={handleShow}
                                                >
                                                  <Link to="#">
                                                    {t("Delete")}
                                                  </Link>
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">MH-12-5022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Driver Name")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("IMEI No.")}
                                            </p>
                                            <p className="title">
                                              1234567894561230
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Type")}
                                            </p>
                                            <p className="title">Car</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">Passenger</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t(
                                                "Vehicle Capacity / Passenger"
                                              )}
                                            </p>
                                            <p className="title">60</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="1">
                            <div className="all-vehicle-main">
                              <div className="all-vehical-head row vehicle-top-inputs">
                                <div className="input-section-wrapper">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Vehicle Name,No., Reg. No, IMEI..."
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Vehicle Category"
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Vehicle Capacity"
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                                      <select
                                        required
                                        className="form-select"
                                        aria-label="Default select example"
                                        placeholder="Transportation Type"
                                      >
                                        <option value="">
                                          Transportation Type
                                        </option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="right-export-btn-section-wrapper">
                                  <div className="c-pointer me-2">
                                    <img src={Export} alt="" />
                                  </div>
                                  <div className="c-pointer">
                                    <img src={Import} alt="" />
                                  </div>
                                </div>
                              </div>
                              <div className="yauto" id="arrange-paading">
                                <div className="row main-cards-wrapper gx-3">
                                  <div
                                    className={
                                      sidebar
                                        ? "col-lg-6 col-md-6"
                                        : "col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={
                                        "common-cat-vehical-card-inner"
                                      }
                                      onClick={() => setVCars(true)}
                                    >
                                      <div className="cat-head">
                                        <img src={Cat_ye_car} alt="" />
                                      </div>
                                      <div className="cat-body w-100">
                                        <div className="cat-body-head">
                                          <p>{t("Luxury Car")}</p>
                                          <p className="count">60</p>
                                        </div>
                                        <div className="cat-body-details">
                                          <label htmlFor="">
                                            <img src={route} alt="" />
                                            2.5K
                                          </label>
                                          <label htmlFor="">
                                            <img src={Repair} alt="" />
                                            5$/m
                                          </label>
                                          <label htmlFor="">
                                            <img src={Pump} alt="" />
                                            25$/ltr
                                          </label>
                                          <label htmlFor="">
                                            <img src={Seats} alt="" />5
                                          </label>
                                        </div>
                                        <div className="cat-body-discription">
                                          <label htmlFor="">
                                            {t("Vehicle Description")}
                                          </label>
                                          <p>
                                            This is a small vehicle with
                                            capacity of 3 passengers at a time,
                                            300 ltr boot-space
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-lg-6 col-md-6"
                                        : "col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={
                                        "common-cat-vehical-card-inner"
                                      }
                                      onClick={() => {
                                        setVBike(true);
                                      }}
                                    >
                                      <div className="cat-head">
                                        <img src={Cat_ye_bike} alt="" />
                                      </div>
                                      <div className="cat-body w-100">
                                        <div className="cat-body-head">
                                          <p>{t("Bike")}</p>
                                          <p className="count">60</p>
                                        </div>
                                        <div className="cat-body-details">
                                          <label htmlFor="">
                                            <img src={route} alt="" />
                                            2.5K
                                          </label>
                                          <label htmlFor="">
                                            <img src={Repair} alt="" />
                                            5$/m
                                          </label>
                                          <label htmlFor="">
                                            <img src={Pump} alt="" />
                                            25$/ltr
                                          </label>
                                          <label htmlFor="">
                                            <img src={Seats} alt="" />1
                                          </label>
                                        </div>
                                        <div className="cat-body-discription">
                                          <label htmlFor="">
                                            {t("Vehicle Description")}
                                          </label>
                                          <p>
                                            This is a small vehicle with
                                            capacity of 3 passengers at a time,
                                            300 ltr boot-space
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-lg-6 col-md-6"
                                        : "col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={
                                        "common-cat-vehical-card-inner"
                                      }
                                    >
                                      <div className="cat-head">
                                        <img src={Cat_ye_car} alt="" />
                                      </div>
                                      <div className="cat-body w-100">
                                        <div className="cat-body-head">
                                          <p>{t("Pick-Up Truck")}</p>
                                          <p className="count">60</p>
                                        </div>
                                        <div className="cat-body-details">
                                          <label htmlFor="">
                                            <img src={route} alt="" />
                                            2.5K
                                          </label>
                                          <label htmlFor="">
                                            <img src={Repair} alt="" />
                                            5$/m
                                          </label>
                                          <label htmlFor="">
                                            <img src={Pump} alt="" />
                                            25$/ltr
                                          </label>
                                          <label htmlFor="">
                                            <img src={weight} alt="" />
                                            500Kg
                                          </label>
                                        </div>
                                        <div className="cat-body-discription">
                                          <label htmlFor="">
                                            {t("Vehicle Description")}
                                          </label>
                                          <p>
                                            This is a small vehicle with
                                            capacity of 3 passengers at a time,
                                            300 ltr boot-space
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-lg-6 col-md-6"
                                        : "col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={
                                        "common-cat-vehical-card-inner"
                                      }
                                    >
                                      <div className="cat-head">
                                        <img src={Cat_ye_car} alt="" />
                                      </div>
                                      <div className="cat-body w-100">
                                        <div className="cat-body-head">
                                          <p>{t("Truck")}</p>
                                          <p className="count">60</p>
                                        </div>
                                        <div className="cat-body-details">
                                          <label htmlFor="">
                                            <img src={route} alt="" />
                                            2.5K
                                          </label>
                                          <label htmlFor="">
                                            <img src={Repair} alt="" />
                                            5$/m
                                          </label>
                                          <label htmlFor="">
                                            <img src={Pump} alt="" />
                                            25$/ltr
                                          </label>
                                          <label htmlFor="">
                                            <img src={weight} alt="" />
                                            500Kg
                                          </label>
                                        </div>
                                        <div className="cat-body-discription">
                                          <label htmlFor="">
                                            {t("Vehicle Description")}
                                          </label>
                                          <p>
                                            This is a small vehicle with
                                            capacity of 3 passengers at a time,
                                            300 ltr boot-space
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-lg-6 col-md-6"
                                        : "col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={
                                        "common-cat-vehical-card-inner"
                                      }
                                    >
                                      <div className="cat-head">
                                        <img src={Cat_ye_bike} alt="" />
                                      </div>
                                      <div className="cat-body w-100">
                                        <div className="cat-body-head">
                                          <p>{t("Scooter")}</p>
                                          <p className="count">60</p>
                                        </div>
                                        <div className="cat-body-details">
                                          <label htmlFor="">
                                            <img src={route} alt="" />
                                            2.5K
                                          </label>
                                          <label htmlFor="">
                                            <img src={Repair} alt="" />
                                            5$/m
                                          </label>
                                          <label htmlFor="">
                                            <img src={Pump} alt="" />
                                            25$/ltr
                                          </label>
                                          <label htmlFor="">
                                            <img src={Seats} alt="" />1
                                          </label>
                                        </div>
                                        <div className="cat-body-discription">
                                          <label htmlFor="">
                                            {t("Vehicle Description")}
                                          </label>
                                          <p>
                                            This is a small vehicle with
                                            capacity of 3 passengers at a time,
                                            300 ltr boot-space
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-lg-6 col-md-6"
                                        : "col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={
                                        "common-cat-vehical-card-inner"
                                      }
                                    >
                                      <div className="cat-head">
                                        <img src={Cat_ye_car} alt="" />
                                      </div>
                                      <div className="cat-body w-100">
                                        <div className="cat-body-head">
                                          <p>{t("Utility Cab")}</p>
                                          <p className="count">60</p>
                                        </div>
                                        <div className="cat-body-details">
                                          <label htmlFor="">
                                            <img src={route} alt="" />
                                            2.5K
                                          </label>
                                          <label htmlFor="">
                                            <img src={Repair} alt="" />
                                            5$/m
                                          </label>
                                          <label htmlFor="">
                                            <img src={Pump} alt="" />
                                            25$/ltr
                                          </label>
                                          <label htmlFor="">
                                            <img src={Seats} alt="" />3
                                          </label>
                                        </div>
                                        <div className="cat-body-discription">
                                          <label htmlFor="">
                                            {t("Vehicle Description")}
                                          </label>
                                          <p>
                                            This is a small vehicle with
                                            capacity of 3 passengers at a time,
                                            300 ltr boot-space
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-lg-6 col-md-6"
                                        : "col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={
                                        "common-cat-vehical-card-inner"
                                      }
                                    >
                                      <div className="cat-head">
                                        <img src={Cat_ye_car} alt="" />
                                      </div>
                                      <div className="cat-body w-100">
                                        <div className="cat-body-head">
                                          <p>{t("Pick-Up Truck")}</p>
                                          <p className="count">60</p>
                                        </div>
                                        <div className="cat-body-details">
                                          <label htmlFor="">
                                            <img src={route} alt="" />
                                            2.5K
                                          </label>
                                          <label htmlFor="">
                                            <img src={Repair} alt="" />
                                            5$/m
                                          </label>
                                          <label htmlFor="">
                                            <img src={Pump} alt="" />
                                            25$/ltr
                                          </label>
                                          <label htmlFor="">
                                            <img src={Seats} alt="" />5
                                          </label>
                                        </div>
                                        <div className="cat-body-discription">
                                          <label htmlFor="">
                                            {t("Vehicle Description")}
                                          </label>
                                          <p>
                                            This is a small vehicle with
                                            capacity of 3 passengers at a time,
                                            300 ltr boot-space
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-lg-6 col-md-6"
                                        : "col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={
                                        "common-cat-vehical-card-inner"
                                      }
                                    >
                                      <div className="cat-head">
                                        <img src={Cat_ye_bike} alt="" />
                                      </div>
                                      <div className="cat-body w-100">
                                        <div className="cat-body-head">
                                          <p>{t("Bike-EV")}</p>
                                          <p className="count">60</p>
                                        </div>
                                        <div className="cat-body-details">
                                          <label htmlFor="">
                                            <img src={route} alt="" />
                                            2.5K
                                          </label>
                                          <label htmlFor="">
                                            <img src={Repair} alt="" />
                                            5$/m
                                          </label>
                                          <label htmlFor="">
                                            <img src={EVlogo} alt="" />
                                            25$
                                          </label>
                                          <label htmlFor="">
                                            <img src={Seats} alt="" />1
                                          </label>
                                        </div>
                                        <div className="cat-body-discription">
                                          <label htmlFor="">
                                            {t("Vehicle Description")}
                                          </label>
                                          <p>
                                            This is a small vehicle with
                                            capacity of 3 passengers at a time,
                                            300 ltr boot-space
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-lg-6 col-md-6"
                                        : "col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={
                                        "common-cat-vehical-card-inner"
                                      }
                                    >
                                      <div className="cat-head">
                                        <img src={Cat_ye_car} alt="" />
                                      </div>
                                      <div className="cat-body w-100">
                                        <div className="cat-body-head">
                                          <p>{t("Van")}</p>
                                          <p className="count">60</p>
                                        </div>
                                        <div className="cat-body-details">
                                          <label htmlFor="">
                                            <img src={route} alt="" />
                                            2.5K
                                          </label>
                                          <label htmlFor="">
                                            <img src={Repair} alt="" />
                                            5$/m
                                          </label>
                                          <label htmlFor="">
                                            <img src={Pump} alt="" />
                                            25$/ltr
                                          </label>
                                          <label htmlFor="">
                                            <img src={weight} alt="" />
                                            500Kg
                                          </label>
                                        </div>
                                        <div className="cat-body-discription">
                                          <label htmlFor="">
                                            {t("Vehicle Description")}
                                          </label>
                                          <p>
                                            This is a small vehicle with
                                            capacity of 3 passengers at a time,
                                            300 ltr boot-space
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="2">
                            <div className="all-vehicle-main">
                              <div className="all-vehical-head row vehicle-top-inputs">
                                <div className="input-section-wrapper">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Vehicle Name,No., Reg. No, IMEI..."
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Vehicle Category"
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Vehicle Capacity"
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                                      <select
                                        required
                                        className="form-select"
                                        aria-label="Default select example"
                                        placeholder="Transportation Type"
                                      >
                                        <option value="">
                                          Transportation Type
                                        </option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="right-export-btn-section-wrapper">
                                  <div className="c-pointer me-2">
                                    <img src={Export} alt="" />
                                  </div>
                                  <div className="c-pointer">
                                    <img src={Import} alt="" />
                                  </div>
                                </div>
                              </div>
                              <div className="yauto" id="arrange-paading">
                                <div
                                  className="row main-cards-wrapper gx-3 p-none"
                                  style={{ opacity: "0.5" }}
                                >
                                  <div
                                    className={
                                      sidebar
                                        ? "col-lg-6 col-md-6"
                                        : "col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={
                                        "common-cat-vehical-card-inner"
                                      }
                                      onClick={() => setVCars(true)}
                                    >
                                      <div className="cat-head">
                                        <img src={Cat_ye_car} alt="" />
                                      </div>
                                      <div className="cat-body w-100">
                                        <div className="cat-body-head">
                                          <p>{t("Luxury Car")}</p>
                                          <p className="count">60</p>
                                        </div>
                                        <div className="cat-body-details">
                                          <label htmlFor="">
                                            <img src={route} alt="" />
                                            2.5K
                                          </label>
                                          <label htmlFor="">
                                            <img src={Repair} alt="" />
                                            5$/m
                                          </label>
                                          <label htmlFor="">
                                            <img src={Pump} alt="" />
                                            25$/ltr
                                          </label>
                                          <label htmlFor="">
                                            <img src={Seats} alt="" />5
                                          </label>
                                        </div>
                                        <div className="cat-body-discription">
                                          <label htmlFor="">
                                            {t("Vehicle Description")}
                                          </label>
                                          <p>
                                            This is a small vehicle with
                                            capacity of 3 passengers at a time,
                                            300 ltr boot-space
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-lg-6 col-md-6"
                                        : "col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={
                                        "common-cat-vehical-card-inner"
                                      }
                                      onClick={() => {
                                        setVBike(true);
                                      }}
                                    >
                                      <div className="cat-head">
                                        <img src={Cat_ye_bike} alt="" />
                                      </div>
                                      <div className="cat-body w-100">
                                        <div className="cat-body-head">
                                          <p>{t("Bike")}</p>
                                          <p className="count">60</p>
                                        </div>
                                        <div className="cat-body-details">
                                          <label htmlFor="">
                                            <img src={route} alt="" />
                                            2.5K
                                          </label>
                                          <label htmlFor="">
                                            <img src={Repair} alt="" />
                                            5$/m
                                          </label>
                                          <label htmlFor="">
                                            <img src={Pump} alt="" />
                                            25$/ltr
                                          </label>
                                          <label htmlFor="">
                                            <img src={Seats} alt="" />1
                                          </label>
                                        </div>
                                        <div className="cat-body-discription">
                                          <label htmlFor="">
                                            {t("Vehicle Description")}
                                          </label>
                                          <p>
                                            This is a small vehicle with
                                            capacity of 3 passengers at a time,
                                            300 ltr boot-space
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-lg-6 col-md-6"
                                        : "col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={
                                        "common-cat-vehical-card-inner"
                                      }
                                    >
                                      <div className="cat-head">
                                        <img src={Cat_ye_car} alt="" />
                                      </div>
                                      <div className="cat-body w-100">
                                        <div className="cat-body-head">
                                          <p>{t("Pick-Up Truck")}</p>
                                          <p className="count">60</p>
                                        </div>
                                        <div className="cat-body-details">
                                          <label htmlFor="">
                                            <img src={route} alt="" />
                                            2.5K
                                          </label>
                                          <label htmlFor="">
                                            <img src={Repair} alt="" />
                                            5$/m
                                          </label>
                                          <label htmlFor="">
                                            <img src={Pump} alt="" />
                                            25$/ltr
                                          </label>
                                          <label htmlFor="">
                                            <img src={weight} alt="" />
                                            500Kg
                                          </label>
                                        </div>
                                        <div className="cat-body-discription">
                                          <label htmlFor="">
                                            {t("Vehicle Description")}
                                          </label>
                                          <p>
                                            This is a small vehicle with
                                            capacity of 3 passengers at a time,
                                            300 ltr boot-space
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-lg-6 col-md-6"
                                        : "col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={
                                        "common-cat-vehical-card-inner"
                                      }
                                    >
                                      <div className="cat-head">
                                        <img src={Cat_ye_car} alt="" />
                                      </div>
                                      <div className="cat-body w-100">
                                        <div className="cat-body-head">
                                          <p>{t("Truck")}</p>
                                          <p className="count">60</p>
                                        </div>
                                        <div className="cat-body-details">
                                          <label htmlFor="">
                                            <img src={route} alt="" />
                                            2.5K
                                          </label>
                                          <label htmlFor="">
                                            <img src={Repair} alt="" />
                                            5$/m
                                          </label>
                                          <label htmlFor="">
                                            <img src={Pump} alt="" />
                                            25$/ltr
                                          </label>
                                          <label htmlFor="">
                                            <img src={weight} alt="" />
                                            500Kg
                                          </label>
                                        </div>
                                        <div className="cat-body-discription">
                                          <label htmlFor="">
                                            {t("Vehicle Description")}
                                          </label>
                                          <p>
                                            This is a small vehicle with
                                            capacity of 3 passengers at a time,
                                            300 ltr boot-space
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-lg-6 col-md-6"
                                        : "col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={
                                        "common-cat-vehical-card-inner"
                                      }
                                    >
                                      <div className="cat-head">
                                        <img src={Cat_ye_bike} alt="" />
                                      </div>
                                      <div className="cat-body w-100">
                                        <div className="cat-body-head">
                                          <p>{t("Scooter")}</p>
                                          <p className="count">60</p>
                                        </div>
                                        <div className="cat-body-details">
                                          <label htmlFor="">
                                            <img src={route} alt="" />
                                            2.5K
                                          </label>
                                          <label htmlFor="">
                                            <img src={Repair} alt="" />
                                            5$/m
                                          </label>
                                          <label htmlFor="">
                                            <img src={Pump} alt="" />
                                            25$/ltr
                                          </label>
                                          <label htmlFor="">
                                            <img src={Seats} alt="" />1
                                          </label>
                                        </div>
                                        <div className="cat-body-discription">
                                          <label htmlFor="">
                                            {t("Vehicle Description")}
                                          </label>
                                          <p>
                                            This is a small vehicle with
                                            capacity of 3 passengers at a time,
                                            300 ltr boot-space
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-lg-6 col-md-6"
                                        : "col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={
                                        "common-cat-vehical-card-inner"
                                      }
                                    >
                                      <div className="cat-head">
                                        <img src={Cat_ye_car} alt="" />
                                      </div>
                                      <div className="cat-body w-100">
                                        <div className="cat-body-head">
                                          <p>{t("Utility Cab")}</p>
                                          <p className="count">60</p>
                                        </div>
                                        <div className="cat-body-details">
                                          <label htmlFor="">
                                            <img src={route} alt="" />
                                            2.5K
                                          </label>
                                          <label htmlFor="">
                                            <img src={Repair} alt="" />
                                            5$/m
                                          </label>
                                          <label htmlFor="">
                                            <img src={Pump} alt="" />
                                            25$/ltr
                                          </label>
                                          <label htmlFor="">
                                            <img src={Seats} alt="" />3
                                          </label>
                                        </div>
                                        <div className="cat-body-discription">
                                          <label htmlFor="">
                                            {t("Vehicle Description")}
                                          </label>
                                          <p>
                                            This is a small vehicle with
                                            capacity of 3 passengers at a time,
                                            300 ltr boot-space
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-lg-6 col-md-6"
                                        : "col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={
                                        "common-cat-vehical-card-inner"
                                      }
                                    >
                                      <div className="cat-head">
                                        <img src={Cat_ye_car} alt="" />
                                      </div>
                                      <div className="cat-body w-100">
                                        <div className="cat-body-head">
                                          <p>{t("Pick-Up Truck")}</p>
                                          <p className="count">60</p>
                                        </div>
                                        <div className="cat-body-details">
                                          <label htmlFor="">
                                            <img src={route} alt="" />
                                            2.5K
                                          </label>
                                          <label htmlFor="">
                                            <img src={Repair} alt="" />
                                            5$/m
                                          </label>
                                          <label htmlFor="">
                                            <img src={Pump} alt="" />
                                            25$/ltr
                                          </label>
                                          <label htmlFor="">
                                            <img src={Seats} alt="" />5
                                          </label>
                                        </div>
                                        <div className="cat-body-discription">
                                          <label htmlFor="">
                                            {t("Vehicle Description")}
                                          </label>
                                          <p>
                                            This is a small vehicle with
                                            capacity of 3 passengers at a time,
                                            300 ltr boot-space
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-lg-6 col-md-6"
                                        : "col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={
                                        "common-cat-vehical-card-inner"
                                      }
                                    >
                                      <div className="cat-head">
                                        <img src={Cat_ye_bike} alt="" />
                                      </div>
                                      <div className="cat-body w-100">
                                        <div className="cat-body-head">
                                          <p>{t("Bike-EV")}</p>
                                          <p className="count">60</p>
                                        </div>
                                        <div className="cat-body-details">
                                          <label htmlFor="">
                                            <img src={route} alt="" />
                                            2.5K
                                          </label>
                                          <label htmlFor="">
                                            <img src={Repair} alt="" />
                                            5$/m
                                          </label>
                                          <label htmlFor="">
                                            <img src={EVlogo} alt="" />
                                            25$
                                          </label>
                                          <label htmlFor="">
                                            <img src={Seats} alt="" />1
                                          </label>
                                        </div>
                                        <div className="cat-body-discription">
                                          <label htmlFor="">
                                            {t("Vehicle Description")}
                                          </label>
                                          <p>
                                            This is a small vehicle with
                                            capacity of 3 passengers at a time,
                                            300 ltr boot-space
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-lg-6 col-md-6"
                                        : "col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={
                                        "common-cat-vehical-card-inner"
                                      }
                                    >
                                      <div className="cat-head">
                                        <img src={Cat_ye_car} alt="" />
                                      </div>
                                      <div className="cat-body w-100">
                                        <div className="cat-body-head">
                                          <p>{t("Van")}</p>
                                          <p className="count">60</p>
                                        </div>
                                        <div className="cat-body-details">
                                          <label htmlFor="">
                                            <img src={route} alt="" />
                                            2.5K
                                          </label>
                                          <label htmlFor="">
                                            <img src={Repair} alt="" />
                                            5$/m
                                          </label>
                                          <label htmlFor="">
                                            <img src={Pump} alt="" />
                                            25$/ltr
                                          </label>
                                          <label htmlFor="">
                                            <img src={weight} alt="" />
                                            500Kg
                                          </label>
                                        </div>
                                        <div className="cat-body-discription">
                                          <label htmlFor="">
                                            {t("Vehicle Description")}
                                          </label>
                                          <p>
                                            This is a small vehicle with
                                            capacity of 3 passengers at a time,
                                            300 ltr boot-space
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="3">
                            <div className="all-vehicle-main">
                              <div className="all-vehical-head row vehicle-top-inputs">
                                <div className="input-section-wrapper">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Vehicle Name,No., Reg. No, IMEI..."
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Vehicle Category"
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Vehicle Capacity"
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                                      <select
                                        required
                                        className="form-select"
                                        aria-label="Default select example"
                                        placeholder="Transportation Type"
                                      >
                                        <option value="">
                                          Transportation Type
                                        </option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="right-export-btn-section-wrapper">
                                  <div className="c-pointer me-2">
                                    <img src={Export} alt="" />
                                  </div>
                                  <div className="c-pointer">
                                    <img src={Import} alt="" />
                                  </div>
                                </div>
                              </div>
                              <div className="yauto" id="arrange-paading">
                                <div className="row main-cards-wrapper gx-3">
                                  <div
                                    className={
                                      sidebar
                                        ? "col-lg-6 col-md-6"
                                        : "col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={
                                        "common-cat-vehical-card-inner background-img"
                                      }
                                    >
                                      <div className="track-qustion">
                                        <img src={Qustiontracking} alt="" />
                                      </div>
                                      <div className="cat-head">
                                        <img src={Cat_ye_car} alt="" />
                                      </div>
                                      <div className="cat-body w-100">
                                        <div className="cat-body-head">
                                          <p>{t("Luxury Car")}</p>
                                          <p className="count">60</p>
                                        </div>
                                        <div className="cat-body-details">
                                          <label htmlFor="">
                                            <img src={route} alt="" />
                                            2.5K
                                          </label>
                                          <label htmlFor="">
                                            <img src={Repair} alt="" />
                                            5$/m
                                          </label>
                                          <label htmlFor="">
                                            <img src={Pump} alt="" />
                                            25$/ltr
                                          </label>
                                          <label htmlFor="">
                                            <img src={Seats} alt="" />5
                                          </label>
                                        </div>
                                        <div className="cat-body-discription">
                                          <label htmlFor="">
                                            {t("Vehicle Description")}
                                          </label>
                                          <p>
                                            This is a small vehicle with
                                            capacity of 3 passengers at a time,
                                            300 ltr boot-space
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-lg-6 col-md-6"
                                        : "col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={
                                        "common-cat-vehical-card-inner background-img"
                                      }
                                    >
                                      <div className="track-qustion">
                                        <img src={Qustiontracking} alt="" />
                                      </div>
                                      <div className="cat-head">
                                        <img src={Cat_ye_bike} alt="" />
                                      </div>
                                      <div className="cat-body w-100">
                                        <div className="cat-body-head">
                                          <p>{t("Bike")}</p>
                                          <p className="count">60</p>
                                        </div>
                                        <div className="cat-body-details">
                                          <label htmlFor="">
                                            <img src={route} alt="" />
                                            2.5K
                                          </label>
                                          <label htmlFor="">
                                            <img src={Repair} alt="" />
                                            5$/m
                                          </label>
                                          <label htmlFor="">
                                            <img src={Pump} alt="" />
                                            25$/ltr
                                          </label>
                                          <label htmlFor="">
                                            <img src={Seats} alt="" />1
                                          </label>
                                        </div>
                                        <div className="cat-body-discription">
                                          <label htmlFor="">
                                            {t("Vehicle Description")}
                                          </label>
                                          <p>
                                            This is a small vehicle with
                                            capacity of 3 passengers at a time,
                                            300 ltr boot-space
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    className={
                                      sidebar
                                        ? "col-lg-6 col-md-6"
                                        : "col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={
                                        "common-cat-vehical-card-inner background-img"
                                      }
                                    >
                                      <div className="track-qustion">
                                        <img src={Qustiontracking} alt="" />
                                      </div>
                                      <div className="cat-head">
                                        <img src={Cat_ye_car} alt="" />
                                      </div>
                                      <div className="cat-body w-100">
                                        <div className="cat-body-head">
                                          <p>{t("Pick-Up Truck")}</p>
                                          <p className="count">60</p>
                                        </div>
                                        <div className="cat-body-details">
                                          <label htmlFor="">
                                            <img src={route} alt="" />
                                            2.5K
                                          </label>
                                          <label htmlFor="">
                                            <img src={Repair} alt="" />
                                            5$/m
                                          </label>
                                          <label htmlFor="">
                                            <img src={Pump} alt="" />
                                            25$/ltr
                                          </label>
                                          <label htmlFor="">
                                            <img src={weight} alt="" />
                                            500Kg
                                          </label>
                                        </div>
                                        <div className="cat-body-discription">
                                          <label htmlFor="">
                                            {t("Vehicle Description")}
                                          </label>
                                          <p>
                                            This is a small vehicle with
                                            capacity of 3 passengers at a time,
                                            300 ltr boot-space
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    className={
                                      sidebar
                                        ? "col-lg-6 col-md-6"
                                        : "col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={
                                        "common-cat-vehical-card-inner background-img"
                                      }
                                    >
                                      <div className="track-qustion">
                                        <img src={Qustiontracking} alt="" />
                                      </div>
                                      <div className="cat-head">
                                        <img src={Cat_ye_car} alt="" />
                                      </div>
                                      <div className="cat-body w-100">
                                        <div className="cat-body-head">
                                          <p>{t("Truck")}</p>
                                          <p className="count">60</p>
                                        </div>
                                        <div className="cat-body-details">
                                          <label htmlFor="">
                                            <img src={route} alt="" />
                                            2.5K
                                          </label>
                                          <label htmlFor="">
                                            <img src={Repair} alt="" />
                                            5$/m
                                          </label>
                                          <label htmlFor="">
                                            <img src={Pump} alt="" />
                                            25$/ltr
                                          </label>
                                          <label htmlFor="">
                                            <img src={weight} alt="" />
                                            500Kg
                                          </label>
                                        </div>
                                        <div className="cat-body-discription">
                                          <label htmlFor="">
                                            {t("Vehicle Description")}
                                          </label>
                                          <p>
                                            This is a small vehicle with
                                            capacity of 3 passengers at a time,
                                            300 ltr boot-space
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    className={
                                      sidebar
                                        ? "col-lg-6 col-md-6"
                                        : "col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={
                                        "common-cat-vehical-card-inner background-img"
                                      }
                                    >
                                      <div className="track-qustion">
                                        <img src={Qustiontracking} alt="" />
                                      </div>
                                      <div className="cat-head">
                                        <img src={Cat_ye_bike} alt="" />
                                      </div>
                                      <div className="cat-body w-100">
                                        <div className="cat-body-head">
                                          <p>{t("Scooter")}</p>
                                          <p className="count">60</p>
                                        </div>
                                        <div className="cat-body-details">
                                          <label htmlFor="">
                                            <img src={route} alt="" />
                                            2.5K
                                          </label>
                                          <label htmlFor="">
                                            <img src={Repair} alt="" />
                                            5$/m
                                          </label>
                                          <label htmlFor="">
                                            <img src={Pump} alt="" />
                                            25$/ltr
                                          </label>
                                          <label htmlFor="">
                                            <img src={Seats} alt="" />1
                                          </label>
                                        </div>
                                        <div className="cat-body-discription">
                                          <label htmlFor="">
                                            {t("Vehicle Description")}
                                          </label>
                                          <p>
                                            This is a small vehicle with
                                            capacity of 3 passengers at a time,
                                            300 ltr boot-space
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    className={
                                      sidebar
                                        ? "col-lg-6 col-md-6"
                                        : "col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={
                                        "common-cat-vehical-card-inner background-img"
                                      }
                                    >
                                      <div className="track-qustion">
                                        <img src={Qustiontracking} alt="" />
                                      </div>
                                      <div className="cat-head">
                                        <img src={Cat_ye_car} alt="" />
                                      </div>
                                      <div className="cat-body w-100">
                                        <div className="cat-body-head">
                                          <p>{t("Utility Cab")}</p>
                                          <p className="count">60</p>
                                        </div>
                                        <div className="cat-body-details">
                                          <label htmlFor="">
                                            <img src={route} alt="" />
                                            2.5K
                                          </label>
                                          <label htmlFor="">
                                            <img src={Repair} alt="" />
                                            5$/m
                                          </label>
                                          <label htmlFor="">
                                            <img src={Pump} alt="" />
                                            25$/ltr
                                          </label>
                                          <label htmlFor="">
                                            <img src={Seats} alt="" />3
                                          </label>
                                        </div>
                                        <div className="cat-body-discription">
                                          <label htmlFor="">
                                            {t("Vehicle Description")}
                                          </label>
                                          <p>
                                            This is a small vehicle with
                                            capacity of 3 passengers at a time,
                                            300 ltr boot-space
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    className={
                                      sidebar
                                        ? "col-lg-6 col-md-6"
                                        : "col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={
                                        "common-cat-vehical-card-inner background-img"
                                      }
                                    >
                                      <div className="track-qustion">
                                        <img src={Qustiontracking} alt="" />
                                      </div>
                                      <div className="cat-head">
                                        <img src={Cat_ye_car} alt="" />
                                      </div>
                                      <div className="cat-body w-100">
                                        <div className="cat-body-head">
                                          <p>{t("Pick-Up Truck")}</p>
                                          <p className="count">60</p>
                                        </div>
                                        <div className="cat-body-details">
                                          <label htmlFor="">
                                            <img src={route} alt="" />
                                            2.5K
                                          </label>
                                          <label htmlFor="">
                                            <img src={Repair} alt="" />
                                            5$/m
                                          </label>
                                          <label htmlFor="">
                                            <img src={Pump} alt="" />
                                            25$/ltr
                                          </label>
                                          <label htmlFor="">
                                            <img src={Seats} alt="" />5
                                          </label>
                                        </div>
                                        <div className="cat-body-discription">
                                          <label htmlFor="">
                                            {t("Vehicle Description")}
                                          </label>
                                          <p>
                                            This is a small vehicle with
                                            capacity of 3 passengers at a time,
                                            300 ltr boot-space
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    className={
                                      sidebar
                                        ? "col-lg-6 col-md-6"
                                        : "col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={
                                        "common-cat-vehical-card-inner background-img"
                                      }
                                    >
                                      <div className="track-qustion">
                                        <img src={Qustiontracking} alt="" />
                                      </div>
                                      <div className="cat-head">
                                        <img src={Cat_ye_bike} alt="" />
                                      </div>
                                      <div className="cat-body w-100">
                                        <div className="cat-body-head">
                                          <p>{t("Bike-EV")}</p>
                                          <p className="count">60</p>
                                        </div>
                                        <div className="cat-body-details">
                                          <label htmlFor="">
                                            <img src={route} alt="" />
                                            2.5K
                                          </label>
                                          <label htmlFor="">
                                            <img src={Repair} alt="" />
                                            5$/m
                                          </label>
                                          <label htmlFor="">
                                            <img src={EVlogo} alt="" />
                                            25$
                                          </label>
                                          <label htmlFor="">
                                            <img src={Seats} alt="" />1
                                          </label>
                                        </div>
                                        <div className="cat-body-discription">
                                          <label htmlFor="">
                                            {t("Vehicle Description")}
                                          </label>
                                          <p>
                                            This is a small vehicle with
                                            capacity of 3 passengers at a time,
                                            300 ltr boot-space
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    className={
                                      sidebar
                                        ? "col-lg-6 col-md-6"
                                        : "col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={
                                        "common-cat-vehical-card-inner background-img"
                                      }
                                    >
                                      <div className="track-qustion">
                                        <img src={Qustiontracking} alt="" />
                                      </div>
                                      <div className="cat-head">
                                        <img src={Cat_ye_car} alt="" />
                                      </div>
                                      <div className="cat-body w-100">
                                        <div className="cat-body-head">
                                          <p>{t("Van")}</p>
                                          <p className="count">60</p>
                                        </div>
                                        <div className="cat-body-details">
                                          <label htmlFor="">
                                            <img src={route} alt="" />
                                            2.5K
                                          </label>
                                          <label htmlFor="">
                                            <img src={Repair} alt="" />
                                            5$/m
                                          </label>
                                          <label htmlFor="">
                                            <img src={Pump} alt="" />
                                            25$/ltr
                                          </label>
                                          <label htmlFor="">
                                            <img src={weight} alt="" />
                                            500Kg
                                          </label>
                                        </div>
                                        <div className="cat-body-discription">
                                          <label htmlFor="">
                                            {t("Vehicle Description")}
                                          </label>
                                          <p>
                                            This is a small vehicle with
                                            capacity of 3 passengers at a time,
                                            300 ltr boot-space
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Tab.Pane>
                        </Tab.Content>
                      </Col>
                    </Tab.Container>
                  </div>
                  <p className="reg-color mt-3">
                    {t("Showing")} 1 - 10 {t("of")} 200
                  </p>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="three">
                <Link to="/AddVehicleGroup">
                  <button className="cx-btn-1 tbBtn">
                    + {t("Add Vehicle Group")}
                  </button>
                </Link>
                <div className="main-master-wrapper mb-0 inner-tabs-section overflow-hidden">
                  <div id="scroll_insideThe_Padding">
                    {view == false ? (
                      <Tab.Container
                        id="left-tabs-example"
                        className="td-tab-wrapper"
                        defaultActiveKey="0"
                      >
                        <Nav
                          variant="pills"
                          className="td-nav"
                          id="InnerTabNew_Foure"
                        >
                          <Nav.Item className="td-tab">
                            <Nav.Link className="td-link" eventKey="0">
                              {t("All")}
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item className="td-tab">
                            <Nav.Link className="td-link" eventKey="1">
                              {t("Online")}
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item className="td-tab">
                            <Nav.Link className="td-link" eventKey="2">
                              {t("Offline")}
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item className="td-tab">
                            <Nav.Link className="td-link" eventKey="3">
                              {t("Untracked/Not Tracked Yet")}
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>

                        <Col sm={12} className="">
                          <Tab.Content>
                            <Tab.Pane eventKey="0">
                              <div className="all-vehicle-main">
                                <div className="all-vehical-head row vehicle-top-inputs">
                                  <div className="input-section-wrapper">
                                    <div className="row">
                                      <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Vehicle Name,No., Reg. No, IMEI..."
                                        />
                                      </div>
                                      <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Vehicle Category"
                                        />
                                      </div>
                                      <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Vehicle Capacity"
                                        />
                                      </div>
                                      <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                                        <select
                                          required
                                          className="form-select"
                                          aria-label="Default select example"
                                          placeholder="Transportation Type"
                                        >
                                          <option value="">
                                            Transportation Type
                                          </option>
                                          <option value="1">One</option>
                                          <option value="2">Two</option>
                                          <option value="3">Three</option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="right-export-btn-section-wrapper">
                                    <div className="c-pointer me-2">
                                      <img src={Export} alt="" />
                                    </div>
                                    <div className="c-pointer">
                                      <img src={Import} alt="" />
                                    </div>
                                  </div>
                                </div>

                                <div className="yauto" id="arrange-paading">
                                  <div className="row main-cards-wrapper gx-3">
                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={"common-vehical-card-inner"}
                                      >
                                        <div className="vehical-card-head ">
                                          <div className="heading">
                                            <img
                                              src={Grouplogo}
                                              alt=""
                                              className="custom-Margin "
                                            />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Group Name")}
                                              </p>
                                              <p className="title">
                                                Night shift vehicles
                                              </p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              <Dropdown.Toggle id="dropdown-basic">
                                                <img src={option} alt="" />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                <Dropdown.Item>
                                                  <Link
                                                    to="#"
                                                    onClick={showView}
                                                    className="d-block"
                                                  >
                                                    {t("View")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                  <Link
                                                    to="/EditVehicleGroup"
                                                    className="d-block"
                                                  >
                                                    {t("Edit")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                  <Link
                                                    to="#"
                                                    className="d-block"
                                                  >
                                                    {t("Geofence")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={showGroup}
                                                >
                                                  <Link
                                                    to="#"
                                                    className="d-block"
                                                  >
                                                    {t("Delete")}
                                                  </Link>
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Manager")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Created At")}
                                            </p>
                                            <p className="title">20-12-2022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Drivers")}
                                            </p>
                                            <p className="title">60</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicles")}
                                            </p>
                                            <p className="title">5</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">
                                              Passenger & Goods
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={"common-vehical-card-inner"}
                                      >
                                        <div className="vehical-card-head ">
                                          <div className="heading">
                                            <img
                                              src={Grouplogo}
                                              alt=""
                                              className="custom-Margin "
                                            />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Group Name")}
                                              </p>
                                              <p className="title">
                                                Guest vehicles
                                              </p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              <Dropdown.Toggle id="dropdown-basic">
                                                <img src={option} alt="" />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                <Dropdown.Item>
                                                  <Link
                                                    to="#"
                                                    onClick={showView}
                                                  >
                                                    {t("View")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                  <Link to="/EditVehicleGroup">
                                                    {t("Edit")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                  <Link to="#">
                                                    {t("Geofence")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={showGroup}
                                                >
                                                  <Link to="#">
                                                    {t("Delete")}
                                                  </Link>
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Manager")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Created At")}
                                            </p>
                                            <p className="title">20-12-2022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Drivers")}
                                            </p>
                                            <p className="title">60</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicles")}
                                            </p>
                                            <p className="title">5</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">
                                              Passenger & Goods
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={"common-vehical-card-inner"}
                                      >
                                        <div className="vehical-card-head ">
                                          <div className="heading">
                                            <img
                                              src={Grouplogo}
                                              alt=""
                                              className="custom-Margin "
                                            />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Group Name")}
                                              </p>
                                              <p className="title">
                                                Airport pickup
                                              </p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              <Dropdown.Toggle id="dropdown-basic">
                                                <img src={option} alt="" />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                <Dropdown.Item>
                                                  <Link
                                                    to="#"
                                                    onClick={showView}
                                                  >
                                                    {t("View")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                  <Link to="/EditVehicleGroup">
                                                    {t("Edit")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                  <Link to="#">
                                                    {t("Geofence")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={showGroup}
                                                >
                                                  <Link to="#">
                                                    {t("Delete")}
                                                  </Link>
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Manager")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Created At")}
                                            </p>
                                            <p className="title">20-12-2022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Drivers")}
                                            </p>
                                            <p className="title">60</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicles")}
                                            </p>
                                            <p className="title">5</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">
                                              Passenger & Goods
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={"common-vehical-card-inner"}
                                      >
                                        <div className="vehical-card-head ">
                                          <div className="heading">
                                            <img
                                              src={Grouplogo}
                                              alt=""
                                              className="custom-Margin "
                                            />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Group Name")}
                                              </p>
                                              <p className="title">Trailers</p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              <Dropdown.Toggle id="dropdown-basic">
                                                <img src={option} alt="" />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                <Dropdown.Item>
                                                  <Link
                                                    to="#"
                                                    onClick={showView}
                                                  >
                                                    {t("View")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                  <Link to="/EditVehicleGroup">
                                                    {t("Edit")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                  <Link to="#">
                                                    {t("Geofence")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={showGroup}
                                                >
                                                  <Link to="#">
                                                    {t("Delete")}
                                                  </Link>
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Manager")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Created At")}
                                            </p>
                                            <p className="title">20-12-2022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Drivers")}
                                            </p>
                                            <p className="title">60</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicles")}
                                            </p>
                                            <p className="title">5</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">
                                              Passenger & Goods
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={"common-vehical-card-inner"}
                                      >
                                        <div className="vehical-card-head ">
                                          <div className="heading">
                                            <img
                                              src={Grouplogo}
                                              alt=""
                                              className="custom-Margin "
                                            />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Group Name")}
                                              </p>
                                              <p className="title">
                                                Containers
                                              </p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              <Dropdown.Toggle id="dropdown-basic">
                                                <img src={option} alt="" />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                <Dropdown.Item>
                                                  <Link
                                                    to="#"
                                                    onClick={showView}
                                                  >
                                                    {t("View")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                  <Link to="/EditVehicleGroup">
                                                    {t("Edit")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                  <Link to="#">
                                                    {t("Geofence")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={showGroup}
                                                >
                                                  <Link to="#">
                                                    {t("Delete")}
                                                  </Link>
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Manager")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Created At")}
                                            </p>
                                            <p className="title">20-12-2022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Drivers")}
                                            </p>
                                            <p className="title">60</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicles")}
                                            </p>
                                            <p className="title">5</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">
                                              Passenger & Goods
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={"common-vehical-card-inner"}
                                      >
                                        <div className="vehical-card-head ">
                                          <div className="heading">
                                            <img
                                              src={Grouplogo}
                                              alt=""
                                              className="custom-Margin "
                                            />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Group Name")}
                                              </p>
                                              <p className="title">
                                                Passenger vehicles
                                              </p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              <Dropdown.Toggle id="dropdown-basic">
                                                <img src={option} alt="" />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                <Dropdown.Item>
                                                  <Link
                                                    to="#"
                                                    onClick={showView}
                                                  >
                                                    {t("View")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                  <Link to="/EditVehicleGroup">
                                                    {t("Edit")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                  <Link to="#">
                                                    {t("Geofence")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={showGroup}
                                                >
                                                  <Link to="#">
                                                    {t("Delete")}
                                                  </Link>
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Manager")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Created At")}
                                            </p>
                                            <p className="title">20-12-2022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Drivers")}
                                            </p>
                                            <p className="title">60</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicles")}
                                            </p>
                                            <p className="title">5</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">
                                              Passenger & Goods
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={"common-vehical-card-inner"}
                                      >
                                        <div className="vehical-card-head ">
                                          <div className="heading">
                                            <img
                                              src={Grouplogo}
                                              alt=""
                                              className="custom-Margin "
                                            />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Group Name")}
                                              </p>
                                              <p className="title">
                                                Under maintenance vehicles
                                              </p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              <Dropdown.Toggle id="dropdown-basic">
                                                <img src={option} alt="" />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                <Dropdown.Item>
                                                  <Link
                                                    to="#"
                                                    onClick={showView}
                                                  >
                                                    {t("View")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                  <Link to="/EditVehicleGroup">
                                                    {t("Edit")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                  <Link to="#">
                                                    {t("Geofence")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={showGroup}
                                                >
                                                  <Link to="#">
                                                    {t("Delete")}
                                                  </Link>
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Manager")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Created At")}
                                            </p>
                                            <p className="title">20-12-2022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Drivers")}
                                            </p>
                                            <p className="title">60</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicles")}
                                            </p>
                                            <p className="title">5</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">
                                              Passenger & Goods
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={"common-vehical-card-inner"}
                                      >
                                        <div className="vehical-card-head ">
                                          <div className="heading">
                                            <img
                                              src={Grouplogo}
                                              alt=""
                                              className="custom-Margin "
                                            />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Group Name")}
                                              </p>
                                              <p className="title">
                                                Night shift vehicles
                                              </p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              <Dropdown.Toggle id="dropdown-basic">
                                                <img src={option} alt="" />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                <Dropdown.Item>
                                                  <Link
                                                    to="#"
                                                    onClick={showView}
                                                  >
                                                    {t("View")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                  <Link to="/EditVehicleGroup">
                                                    {t("Edit")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                  <Link to="#">
                                                    {t("Geofence")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={showGroup}
                                                >
                                                  <Link to="#">
                                                    {t("Delete")}
                                                  </Link>
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Manager")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Created At")}
                                            </p>
                                            <p className="title">20-12-2022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Drivers")}
                                            </p>
                                            <p className="title">60</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicles")}
                                            </p>
                                            <p className="title">5</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">
                                              Passenger & Goods
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={"common-vehical-card-inner"}
                                      >
                                        <div className="vehical-card-head ">
                                          <div className="heading">
                                            <img
                                              src={Grouplogo}
                                              alt=""
                                              className="custom-Margin "
                                            />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Group Name")}
                                              </p>
                                              <p className="title">
                                                Airport pickup
                                              </p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              <Dropdown.Toggle id="dropdown-basic">
                                                <img src={option} alt="" />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                <Dropdown.Item>
                                                  <Link
                                                    to="#"
                                                    onClick={showView}
                                                  >
                                                    {t("View")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                  <Link to="/EditVehicleGroup">
                                                    {t("Edit")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                  <Link to="#">
                                                    {t("Geofence")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={showGroup}
                                                >
                                                  <Link to="#">
                                                    {t("Delete")}
                                                  </Link>
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Manager")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Created At")}
                                            </p>
                                            <p className="title">20-12-2022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Drivers")}
                                            </p>
                                            <p className="title">60</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicles")}
                                            </p>
                                            <p className="title">5</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">
                                              Passenger & Goods
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="1">
                              <div className="all-vehicle-main">
                                <div className="all-vehical-head row vehicle-top-inputs">
                                  <div className="input-section-wrapper">
                                    <div className="row">
                                      <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Vehicle Name,No., Reg. No, IMEI..."
                                        />
                                      </div>
                                      <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Vehicle Category"
                                        />
                                      </div>
                                      <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Vehicle Capacity"
                                        />
                                      </div>
                                      <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                                        <select
                                          required
                                          className="form-select"
                                          aria-label="Default select example"
                                          placeholder="Transportation Type"
                                        >
                                          <option value="">
                                            Transportation Type
                                          </option>
                                          <option value="1">One</option>
                                          <option value="2">Two</option>
                                          <option value="3">Three</option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="right-export-btn-section-wrapper">
                                    <div className="c-pointer me-2">
                                      <img src={Export} alt="" />
                                    </div>
                                    <div className="c-pointer">
                                      <img src={Import} alt="" />
                                    </div>
                                  </div>
                                </div>
                                <div className="yauto" id="arrange-paading">
                                  <div className="row main-cards-wrapper gx-3">
                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={"common-vehical-card-inner"}
                                      >
                                        <div className="vehical-card-head ">
                                          <div className="heading">
                                            <img
                                              src={Grouplogo}
                                              alt=""
                                              className="custom-Margin "
                                            />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Group Name")}
                                              </p>
                                              <p className="title">Ambani</p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              <Dropdown.Toggle id="dropdown-basic">
                                                <img src={option} alt="" />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                <Dropdown.Item>
                                                  <Link
                                                    to="#"
                                                    onClick={showView}
                                                    className="d-block"
                                                  >
                                                    {t("View")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                  <Link
                                                    to="/EditVehicleGroup"
                                                    className="d-block"
                                                  >
                                                    {t("Edit")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                  <Link
                                                    to="#"
                                                    className="d-block"
                                                  >
                                                    {t("Geofence")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={showGroup}
                                                >
                                                  <Link
                                                    to="#"
                                                    className="d-block"
                                                  >
                                                    {t("Delete")}
                                                  </Link>
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Manager")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Created At")}
                                            </p>
                                            <p className="title">20-12-2022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Drivers")}
                                            </p>
                                            <p className="title">60</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicles")}
                                            </p>
                                            <p className="title">5</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">
                                              Passenger & Goods
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={"common-vehical-card-inner"}
                                      >
                                        <div className="vehical-card-head ">
                                          <div className="heading">
                                            <img
                                              src={Grouplogo}
                                              alt=""
                                              className="custom-Margin "
                                            />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Group Name")}
                                              </p>
                                              <p className="title">Ambani</p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              <Dropdown.Toggle id="dropdown-basic">
                                                <img src={option} alt="" />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                <Dropdown.Item>
                                                  <Link
                                                    to="#"
                                                    onClick={showView}
                                                  >
                                                    {t("View")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                  <Link to="/EditVehicleGroup">
                                                    {t("Edit")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                  <Link to="#">
                                                    {t("Geofence")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={showGroup}
                                                >
                                                  <Link to="#">
                                                    {t("Delete")}
                                                  </Link>
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Manager")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Created At")}
                                            </p>
                                            <p className="title">20-12-2022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Drivers")}
                                            </p>
                                            <p className="title">60</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicles")}
                                            </p>
                                            <p className="title">5</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">
                                              Passenger & Goods
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={"common-vehical-card-inner"}
                                      >
                                        <div className="vehical-card-head ">
                                          <div className="heading">
                                            <img
                                              src={Grouplogo}
                                              alt=""
                                              className="custom-Margin "
                                            />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Group Name")}
                                              </p>
                                              <p className="title">Ambani</p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              <Dropdown.Toggle id="dropdown-basic">
                                                <img src={option} alt="" />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                <Dropdown.Item>
                                                  <Link
                                                    to="#"
                                                    onClick={showView}
                                                  >
                                                    {t("View")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                  <Link to="/EditVehicleGroup">
                                                    {t("Edit")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                  <Link to="#">
                                                    {t("Geofence")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={showGroup}
                                                >
                                                  <Link to="#">
                                                    {t("Delete")}
                                                  </Link>
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Manager")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Created At")}
                                            </p>
                                            <p className="title">20-12-2022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Drivers")}
                                            </p>
                                            <p className="title">60</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicles")}
                                            </p>
                                            <p className="title">5</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">
                                              Passenger & Goods
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={"common-vehical-card-inner"}
                                      >
                                        <div className="vehical-card-head ">
                                          <div className="heading">
                                            <img
                                              src={Grouplogo}
                                              alt=""
                                              className="custom-Margin "
                                            />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Group Name")}
                                              </p>
                                              <p className="title">Ambani</p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              <Dropdown.Toggle id="dropdown-basic">
                                                <img src={option} alt="" />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                <Dropdown.Item>
                                                  <Link
                                                    to="#"
                                                    onClick={showView}
                                                  >
                                                    {t("View")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                  <Link to="/EditVehicleGroup">
                                                    {t("Edit")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                  <Link to="#">
                                                    {t("Geofence")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={showGroup}
                                                >
                                                  <Link to="#">
                                                    {t("Delete")}
                                                  </Link>
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Manager")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Created At")}
                                            </p>
                                            <p className="title">20-12-2022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Drivers")}
                                            </p>
                                            <p className="title">60</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicles")}
                                            </p>
                                            <p className="title">5</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">
                                              Passenger & Goods
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={"common-vehical-card-inner"}
                                      >
                                        <div className="vehical-card-head ">
                                          <div className="heading">
                                            <img
                                              src={Grouplogo}
                                              alt=""
                                              className="custom-Margin "
                                            />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Group Name")}
                                              </p>
                                              <p className="title">Ambani</p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              <Dropdown.Toggle id="dropdown-basic">
                                                <img src={option} alt="" />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                <Dropdown.Item>
                                                  <Link
                                                    to="#"
                                                    onClick={showView}
                                                  >
                                                    {t("View")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                  <Link to="/EditVehicleGroup">
                                                    {t("Edit")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                  <Link to="#">
                                                    {t("Geofence")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={showGroup}
                                                >
                                                  <Link to="#">
                                                    {t("Delete")}
                                                  </Link>
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Manager")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Created At")}
                                            </p>
                                            <p className="title">20-12-2022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Drivers")}
                                            </p>
                                            <p className="title">60</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicles")}
                                            </p>
                                            <p className="title">5</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">
                                              Passenger & Goods
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={"common-vehical-card-inner"}
                                      >
                                        <div className="vehical-card-head ">
                                          <div className="heading">
                                            <img
                                              src={Grouplogo}
                                              alt=""
                                              className="custom-Margin "
                                            />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Group Name")}
                                              </p>
                                              <p className="title">Ambani</p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              <Dropdown.Toggle id="dropdown-basic">
                                                <img src={option} alt="" />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                <Dropdown.Item>
                                                  <Link
                                                    to="#"
                                                    onClick={showView}
                                                  >
                                                    {t("View")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                  <Link to="/EditVehicleGroup">
                                                    {t("Edit")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                  <Link to="#">
                                                    {t("Geofence")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={showGroup}
                                                >
                                                  <Link to="#">
                                                    {t("Delete")}
                                                  </Link>
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Manager")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Created At")}
                                            </p>
                                            <p className="title">20-12-2022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Drivers")}
                                            </p>
                                            <p className="title">60</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicles")}
                                            </p>
                                            <p className="title">5</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">
                                              Passenger & Goods
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={"common-vehical-card-inner"}
                                      >
                                        <div className="vehical-card-head ">
                                          <div className="heading">
                                            <img
                                              src={Grouplogo}
                                              alt=""
                                              className="custom-Margin "
                                            />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Group Name")}
                                              </p>
                                              <p className="title">Ambani</p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              <Dropdown.Toggle id="dropdown-basic">
                                                <img src={option} alt="" />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                <Dropdown.Item>
                                                  <Link
                                                    to="#"
                                                    onClick={showView}
                                                  >
                                                    {t("View")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                  <Link to="/EditVehicleGroup">
                                                    {t("Edit")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                  <Link to="#">
                                                    {t("Geofence")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={showGroup}
                                                >
                                                  <Link to="#">
                                                    {t("Delete")}
                                                  </Link>
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Manager")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Created At")}
                                            </p>
                                            <p className="title">20-12-2022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Drivers")}
                                            </p>
                                            <p className="title">60</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicles")}
                                            </p>
                                            <p className="title">5</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">
                                              Passenger & Goods
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={"common-vehical-card-inner"}
                                      >
                                        <div className="vehical-card-head ">
                                          <div className="heading">
                                            <img
                                              src={Grouplogo}
                                              alt=""
                                              className="custom-Margin "
                                            />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Group Name")}
                                              </p>
                                              <p className="title">Ambani</p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              <Dropdown.Toggle id="dropdown-basic">
                                                <img src={option} alt="" />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                <Dropdown.Item>
                                                  <Link
                                                    to="#"
                                                    onClick={showView}
                                                  >
                                                    {t("View")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                  <Link to="/EditVehicleGroup">
                                                    {t("Edit")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                  <Link to="#">
                                                    {t("Geofence")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={showGroup}
                                                >
                                                  <Link to="#">
                                                    {t("Delete")}
                                                  </Link>
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Manager")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Created At")}
                                            </p>
                                            <p className="title">20-12-2022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Drivers")}
                                            </p>
                                            <p className="title">60</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicles")}
                                            </p>
                                            <p className="title">5</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">
                                              Passenger & Goods
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={"common-vehical-card-inner"}
                                      >
                                        <div className="vehical-card-head ">
                                          <div className="heading">
                                            <img
                                              src={Grouplogo}
                                              alt=""
                                              className="custom-Margin "
                                            />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Group Name")}
                                              </p>
                                              <p className="title">Ambani</p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              <Dropdown.Toggle id="dropdown-basic">
                                                <img src={option} alt="" />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                <Dropdown.Item>
                                                  <Link
                                                    to="#"
                                                    onClick={showView}
                                                  >
                                                    {t("View")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                  <Link to="/EditVehicleGroup">
                                                    {t("Edit")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                  <Link to="#">
                                                    {t("Geofence")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={showGroup}
                                                >
                                                  <Link to="#">
                                                    {t("Delete")}
                                                  </Link>
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Manager")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Created At")}
                                            </p>
                                            <p className="title">20-12-2022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Drivers")}
                                            </p>
                                            <p className="title">60</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicles")}
                                            </p>
                                            <p className="title">5</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">
                                              Passenger & Goods
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="2">
                              <div className="all-vehicle-main">
                                <div className="all-vehical-head row vehicle-top-inputs">
                                  <div className="input-section-wrapper">
                                    <div className="row">
                                      <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Vehicle Name,No., Reg. No, IMEI..."
                                        />
                                      </div>
                                      <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Vehicle Category"
                                        />
                                      </div>
                                      <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Vehicle Capacity"
                                        />
                                      </div>
                                      <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                                        <select
                                          required
                                          className="form-select"
                                          aria-label="Default select example"
                                          placeholder="Transportation Type"
                                        >
                                          <option value="">
                                            Transportation Type
                                          </option>
                                          <option value="1">One</option>
                                          <option value="2">Two</option>
                                          <option value="3">Three</option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="right-export-btn-section-wrapper">
                                    <div className="c-pointer me-2">
                                      <img src={Export} alt="" />
                                    </div>
                                    <div className="c-pointer">
                                      <img src={Import} alt="" />
                                    </div>
                                  </div>
                                </div>
                                <div className="yauto" id="arrange-paading">
                                  <div className="row main-cards-wrapper gx-3">
                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={
                                          "common-vehical-card-inner  background-img"
                                        }
                                      >
                                        <div className="track-qustion"></div>
                                        <div className="vehical-card-head ">
                                          <div className="heading">
                                            <img
                                              src={Grouplogo}
                                              alt=""
                                              className="custom-Margin "
                                            />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Group Name")}
                                              </p>
                                              <p className="title">Ambani</p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              {/* <Dropdown.Toggle id="dropdown-basic"> */}
                                              <img src={option} alt="" />
                                              {/* </Dropdown.Toggle> */}

                                              {/* <Dropdown.Menu>
                                                                        <Dropdown.Item  >{t("View")}</Dropdown.Item>
                                                                        <Dropdown.Item   >Edit</Dropdown.Item>
                                                                        <Dropdown.Item   >{t("Geofence")}</Dropdown.Item>
                                                                        <Dropdown.Item   onClick={handleShow} >Delete </Dropdown.Item>
                                                                    </Dropdown.Menu> */}
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Manager")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Created At")}
                                            </p>
                                            <p className="title">20-12-2022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Drivers")}
                                            </p>
                                            <p className="title">60</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicles")}
                                            </p>
                                            <p className="title">5</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">
                                              Passenger & Goods
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={
                                          "common-vehical-card-inner  background-img"
                                        }
                                      >
                                        <div className="track-qustion"></div>
                                        <div className="vehical-card-head ">
                                          <div className="heading">
                                            <img
                                              src={Grouplogo}
                                              alt=""
                                              className="custom-Margin "
                                            />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Group Name")}
                                              </p>
                                              <p className="title">Ambani</p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              {/* <Dropdown.Toggle id="dropdown-basic"> */}
                                              <img src={option} alt="" />
                                              {/* </Dropdown.Toggle> */}
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Manager")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Created At")}
                                            </p>
                                            <p className="title">20-12-2022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Drivers")}
                                            </p>
                                            <p className="title">60</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicles")}
                                            </p>
                                            <p className="title">5</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">
                                              Passenger & Goods
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={
                                          "common-vehical-card-inner  background-img"
                                        }
                                      >
                                        <div className="track-qustion"></div>
                                        <div className="vehical-card-head ">
                                          <div className="heading">
                                            <img
                                              src={Grouplogo}
                                              alt=""
                                              className="custom-Margin "
                                            />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Group Name")}
                                              </p>
                                              <p className="title">Ambani</p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              {/* <Dropdown.Toggle id="dropdown-basic"> */}
                                              <img src={option} alt="" />
                                              {/* </Dropdown.Toggle> */}
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Manager")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Created At")}
                                            </p>
                                            <p className="title">20-12-2022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Drivers")}
                                            </p>
                                            <p className="title">60</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicles")}
                                            </p>
                                            <p className="title">5</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">
                                              Passenger & Goods
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={
                                          "common-vehical-card-inner  background-img"
                                        }
                                      >
                                        <div className="track-qustion"></div>
                                        <div className="vehical-card-head ">
                                          <div className="heading">
                                            <img
                                              src={Grouplogo}
                                              alt=""
                                              className="custom-Margin "
                                            />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Group Name")}
                                              </p>
                                              <p className="title">Ambani</p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              {/* <Dropdown.Toggle id="dropdown-basic"> */}
                                              <img src={option} alt="" />
                                              {/* </Dropdown.Toggle> */}

                                              {/* <Dropdown.Menu>
                                                                        <Dropdown.Item  >View</Dropdown.Item>
                                                                        <Dropdown.Item   >Edit</Dropdown.Item>
                                                                        <Dropdown.Item   >{t("Geofence")}</Dropdown.Item>
                                                                        <Dropdown.Item   onClick={handleShow} >Delete </Dropdown.Item>
                                                                    </Dropdown.Menu> */}
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Manager")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Created At")}
                                            </p>
                                            <p className="title">20-12-2022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Drivers")}
                                            </p>
                                            <p className="title">60</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicles")}
                                            </p>
                                            <p className="title">5</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">
                                              Passenger & Goods
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={
                                          "common-vehical-card-inner  background-img"
                                        }
                                      >
                                        <div className="track-qustion"></div>
                                        <div className="vehical-card-head ">
                                          <div className="heading">
                                            <img
                                              src={Grouplogo}
                                              alt=""
                                              className="custom-Margin "
                                            />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Group Name")}
                                              </p>
                                              <p className="title">Ambani</p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              {/* <Dropdown.Toggle id="dropdown-basic"> */}
                                              <img src={option} alt="" />
                                              {/* </Dropdown.Toggle> */}

                                              {/* <Dropdown.Menu>
                                                                        <Dropdown.Item  >View</Dropdown.Item>
                                                                        <Dropdown.Item   >Edit</Dropdown.Item>
                                                                        <Dropdown.Item   >{t("Geofence")}</Dropdown.Item>
                                                                        <Dropdown.Item   onClick={handleShow} >Delete </Dropdown.Item>
                                                                    </Dropdown.Menu> */}
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Manager")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Created At")}
                                            </p>
                                            <p className="title">20-12-2022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Drivers")}
                                            </p>
                                            <p className="title">60</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicles")}
                                            </p>
                                            <p className="title">5</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">
                                              Passenger & Goods
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={
                                          "common-vehical-card-inner  background-img"
                                        }
                                      >
                                        <div className="track-qustion"></div>
                                        <div className="vehical-card-head ">
                                          <div className="heading">
                                            <img
                                              src={Grouplogo}
                                              alt=""
                                              className="custom-Margin "
                                            />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Group Name")}
                                              </p>
                                              <p className="title">Ambani</p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              {/* <Dropdown.Toggle id="dropdown-basic"> */}
                                              <img src={option} alt="" />
                                              {/* </Dropdown.Toggle> */}

                                              {/* <Dropdown.Menu>
                                                                        <Dropdown.Item  >View</Dropdown.Item>
                                                                        <Dropdown.Item   >Edit</Dropdown.Item>
                                                                        <Dropdown.Item   >{t("Geofence")}</Dropdown.Item>
                                                                        <Dropdown.Item   onClick={handleShow} >Delete </Dropdown.Item>
                                                                    </Dropdown.Menu> */}
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Manager")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Created At")}
                                            </p>
                                            <p className="title">20-12-2022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Drivers")}
                                            </p>
                                            <p className="title">60</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicles")}
                                            </p>
                                            <p className="title">5</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">
                                              Passenger & Goods
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={
                                          "common-vehical-card-inner  background-img"
                                        }
                                      >
                                        <div className="track-qustion"></div>
                                        <div className="vehical-card-head ">
                                          <div className="heading">
                                            <img
                                              src={Grouplogo}
                                              alt=""
                                              className="custom-Margin "
                                            />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Group Name")}
                                              </p>
                                              <p className="title">Ambani</p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              {/* <Dropdown.Toggle id="dropdown-basic"> */}
                                              <img src={option} alt="" />
                                              {/* </Dropdown.Toggle> */}

                                              {/* <Dropdown.Menu>
                                                                        <Dropdown.Item  >View</Dropdown.Item>
                                                                        <Dropdown.Item   >Edit</Dropdown.Item>
                                                                        <Dropdown.Item   >{t("Geofence")}</Dropdown.Item>
                                                                        <Dropdown.Item   onClick={handleShow} >Delete </Dropdown.Item>
                                                                    </Dropdown.Menu> */}
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Manager")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Created At")}
                                            </p>
                                            <p className="title">20-12-2022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Drivers")}
                                            </p>
                                            <p className="title">60</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicles")}
                                            </p>
                                            <p className="title">5</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">
                                              Passenger & Goods
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={
                                          "common-vehical-card-inner  background-img"
                                        }
                                      >
                                        <div className="track-qustion"></div>
                                        <div className="vehical-card-head ">
                                          <div className="heading">
                                            <img
                                              src={Grouplogo}
                                              alt=""
                                              className="custom-Margin "
                                            />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Group Name")}
                                              </p>
                                              <p className="title">Ambani</p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              {/* <Dropdown.Toggle id="dropdown-basic"> */}
                                              <img src={option} alt="" />
                                              {/* </Dropdown.Toggle> */}

                                              {/* <Dropdown.Menu>
                                                                        <Dropdown.Item  >View</Dropdown.Item>
                                                                        <Dropdown.Item   >Edit</Dropdown.Item>
                                                                        <Dropdown.Item   >{t("Geofence")}</Dropdown.Item>
                                                                        <Dropdown.Item   onClick={handleShow} >Delete </Dropdown.Item>
                                                                    </Dropdown.Menu> */}
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Manager")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Created At")}
                                            </p>
                                            <p className="title">20-12-2022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Drivers")}
                                            </p>
                                            <p className="title">60</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicles")}
                                            </p>
                                            <p className="title">5</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">
                                              Passenger & Goods
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={
                                          "common-vehical-card-inner  background-img"
                                        }
                                      >
                                        <div className="track-qustion"></div>
                                        <div className="vehical-card-head ">
                                          <div className="heading">
                                            <img
                                              src={Grouplogo}
                                              alt=""
                                              className="custom-Margin "
                                            />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Group Name")}
                                              </p>
                                              <p className="title">Ambani</p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              {/* <Dropdown.Toggle id="dropdown-basic"> */}
                                              <img src={option} alt="" />
                                              {/* </Dropdown.Toggle> */}

                                              {/* <Dropdown.Menu>
                                                                        <Dropdown.Item  >View</Dropdown.Item>
                                                                        <Dropdown.Item   >Edit</Dropdown.Item>
                                                                        <Dropdown.Item   >{t("Geofence")}</Dropdown.Item>
                                                                        <Dropdown.Item   onClick={handleShow} >Delete </Dropdown.Item>
                                                                    </Dropdown.Menu> */}
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Manager")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Created At")}
                                            </p>
                                            <p className="title">20-12-2022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Drivers")}
                                            </p>
                                            <p className="title">60</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicles")}
                                            </p>
                                            <p className="title">5</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">
                                              Passenger & Goods
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="3">
                              <div className="all-vehicle-main">
                                <div className="all-vehical-head row vehicle-top-inputs">
                                  <div className="input-section-wrapper">
                                    <div className="row">
                                      <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Vehicle Name,No., Reg. No, IMEI..."
                                        />
                                      </div>
                                      <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Vehicle Category"
                                        />
                                      </div>
                                      <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Vehicle Capacity"
                                        />
                                      </div>
                                      <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                                        <select
                                          required
                                          className="form-select"
                                          aria-label="Default select example"
                                          placeholder="Transportation Type"
                                        >
                                          <option value="">
                                            Transportation Type
                                          </option>
                                          <option value="1">One</option>
                                          <option value="2">Two</option>
                                          <option value="3">Three</option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="right-export-btn-section-wrapper">
                                    <div className="c-pointer me-2">
                                      <img src={Export} alt="" />
                                    </div>
                                    <div className="c-pointer">
                                      <img src={Import} alt="" />
                                    </div>
                                  </div>
                                </div>
                                <div className="yauto" id="arrange-paading">
                                  <div className="row main-cards-wrapper gx-3">
                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={
                                          "common-vehical-card-inner  background-img"
                                        }
                                      >
                                        <div className="track-qustion">
                                          <img src={Qustiontracking} alt="" />
                                        </div>
                                        <div className="vehical-card-head ">
                                          <div className="heading">
                                            <img
                                              src={Grouplogo}
                                              alt=""
                                              className="custom-Margin "
                                            />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Group Name")}
                                              </p>
                                              <p className="title">Ambani</p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              {/* <Dropdown.Toggle id="dropdown-basic"> */}
                                              <img src={option} alt="" />
                                              {/* </Dropdown.Toggle> */}

                                              {/* <Dropdown.Menu>
                                                                        <Dropdown.Item  >View</Dropdown.Item>
                                                                        <Dropdown.Item   >Edit</Dropdown.Item>
                                                                        <Dropdown.Item   >{t("Geofence")}</Dropdown.Item>
                                                                        <Dropdown.Item   onClick={handleShow} >Delete </Dropdown.Item>
                                                                    </Dropdown.Menu> */}
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Manager")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Created At")}
                                            </p>
                                            <p className="title">20-12-2022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Drivers")}
                                            </p>
                                            <p className="title">60</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicles")}
                                            </p>
                                            <p className="title">5</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">
                                              Passenger & Goods
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={
                                          "common-vehical-card-inner  background-img"
                                        }
                                      >
                                        <div className="track-qustion">
                                          <img src={Qustiontracking} alt="" />
                                        </div>
                                        <div className="vehical-card-head ">
                                          <div className="heading">
                                            <img
                                              src={Grouplogo}
                                              alt=""
                                              className="custom-Margin "
                                            />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Group Name")}
                                              </p>
                                              <p className="title">Ambani</p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              {/* <Dropdown.Toggle id="dropdown-basic"> */}
                                              <img src={option} alt="" />
                                              {/* </Dropdown.Toggle> */}

                                              {/* <Dropdown.Menu>
                                                                        <Dropdown.Item  >View</Dropdown.Item>
                                                                        <Dropdown.Item   >Edit</Dropdown.Item>
                                                                        <Dropdown.Item   >{t("Geofence")}</Dropdown.Item>
                                                                        <Dropdown.Item   onClick={handleShow} >Delete </Dropdown.Item>
                                                                    </Dropdown.Menu> */}
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Manager")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Created At")}
                                            </p>
                                            <p className="title">20-12-2022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Drivers")}
                                            </p>
                                            <p className="title">60</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicles")}
                                            </p>
                                            <p className="title">5</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">
                                              Passenger & Goods
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={
                                          "common-vehical-card-inner  background-img"
                                        }
                                      >
                                        <div className="track-qustion">
                                          <img src={Qustiontracking} alt="" />
                                        </div>
                                        <div className="vehical-card-head ">
                                          <div className="heading">
                                            <img
                                              src={Grouplogo}
                                              alt=""
                                              className="custom-Margin "
                                            />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Group Name")}
                                              </p>
                                              <p className="title">Ambani</p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              {/* <Dropdown.Toggle id="dropdown-basic"> */}
                                              <img src={option} alt="" />
                                              {/* </Dropdown.Toggle> */}

                                              {/* <Dropdown.Menu>
                                                                        <Dropdown.Item  >View</Dropdown.Item>
                                                                        <Dropdown.Item   >Edit</Dropdown.Item>
                                                                        <Dropdown.Item   >{t("Geofence")}</Dropdown.Item>
                                                                        <Dropdown.Item   onClick={handleShow} >Delete </Dropdown.Item>
                                                                    </Dropdown.Menu> */}
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Manager")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Created At")}
                                            </p>
                                            <p className="title">20-12-2022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Drivers")}
                                            </p>
                                            <p className="title">60</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicles")}
                                            </p>
                                            <p className="title">5</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">
                                              Passenger & Goods
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={
                                          "common-vehical-card-inner  background-img"
                                        }
                                      >
                                        <div className="track-qustion">
                                          <img src={Qustiontracking} alt="" />
                                        </div>
                                        <div className="vehical-card-head ">
                                          <div className="heading">
                                            <img
                                              src={Grouplogo}
                                              alt=""
                                              className="custom-Margin "
                                            />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Group Name")}
                                              </p>
                                              <p className="title">Ambani</p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              {/* <Dropdown.Toggle id="dropdown-basic"> */}
                                              <img src={option} alt="" />
                                              {/* </Dropdown.Toggle> */}

                                              {/* <Dropdown.Menu>
                                                                        <Dropdown.Item  >View</Dropdown.Item>
                                                                        <Dropdown.Item   >Edit</Dropdown.Item>
                                                                        <Dropdown.Item   >{t("Geofence")}</Dropdown.Item>
                                                                        <Dropdown.Item   onClick={handleShow} >Delete </Dropdown.Item>
                                                                    </Dropdown.Menu> */}
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Manager")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Created At")}
                                            </p>
                                            <p className="title">20-12-2022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Drivers")}
                                            </p>
                                            <p className="title">60</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicles")}
                                            </p>
                                            <p className="title">5</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">
                                              Passenger & Goods
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={
                                          "common-vehical-card-inner  background-img"
                                        }
                                      >
                                        <div className="track-qustion">
                                          <img src={Qustiontracking} alt="" />
                                        </div>
                                        <div className="vehical-card-head ">
                                          <div className="heading">
                                            <img
                                              src={Grouplogo}
                                              alt=""
                                              className="custom-Margin "
                                            />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Group Name")}
                                              </p>
                                              <p className="title">Ambani</p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              {/* <Dropdown.Toggle id="dropdown-basic"> */}
                                              <img src={option} alt="" />
                                              {/* </Dropdown.Toggle> */}

                                              {/* <Dropdown.Menu>
                                                                        <Dropdown.Item  >View</Dropdown.Item>
                                                                        <Dropdown.Item   >Edit</Dropdown.Item>
                                                                        <Dropdown.Item   >{t("Geofence")}</Dropdown.Item>
                                                                        <Dropdown.Item   onClick={handleShow} >Delete </Dropdown.Item>
                                                                    </Dropdown.Menu> */}
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Manager")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Created At")}
                                            </p>
                                            <p className="title">20-12-2022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Drivers")}
                                            </p>
                                            <p className="title">60</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicles")}
                                            </p>
                                            <p className="title">5</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">
                                              Passenger & Goods
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={
                                          "common-vehical-card-inner  background-img"
                                        }
                                      >
                                        <div className="track-qustion">
                                          <img src={Qustiontracking} alt="" />
                                        </div>
                                        <div className="vehical-card-head ">
                                          <div className="heading">
                                            <img
                                              src={Grouplogo}
                                              alt=""
                                              className="custom-Margin "
                                            />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Group Name")}
                                              </p>
                                              <p className="title">Ambani</p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              {/* <Dropdown.Toggle id="dropdown-basic"> */}
                                              <img src={option} alt="" />
                                              {/* </Dropdown.Toggle> */}

                                              {/* <Dropdown.Menu>
                                                                        <Dropdown.Item  >View</Dropdown.Item>
                                                                        <Dropdown.Item   >Edit</Dropdown.Item>
                                                                        <Dropdown.Item   >{t("Geofence")}</Dropdown.Item>
                                                                        <Dropdown.Item   onClick={handleShow} >Delete </Dropdown.Item>
                                                                    </Dropdown.Menu> */}
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Manager")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Created At")}
                                            </p>
                                            <p className="title">20-12-2022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Drivers")}
                                            </p>
                                            <p className="title">60</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicles")}
                                            </p>
                                            <p className="title">5</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">
                                              Passenger & Goods
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={
                                          "common-vehical-card-inner  background-img"
                                        }
                                      >
                                        <div className="track-qustion">
                                          <img src={Qustiontracking} alt="" />
                                        </div>
                                        <div className="vehical-card-head ">
                                          <div className="heading">
                                            <img
                                              src={Grouplogo}
                                              alt=""
                                              className="custom-Margin "
                                            />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Group Name")}
                                              </p>
                                              <p className="title">Ambani</p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              {/* <Dropdown.Toggle id="dropdown-basic"> */}
                                              <img src={option} alt="" />
                                              {/* </Dropdown.Toggle> */}

                                              {/* <Dropdown.Menu>
                                                                        <Dropdown.Item  >View</Dropdown.Item>
                                                                        <Dropdown.Item   >Edit</Dropdown.Item>
                                                                        <Dropdown.Item   >{t("Geofence")}</Dropdown.Item>
                                                                        <Dropdown.Item   onClick={handleShow} >Delete </Dropdown.Item>
                                                                    </Dropdown.Menu> */}
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Manager")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Created At")}
                                            </p>
                                            <p className="title">20-12-2022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Drivers")}
                                            </p>
                                            <p className="title">60</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicles")}
                                            </p>
                                            <p className="title">5</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">
                                              Passenger & Goods
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={
                                          "common-vehical-card-inner  background-img"
                                        }
                                      >
                                        <div className="track-qustion">
                                          <img src={Qustiontracking} alt="" />
                                        </div>
                                        <div className="vehical-card-head ">
                                          <div className="heading">
                                            <img
                                              src={Grouplogo}
                                              alt=""
                                              className="custom-Margin "
                                            />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Group Name")}
                                              </p>
                                              <p className="title">Ambani</p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              {/* <Dropdown.Toggle id="dropdown-basic"> */}
                                              <img src={option} alt="" />
                                              {/* </Dropdown.Toggle> */}

                                              {/* <Dropdown.Menu>
                                                                        <Dropdown.Item  >View</Dropdown.Item>
                                                                        <Dropdown.Item   >Edit</Dropdown.Item>
                                                                        <Dropdown.Item   >{t("Geofence")}</Dropdown.Item>
                                                                        <Dropdown.Item   onClick={handleShow} >Delete </Dropdown.Item>
                                                                    </Dropdown.Menu> */}
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Manager")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Created At")}
                                            </p>
                                            <p className="title">20-12-2022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Drivers")}
                                            </p>
                                            <p className="title">60</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicles")}
                                            </p>
                                            <p className="title">5</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">
                                              Passenger & Goods
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={
                                          "common-vehical-card-inner  background-img"
                                        }
                                      >
                                        <div className="track-qustion">
                                          <img src={Qustiontracking} alt="" />
                                        </div>
                                        <div className="vehical-card-head ">
                                          <div className="heading">
                                            <img
                                              src={Grouplogo}
                                              alt=""
                                              className="custom-Margin "
                                            />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Group Name")}
                                              </p>
                                              <p className="title">Ambani</p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              {/* <Dropdown.Toggle id="dropdown-basic"> */}
                                              <img src={option} alt="" />
                                              {/* </Dropdown.Toggle> */}

                                              {/* <Dropdown.Menu>
                                                                        <Dropdown.Item  >View</Dropdown.Item>
                                                                        <Dropdown.Item   >Edit</Dropdown.Item>
                                                                        <Dropdown.Item   >{t("Geofence")}</Dropdown.Item>
                                                                        <Dropdown.Item   onClick={handleShow} >Delete </Dropdown.Item>
                                                                    </Dropdown.Menu> */}
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Manager")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Group Created At")}
                                            </p>
                                            <p className="title">20-12-2022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Drivers")}
                                            </p>
                                            <p className="title">60</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicles")}
                                            </p>
                                            <p className="title">5</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">
                                              Passenger & Goods
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tab.Pane>
                          </Tab.Content>
                        </Col>
                      </Tab.Container>
                    ) : (
                      <Tab.Container
                        id="left-tabs-example"
                        className="td-tab-wrapper"
                        defaultActiveKey="0"
                      >
                        <Nav
                          variant="pills"
                          className="td-nav"
                          id="InnerTabNew_Five"
                        >
                          <Nav.Item className="td-tab">
                            <Nav.Link className="td-link" eventKey="0">
                              {t("All")}
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item className="td-tab">
                            <Nav.Link className="td-link" eventKey="1">
                              {t("Online")}
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item className="td-tab">
                            <Nav.Link className="td-link" eventKey="2">
                              {t("Offline")}
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item className="td-tab">
                            <Nav.Link className="td-link" eventKey="3">
                              {t("Untracked")}
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item className="td-tab">
                            <Nav.Link className="td-link" eventKey="4">
                              {t("Geofence")}
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>

                        <Col sm={12} className="">
                          <Tab.Content>
                            <Tab.Pane eventKey="0">
                              <div className="all-vehicle-main">
                                <div className="all-vehical-head row vehicle-top-inputs">
                                  <div className="input-section-wrapper">
                                    <div className="row">
                                      <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Vehicle Name,No., Reg. No, IMEI..."
                                        />
                                      </div>
                                      <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Vehicle Category"
                                        />
                                      </div>
                                      <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Vehicle Capacity"
                                        />
                                      </div>
                                      <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                                        <select
                                          required
                                          className="form-select"
                                          aria-label="Default select example"
                                          placeholder="Transportation Type"
                                        >
                                          <option value="">
                                            Transportation Type
                                          </option>
                                          <option value="1">One</option>
                                          <option value="2">Two</option>
                                          <option value="3">Three</option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="right-export-btn-section-wrapper">
                                    <div className="c-pointer me-2">
                                      <img src={Export} alt="" />
                                    </div>
                                    <div className="c-pointer">
                                      <img src={Import} alt="" />
                                    </div>
                                  </div>
                                  <div
                                    className="col-lg-12 showGroup"
                                    onClick={hideView}
                                  >
                                    {t("Showing Results for")} : Night shift
                                    vehicles
                                  </div>
                                </div>
                                <div className="yauto" id="viewCard_Group">
                                  <div className="row main-cards-wrapper gx-3">
                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={"common-vehical-card-inner"}
                                      >
                                        <div className="vehical-card-head">
                                          <div className="heading">
                                            <img src={Gcar} alt="" />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Vehicle Name")}
                                              </p>
                                              <p className="title">
                                                Utility CAB
                                              </p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              <Dropdown.Toggle id="dropdown-basic">
                                                <img src={option} alt="" />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                <Dropdown.Item>
                                                  <Link
                                                    to="#"
                                                    className="d-block"
                                                  >
                                                    {t("View")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                  <Link
                                                    to="#"
                                                    className="d-block"
                                                  >
                                                    {t("Edit")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={handleShow}
                                                >
                                                  <Link
                                                    to="#"
                                                    className="d-block"
                                                  >
                                                    {t("Delete")}
                                                  </Link>
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">MH-12-5022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Driver Name")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("IMEI No.")}
                                            </p>
                                            <p className="title">
                                              1234567894561230
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Type")}
                                            </p>
                                            <p className="title">Car</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">Passenger</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t(
                                                "Vehicle Capacity / Passenger"
                                              )}
                                            </p>
                                            <p className="title">60</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={"common-vehical-card-inner"}
                                      >
                                        <div className="vehical-card-head">
                                          <div className="heading">
                                            <img src={Gbike} alt="" />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Vehicle Name")}
                                              </p>
                                              <p className="title">
                                                Honda Bike
                                              </p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              <Dropdown.Toggle id="dropdown-basic">
                                                <img src={option} alt="" />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                <Dropdown.Item>
                                                  <Link to="#">
                                                    {t("View")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                  <Link to="#">
                                                    {t("Edit")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={handleShow}
                                                >
                                                  <Link to="#">
                                                    {t("Delete")}
                                                  </Link>
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">MH-12-5022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Driver Name")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("IMEI No.")}
                                            </p>
                                            <p className="title">
                                              1234567894561230
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Type")}
                                            </p>
                                            <p className="title">Bike</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">Passenger</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t(
                                                "Vehicle Capacity / Passenger"
                                              )}
                                            </p>
                                            <p className="title">1</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={"common-vehical-card-inner"}
                                      >
                                        <div className="vehical-card-head">
                                          <div className="heading">
                                            <img src={Gcar} alt="" />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Vehicle Name")}
                                              </p>
                                              <p className="title">
                                                Volvo Transport
                                              </p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              <Dropdown.Toggle id="dropdown-basic">
                                                <img src={option} alt="" />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                <Dropdown.Item>
                                                  <Link to="#">
                                                    {t("View")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                  <Link to="#">
                                                    {t("Edit")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={handleShow}
                                                >
                                                  <Link to="#">
                                                    {t("Delete")}
                                                  </Link>
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">MH-12-5022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Driver Name")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("IMEI No.")}
                                            </p>
                                            <p className="title">
                                              1234567894561230
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Type")}
                                            </p>
                                            <p className="title">
                                              Pick-Up Truck
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">Goods</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t(
                                                "Vehicle Capacity / Passenger"
                                              )}
                                            </p>
                                            <p className="title">1 Ton</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={"common-vehical-card-inner"}
                                      >
                                        <div className="vehical-card-head">
                                          <div className="heading">
                                            <img src={Bcar} alt="" />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Vehicle Name")}
                                              </p>
                                              <p className="title">
                                                Volvo Transport
                                              </p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              <Dropdown.Toggle id="dropdown-basic">
                                                <img src={option} alt="" />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                <Dropdown.Item>
                                                  <Link to="#">
                                                    {t("View")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                  <Link to="#">
                                                    {t("Edit")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={handleShow}
                                                >
                                                  <Link to="#">
                                                    {t("Delete")}
                                                  </Link>
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">MH-12-5022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Driver Name")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("IMEI No.")}
                                            </p>
                                            <p className="title">
                                              1234567894561230
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Type")}
                                            </p>
                                            <p className="title">
                                              Pick-Up Truck
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">Goods</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t(
                                                "Vehicle Capacity / Passenger"
                                              )}
                                            </p>
                                            <p className="title">1 Ton</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={"common-vehical-card-inner"}
                                      >
                                        <div className="vehical-card-head">
                                          <div className="heading">
                                            <img src={Bcar} alt="" />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Vehicle Name")}
                                              </p>
                                              <p className="title">
                                                Mercedes Bus{" "}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              <Dropdown.Toggle id="dropdown-basic">
                                                <img src={option} alt="" />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                <Dropdown.Item>
                                                  <Link to="#">
                                                    {t("View")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                  <Link to="#">
                                                    {t("Edit")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={handleShow}
                                                >
                                                  <Link to="#">
                                                    {t("Delete")}
                                                  </Link>
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">MH-12-5022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Driver Name")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("IMEI No.")}
                                            </p>
                                            <p className="title">
                                              1234567894561230
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Type")}
                                            </p>
                                            <p className="title">Vanity Van</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">Passenger</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t(
                                                "Vehicle Capacity / Passenger"
                                              )}
                                            </p>
                                            <p className="title">60</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={"common-vehical-card-inner"}
                                      >
                                        <div className="vehical-card-head">
                                          <div className="heading">
                                            <img src={Bcar} alt="" />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Vehicle Name")}
                                              </p>
                                              <p className="title">
                                                Volvo Transport
                                              </p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              <Dropdown.Toggle id="dropdown-basic">
                                                <img src={option} alt="" />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                <Dropdown.Item>
                                                  <Link to="#">
                                                    {t("View")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                  <Link to="#">
                                                    {t("Edit")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={handleShow}
                                                >
                                                  <Link to="#">
                                                    {t("Delete")}
                                                  </Link>
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">MH-12-5022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Driver Name")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("IMEI No.")}
                                            </p>
                                            <p className="title">
                                              1234567894561230
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Type")}
                                            </p>
                                            <p className="title">
                                              Pick-Up Truck
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">Goods</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t(
                                                "Vehicle Capacity / Passenger"
                                              )}
                                            </p>
                                            <p className="title">500 Kg</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={"common-vehical-card-inner"}
                                      >
                                        <div className="vehical-card-head">
                                          <div className="heading">
                                            <img src={Rcar} alt="" />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Vehicle Name")}
                                              </p>
                                              <p className="title">
                                                Volvo Transport
                                              </p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              <Dropdown.Toggle id="dropdown-basic">
                                                <img src={option} alt="" />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                <Dropdown.Item>
                                                  <Link to="#">
                                                    {t("View")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                  <Link to="#">
                                                    {t("Edit")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={handleShow}
                                                >
                                                  <Link to="#">
                                                    {t("Delete")}
                                                  </Link>
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">MH-12-5022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Driver Name")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("IMEI No.")}
                                            </p>
                                            <p className="title">
                                              1234567894561230
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Type")}
                                            </p>
                                            <p className="title">
                                              Pick-Up Truck
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">Goods</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t(
                                                "Vehicle Capacity / Passenger"
                                              )}
                                            </p>
                                            <p className="title">500 Kg</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={"common-vehical-card-inner"}
                                      >
                                        <div className="vehical-card-head">
                                          <div className="heading">
                                            <img src={BKcar} alt="" />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Vehicle Name")}
                                              </p>
                                              <p className="title">
                                                Mercedes Bus{" "}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              <Dropdown.Toggle id="dropdown-basic">
                                                <img src={option} alt="" />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                <Dropdown.Item>
                                                  <Link to="#">
                                                    {t("View")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                  <Link to="#">
                                                    {t("Edit")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={handleShow}
                                                >
                                                  <Link to="#">
                                                    {t("Delete")}
                                                  </Link>
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">MH-12-5022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Driver Name")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("IMEI No.")}
                                            </p>
                                            <p className="title">
                                              1234567894561230
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Type")}
                                            </p>
                                            <p className="title">Vanity Van</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">Passenger</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t(
                                                "Vehicle Capacity / Passenger"
                                              )}
                                            </p>
                                            <p className="title">60</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={"common-vehical-card-inner"}
                                      >
                                        <div className="vehical-card-head">
                                          <div className="heading">
                                            <img src={Rbike} alt="" />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Vehicle Name")}
                                              </p>
                                              <p className="title">
                                                Honda Bike
                                              </p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              <Dropdown.Toggle id="dropdown-basic">
                                                <img src={option} alt="" />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                <Dropdown.Item>
                                                  <Link to="#">
                                                    {t("View")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                  <Link to="#">
                                                    {t("Edit")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={handleShow}
                                                >
                                                  <Link to="#">
                                                    {t("Delete")}
                                                  </Link>
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">MH-12-5022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Driver Name")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("IMEI No.")}
                                            </p>
                                            <p className="title">
                                              1234567894561230
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Type")}
                                            </p>
                                            <p className="title">Bike</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">Passenger</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t(
                                                "Vehicle Capacity / Passenger"
                                              )}
                                            </p>
                                            <p className="title">1</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="1">
                              <div className="all-vehicle-main">
                                <div className="all-vehical-head row vehicle-top-inputs">
                                  <div className="input-section-wrapper">
                                    <div className="row">
                                      <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Vehicle Name,No., Reg. No, IMEI..."
                                        />
                                      </div>
                                      <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Vehicle Category"
                                        />
                                      </div>
                                      <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Vehicle Capacity"
                                        />
                                      </div>
                                      <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                                        <select
                                          required
                                          className="form-select"
                                          aria-label="Default select example"
                                          placeholder="Transportation Type"
                                        >
                                          <option value="">
                                            Transportation Type
                                          </option>
                                          <option value="1">One</option>
                                          <option value="2">Two</option>
                                          <option value="3">Three</option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="right-export-btn-section-wrapper">
                                    <div className="c-pointer me-2">
                                      <img src={Export} alt="" />
                                    </div>
                                    <div className="c-pointer">
                                      <img src={Import} alt="" />
                                    </div>
                                  </div>
                                </div>
                                <div className="yauto" id="arrange-paading">
                                  <div className="row main-cards-wrapper gx-3 ">
                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={"common-vehical-card-inner"}
                                      >
                                        <div className="vehical-card-head">
                                          <div className="heading">
                                            <img src={Gcar} alt="" />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Vehicle Name")}
                                              </p>
                                              <p className="title">
                                                Utility CAB
                                              </p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              <Dropdown.Toggle id="dropdown-basic">
                                                <img src={option} alt="" />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                <Dropdown.Item>
                                                  <Link to="#">
                                                    {t("View")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                  <Link to="#">
                                                    {t("Edit")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={handleShow}
                                                >
                                                  <Link to="#">
                                                    {t("Delete")}
                                                  </Link>
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">MH-12-5022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Driver Name")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("IMEI No.")}
                                            </p>
                                            <p className="title">
                                              1234567894561230
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Type")}
                                            </p>
                                            <p className="title">Car</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">Passenger</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t(
                                                "Vehicle Capacity / Passenger"
                                              )}
                                            </p>
                                            <p className="title">60</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={"common-vehical-card-inner"}
                                      >
                                        <div className="vehical-card-head">
                                          <div className="heading">
                                            <img src={Gbike} alt="" />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Vehicle Name")}
                                              </p>
                                              <p className="title">
                                                Honda Bike
                                              </p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              <Dropdown.Toggle id="dropdown-basic">
                                                <img src={option} alt="" />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                <Dropdown.Item>
                                                  <Link to="#">
                                                    {t("View")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                  <Link to="#">
                                                    {t("Edit")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={handleShow}
                                                >
                                                  <Link to="#">
                                                    {t("Delete")}
                                                  </Link>
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">MH-12-5022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Driver Name")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("IMEI No.")}
                                            </p>
                                            <p className="title">
                                              1234567894561230
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Type")}
                                            </p>
                                            <p className="title">Bike</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">Passenger</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t(
                                                "Vehicle Capacity / Passenger"
                                              )}
                                            </p>
                                            <p className="title">1</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={"common-vehical-card-inner"}
                                      >
                                        <div className="vehical-card-head">
                                          <div className="heading">
                                            <img src={Gcar} alt="" />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Vehicle Name")}
                                              </p>
                                              <p className="title">
                                                Volvo Transport
                                              </p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              <Dropdown.Toggle id="dropdown-basic">
                                                <img src={option} alt="" />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                <Dropdown.Item>
                                                  <Link to="#">
                                                    {t("View")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                  <Link to="#">
                                                    {t("Edit")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={handleShow}
                                                >
                                                  <Link to="#">
                                                    {t("Delete")}
                                                  </Link>
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">MH-12-5022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Driver Name")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("IMEI No.")}
                                            </p>
                                            <p className="title">
                                              1234567894561230
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Type")}
                                            </p>
                                            <p className="title">
                                              Pick-Up Truck
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">Goods</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t(
                                                "Vehicle Capacity / Passenger"
                                              )}
                                            </p>
                                            <p className="title">1 Ton</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={"common-vehical-card-inner"}
                                      >
                                        <div className="vehical-card-head">
                                          <div className="heading">
                                            <img src={Bcar} alt="" />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Vehicle Name")}
                                              </p>
                                              <p className="title">
                                                Volvo Transport
                                              </p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              <Dropdown.Toggle id="dropdown-basic">
                                                <img src={option} alt="" />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                <Dropdown.Item>
                                                  <Link to="#">
                                                    {t("View")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                  <Link to="#">
                                                    {t("Edit")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={handleShow}
                                                >
                                                  <Link to="#">
                                                    {t("Delete")}
                                                  </Link>
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">MH-12-5022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Driver Name")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("IMEI No.")}
                                            </p>
                                            <p className="title">
                                              1234567894561230
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Type")}
                                            </p>
                                            <p className="title">
                                              Pick-Up Truck
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">Goods</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t(
                                                "Vehicle Capacity / Passenger"
                                              )}
                                            </p>
                                            <p className="title">1 Ton</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={"common-vehical-card-inner"}
                                      >
                                        <div className="vehical-card-head">
                                          <div className="heading">
                                            <img src={Bcar} alt="" />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Vehicle Name")}
                                              </p>
                                              <p className="title">
                                                Mercedes Bus{" "}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              <Dropdown.Toggle id="dropdown-basic">
                                                <img src={option} alt="" />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                <Dropdown.Item>
                                                  <Link to="#">
                                                    {t("View")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                  <Link to="#">
                                                    {t("Edit")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={handleShow}
                                                >
                                                  <Link to="#">
                                                    {t("Delete")}
                                                  </Link>
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">MH-12-5022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Driver Name")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("IMEI No.")}
                                            </p>
                                            <p className="title">
                                              1234567894561230
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Type")}
                                            </p>
                                            <p className="title">Vanity Van</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">Passenger</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t(
                                                "Vehicle Capacity / Passenger"
                                              )}
                                            </p>
                                            <p className="title">60</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={"common-vehical-card-inner"}
                                      >
                                        <div className="vehical-card-head">
                                          <div className="heading">
                                            <img src={Bcar} alt="" />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Vehicle Name")}
                                              </p>
                                              <p className="title">
                                                Volvo Transport
                                              </p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              <Dropdown.Toggle id="dropdown-basic">
                                                <img src={option} alt="" />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                <Dropdown.Item>
                                                  <Link to="#">
                                                    {t("View")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                  <Link to="#">
                                                    {t("Edit")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={handleShow}
                                                >
                                                  <Link to="#">
                                                    {t("Delete")}
                                                  </Link>
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">MH-12-5022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Driver Name")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("IMEI No.")}
                                            </p>
                                            <p className="title">
                                              1234567894561230
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Type")}
                                            </p>
                                            <p className="title">
                                              Pick-Up Truck
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">Goods</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t(
                                                "Vehicle Capacity / Passenger"
                                              )}
                                            </p>
                                            <p className="title">500 Kg</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={"common-vehical-card-inner"}
                                      >
                                        <div className="vehical-card-head">
                                          <div className="heading">
                                            <img src={Rcar} alt="" />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Vehicle Name")}
                                              </p>
                                              <p className="title">
                                                Volvo Transport
                                              </p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              <Dropdown.Toggle id="dropdown-basic">
                                                <img src={option} alt="" />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                <Dropdown.Item>
                                                  <Link to="#">
                                                    {t("View")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                  <Link to="#">
                                                    {t("Edit")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={handleShow}
                                                >
                                                  <Link to="#">
                                                    {t("Delete")}
                                                  </Link>
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">MH-12-5022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Driver Name")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("IMEI No.")}
                                            </p>
                                            <p className="title">
                                              1234567894561230
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Type")}
                                            </p>
                                            <p className="title">
                                              Pick-Up Truck
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">Goods</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t(
                                                "Vehicle Capacity / Passenger"
                                              )}
                                            </p>
                                            <p className="title">500 Kg</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={"common-vehical-card-inner"}
                                      >
                                        <div className="vehical-card-head">
                                          <div className="heading">
                                            <img src={BKcar} alt="" />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Vehicle Name")}
                                              </p>
                                              <p className="title">
                                                Mercedes Bus{" "}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              <Dropdown.Toggle id="dropdown-basic">
                                                <img src={option} alt="" />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                <Dropdown.Item>
                                                  <Link to="#">
                                                    {t("View")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                  <Link to="#">
                                                    {t("Edit")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={handleShow}
                                                >
                                                  <Link to="#">
                                                    {t("Delete")}
                                                  </Link>
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">MH-12-5022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Driver Name")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("IMEI No.")}
                                            </p>
                                            <p className="title">
                                              1234567894561230
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Type")}
                                            </p>
                                            <p className="title">Vanity Van</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">Passenger</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t(
                                                "Vehicle Capacity / Passenger"
                                              )}
                                            </p>
                                            <p className="title">60</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={"common-vehical-card-inner"}
                                      >
                                        <div className="vehical-card-head">
                                          <div className="heading">
                                            <img src={Rbike} alt="" />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Vehicle Name")}
                                              </p>
                                              <p className="title">
                                                Honda Bike
                                              </p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              <Dropdown.Toggle id="dropdown-basic">
                                                <img src={option} alt="" />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                <Dropdown.Item>
                                                  <Link to="#">
                                                    {t("View")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                  <Link to="#">
                                                    {t("Edit")}
                                                  </Link>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={handleShow}
                                                >
                                                  <Link to="#">
                                                    {t("Delete")}
                                                  </Link>
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">MH-12-5022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Driver Name")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("IMEI No.")}
                                            </p>
                                            <p className="title">
                                              1234567894561230
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Type")}
                                            </p>
                                            <p className="title">Bike</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">Passenger</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t(
                                                "Vehicle Capacity / Passenger"
                                              )}
                                            </p>
                                            <p className="title">1</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="2">
                              <div className="all-vehicle-main">
                                <div className="all-vehical-head row vehicle-top-inputs">
                                  <div className="input-section-wrapper">
                                    <div className="row">
                                      <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Vehicle Name,No., Reg. No, IMEI..."
                                        />
                                      </div>
                                      <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Vehicle Category"
                                        />
                                      </div>
                                      <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Vehicle Capacity"
                                        />
                                      </div>
                                      <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                                        <select
                                          required
                                          className="form-select"
                                          aria-label="Default select example"
                                          placeholder="Transportation Type"
                                        >
                                          <option value="">
                                            Transportation Type
                                          </option>
                                          <option value="1">One</option>
                                          <option value="2">Two</option>
                                          <option value="3">Three</option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="right-export-btn-section-wrapper">
                                    <div className="c-pointer me-2">
                                      <img src={Export} alt="" />
                                    </div>
                                    <div className="c-pointer">
                                      <img src={Import} alt="" />
                                    </div>
                                  </div>
                                </div>
                                <div className="yauto" id="arrange-paading">
                                  <div className="row main-cards-wrapper gx-3">
                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={"common-vehical-card-inner"}
                                      >
                                        <div className="vehical-card-head">
                                          <div className="heading">
                                            <img src={GRcar} alt="" />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Vehicle Name")}
                                              </p>
                                              <p className="title">
                                                Utility CAB
                                              </p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              <Dropdown.Toggle id="dropdown-basic">
                                                <img src={option} alt="" />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                <Dropdown.Item>
                                                  {t("View")}
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={handleShow}
                                                >
                                                  {t("Delete")}{" "}
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">MH-12-5022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Driver Name")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("IMEI No.")}
                                            </p>
                                            <p className="title">
                                              1234567894561230
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Type")}
                                            </p>
                                            <p className="title">Car</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">Passenger</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t(
                                                "Vehicle Capacity / Passenger"
                                              )}
                                            </p>
                                            <p className="title">60</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={"common-vehical-card-inner"}
                                      >
                                        <div className="vehical-card-head">
                                          <div className="heading">
                                            <img src={GRbike} alt="" />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Vehicle Name")}
                                              </p>
                                              <p className="title">
                                                Honda Bike
                                              </p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              <Dropdown.Toggle id="dropdown-basic">
                                                <img src={option} alt="" />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                <Dropdown.Item>
                                                  {t("View")}
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={handleShow}
                                                >
                                                  {t("Delete")}{" "}
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">MH-12-5022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Driver Name")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("IMEI No.")}
                                            </p>
                                            <p className="title">
                                              1234567894561230
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Type")}
                                            </p>
                                            <p className="title">Bike</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">Passenger</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t(
                                                "Vehicle Capacity / Passenger"
                                              )}
                                            </p>
                                            <p className="title">1</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={"common-vehical-card-inner"}
                                      >
                                        <div className="vehical-card-head">
                                          <div className="heading">
                                            <img src={GRcar} alt="" />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Vehicle Name")}
                                              </p>
                                              <p className="title">
                                                Volvo Transport
                                              </p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              <Dropdown.Toggle id="dropdown-basic">
                                                <img src={option} alt="" />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                <Dropdown.Item>
                                                  {t("View")}
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={handleShow}
                                                >
                                                  {t("Delete")}{" "}
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">MH-12-5022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Driver Name")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("IMEI No.")}
                                            </p>
                                            <p className="title">
                                              1234567894561230
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Type")}
                                            </p>
                                            <p className="title">
                                              Pick-Up Truck
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">Goods</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t(
                                                "Vehicle Capacity / Passenger"
                                              )}
                                            </p>
                                            <p className="title">1 Ton</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={"common-vehical-card-inner"}
                                      >
                                        <div className="vehical-card-head">
                                          <div className="heading">
                                            <img src={GRcar} alt="" />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Vehicle Name")}
                                              </p>
                                              <p className="title">
                                                Volvo Transport
                                              </p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              <Dropdown.Toggle id="dropdown-basic">
                                                <img src={option} alt="" />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                <Dropdown.Item>
                                                  {t("View")}
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={handleShow}
                                                >
                                                  {t("Delete")}{" "}
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">MH-12-5022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Driver Name")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("IMEI No.")}
                                            </p>
                                            <p className="title">
                                              1234567894561230
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Type")}
                                            </p>
                                            <p className="title">
                                              Pick-Up Truck
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">Goods</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t(
                                                "Vehicle Capacity / Passenger"
                                              )}
                                            </p>
                                            <p className="title">1 Ton</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={"common-vehical-card-inner"}
                                      >
                                        <div className="vehical-card-head">
                                          <div className="heading">
                                            <img src={GRcar} alt="" />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Vehicle Name")}
                                              </p>
                                              <p className="title">
                                                Mercedes Bus{" "}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              <Dropdown.Toggle id="dropdown-basic">
                                                <img src={option} alt="" />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                <Dropdown.Item>
                                                  {t("View")}
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={handleShow}
                                                >
                                                  {t("Delete")}{" "}
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">MH-12-5022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Driver Name")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("IMEI No.")}
                                            </p>
                                            <p className="title">
                                              1234567894561230
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Type")}
                                            </p>
                                            <p className="title">Vanity Van</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">Passenger</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t(
                                                "Vehicle Capacity / Passenger"
                                              )}
                                            </p>
                                            <p className="title">60</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={"common-vehical-card-inner"}
                                      >
                                        <div className="vehical-card-head">
                                          <div className="heading">
                                            <img src={GRcar} alt="" />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Vehicle Name")}
                                              </p>
                                              <p className="title">
                                                Volvo Transport
                                              </p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              <Dropdown.Toggle id="dropdown-basic">
                                                <img src={option} alt="" />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                <Dropdown.Item>
                                                  {t("View")}
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={handleShow}
                                                >
                                                  {t("Delete")}{" "}
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">MH-12-5022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Driver Name")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("IMEI No.")}
                                            </p>
                                            <p className="title">
                                              1234567894561230
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Type")}
                                            </p>
                                            <p className="title">
                                              Pick-Up Truck
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">Goods</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t(
                                                "Vehicle Capacity / Passenger"
                                              )}
                                            </p>
                                            <p className="title">500 Kg</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={"common-vehical-card-inner"}
                                      >
                                        <div className="vehical-card-head">
                                          <div className="heading">
                                            <img src={GRcar} alt="" />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Vehicle Name")}
                                              </p>
                                              <p className="title">
                                                Volvo Transport
                                              </p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              <Dropdown.Toggle id="dropdown-basic">
                                                <img src={option} alt="" />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                <Dropdown.Item>
                                                  {t("View")}
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={handleShow}
                                                >
                                                  {t("Delete")}{" "}
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">MH-12-5022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Driver Name")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("IMEI No.")}
                                            </p>
                                            <p className="title">
                                              1234567894561230
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Type")}
                                            </p>
                                            <p className="title">
                                              Pick-Up Truck
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">Goods</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t(
                                                "Vehicle Capacity / Passenger"
                                              )}
                                            </p>
                                            <p className="title">500 Kg</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={"common-vehical-card-inner"}
                                      >
                                        <div className="vehical-card-head">
                                          <div className="heading">
                                            <img src={GRcar} alt="" />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Vehicle Name")}
                                              </p>
                                              <p className="title">
                                                Mercedes Bus{" "}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              <Dropdown.Toggle id="dropdown-basic">
                                                <img src={option} alt="" />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                <Dropdown.Item>
                                                  {t("View")}
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={handleShow}
                                                >
                                                  {t("Delete")}{" "}
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">MH-12-5022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Driver Name")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("IMEI No.")}
                                            </p>
                                            <p className="title">
                                              1234567894561230
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Type")}
                                            </p>
                                            <p className="title">Vanity Van</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">Passenger</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t(
                                                "Vehicle Capacity / Passenger"
                                              )}
                                            </p>
                                            <p className="title">60</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={"common-vehical-card-inner"}
                                      >
                                        <div className="vehical-card-head">
                                          <div className="heading">
                                            <img src={GRbike} alt="" />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Vehicle Name")}
                                              </p>
                                              <p className="title">
                                                Honda Bike
                                              </p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              <Dropdown.Toggle id="dropdown-basic">
                                                <img src={option} alt="" />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                <Dropdown.Item>
                                                  {t("View")}
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={handleShow}
                                                >
                                                  {t("Delete")}{" "}
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">MH-12-5022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Driver Name")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("IMEI No.")}
                                            </p>
                                            <p className="title">
                                              1234567894561230
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Type")}
                                            </p>
                                            <p className="title">Bike</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">Passenger</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t(
                                                "Vehicle Capacity / Passenger"
                                              )}
                                            </p>
                                            <p className="title">1</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="3">
                              <div className="all-vehicle-main">
                                <div className="all-vehical-head row vehicle-top-inputs">
                                  <div className="input-section-wrapper">
                                    <div className="row">
                                      <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Vehicle Name,No., Reg. No, IMEI..."
                                        />
                                      </div>
                                      <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Vehicle Category"
                                        />
                                      </div>
                                      <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Vehicle Capacity"
                                        />
                                      </div>
                                      <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                                        <select
                                          required
                                          className="form-select"
                                          aria-label="Default select example"
                                          placeholder="Transportation Type"
                                        >
                                          <option value="">
                                            Transportation Type
                                          </option>
                                          <option value="1">One</option>
                                          <option value="2">Two</option>
                                          <option value="3">Three</option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="right-export-btn-section-wrapper">
                                    <div className="c-pointer me-2">
                                      <img src={Export} alt="" />
                                    </div>
                                    <div className="c-pointer">
                                      <img src={Import} alt="" />
                                    </div>
                                  </div>
                                </div>
                                <div className="yauto" id="arrange-paading">
                                  <div className="row main-cards-wrapper gx-3">
                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={"common-vehical-card-inner"}
                                      >
                                        <div className="vehical-card-head">
                                          <div className="heading">
                                            <img src={YQcar} alt="" />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Vehicle Name")}
                                              </p>
                                              <p className="title">
                                                Utility CAB
                                              </p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              <Dropdown.Toggle id="dropdown-basic">
                                                <img src={option} alt="" />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                <Dropdown.Item>
                                                  {t("View")}
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={handleShow}
                                                >
                                                  {t("Delete")}{" "}
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">MH-12-5022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Driver Name")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("IMEI No.")}
                                            </p>
                                            <p className="title">
                                              1234567894561230
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Type")}
                                            </p>
                                            <p className="title">Car</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">Passenger</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t(
                                                "Vehicle Capacity / Passenger"
                                              )}
                                            </p>
                                            <p className="title">60</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={"common-vehical-card-inner"}
                                      >
                                        <div className="vehical-card-head">
                                          <div className="heading">
                                            <img src={Ybike} alt="" />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Vehicle Name")}
                                              </p>
                                              <p className="title">
                                                Honda Bike
                                              </p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              <Dropdown.Toggle id="dropdown-basic">
                                                <img src={option} alt="" />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                <Dropdown.Item>
                                                  {t("View")}
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={handleShow}
                                                >
                                                  {t("Delete")}{" "}
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">MH-12-5022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Driver Name")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("IMEI No.")}
                                            </p>
                                            <p className="title">
                                              1234567894561230
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Type")}
                                            </p>
                                            <p className="title">Bike</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">Passenger</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t(
                                                "Vehicle Capacity / Passenger"
                                              )}
                                            </p>
                                            <p className="title">1</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={"common-vehical-card-inner"}
                                      >
                                        <div className="vehical-card-head">
                                          <div className="heading">
                                            <img src={Ycar} alt="" />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Vehicle Name")}
                                              </p>
                                              <p className="title">
                                                Volvo Transport
                                              </p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              <Dropdown.Toggle id="dropdown-basic">
                                                <img src={option} alt="" />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                <Dropdown.Item>
                                                  {t("View")}
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={handleShow}
                                                >
                                                  {t("Delete")}{" "}
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">MH-12-5022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Driver Name")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("IMEI No.")}
                                            </p>
                                            <p className="title">
                                              1234567894561230
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Type")}
                                            </p>
                                            <p className="title">
                                              Pick-Up Truck
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">Goods</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t(
                                                "Vehicle Capacity / Passenger"
                                              )}
                                            </p>
                                            <p className="title">1 Ton</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={"common-vehical-card-inner"}
                                      >
                                        <div className="vehical-card-head">
                                          <div className="heading">
                                            <img src={Ycar} alt="" />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Vehicle Name")}
                                              </p>
                                              <p className="title">
                                                Volvo Transport
                                              </p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              <Dropdown.Toggle id="dropdown-basic">
                                                <img src={option} alt="" />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                <Dropdown.Item>
                                                  {t("View")}
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={handleShow}
                                                >
                                                  {t("Delete")}{" "}
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">MH-12-5022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Driver Name")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("IMEI No.")}
                                            </p>
                                            <p className="title">
                                              1234567894561230
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Type")}
                                            </p>
                                            <p className="title">
                                              Pick-Up Truck
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">Goods</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t(
                                                "Vehicle Capacity / Passenger"
                                              )}
                                            </p>
                                            <p className="title">1 Ton</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={"common-vehical-card-inner"}
                                      >
                                        <div className="vehical-card-head">
                                          <div className="heading">
                                            <img src={YQcar} alt="" />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Vehicle Name")}
                                              </p>
                                              <p className="title">
                                                Mercedes Bus{" "}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              <Dropdown.Toggle id="dropdown-basic">
                                                <img src={option} alt="" />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                <Dropdown.Item>
                                                  {t("View")}
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={handleShow}
                                                >
                                                  {t("Delete")}{" "}
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">MH-12-5022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Driver Name")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("IMEI No.")}
                                            </p>
                                            <p className="title">
                                              1234567894561230
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Type")}
                                            </p>
                                            <p className="title">Vanity Van</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">Passenger</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t(
                                                "Vehicle Capacity / Passenger"
                                              )}
                                            </p>
                                            <p className="title">60</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={"common-vehical-card-inner"}
                                      >
                                        <div className="vehical-card-head">
                                          <div className="heading">
                                            <img src={Ycar} alt="" />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Vehicle Name")}
                                              </p>
                                              <p className="title">
                                                Volvo Transport
                                              </p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              <Dropdown.Toggle id="dropdown-basic">
                                                <img src={option} alt="" />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                <Dropdown.Item>
                                                  {t("View")}
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={handleShow}
                                                >
                                                  {t("Delete")}{" "}
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">MH-12-5022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Driver Name")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("IMEI No.")}
                                            </p>
                                            <p className="title">
                                              1234567894561230
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Type")}
                                            </p>
                                            <p className="title">
                                              Pick-Up Truck
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">Goods</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t(
                                                "Vehicle Capacity / Passenger"
                                              )}
                                            </p>
                                            <p className="title">500 Kg</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={"common-vehical-card-inner"}
                                      >
                                        <div className="vehical-card-head">
                                          <div className="heading">
                                            <img src={Ycar} alt="" />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Vehicle Name")}
                                              </p>
                                              <p className="title">
                                                Volvo Transport
                                              </p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              <Dropdown.Toggle id="dropdown-basic">
                                                <img src={option} alt="" />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                <Dropdown.Item>
                                                  {t("View")}
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={handleShow}
                                                >
                                                  {t("Delete")}{" "}
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">MH-12-5022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Driver Name")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("IMEI No.")}
                                            </p>
                                            <p className="title">
                                              1234567894561230
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Type")}
                                            </p>
                                            <p className="title">
                                              Pick-Up Truck
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">Goods</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t(
                                                "Vehicle Capacity / Passenger"
                                              )}
                                            </p>
                                            <p className="title">500 Kg</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={"common-vehical-card-inner"}
                                      >
                                        <div className="vehical-card-head">
                                          <div className="heading">
                                            <img src={Ycar} alt="" />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Vehicle Name")}
                                              </p>
                                              <p className="title">
                                                Mercedes Bus{" "}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              <Dropdown.Toggle id="dropdown-basic">
                                                <img src={option} alt="" />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                <Dropdown.Item>
                                                  {t("View")}
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={handleShow}
                                                >
                                                  {t("Delete")}{" "}
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">MH-12-5022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Driver Name")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("IMEI No.")}
                                            </p>
                                            <p className="title">
                                              1234567894561230
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Type")}
                                            </p>
                                            <p className="title">Vanity Van</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">Passenger</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t(
                                                "Vehicle Capacity / Passenger"
                                              )}
                                            </p>
                                            <p className="title">60</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        sidebar
                                          ? "col-xl-4 col-lg-6 col-md-6"
                                          : "col-xl-3 col-lg-4 col-md-6"
                                      }
                                    >
                                      <div
                                        className={"common-vehical-card-inner"}
                                      >
                                        <div className="vehical-card-head">
                                          <div className="heading">
                                            <img src={YQbike} alt="" />
                                            <div className="">
                                              <p className="sub-heading">
                                                {t("Vehicle Name")}
                                              </p>
                                              <p className="title">
                                                Honda Bike
                                              </p>
                                            </div>
                                          </div>
                                          <div className="option customer-option">
                                            <Dropdown>
                                              <Dropdown.Toggle id="dropdown-basic">
                                                <img src={option} alt="" />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                <Dropdown.Item>
                                                  {t("View")}
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={handleShow}
                                                >
                                                  {t("Delete")}{" "}
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="vehical-card-body row">
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">MH-12-5022</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Driver Name")}
                                            </p>
                                            <p className="title">
                                              Mr. John Doe
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("IMEI No.")}
                                            </p>
                                            <p className="title">
                                              1234567894561230
                                            </p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Vehicle Type")}
                                            </p>
                                            <p className="title">Bike</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t("Transportation Type")}
                                            </p>
                                            <p className="title">Passenger</p>
                                          </div>
                                          <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                            <p className="sub-heading">
                                              {t(
                                                "Vehicle Capacity / Passenger"
                                              )}
                                            </p>
                                            <p className="title">1</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="4">
                              <div className="all-vehicle-main">
                                <div className="all-vehical-head row vehicle-top-inputs">
                                  <div className="input-section-wrapper">
                                    <div className="row">
                                      <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Vehicle Name,No., Reg. No, IMEI..."
                                        />
                                      </div>
                                      <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Vehicle Category"
                                        />
                                      </div>
                                      <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Vehicle Capacity"
                                        />
                                      </div>
                                      <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                                        <select
                                          required
                                          className="form-select"
                                          aria-label="Default select example"
                                          placeholder="Transportation Type"
                                        >
                                          <option value="">
                                            Transportation Type
                                          </option>
                                          <option value="1">One</option>
                                          <option value="2">Two</option>
                                          <option value="3">Three</option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="right-export-btn-section-wrapper">
                                    <div className="c-pointer me-2">
                                      <img src={Export} alt="" />
                                    </div>
                                    <div className="c-pointer">
                                      <img src={Import} alt="" />
                                    </div>
                                  </div>
                                </div>
                                <div className="yauto" id="arrange-paading">
                                  <div className="row main-cards-wrapper gx-3">
                                    <div className="main-road-map">
                                      <MapComponent />
                                      <div class="shape"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tab.Pane>
                          </Tab.Content>
                        </Col>
                      </Tab.Container>
                    )}
                  </div>
                  <p className="reg-color mt-3">{t("Showing")} 1 - 10 of 200</p>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="four">
                <Link to="/NewSyncRequest">
                  <button className="cx-btn-1 tbBtn">
                    + {t("New Sync Request")}
                  </button>
                </Link>
                <div className="main-master-wrapper mb-0 inner-tabs-section overflow-hidden">
                  <div id="scroll_insideThe_Padding">
                    <Tab.Container
                      id="left-tabs-example"
                      className="td-tab-wrapper"
                      defaultActiveKey="0"
                    >
                      <Nav
                        variant="pills"
                        className="td-nav"
                        id="InnerTabNew_Foure"
                      >
                        <Nav.Item className="td-tab">
                          <Nav.Link className="td-link" eventKey="0">
                            {t("All")}
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="td-tab">
                          <Nav.Link className="td-link" eventKey="1">
                            {t("Online")}
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="td-tab">
                          <Nav.Link className="td-link" eventKey="2">
                            {t("Offline")}
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="td-tab">
                          <Nav.Link className="td-link" eventKey="3">
                            {t("Untracked/Not Tracked Yet")}
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <Col sm={12} className="">
                        <Tab.Content>
                          <Tab.Pane eventKey="0">
                            <div className="all-vehicle-main">
                              <div className="all-vehical-head row vehicle-top-inputs">
                                <div className="input-section-wrapper">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Vehicle Name,No., Reg. No, IMEI..."
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Sync Request Date & Time"
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Sync Period"
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Sync Request Status"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="right-export-btn-section-wrapper">
                                  <div className="c-pointer">
                                    <img src={Import} alt="" />
                                  </div>
                                </div>
                              </div>
                              <div className="yauto" id="arrange-paading">
                                <div className="row main-cards-wrapper gx-3">
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Gcar} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}
                                            </p>
                                            <p className="title">Utility CAB</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Date")}
                                          </p>
                                          <p className="title">12-02-2023</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Time")}
                                          </p>
                                          <p className="title">04:04:53 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Period")}
                                          </p>
                                          <p className="title">Date Range</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Status")}
                                          </p>
                                          <p className="title">Pending</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Start Date")}
                                          </p>
                                          <p className="title">12-02-2023</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync End Date")}
                                          </p>
                                          <p className="title">18-02-2023</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Gbike} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}
                                            </p>
                                            <p className="title">Honda Bike</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Date")}
                                          </p>
                                          <p className="title">12-02-2023</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Time")}
                                          </p>
                                          <p className="title">04:04:53 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Period")}
                                          </p>
                                          <p className="title">Date Range</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Status")}
                                          </p>
                                          <p className="title">Pending</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Start Date")}
                                          </p>
                                          <p className="title">12-02-2023</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync End Date")}
                                          </p>
                                          <p className="title">18-02-2023</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Gcar} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Date")}
                                          </p>
                                          <p className="title">12-02-2023</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Time")}
                                          </p>
                                          <p className="title">04:04:53 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Period")}
                                          </p>
                                          <p className="title">Date Range</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Status")}
                                          </p>
                                          <p className="title">Pending</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Start Date")}
                                          </p>
                                          <p className="title">12-02-2023</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync End Date")}
                                          </p>
                                          <p className="title">18-02-2023</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Bcar} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Date")}
                                          </p>
                                          <p className="title">12-02-2023</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Time")}
                                          </p>
                                          <p className="title">04:04:53 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Period")}
                                          </p>
                                          <p className="title">Date Range</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Status")}
                                          </p>
                                          <p className="title">Pending</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Start Date")}
                                          </p>
                                          <p className="title">12-02-2023</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync End Date")}
                                          </p>
                                          <p className="title">18-02-2023</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Bcar} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}
                                            </p>
                                            <p className="title">
                                              Mercedes Bus{" "}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Date")}
                                          </p>
                                          <p className="title">12-02-2023</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Time")}
                                          </p>
                                          <p className="title">04:04:53 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Period")}
                                          </p>
                                          <p className="title">Date Range</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Status")}
                                          </p>
                                          <p className="title">Pending</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Start Date")}
                                          </p>
                                          <p className="title">12-02-2023</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync End Date")}
                                          </p>
                                          <p className="title">18-02-2023</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Bcar} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Date")}
                                          </p>
                                          <p className="title">12-02-2023</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Time")}
                                          </p>
                                          <p className="title">04:04:53 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Period")}
                                          </p>
                                          <p className="title">Date Range</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Status")}
                                          </p>
                                          <p className="title">Pending</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Start Date")}
                                          </p>
                                          <p className="title">12-02-2023</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync End Date")}
                                          </p>
                                          <p className="title">18-02-2023</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Rcar} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Date")}
                                          </p>
                                          <p className="title">12-02-2023</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Time")}
                                          </p>
                                          <p className="title">04:04:53 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Period")}
                                          </p>
                                          <p className="title">Date Range</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Status")}
                                          </p>
                                          <p className="title">Pending</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Start Date")}
                                          </p>
                                          <p className="title">12-02-2023</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync End Date")}
                                          </p>
                                          <p className="title">18-02-2023</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={BKcar} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}
                                            </p>
                                            <p className="title">
                                              Mercedes Bus{" "}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Date")}
                                          </p>
                                          <p className="title">12-02-2023</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Time")}
                                          </p>
                                          <p className="title">04:04:53 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Period")}
                                          </p>
                                          <p className="title">Date Range</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Status")}
                                          </p>
                                          <p className="title">Pending</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Start Date")}
                                          </p>
                                          <p className="title">12-02-2023</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync End Date")}
                                          </p>
                                          <p className="title">18-02-2023</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Rbike} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}
                                            </p>
                                            <p className="title">Honda Bike</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Date")}
                                          </p>
                                          <p className="title">12-02-2023</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Time")}
                                          </p>
                                          <p className="title">04:04:53 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Period")}
                                          </p>
                                          <p className="title">Date Range</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Status")}
                                          </p>
                                          <p className="title">Pending</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Start Date")}
                                          </p>
                                          <p className="title">12-02-2023</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync End Date")}
                                          </p>
                                          <p className="title">18-02-2023</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="1">
                            <div className="all-vehicle-main">
                              <div className="all-vehical-head row vehicle-top-inputs">
                                <div className="input-section-wrapper">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Vehicle Name,No., Reg. No, IMEI..."
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Sync Request Date & Time"
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Sync Period"
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Sync Request Status"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="right-export-btn-section-wrapper">
                                  <div className="c-pointer">
                                    <img src={Import} alt="" />
                                  </div>
                                </div>
                              </div>
                              <div className="yauto" id="arrange-paading">
                                <div className="row main-cards-wrapper gx-3">
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Gcar} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}
                                            </p>
                                            <p className="title">Utility CAB</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Date")}
                                          </p>
                                          <p className="title">12-02-2023</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Time")}
                                          </p>
                                          <p className="title">04:04:53 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Period")}
                                          </p>
                                          <p className="title">Date Range</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Status")}
                                          </p>
                                          <p className="title">Pending</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Start Date")}
                                          </p>
                                          <p className="title">12-02-2023</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync End Date")}
                                          </p>
                                          <p className="title">18-02-2023</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Gbike} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}
                                            </p>
                                            <p className="title">Honda Bike</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Date")}
                                          </p>
                                          <p className="title">12-02-2023</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Time")}
                                          </p>
                                          <p className="title">04:04:53 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Period")}
                                          </p>
                                          <p className="title">Date Range</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Status")}
                                          </p>
                                          <p className="title">Pending</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Start Date")}
                                          </p>
                                          <p className="title">12-02-2023</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync End Date")}
                                          </p>
                                          <p className="title">18-02-2023</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Gcar} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Date")}
                                          </p>
                                          <p className="title">12-02-2023</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Time")}
                                          </p>
                                          <p className="title">04:04:53 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Period")}
                                          </p>
                                          <p className="title">Date Range</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Status")}
                                          </p>
                                          <p className="title">Pending</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Start Date")}
                                          </p>
                                          <p className="title">12-02-2023</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync End Date")}
                                          </p>
                                          <p className="title">18-02-2023</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Bcar} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Date")}
                                          </p>
                                          <p className="title">12-02-2023</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Time")}
                                          </p>
                                          <p className="title">04:04:53 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Period")}
                                          </p>
                                          <p className="title">Date Range</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Status")}
                                          </p>
                                          <p className="title">Pending</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Start Date")}
                                          </p>
                                          <p className="title">12-02-2023</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync End Date")}
                                          </p>
                                          <p className="title">18-02-2023</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Bcar} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}
                                            </p>
                                            <p className="title">
                                              Mercedes Bus{" "}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Date")}
                                          </p>
                                          <p className="title">12-02-2023</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Time")}
                                          </p>
                                          <p className="title">04:04:53 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Period")}
                                          </p>
                                          <p className="title">Date Range</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Status")}
                                          </p>
                                          <p className="title">Pending</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Start Date")}
                                          </p>
                                          <p className="title">12-02-2023</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync End Date")}
                                          </p>
                                          <p className="title">18-02-2023</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Bcar} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Date")}
                                          </p>
                                          <p className="title">12-02-2023</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Time")}
                                          </p>
                                          <p className="title">04:04:53 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Period")}
                                          </p>
                                          <p className="title">Date Range</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Status")}
                                          </p>
                                          <p className="title">Pending</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Start Date")}
                                          </p>
                                          <p className="title">12-02-2023</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync End Date")}
                                          </p>
                                          <p className="title">18-02-2023</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Rcar} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Date")}
                                          </p>
                                          <p className="title">12-02-2023</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Time")}
                                          </p>
                                          <p className="title">04:04:53 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Period")}
                                          </p>
                                          <p className="title">Date Range</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Status")}
                                          </p>
                                          <p className="title">Pending</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Start Date")}
                                          </p>
                                          <p className="title">12-02-2023</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync End Date")}
                                          </p>
                                          <p className="title">18-02-2023</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={BKcar} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}
                                            </p>
                                            <p className="title">
                                              Mercedes Bus{" "}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Date")}
                                          </p>
                                          <p className="title">12-02-2023</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Time")}
                                          </p>
                                          <p className="title">04:04:53 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Period")}
                                          </p>
                                          <p className="title">Date Range</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Status")}
                                          </p>
                                          <p className="title">Pending</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Start Date")}
                                          </p>
                                          <p className="title">12-02-2023</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync End Date")}
                                          </p>
                                          <p className="title">18-02-2023</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Rbike} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}
                                            </p>
                                            <p className="title">Honda Bike</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Date")}
                                          </p>
                                          <p className="title">12-02-2023</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Time")}
                                          </p>
                                          <p className="title">04:04:53 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Period")}
                                          </p>
                                          <p className="title">Date Range</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Status")}
                                          </p>
                                          <p className="title">Pending</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Start Date")}
                                          </p>
                                          <p className="title">12-02-2023</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync End Date")}
                                          </p>
                                          <p className="title">18-02-2023</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="2">
                            <div className="all-vehicle-main">
                              <div className="all-vehical-head row vehicle-top-inputs">
                                <div className="input-section-wrapper">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Vehicle Name,No., Reg. No, IMEI..."
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Sync Request Date & Time"
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Sync Period"
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Sync Request Status"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="right-export-btn-section-wrapper">
                                  <div className="c-pointer">
                                    <img src={Import} alt="" />
                                  </div>
                                </div>
                              </div>
                              <div className="yauto" id="arrange-paading">
                                <div className="row main-cards-wrapper gx-3">
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={GRcar} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}
                                            </p>
                                            <p className="title">Utility CAB</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Date")}
                                          </p>
                                          <p className="title">12-02-2023</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Time")}
                                          </p>
                                          <p className="title">04:04:53 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Period")}
                                          </p>
                                          <p className="title">Date Range</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Status")}
                                          </p>
                                          <p className="title">Pending</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Start Date")}
                                          </p>
                                          <p className="title">12-02-2023</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync End Date")}
                                          </p>
                                          <p className="title">18-02-2023</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={GRbike} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}
                                            </p>
                                            <p className="title">Honda Bike</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Date")}
                                          </p>
                                          <p className="title">12-02-2023</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Time")}
                                          </p>
                                          <p className="title">04:04:53 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Period")}
                                          </p>
                                          <p className="title">Date Range</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Status")}
                                          </p>
                                          <p className="title">Pending</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Start Date")}
                                          </p>
                                          <p className="title">12-02-2023</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync End Date")}
                                          </p>
                                          <p className="title">18-02-2023</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={GRcar} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Date")}
                                          </p>
                                          <p className="title">12-02-2023</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Time")}
                                          </p>
                                          <p className="title">04:04:53 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Period")}
                                          </p>
                                          <p className="title">Date Range</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Status")}
                                          </p>
                                          <p className="title">Pending</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Start Date")}
                                          </p>
                                          <p className="title">12-02-2023</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync End Date")}
                                          </p>
                                          <p className="title">18-02-2023</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={GRcar} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Date")}
                                          </p>
                                          <p className="title">12-02-2023</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Time")}
                                          </p>
                                          <p className="title">04:04:53 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Period")}
                                          </p>
                                          <p className="title">Date Range</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Status")}
                                          </p>
                                          <p className="title">Pending</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Start Date")}
                                          </p>
                                          <p className="title">12-02-2023</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync End Date")}
                                          </p>
                                          <p className="title">18-02-2023</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={GRcar} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}
                                            </p>
                                            <p className="title">
                                              Mercedes Bus{" "}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Date")}
                                          </p>
                                          <p className="title">12-02-2023</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Time")}
                                          </p>
                                          <p className="title">04:04:53 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Period")}
                                          </p>
                                          <p className="title">Date Range</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Status")}
                                          </p>
                                          <p className="title">Pending</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Start Date")}
                                          </p>
                                          <p className="title">12-02-2023</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync End Date")}
                                          </p>
                                          <p className="title">18-02-2023</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={GRcar} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Date")}
                                          </p>
                                          <p className="title">12-02-2023</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Time")}
                                          </p>
                                          <p className="title">04:04:53 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Period")}
                                          </p>
                                          <p className="title">Date Range</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Status")}
                                          </p>
                                          <p className="title">Pending</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Start Date")}
                                          </p>
                                          <p className="title">12-02-2023</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync End Date")}
                                          </p>
                                          <p className="title">18-02-2023</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={GRcar} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Date")}
                                          </p>
                                          <p className="title">12-02-2023</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Time")}
                                          </p>
                                          <p className="title">04:04:53 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Period")}
                                          </p>
                                          <p className="title">Date Range</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Status")}
                                          </p>
                                          <p className="title">Pending</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Start Date")}
                                          </p>
                                          <p className="title">12-02-2023</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync End Date")}
                                          </p>
                                          <p className="title">18-02-2023</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={GRcar} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}
                                            </p>
                                            <p className="title">
                                              Mercedes Bus{" "}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Date")}
                                          </p>
                                          <p className="title">12-02-2023</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Time")}
                                          </p>
                                          <p className="title">04:04:53 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Period")}
                                          </p>
                                          <p className="title">Date Range</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Status")}
                                          </p>
                                          <p className="title">Pending</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Start Date")}
                                          </p>
                                          <p className="title">12-02-2023</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync End Date")}
                                          </p>
                                          <p className="title">18-02-2023</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={GRbike} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}
                                            </p>
                                            <p className="title">Honda Bike</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Date")}
                                          </p>
                                          <p className="title">12-02-2023</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Time")}
                                          </p>
                                          <p className="title">04:04:53 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Period")}
                                          </p>
                                          <p className="title">Date Range</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Request Status")}
                                          </p>
                                          <p className="title">Pending</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync Start Date")}
                                          </p>
                                          <p className="title">12-02-2023</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Sync End Date")}
                                          </p>
                                          <p className="title">18-02-2023</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="3">
                            <div className="all-vehicle-main">
                              <div className="all-vehical-head row vehicle-top-inputs">
                                <div className="input-section-wrapper">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Vehicle Name,No., Reg. No, IMEI..."
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Sync Request Date & Time"
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Sync Period"
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Sync Request Status"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="right-export-btn-section-wrapper">
                                  <div className="c-pointer">
                                    <img src={Import} alt="" />
                                  </div>
                                </div>
                              </div>
                              <div className="yauto" id="arrange-paading">
                                <div className="row main-cards-wrapper gx-3">
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={
                                        "common-vehical-card-inner  background-img"
                                      }
                                    >
                                      <div className="track-qustion">
                                        {/* <img src={Qustiontracking} alt="" /> */}
                                      </div>
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Gcar} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}
                                            </p>
                                            <p className="title">Utility CAB</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Number")}
                                          </p>
                                          <p className="title">MH-12-5022</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Type")}
                                          </p>
                                          <p className="title">Car</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Transportation Type")}
                                          </p>
                                          <p className="title">Passenger</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Capacity / Passenger")}
                                          </p>
                                          <p className="title">60</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={
                                        "common-vehical-card-inner  background-img"
                                      }
                                    >
                                      <div className="track-qustion">
                                        <img src={Qustiontracking} alt="" />
                                      </div>
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Gbike} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}
                                            </p>
                                            <p className="title">Honda Bike</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Number")}
                                          </p>
                                          <p className="title">MH-12-5022</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Type")}
                                          </p>
                                          <p className="title">Bike</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Transportation Type")}
                                          </p>
                                          <p className="title">Passenger</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Capacity / Passenger")}
                                          </p>
                                          <p className="title">1</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={
                                        "common-vehical-card-inner  background-img"
                                      }
                                    >
                                      <div className="track-qustion">
                                        {/* <img src={Qustiontracking} alt="" /> */}
                                      </div>
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Gcar} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Number")}
                                          </p>
                                          <p className="title">MH-12-5022</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Type")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Transportation Type")}
                                          </p>
                                          <p className="title">Goods</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Capacity / Passenger")}
                                          </p>
                                          <p className="title">1 Ton</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={
                                        "common-vehical-card-inner  background-img"
                                      }
                                    >
                                      <div className="track-qustion">
                                        <img src={Qustiontracking} alt="" />
                                      </div>
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Bcar} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Number")}
                                          </p>
                                          <p className="title">MH-12-5022</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Type")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Transportation Type")}
                                          </p>
                                          <p className="title">Goods</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Capacity / Passenger")}
                                          </p>
                                          <p className="title">1 Ton</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={
                                        "common-vehical-card-inner  background-img"
                                      }
                                    >
                                      <div className="track-qustion">
                                        {/* <img src={Qustiontracking} alt="" /> */}
                                      </div>
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Bcar} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}
                                            </p>
                                            <p className="title">
                                              Mercedes Bus{" "}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Number")}
                                          </p>
                                          <p className="title">MH-12-5022</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Type")}
                                          </p>
                                          <p className="title">Vanity Van</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Transportation Type")}
                                          </p>
                                          <p className="title">Passenger</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Capacity / Passenger")}
                                          </p>
                                          <p className="title">60</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={
                                        "common-vehical-card-inner  background-img"
                                      }
                                    >
                                      <div className="track-qustion">
                                        <img src={Qustiontracking} alt="" />
                                      </div>
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Bcar} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Number")}
                                          </p>
                                          <p className="title">MH-12-5022</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Type")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Transportation Type")}
                                          </p>
                                          <p className="title">Goods</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Capacity / Passenger")}
                                          </p>
                                          <p className="title">500 Kg</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={
                                        "common-vehical-card-inner  background-img"
                                      }
                                    >
                                      <div className="track-qustion">
                                        {/* <img src={Qustiontracking} alt="" /> */}
                                      </div>
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Rcar} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Number")}
                                          </p>
                                          <p className="title">MH-12-5022</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Type")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Transportation Type")}
                                          </p>
                                          <p className="title">Goods</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Capacity / Passenger")}
                                          </p>
                                          <p className="title">500 Kg</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={
                                        "common-vehical-card-inner  background-img"
                                      }
                                    >
                                      <div className="track-qustion">
                                        <img src={Qustiontracking} alt="" />
                                      </div>
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={BKcar} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Name")}
                                            </p>
                                            <p className="title">
                                              Mercedes Bus{" "}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Number")}
                                          </p>
                                          <p className="title">MH-12-5022</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Type")}
                                          </p>
                                          <p className="title">Vanity Van</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Transportation Type")}
                                          </p>
                                          <p className="title">Passenger</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Capacity / Passenger")}
                                          </p>
                                          <p className="title">60</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                  >
                                    <div
                                      className={
                                        "common-vehical-card-inner  background-img"
                                      }
                                    >
                                      <div className="track-qustion">
                                        {/* <img src={Qustiontracking} alt="" /> */}
                                      </div>
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Rbike} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              Vehicle Name
                                            </p>
                                            <p className="title">Honda Bike</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Number")}
                                          </p>
                                          <p className="title">MH-12-5022</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Type")}
                                          </p>
                                          <p className="title">Bike</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Transportation Type")}
                                          </p>
                                          <p className="title">Passenger</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Capacity / Passenger")}
                                          </p>
                                          <p className="title">1</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Tab.Pane>
                        </Tab.Content>
                      </Col>
                    </Tab.Container>
                  </div>
                  <p className="reg-color mt-3">{t("Showing")} 1 - 10 of 200</p>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="Five">
                <div id="fetureset_main">
                  <div className="inner_tabs_For_FeatureSet">
                    <Tab.Container
                      id="left-tabs-example"
                      className="Inner_tab_featureSet"
                      defaultActiveKey="0"
                    >
                      <Nav
                        variant="pills"
                        className="td-nav mb-3"
                        id="InnerTabNew_feature"
                      >
                        <Nav.Item className="UL_main_feature">
                          <Splide
                            options={{
                              rewind: true,
                              // type: 'loop',
                              focus: 0,
                              // omitEnd  : true,
                              autoWidth: true,
                              next: "splide__arrow--next your-class-next",
                              gap: "1rem",
                            }}
                            aria-label="My Favorite Images"
                          >
                            <SplideSlide>
                              <Nav.Link className="td-link" eventKey="0">
                                {t("Immobilization")}
                              </Nav.Link>
                            </SplideSlide>
                            <SplideSlide>
                              <Nav.Link className="td-link" eventKey="1">
                                {t("Immobilization 1")}
                              </Nav.Link>
                            </SplideSlide>
                            <SplideSlide>
                              <Nav.Link className="td-link" eventKey="2">
                                {t("Temperature Sensors")}
                              </Nav.Link>
                            </SplideSlide>
                            <SplideSlide>
                              <Nav.Link className="td-link" eventKey="3">
                                {t("Fuel sensor")}
                              </Nav.Link>
                            </SplideSlide>
                            <SplideSlide>
                              <Nav.Link className="td-link" eventKey="4">
                                {t("I button")}
                              </Nav.Link>
                            </SplideSlide>
                            <SplideSlide>
                              <Nav.Link className="td-link" eventKey="5">
                                {t("Seat Belt sensor")}
                              </Nav.Link>
                            </SplideSlide>
                            <SplideSlide>
                              <Nav.Link className="td-link" eventKey="6">
                                {t("Echo Driving")}
                              </Nav.Link>
                            </SplideSlide>
                            <SplideSlide>
                              <Nav.Link className="td-link" eventKey="7">
                                {t("IVMS")}
                              </Nav.Link>
                            </SplideSlide>
                            <SplideSlide>
                              <Nav.Link className="td-link" eventKey="8">
                                {t("Card reader")}
                              </Nav.Link>
                            </SplideSlide>
                            <SplideSlide>
                              <Nav.Link className="td-link" eventKey="9">
                                {t("Over Speeding")}
                              </Nav.Link>
                            </SplideSlide>
                            <SplideSlide>
                              <Nav.Link className="td-link" eventKey="10">
                                {t("Crash Detection")}
                              </Nav.Link>
                            </SplideSlide>
                            <SplideSlide>
                              <Nav.Link className="td-link" eventKey="11">
                                {t("Excessive Idling")}
                              </Nav.Link>
                            </SplideSlide>
                            <SplideSlide>
                              <Nav.Link className="td-link" eventKey="12">
                                {t("Towing detection")}
                              </Nav.Link>
                            </SplideSlide>
                            <SplideSlide>
                              <Nav.Link className="td-link" eventKey="13">
                                {t("un plug detection")}
                              </Nav.Link>
                            </SplideSlide>
                          </Splide>
                        </Nav.Item>
                      </Nav>
                      <Col sm={12} className="">
                        <Tab.Content>
                          <Tab.Pane eventKey="0">
                            <div className="all-vehicle-main featureSet_card_main">
                              <div className="all-vehical-head row vehicle-top-inputs">
                                <div className="input-section-wrapper">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Vehicle Name,No., Reg. No, IMEI..."
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Vehicle Category"
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                                      <select
                                        required
                                        className="form-select"
                                        aria-label="Default select example"
                                        placeholder="Transportation Type"
                                      >
                                        <option value="">
                                          Transportation Type
                                        </option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="right-export-btn-section-wrapper">
                                  <div className="c-pointer">
                                    <img src={Import} alt="" />
                                  </div>
                                </div>
                              </div>
                              <div className="yauto" id="arrange-paading">
                                <div className="row main-cards-wrapper gx-3">
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          {Immobilization == true ? (
                                            <img src={Feature_CarLock} alt="" />
                                          ) : (
                                            <img
                                              src={Feature_CarLock_active}
                                              alt=""
                                            />
                                          )}
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              onChange={() =>
                                                setImmobilization(
                                                  !Immobilization
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Number")}
                                          </p>
                                          <p className="title">MH-12-5022</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Type")}.
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Transportation Type")}
                                          </p>
                                          <p className="title">Goods</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Capacity")}
                                          </p>
                                          <p className="title">1 Ton</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_CarLock_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Number")}
                                          </p>
                                          <p className="title">MH-12-5022</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Type")}.
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Transportation Type")}
                                          </p>
                                          <p className="title">Goods</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Capacity")}
                                          </p>
                                          <p className="title">1 Ton</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_CarLock_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Number")}
                                          </p>
                                          <p className="title">MH-12-5022</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Type")}.
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Transportation Type")}
                                          </p>
                                          <p className="title">Goods</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Capacity")}
                                          </p>
                                          <p className="title">1 Ton</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Feature_CarLock} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Number")}
                                          </p>
                                          <p className="title">MH-12-5022</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Type")}.
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Transportation Type")}
                                          </p>
                                          <p className="title">Goods</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Capacity")}
                                          </p>
                                          <p className="title">1 Ton</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Feature_CarLock} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Number")}
                                          </p>
                                          <p className="title">MH-12-5022</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Type")}.
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Transportation Type")}
                                          </p>
                                          <p className="title">Goods</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Capacity")}
                                          </p>
                                          <p className="title">1 Ton</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Feature_CarLock} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Number")}
                                          </p>
                                          <p className="title">MH-12-5022</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Type")}.
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Transportation Type")}
                                          </p>
                                          <p className="title">Goods</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Capacity")}
                                          </p>
                                          <p className="title">1 Ton</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Feature_CarLock} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Number")}
                                          </p>
                                          <p className="title">MH-12-5022</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Type")}.
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Transportation Type")}
                                          </p>
                                          <p className="title">Goods</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Capacity")}
                                          </p>
                                          <p className="title">1 Ton</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Feature_CarLock} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Number")}
                                          </p>
                                          <p className="title">MH-12-5022</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Type")}.
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Transportation Type")}
                                          </p>
                                          <p className="title">Goods</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Capacity")}
                                          </p>
                                          <p className="title">1 Ton</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Feature_CarLock} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Number")}
                                          </p>
                                          <p className="title">MH-12-5022</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Type")}.
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Transportation Type")}
                                          </p>
                                          <p className="title">Goods</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Capacity")}
                                          </p>
                                          <p className="title">1 Ton</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Feature_CarLock} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Number")}
                                          </p>
                                          <p className="title">MH-12-5022</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Type")}.
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Transportation Type")}
                                          </p>
                                          <p className="title">Goods</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Capacity")}
                                          </p>
                                          <p className="title">1 Ton</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Feature_CarLock} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Number")}
                                          </p>
                                          <p className="title">MH-12-5022</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Vehicle Type")}.
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("Transportation Type")}
                                          </p>
                                          <p className="title">Goods</p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Capacity")}
                                          </p>
                                          <p className="title">1 Ton</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <p className="reg-color mt-3">
                              {t("Showing")} 1 - 10 of 200
                            </p>
                          </Tab.Pane>
                          <Tab.Pane eventKey="1">
                            <div className="all-vehicle-main featureSet_card_main">
                              <div className="all-vehical-head row vehicle-top-inputs">
                                <div className="input-section-wrapper">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Vehicle Name,No., Reg. No, IMEI..."
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Vehicle Category"
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                                      <select
                                        required
                                        className="form-select"
                                        aria-label="Default select example"
                                        placeholder="Transportation Type"
                                      >
                                        <option value="">
                                          Transportation Type
                                        </option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="right-export-btn-section-wrapper">
                                  <div className="c-pointer">
                                    <img src={Import} alt="" />
                                  </div>
                                </div>
                              </div>
                              <div className="yauto" id="arrange-paading">
                                <div className="row main-cards-wrapper gx-3">
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          {Immobilization1 == true ? (
                                            <img
                                              src={Feature_CarLock_active}
                                              alt=""
                                            />
                                          ) : (
                                            <img src={Feature_CarLock} alt="" />
                                          )}
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              onChange={() =>
                                                setImmobilization1(
                                                  !Immobilization1
                                                )
                                              }
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_CarLock_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_CarLock_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_CarLock_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_CarLock_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_CarLock_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_CarLock_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_CarLock_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_CarLock_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_CarLock_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_CarLock_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_CarLock_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_CarLock_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <p className="reg-color mt-3">
                              {t(" Showing")} 1 - 10 of 200
                            </p>
                          </Tab.Pane>
                          <Tab.Pane eventKey="2">
                            <div className="all-vehicle-main featureSet_card_main">
                              <div className="all-vehical-head row vehicle-top-inputs">
                                <div className="input-section-wrapper">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Vehicle Name,No., Reg. No, IMEI..."
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Vehicle Category"
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                                      <select
                                        required
                                        className="form-select"
                                        aria-label="Default select example"
                                        placeholder="Transportation Type"
                                      >
                                        <option value="">
                                          Transportation Type
                                        </option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="right-export-btn-section-wrapper">
                                  <div className="c-pointer">
                                    <img src={Import} alt="" />
                                  </div>
                                </div>
                              </div>
                              <div className="yauto" id="arrange-paading">
                                <div className="row main-cards-wrapper gx-3">
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          {Tempr === true ? (
                                            <img src={Feature_temp} alt="" />
                                          ) : (
                                            <img
                                              src={Feature_temp_active}
                                              alt=""
                                            />
                                          )}
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              onChange={() => setTempr(!Tempr)}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_temp_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_temp_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_temp_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_temp_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_temp_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_temp_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_temp_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_temp_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_temp_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_temp_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_temp_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_temp_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <p className="reg-color mt-3">
                              {t(" Showing")} 1 - 10 of 200
                            </p>
                          </Tab.Pane>
                          <Tab.Pane eventKey="3">
                            <div className="all-vehicle-main featureSet_card_main">
                              <div className="all-vehical-head row vehicle-top-inputs">
                                <div className="input-section-wrapper">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Vehicle Name,No., Reg. No, IMEI..."
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Vehicle Category"
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                                      <select
                                        required
                                        className="form-select"
                                        aria-label="Default select example"
                                        placeholder="Transportation Type"
                                      >
                                        <option value="">
                                          Transportation Type
                                        </option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="right-export-btn-section-wrapper">
                                  <div className="c-pointer">
                                    <img src={Import} alt="" />
                                  </div>
                                </div>
                              </div>
                              <div className="yauto" id="arrange-paading">
                                <div className="row main-cards-wrapper gx-3">
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          {Fuel === true ? (
                                            <img src={Feature_Fuel} alt="" />
                                          ) : (
                                            <img
                                              src={Feature_Fuel_active}
                                              alt=""
                                            />
                                          )}
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              onChange={() => setFuel(!Fuel)}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Feature_Fuel} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Fuel_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Fuel_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Fuel_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Fuel_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Fuel_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Fuel_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Fuel_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Fuel_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Fuel_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Fuel_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Fuel_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <p className="reg-color mt-3">
                              {t(" Showing")} 1 - 10 of 200
                            </p>
                          </Tab.Pane>
                          <Tab.Pane eventKey="4">
                            <div className="all-vehicle-main featureSet_card_main">
                              <div className="all-vehical-head row vehicle-top-inputs">
                                <div className="input-section-wrapper">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Vehicle Name,No., Reg. No, IMEI..."
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Vehicle Category"
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                                      <select
                                        required
                                        className="form-select"
                                        aria-label="Default select example"
                                        placeholder="Transportation Type"
                                      >
                                        <option value="">
                                          Transportation Type
                                        </option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="right-export-btn-section-wrapper">
                                  <div className="c-pointer">
                                    <img src={Import} alt="" />
                                  </div>
                                </div>
                              </div>
                              <div className="yauto" id="arrange-paading">
                                <div className="row main-cards-wrapper gx-3">
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          {ibtn === true ? (
                                            <img src={Feature_I} alt="" />
                                          ) : (
                                            <img
                                              src={Feature_I_active}
                                              alt=""
                                            />
                                          )}
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              onChange={() => setIbtn(!ibtn)}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Feature_I_active} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Feature_I_active} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Feature_I_active} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Feature_I_active} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Feature_I_active} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Feature_I_active} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Feature_I_active} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Feature_I_active} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Feature_I_active} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Feature_I_active} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Feature_I_active} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={Feature_I_active} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <p className="reg-color mt-3">
                              {t(" Showing")} 1 - 10 of 200
                            </p>
                          </Tab.Pane>
                          <Tab.Pane eventKey="5">
                            <div className="all-vehicle-main featureSet_card_main">
                              <div className="all-vehical-head row vehicle-top-inputs">
                                <div className="input-section-wrapper">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Vehicle Name,No., Reg. No, IMEI..."
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Vehicle Category"
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                                      <select
                                        required
                                        className="form-select"
                                        aria-label="Default select example"
                                        placeholder="Transportation Type"
                                      >
                                        <option value="">
                                          Transportation Type
                                        </option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="right-export-btn-section-wrapper">
                                  <div className="c-pointer">
                                    <img src={Import} alt="" />
                                  </div>
                                </div>
                              </div>
                              <div className="yauto" id="arrange-paading">
                                <div className="row main-cards-wrapper gx-3">
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          {seat === true ? (
                                            <img src={Feature_Seat} alt="" />
                                          ) : (
                                            <img
                                              src={Feature_Seat_active}
                                              alt=""
                                            />
                                          )}
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              onChange={() => setSeat(!seat)}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Seat_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Seat_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Seat_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Seat_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Seat_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Seat_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Seat_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Seat_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Seat_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Seat_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Seat_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Seat_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <p className="reg-color mt-3">
                              {t(" Showing")} 1 - 10 of 200
                            </p>
                          </Tab.Pane>
                          <Tab.Pane eventKey="6">
                            <div className="all-vehicle-main featureSet_card_main">
                              <div className="all-vehical-head row vehicle-top-inputs">
                                <div className="input-section-wrapper">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Vehicle Name,No., Reg. No, IMEI..."
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Vehicle Category"
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                                      <select
                                        required
                                        className="form-select"
                                        aria-label="Default select example"
                                        placeholder="Transportation Type"
                                      >
                                        <option value="">
                                          Transportation Type
                                        </option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="right-export-btn-section-wrapper">
                                  <div className="c-pointer">
                                    <img src={Import} alt="" />
                                  </div>
                                </div>
                              </div>
                              <div className="yauto" id="arrange-paading">
                                <div className="row main-cards-wrapper gx-3">
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          {echo === true ? (
                                            <img src={Feature_Echo} alt="" />
                                          ) : (
                                            <img
                                              src={Feature_Echo_active}
                                              alt=""
                                            />
                                          )}
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              onChange={() => setEcho(!echo)}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Echo_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              checked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Echo_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Echo_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Echo_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Echo_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Echo_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Echo_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Echo_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Echo_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Echo_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Echo_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Echo_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <p className="reg-color mt-3">
                              {t(" Showing")} 1 - 10 of 200
                            </p>
                          </Tab.Pane>
                          <Tab.Pane eventKey="7">
                            <div className="all-vehicle-main featureSet_card_main">
                              <div className="all-vehical-head row vehicle-top-inputs">
                                <div className="input-section-wrapper">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Vehicle Name,No., Reg. No, IMEI..."
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Vehicle Category"
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                                      <select
                                        required
                                        className="form-select"
                                        aria-label="Default select example"
                                        placeholder="Transportation Type"
                                      >
                                        <option value="">
                                          Transportation Type
                                        </option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="right-export-btn-section-wrapper">
                                  <div className="c-pointer">
                                    <img src={Import} alt="" />
                                  </div>
                                </div>
                              </div>
                              <div className="yauto" id="arrange-paading">
                                <div className="row main-cards-wrapper gx-3">
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          {ivms === true ? (
                                            <img
                                              src={Feature_IVMS_active}
                                              alt=""
                                            />
                                          ) : (
                                            <img src={Feature_IVMS} alt="" />
                                          )}
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              onChange={() => setIVMS(!ivms)}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_IVMS_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_IVMS_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_IVMS_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_IVMS_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_IVMS_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_IVMS_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_IVMS_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_IVMS_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_IVMS_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_IVMS_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_IVMS_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_IVMS_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <p className="reg-color mt-3">
                              {t(" Showing")} 1 - 10 of 200
                            </p>
                          </Tab.Pane>
                          <Tab.Pane eventKey="8">
                            <div className="all-vehicle-main featureSet_card_main">
                              <div className="all-vehical-head row vehicle-top-inputs">
                                <div className="input-section-wrapper">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Vehicle Name,No., Reg. No, IMEI..."
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Vehicle Category"
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                                      <select
                                        required
                                        className="form-select"
                                        aria-label="Default select example"
                                        placeholder="Transportation Type"
                                      >
                                        <option value="">
                                          Transportation Type
                                        </option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="right-export-btn-section-wrapper">
                                  <div className="c-pointer">
                                    <img src={Import} alt="" />
                                  </div>
                                </div>
                              </div>
                              <div className="yauto" id="arrange-paading">
                                <div className="row main-cards-wrapper gx-3">
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          {card === true ? (
                                            <img src={Feature_Card} alt="" />
                                          ) : (
                                            <img
                                              src={Feature_Card_active}
                                              alt=""
                                            />
                                          )}
                                          <div className="">
                                            <p className="sub-heading">
                                              Vehicle Number123
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              onChange={() => setCard(!card)}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Card_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Card_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Card_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Card_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Card_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Card_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Card_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Card_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Card_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Card_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Card_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Card_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <p className="reg-color mt-3">
                              {t(" Showing")} 1 - 10 of 200
                            </p>
                          </Tab.Pane>
                          <Tab.Pane eventKey="9">
                            <div className="all-vehicle-main featureSet_card_main">
                              <div className="all-vehical-head row vehicle-top-inputs">
                                <div className="input-section-wrapper">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Vehicle Name,No., Reg. No, IMEI..."
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Vehicle Category"
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                                      <select
                                        required
                                        className="form-select"
                                        aria-label="Default select example"
                                        placeholder="Transportation Type"
                                      >
                                        <option value="">
                                          Transportation Type
                                        </option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="right-export-btn-section-wrapper">
                                  <div className="c-pointer">
                                    <img src={Import} alt="" />
                                  </div>
                                </div>
                              </div>
                              <div className="yauto" id="arrange-paading">
                                <div className="row main-cards-wrapper gx-3">
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          {speed === true ? (
                                            <img
                                              src={Feature_Overspeed}
                                              alt=""
                                            />
                                          ) : (
                                            <img
                                              src={Feature_Overspeed_active}
                                              alt=""
                                            />
                                          )}
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              onChange={() => setSpeed(!speed)}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Overspeed_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Overspeed_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Overspeed_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Overspeed_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Overspeed_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Overspeed_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Overspeed_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Overspeed_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Overspeed_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Overspeed_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Overspeed_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Overspeed_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <p className="reg-color mt-3">
                              {t(" Showing")} 1 - 10 of 200
                            </p>
                          </Tab.Pane>
                          <Tab.Pane eventKey="10">
                            <div className="all-vehicle-main featureSet_card_main">
                              <div className="all-vehical-head row vehicle-top-inputs">
                                <div className="input-section-wrapper">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Vehicle Name,No., Reg. No, IMEI..."
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Vehicle Category"
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                                      <select
                                        required
                                        className="form-select"
                                        aria-label="Default select example"
                                        placeholder="Transportation Type"
                                      >
                                        <option value="">
                                          Transportation Type
                                        </option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="right-export-btn-section-wrapper">
                                  <div className="c-pointer">
                                    <img src={Import} alt="" />
                                  </div>
                                </div>
                              </div>
                              <div className="yauto" id="arrange-paading">
                                <div className="row main-cards-wrapper gx-3">
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          {cresh === true ? (
                                            <img
                                              src={Feature_Crash_active}
                                              alt=""
                                            />
                                          ) : (
                                            <img src={Feature_Crash} alt="" />
                                          )}
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                              onChange={() => setCresh(!cresh)}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Crash_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Crash_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Crash_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Crash_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Crash_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Crash_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Crash_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Crash_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Crash_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Crash_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Crash_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Crash_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <p className="reg-color mt-3">
                              {t(" Showing")} 1 - 10 of 200
                            </p>
                          </Tab.Pane>
                          <Tab.Pane eventKey="11">
                            <div className="all-vehicle-main featureSet_card_main">
                              <div className="all-vehical-head row vehicle-top-inputs">
                                <div className="input-section-wrapper">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Vehicle Name,No., Reg. No, IMEI..."
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Vehicle Category"
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                                      <select
                                        required
                                        className="form-select"
                                        aria-label="Default select example"
                                        placeholder="Transportation Type"
                                      >
                                        <option value="">
                                          Transportation Type
                                        </option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="right-export-btn-section-wrapper">
                                  <div className="c-pointer">
                                    <img src={Import} alt="" />
                                  </div>
                                </div>
                              </div>
                              <div className="yauto" id="arrange-paading">
                                <div className="row main-cards-wrapper gx-3">
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          {exicess === true ? (
                                            <img
                                              src={Feature_Exicess_active}
                                              alt=""
                                            />
                                          ) : (
                                            <img src={Feature_Exicess} alt="" />
                                          )}
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                              onChange={() =>
                                                setExicess(!exicess)
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Exicess_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Exicess_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Exicess_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Exicess_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Exicess_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Exicess_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Exicess_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Exicess_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Exicess_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Exicess_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Exicess_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Crash_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <p className="reg-color mt-3">
                              {t(" Showing")} 1 - 10 of 200
                            </p>
                          </Tab.Pane>
                          <Tab.Pane eventKey="12">
                            <div className="all-vehicle-main featureSet_card_main">
                              <div className="all-vehical-head row vehicle-top-inputs">
                                <div className="input-section-wrapper">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Vehicle Name,No., Reg. No, IMEI..."
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Vehicle Category"
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                                      <select
                                        required
                                        className="form-select"
                                        aria-label="Default select example"
                                        placeholder="Transportation Type"
                                      >
                                        <option value="">
                                          Transportation Type
                                        </option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="right-export-btn-section-wrapper">
                                  <div className="c-pointer">
                                    <img src={Import} alt="" />
                                  </div>
                                </div>
                              </div>
                              <div className="yauto" id="arrange-paading">
                                <div className="row main-cards-wrapper gx-3">
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          {towing === true ? (
                                            <img
                                              src={Feature_Towing_active}
                                              alt=""
                                            />
                                          ) : (
                                            <img src={Feature_Towing} alt="" />
                                          )}
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                              onChange={() =>
                                                setTowing(!towing)
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Towing_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Towing_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Towing_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Towing_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Towing_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Towing_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Towing_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Towing_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Towing_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Towing_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Towing_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Towing_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <p className="reg-color mt-3">
                              {t(" Showing")} 1 - 10 of 200
                            </p>
                          </Tab.Pane>
                          <Tab.Pane eventKey="13">
                            <div className="all-vehicle-main featureSet_card_main">
                              <div className="all-vehical-head row vehicle-top-inputs">
                                <div className="input-section-wrapper">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Vehicle Name,No., Reg. No, IMEI..."
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Sync Request Date & Time"
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                                      <select
                                        required
                                        className="form-select"
                                        aria-label="Default select example"
                                        placeholder="Transportation Type"
                                      >
                                        <option value="">
                                          Transportation Type
                                        </option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="right-export-btn-section-wrapper">
                                  <div className="c-pointer">
                                    <img src={Import} alt="" />
                                  </div>
                                </div>
                              </div>
                              <div className="yauto" id="arrange-paading">
                                <div className="row main-cards-wrapper gx-3">
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          {plug === true ? (
                                            <img
                                              src={Feature_Unplag_active}
                                              alt=""
                                            />
                                          ) : (
                                            <img src={Feature_Unplag} alt="" />
                                          )}
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                              onChange={() => setPlug(!plug)}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Unplag_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Unplag_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Unplag_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Unplag_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Unplag_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Unplag_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Unplag_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Unplag_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Unplag_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Unplag_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Unplag_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-xl-4 col-lg-6 col-md-6"
                                        : "col-xl-3 col-lg-4 col-md-6"
                                    }
                                    id="fetureset_single_card"
                                  >
                                    <div
                                      className={"common-vehical-card-inner"}
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img
                                            src={Feature_Unplag_active}
                                            alt=""
                                          />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Vehicle Number")}
                                            </p>
                                            <p className="title">
                                              Volvo Transport
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="form-check form-switch"
                                          id="custom_switch"
                                        >
                                          <div className="d-flex innerFlexTog">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="Tuesday"
                                              defaultChecked
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t("IMEI No.")}
                                          </p>
                                          <p className="title">
                                            1234567894561230
                                          </p>
                                        </div>
                                        <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                                          <p className="sub-heading">
                                            {t(" Vehicle Type.")}
                                          </p>
                                          <p className="title">Pick-Up Truck</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <p className="reg-color mt-3">
                              {t(" Showing")} 1 - 10 of 200
                            </p>
                          </Tab.Pane>
                        </Tab.Content>
                      </Col>
                    </Tab.Container>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>

          <Modal
            show={show}
            onHide={handleClose}
            centered
            className="common-model"
          >
            <Modal.Header closeButton>
              <Modal.Title>{t("Delete Vehicle")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {t("Are you sure you want to delete this vehicle")} ?
            </Modal.Body>
            <Modal.Footer className="pop-up-modal-footer btn-wrapper">
              <button className="cx-btn-1" onClick={handleClose}>
                {t("Close")}
              </button>
              <button className="cx-btn-2" onClick={handleClose}>
                {t("Yes")}
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </motion.div>
  );
};

export default Vehicle;
