import React from "react";
import { motion } from "framer-motion";
import { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import Logout from "../../assets/images/import_icon.svg";
import Share from "../../assets/images/XMLID_1022_.svg";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Dropdown } from "react-bootstrap";
import PreviousButton from "../../sharedComponent/PreviousButton";
import NextButton from "../../sharedComponent/Nextbutton";
import ExistButton from "../../sharedComponent/ExistButton";
import InfoComponent from "../../sharedComponent/SupportHelp/InfoComponent";

const FuelAlerts = ({ onClick }) => {
  const { sidebar, slider, setSlider } = useContext(AppContext);
  const navigate = useNavigate();
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    navigate("/FuelManagementDashbord");
  };
  const handleClickPrevious = () => {
    navigate("/FuelManagementDetails");
  };
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  const { t, i18n } = useTranslation();

  return (
    <motion.div
      className={sidebar ? "taskMain " : "cx-active taskMain"}
      id="cx-main"
      variants={aninations}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.1 }}
    >
      <div id="cx-wrapper" className="FuelManagementDashbord">
        <div className="top_contain_fule">
          <div className="row top-content ">
            <div className="col-lg-10 col-md-12 arrange-margin left">
              <div className="row p-0">
                <div className="col-md-3">
                  <div className="weekCounter">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Name"
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="weekCounter">
                    <input
                      type="Date"
                      className="form-control"
                      placeholder="Date"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-2 col-md-12 mainCol4 right">
              <div className="leftSideSec">
                {/* <Link to="#">
                  <div className="inconMain left-margin me-0">
                    <img src={Share} alt="" />
                  </div>
                </Link> */}
                <div style={{ marginRight: "10px" }} />
                <div className="right-export-btn-section-wrapper">
                  <div className="c-pointer me-2"></div>

                  <div className="md_dropdown">
                    <Dropdown>
                      <Dropdown.Toggle
                        id="dropdown-basic"
                        className="export-toggle"
                      >
                        <p className="Export_Text">{t("Export")}</p>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item>
                          <Link
                            // onClick={() => getExportChat()}
                            className="d-block"
                          >
                            {t("PDF")}
                          </Link>
                        </Dropdown.Item>

                        <Dropdown.Item>
                          <Link
                            // onClick={() => downLoadExcelSheet()}
                            className="d-block"
                          >
                            {t("Excel")}
                          </Link>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="FuelALertDetails">
          <div className="AbnormalFuelConsumption">
            <div className="Heading_fule">
              <p>{t("Abnormal Fuel Consumption")}</p>
            </div>
            <div className="AbnormalFuelConsumption_table">
              <table className="AbnormalFuelConsumption_table_main">
                <thead className="tableHead">
                  <tr>
                    <th>{t("Sr.no")}</th>
                    <th>{t("Vehicle")}</th>
                    <th>{t("Driver")}</th>
                    <th>{t("Abnormal Activity")}</th>
                    <th>{t("Date")}</th>
                    <th>{t("Status")}</th>
                  </tr>
                </thead>
                <tbody className="tableBody">
                  <tr>
                    <td>1</td>
                    <td>Toyota Innova</td>
                    <td>FERNANDO ALONSO</td>
                    <td>
                      Lorem ipsum dolor sit amet, consetetur sadip sjhsdks
                      skkdskdd sllslc{" "}
                    </td>
                    <td>01-01-2023</td>
                    <td className="redColor">High</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>Bajaj Pulsur</td>
                    <td>MARIO ANDRETTI</td>
                    <td>
                      Lorem ipsum dolor sit amet, consetetur sadip sjhsdks
                      skkdskdd sllslc{" "}
                    </td>
                    <td>15-08-2023</td>
                    <td className="yellowColor">Moderate</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>City Honda</td>
                    <td>JIM CLARK</td>
                    <td>
                      Lorem ipsum dolor sit amet, consetetur sadip sjhsdks
                      skkdskdd sllslc{" "}
                    </td>
                    <td>15-08-2023</td>
                    <td className="Low">Low</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>Suzuki Desire</td>
                    <td>JUAN MANUEL FANGIO</td>
                    <td>
                      Lorem ipsum dolor sit amet, consetetur sadip sjhsdks
                      skkdskdd sllslc{" "}
                    </td>
                    <td>22-10-2023</td>
                    <td className="Low">Low</td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>Honda Activa</td>
                    <td>A.J. FOYT</td>
                    <td>
                      Lorem ipsum dolor sit amet, consetetur sadip sjhsdks
                      skkdskdd sllslc{" "}
                    </td>
                    <td>18-12-2023</td>
                    <td className="Low">Low</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="AbnormalGroupExpense">
            <div className="Heading_fule">
              <p>{t("Abnormal Group Expense")}</p>
            </div>
            <div className="AbnormalFuelConsumption_table">
              <table className="AbnormalFuelConsumption_table_main">
                <thead className="tableHead">
                  <tr>
                    <th>{t("Sr.no")}</th>
                    <th>{t("Vehicle")}</th>
                    <th>{t("Driver")}</th>
                    <th>{t("Abnormal Activity")}</th>
                    <th>{t("Date")}</th>
                    <th>{t("Status")}</th>
                  </tr>
                </thead>
                <tbody className="tableBody">
                  <tr>
                    <td>1</td>
                    <td>Toyota Innova</td>
                    <td>FERNANDO ALONSO</td>
                    <td>
                      Lorem ipsum dolor sit amet, consetetur sadip sjhsdks
                      skkdskdd sllslc{" "}
                    </td>
                    <td>01-01-2023</td>
                    <td className="redColor">High</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>Bajaj Pulsur</td>
                    <td>MARIO ANDRETTI</td>
                    <td>
                      Lorem ipsum dolor sit amet, consetetur sadip sjhsdks
                      skkdskdd sllslc{" "}
                    </td>
                    <td>15-08-2023</td>
                    <td className="yellowColor">Moderate</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>City Honda</td>
                    <td>JIM CLARK</td>
                    <td>
                      Lorem ipsum dolor sit amet, consetetur sadip sjhsdks
                      skkdskdd sllslc{" "}
                    </td>
                    <td>15-08-2023</td>
                    <td className="Low">Low</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>Suzuki Desire</td>
                    <td>JUAN MANUEL FANGIO</td>
                    <td>
                      Lorem ipsum dolor sit amet, consetetur sadip sjhsdks
                      skkdskdd sllslc{" "}
                    </td>
                    <td>22-10-2023</td>
                    <td className="Low">Low</td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>Honda Activa</td>
                    <td>A.J. FOYT</td>
                    <td>
                      Lorem ipsum dolor sit amet, consetetur sadip sjhsdks
                      skkdskdd sllslc{" "}
                    </td>
                    <td>18-12-2023</td>
                    <td className="Low">Low</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {slider ? <ExistButton onClose={() => setSlider(false)} /> : null}
        {!slider && <PreviousButton onBack={handleClickPrevious} />}
        {!slider && <NextButton onClick={handleClick} />}
        {!slider && <InfoComponent />}
      </div>
    </motion.div>
  );
};

export default FuelAlerts;
