import React, { useContext, useState, useCallback } from "react";
import { AppContext } from "../../context/AppContext";
import profile from "../../assets/images/Update-profile.svg";
import camera from "../../assets/images/prof_cam.svg";
import { Dropdown, Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import edit_icon from "../../assets/images/ic-edit.svg";
import stops_icon from "../../assets/images/stops_icon.svg";
import delete_icon from "../../assets/images/delete.svg";
import close_icon from "../../assets/images/close_icon.svg";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Button from "react-bootstrap/Button";
import { SortableHandle } from "react-sortable-hoc";
import styled from "styled-components";
import TableRow from "./TableRow";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { ITEMS } from "./data";
import arrayMove from "./arrayMove";
import { motion } from "framer-motion";
import MapComponent from "../../sharedComponent/MapComponent";
import { useTranslation } from "react-i18next";
const ViewStop = () => {
  const { sidebar, setSidebar, Dark, setDark } = useContext(AppContext);
  const [validated, setValidated] = useState(false);
  const { t, i18n } = useTranslation();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [isActive, setIsActive] = useState(false);
  const handleClick = () => {
    setIsActive(!isActive);
  };
  const handleClick2 = () => {
    setIsActive(!isActive);
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  // Draggable Table Row
  const MyTableWrapper = styled.div`
    // padding: 10px;
    width: 100%;
    .empty-th {
      padding: 10px 15px;
    }
    .fixed_header {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0 10px;

      & > tbody {
        display: block;
        width: 100%;
        cursor: grabbing;
        background: transparent;
        & > td {
          & > tr {
          }
        }
      }

      & > thead {
        background: #fff;
        color: #8f4300;
        border: none;

        & > tr {
          display: block;
          //width: 793px;
        }
      }

      & > thead th {
        width: 16%;
        border: none;
        &:first-child {
          width: 80px;
        }
      }
      & > thead th,
      & > tbody td {
        padding: 10px;
        text-align: left;
        width: 16%;
        // border-radius:10px;
      }
      & > tbody tr {
        border-radius: 10px;
        td {
          padding: 15px;
          border: 1px solid #f6efe9;
          color: #8f4300;
          font-size: 14px;
          &:first-child {
            border-top-left-radius: 10px;
            border-right: none;
            border-bottom-left-radius: 10px;
            width: 50px;
          }
          &:nth-child(2) {
            border-right: none;
            border-left: none;
          }
          &:nth-child(3) {
            border-right: none;
            border-left: none;
          }
          &:nth-child(4) {
            border-right: none;
            border-left: none;
          }
          &:nth-child(5) {
            border-right: none;
            border-left: none;
          }
          &:nth-child(6) {
            border-right: none;
            border-left: none;
          }
          &:last-child {
            border-left: none;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
          }
        }
      }
    }
  `;

  const SortableCont = SortableContainer(({ children }) => {
    return <tbody>{children}</tbody>;
  });

  const SortableItem = SortableElement((props) => <TableRow {...props} />);
  const [items, setItems] = useState(ITEMS);

  const onSortEnd = useCallback(({ oldIndex, newIndex }) => {
    setItems((oldItems) => arrayMove(oldItems, oldIndex, newIndex));
  }, []);
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  return (
    <motion.div className={sidebar ? "taskMain " : "cx-active taskMain"} id="cx-main" variants={aninations}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.1 }}>
      <div id="cx-wrapper">
        <div className="main-master-wrapper CustomerProfile">
          <div className="Heading">
            <p>{t("Stop Details")}</p>
            <div className="action-btn-wrapper">
              <Link to="/EditStop">
                <img src={edit_icon} alt="" />
              </Link>
              <Link to="#" onClick={handleShow}>
                <img src={delete_icon} alt="" />
              </Link>
            </div>
          </div>
          <div className="stop-details-wrapper">
            <div className="row">
              <div className="col-md-4 form_input_main">
                <label htmlFor="">{t("Stop Name")}</label>
                <p>Kolhapur, Maharashtra</p>
              </div>
              <div className="col-md-4 form_input_main">
                <label htmlFor="">{t("Stop Code")}</label>
                <p>Kolhapur, Maharashtra</p>
              </div>
              <div className="col-md-4 form_input_main">
                <label htmlFor="">{t("Distance from Customer")} (KM)</label>
                <p>25Kms</p>
              </div>
              <div className="col-md-4 form_input_main">
                <label htmlFor="">{t("Stop Priority")}</label>
                <p>2</p>
              </div>
              <div className="col-md-4 form_input_main">
                <label htmlFor="">{t("Stop Latitude")}</label>
                <p>19.25450840049757</p>
              </div>
              <div className="col-md-4 form_input_main">
                <label htmlFor="">{t("Stop Longitude")}</label>
                <p>73.12744081020355</p>
              </div>
              <div className="col-md-12">
                <div className="transportMap stopmap">
                  <div className="custom-map-height2">
                  <MapComponent />
                  </div>
                  <div className="stop-tooltip-wrapper">
                    <OverlayTrigger
                      trigger="click"
                      key="bottom"
                      placement="bottom"
                      overlay={
                        <Popover
                          id="popover-positioned-bottom"
                          className="popover-main-wrapper"
                          style={{ width: "600px !important" }}
                        >
                          <Popover.Body className="pm-body">
                            <div className="popover-wrapper">
                              <div className="stop-overlay-top">
                                <p>{t("Add Stop")}</p>
                                <button
                                  onClick={() => {
                                    handleClick2();
                                  }}
                                >
                                  <img src={close_icon} alt="" />
                                </button>
                              </div>
                              <div className="pw-bottom">
                                <table className="pwb-table">
                                  <tbody>
                                    <tr>
                                      <td>{t("Stop Name")}</td>
                                      <td>:</td>
                                      <td>Kolhapur, Maharashtra</td>
                                    </tr>
                                    <tr>
                                      <td>{t("Stop Code")}</td>
                                      <td>:</td>
                                      <td>Kolhapur, Maharashtra</td>
                                    </tr>
                                    <tr>
                                      <td>{t("Add to POI")}</td>
                                      <td>:</td>
                                      <td>Yes</td>
                                    </tr>
                                    <tr>
                                      <td colSpan={3}></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div className="so-btn-wrapper">
                                <button className="cx-btn-2">
                                  {t("Add/Update Stop")}
                                </button>
                                <button
                                  className="cx-btn-1"
                                  onClick={handleShow}
                                >
                                  {t("Remove Stop")}
                                </button>
                              </div>
                            </div>
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <Button className="red-car-btn">
                        <img
                          src={stops_icon}
                          alt=""
                          className=""
                          onClick={() => {
                            handleClick();
                          }}
                        />
                      </Button>
                    </OverlayTrigger>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main-master-wrapper mt-3">
          <div className="Heading">
            <p>{t("Stop List")}</p>
          </div>
          <MyTableWrapper>
            <table className="table fixed_header Stop-details-table">
              <thead>
                <tr>
                  <th className="empty-th"></th>
                  <th>{t("Sr.No.")}</th>
                  <th>{t("Stop Name")}</th>
                  <th>{t("Stop Code")}</th>
                  <th>{t("POI")}</th>
                  <th>{t("Geofence Area")}</th>
                  <th>{t("Priority")}</th>
                </tr>
              </thead>
              <SortableCont
                onSortEnd={onSortEnd}
                axis="y"
                lockAxis="y"
                lockToContainerEdges={true}
                lockOffset={["30%", "50%"]}
                helperClass="helperContainerClass"
                useDragHandle={true}
              >
                {items.map((value, index) => (
                  <SortableItem
                    key={`item-${index}`}
                    index={index}
                    first={value.first}
                    second={value.second}
                    third={value.third}
                    fourth={value.fourth}
                    fifth={value.fifth}
                    sixth={value.sixth}
                  />
                ))}
              </SortableCont>
            </table>
          </MyTableWrapper>
        </div>
      </div>
      {/* Delete Trip Modal Start */}

      <Modal show={show} onHide={handleClose} centered className="common-model">
        <Modal.Header closeButton>
          <Modal.Title>{t("Delete Stop")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t("Are you sure you want to delete this Stop")} ?</Modal.Body>
        <Modal.Footer className="pop-up-modal-footer">
          <button className="cx-btn-1" onClick={handleClose}>
            {t("Close")}
          </button>
          <button className="cx-btn-2" onClick={handleClose}>
            {t("Yes")}
          </button>
        </Modal.Footer>
      </Modal>

      {/* Delete Trip Modal End */}
    </motion.div>
  );
};

export default ViewStop;
