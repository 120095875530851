// Usama 09-02-2023

import React, { useContext, useState } from "react";
import { Col, Dropdown, Modal, Nav, Tab, Tabs } from "react-bootstrap";
import { AppContext } from "../../../context/AppContext";
import DDlogo from "../../../assets/images/DDlogo.png";
import Import from "../../../assets/images/ic-Import.svg";
import Export from "../../../assets/images/ic-Export.svg";
import option from "../../../assets/images/option-three-dot.svg";
import Grouplogo from "../../../assets/images/Customer-profile.png";
import untracked_icon from "../../../assets/images/untracked_icon.svg";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import Pagenation from "../../../sharedComponent/Pagenation";
import { useTranslation } from "react-i18next";
import NextButton from "../../../sharedComponent/Nextbutton";
import ExistButton from "../../../sharedComponent/ExistButton";
import InfoComponent from "../../../sharedComponent/SupportHelp/InfoComponent";

const Drivers = ({ onClick }) => {
  const [show, setShow] = useState(false);
  const { t, i18n } = useTranslation();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const { sidebar, setSidebar, Dark, setDark, slider, setSlider } =
    useContext(AppContext);

  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  const navigate = useNavigate();
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    navigate("/AddDrivers");
  };
  const handleClickPrevious = () => {
    navigate("/FuelManagementDetails");
  };
  return (
    <motion.div
      className={sidebar ? "taskMain " : "cx-active taskMain"}
      id="cx-main"
      variants={aninations}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.1 }}
    >
      <div id="cx-wrapper">
        <Link to="/AddDrivers" className="d-flex justify-content-end mb-3">
          <button className="cx-btn-3 tbBtn">+ {t("Create Driver")}</button>
        </Link>
        <div id="cutomScroll">
          <div className="main-master-wrapper mb-0 inner-tabs-section tabs-custom-width-25 ">
            <div id="scroll_insideThe_Padding">
              <Tab.Container
                id="left-tabs-example"
                className="td-tab-wrapper"
                defaultActiveKey="0"
              >
                <Nav variant="pills" className="td-nav" id="InnerTabNew_Foure">
                  <Nav.Item className="td-tab">
                    <Nav.Link className="td-link" eventKey="0">
                      {t("All")}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="td-tab">
                    <Nav.Link className="td-link" eventKey="1">
                      {t("Online")}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="td-tab">
                    <Nav.Link className="td-link" eventKey="2">
                      {t("Offline")}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="td-tab">
                    <Nav.Link className="td-link" eventKey="3">
                      {t("Untracked")}
                    </Nav.Link>
                  </Nav.Item>
                </Nav>

                <Col sm={12} className="">
                  <Tab.Content>
                    <Tab.Pane eventKey="0">
                      <div className="all-vehicle-main">
                        <div className="all-vehical-head row vehicle-top-inputs">
                          <div className="input-section-wrapper">
                            <div className="row">
                              <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Vehicle Name,No., Reg. No, IMEI..."
                                />
                              </div>
                              <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Vehicle Category"
                                />
                              </div>
                              <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Vehicle Capacity"
                                />
                              </div>
                              <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                                <select
                                  required
                                  className="form-select"
                                  aria-label="Default select example"
                                  placeholder="Transportation Type"
                                >
                                  <option value="">Transportation Type</option>
                                  <option value="1">One</option>
                                  <option value="2">Two</option>
                                  <option value="3">Three</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="right-export-btn-section-wrapper">
                            <div className="c-pointer me-2">
                              <img src={Export} alt="" />
                            </div>
                            <div className="c-pointer">
                              <img src={Import} alt="" />
                            </div>
                          </div>
                        </div>
                        <div className="yauto" id="TransportMananger_height">
                          <div className="row gx-3 main-cards-wrapper">
                            <div
                              className={
                                sidebar
                                  ? "col-lg-4 col-md-6"
                                  : "col-lg-3 col-md-6"
                              }
                            >
                              <div className="common-vehical-card-inner p-0">
                                <div className="vehical-card-head vc-top">
                                  <div className="heading top-avatar-wrapper">
                                    <img
                                      src={Grouplogo}
                                      alt=""
                                      className="custom-Margin"
                                    />
                                    <div className="">
                                      <p className="sub-heading">
                                        {t("Driver Name")}
                                      </p>
                                      <p className="title">Mark S. Woods</p>
                                    </div>
                                  </div>
                                  <div className="option customer-option">
                                    <Dropdown>
                                      <Dropdown.Toggle id="dropdown-basic">
                                        <img src={option} alt="" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ViewDrivers"
                                            className="d-block"
                                          >
                                            {t("View")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/EditDrivers"
                                            className="d-block"
                                          >
                                            {t("Edit")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ChangeDriverPassword"
                                            className="d-block"
                                          >
                                            {t("Change Password")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow1}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Block")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow2}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Resign")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Delete")}
                                          </Link>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="vehical-card-body vc-body row g-0">
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Email")}</p>
                                    <p className="title">markwoods@gmail.com</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Contact No.")}
                                    </p>
                                    <p className="title">99999 99999</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Vehicle No.")}
                                    </p>
                                    <p className="title">MH-12-2023</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Vehicle Assignment")}.
                                    </p>
                                    <p className="title">Toyota Innova</p>
                                  </div>
                                  <div className="card-contain col-lg-12">
                                    <p className="sub-heading">
                                      {t("Address")}
                                    </p>
                                    <p className="title">
                                      Vishrantwadi, Kalas Road, Pune- 411030.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                sidebar
                                  ? "col-lg-4 col-md-6"
                                  : "col-lg-3 col-md-6"
                              }
                            >
                              <div className="common-vehical-card-inner p-0">
                                <div className="vehical-card-head vc-top">
                                  <div className="heading top-avatar-wrapper">
                                    <img
                                      src={Grouplogo}
                                      alt=""
                                      className="custom-Margin"
                                    />
                                    <div className="">
                                      <p className="sub-heading">
                                        {t("Driver Name")}
                                      </p>
                                      <p className="title">Mark S. Woods</p>
                                    </div>
                                  </div>
                                  <div className="option customer-option">
                                    <Dropdown>
                                      <Dropdown.Toggle id="dropdown-basic">
                                        <img src={option} alt="" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ViewDrivers"
                                            className="d-block"
                                          >
                                            {t("View")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/EditDrivers"
                                            className="d-block"
                                          >
                                            {t("Edit")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ChangeDriverPassword"
                                            className="d-block"
                                          >
                                            {t("Change Password")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow1}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Block")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow2}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Resign")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Delete")}
                                          </Link>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="vehical-card-body vc-body row g-0">
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Email")}</p>
                                    <p className="title">markwoods@gmail.com</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Contact No.")}
                                    </p>
                                    <p className="title">99999 99999</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Vehicle No.")}
                                    </p>
                                    <p className="title">MH-12-2023</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Vehicle Assignment")}.
                                    </p>
                                    <p className="title">Toyota Innova</p>
                                  </div>
                                  <div className="card-contain col-lg-12">
                                    <p className="sub-heading">
                                      {t("Address")}
                                    </p>
                                    <p className="title">
                                      Vishrantwadi, Kalas Road, Pune- 411030.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                sidebar
                                  ? "col-lg-4 col-md-6"
                                  : "col-lg-3 col-md-6"
                              }
                            >
                              <div className="common-vehical-card-inner p-0">
                                <div className="vehical-card-head vc-top">
                                  <div className="heading top-avatar-wrapper">
                                    <img
                                      src={Grouplogo}
                                      alt=""
                                      className="custom-Margin"
                                    />
                                    <div className="">
                                      <p className="sub-heading">
                                        {t("Driver Name")}
                                      </p>
                                      <p className="title">Mark S. Woods</p>
                                    </div>
                                  </div>
                                  <div className="option customer-option">
                                    <Dropdown>
                                      <Dropdown.Toggle id="dropdown-basic">
                                        <img src={option} alt="" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ViewDrivers"
                                            className="d-block"
                                          >
                                            {t("View")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/EditDrivers"
                                            className="d-block"
                                          >
                                            {t("Edit")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ChangeDriverPassword"
                                            className="d-block"
                                          >
                                            {t("Change Password")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow1}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Block")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow2}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Resign")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Delete")}
                                          </Link>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="vehical-card-body vc-body row g-0">
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Email")}</p>
                                    <p className="title">markwoods@gmail.com</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Contact No.")}
                                    </p>
                                    <p className="title">99999 99999</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Vehicle No.")}
                                    </p>
                                    <p className="title">MH-12-2023</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Vehicle Assignment")}.
                                    </p>
                                    <p className="title">Toyota Innova</p>
                                  </div>
                                  <div className="card-contain col-lg-12">
                                    <p className="sub-heading">
                                      {t("Address")}
                                    </p>
                                    <p className="title">
                                      Vishrantwadi, Kalas Road, Pune- 411030.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                sidebar
                                  ? "col-lg-4 col-md-6"
                                  : "col-lg-3 col-md-6"
                              }
                            >
                              <div className="common-vehical-card-inner p-0">
                                <div className="vehical-card-head vc-top">
                                  <div className="heading top-avatar-wrapper">
                                    <img
                                      src={Grouplogo}
                                      alt=""
                                      className="custom-Margin"
                                    />
                                    <div className="">
                                      <p className="sub-heading">
                                        {t("Driver Name")}
                                      </p>
                                      <p className="title">Mark S. Woods</p>
                                    </div>
                                  </div>
                                  <div className="option customer-option">
                                    <Dropdown>
                                      <Dropdown.Toggle id="dropdown-basic">
                                        <img src={option} alt="" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ViewDrivers"
                                            className="d-block"
                                          >
                                            {t("View")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/EditDrivers"
                                            className="d-block"
                                          >
                                            {t("Edit")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ChangeDriverPassword"
                                            className="d-block"
                                          >
                                            {t("Change Password")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow1}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Block")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow2}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Resign")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Delete")}
                                          </Link>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="vehical-card-body vc-body row g-0">
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Email")}</p>
                                    <p className="title">markwoods@gmail.com</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Contact No.")}
                                    </p>
                                    <p className="title">99999 99999</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Vehicle No.")}
                                    </p>
                                    <p className="title">MH-12-2023</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Vehicle Assignment")}.
                                    </p>
                                    <p className="title">Toyota Innova</p>
                                  </div>
                                  <div className="card-contain col-lg-12">
                                    <p className="sub-heading">
                                      {t("Address")}
                                    </p>
                                    <p className="title">
                                      Vishrantwadi, Kalas Road, Pune- 411030.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                sidebar
                                  ? "col-lg-4 col-md-6"
                                  : "col-lg-3 col-md-6"
                              }
                            >
                              <div className="common-vehical-card-inner p-0">
                                <div className="vehical-card-head vc-top">
                                  <div className="heading top-avatar-wrapper">
                                    <img
                                      src={Grouplogo}
                                      alt=""
                                      className="custom-Margin"
                                    />
                                    <div className="">
                                      <p className="sub-heading">
                                        {t("Driver Name")}
                                      </p>
                                      <p className="title">Mark S. Woods</p>
                                    </div>
                                  </div>
                                  <div className="option customer-option">
                                    <Dropdown>
                                      <Dropdown.Toggle id="dropdown-basic">
                                        <img src={option} alt="" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ViewDrivers"
                                            className="d-block"
                                          >
                                            {t("View")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/EditDrivers"
                                            className="d-block"
                                          >
                                            {t("Edit")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ChangeDriverPassword"
                                            className="d-block"
                                          >
                                            {t("Change Password")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow1}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Block")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow2}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Resign")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Delete")}
                                          </Link>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="vehical-card-body vc-body row g-0">
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Email")}</p>
                                    <p className="title">markwoods@gmail.com</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Contact No.")}
                                    </p>
                                    <p className="title">99999 99999</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Vehicle No.")}
                                    </p>
                                    <p className="title">MH-12-2023</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Vehicle Assignment")}.
                                    </p>
                                    <p className="title">Toyota Innova</p>
                                  </div>
                                  <div className="card-contain col-lg-12">
                                    <p className="sub-heading">
                                      {t("Address")}
                                    </p>
                                    <p className="title">
                                      Vishrantwadi, Kalas Road, Pune- 411030.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                sidebar
                                  ? "col-lg-4 col-md-6"
                                  : "col-lg-3 col-md-6"
                              }
                            >
                              <div className="common-vehical-card-inner p-0">
                                <div className="vehical-card-head vc-top">
                                  <div className="heading top-avatar-wrapper">
                                    <img
                                      src={Grouplogo}
                                      alt=""
                                      className="custom-Margin"
                                    />
                                    <div className="">
                                      <p className="sub-heading">
                                        {t("Driver Name")}
                                      </p>
                                      <p className="title">Mark S. Woods</p>
                                    </div>
                                  </div>
                                  <div className="option customer-option">
                                    <Dropdown>
                                      <Dropdown.Toggle id="dropdown-basic">
                                        <img src={option} alt="" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ViewDrivers"
                                            className="d-block"
                                          >
                                            {t("View")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/EditDrivers"
                                            className="d-block"
                                          >
                                            {t("Edit")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ChangeDriverPassword"
                                            className="d-block"
                                          >
                                            {t("Change Password")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow1}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Block")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow2}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Resign")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Delete")}
                                          </Link>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="vehical-card-body vc-body row g-0">
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Email")}</p>
                                    <p className="title">markwoods@gmail.com</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Contact No.")}
                                    </p>
                                    <p className="title">99999 99999</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Vehicle No.")}
                                    </p>
                                    <p className="title">MH-12-2023</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Vehicle Assignment")}.
                                    </p>
                                    <p className="title">Toyota Innova</p>
                                  </div>
                                  <div className="card-contain col-lg-12">
                                    <p className="sub-heading">
                                      {t("Address")}
                                    </p>
                                    <p className="title">
                                      Vishrantwadi, Kalas Road, Pune- 411030.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                sidebar
                                  ? "col-lg-4 col-md-6"
                                  : "col-lg-3 col-md-6"
                              }
                            >
                              <div className="common-vehical-card-inner p-0">
                                <div className="vehical-card-head vc-top">
                                  <div className="heading top-avatar-wrapper">
                                    <img
                                      src={Grouplogo}
                                      alt=""
                                      className="custom-Margin"
                                    />
                                    <div className="">
                                      <p className="sub-heading">
                                        {t("Driver Name")}
                                      </p>
                                      <p className="title">Mark S. Woods</p>
                                    </div>
                                  </div>
                                  <div className="option customer-option">
                                    <Dropdown>
                                      <Dropdown.Toggle id="dropdown-basic">
                                        <img src={option} alt="" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ViewDrivers"
                                            className="d-block"
                                          >
                                            {t("View")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/EditDrivers"
                                            className="d-block"
                                          >
                                            {t("Edit")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ChangeDriverPassword"
                                            className="d-block"
                                          >
                                            {t("Change Password")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow1}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Block")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow2}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Resign")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Delete")}
                                          </Link>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="vehical-card-body vc-body row g-0">
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Email")}</p>
                                    <p className="title">markwoods@gmail.com</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Contact No.")}
                                    </p>
                                    <p className="title">99999 99999</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Vehicle No.")}
                                    </p>
                                    <p className="title">MH-12-2023</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Vehicle Assignment")}.
                                    </p>
                                    <p className="title">Toyota Innova</p>
                                  </div>
                                  <div className="card-contain col-lg-12">
                                    <p className="sub-heading">
                                      {t("Address")}
                                    </p>
                                    <p className="title">
                                      Vishrantwadi, Kalas Road, Pune- 411030.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                sidebar
                                  ? "col-lg-4 col-md-6"
                                  : "col-lg-3 col-md-6"
                              }
                            >
                              <div className="common-vehical-card-inner p-0">
                                <div className="vehical-card-head vc-top">
                                  <div className="heading top-avatar-wrapper">
                                    <img
                                      src={Grouplogo}
                                      alt=""
                                      className="custom-Margin"
                                    />
                                    <div className="">
                                      <p className="sub-heading">
                                        {t("Driver Name")}
                                      </p>
                                      <p className="title">Mark S. Woods</p>
                                    </div>
                                  </div>
                                  <div className="option customer-option">
                                    <Dropdown>
                                      <Dropdown.Toggle id="dropdown-basic">
                                        <img src={option} alt="" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ViewDrivers"
                                            className="d-block"
                                          >
                                            {t("View")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/EditDrivers"
                                            className="d-block"
                                          >
                                            {t("Edit")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ChangeDriverPassword"
                                            className="d-block"
                                          >
                                            {t("Change Password")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow1}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Block")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow2}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Resign")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Delete")}
                                          </Link>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="vehical-card-body vc-body row g-0">
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Email")}</p>
                                    <p className="title">markwoods@gmail.com</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Contact No.")}
                                    </p>
                                    <p className="title">99999 99999</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Vehicle No.")}
                                    </p>
                                    <p className="title">MH-12-2023</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Vehicle Assignment")}.
                                    </p>
                                    <p className="title">Toyota Innova</p>
                                  </div>
                                  <div className="card-contain col-lg-12">
                                    <p className="sub-heading">
                                      {t("Address")}
                                    </p>
                                    <p className="title">
                                      Vishrantwadi, Kalas Road, Pune- 411030.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="1">
                      <div className="all-vehicle-main">
                        <div className="all-vehical-head row vehicle-top-inputs">
                          <div className="input-section-wrapper">
                            <div className="row">
                              <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Vehicle Name,No., Reg. No, IMEI..."
                                />
                              </div>
                              <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Vehicle Category"
                                />
                              </div>
                              <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Vehicle Capacity"
                                />
                              </div>
                              <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                                <select
                                  required
                                  className="form-select"
                                  aria-label="Default select example"
                                  placeholder="Transportation Type"
                                >
                                  <option value="">Transportation Type</option>
                                  <option value="1">One</option>
                                  <option value="2">Two</option>
                                  <option value="3">Three</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="right-export-btn-section-wrapper">
                            <div className="c-pointer me-2">
                              <img src={Export} alt="" />
                            </div>
                            <div className="c-pointer">
                              <img src={Import} alt="" />
                            </div>
                          </div>
                        </div>
                        <div className="yauto" id="TransportMananger_height">
                          <div className="row gx-3 main-cards-wrapper">
                            <div
                              className={
                                sidebar
                                  ? "col-lg-4 col-md-6"
                                  : "col-lg-3 col-md-6"
                              }
                            >
                              <div className="common-vehical-card-inner p-0">
                                <div className="vehical-card-head vc-top">
                                  <div className="heading top-avatar-wrapper">
                                    <img
                                      src={Grouplogo}
                                      alt=""
                                      className="custom-Margin"
                                    />
                                    <div className="">
                                      <p className="sub-heading">
                                        {t("Driver Name")}
                                      </p>
                                      <p className="title">Mark S. Woods</p>
                                    </div>
                                  </div>
                                  <div className="option customer-option">
                                    <Dropdown>
                                      <Dropdown.Toggle id="dropdown-basic">
                                        <img src={option} alt="" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ViewDrivers"
                                            className="d-block"
                                          >
                                            {t("View")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/EditDrivers"
                                            className="d-block"
                                          >
                                            {t("Edit")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ChangeDriverPassword"
                                            className="d-block"
                                          >
                                            {t("Change Password")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow1}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Block")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow2}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Resign")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Delete")}
                                          </Link>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="vehical-card-body vc-body row g-0">
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Email")}</p>
                                    <p className="title">markwoods@gmail.com</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Contact No.")}
                                    </p>
                                    <p className="title">99999 99999</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Vehicle No.")}
                                    </p>
                                    <p className="title">MH-12-2023</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Vehicle Assignment")}.
                                    </p>
                                    <p className="title">Toyota Innova</p>
                                  </div>
                                  <div className="card-contain col-lg-12">
                                    <p className="sub-heading">
                                      {t("Address")}
                                    </p>
                                    <p className="title">
                                      Vishrantwadi, Kalas Road, Pune- 411030.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                sidebar
                                  ? "col-lg-4 col-md-6"
                                  : "col-lg-3 col-md-6"
                              }
                            >
                              <div className="common-vehical-card-inner p-0">
                                <div className="vehical-card-head vc-top">
                                  <div className="heading top-avatar-wrapper">
                                    <img
                                      src={Grouplogo}
                                      alt=""
                                      className="custom-Margin"
                                    />
                                    <div className="">
                                      <p className="sub-heading">
                                        {t("Driver Name")}
                                      </p>
                                      <p className="title">Mark S. Woods</p>
                                    </div>
                                  </div>
                                  <div className="option customer-option">
                                    <Dropdown>
                                      <Dropdown.Toggle id="dropdown-basic">
                                        <img src={option} alt="" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ViewDrivers"
                                            className="d-block"
                                          >
                                            {t("View")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/EditDrivers"
                                            className="d-block"
                                          >
                                            {t("Edit")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ChangeDriverPassword"
                                            className="d-block"
                                          >
                                            {t("Change Password")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow1}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Block")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow2}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Resign")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Delete")}
                                          </Link>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="vehical-card-body vc-body row g-0">
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Email")}</p>
                                    <p className="title">markwoods@gmail.com</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Contact No.")}
                                    </p>
                                    <p className="title">99999 99999</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Vehicle No.")}
                                    </p>
                                    <p className="title">MH-12-2023</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Vehicle Assignment")}.
                                    </p>
                                    <p className="title">Toyota Innova</p>
                                  </div>
                                  <div className="card-contain col-lg-12">
                                    <p className="sub-heading">
                                      {t("Address")}
                                    </p>
                                    <p className="title">
                                      Vishrantwadi, Kalas Road, Pune- 411030.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                sidebar
                                  ? "col-lg-4 col-md-6"
                                  : "col-lg-3 col-md-6"
                              }
                            >
                              <div className="common-vehical-card-inner p-0">
                                <div className="vehical-card-head vc-top">
                                  <div className="heading top-avatar-wrapper">
                                    <img
                                      src={Grouplogo}
                                      alt=""
                                      className="custom-Margin"
                                    />
                                    <div className="">
                                      <p className="sub-heading">
                                        {t("Driver Name")}
                                      </p>
                                      <p className="title">Mark S. Woods</p>
                                    </div>
                                  </div>
                                  <div className="option customer-option">
                                    <Dropdown>
                                      <Dropdown.Toggle id="dropdown-basic">
                                        <img src={option} alt="" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ViewDrivers"
                                            className="d-block"
                                          >
                                            {t("View")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/EditDrivers"
                                            className="d-block"
                                          >
                                            {t("Edit")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ChangeDriverPassword"
                                            className="d-block"
                                          >
                                            {t("Change Password")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow1}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Block")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow2}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Resign")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Delete")}
                                          </Link>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="vehical-card-body vc-body row g-0">
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Email")}</p>
                                    <p className="title">markwoods@gmail.com</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Contact No.")}
                                    </p>
                                    <p className="title">99999 99999</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Vehicle No.")}
                                    </p>
                                    <p className="title">MH-12-2023</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Vehicle Assignment")}.
                                    </p>
                                    <p className="title">Toyota Innova</p>
                                  </div>
                                  <div className="card-contain col-lg-12">
                                    <p className="sub-heading">
                                      {t("Address")}
                                    </p>
                                    <p className="title">
                                      Vishrantwadi, Kalas Road, Pune- 411030.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                sidebar
                                  ? "col-lg-4 col-md-6"
                                  : "col-lg-3 col-md-6"
                              }
                            >
                              <div className="common-vehical-card-inner p-0">
                                <div className="vehical-card-head vc-top">
                                  <div className="heading top-avatar-wrapper">
                                    <img
                                      src={Grouplogo}
                                      alt=""
                                      className="custom-Margin"
                                    />
                                    <div className="">
                                      <p className="sub-heading">
                                        {t("Driver Name")}
                                      </p>
                                      <p className="title">Mark S. Woods</p>
                                    </div>
                                  </div>
                                  <div className="option customer-option">
                                    <Dropdown>
                                      <Dropdown.Toggle id="dropdown-basic">
                                        <img src={option} alt="" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ViewDrivers"
                                            className="d-block"
                                          >
                                            {t("View")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/EditDrivers"
                                            className="d-block"
                                          >
                                            {t("Edit")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ChangeDriverPassword"
                                            className="d-block"
                                          >
                                            {t("Change Password")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow1}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Block")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow2}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Resign")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Delete")}
                                          </Link>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="vehical-card-body vc-body row g-0">
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Email")}</p>
                                    <p className="title">markwoods@gmail.com</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Contact No.")}
                                    </p>
                                    <p className="title">99999 99999</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Vehicle No.")}
                                    </p>
                                    <p className="title">MH-12-2023</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Vehicle Assignment")}.
                                    </p>
                                    <p className="title">Toyota Innova</p>
                                  </div>
                                  <div className="card-contain col-lg-12">
                                    <p className="sub-heading">
                                      {t("Address")}
                                    </p>
                                    <p className="title">
                                      Vishrantwadi, Kalas Road, Pune- 411030.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                sidebar
                                  ? "col-lg-4 col-md-6"
                                  : "col-lg-3 col-md-6"
                              }
                            >
                              <div className="common-vehical-card-inner p-0">
                                <div className="vehical-card-head vc-top">
                                  <div className="heading top-avatar-wrapper">
                                    <img
                                      src={Grouplogo}
                                      alt=""
                                      className="custom-Margin"
                                    />
                                    <div className="">
                                      <p className="sub-heading">
                                        {t("Driver Name")}
                                      </p>
                                      <p className="title">Mark S. Woods</p>
                                    </div>
                                  </div>
                                  <div className="option customer-option">
                                    <Dropdown>
                                      <Dropdown.Toggle id="dropdown-basic">
                                        <img src={option} alt="" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ViewDrivers"
                                            className="d-block"
                                          >
                                            {t("View")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/EditDrivers"
                                            className="d-block"
                                          >
                                            {t("Edit")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ChangeDriverPassword"
                                            className="d-block"
                                          >
                                            {t("Change Password")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow1}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Block")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow2}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Resign")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Delete")}
                                          </Link>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="vehical-card-body vc-body row g-0">
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Email")}</p>
                                    <p className="title">markwoods@gmail.com</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Contact No.")}
                                    </p>
                                    <p className="title">99999 99999</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Vehicle No.")}
                                    </p>
                                    <p className="title">MH-12-2023</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Vehicle Assignment")}.
                                    </p>
                                    <p className="title">Toyota Innova</p>
                                  </div>
                                  <div className="card-contain col-lg-12">
                                    <p className="sub-heading">
                                      {t("Address")}
                                    </p>
                                    <p className="title">
                                      Vishrantwadi, Kalas Road, Pune- 411030.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                sidebar
                                  ? "col-lg-4 col-md-6"
                                  : "col-lg-3 col-md-6"
                              }
                            >
                              <div className="common-vehical-card-inner p-0">
                                <div className="vehical-card-head vc-top">
                                  <div className="heading top-avatar-wrapper">
                                    <img
                                      src={Grouplogo}
                                      alt=""
                                      className="custom-Margin"
                                    />
                                    <div className="">
                                      <p className="sub-heading">
                                        {t("Driver Name")}
                                      </p>
                                      <p className="title">Mark S. Woods</p>
                                    </div>
                                  </div>
                                  <div className="option customer-option">
                                    <Dropdown>
                                      <Dropdown.Toggle id="dropdown-basic">
                                        <img src={option} alt="" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ViewDrivers"
                                            className="d-block"
                                          >
                                            {t("View")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/EditDrivers"
                                            className="d-block"
                                          >
                                            {t("Edit")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ChangeDriverPassword"
                                            className="d-block"
                                          >
                                            {t("Change Password")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow1}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Block")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow2}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Resign")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Delete")}
                                          </Link>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="vehical-card-body vc-body row g-0">
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Email")}</p>
                                    <p className="title">markwoods@gmail.com</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Contact No.")}
                                    </p>
                                    <p className="title">99999 99999</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Vehicle No.")}
                                    </p>
                                    <p className="title">MH-12-2023</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Vehicle Assignment")}.
                                    </p>
                                    <p className="title">Toyota Innova</p>
                                  </div>
                                  <div className="card-contain col-lg-12">
                                    <p className="sub-heading">
                                      {t("Address")}
                                    </p>
                                    <p className="title">
                                      Vishrantwadi, Kalas Road, Pune- 411030.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                sidebar
                                  ? "col-lg-4 col-md-6"
                                  : "col-lg-3 col-md-6"
                              }
                            >
                              <div className="common-vehical-card-inner p-0">
                                <div className="vehical-card-head vc-top">
                                  <div className="heading top-avatar-wrapper">
                                    <img
                                      src={Grouplogo}
                                      alt=""
                                      className="custom-Margin"
                                    />
                                    <div className="">
                                      <p className="sub-heading">
                                        {t("Driver Name")}
                                      </p>
                                      <p className="title">Mark S. Woods</p>
                                    </div>
                                  </div>
                                  <div className="option customer-option">
                                    <Dropdown>
                                      <Dropdown.Toggle id="dropdown-basic">
                                        <img src={option} alt="" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ViewDrivers"
                                            className="d-block"
                                          >
                                            {t("View")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/EditDrivers"
                                            className="d-block"
                                          >
                                            {t("Edit")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ChangeDriverPassword"
                                            className="d-block"
                                          >
                                            {t("Change Password")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow1}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Block")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow2}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Resign")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Delete")}
                                          </Link>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="vehical-card-body vc-body row g-0">
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Email")}</p>
                                    <p className="title">markwoods@gmail.com</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Contact No.")}
                                    </p>
                                    <p className="title">99999 99999</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Vehicle No.")}
                                    </p>
                                    <p className="title">MH-12-2023</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Vehicle Assignment")}.
                                    </p>
                                    <p className="title">Toyota Innova</p>
                                  </div>
                                  <div className="card-contain col-lg-12">
                                    <p className="sub-heading">
                                      {t("Address")}
                                    </p>
                                    <p className="title">
                                      Vishrantwadi, Kalas Road, Pune- 411030.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="2">
                      <div className="all-vehicle-main">
                        <div className="all-vehical-head row vehicle-top-inputs">
                          <div className="input-section-wrapper">
                            <div className="row">
                              <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Vehicle Name,No., Reg. No, IMEI..."
                                />
                              </div>
                              <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Vehicle Category"
                                />
                              </div>
                              <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Vehicle Capacity"
                                />
                              </div>
                              <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                                <select
                                  required
                                  className="form-select"
                                  aria-label="Default select example"
                                  placeholder="Transportation Type"
                                >
                                  <option value="">Transportation Type</option>
                                  <option value="1">One</option>
                                  <option value="2">Two</option>
                                  <option value="3">Three</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="right-export-btn-section-wrapper">
                            <div className="c-pointer me-2">
                              <img src={Export} alt="" />
                            </div>
                            <div className="c-pointer">
                              <img src={Import} alt="" />
                            </div>
                          </div>
                        </div>
                        <div className="yauto" id="TransportMananger_height">
                          <div
                            className="row gx-3 main-cards-wrapper p-none"
                            style={{ opacity: "0.5" }}
                          >
                            <div
                              className={
                                sidebar
                                  ? "col-lg-4 col-md-6"
                                  : "col-lg-3 col-md-6"
                              }
                            >
                              <div className="common-vehical-card-inner p-0">
                                <div className="vehical-card-head vc-top">
                                  <div className="heading top-avatar-wrapper">
                                    <img
                                      src={Grouplogo}
                                      alt=""
                                      className="custom-Margin"
                                    />
                                    <div className="">
                                      <p className="sub-heading">
                                        {t("Driver Name")}
                                      </p>
                                      <p className="title">Mark S. Woods</p>
                                    </div>
                                  </div>
                                  <div className="option customer-option">
                                    <Dropdown>
                                      <Dropdown.Toggle id="dropdown-basic">
                                        <img src={option} alt="" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ViewDrivers"
                                            className="d-block"
                                          >
                                            {t("View")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/EditDrivers"
                                            className="d-block"
                                          >
                                            {t("Edit")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ChangeDriverPassword"
                                            className="d-block"
                                          >
                                            {t("Change Password")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow1}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Block")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow2}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Resign")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Delete")}
                                          </Link>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="vehical-card-body vc-body row g-0">
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Email")}</p>
                                    <p className="title">markwoods@gmail.com</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Contact No.")}
                                    </p>
                                    <p className="title">99999 99999</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Vehicle No.")}
                                    </p>
                                    <p className="title">MH-12-2023</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Vehicle Assignment")}.
                                    </p>
                                    <p className="title">Toyota Innova</p>
                                  </div>
                                  <div className="card-contain col-lg-12">
                                    <p className="sub-heading">
                                      {t("Address")}
                                    </p>
                                    <p className="title">
                                      Vishrantwadi, Kalas Road, Pune- 411030.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                sidebar
                                  ? "col-lg-4 col-md-6"
                                  : "col-lg-3 col-md-6"
                              }
                            >
                              <div className="common-vehical-card-inner p-0">
                                <div className="vehical-card-head vc-top">
                                  <div className="heading top-avatar-wrapper">
                                    <img
                                      src={Grouplogo}
                                      alt=""
                                      className="custom-Margin"
                                    />
                                    <div className="">
                                      <p className="sub-heading">
                                        {t("Driver Name")}
                                      </p>
                                      <p className="title">Mark S. Woods</p>
                                    </div>
                                  </div>
                                  <div className="option customer-option">
                                    <Dropdown>
                                      <Dropdown.Toggle id="dropdown-basic">
                                        <img src={option} alt="" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ViewDrivers"
                                            className="d-block"
                                          >
                                            {t("View")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/EditDrivers"
                                            className="d-block"
                                          >
                                            {t("Edit")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ChangeDriverPassword"
                                            className="d-block"
                                          >
                                            {t("Change Password")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow1}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Block")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow2}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Resign")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Delete")}
                                          </Link>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="vehical-card-body vc-body row g-0">
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Email")}</p>
                                    <p className="title">markwoods@gmail.com</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Contact No.")}
                                    </p>
                                    <p className="title">99999 99999</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Vehicle No.")}
                                    </p>
                                    <p className="title">MH-12-2023</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Vehicle Assignment")}.
                                    </p>
                                    <p className="title">Toyota Innova</p>
                                  </div>
                                  <div className="card-contain col-lg-12">
                                    <p className="sub-heading">
                                      {t("Address")}
                                    </p>
                                    <p className="title">
                                      Vishrantwadi, Kalas Road, Pune- 411030.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                sidebar
                                  ? "col-lg-4 col-md-6"
                                  : "col-lg-3 col-md-6"
                              }
                            >
                              <div className="common-vehical-card-inner p-0">
                                <div className="vehical-card-head vc-top">
                                  <div className="heading top-avatar-wrapper">
                                    <img
                                      src={Grouplogo}
                                      alt=""
                                      className="custom-Margin"
                                    />
                                    <div className="">
                                      <p className="sub-heading">
                                        {t("Driver Name")}
                                      </p>
                                      <p className="title">Mark S. Woods</p>
                                    </div>
                                  </div>
                                  <div className="option customer-option">
                                    <Dropdown>
                                      <Dropdown.Toggle id="dropdown-basic">
                                        <img src={option} alt="" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ViewDrivers"
                                            className="d-block"
                                          >
                                            {t("View")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/EditDrivers"
                                            className="d-block"
                                          >
                                            {t("Edit")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ChangeDriverPassword"
                                            className="d-block"
                                          >
                                            {t("Change Password")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow1}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Block")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow2}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Resign")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Delete")}
                                          </Link>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="vehical-card-body vc-body row g-0">
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Email")}</p>
                                    <p className="title">markwoods@gmail.com</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Contact No.")}
                                    </p>
                                    <p className="title">99999 99999</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Vehicle No.")}
                                    </p>
                                    <p className="title">MH-12-2023</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Vehicle Assignment")}.
                                    </p>
                                    <p className="title">Toyota Innova</p>
                                  </div>
                                  <div className="card-contain col-lg-12">
                                    <p className="sub-heading">
                                      {t("Address")}
                                    </p>
                                    <p className="title">
                                      Vishrantwadi, Kalas Road, Pune- 411030.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                sidebar
                                  ? "col-lg-4 col-md-6"
                                  : "col-lg-3 col-md-6"
                              }
                            >
                              <div className="common-vehical-card-inner p-0">
                                <div className="vehical-card-head vc-top">
                                  <div className="heading top-avatar-wrapper">
                                    <img
                                      src={Grouplogo}
                                      alt=""
                                      className="custom-Margin"
                                    />
                                    <div className="">
                                      <p className="sub-heading">
                                        {t("Driver Name")}
                                      </p>
                                      <p className="title">Mark S. Woods</p>
                                    </div>
                                  </div>
                                  <div className="option customer-option">
                                    <Dropdown>
                                      <Dropdown.Toggle id="dropdown-basic">
                                        <img src={option} alt="" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ViewDrivers"
                                            className="d-block"
                                          >
                                            {t("View")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/EditDrivers"
                                            className="d-block"
                                          >
                                            {t("Edit")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ChangeDriverPassword"
                                            className="d-block"
                                          >
                                            {t("Change Password")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow1}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Block")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow2}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Resign")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Delete")}
                                          </Link>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="vehical-card-body vc-body row g-0">
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Email")}</p>
                                    <p className="title">markwoods@gmail.com</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Contact No.")}
                                    </p>
                                    <p className="title">99999 99999</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Vehicle No.")}
                                    </p>
                                    <p className="title">MH-12-2023</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Vehicle Assignment")}.
                                    </p>
                                    <p className="title">Toyota Innova</p>
                                  </div>
                                  <div className="card-contain col-lg-12">
                                    <p className="sub-heading">
                                      {t("Address")}
                                    </p>
                                    <p className="title">
                                      Vishrantwadi, Kalas Road, Pune- 411030.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                sidebar
                                  ? "col-lg-4 col-md-6"
                                  : "col-lg-3 col-md-6"
                              }
                            >
                              <div className="common-vehical-card-inner p-0">
                                <div className="vehical-card-head vc-top">
                                  <div className="heading top-avatar-wrapper">
                                    <img
                                      src={Grouplogo}
                                      alt=""
                                      className="custom-Margin"
                                    />
                                    <div className="">
                                      <p className="sub-heading">
                                        {t("Driver Name")}
                                      </p>
                                      <p className="title">Mark S. Woods</p>
                                    </div>
                                  </div>
                                  <div className="option customer-option">
                                    <Dropdown>
                                      <Dropdown.Toggle id="dropdown-basic">
                                        <img src={option} alt="" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ViewDrivers"
                                            className="d-block"
                                          >
                                            {t("View")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/EditDrivers"
                                            className="d-block"
                                          >
                                            {t("Edit")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ChangeDriverPassword"
                                            className="d-block"
                                          >
                                            {t("Change Password")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow1}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Block")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow2}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Resign")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Delete")}
                                          </Link>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="vehical-card-body vc-body row g-0">
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Email")}</p>
                                    <p className="title">markwoods@gmail.com</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Contact No.")}
                                    </p>
                                    <p className="title">99999 99999</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Vehicle No.")}
                                    </p>
                                    <p className="title">MH-12-2023</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Vehicle Assignment")}.
                                    </p>
                                    <p className="title">Toyota Innova</p>
                                  </div>
                                  <div className="card-contain col-lg-12">
                                    <p className="sub-heading">
                                      {t("Address")}
                                    </p>
                                    <p className="title">
                                      Vishrantwadi, Kalas Road, Pune- 411030.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                sidebar
                                  ? "col-lg-4 col-md-6"
                                  : "col-lg-3 col-md-6"
                              }
                            >
                              <div className="common-vehical-card-inner p-0">
                                <div className="vehical-card-head vc-top">
                                  <div className="heading top-avatar-wrapper">
                                    <img
                                      src={Grouplogo}
                                      alt=""
                                      className="custom-Margin"
                                    />
                                    <div className="">
                                      <p className="sub-heading">
                                        {t("Driver Name")}
                                      </p>
                                      <p className="title">Mark S. Woods</p>
                                    </div>
                                  </div>
                                  <div className="option customer-option">
                                    <Dropdown>
                                      <Dropdown.Toggle id="dropdown-basic">
                                        <img src={option} alt="" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ViewDrivers"
                                            className="d-block"
                                          >
                                            {t("View")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/EditDrivers"
                                            className="d-block"
                                          >
                                            {t("Edit")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ChangeDriverPassword"
                                            className="d-block"
                                          >
                                            {t("Change Password")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow1}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Block")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow2}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Resign")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Delete")}
                                          </Link>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="vehical-card-body vc-body row g-0">
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Email")}</p>
                                    <p className="title">markwoods@gmail.com</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Contact No.")}
                                    </p>
                                    <p className="title">99999 99999</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Vehicle No.")}
                                    </p>
                                    <p className="title">MH-12-2023</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Vehicle Assignment")}.
                                    </p>
                                    <p className="title">Toyota Innova</p>
                                  </div>
                                  <div className="card-contain col-lg-12">
                                    <p className="sub-heading">
                                      {t("Address")}
                                    </p>
                                    <p className="title">
                                      Vishrantwadi, Kalas Road, Pune- 411030.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                sidebar
                                  ? "col-lg-4 col-md-6"
                                  : "col-lg-3 col-md-6"
                              }
                            >
                              <div className="common-vehical-card-inner p-0">
                                <div className="vehical-card-head vc-top">
                                  <div className="heading top-avatar-wrapper">
                                    <img
                                      src={Grouplogo}
                                      alt=""
                                      className="custom-Margin"
                                    />
                                    <div className="">
                                      <p className="sub-heading">
                                        {t("Driver Name")}
                                      </p>
                                      <p className="title">Mark S. Woods</p>
                                    </div>
                                  </div>
                                  <div className="option customer-option">
                                    <Dropdown>
                                      <Dropdown.Toggle id="dropdown-basic">
                                        <img src={option} alt="" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ViewDrivers"
                                            className="d-block"
                                          >
                                            {t("View")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/EditDrivers"
                                            className="d-block"
                                          >
                                            {t("Edit")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ChangeDriverPassword"
                                            className="d-block"
                                          >
                                            {t("Change Password")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow1}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Block")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow2}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Resign")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Delete")}
                                          </Link>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="vehical-card-body vc-body row g-0">
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Email")}</p>
                                    <p className="title">markwoods@gmail.com</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Contact No.")}
                                    </p>
                                    <p className="title">99999 99999</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Vehicle No.")}
                                    </p>
                                    <p className="title">MH-12-2023</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Vehicle Assignment")}.
                                    </p>
                                    <p className="title">Toyota Innova</p>
                                  </div>
                                  <div className="card-contain col-lg-12">
                                    <p className="sub-heading">
                                      {t("Address")}
                                    </p>
                                    <p className="title">
                                      Vishrantwadi, Kalas Road, Pune- 411030.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="3">
                      <div className="all-vehicle-main">
                        <div className="all-vehical-head row vehicle-top-inputs">
                          <div className="input-section-wrapper">
                            <div className="row">
                              <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Vehicle Name,No., Reg. No, IMEI..."
                                />
                              </div>
                              <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Vehicle Category"
                                />
                              </div>
                              <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Vehicle Capacity"
                                />
                              </div>
                              <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                                <select
                                  required
                                  className="form-select"
                                  aria-label="Default select example"
                                  placeholder="Transportation Type"
                                >
                                  <option value="">Transportation Type</option>
                                  <option value="1">One</option>
                                  <option value="2">Two</option>
                                  <option value="3">Three</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="right-export-btn-section-wrapper">
                            <div className="c-pointer me-2">
                              <img src={Export} alt="" />
                            </div>
                            <div className="c-pointer">
                              <img src={Import} alt="" />
                            </div>
                          </div>
                        </div>
                        <div className="yauto" id="TransportMananger_height">
                          <div className="row gx-3 main-cards-wrapper p-none">
                            <div
                              className={
                                sidebar
                                  ? "col-lg-4 col-md-6"
                                  : "col-lg-3 col-md-6"
                              }
                            >
                              <div
                                className={
                                  "common-vehical-card-inner cv-card p-0"
                                }
                              >
                                <div className="untracked-overlay">
                                  <div className="untracked-img">
                                    <img src={untracked_icon} alt="" />
                                  </div>
                                </div>
                                <div className="vehical-card-head vc-top">
                                  <div className="heading top-avatar-wrapper">
                                    <img
                                      src={Grouplogo}
                                      alt=""
                                      className="custom-Margin"
                                    />
                                    <div className="">
                                      <p className="sub-heading">
                                        {t("Driver Name")}
                                      </p>
                                      <p className="title">Mark S. Woods</p>
                                    </div>
                                  </div>
                                  <div className="option customer-option">
                                    <Dropdown>
                                      <Dropdown.Toggle id="dropdown-basic">
                                        <img src={option} alt="" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item>
                                          <Link to="/ViewDrivers">
                                            {t("View")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link to="/EditDrivers">
                                            {t("Edit")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link to="/ChangeDriverPassword">
                                            {t("Change Password")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow1}
                                        >
                                          <Link to="#">{t("Block")}</Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow2}
                                        >
                                          <Link to="#">{t("Resign")}</Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow}
                                        >
                                          <Link to="#">{t("Delete")}</Link>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="vehical-card-body vc-body row g-0">
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Email")}</p>
                                    <p className="title">markwoods@gmail.com</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Contact No.")}
                                    </p>
                                    <p className="title">99999 99999</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Vehicle No.")}
                                    </p>
                                    <p className="title">MH-12-2023</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Vehicle Assignment")}.
                                    </p>
                                    <p className="title">Toyota Innova</p>
                                  </div>
                                  <div className="card-contain col-lg-12">
                                    <p className="sub-heading">
                                      {t("Address")}
                                    </p>
                                    <p className="title">
                                      Vishrantwadi, Kalas Road, Pune- 411030.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                sidebar
                                  ? "col-lg-4 col-md-6"
                                  : "col-lg-3 col-md-6"
                              }
                            >
                              <div
                                className={
                                  "common-vehical-card-inner cv-card p-0"
                                }
                              >
                                <div className="untracked-overlay">
                                  <div className="untracked-img">
                                    <img src={untracked_icon} alt="" />
                                  </div>
                                </div>
                                <div className="vehical-card-head vc-top">
                                  <div className="heading top-avatar-wrapper">
                                    <img
                                      src={Grouplogo}
                                      alt=""
                                      className="custom-Margin"
                                    />
                                    <div className="">
                                      <p className="sub-heading">
                                        {t("Driver Name")}
                                      </p>
                                      <p className="title">Mark S. Woods</p>
                                    </div>
                                  </div>
                                  <div className="option customer-option">
                                    <Dropdown>
                                      <Dropdown.Toggle id="dropdown-basic">
                                        <img src={option} alt="" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item>
                                          <Link to="/ViewDrivers">
                                            {t("View")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link to="/EditDrivers">
                                            {t("Edit")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link to="/ChangeDriverPassword">
                                            {t("Change Password")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow1}
                                        >
                                          <Link to="#">{t("Block")}</Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow2}
                                        >
                                          <Link to="#">{t("Resign")}</Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow}
                                        >
                                          <Link to="#">{t("Delete")}</Link>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="vehical-card-body vc-body row g-0">
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Email")}</p>
                                    <p className="title">markwoods@gmail.com</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Contact No.")}
                                    </p>
                                    <p className="title">99999 99999</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Vehicle No.")}
                                    </p>
                                    <p className="title">MH-12-2023</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Vehicle Assignment")}.
                                    </p>
                                    <p className="title">Toyota Innova</p>
                                  </div>
                                  <div className="card-contain col-lg-12">
                                    <p className="sub-heading">
                                      {t("Address")}
                                    </p>
                                    <p className="title">
                                      Vishrantwadi, Kalas Road, Pune- 411030.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                sidebar
                                  ? "col-lg-4 col-md-6"
                                  : "col-lg-3 col-md-6"
                              }
                            >
                              <div
                                className={
                                  "common-vehical-card-inner cv-card p-0"
                                }
                              >
                                <div className="untracked-overlay">
                                  <div className="untracked-img">
                                    <img src={untracked_icon} alt="" />
                                  </div>
                                </div>
                                <div className="vehical-card-head vc-top">
                                  <div className="heading top-avatar-wrapper">
                                    <img
                                      src={Grouplogo}
                                      alt=""
                                      className="custom-Margin"
                                    />
                                    <div className="">
                                      <p className="sub-heading">
                                        {t("Driver Name")}
                                      </p>
                                      <p className="title">Mark S. Woods</p>
                                    </div>
                                  </div>
                                  <div className="option customer-option">
                                    <Dropdown>
                                      <Dropdown.Toggle id="dropdown-basic">
                                        <img src={option} alt="" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item>
                                          <Link to="/ViewDrivers">
                                            {t("View")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link to="/EditDrivers">
                                            {t("Edit")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link to="/ChangeDriverPassword">
                                            {t("Change Password")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow1}
                                        >
                                          <Link to="#">{t("Block")}</Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow2}
                                        >
                                          <Link to="#">{t("Resign")}</Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow}
                                        >
                                          <Link to="#">{t("Delete")}</Link>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="vehical-card-body vc-body row g-0">
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Email")}</p>
                                    <p className="title">markwoods@gmail.com</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Contact No.")}
                                    </p>
                                    <p className="title">99999 99999</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Vehicle No.")}
                                    </p>
                                    <p className="title">MH-12-2023</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Vehicle Assignment")}.
                                    </p>
                                    <p className="title">Toyota Innova</p>
                                  </div>
                                  <div className="card-contain col-lg-12">
                                    <p className="sub-heading">
                                      {t("Address")}
                                    </p>
                                    <p className="title">
                                      Vishrantwadi, Kalas Road, Pune- 411030.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                sidebar
                                  ? "col-lg-4 col-md-6"
                                  : "col-lg-3 col-md-6"
                              }
                            >
                              <div
                                className={
                                  "common-vehical-card-inner cv-card p-0"
                                }
                              >
                                <div className="untracked-overlay">
                                  <div className="untracked-img">
                                    <img src={untracked_icon} alt="" />
                                  </div>
                                </div>
                                <div className="vehical-card-head vc-top">
                                  <div className="heading top-avatar-wrapper">
                                    <img
                                      src={Grouplogo}
                                      alt=""
                                      className="custom-Margin"
                                    />
                                    <div className="">
                                      <p className="sub-heading">
                                        {t("Driver Name")}
                                      </p>
                                      <p className="title">Mark S. Woods</p>
                                    </div>
                                  </div>
                                  <div className="option customer-option">
                                    <Dropdown>
                                      <Dropdown.Toggle id="dropdown-basic">
                                        <img src={option} alt="" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item>
                                          <Link to="/ViewDrivers">
                                            {t("View")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link to="/EditDrivers">
                                            {t("Edit")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link to="/ChangeDriverPassword">
                                            {t("Change Password")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow1}
                                        >
                                          <Link to="#">{t("Block")}</Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow2}
                                        >
                                          <Link to="#">{t("Resign")}</Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow}
                                        >
                                          <Link to="#">{t("Delete")}</Link>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="vehical-card-body vc-body row g-0">
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Email")}</p>
                                    <p className="title">markwoods@gmail.com</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Contact No.")}
                                    </p>
                                    <p className="title">99999 99999</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Vehicle No.")}
                                    </p>
                                    <p className="title">MH-12-2023</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Vehicle Assignment")}.
                                    </p>
                                    <p className="title">Toyota Innova</p>
                                  </div>
                                  <div className="card-contain col-lg-12">
                                    <p className="sub-heading">
                                      {t("Address")}
                                    </p>
                                    <p className="title">
                                      Vishrantwadi, Kalas Road, Pune- 411030.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                sidebar
                                  ? "col-lg-4 col-md-6"
                                  : "col-lg-3 col-md-6"
                              }
                            >
                              <div
                                className={
                                  "common-vehical-card-inner cv-card p-0"
                                }
                              >
                                <div className="untracked-overlay">
                                  <div className="untracked-img">
                                    <img src={untracked_icon} alt="" />
                                  </div>
                                </div>
                                <div className="vehical-card-head vc-top">
                                  <div className="heading top-avatar-wrapper">
                                    <img
                                      src={Grouplogo}
                                      alt=""
                                      className="custom-Margin"
                                    />
                                    <div className="">
                                      <p className="sub-heading">
                                        {t("Driver Name")}
                                      </p>
                                      <p className="title">Mark S. Woods</p>
                                    </div>
                                  </div>
                                  <div className="option customer-option">
                                    <Dropdown>
                                      <Dropdown.Toggle id="dropdown-basic">
                                        <img src={option} alt="" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item>
                                          <Link to="/ViewDrivers">
                                            {t("View")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link to="/EditDrivers">
                                            {t("Edit")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link to="/ChangeDriverPassword">
                                            {t("Change Password")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow1}
                                        >
                                          <Link to="#">{t("Block")}</Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow2}
                                        >
                                          <Link to="#">{t("Resign")}</Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow}
                                        >
                                          <Link to="#">{t("Delete")}</Link>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="vehical-card-body vc-body row g-0">
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Email")}</p>
                                    <p className="title">markwoods@gmail.com</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Contact No.")}
                                    </p>
                                    <p className="title">99999 99999</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Vehicle No.")}
                                    </p>
                                    <p className="title">MH-12-2023</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Vehicle Assignment")}.
                                    </p>
                                    <p className="title">Toyota Innova</p>
                                  </div>
                                  <div className="card-contain col-lg-12">
                                    <p className="sub-heading">
                                      {t("Address")}
                                    </p>
                                    <p className="title">
                                      Vishrantwadi, Kalas Road, Pune- 411030.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                sidebar
                                  ? "col-lg-4 col-md-6"
                                  : "col-lg-3 col-md-6"
                              }
                            >
                              <div
                                className={
                                  "common-vehical-card-inner cv-card p-0"
                                }
                              >
                                <div className="untracked-overlay">
                                  <div className="untracked-img">
                                    <img src={untracked_icon} alt="" />
                                  </div>
                                </div>
                                <div className="vehical-card-head vc-top">
                                  <div className="heading top-avatar-wrapper">
                                    <img
                                      src={Grouplogo}
                                      alt=""
                                      className="custom-Margin"
                                    />
                                    <div className="">
                                      <p className="sub-heading">
                                        {t("Driver Name")}
                                      </p>
                                      <p className="title">Mark S. Woods</p>
                                    </div>
                                  </div>
                                  <div className="option customer-option">
                                    <Dropdown>
                                      <Dropdown.Toggle id="dropdown-basic">
                                        <img src={option} alt="" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item>
                                          <Link to="/ViewDrivers">
                                            {t("View")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link to="/EditDrivers">
                                            {t("Edit")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link to="/ChangeDriverPassword">
                                            {t("Change Password")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow1}
                                        >
                                          <Link to="#">{t("Block")}</Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow2}
                                        >
                                          <Link to="#">{t("Resign")}</Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow}
                                        >
                                          <Link to="#">{t("Delete")}</Link>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="vehical-card-body vc-body row g-0">
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Email")}</p>
                                    <p className="title">markwoods@gmail.com</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Contact No.")}
                                    </p>
                                    <p className="title">99999 99999</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Vehicle No.")}
                                    </p>
                                    <p className="title">MH-12-2023</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Vehicle Assignment")}.
                                    </p>
                                    <p className="title">Toyota Innova</p>
                                  </div>
                                  <div className="card-contain col-lg-12">
                                    <p className="sub-heading">
                                      {t("Address")}
                                    </p>
                                    <p className="title">
                                      Vishrantwadi, Kalas Road, Pune- 411030.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                sidebar
                                  ? "col-lg-4 col-md-6"
                                  : "col-lg-3 col-md-6"
                              }
                            >
                              <div
                                className={
                                  "common-vehical-card-inner cv-card p-0"
                                }
                              >
                                <div className="untracked-overlay">
                                  <div className="untracked-img">
                                    <img src={untracked_icon} alt="" />
                                  </div>
                                </div>
                                <div className="vehical-card-head vc-top">
                                  <div className="heading top-avatar-wrapper">
                                    <img
                                      src={Grouplogo}
                                      alt=""
                                      className="custom-Margin"
                                    />
                                    <div className="">
                                      <p className="sub-heading">
                                        {t("Driver Name")}
                                      </p>
                                      <p className="title">Mark S. Woods</p>
                                    </div>
                                  </div>
                                  <div className="option customer-option">
                                    <Dropdown>
                                      <Dropdown.Toggle id="dropdown-basic">
                                        <img src={option} alt="" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item>
                                          <Link to="/ViewDrivers">
                                            {t("View")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link to="/EditDrivers">
                                            {t("Edit")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link to="/ChangeDriverPassword">
                                            {t("Change Password")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow1}
                                        >
                                          <Link to="#">{t("Block")}</Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow2}
                                        >
                                          <Link to="#">{t("Resign")}</Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow}
                                        >
                                          <Link to="#">{t("Delete")}</Link>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="vehical-card-body vc-body row g-0">
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Email")}</p>
                                    <p className="title">markwoods@gmail.com</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Contact No.")}
                                    </p>
                                    <p className="title">99999 99999</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Vehicle No.")}
                                    </p>
                                    <p className="title">MH-12-2023</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">
                                      {t("Vehicle Assignment")}.
                                    </p>
                                    <p className="title">Toyota Innova</p>
                                  </div>
                                  <div className="card-contain col-lg-12">
                                    <p className="sub-heading">
                                      {t("Address")}
                                    </p>
                                    <p className="title">
                                      Vishrantwadi, Kalas Road, Pune- 411030.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Tab.Container>
            </div>
            <Pagenation />
          </div>
        </div>

        {/* Delete Modal Start */}
        <Modal
          show={show}
          onHide={handleClose}
          centered
          className="common-model"
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("Delete Driver")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {t("Are you sure you want to Delete this Driver")} ?
          </Modal.Body>
          <Modal.Footer className="pop-up-modal-footer">
            <div class="btn-wrapper">
              <button className="cx-btn-1" onClick={handleClose}>
                {t("Cancel")}
              </button>
              <button className="cx-btn-2" onClick={handleClose}>
                {t("Yes")}
              </button>
            </div>
          </Modal.Footer>
        </Modal>
        {/* Delete Modal End */}

        {/* Block Modal Start */}
        <Modal
          show={show1}
          onHide={handleClose1}
          centered
          className="common-model"
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("Block")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{t("Are you sure you want to Block")} ?</Modal.Body>
          <Modal.Footer className="pop-up-modal-footer">
            <div class="btn-wrapper">
              <button className="cx-btn-1" onClick={handleClose1}>
                {t("Cancel")}
              </button>
              <button className="cx-btn-2" onClick={handleClose1}>
                {t("Yes")}
              </button>
            </div>
          </Modal.Footer>
        </Modal>
        {/* Block Modal End */}

        {/* Resign/retire Modal Start */}
        <Modal
          show={show2}
          onHide={handleClose2}
          centered
          className="common-model"
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("Resign / Retire")} </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {t("Are you sure you want to Resign / Retire")} ?
          </Modal.Body>
          <Modal.Footer className="pop-up-modal-footer">
            <div class="btn-wrapper">
              <button className="cx-btn-1" onClick={handleClose2}>
                {t("Cancel")}
              </button>
              <button className="cx-btn-2" onClick={handleClose2}>
                {t("Yes")}
              </button>
            </div>
          </Modal.Footer>
        </Modal>
        {slider ? <ExistButton onClose={() => setSlider(false)} /> : null}
        {!slider && <NextButton onClick={handleClick} />}
        {!slider && <InfoComponent />}
      </div>
    </motion.div>
  );
};

export default Drivers;
