import {
  Routes,
  Route,
  useLocation,
  useNavigate,
  Link,
} from "react-router-dom";
import React, { Suspense, useState } from "react";

import { motion } from "framer-motion";
import { AnimatePresence } from "framer-motion";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/styles/main.scss";
import Sidebar from "./sharedComponent/Sidebar.js";
import Header from "./sharedComponent/Header";
import Dashboard from "./pages/Dashboard/Dashboard";
import "react-datepicker/dist/react-datepicker.css";
import GeneralSetting from "./pages/MasterSettings/GeneralSetting";
import IntegrationSetting from "./pages/MasterSettings/IntegrationSetting";
import CustomerProfile from "./pages/MasterSettings/CustomerProfile";
import TransportationSetting from "./pages/MasterSettings/TransportationSetting";
import NotificationSetting from "./pages/MasterSettings/NotificationSetting";
import AddOnSettings from "./pages/MasterSettings/AddOnSettings";
import AddOnSettingsCart from "./pages/MasterSettings/AddOnSettingsCart";
import Holidays from "./pages/MasterData/Holidays";
import EditHolidays from "./pages/MasterData/EditHolidays";
import AddHolidays from "./pages/MasterData/AddHolidays";
import Vacations from "./pages/MasterData/Vacations";
import EditVacation from "./pages/MasterData/EditVacation";
import AddVacation from "./pages/MasterData/AddVacation";
import LuxuryCars from "./pages/MasterData/LuxuryCars";
import DispatchSetting from "./pages/MasterSettings/DispatchSetting";
import UpdateCustomerProfile from "./pages/MasterSettings/UpdateCustomerProfile";
import LogoUpdate from "./pages/MasterSettings/LogoUpdate";
import Login from "./pages/AuthPages/Login";
import ForgetPassword from "./pages/AuthPages/ForgetPassword";
import Registration from "./pages/AuthPages/Registration";
import DemoAccount from "./pages/AuthPages/DemoAccount";
import AccessRight from "./pages/MasterSettings/AccessRight";
import Vehicle from "./pages/MasterData/Vehicle";
import AddVehicle from "./pages/MasterData/AddVehicle";
import LoginWithOTP from "./pages/AuthPages/LoginWithOTP";
import Animation from "./pages/AuthPages/Animation";
import TransportManager from "./pages/users/TransportManager/TransportManager";
import AddTransportManager from "./pages/users/TransportManager/AddTransportManager";
import EditTransportManager from "./pages/users/TransportManager/EditTransportManager";
import ViewTransportManager from "./pages/users/TransportManager/ViewTransportManager";
import VehicleAssistants from "./pages/users/VehicleAssistants/VehicleAssistants";
import AddVehicleAssistants from "./pages/users/VehicleAssistants/AddVehicleAssistants";
import EditVehicleAssistants from "./pages/users/VehicleAssistants/EditVehicleAssistants";
import ViewVehicleAssistants from "./pages/users/VehicleAssistants/ViewVehicleAssistants";
import ChangePassword from "./pages/users/TransportManager/ChangePassword";
import FleetManager from "./pages/users/FleetManager/FleetManager";
import AddFleetManager from "./pages/users/FleetManager/AddFleetManager";
import EditFleetManager from "./pages/users/FleetManager/EditFleetManager";
import ViewFleetManager from "./pages/users/FleetManager/ViewFleetManager";
import Drivers from "./pages/users/Drivers/Drivers";
import AddDrivers from "./pages/users/Drivers/AddDrivers";
import EditDrivers from "./pages/users/Drivers/EditDrivers";
import ViewDrivers from "./pages/users/Drivers/ViewDrivers";
import DeliveryPerson from "./pages/users/DeliveryPerson/DeliveryPerson";
import AddDeliveryPerson from "./pages/users/DeliveryPerson/AddDeliveryPerson";
import EditDeliveryPerson from "./pages/users/DeliveryPerson/EditDeliveryPerson";
import ViewDeliveryPerson from "./pages/users/DeliveryPerson/ViewDeliveryPerson";
import Administrator from "./pages/users/administrator/Administrator";
import View from "./pages/users/administrator/View";
import AddVehicleCategory from "./pages/MasterData/AddVehicleCategory";

import AddParkingSlot from "./pages/parkingSlot/AddParkingSlot";
import RegistrationLocation from "./pages/AuthPages/RegistrationLocation";
import EditParkingSlot from "./pages/parkingSlot/EditParkingSlot";
import PointOfIntrest from "./pages/pointsOfIntrest/PointOfIntrest";
import AddPointOfInterest from "./pages/pointsOfIntrest/AddPointOfInterest";
import EditPointOfInterest from "./pages/pointsOfIntrest/EditPointOfInterest";
import GeofenceArea from "./pages/geofenceArea/GeofenceArea";
import NewSyncRequest from "./pages/MasterData/NewSyncRequest";
import AddGeafenceArea from "./pages/geofenceArea/AddGeofenceArea";
import AddGeofenceArea from "./pages/geofenceArea/AddGeofenceArea";
import EditGeofenceArea from "./pages/geofenceArea/EditGeofenceArea";
import VehicleAccident from "./pages/vehicleExpenses/vehicleAccident/VehicleAccident";
import AddVehicleAccident from "./pages/vehicleExpenses/vehicleAccident/AddVehicleAccident";
import EditVehicleAccident from "./pages/vehicleExpenses/vehicleAccident/EditVehicleAccident";
import VehicleDetails from "./pages/MasterData/VehicleDetails";
import AddVehicleGroup from "./pages/MasterData/AddVehicleGroup";
import MyProfile from "./pages/Account/MyProfile";
import EditProfile from "./pages/Account/EditProfile";
import ChangePasswordProfile from "./pages/Account/ChangePasswordProfile";
import MyMessages from "./pages/Account/MyMessages";
import ComposeMessage from "./pages/Account/ComposeMessage";
import ChatBox from "./pages/Account/ChatBox";
import ViewVehicleAccident from "./pages/vehicleExpenses/vehicleAccident/ViewVehicleAccident";
import ViewVehicleAccesory from "./pages/vehicleExpenses/vehicleAccesory/ViewVehicleAccesory";
import EditVehicleAccesory from "./pages/vehicleExpenses/vehicleAccesory/EditVehicleAccesory";
import AddVehicleAccesory from "./pages/vehicleExpenses/vehicleAccesory/AddVehicleAccesory";
import VehicleAccesory from "./pages/vehicleExpenses/vehicleAccesory/VehicleAccesory";
import VehicleSpareParts from "./pages/vehicleExpenses/vehicleSpareParts/VehicleSpareParts";
import AddVehicleSpareParts from "./pages/vehicleExpenses/vehicleSpareParts/AddVehicleSpareParts";
import EditVehicleSpareParts from "./pages/vehicleExpenses/vehicleSpareParts/EditVehicleSpareParts";
import ViewVehicleSpareParts from "./pages/vehicleExpenses/vehicleSpareParts/ViewVehicleSpareParts";
import VehicleFine from "./pages/vehicleExpenses/vehicleFine/VehicleFine";
import AddVehicleFine from "./pages/vehicleExpenses/vehicleFine/AddVehicleFine";
import ViewVehicleFine from "./pages/vehicleExpenses/vehicleFine/ViewVehicleFine";
import EditVehicleFine from "./pages/vehicleExpenses/vehicleFine/EditVehicleFine";
import CustomerSupport from "./pages/Account/CustomerSupport";
import FuelExpenses from "./pages/vehicleExpenses/FuelExpenses/FuelExpenses";
import AddFuelExpenses from "./pages/vehicleExpenses/FuelExpenses/AddFuelExpenses";
import EditFuelExpenses from "./pages/vehicleExpenses/FuelExpenses/EditFuelExpenses";
import ViewFuelExpenses from "./pages/vehicleExpenses/FuelExpenses/ViewFuelExpenses";
import TripManagement from "./pages/TripManagement/TripManagement";
import AddDispatchTrip from "./pages/TripManagement/AddDispatchTrip";
import ViewDispatchTrip from "./pages/TripManagement/ViewDispatchTrip";
import EditDispatchTrip from "./pages/TripManagement/EditDispatchTrip";
import DispatchCustomer from "./pages/Dispatch/DispatchCustomer/DispatchCustomer";

import ViewDispatch from "./pages/Dispatch/DispatchCustomer/ViewDispatch";
import EditCustomerDispatch from "./pages/Dispatch/DispatchCustomer/EditCustomerDispatch";
import AddDispatchCustomer from "./pages/Dispatch/DispatchCustomer/AddDispatchCustomer";
import DispatchOrder from "./pages/Dispatch/DispatchOrder/DispatchOrder";
import ViewOrders from "./pages/Dispatch/DispatchOrder/ViewOrders";
import AddStop from "./pages/TripManagement/AddStop";
import EditStop from "./pages/TripManagement/EditStop";
import ViewStop from "./pages/TripManagement/ViewStop";
import ManualRouting from "./pages/Dispatch/DispatchOrder/ManualRouting";
import UpdateCustomerLogo from "./pages/MasterSettings/UpdateCustomerLogo";
import LockScreen from "./pages/Account/LockScreen";
import Invoiced from "./pages/Payment/Invoiced";
import InvoiceDetails from "./pages/Payment/InvoiceDetails";
import Paid from "./pages/Payment/Paid";
import PaidPaymentInvoice from "./pages/Payment/PaidPaymentInvoice";
import PaidPaymentReceipt from "./pages/Payment/PaidPaymentReceipt";
import Announcement from "./pages/vehicleExpenses/Communication/Announcement";
import Addaudio from "./pages/vehicleExpenses/Communication/Addaudio";
import EditAudio from "./pages/vehicleExpenses/Communication/EditAudio";
import Email from "./pages/vehicleExpenses/Communication/Email";
import EmailDetails from "./pages/vehicleExpenses/Communication/EmailDetails";
import ComposeEmail from "./pages/vehicleExpenses/Communication/ComposeEmail";
import PushNotification from "./pages/vehicleExpenses/Communication/PushNotification";
import NotificationDetails from "./pages/vehicleExpenses/Communication/NotificationDetails";
import AddPushNotification from "./pages/vehicleExpenses/Communication/AddPushNotification";
import Reports from "./pages/vehicleExpenses/Report/Reports";
import GenerateCustomReport from "./pages/vehicleExpenses/Report/GenerateCustomReport";
import ScheduleReport from "./pages/vehicleExpenses/Report/ScheduleReport";
import ReportView from "./pages/vehicleExpenses/Report/ReportView";
import AllUsers from "./pages/NewUsers/AllUsers";
import CreateUser from "./pages/NewUsers/CreateUser";
import EditUser from "./pages/NewUsers/EditUser";
import ChangeDriverPassword from "./pages/users/Drivers/ChangeDriverPassword";
import ChangePassDelivery from "./pages/users/DeliveryPerson/ChangePassDelivery";
import ChangeFleetPass from "./pages/users/FleetManager/ChangeFleetPass";
import ChangeDelPass from "./pages/users/VehicleAssistants/ChangeDelPass.js";
import ConfigurationChecker from "./pages/ConfigurationChecker";
import AnimatedRoutes from "./sharedComponent/AnimatedRoutes";
import EditVehicle from "./pages/MasterData/EditVehicle";
import EditVehicleGroup from "./pages/MasterData/EditVehicleGroup";
import EmailSupport from "./pages/Account/EmailSupport";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

import ReplayPlayback from "./pages/ReplayPlayback/ReplayPlayback";
import FuelManagementDashbord from "./pages/FuelManagement/FuelManagementDashbord";
import FuelManagementDetails from "./pages/FuelManagement/FuelManagementDetails";
import FuelAlerts from "./pages/FuelManagement/FuelAlerts";
import ReportDistributionContacts_Details from "./pages/NewUsers/ReportDistributionContacts_Details";
import AcessRole from "./pages/MasterSettings/AcessRight/AcessRole";
import UserRole from "./pages/MasterSettings/AcessRight/UserRole";
import InfoComponent from "./sharedComponent/SupportHelp/InfoComponent";
import LogChanges from "./pages/MasterSettings/LogChanges";
import DeliveryRequest from "./pages/Dispatch/Delivery/DeliveryRequest";
import VehicleBooking from "./pages/Dispatch/Vehicle_Booking/VehicleBooking";
import UserDashboard from "./pages/UserDashboard/UserDashboard";
import UserAlert from "./pages/UserDashboard/UserAlert";
import Merchant from "./pages/Dispatch/Merchant/Merchant";
import AddMerchant from "./pages/Dispatch/Merchant/AddMerchant";
import EditMerchant from "./pages/Dispatch/Merchant/EditMerchant";
import ViewMerchant from "./pages/Dispatch/Merchant/ViewMerchant";
import IVMS from "./pages/HardwareFeatures/IVMS.js";
import ReportIssue from "./pages/Account/ReportIssue";
import ViewReport from "./pages/Account/ViewReport";
import VehicleInspection from "./pages/VehicleInspection/VehicleInspection";
import NewInspection from "./pages/VehicleInspection/NewInspection";
import NewVehicleInspection from "./pages/VehicleInspection/NewVehicleInspection";
import ViewInspectionDetails from "./pages/VehicleInspection/ViewInspectionDetails";
import VehicleBookingList from "./pages/Dispatch/Vehicle_Booking/VehicleBookingList";
import AddVehicleMaintenance from "./pages/vehicleExpenses/VehicleMaintenance/AddVehicleMaintenance";
import EditVehicleMaintenance from "./pages/vehicleExpenses/VehicleMaintenance/EditVehicleMaintenance";
import VehicleMaintenance from "./pages/vehicleExpenses/VehicleMaintenance/VehicleMaintenance";
import ViewVehicleMaintenance from "./pages/vehicleExpenses/VehicleMaintenance/ViewVehicleMaintenance";
import VehicleInspectionDashboard from "./pages/VehicleInspection/VehicleInspectionDashboard";
import InspectionPDF from "./pages/VehicleInspection/InspectionPDF";
import OfferDriverMarketPlace from "./pages/MarketPlace/OfferDriverMarketPlace";
import OfferVehicleMarketPlace from "./pages/MarketPlace/OfferVehicleMarketPlace";
import OnlineAuctionMarketPlace from "./pages/MarketPlace/OnlineAuction/OnlineAuctionMarketPlace";
import DirectOrderMarketPlace from "./pages/MarketPlace/DirectOrder/DirectOrderMarketPlace";
import DirectOrderAddVehicle from "./pages/MarketPlace/DirectOrder/DirectOrderAddVehicle";
import DirectOrderOfferVehicle from "./pages/MarketPlace/DirectOrder/DirectOrderOfferLoad";
import DirectOrderOfferLoad from "./pages/MarketPlace/DirectOrder/DirectOrderOfferLoad";
import DirectOrderReferenceDetails from "./pages/MarketPlace/DirectOrder/DirectOrderReferenceDetails";
import DirectOrderVehicleDetails from "./pages/MarketPlace/DirectOrder/DirectOrderVehicleDetails";
import OnlineAuction from "./pages/MarketPlace/OnlineAuction/OnlineAuction";
import BidAuctionDetails from "./pages/MarketPlace/OnlineAuction/BidAuctionDetails";
import AuctionOfferDetails from "./pages/MarketPlace/OnlineAuction/AuctionOfferDetails";
import OrderConfDetails from "./pages/MarketPlace/OnlineAuction/OrderConfDetails";
import AuctionAddVehicle from "./pages/MarketPlace/OnlineAuction/AuctionAddVehicle";
import AuctionReferenceDetails from "./pages/MarketPlace/OnlineAuction/AuctionReferenceDetails";
import DirectOrderConfirmation from "./pages/MarketPlace/DirectOrder/DirectOrderConfirmation";
import OnlineAuctionVehicleDetails from "./pages/MarketPlace/OnlineAuction/OnlineAuctionVehicleDetails";
import Slidebarinspection from "./sharedComponent/Slidebarinspection.js";
import Slideparkingmanagement from "./sharedComponent/Slideparkingmanagement.js";
import Slidefuelmanagement from "./sharedComponent/Slidefuelmanagement.js";
import Slidevehicleaccident from "./sharedComponent/Slidevehicleaccident.js";
import Slidedelivery from "./sharedComponent/Slidedelivery.js";
import Slidedashboard from "./sharedComponent/Slidedashboard.js";
import Slidereports from "./sharedComponent/Slidereports.js";
import Slideplayback from "./sharedComponent/Slideplayback.js";
import SlideIVMS from "./sharedComponent/SlideIVMS.js";
import Nextbutton from "./sharedComponent/Nextbutton.js";
import PreviousButton from "./sharedComponent/PreviousButton.js";
import NextButton from "./sharedComponent/Nextbutton.js";
import Adddispatchedcustomer from "./pages/Dispatch/DispatchCustomer/Adddispatchedcustomer.js";
import RepleyplayBack from "./pages/ReplayPlayback/RepleyplayBack.js";
import SliderDriver from "./sharedComponent/SliderDriver.js";
import ParkingManagementone from "./pages/ParkingManagementOnes/ParkingManagementone.js";
import ParkingManagement from "./pages/parkingSlot/ParkingManagement.js";
import DeliveryDispatchManagement from "./pages/Dispatch/DispatchCustomer/DeliveryDispatchManagement.js";
import TripManagementnew from "./pages/Tripmanagementnew/TripManagementnew.js";
import AddDispatchTripnew from "./pages/Tripmanagementnew/AddDispatchTripnew.js";
import ViewDispatchTripnew from "./pages/Tripmanagementnew/ViewDispatchTripnew.js";
import AddStopnew from "./pages/Tripmanagementnew/AddStopnew.js";
import EditStopnew from "./pages/Tripmanagementnew/EditStopnew.js";
import ViewStopnew from "./pages/Tripmanagementnew/ViewStopnew.js";
import DeliveryRequestone from "./pages/Dispatch/Delivery/DeliveryRequestone.js";
import RedirectComponent from "../src/sharedComponent/RedirectComponent.js";
import Overspeed from "./pages/HardwareFeatures/Overspeed.js";
import Immobiliserreports from "./pages/HardwareFeatures/Immobiliserreports.js";
import Temprature from "./pages/HardwareFeatures/Temprature.js";
import Driverbehavioureports from "./pages/HardwareFeatures/Driverbehavioureports.js";

const routes = [
  { path: "/", name: "login", Component: Login },
  { path: "/LoginWithOTP", name: "loginwithotp", Component: LoginWithOTP },
];

function App() {
  const location = useLocation();
  let currentRoute = location.pathname;
  let pathRoute = window.location.href;
  console.log(window.location.href, "currentRoute");
  const url = window.location.href;
  const parts = url.split("/");
  const token1 = parts[parts.length - 1]; // Get the last part of the URL

  console.log(token1, "this is token 1");

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  const renderSidebar = () => {
    console.log(pathRoute, "local");
    console.log(currentRoute, "current");

    if (
      currentRoute === `VehicleBooking/${token1}` ||
      currentRoute === "VehicleBooking" ||
      currentRoute === `DispatchCustomer/${token1}` ||
      currentRoute === "DispatchCustomer" ||
      currentRoute === `AddDispatchCustomer/${token1}` ||
      currentRoute === "AddDispatchCustomer" ||
      currentRoute === `VehicleBookingList/${token1}` ||
      currentRoute === "VehicleBookingList" ||
      currentRoute === `DeliveryRequest/${token1}` ||
      currentRoute === "DeliveryRequest" ||
      currentRoute === `TripManagementnew/${token1}` ||
      currentRoute === "TripManagementnew" ||
      currentRoute === `AddDispatchTripnew/${token1}` ||
      currentRoute === "AddDispatchTripnew" ||
      currentRoute === `AddStopnew/${token1}` ||
      currentRoute === "AddStopnew"
    ) {
      return <Sidebar />;
    } else if (
      currentRoute === `VehicleInspectionDashboard/${token1}` ||
      currentRoute === "VehicleInspectionDashboard" ||
      currentRoute === `NewInspection/${token1}` ||
      currentRoute === "NewInspection" ||
      currentRoute === `VehicleInspection/${token1}` ||
      currentRoute === "VehicleInspection"
    ) {
      return <Slidebarinspection />;
    } else if (
      currentRoute === `ParkingManagement/${token1}` ||
      currentRoute === "ParkingManagement" ||
      currentRoute === `AddParkingSlot/${token1}` ||
      currentRoute === "AddParkingSlot" ||
      currentRoute === `RepleyplayBack/${token1}` ||
      currentRoute === "RepleyplayBack"
    ) {
      return <Slideparkingmanagement />;
    } else if (
      currentRoute === `FuelManagementDashbord/${token1}` ||
      currentRoute === "FuelManagementDashbord" ||
      currentRoute === `FuelManagementDetails/${token1}` ||
      currentRoute === "FuelManagementDetails" ||
      currentRoute === `FuelAlerts/${token1}` ||
      currentRoute === "FuelAlerts"
    ) {
      return <Slidefuelmanagement />;
    } else if (
      currentRoute === `VehicleAccident/${token1}` ||
      currentRoute === "VehicleAccident" ||
      currentRoute === `AddVehicleAccident/${token1}` ||
      currentRoute === "AddVehicleAccident" ||
      currentRoute === `VehicleAccesory/${token1}` ||
      currentRoute === "VehicleAccesory" ||
      currentRoute === `AddVehicleAccesory/${token1}` ||
      currentRoute === "AddVehicleAccesory" ||
      currentRoute === `VehicleSpareParts/${token1}` ||
      currentRoute === "VehicleSpareParts" ||
      currentRoute === `AddVehicleSpareParts/${token1}` ||
      currentRoute === "AddVehicleSpareParts" ||
      currentRoute === `VehicleFine/${token1}` ||
      currentRoute === "VehicleFine" ||
      currentRoute === `AddVehicleFine/${token1}` ||
      currentRoute === "AddVehicleFine" ||
      currentRoute === `VehicleMaintenance/${token1}` ||
      currentRoute === "VehicleMaintenance" ||
      currentRoute === `AddVehicleMaintenance/${token1}` ||
      currentRoute === "AddVehicleMaintenance" ||
      currentRoute === `FuelExpenses/${token1}` ||
      currentRoute === "FuelExpenses" ||
      currentRoute === `AddFuelExpenses/${token1}` ||
      currentRoute === "AddFuelExpenses"
    ) {
      return <Slidevehicleaccident />;
    } else if (
      currentRoute === `DeliveryDispatchManagement/${token1}` ||
      currentRoute === "Merchant" ||
      currentRoute === `Merchant/${token1}` ||
      currentRoute === "AddMerchant" ||
      currentRoute === `AddMerchant/${token1}` ||
      currentRoute === "DeliveryDispatchManagement" ||
      currentRoute === `AddDispatchCustomer/${token1}` ||
      currentRoute === "AddDispatchCustomer" ||
      currentRoute === `DispatchOrder/${token1}` ||
      currentRoute === "DispatchOrder" ||
      currentRoute === `ManualRouting/${token1}` ||
      currentRoute === "ManualRouting" ||
      currentRoute === `DeliveryRequestone/${token1}` ||
      currentRoute === "DeliveryRequestone" ||
      currentRoute === `TripManagement/${token1}` ||
      currentRoute === "TripManagement" ||
      currentRoute === `AddDispatchTrip/${token1}` ||
      currentRoute === "AddDispatchTrip" ||
      currentRoute === `AddStop/${token1}` ||
      currentRoute === "AddStop"
    ) {
      return <Slidedelivery />;
    } else if (
      currentRoute === `Dashboard/${token1}` ||
      currentRoute === "Dashboard"
    ) {
      return <Slidedashboard />;
    } else if (
      currentRoute === `Reports/${token1}` ||
      currentRoute === `ScheduleReport/${token1}` ||
      currentRoute === "ScheduleReport" ||
      currentRoute === `GenerateCustomReport/${token1}` ||
      currentRoute === "GenerateCustomReport" ||
      currentRoute === "Reports"
    ) {
      return <Slidereports />;
    } else if (
      currentRoute === `ReplayPlayback/${token1}` ||
      currentRoute === "ReplayPlayback" ||
      currentRoute === `ParkingManagementone/${token1}` ||
      currentRoute === "ParkingManagementone" ||
      currentRoute === `AddParkingSlot/${token1}` ||
      currentRoute === "AddParkingSlot"
    ) {
      return <Slideplayback />;
    } else if (
      currentRoute === `IVMS/${token1}` ||
      currentRoute === "IVMS" ||
      currentRoute === `AddVehicle/${token1}` ||
      currentRoute === "AddVehicle" ||
      currentRoute === "Overspeed" ||
      currentRoute === "Immobiliserreports" ||
      currentRoute === "Temprature" ||
      currentRoute === "Driverbehavioureports"
    ) {
      return <SlideIVMS />;
    } else if (
      currentRoute === `Drivers/${token1}` ||
      currentRoute === "Drivers" ||
      currentRoute === `AddDrivers/${token1}` ||
      currentRoute === "AddDrivers" ||
      currentRoute === `EditDrivers/${token1}` ||
      currentRoute === "EditDrivers" ||
      currentRoute === `ViewDrivers/${token1}` ||
      currentRoute === "ViewDrivers"
    ) {
      return <SliderDriver />;
    } else {
      return <Sidebar />;
    }
  };

  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <div className="container auth-container">
          <AnimatePresence>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="LoginWithOTP" element={<LoginWithOTP />} />
              <Route path="Animation" element={<Animation />} />
              <Route
                path="RegistrationLocation"
                element={<RegistrationLocation />}
              />

              <Route path="ForgetPassword" element={<ForgetPassword />} />
              <Route path="Registration" element={<Registration />} />
              <Route path="DemoAccount" element={<DemoAccount />} />
              <Route path="InspectionPDF" element={<InspectionPDF />} />
            </Routes>
          </AnimatePresence>
        </div>

        <div className="App  background_main_wrapper" id="max-width">
          {currentRoute !== "/" &&
            currentRoute !== "/ForgetPassword" &&
            currentRoute !== "/Registration" &&
            currentRoute !== "/DemoAccount" &&
            currentRoute !== "/LoginWithOTP" &&
            currentRoute !== "/Animation" &&
            currentRoute !== "/RegistrationLocation" &&
            currentRoute !== "/InspectionPDF" &&
            currentRoute !== "/LockScreen" && (
              <AnimatePresence>{renderSidebar()}</AnimatePresence>
            )}

          {/* ========== Info Help Compo start ======== */}
          {currentRoute === "/Dashboard" ||
          currentRoute === "/CustomerProfile" ||
          currentRoute === "/GeneralSetting" ||
          currentRoute === "/IntegrationSetting" ||
          currentRoute === "/TransportationSetting" ||
          currentRoute === "/NotificationSetting" ||
          currentRoute === "/DispatchSetting" ||
          currentRoute === "/AcessRole" ||
          currentRoute === "/LogChanges" ||
          currentRoute === "/Vehicle" ||
          currentRoute === "/Holidays" ||
          currentRoute === "/Vacations" ||
          currentRoute === "/ParkingSlot" ||
          currentRoute === "/PointOfIntrest" ||
          currentRoute === "/GeofenceAreas" ||
          currentRoute === "/AddOnSettings" ? (
            <InfoComponent />
          ) : (
            <></>
          )}
          {/* ========== Info Help Compo end ======== */}
          <AnimatePresence>
            <Routes>
              {/* ============ DASHBOARD ============= */}
              <Route path="Dashboard" element={<Dashboard />} />
              {token1 && (
                <Route path={`Dashboard/${token1}`} element={<Dashboard />} />
              )}
              {/* ============ MASTER SETTING ============= */}
              <Route path="CustomerProfile" element={<CustomerProfile />} />
              <Route path="GeneralSetting" element={<GeneralSetting />} />
              <Route
                path="IntegrationSetting"
                element={<IntegrationSetting />}
              />
              <Route
                path="TransportationSetting"
                element={<TransportationSetting />}
              />
              <Route
                path="NotificationSetting"
                element={<NotificationSetting />}
              />
              <Route path="DispatchSetting" element={<DispatchSetting />} />
              <Route path="AccessRight" element={<AccessRight />} />
              <Route path="UserRole" element={<UserRole />} />
              <Route path="AcessRole" element={<AcessRole />} />
              <Route path="AddOnSettings" element={<AddOnSettings />} />
              <Route path="LogoUpdate" element={<LogoUpdate />} />
              <Route
                path="UpdateCustomerLogo"
                element={<UpdateCustomerLogo />}
              />
              <Route path="AddOnSettingsCart" element={<AddOnSettingsCart />} />
              <Route
                path="UpdateCustomerProfile"
                element={<UpdateCustomerProfile />}
              />
              <Route path="LogChanges" element={<LogChanges />} />
              {/* ============ MASTER DATA ============= */}
              <Route path="Vehicle" element={<Vehicle />} />
              <Route path="Holidays" element={<Holidays />} />
              <Route path="Vacations" element={<Vacations />} />
              <Route path="AddVehicle" element={<AddVehicle />} />
              <Route
                path="AddVehicleCategory"
                element={<AddVehicleCategory />}
              />
              <Route path="NewSyncRequest" element={<NewSyncRequest />} />
              <Route path="VehicleDetails" element={<VehicleDetails />} />
              <Route path="EditVehicle" element={<EditVehicle />} />
              <Route path="AddVehicleGroup" element={<AddVehicleGroup />} />
              <Route path="EditVehicleGroup" element={<EditVehicleGroup />} />
              <Route path="AddHolidays" element={<AddHolidays />} />
              <Route path="EditHolidays" element={<EditHolidays />} />
              <Route path="AddVacation" element={<AddVacation />} />
              <Route path="EditVacation" element={<EditVacation />} />
              {/* ============ Parking Slots ============= */}
              <Route path="ParkingManagement" element={<ParkingManagement />} />
              {token1 && (
                <Route
                  path={`ParkingManagement/${token1}`}
                  element={<ParkingManagement />}
                />
              )}
              {token1 && (
                <Route path={`AddVehicle/${token1}`} element={<AddVehicle />} />
              )}
              {token1 && (
                <Route
                  path={`AddParkingSlot/${token1}`}
                  element={<AddParkingSlot />}
                />
              )}
              <Route path="AddParkingSlot" element={<AddParkingSlot />} />
              <Route path="EditParkingSlot" element={<EditParkingSlot />} />
              {/* ============ Points of interest ============= */}
              <Route path="PointOfIntrest" element={<PointOfIntrest />} />
              <Route
                path="AddPointOfInterest"
                element={<AddPointOfInterest />}
              />
              <Route
                path="EditPointOfInterest"
                element={<EditPointOfInterest />}
              />
              {/* ============ Geofence Area ============= */}
              <Route path="GeofenceAreas" element={<GeofenceArea />} />
              <Route path="Overspeed" element={<Overspeed />} />
              <Route path="AddGeofenceArea" element={<AddGeofenceArea />} />
              <Route path="EditGeofenceArea" element={<EditGeofenceArea />} />
              {/* ============ USERS ============= */}
              <Route path="administrator" element={<Administrator />} />
              <Route path="view" element={<View />} />
              <Route
                path="ReportDistributionContactsDetails"
                element={<ReportDistributionContacts_Details />}
              />
              <Route path="AllUsers" element={<AllUsers />} />
              <Route path="CreateUser" element={<CreateUser />} />
              <Route path="EditUser" element={<EditUser />} />
              {/* ============ TRANSPORT MANAGER ============= */}
              <Route path="TransportManager" element={<TransportManager />} />
              <Route
                path="AddTransportManager"
                element={<AddTransportManager />}
              />
              <Route
                path="EditTransportManager"
                element={<EditTransportManager />}
              />
              <Route
                path="ViewTransportManager"
                element={<ViewTransportManager />}
              />
              <Route path="ChangePassword" element={<ChangePassword />} />
              {/* ============ DRIVERS ============= */}
              <Route path="Drivers" element={<Drivers />} />
              {token1 && (
                <Route path={`Drivers${token1}`} element={<Drivers />} />
              )}
              {token1 && (
                <Route
                  path={`EditDrivers${token1}`}
                  element={<EditDrivers />}
                />
              )}
              {token1 && (
                <Route
                  path={`ViewDrivers${token1}`}
                  element={<ViewDrivers />}
                />
              )}
              {token1 && (
                <Route path={`AddDrivers${token1}`} element={<AddDrivers />} />
              )}
              <Route path="AddDrivers" element={<AddDrivers />} />
              <Route path="EditDrivers" element={<EditDrivers />} />
              <Route path="ViewDrivers" element={<ViewDrivers />} />
              <Route
                path="ChangeDriverPassword"
                element={<ChangeDriverPassword />}
              />
              {/* ============ DELIVERY PERSON ============= */}
              <Route path="DeliveryPerson" element={<DeliveryPerson />} />
              <Route path="AddDeliveryPerson" element={<AddDeliveryPerson />} />
              <Route
                path="EditDeliveryPerson"
                element={<EditDeliveryPerson />}
              />
              <Route
                path="ViewDeliveryPerson"
                element={<ViewDeliveryPerson />}
              />
              <Route
                path="ChangePassDelivery"
                element={<ChangePassDelivery />}
              />
              {/* ============ FLEET MANAGER ============= */}
              <Route path="FleetManager" element={<FleetManager />} />
              <Route path="AddFleetManager" element={<AddFleetManager />} />
              <Route path="EditFleetManager" element={<EditFleetManager />} />
              <Route path="ViewFleetManager" element={<ViewFleetManager />} />
              <Route path="ChangeFleetPass" element={<ChangeFleetPass />} />
              {/* ============ VEHICLE ASSISTANT ============= */}
              <Route path="VehicleAssistants" element={<VehicleAssistants />} />
              <Route
                path="AddVehicleAssistants"
                element={<AddVehicleAssistants />}
              />
              <Route
                path="EditVehicleAssistants"
                element={<EditVehicleAssistants />}
              />
              <Route
                path="ViewVehicleAssistants"
                element={<ViewVehicleAssistants />}
              />
              <Route path="ChangeDelPass" element={<ChangeDelPass />} />
              {/* Payment */}
              <Route path="Invoiced" element={<Invoiced />} />
              <Route path="InvoiceDetails" element={<InvoiceDetails />} />
              <Route path="Paid" element={<Paid />} />
              <Route
                path="PaidPaymentReceipt"
                element={<PaidPaymentReceipt />}
              />
              <Route
                path="PaidPaymentInvoice"
                element={<PaidPaymentInvoice />}
              />
              {/* Trip Management */}=
              <Route path="TripManagement" element={<TripManagement />} />
              <Route path="AddDispatchTrip" element={<AddDispatchTrip />} />
              <Route path="ViewDispatchTrip" element={<ViewDispatchTrip />} />
              <Route path="EditDispatchTrip" element={<EditDispatchTrip />} />
              <Route path="AddStop" element={<AddStop />} />
              <Route path="EditStop" element={<EditStop />} />
              <Route path="ViewStop" element={<ViewStop />} />
              {/* Trip Management */}
              <Route path="TripManagementnew" element={<TripManagementnew />} />
              {token1 && (
                <Route path={`AddStop/${token1}`} element={<AddStop />} />
              )}
              {token1 && (
                <Route
                  path={`TripManagementnew/${token1}`}
                  element={<TripManagementnew />}
                />
              )}
              {token1 && (
                <Route
                  path={`AddDispatchTrip/${token1}`}
                  element={<AddDispatchTrip />}
                />
              )}
              {token1 && (
                <Route
                  path={`TripManagement/${token1}`}
                  element={<TripManagement />}
                />
              )}
              <Route
                path="AddDispatchTripnew"
                element={<AddDispatchTripnew />}
              />
              {token1 && (
                <Route
                  path={`AddDispatchTripnew/${token1}`}
                  element={<AddDispatchTripnew />}
                />
              )}
              <Route
                path="ViewDispatchTripnew"
                element={<ViewDispatchTripnew />}
              />
              <Route path="AddStopnew" element={<AddStopnew />} />
              {token1 && (
                <Route path={`AddStopnew/${token1}`} element={<AddStopnew />} />
              )}
              <Route path="EditStopnew" element={<EditStopnew />} />
              <Route path="ViewStopnew" element={<ViewStopnew />} />
              {/* Trip Management */}
              {/* ============ Vehicle Accident  ============= */}
              <Route path="VehicleAccident" element={<VehicleAccident />} />
              {token1 && (
                <Route
                  path={`VehicleAccident/${token1}`}
                  element={<VehicleAccident />}
                />
              )}
              {token1 && (
                <Route
                  path={`AddVehicleAccident/${token1}`}
                  element={<AddVehicleAccident />}
                />
              )}
              <Route
                path="AddVehicleAccident"
                element={<AddVehicleAccident />}
              />
              <Route
                path="EditVehicleAccident"
                element={<EditVehicleAccident />}
              />
              <Route
                path="ViewVehicleAccident"
                element={<ViewVehicleAccident />}
              />
              {/* ============ Account  ============= */}
              <Route path="MyProfile" element={<MyProfile />} />
              <Route path="EditProfile" element={<EditProfile />} />
              <Route
                path="ChangePasswordProfile"
                element={<ChangePasswordProfile />}
              />
              <Route path="MyMessages" element={<MyMessages />} />
              <Route path="ComposeMessage" element={<ComposeMessage />} />
              <Route path="ChatBox" element={<ChatBox />} />
              <Route path="CustomerSupport" element={<CustomerSupport />} />
              <Route path="ReportIssue" element={<ReportIssue />} />
              <Route path="ViewReport" element={<ViewReport />} />
              {/* ============ Vehicle Accesory  ============= */}
              <Route path="VehicleAccesory" element={<VehicleAccesory />} />
              {token1 && (
                <Route
                  path={`VehicleAccesory/${token1}`}
                  element={<VehicleAccesory />}
                />
              )}
              <Route
                path="AddVehicleAccesory"
                element={<AddVehicleAccesory />}
              />
              {token1 && (
                <Route
                  path={`AddVehicleAccesory/${token1}`}
                  element={<AddVehicleAccesory />}
                />
              )}
              <Route
                path="EditVehicleAccesory"
                element={<EditVehicleAccesory />}
              />
              <Route
                path="ViewVehicleAccesory"
                element={<ViewVehicleAccesory />}
              />
              {/* ============ Vehicle Spare Parts  ============= */}
              <Route path="VehicleSpareParts" element={<VehicleSpareParts />} />
              {token1 && (
                <Route
                  path={`VehicleSpareParts/${token1}`}
                  element={<VehicleSpareParts />}
                />
              )}
              {token1 && (
                <Route
                  path={`AddVehicleSpareParts/${token1}`}
                  element={<AddVehicleSpareParts />}
                />
              )}
              <Route
                path="AddVehicleSpareParts"
                element={<AddVehicleSpareParts />}
              />
              <Route
                path="EditVehicleSpareParts"
                element={<EditVehicleSpareParts />}
              />
              <Route
                path="ViewVehicleSpareParts"
                element={<ViewVehicleSpareParts />}
              />
              {/* ============ Vehicle Fine  ============= */}
              <Route path="VehicleFine" element={<VehicleFine />} />
              {token1 && (
                <Route
                  path={`VehicleFine/${token1}`}
                  element={<VehicleFine />}
                />
              )}
              {token1 && (
                <Route
                  path={`AddVehicleFine/${token1}`}
                  element={<AddVehicleFine />}
                />
              )}
              <Route path="AddVehicleFine" element={<AddVehicleFine />} />
              <Route path="EditVehicleFine" element={<EditVehicleFine />} />
              <Route path="ViewVehicleFine" element={<ViewVehicleFine />} />
              {/* ============ Fuel Expenses ============= */}
              <Route path="FuelExpenses" element={<FuelExpenses />} />
              <Route path="ViewFuelExpenses" element={<ViewFuelExpenses />} />
              <Route path="AddFuelExpenses" element={<AddFuelExpenses />} />
              <Route path="EditFuelExpenses" element={<EditFuelExpenses />} />
              {/* ============ Dispatch Module ============= */}
              <Route path="DispatchCustomer" element={<DispatchCustomer />} />
              {token1 && (
                <Route
                  path={`DispatchCustomer/${token1}`}
                  element={<DispatchCustomer />}
                />
              )}
              {token1 && (
                <Route
                  path={`AddFuelExpenses/${token1}`}
                  element={<AddFuelExpenses />}
                />
              )}
              {token1 && (
                <Route
                  path={`FuelExpenses/${token1}`}
                  element={<FuelExpenses />}
                />
              )}
              {token1 && (
                <Route
                  path={`DeliveryDispatchManagement/${token1}`}
                  element={<DeliveryDispatchManagement />}
                />
              )}
              <Route
                path="DeliveryDispatchManagement"
                element={<DeliveryDispatchManagement />}
              />
              <Route
                path="Adddispatchedcustomer"
                element={<Adddispatchedcustomer />}
              />
              <Route path="ViewDispatch" element={<ViewDispatch />} />
              <Route
                path="AddDispatchCustomer"
                element={<AddDispatchCustomer />}
              />
              {token1 && (
                <Route
                  path={`AddDispatchCustomer/${token1}`}
                  element={<AddDispatchCustomer />}
                />
              )}
              <Route
                path="EditCustomerDispatch"
                element={<EditCustomerDispatch />}
              />
              <Route path="DispatchOrder" element={<DispatchOrder />} />
              <Route path="ViewOrders" element={<ViewOrders />} />
              <Route path="ManualRouting" element={<ManualRouting />} />
              <Route path="DeliveryRequest" element={<DeliveryRequest />} />
              {token1 && (
                <Route
                  path={`DeliveryRequest/${token1}`}
                  element={<DeliveryRequest />}
                />
              )}
              <Route path="VehicleBooking" element={<VehicleBooking />} />
              {token1 && (
                <Route
                  path={`VehicleBooking/${token1}`}
                  element={<VehicleBooking />}
                />
              )}
              {token1 && (
                <Route
                  path={`DeliveryRequestone/${token1}`}
                  element={<DeliveryRequestone />}
                />
              )}
              <Route
                path="DeliveryRequestone"
                element={<DeliveryRequestone />}
              />
              {/* ============ Configuration Checker ============= */}
              <Route
                path="ConfigurationChecker"
                element={<ConfigurationChecker />}
              />
              <Route path="LogoUpdate" element={<LogoUpdate />} />
              <Route
                path="UpdateCustomerLogo"
                element={<UpdateCustomerLogo />}
              />
              <Route path="AddVehicle" element={<AddVehicle />} />
              <Route
                path="AddVehicleCategory"
                element={<AddVehicleCategory />}
              />
              <Route path="NewSyncRequest" element={<NewSyncRequest />} />
              <Route path="VehicleDetails" element={<VehicleDetails />} />
              <Route path="AddVehicleGroup" element={<AddVehicleGroup />} />
              <Route path="LockScreen" element={<LockScreen />} />
              <Route path="AddOnSettingsCart" element={<AddOnSettingsCart />} />
              <Route path="AddHolidays" element={<AddHolidays />} />
              <Route path="EditHolidays" element={<EditHolidays />} />
              <Route path="AddVacation" element={<AddVacation />} />
              <Route path="EditVacation" element={<EditVacation />} />
              <Route path="LuxuryCars" element={<LuxuryCars />} />
              <Route
                path="UpdateCustomerProfile"
                element={<UpdateCustomerProfile />}
              />
              <Route path="Announcement" element={<Announcement />} />
              <Route
                path="EditCustomerDispatch"
                element={<EditCustomerDispatch />}
              />
              {/* ============ Communication Module ============= */}
              <Route path="Announcement" element={<Announcement />} />
              <Route path="DispatchOrder" element={<DispatchOrder />} />
              <Route path="ViewOrders" element={<ViewOrders />} />
              <Route path="ManualRouting" element={<ManualRouting />} />
              <Route path="Merchant" element={<Merchant />} />
              <Route path="AddMerchant" element={<AddMerchant />} />
              <Route path="EditMerchant" element={<EditMerchant />} />
              <Route path="ViewMerchant" element={<ViewMerchant />} />
              <Route path="Addaudio" element={<Addaudio />} />
              <Route path="EditAudio" element={<EditAudio />} />
              <Route path="Email" element={<Email />} />
              <Route path="EmailSupport" element={<EmailSupport />} />
              <Route path="EmailDetails" element={<EmailDetails />} />
              <Route path="ComposeEmail" element={<ComposeEmail />} />
              <Route path="PushNotification" element={<PushNotification />} />
              <Route
                path="NotificationDetails"
                element={<NotificationDetails />}
              />
              <Route
                path="AddPushNotification"
                element={<AddPushNotification />}
              />
              {/* ============ Reports Module ============= */}
              <Route path="Reports" element={<Reports />} />
              {token1 && (
                <Route
                  path={`ManualRouting/${token1}`}
                  element={<ManualRouting />}
                />
              )}
              {token1 && (
                <Route
                  path={`DispatchOrder/${token1}`}
                  element={<DispatchOrder />}
                />
              )}
              {token1 && (
                <Route
                  path={`AddMerchant/${token1}`}
                  element={<AddMerchant />}
                />
              )}
              {token1 && (
                <Route path={`Merchant/${token1}`} element={<Merchant />} />
              )}
              {token1 && (
                <Route path={`Reports/${token1}`} element={<Reports />} />
              )}
              {token1 && (
                <Route
                  path={`ScheduleReport/${token1}`}
                  element={<ScheduleReport />}
                />
              )}
              {token1 && (
                <Route
                  path={`GenerateCustomReport/${token1}`}
                  element={<GenerateCustomReport />}
                />
              )}
              <Route path="ScheduleReport" element={<ScheduleReport />} />
              <Route
                path="GenerateCustomReport"
                element={<GenerateCustomReport />}
              />
              <Route
                path="Driverbehavioureports"
                element={<Driverbehavioureports />}
              />
              <Route path="ReportView" element={<ReportView />} />
              {/* ============ New Users Module ============= */}
              <Route path="Temprature" element={<Temprature />} />
              <Route path="AllUsers" element={<AllUsers />} />
              <Route path="CreateUser" element={<CreateUser />} />
              <Route path="EditUser" element={<EditUser />} />
              {/* ============ Configuration Checker ============= */}
              <Route
                path="ConfigurationChecker"
                element={<ConfigurationChecker />}
              />
              {/* ============ Lock Screeen ============= */}
              <Route path="LockScreen" element={<LockScreen />} />
              {/* ============ Feature Set ============= */}
              <Route path="IVMS" element={<IVMS />} />
              {token1 && <Route path={`IVMS/${token1}`} element={<IVMS />} />}
              {/* ============ User Section ============= */}
              <Route path="UserDashboard" element={<UserDashboard />} />
              <Route path="UserAlert" element={<UserAlert />} />
              {/* ============ ParkingManagement ============= */}
              <Route
                path="ParkingManagementone"
                element={<ParkingManagementone />}
              />
              {token1 && (
                <Route
                  path={`ParkingManagementone/${token1}`}
                  element={<ParkingManagementone />}
                />
              )}
              {/* ============ Vehicle Inspection ============= */}
              <Route
                path="VehicleInspectionDashboard"
                element={<VehicleInspectionDashboard />}
              />
              {token1 && (
                <Route
                  path={`VehicleInspectionDashboard/${token1}`}
                  element={<VehicleInspectionDashboard />}
                />
              )}
              {token1 && (
                <Route
                  path={`VehicleInspection/${token1}`}
                  element={<VehicleInspection />}
                />
              )}
              <Route path="VehicleInspection" element={<VehicleInspection />} />
              <Route path="NewInspection" element={<NewInspection />} />
              {token1 && (
                <Route
                  path={`NewInspection/${token1}`}
                  element={<NewInspection />}
                />
              )}
              <Route
                path="NewVehicleInspection"
                element={<NewVehicleInspection />}
              />
              <Route
                path="ViewInspectionDetails"
                element={<ViewInspectionDetails />}
              />
              {/* ============ Replay Playback ============= */}
              <Route path="ReplayPlayback" element={<ReplayPlayback />} />
              {token1 && (
                <Route
                  path={`ReplayPlayback/${token1}`}
                  element={<ReplayPlayback />}
                />
              )}
              {token1 && (
                <Route
                  path={`RepleyplayBack/${token1}`}
                  element={<RepleyplayBack />}
                />
              )}
              <Route path="RepleyplayBack" element={<RepleyplayBack />} />
              {/* ============ Fuel ManagementDashbord ============= */}
              <Route
                path="FuelManagementDashbord"
                element={<FuelManagementDashbord />}
              />
              {token1 && (
                <Route
                  path={`FuelManagementDashbord/${token1}`}
                  element={<FuelManagementDashbord />}
                />
              )}
              <Route
                path="Immobiliserreports"
                element={<Immobiliserreports />}
              />
              <Route
                path="FuelManagementDetails"
                element={<FuelManagementDetails />}
              />
              {token1 && (
                <Route
                  path={`FuelManagementDetails/${token1}`}
                  element={<FuelManagementDetails />}
                />
              )}
              <Route path="FuelAlerts" element={<FuelAlerts />} />
              {token1 && (
                <Route path={`FuelAlerts/${token1}`} element={<FuelAlerts />} />
              )}
              <Route
                path="VehicleBookingList"
                element={<VehicleBookingList />}
              />
              {token1 && (
                <Route
                  path={`VehicleBookingList/${token1}`}
                  element={<VehicleBookingList />}
                />
              )}
              {/*============ Vehicle Maintance Routes =========*/}
              <Route
                path="AddVehicleMaintenance"
                element={<AddVehicleMaintenance />}
              />
              {token1 && (
                <Route
                  path={`AddVehicleMaintenance/${token1}`}
                  element={<AddVehicleMaintenance />}
                />
              )}
              <Route
                path="EditVehicleMaintenance"
                element={<EditVehicleMaintenance />}
              />
              {token1 && (
                <Route
                  path={`VehicleMaintenance/${token1}`}
                  element={<VehicleMaintenance />}
                />
              )}
              <Route
                path="VehicleMaintenance"
                element={<VehicleMaintenance />}
              />
              <Route
                path="ViewVehicleMaintenance"
                element={<ViewVehicleMaintenance />}
              />
              {/* ====================== MARKET PLACE ========================== */}
              <Route
                path="OfferDriverMarketPlace"
                element={<OfferDriverMarketPlace />}
              />
              <Route
                path="DirectOrderMarketPlace"
                element={<DirectOrderMarketPlace />}
              />
              <Route
                path="OfferVehicleMarketPlace"
                element={<OfferVehicleMarketPlace />}
              />
              <Route
                path="OnlineAuctionMarketPlace"
                element={<OnlineAuctionMarketPlace />}
              />
              <Route
                path="DirectOrderAddVehicle"
                element={<DirectOrderAddVehicle />}
              />
              <Route
                path="DirectOrderOfferLoad"
                element={<DirectOrderOfferLoad />}
              />
              <Route
                path="DirectOrderReferenceDetails"
                element={<DirectOrderReferenceDetails />}
              />
              <Route
                path="DirectOrderVehicleDetails"
                element={<DirectOrderVehicleDetails />}
              />
              <Route />
              <Route
                path="DirectOrderOfferLoad"
                element={<DirectOrderOfferLoad />}
              />
              <Route path="OnlineAuction" element={<OnlineAuction />} />
              <Route path="BidAuctionDetails" element={<BidAuctionDetails />} />
              <Route
                path="AuctionOfferDetails"
                element={<AuctionOfferDetails />}
              />
              <Route path="OrderConfDetails" element={<OrderConfDetails />} />
              <Route path="AuctionAddVehicle" element={<AuctionAddVehicle />} />
              <Route
                path="AuctionReferenceDetails"
                element={<AuctionReferenceDetails />}
              />
              <Route
                path="DirectOrderVehicleDetails"
                element={<DirectOrderVehicleDetails />}
              />
              <Route
                path="DirectOrderConfirmation"
                element={<DirectOrderConfirmation />}
              />
              <Route
                path="OnlineAuctionVehicleDetails"
                element={<OnlineAuctionVehicleDetails />}
              />
            </Routes>
          </AnimatePresence>
        </div>
        <RedirectComponent />
      </Suspense>
    </>
  );
}

export default App;
