import { React, useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { Form } from "react-bootstrap";
import blue_front from "../../assets/images/Vehicle_Icon/Vehicle_Icon_blue_car_front.svg"
import blue_mini from "../../assets/images/Vehicle_Icon/Vehicle_Icon_blue_car_mini.svg"
import blue_side from "../../assets/images/Vehicle_Icon/Vehicle_Icon_blue_car_side.svg"
import { motion } from "framer-motion";

import red_front from "../../assets/images/Vehicle_Icon/Vehicle_Icon_red_car_front.svg"
import red_mini from "../../assets/images/Vehicle_Icon/Vehicle_Icon_red_car_mini.svg"
import red_side from "../../assets/images/Vehicle_Icon/Vehicle_Icon_red_car_side.svg"
import yellow_front from "../../assets/images/Vehicle_Icon/Vehicle_Icon_yellow_car_front.svg"
import yellow_mini from "../../assets/images/Vehicle_Icon/Vehicle_Icon_yellow_car_mini.svg"
import yellow_side from "../../assets/images/Vehicle_Icon/Vehicle_Icon_yellow_car_side.svg"
import wight_side from "../../assets/images/Vehicle_Icon/Vehicle_Icon_wight_car_side.svg"
import green_front from "../../assets/images/Vehicle_Icon/Vehicle_Icon_green_car_front.svg"
import police_front from "../../assets/images/Vehicle_Icon/Vehicle_Icon_police_car_front.svg"
import sport_front from "../../assets/images/Vehicle_Icon/Vehicle_Icon_sport_front.svg"
import track_front from "../../assets/images/Vehicle_Icon/Vehicle_Icon_yellow_track_front.svg"
import taxi_front from "../../assets/images/Vehicle_Icon/Vehicle_Icon_taxi.svg"
import green_mini from "../../assets/images/Vehicle_Icon/Vehicle_Icon_green_car_mini.svg"
import green_side from "../../assets/images/Vehicle_Icon/Vehicle_Icon_green_car_side.svg"
import orange_side from "../../assets/images/Vehicle_Icon/Vehicle_Icon_orange_car_side.svg"
import blue_book from "../../assets/images/Vehicle_Icon/Vehicle_Icon_book.svg"
import blue_book2 from "../../assets/images/Vehicle_Icon/Vehicle_Icon_book2.svg"
import blue_ID from "../../assets/images/Vehicle_Icon/Vehicle_Icon_ID.svg"
import black_side from "../../assets/images/Vehicle_Icon/Vehicle_Icon_black_side.svg"
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AddVehicleCategory = () => {
  const { sidebar, setSidebar, Dark, setDark } = useContext(AppContext);
  const [validated, setValidated] = useState(false);
  const { t, i18n } = useTranslation();

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  return (
    <>
      <motion.div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }}
      >
        <div id="cx-wrapper">
          <div className="main-master-wrapper">
            <div className="Heading">
              <p>{t("Add Vehicle Category")}</p>
            </div>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <div className="row vehicle_caregory">
                <div className="col-md-6 mb-3">
                  <Form.Label className="common-labels" for="typeVi" >{t("Vehicle Type")}</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    id="typeVi"
                    placeholder="Enter Vehicle Type Name..."
                  />
                  <Form.Control.Feedback type="invalid">
                    Select Vehicle Icon
                  </Form.Control.Feedback>
                </div>
                <div className="col-md-6 mb-3 select_vehicle_icon">
                  <Form.Label className="common-labels" For="file">
                    {t("Select Vehicle Icon")}
                  </Form.Label>
                  <Form.Control
                    // style={{ visibility: "hidden" }}
                    type="file"
                    required
                    id="file"
                    placeholder="Browse a file..."
                  />
                  <Form.Control.Feedback type="invalid" tooltip>
                    Vehicle Icon
                  </Form.Control.Feedback>

                </div>
                <div className="row m-0 p-0 mb-3 logo-custom-main">
                  <div className="col-md-6 text-main">
                    <Form.Label className="common-labels">
                      {t("Vehicle Type Description")}
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      required
                      type="text"
                      rows="5"
                      placeholder="Enter Vehicle Type Description..."
                    />
                  </div>
                  <div className="col-md-6">
                    <div className="or-section">
                      <hr />
                      <Form.Label className="common-labels">
                        {t("OR")}
                      </Form.Label>
                      <hr />
                    </div>
                    <div className="innerImages">

                      <label class="custom-checkbox" for="flexRadioDefault1">
                        <input class="d-none" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                        <img src={blue_book} alt="" />
                      </label>
                      <label class="custom-checkbox" for="flexRadioDefault2">
                        <input class="d-none" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                        <img src={blue_ID} alt="" />
                      </label>
                      <label class="custom-checkbox" for="blue_book2">
                        <input class="d-none" type="radio" name="flexRadioDefault" id="blue_book2" />
                        <img src={blue_book2} alt="" />
                      </label>
                      <label class="custom-checkbox" for="taxi_front">
                        <input class="d-none" type="radio" name="flexRadioDefault" id="taxi_front" />
                        <img src={taxi_front} alt="" />
                      </label>
                      <label class="custom-checkbox" for="yellow_mini">
                        <input class="d-none" type="radio" name="flexRadioDefault" id="yellow_mini" />
                        <img src={yellow_mini} alt="" />
                      </label>
                      <label class="custom-checkbox" for="orange_side">
                        <input class="d-none" type="radio" name="flexRadioDefault" id="orange_side" />
                        <img src={orange_side} alt="" />
                      </label>
                      <label class="custom-checkbox" for="yellow_side">
                        <input class="d-none" type="radio" name="flexRadioDefault" id="yellow_side" />
                        <img src={yellow_side} alt="" />
                      </label>
                      <label class="custom-checkbox" for="green_side">
                        <input class="d-none" type="radio" name="flexRadioDefault" id="green_side" />
                        <img src={green_side} alt="" />
                      </label>
                      <label class="custom-checkbox" for="red_side">
                        <input class="d-none" type="radio" name="flexRadioDefault" id="red_side" />
                        <img src={red_side} alt="" />
                      </label>
                      <label class="custom-checkbox" for="green_mini">
                        <input class="d-none" type="radio" name="flexRadioDefault" id="green_mini" />
                        <img src={green_mini} alt="" />
                      </label>
                      <label class="custom-checkbox" for="red_side2">
                        <input class="d-none" type="radio" name="flexRadioDefault" id="red_side2" />
                        <img src={red_side} alt="" />
                      </label>
                      <label class="custom-checkbox" for="green_mini2">
                        <input class="d-none" type="radio" name="flexRadioDefault" id="green_mini2" />
                        <img src={green_mini} alt="" />
                      </label>
                      <label class="custom-checkbox" for="blue_mini">
                        <input class="d-none" type="radio" name="flexRadioDefault" id="blue_mini" />
                        <img src={blue_mini} alt="" />
                      </label>
                      <label class="custom-checkbox" for="yellow_front">
                        <input class="d-none" type="radio" name="flexRadioDefault" id="yellow_front" />
                        <img src={yellow_front} alt="" />
                      </label>
                      <label class="custom-checkbox" for="blue_side">
                        <input class="d-none" type="radio" name="flexRadioDefault" id="blue_side" />
                        <img src={blue_side} alt="" />
                      </label>
                      <label class="custom-checkbox" for="red_mini">
                        <input class="d-none" type="radio" name="flexRadioDefault" id="red_mini" />
                        <img src={red_mini} alt="" />
                      </label>
                      <label class="custom-checkbox" for="police_front">
                        <input class="d-none" type="radio" name="flexRadioDefault" id="police_front" />
                        <img src={police_front} alt="" />
                      </label>
                      <label class="custom-checkbox" for="green_front">
                        <input class="d-none" type="radio" name="flexRadioDefault" id="green_front" />
                        <img src={green_front} alt="" />
                      </label>
                      <label class="custom-checkbox" for="red_front">
                        <input class="d-none" type="radio" name="flexRadioDefault" id="red_front" />
                        <img src={red_front} alt="" />
                      </label>
                      <label class="custom-checkbox" for="car3">
                        <input class="d-none" type="radio" name="flexRadioDefault" id="car3" />
                        <img src={blue_front} alt="" />
                      </label>
                      <label class="custom-checkbox" for="track_front">
                        <input class="d-none" type="radio" name="flexRadioDefault" id="track_front" />
                        <img src={track_front} alt="" />
                      </label>
                      <label class="custom-checkbox" for="wight_side">
                        <input class="d-none" type="radio" name="flexRadioDefault" id="wight_side" />
                        <img src={wight_side} alt="" />
                      </label>
                      <label class="custom-checkbox" for="sport_front">
                        <input class="d-none" type="radio" name="flexRadioDefault" id="sport_front" />
                        <img src={sport_front} alt="" />
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 priview-icon">
                  <p className="common-labels mb-4">{t("Preview")}</p>
                  <img src={red_side} alt="" />
                  <img src={green_side} alt="" />
                  <img src={red_side} alt="" />
                  <img src={blue_mini} alt="" />
                  <img src={black_side} alt="" />
                </div>
                <div className="botttom-btn btn-wrapper">
                  <Link to="/Vehicle"><button className="cx-btn-1">{t("Cancel")}</button></Link>
                  <Link to="/Vehicle"><button className="cx-btn-2">{t("Submit")}</button></Link>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </motion.div >
    </>
  );
};

export default AddVehicleCategory;
