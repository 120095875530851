import { React, useContext } from "react";
import { AppContext } from "../../context/AppContext";
import DDlogo from "../../assets/images/DDlogo.png";
import { motion } from "framer-motion";

const AccessRight = () => {
  const { sidebar, setSidebar, Dark, setDark } = useContext(AppContext);
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  return (
    <>
      <motion.div
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }}
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div id="cx-wrapper" className="Access_Right">
          <div className="main-master-wrapper">
            <div className="innerInputsGen" >
              <div className="row">
                <form>
                  <div className="col-md-6  form_input_main">
                    <div className="innerSelectBox weekCounter form_input_main">
                      <p className="headingMain">User Role</p>
                      <select
                      required
                        className="form-select"
                        aria-label="Default select example"
                      >
                        <option value="">Select User Role</option>
                        <option value="1">Accountant</option>
                        <option value="2">Administratore</option>
                        <option value="3">Driver</option>
                        <option value="3">Vehicle Assistant</option>
                        <option value="3">Dashboard</option>
                        <option value="3">Master Setting</option>
                      </select>
                    </div>
                  </div>
                  <div className="Heading">
                    <p>Accountant</p>
                  </div>
                  <div className="col-md-12">
                    <div className="weekCounter form_input_main">
                      <label className=" accesHeading belowLine">Customer Admin</label>
                      <div className="row">
                        <div className="col-md-4 greenCheck">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="RightsView"
                          />
                          <label className="innerLabel" htmlFor="RightsView">View Admin</label>
                        </div>
                        <div className="col-md-4 greenCheck">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="RightsView2"
                          />
                          <label className="innerLabel" htmlFor="RightsView2">Manage Admin</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="weekCounter form_input_main">
                      <label className=" accesHeading belowLine">Transport Manager</label>
                      <div className="row">
                        <div className="col-md-4 greenCheck">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="RightsView1"
                          />
                          <label className="innerLabel" htmlFor="RightsView1">View Tm</label>
                        </div>
                        <div className="col-md-4 greenCheck">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="RightsView3"
                          />
                          <label className="innerLabel" htmlFor="RightsView3">Manage Tm</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="weekCounter form_input_main">
                      <label className=" accesHeading belowLine">Drivers</label>
                      <div className="row">
                        <div className="col-md-4 greenCheck">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="RightsView4"
                          />
                          <label className="innerLabel" htmlFor="RightsView4">View Driver</label>
                        </div>
                        <div className="col-md-4 greenCheck">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="RightsView5"
                          />
                          <label className="innerLabel" htmlFor="RightsView5">Manage Driver</label>
                        </div>
                        <div className="col-md-4 greenCheck">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="RightsView6"
                          />
                          <label className="innerLabel" htmlFor="RightsView6">Delete Driver</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="weekCounter form_input_main">
                      <label className="accesHeading belowLine">Vehicle Assistant</label>
                      <div className="row">
                        <div className="col-md-4 greenCheck">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="RightsVehicle1"
                          />
                          <label className="innerLabel" htmlFor="RightsVehicle1">
                          View Vehicle Assistant
                          </label>
                        </div>
                        <div className="col-md-4 greenCheck">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="RightsViewVa"
                          />
                          <label className="innerLabel" htmlFor="RightsViewVa">Manage Vehicle Assistant</label>
                        </div>
                        <div className="col-md-4 greenCheck">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="RightsDelete"
                          />
                          <label className="innerLabel" htmlFor="RightsDelete">
                          Delete Vehicle Assistant
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="weekCounter form_input_main">
                      <label className=" accesHeading belowLine">Dashboard</label>
                      <div className="row">
                        <div className="col-md-4 greenCheck">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="RightsVehicl54"
                          />
                          <label className="innerLabel" htmlFor="RightsVehicl54">
                             Dashboard Vehicle
                          </label>
                        </div>
                        <div className="col-md-4 greenCheck">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="RightsView99"
                          />
                          <label className="innerLabel" htmlFor="RightsView99">
                             Dashboard Map
                          </label>
                        </div>
                        <div className="col-md-4 greenCheck">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="RightsPending"
                          />
                          <label className="innerLabel" htmlFor="RightsPending">
                             Dashboard Pending
                          </label>
                        </div>
                        <div className="col-md-4 greenCheck">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="RightsStatics"
                          />
                          <label className="innerLabel" htmlFor="RightsStatics">
                             Dashboard Statistics
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="weekCounter form_input_main">
                      <label className=" accesHeading belowLine">
                        Master Settings & Scheduled Pickups{" "}
                      </label>
                      <div className="row mb-2">
                        <div className="multiHeading">
                          <label>Customer Profile & Logo</label>
                        </div>
                        <div className="col-md-4 greenCheck">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="RightsCustomerPro"
                          />
                          <label className="innerLabel" htmlFor="RightsCustomerPro">
                            Right to View Customer Profile
                          </label>
                        </div>
                        <div className="col-md-4 greenCheck">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="RightsCustomerManage"
                          />
                          <label className="innerLabel" htmlFor="RightsCustomerManage">
                            Right to manage Customer Profile{" "}
                          </label>
                        </div>
                        <div className="col-md-4 greenCheck mb-2">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="RightsCustomerLogo"
                          />
                          <label className="innerLabel" htmlFor="RightsCustomerLogo">
                            Right to Manage Customer Logo
                          </label>
                        </div>
                        <div className="multiHeading">
                          <label>Academic Settings</label>
                        </div>
                        <div className="col-md-12 greenCheck mb-2">

                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="RightsAcedmicSet"
                          />
                          <label className="innerLabel" htmlFor="RightsAcedmicSet">
                            Right to manage Academic Settings
                          </label>
                        </div>
                        <div className="multiHeading">
                          <label>Shift</label>
                        </div>
                        <div className="col-md-12 greenCheck mb-2">

                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="RightsmanShift"
                          />
                          <label className="innerLabel" htmlFor="RightsmanShift">
                            Right to manage Shift
                          </label>
                        </div>
                        <div className="multiHeading">
                          <label>Vehicle Types & Vehicles</label>
                        </div>
                        <div className="col-md-4 greenCheck">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="RightsVehicleType"
                          />
                          <label className="innerLabel" htmlFor="RightsVehicleType">
                            Right to View Vehicle Type
                          </label>
                        </div>
                        <div className="col-md-4 greenCheck">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="RightManVehType"
                          />
                          <label className="innerLabel" htmlFor="RightManVehType">
                            Right to manage Vehicle Type
                          </label>
                        </div>
                        <div className="col-md-4 greenCheck mb-2">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="RightsToVVeh"
                          />
                          <label className="innerLabel" htmlFor="RightsToVVeh">
                            Right to view Vehicle
                          </label>
                        </div>
                        <div className="col-md-4 greenCheck mb-2">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="RightToManVeh2"
                          />
                          <label className="innerLabel" htmlFor="RightToManVeh2">
                            Right to manage Vehicle
                          </label>
                        </div>
                        <div className="multiHeading">
                          <label>Vehicle Assignment</label>
                        </div>
                        <div className="col-md-4 greenCheck mb-2">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="RightsVehAsses"
                          />
                          <label className="innerLabel" htmlFor="RightsVehAsses">
                            Right to View Vehicle Assignment
                          </label>
                        </div>
                        <div className="col-md-4 greenCheck mb-2">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="RightsManAsses3"
                          />
                          <label className="innerLabel" htmlFor="RightsManAsses3">
                            Right to manage Vehicle Assignment
                          </label>
                        </div>
                        <div className="multiHeading">
                          <label>General Settings</label>
                        </div>
                        <div className="col-md-12 greenCheck mb-2">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="RightToGenSet"
                          />
                          <label className="innerLabel" htmlFor="RightToGenSet">
                            Right to View General Settings
                          </label>
                        </div>
                        <div className="multiHeading">
                          <label>Notification Settings</label>
                        </div>
                        <div className="col-md-12 greenCheck mb-2">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="RightToViwNotiSet"
                          />
                          <label className="innerLabel" htmlFor="RightToViwNotiSet">
                            Right to View Notification Settings
                          </label>
                        </div>
                        <div className="multiHeading">
                          <label>Configuration Checker</label>
                        </div>
                        <div className="col-md-12 greenCheck mb-2">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="RightsConfigChecker"
                          />
                          <label className="innerLabel" htmlFor="RightsConfigChecker">
                            Right to View Configuration Checker
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="weekCounter form_input_main">
                      <label className=" accesHeading belowLine">
                        Instant Trips, Vehicle Expenses, Payment, Announcement
                      </label>
                      <div className="row mb-2">
                        <div className="multiHeading">
                          <label>Routes</label>
                        </div>
                        <div className="col-md-4 greenCheck">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="RightsVewROute3"
                          />
                          <label className="innerLabel" htmlFor="RightsVewROute3">
                             View Route
                          </label>
                        </div>
                        <div className="col-md-4 greenCheck">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="RightsManrou3"
                          />
                          <label className="innerLabel" htmlFor="RightsManrou3">
                             Manage Route
                          </label>
                        </div>
                        <div className="col-md-4 greenCheck mb-2">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="RightsTestRou5"
                          />
                          <label className="innerLabel" htmlFor="RightsTestRou5">
                             Test Route
                          </label>
                        </div>
                        <div className="col-md-4 greenCheck mb-2">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="RightsApprove0"
                          />
                          <label className="innerLabel" htmlFor="RightsApprove0">
                             Approve Route
                          </label>
                        </div>
                        <div className="multiHeading">
                          <label>Pickup Points</label>
                        </div>
                        <div className="col-md-4 greenCheck mb-2">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="viewPickPoints"
                          />
                          <label className="innerLabel" htmlFor="viewPickPoints">
                             View Pickuppoint
                          </label>
                        </div>
                        <div className="col-md-4 greenCheck mb-2">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="managePickPoints"
                          />
                          <label className="innerLabel" htmlFor="managePickPoints">
                             Manage Pickuppoint
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="weekCounter form_input_main">
                      <label className=" accesHeading belowLine">Notification</label>
                      <div className="row mb-2">
                        <div className="multiHeading">
                          <label>Sheduled Trips</label>
                        </div>
                        <div className="col-md-4 greenCheck">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="rightsToManage99T"
                          />
                          <label className="innerLabel" htmlFor="rightsToManage99T">
                             Manage Trips
                          </label>
                        </div>
                        <div className="col-md-4 greenCheck">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="RightsViInstPs"
                          />
                          <label className="innerLabel" htmlFor="RightsViInstPs">
                             View Instant Trips
                          </label>
                        </div>
                        <div className="col-md-4 greenCheck mb-2">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="RightsDeViewTrips"
                          />
                          <label className="innerLabel" htmlFor="RightsDeViewTrips">
                             View Trips
                          </label>
                        </div>
                        <div className="col-md-4 greenCheck mb-2">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="RightsExpManaVeh"
                          />
                          <label className="innerLabel" htmlFor="RightsExpManaVeh">
                             Manage Vehicle Expense
                          </label>
                        </div>
                        <div className="col-md-4 greenCheck">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="RightsVPaymentVE"
                          />
                          <label className="innerLabel" htmlFor="RightsVPaymentVE">
                             Online Payment
                          </label>
                        </div>
                        <div className="col-md-4 greenCheck">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="RightInstTrips54"
                          />
                          <label className="innerLabel" htmlFor="RightInstTrips54">
                             Manage Instant Trips
                          </label>
                        </div>
                        <div className="col-md-4 greenCheck mb-2">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="RightsAnnouncVeh09"
                          />
                          <label className="innerLabel" htmlFor="RightsAnnouncVeh09">
                             Manage Announcement Vehicle
                          </label>
                        </div>
                        <div className="col-md-4 greenCheck mb-2">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="RightsToNotiSec99"
                          />
                          <label className="innerLabel" htmlFor="RightsToNotiSec99">
                             Manage Notification Admin
                          </label>
                        </div>
                        <div className="col-md-4 greenCheck mb-2">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="Rpaymentsight"
                          />
                          <label className="innerLabel" htmlFor="Rpaymentsight">
                             Manage Payment
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="weekCounter form_input_main">
                      <label className=" accesHeading belowLine">Historical Data</label>
                      <div className="row mb-2">
                        <div className="col-md-12 greenCheck">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="RightsHistoric"
                          />
                          <label className="innerLabel" htmlFor="RightsHistoric">
                             Manage Notification Tm
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="weekCounter form_input_main">
                      <label className=" accesHeading belowLine">Reports</label>
                      <div className="row mb-2">
                        <div className="col-md-4 greenCheck">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="RightsReponotiDr"
                          />
                          <label className="innerLabel" htmlFor="RightsReponotiDr">
                             Manage Notification Driver
                          </label>
                        </div>
                        <div className="col-md-4 greenCheck">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="RightsRepoNotiFep"
                          />
                          <label className="innerLabel" htmlFor="RightsRepoNotiFep">
                             Manage Notification Helper
                          </label>
                        </div>
                        <div className="col-md-4 greenCheck mb-2">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="RightRepoNotiAcount"
                          />
                          <label className="innerLabel" htmlFor="RightRepoNotiAcount">
                             Manage Notification Accountant
                          </label>
                        </div>
                        <div className="col-md-4 greenCheck mb-2">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="RightsRepoMsgs"
                          />
                          <label className="innerLabel" htmlFor="RightsRepoMsgs">
                             Messages
                          </label>
                        </div>
                        <div className="col-md-4 greenCheck">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="RightsTagRepo"
                          />
                          <label className="innerLabel" htmlFor="RightsTagRepo">
                             Manage Tag Prints
                          </label>
                        </div>
                        <div className="col-md-4 greenCheck">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="RightsRepoNotiVen"
                          />
                          <label className="innerLabel" htmlFor="RightsRepoNotiVen">
                             Manage Notification Tag Vendor
                          </label>
                        </div>
                        <div className="col-md-4 greenCheck mb-2">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="RightsRepoManRigSur"
                          />
                          <label className="innerLabel" htmlFor="RightsRepoManRigSur">
                             Manage Notification Surveyor
                          </label>
                        </div>
                        <div className="col-md-4 greenCheck mb-2">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="RightsDeManPlac"
                          />
                          <label className="innerLabel" htmlFor="RightsDeManPlac">
                             Manage Notification Route Planner
                          </label>
                        </div>
                        <div className="col-md-4 greenCheck mb-2">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="RightsSupporRepo"
                          />
                          <label className="innerLabel" htmlFor="RightsSupporRepo">
                             Manage Notification Support
                          </label>
                        </div>
                        <div className="col-md-4 greenCheck mb-2">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="RightsDeRpeoHsi"
                          />
                          <label className="innerLabel" htmlFor="RightsDeRpeoHsi">
                             Historical Data
                          </label>
                        </div>
                        <div className="col-md-4 greenCheck mb-2">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="RightsTrEreome"
                          />
                          <label className="innerLabel" htmlFor="RightsTrEreome">
                             Transportation Report
                          </label>
                        </div>
                        <div className="col-md-4 greenCheck mb-2">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="RightsDRiverPreo"
                          />
                          <label className="innerLabel" htmlFor="RightsDRiverPreo">
                             Driver Report
                          </label>
                        </div>
                        <div className="col-md-4 greenCheck mb-2">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="RightsVihePort"
                          />
                          <label className="innerLabel" htmlFor="RightsVihePort">
                             Vehicle Report
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="d-flex justify-content-end align-items-center btn-wrapper access-right-btn-wrapper">
                <button className="cx-btn-1">Cancel</button>
                <button className="cx-btn-2">Submit</button>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default AccessRight;