import React, { useContext, useState } from "react";
import { Form } from "react-bootstrap";
import { AppContext } from "../../context/AppContext";
import Rcheck from "../../assets/images/red-check.svg";
import Gcheck from "../../assets/images/Green-check.svg";
// import DDlogo from "../../assets/images/DDlogo.png";
import Close from "../../assets/images/Close.svg";
import Geofance from "../../assets/images/Geo-fance.svg";
import Rectangle from "../../assets/images/Rectangle.svg";
import plam from "../../assets/images/plam.svg";
import { useNavigate } from "react-router";
import Multiselect from "multiselect-react-dropdown";
import whiteTrash from "../../assets/images/whiteTrash.svg";
import whiteHand from "../../assets/images/whiteHand.svg";
import whiteBox from "../../assets/images/whiteBox.svg";
import whiteBin from "../../assets/images/whiteBin.svg";
import { Link } from "react-router-dom";
import Calendar from "../../assets/images/calendar.svg";
import DatePicker from "react-datepicker";
import { motion } from "framer-motion";
import MapComponent from "../../sharedComponent/MapComponent";
import { useTranslation } from "react-i18next";

const EditVehicle = () => {
  const [form1, setForm1] = useState(true);
  const [form2, setForm2] = useState(false);
  const [form3, setForm3] = useState(false);
  const [form4, setForm4] = useState(false);
  const [form5, setForm5] = useState(false);
  const [addnew, setAddnew] = useState(1);
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const handelNext = () => {
    if (
      form1 == true &&
      form2 == false &&
      form3 == false &&
      form5 == false &&
      form5 == false
    ) {
      setForm1(false);
      setForm2(true);
      setForm3(false);
      setForm4(false);
      setForm5(false);
    } else if (
      form1 == false &&
      form2 == true &&
      form3 == false &&
      form4 == false &&
      form5 == false
    ) {
      setForm1(false);
      setForm2(false);
      setForm3(true);
      setForm4(false);
      setForm5(false);
    } else if (
      form1 == false &&
      form2 == false &&
      form3 == true &&
      form4 == false &&
      form5 == false
    ) {
      setForm1(false);
      setForm2(false);
      setForm3(false);
      setForm4(true);
      setForm5(false);
    } else if (
      form1 == false &&
      form2 == false &&
      form3 == false &&
      form4 == true &&
      form5 == false
    ) {
      setForm1(false);
      setForm2(false);
      setForm3(false);
      setForm4(false);
      setForm5(true);
    } else if (form5 == true) {
      navigate("/Vehicle");
    }
  };
  const handelback = () => {
    if (
      form1 == true &&
      form2 == false &&
      form3 == false &&
      form4 == false &&
      form5 == false
    ) {
      navigate("/Vehicle");
    } else if (
      form1 == false &&
      form2 == true &&
      form3 == false &&
      form4 == false &&
      form5 == false
    ) {
      setForm1(true);
      setForm2(false);
      setForm3(false);
      setForm4(false);
      setForm5(false);
    } else if (
      form1 == false &&
      form2 == false &&
      form3 == true &&
      form4 == false &&
      form5 == false
    ) {
      setForm1(false);
      setForm2(true);
      setForm3(false);
      setForm4(false);
      setForm5(false);
    } else if (
      form1 == false &&
      form2 == false &&
      form3 == false &&
      form4 == true &&
      form5 == false
    ) {
      setForm1(false);
      setForm2(false);
      setForm3(true);
      setForm4(false);
      setForm5(false);
    } else if (
      form1 == false &&
      form2 == false &&
      form3 == false &&
      form4 == false &&
      form5 == true
    ) {
      setForm1(false);
      setForm2(false);
      setForm3(false);
      setForm4(true);
      setForm5(false);
    }
  };

  const [validated, setValidated] = useState(false);
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  const projectList = () => {
    setAddnew(addnew + 1);
  };
  const RemoveProject = (index) => {
    setAddnew(addnew - 1);
  };

  const { sidebar, setSidebar, Dark, setDark } = useContext(AppContext);
  const [startDate, setStartDate] = useState(new Date());
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  return (
    <motion.div
      className={sidebar ? "taskMain " : "cx-active taskMain"}
      id="cx-main"
      variants={aninations}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.1 }}
    >
      <div id="cx-wrapper">
        <div
          className="main-master-wrapper addvhical-main custom_height_Form_full"
          id="Add-vehicle"
        >
          <div className="add-vehicle-form-wrapper">
            <div className="addvhical-heading">
              <p>{t("Edit Vehicle")}</p>
            </div>
            <div className="stepperauto">
              <div className="AddVehicle-steper">
                <div className="single-step active">
                  <p>{t("Vehicle Information")}</p>
                  <img src={Gcheck} alt="" />
                </div>
                {form2 == true ||
                  form3 == true ||
                  form4 == true ||
                  form5 == true ? (
                  <div className="single-step active">
                    <p>{t("Tracking Device")}</p>
                    <img src={Gcheck} alt="" />
                  </div>
                ) : (
                  <div className="single-step">
                    <p>{t("Tracking Device")}</p>
                    <img src={Rcheck} alt="" />
                  </div>
                )}
                {form3 == true || form4 == true || form5 == true ? (
                  <div className="single-step active">
                    <p>{t("Temperature Sensor")}</p>
                    <img src={Gcheck} alt="" />
                  </div>
                ) : (
                  <div className="single-step">
                    <p>{t("Temperature Sensor")}</p>
                    <img src={Rcheck} alt="" />
                  </div>
                )}
                {form4 == true || form5 == true ? (
                  <div className="single-step active">
                    <p>{t("Validity Informations")}</p>
                    <img src={Gcheck} alt="" />
                  </div>
                ) : (
                  <div className="single-step">
                    <p>{t("Validity Informations")}</p>
                    <img src={Rcheck} alt="" />
                  </div>
                )}
                {form5 == true ? (
                  <div className="single-step last-step active">
                    <p>{t("Vehicle Geo-fence")}</p>
                    <img src={Gcheck} alt="" />
                  </div>
                ) : (
                  <div className="single-step">
                    <p>{t("Vehicle Geo-fence")}</p>
                    <img src={Rcheck} alt="" />
                  </div>
                )}
              </div>
            </div>
            <div className="main-form-section">
              {form1 == true ? (
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <div className="information-card innerInputsGen row mt-4 mb-0">
                    <div className="col-md-6 form_input_main">
                      <Form.Label className="common-labels">
                        {t("Vehicle Number/Name")} <span>&#42;</span>
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Enter Vehicle Name/Number..."
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Vehicle Number/Name.
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 form_input_main">
                      <Form.Label className="common-labels">
                        {t("Registration Number")} <span>&#42;</span>
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Enter Registration Number..."
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Registration Number.
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 form_input_main">
                      <div className="d-flex justify-content-between flex-wrap">
                        <Form.Label className="common-labels label-with-radio custom-label-title form_input_main me-0">
                          <p>
                            {t("Vehicle Category")} <span>&#42;</span>
                          </p>
                        </Form.Label>
                        {/* <div className="radio-wrapper">
                          <p>
                            <label for="test2">Exisiting</label>
                            <input type="radio" id="test2" name="radio-group" />
                            <label for="test2"></label>
                          </p>
                          <p>
                            <label for="test3">New</label>
                            <input type="radio" id="test3" name="radio-group" />
                            <label for="test3"></label>
                          </p>
                        </div> */}
                        <div id="customRadios">
                          <div class="form-check greenFlex me-2">
                            <input class="form-check-input" type="radio" name="flexRadioDefault2" id="Exisiting" />
                            <label class="form-check-label custLabel" for="Exisiting">
                              {t("Exisiting")}
                            </label>
                          </div>
                          <div class="form-check  greenFlex">
                            <input class="form-check-input" type="radio" name="flexRadioDefault2" id="New" />
                            <label class="form-check-label custLabel" for="New">
                              {t("New")}
                            </label>
                          </div>
                        </div>
                      </div>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Select Vehicle Category..."
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Select Vehicle Category.
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 form_input_main">
                      <div className="">
                        <div className="d-flex justify-content-between flex-wrap">
                          <Form.Label className="common-labels label-with-radio custom-label-title me-0">
                            <p>
                              {t("Vehicle Capacity")} <span>&#42;</span>
                            </p>
                          </Form.Label>
                          <div id="customRadios">
                            <div class="form-check greenFlex me-2">
                              <input class="form-check-input" type="radio" name="flexRadioDefault1" id="Goods" />
                              <label class="form-check-label custLabel" for="Goods">
                                {t("Goods")}
                              </label>
                            </div>
                            <div class="form-check  greenFlex">
                              <input class="form-check-input" type="radio" name="flexRadioDefault1" id="Passenger" />
                              <label class="form-check-label custLabel" for="Passenger">
                                {t("Passenger")}
                              </label>
                            </div>
                          </div>
                        </div>
                        <select
                          required
                          className="form-select"
                          aria-label="Default select example"
                          placeholder="Select Vehicle Capacity..."
                        >
                          <option selected value="">Select Vehicle Capacity...</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6 form_input_main">
                      <Form.Label className="common-labels">
                        {t("Model Name & Number")}
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Enter Model Name & Number..."
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Registration Number.
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 form_input_main">
                      <Form.Label className="common-labels">
                        {t("Make Year")}
                      </Form.Label>
                      <div className="innerSelectBox weekCounter datepicker-main">
                        <DatePicker
                          required
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          className="form-control"
                        />
                        <img src={Calendar} className="calendarLogo" alt="" />
                      </div>
                      <Form.Control.Feedback type="invalid">
                        Please Enter Registration Number.
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 form_input_main">
                      <Form.Label className="common-labels">
                        {t("Chassis Number or VIN number")}
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Enter Number or VIN number ..."
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Registration Number.
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 form_input_main">
                      <Form.Label className="common-labels">
                        {t("Engine Number")}
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Enter Engine Number ..."
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Registration Number.
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 form_input_main">
                      <div className="innerSelectBox ">
                        <Form.Label className="common-labels custom-label-title me-0">
                          <p>{t("Fuel Type")}</p>
                        </Form.Label>
                        <select
                          required
                          className="form-select"
                          aria-label="Default select example"
                          placeholder="Select Fuel Type..."
                        >
                          <option selected value="">Select Fuel Type...</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6 form_input_main">
                      <Form.Label className="common-labels">
                        {t("Odometer Reading Cart")}
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Enter Odometer Reading ..."
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Registration Number.
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 form_input_main">
                      <div className="innerSelectBox ">
                        <Form.Label className="common-labels custom-label-title me-0">
                          <p>{t("Driver")}</p>
                        </Form.Label>
                        <select
                          required
                          className="form-select"
                          aria-label="Default select example"
                          placeholder="Select Driver..."
                        >
                          <option selected value="">Select Driver...</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                      </div>
                    </div>
                    {/* <div className="col-md-6">
                                <div className="innerSelectBox ">
                                    <Form.Label className="common-labels custom-label-title me-0">
                                        <p>Vehicle Group</p>
                                    </Form.Label>
                                    <select
                                        className="form-select"
                                        aria-label="Default select example"
                                        placeholder='Select Vehicle Group...'
                                    >
                                        <option selected>Open this select menu</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </select>
                                    
                                </div>
                            </div> */}
                    <div className="col-md-6 mb-3">
                      <Form.Label className="common-labels custom-label-title me-0">
                        <p>{t("Vehicle Group")}</p>
                      </Form.Label>
                      <div className="dropdown-wrapper w-100">
                        <div className="multi-select-1 w-100">
                          <Multiselect
                            className="multi-select-main"
                            displayValue="key"
                            onKeyPressFn={function noRefCheck() { }}
                            onRemove={function noRefCheck() { }}
                            onSearch={function noRefCheck() { }}
                            onSelect={function noRefCheck() { }}
                            options={[
                              {
                                cat: "Group 1",
                                key: "Option 1",
                              },
                              {
                                cat: "Group 1",
                                key: "Option 2",
                              },
                              {
                                cat: "Group 1",
                                key: "Option 3",
                              },
                              {
                                cat: "Group 2",
                                key: "Option 4",
                              },
                              {
                                cat: "Group 2",
                                key: "Option 5",
                              },
                              {
                                cat: "Group 2",
                                key: "Option 6",
                              },
                              {
                                cat: "Group 2",
                                key: "Option 7",
                              },
                            ]}
                            showCheckbox
                            placeholder="Select Vehicle Group ..."
                            style={{
                              chips: {
                                background: "#8f4300",
                                "border-radius": "20px",
                              },
                              input: {
                                "&::checked": {
                                  color: "#8f4300",
                                },
                              },
                              searchBox: {},
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              ) : (
                ""
              )}
              {form2 == true ? (
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <div className="information-card innerInputsGen row mt-4 mb-0">
                    <div className="col-md-6 form_input_main">
                      <Form.Label className="common-labels custom-label-title">
                        <p>
                          {t("IMEI Number")} <span>&#42;</span>
                        </p>
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Enter IMEI Number..."
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter IMEI Number .
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 form_input_main">
                      <div className="innerSelectBox ">
                        <Form.Label className="common-labels custom-label-title me-0">
                          <p>
                            {t("Type of Device")} <span>&#42;</span>
                          </p>
                        </Form.Label>
                        <select
                          required
                          className="form-select"
                          aria-label="Default select example"
                          placeholder="Select Type of Device ..."
                        >
                          <option selected value="">Select Type of Device</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6 form_input_main">
                      <Form.Label className="common-labels">
                        {t("Sim Card Number")}
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Enter Sim card Number..."
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Sim card Number.
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 form_input_main">
                      <Form.Label className="common-labels">
                        {t("Sim Telephone Number")}
                      </Form.Label>
                      <Form.Control
                        required
                        type="type"
                        placeholder="Enter Sim Telephone Number ..."
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Sim telephone Number.
                      </Form.Control.Feedback>
                    </div>
                  </div>
                </Form>
              ) : (
                ""
              )}
              {form3 == true ? (
                <>
                  {[...Array(addnew)].map((rec, index) => {
                    return (
                      <div className="addNew-main">
                        <div className="addnew-head">
                          <p>{t("Temperature Sensor")} {index + 1}</p>
                          {addnew >= 1 ? (
                            <img
                              src={Close}
                              alt=""
                              onClick={() => RemoveProject()}
                              className="c-pointer"
                            />
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="addNew-body row">
                          <div className="col-md-6 form_input_main">
                            <Form.Label className="common-labels custom-label-title">
                              <p>{t("Sensor Name")}</p>
                            </Form.Label>
                            <Form.Control
                              required
                              type="text"
                              placeholder="Enter Sensor Name"
                            />
                          </div>
                          <div className="col-md-6 form_input_main demo-section"></div>
                          <div className="col-md-6 form_input_main">
                            <Form.Label className="common-labels custom-label-title">
                              <p>
                                {t("Minimum Temperature Threshold on Vehicle Running")}
                              </p>
                            </Form.Label>
                            <Form.Control
                              required
                              type="text"
                              placeholder="Enter Minimum Temperature Threshold on Vehicle Running"
                            />
                          </div>
                          <div className="col-md-6 form_input_main">
                            <Form.Label className="common-labels custom-label-title">
                              <p>
                                {t("Maximum Temperature Threshold on Vehicle Running")}
                              </p>
                            </Form.Label>
                            <Form.Control
                              required
                              type="text"
                              placeholder="Enter Maximum Temperature Threshold on Vehicle Running"
                            />
                          </div>
                          <div className="col-md-6 form_input_main">
                            <Form.Label className="common-labels custom-label-title">
                              <p>
                                {t("Minimum Temperature Threshold on Vehicle Parked")}
                              </p>
                            </Form.Label>
                            <Form.Control
                              required
                              type="text"
                              placeholder="Enter Minimum Temperature Threshold on Vehicle Parked"
                            />
                          </div>
                          <div className="col-md-6 form_input_main">
                            <Form.Label className="common-labels custom-label-title">
                              <p>
                                {t("Maximum Temperature Threshold on Vehicle Parked")}
                              </p>
                            </Form.Label>
                            <Form.Control
                              required
                              type="text"
                              placeholder="Enter Maximum Temperature Threshold on Vehicle Parked"
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <button
                    className="cx-btn-2 bordernone mb-3"
                    onClick={() => projectList()}
                  >
                    + {t("Add New")}
                  </button>
                </>
              ) : (
                ""
              )}
              {form4 == true ? (
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <div className="information-card innerInputsGen row mt-4 mb-0">
                    <div className="col-md-6 form_input_main">
                      <Form.Label className="common-labels">
                        {t("Insurance Start Date")}
                      </Form.Label>
                      <div className="innerSelectBox weekCounter datepicker-main">
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          className="form-control"
                        />
                        <img src={Calendar} className="calendarLogo" alt="" />
                      </div>
                    </div>
                    <div className="col-md-6 form_input_main">
                      <Form.Label className="common-labels">
                        {t("Insurance End Date")}
                      </Form.Label>
                      <div className="innerSelectBox weekCounter datepicker-main">
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          className="form-control"
                        />
                        <img src={Calendar} className="calendarLogo" alt="" />
                      </div>
                    </div>
                    <div className="col-md-6 form_input_main">
                      <Form.Label className="common-labels">
                        {t("Permit Start Date")}
                      </Form.Label>
                      <div className="innerSelectBox weekCounter datepicker-main">
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          className="form-control"
                        />
                        <img src={Calendar} className="calendarLogo" alt="" />
                      </div>
                    </div>
                    <div className="col-md-6 form_input_main">
                      <Form.Label className="common-labels">
                        {t("Permit End Date")}
                      </Form.Label>
                      <div className="innerSelectBox weekCounter datepicker-main">
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          className="form-control"
                        />
                        <img src={Calendar} className="calendarLogo" alt="" />
                      </div>
                    </div>
                    <div className="col-md-6 form_input_main">
                      <Form.Label className="common-labels">
                        {t("Municipality Permission Start Date")}
                      </Form.Label>
                      <div className="innerSelectBox weekCounter datepicker-main">
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          className="form-control"
                        />
                        <img src={Calendar} className="calendarLogo" alt="" />
                      </div>
                    </div>
                    <div className="col-md-6 form_input_main">
                      <Form.Label className="common-labels">
                        {t("Municipality Permission End Date")}
                      </Form.Label>
                      <div className="innerSelectBox weekCounter datepicker-main">
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          className="form-control"
                        />
                        <img src={Calendar} className="calendarLogo" alt="" />
                      </div>
                    </div>
                  </div>
                </Form>
              ) : (
                ""
              )}
              {form5 == true ? (
                <>
                  <div className="addnew-map">
                    <div className="map-head">
                      <p>
                        {t("If you want to set geofence for vehicle separately, youcan use below tools to draw geofence")}.
                      </p>
                    </div>
                    <div className="road-map addParkingMap">
                      
                    <MapComponent />
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="btn-common btn-wrapper">
            <button className="cx-btn-1" onClick={handelback}>
              {form1 === true ? t("Back") : t("Back")}
            </button>
            <button className="cx-btn-2" onClick={handelNext}>
              {form5 === true ? t("Submit") : t("Next")}
            </button>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default EditVehicle;
