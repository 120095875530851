// import React from 'react'

// const UserRole = () => {
//   return (
//     <div>UserRole</div>
//   )
// }

// export default UserRole

import { React, useContext, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import delete_icon from "../../../assets/images/delete.svg";
import { Tab, Tabs, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Calendar from "../../../assets/images/calendar.svg";
import DatePicker from "react-datepicker";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
import import_icon from "../../../assets/images/import_icon.svg";
import export_icon from "../../../assets/images/export_icon.svg";
import edit_icon from "../../../assets/images/ic-edit.svg";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { motion } from "framer-motion";
import Pagenation from "../../../sharedComponent/Pagenation";
import ReactSelect from "../../../sharedComponent/ReactSelect";
import { useTranslation } from "react-i18next";

const UserRole = () => {
  const { sidebar, setSidebar, Dark, setDark } = useContext(AppContext);
  const [show, setShow] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [edit, setEdit] = useState(false);
  const [create, setCreate] = useState(false);
  const { t, i18n } = useTranslation();

  const handleCloseCreate = () => setCreate(false);
  const handleShowCreate = () => setCreate(true);
  const handleCloseEdit = () => setEdit(false);
  const handleShowEdit = () => setEdit(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  const renderTooltipForEdit = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {t("Edit")}
    </Tooltip>
  );
  const renderTooltipForDelete = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {t("Delete")}
    </Tooltip>
  );
  return (
    <>
      <motion.div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }}
      >
        <div id="cx-wrapper" className="Holidays_Main">
          <div className="holidays-filter-wrapper">
            <div className=""></div>
            <div className="right-wrapper">
              <Link
                to="#"
                onClick={handleShowCreate}
                className="add-holiday-btn"
                variant="primary"
              >
                + {t("Create Role")}
              </Link>
              <Link to="#" className="import-icon">
                <img src={import_icon} alt="" />
              </Link>
              <Link to="#" className="export-icon">
                <img src={export_icon} alt="" />
              </Link>
            </div>
          </div>
          <div className="main-master-wrapper mt-3">
            <div id="scroll_insideThe_Padding_tabel">
              <table className="holiday-table">
                <thead className="ht-head userRoleTable">
                  <tr>
                    <td className="">{t("Sr.No.")}</td>
                    <td>{t("Role")}</td>
                    <td>{t("Status")}</td>
                    <td>{t("Action")}</td>
                  </tr>
                </thead>
                <tbody className="ht-body">
                  <tr className="table-row-custom">
                    <td>1</td>
                    <td>Admin</td>
                    <td>Inactive</td>
                    <td className="d-flex">
                      <OverlayTrigger
                        placement="left"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipForEdit}
                      >
                        <Link to="#" onClick={handleShowEdit}>
                          <div className="inconsIn">
                            <img src={edit_icon} alt="" />
                          </div>
                        </Link>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipForDelete}
                      >
                        <Link to="#" onClick={handleShow}>
                          <div className="inconsIn">
                            <img src={delete_icon} alt="" />
                          </div>
                        </Link>
                      </OverlayTrigger>
                    </td>
                  </tr>
                  <tr className="table-row-custom">
                    <td>2</td>
                    <td>Accountants</td>
                    <td>Inactive</td>
                    <td className="d-flex">
                      <OverlayTrigger
                        placement="left"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipForEdit}
                      >
                        <Link to="#" onClick={handleShowEdit}>
                          <div className="inconsIn">
                            <img src={edit_icon} alt="" />
                          </div>
                        </Link>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipForDelete}
                      >
                        <Link to="#" onClick={handleShow}>
                          <div className="inconsIn">
                            <img src={delete_icon} alt="" />
                          </div>
                        </Link>
                      </OverlayTrigger>
                    </td>
                  </tr>
                  <tr className="table-row-custom">
                    <td>3</td>
                    <td>Fleet Manaager</td>
                    <td>Inactive</td>
                    <td className="d-flex">
                      <OverlayTrigger
                        placement="left"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipForEdit}
                      >
                        <Link to="#" onClick={handleShowEdit}>
                          <div className="inconsIn">
                            <img src={edit_icon} alt="" />
                          </div>
                        </Link>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipForDelete}
                      >
                        <Link to="#" onClick={handleShow}>
                          <div className="inconsIn">
                            <img src={delete_icon} alt="" />
                          </div>
                        </Link>
                      </OverlayTrigger>
                    </td>
                  </tr>
                  <tr className="table-row-custom">
                    <td>4</td>
                    <td>Transport Manager</td>
                    <td>Inactive</td>
                    <td className="d-flex">
                      <OverlayTrigger
                        placement="left"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipForEdit}
                      >
                        <Link to="#" onClick={handleShowEdit}>
                          <div className="inconsIn">
                            <img src={edit_icon} alt="" />
                          </div>
                        </Link>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipForDelete}
                      >
                        <Link to="#" onClick={handleShow}>
                          <div className="inconsIn">
                            <img src={delete_icon} alt="" />
                          </div>
                        </Link>
                      </OverlayTrigger>
                    </td>
                  </tr>
                  <tr className="table-row-custom">
                    <td>5</td>
                    <td>Driver</td>
                    <td>Inactive</td>
                    <td className="d-flex">
                      <OverlayTrigger
                        placement="left"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipForEdit}
                      >
                        <Link to="#" onClick={handleShowEdit}>
                          <div className="inconsIn">
                            <img src={edit_icon} alt="" />
                          </div>
                        </Link>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipForDelete}
                      >
                        <Link to="#" onClick={handleShow}>
                          <div className="inconsIn">
                            <img src={delete_icon} alt="" />
                          </div>
                        </Link>
                      </OverlayTrigger>
                    </td>
                  </tr>
                  <tr className="table-row-custom">
                    <td>6</td>
                    <td>Assistant</td>
                    <td>Inactive</td>
                    <td className="d-flex">
                      <OverlayTrigger
                        placement="left"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipForEdit}
                      >
                        <Link to="#" onClick={handleShowEdit}>
                          <div className="inconsIn">
                            <img src={edit_icon} alt="" />
                          </div>
                        </Link>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipForDelete}
                      >
                        <Link to="#" onClick={handleShow}>
                          <div className="inconsIn">
                            <img src={delete_icon} alt="" />
                          </div>
                        </Link>
                      </OverlayTrigger>
                    </td>
                  </tr>
                  <tr className="table-row-custom">
                    <td>7</td>
                    <td>Dashboard</td>
                    <td>Inactive</td>
                    <td className="d-flex">
                      <OverlayTrigger
                        placement="left"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipForEdit}
                      >
                        <Link to="#" onClick={handleShowEdit}>
                          <div className="inconsIn">
                            <img src={edit_icon} alt="" />
                          </div>
                        </Link>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipForDelete}
                      >
                        <Link to="#" onClick={handleShow}>
                          <div className="inconsIn">
                            <img src={delete_icon} alt="" />
                          </div>
                        </Link>
                      </OverlayTrigger>
                    </td>
                  </tr>
                  <tr className="table-row-custom">
                    <td>8</td>
                    <td>Vehicle Assistant</td>
                    <td>Inactive</td>
                    <td className="d-flex">
                      <OverlayTrigger
                        placement="left"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipForEdit}
                      >
                        <Link to="#" onClick={handleShowEdit}>
                          <div className="inconsIn">
                            <img src={edit_icon} alt="" />
                          </div>
                        </Link>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipForDelete}
                      >
                        <Link to="#" onClick={handleShow}>
                          <div className="inconsIn">
                            <img src={delete_icon} alt="" />
                          </div>
                        </Link>
                      </OverlayTrigger>
                    </td>
                  </tr>
                  <tr className="table-row-custom">
                    <td>9</td>
                    <td>Trip Manager</td>
                    <td>Inactive</td>
                    <td className="d-flex">
                      <OverlayTrigger
                        placement="left"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipForEdit}
                      >
                        <Link to="#" onClick={handleShowEdit}>
                          <div className="inconsIn">
                            <img src={edit_icon} alt="" />
                          </div>
                        </Link>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipForDelete}
                      >
                        <Link to="#" onClick={handleShow}>
                          <div className="inconsIn">
                            <img src={delete_icon} alt="" />
                          </div>
                        </Link>
                      </OverlayTrigger>
                    </td>
                  </tr>
                  <tr className="table-row-custom">
                    <td>10</td>
                    <td>Trip Manager</td>
                    <td>Inactive</td>
                    <td className="d-flex">
                      <OverlayTrigger
                        placement="left"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipForEdit}
                      >
                        <Link to="#" onClick={handleShowEdit}>
                          <div className="inconsIn">
                            <img src={edit_icon} alt="" />
                          </div>
                        </Link>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipForDelete}
                      >
                        <Link to="#" onClick={handleShow}>
                          <div className="inconsIn">
                            <img src={delete_icon} alt="" />
                          </div>
                        </Link>
                      </OverlayTrigger>
                    </td>
                  </tr>
                  <tr className="table-row-custom">
                    <td>11</td>
                    <td>Trip Manager</td>
                    <td>Inactive</td>
                    <td className="d-flex">
                      <OverlayTrigger
                        placement="left"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipForEdit}
                      >
                        <Link to="#" onClick={handleShowEdit}>
                          <div className="inconsIn">
                            <img src={edit_icon} alt="" />
                          </div>
                        </Link>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipForDelete}
                      >
                        <Link to="#" onClick={handleShow}>
                          <div className="inconsIn">
                            <img src={delete_icon} alt="" />
                          </div>
                        </Link>
                      </OverlayTrigger>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <Pagenation />
          </div>
          <Modal
            show={show}
            onHide={handleClose}
            centered
            className="common-model"
          >
            <Modal.Header closeButton>
              <Modal.Title>{t("Delete User Role")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{t("Are you sure you want to delete this Role")} ?</Modal.Body>
            <Modal.Footer className="pop-up-modal-footer">
              <button className="cx-btn-1" onClick={handleClose}>
                {t("Close")}
              </button>
              <button className="cx-btn-2" onClick={handleClose}>
                {t("Yes")}
              </button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={edit}
            onHide={handleCloseEdit}
            centered
            className="common-model"
          >
            <Modal.Header closeButton>
              <Modal.Title>{t("Edit Role")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Label className="common-labels">
                {t("Role")} <span className="red-star">*</span>
              </Form.Label>
              <ReactSelect />
            </Modal.Body>
            <Modal.Footer className="pop-up-modal-footer">
              <button className="cx-btn-1" onClick={handleCloseEdit}>
                {t("Close")}
              </button>
              <button className="cx-btn-2" onClick={handleCloseEdit}>
                {t("Yes")}
              </button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={create}
            onHide={handleCloseCreate}
            centered
            className="common-model"
          >
            <Modal.Header closeButton>
              <Modal.Title>{t("Create Role")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Label className="common-labels">
                {t("Role")} <span className="red-star">*</span>
              </Form.Label>
              <Form.Select
                required
                as="select"
                type="select"
                name="Speed_limit"
              >
                <option value="50">Enter Role...</option>
                <option value="100">CEO</option>
                <option value="150">Performance Manager</option>
              </Form.Select>
            </Modal.Body>
            <Modal.Footer className="pop-up-modal-footer">
              <button className="cx-btn-1" onClick={handleCloseCreate}>
                {t("Close")}
              </button>
              <button className="cx-btn-2" onClick={handleCloseCreate}>
                {t("Create")}
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      </motion.div>
    </>
  );
};

export default UserRole;
