import React, { useState, useContext } from "react";

import GoogleMapReact from "google-map-react";

import {
  MapContainer,
  TileLayer,
  FeatureGroup,
  Marker,
  useMap,
  Polygon,
  Popup,
} from "react-leaflet";

import { EditControl } from "react-leaflet-draw";
import { Icon } from "leaflet";
import { FullscreenControl } from "react-leaflet-fullscreen";
import "react-leaflet-fullscreen/dist/styles.css";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import { AppContext } from "../context/AppContext";
import L from "leaflet";

import "leaflet/dist/leaflet.css";

import { createControlComponent } from '@react-leaflet/core';

import 'leaflet.fullscreen';

const FullscreenControlComp = createControlComponent(props => L.control.fullscreen(props))

// import LeafletgeoSearch from "./LeafletgeoSearch";

const MapComponent = ({ComponentId}) => {
  const [mapLayer, setMapLayer] = useState([]);

  const [circle, setCircle] = useState();

  const [radius, setRadius] = useState();

  const { googleMap, setGoogleMap } = useContext(AppContext);

  const [selected, setSelected] = useState({});

  const onSelect = (item) => {
    setSelected(item);
  };

  const defaultProps = {
    center: {
      lat: 19.143683,

      lng: 77.314136,
    },

    zoom: 11,
  };

  const AnyReactComponent = ({ text }) => <div>{text}</div>;

  //functinality

  const _onCreate = (e) => {
    setRadius();

    setCircle();

    setMapLayer([]);

    const { layerType, layer } = e;

    if (layerType === "polygon") {
      const { _leaflet_id } = layer;

      setMapLayer((layers) => layer.getLatLngs()[0]);
    }
  };

  const _onEdited = (e) => {
    const { layers: _layer } = e;

    Object.values(_layer).map(({ _leaflet_id, editing }) => {
      setMapLayer((layers) =>
        layers.map((l) =>
          l.id === _leaflet_id
            ? { ...l, latlngs: { ...editing.latlngs[0] } }
            : l
        )
      );
    });
  };

  const _onDeleted = (e) => {
    const {
      layers: { _layers },
    } = e;

    let testmap = [...mapLayer];

    Object.values(_layers).map(({ _leaflet_id }) => {
      testmap.filter((l) => l.id !== _leaflet_id); //setMapLayer((layers) => layers.filter((l) => l.id !== _leaflet_id));
    });

    setMapLayer(testmap);
  };

  return (
    <div className="map-wrapper">
      {googleMap ? (
        <div className="google-map">
          <div className="map-main">
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyARN4ZLpzuzwGo2M6PKr2M--juR5zJyrew",
              }}
              defaultCenter={defaultProps.center}
              defaultZoom={defaultProps.zoom}
            >
              <AnyReactComponent
                lat={19.887288}
                lng={75.368412}
                text="My Marker"
              />
            </GoogleMapReact>
          </div>
        </div>
      ) : (
        <div className="leaflet-map">
          <div className="map-main">
            <MapContainer
              center={[45.4, -75.7]}
              zoom={12}
              scrollWheelZoom={false}
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              />
              
                <FullscreenControlComp position="bottomright"/>
              {
                ComponentId !== "dashboard" &&  (<FeatureGroup>
                  <EditControl  position="topright"
                    onCreated={_onCreate}
                    // onEdited={_onEdited}
                    // onDeleted={_onDeleted}
                    draw={{
                      rectangle: true,
  
                      polyline: false,
  
                      polygon: true,
  
                      circle: true,
  
                      circlemarker: false,
  
                      markers: false,
  
                      marker: false,
                    }}
                  />
                </FeatureGroup>)
              }
              
              {/*               <LeafletgeoSearch/> */}
              <Marker position={[51.505, -0.09]}>
                <Popup>
                 A pretty CSS3 popup. <br /> Easily
                  customizable.
                </Popup>
              </Marker>
            </MapContainer>
          </div>
        </div>
      )}
    </div>
  );
};

export default MapComponent;
