import { AppContext } from "./AppContext";
import React, { useState } from "react";
const AppState = (props) => {
  const [sidebar, setSidebar] = useState(false);
  const [googleMap, setGoogleMap] = useState(false);
  const [infoOpen, setInfoOpen] = useState(false);
  const [ChangeLang, SetChangeLang] = useState("");
  const [slider, setSlider] = useState("");
  return (
    <div>
      <AppContext.Provider
        value={{
          sidebar,
          setSidebar,
          googleMap,
          setGoogleMap,
          infoOpen,
          setInfoOpen,
          ChangeLang,
          SetChangeLang,
          slider,
          setSlider,
        }}
      >
        {props.children}
      </AppContext.Provider>
    </div>
  );
};

export default AppState;
