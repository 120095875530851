import React from 'react'
import { PDFExport } from '@progress/kendo-react-pdf';
// import { Image } from '@progress/kendo-react-common';
import profile from "../../assets/images/InspetionPDF/FrontPageProfile.svg"
import HeaderLeftLogo from "../../assets/images/InspetionPDF/HeaderLeftLogo.svg"
import CarView from "../../assets/images/InspetionPDF/CarView.svg"
import ProfilePhoto from "../../assets/images/InspetionPDF/ProfilePhoto.svg"
import Persone from "../../assets/images/InspetionPDF/Persone.svg"
import Drive from "../../assets/images/InspetionPDF/Drive.svg"
import Star from "../../assets/images/InspetionPDF/Star.svg"
import red from "../../assets/images/Red_Tri.svg"
import yellow from "../../assets/images/Yellow_Tri.svg"
import green from "../../assets/images/Green-check.svg"
import Logo from "../../assets/images/Web-Application-Logo.svg"

const InspectionPDF = () => {
    const pdfExportComponent = React.useRef(null);

    const exportPDF = () => {
        if (pdfExportComponent.current) {
            pdfExportComponent.current.save();
        }
    };

    return (
        <div>
            <button onClick={exportPDF}>Export PDF</button>
            <PDFExport
                ref={pdfExportComponent} paperSize="A4"
                fileName="Inpection"
                forcePageBreak=".page-break"
            >
                <div className='FrontPage'>
                    <div className="TopSection">
                        <img src={profile} alt="" />
                    </div>
                    <div className="bodyHeading">
                        <div className="logo">
                            <img src={Logo} alt="" />
                        </div>
                    </div>
                    <div className="lastSection">
                        <p>VEHICLE <br /> INSPECTION <br /> REPORT</p>
                        <p className="bottomP">www.vehicletrackingsystem.com</p>
                    </div>
                </div>
                <div className='page-break SecondPage'>
                    <div className="header">
                        <img src={Logo} alt="" />
                        <div className="Name">Vehicle Inspection Report</div>
                        <img src={HeaderLeftLogo} alt="" />
                    </div>
                    <div className="body">
                        <div className="Section1">
                            <img src={CarView} alt="" />
                        </div>
                        <div className="DetailsSection">
                            <div className="Heading">Vehicle Details</div>
                            <div className="row">
                                <div className="col-lg-6 mb-2">
                                    <div className="singleLine">
                                        <p className='MAinHeading'>Number</p>
                                        <p className='Deatils'>MH12GG2022</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 mb-2">
                                    <div className="singleLine">
                                        <p className='MAinHeading'>Registration No.</p>
                                        <p className='Deatils'>221100336654860</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 mb-2">
                                    <div className="singleLine">
                                        <p className='MAinHeading'>Type</p>
                                        <p className='Deatils'>Car</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 mb-2">
                                    <div className="singleLine">
                                        <p className='MAinHeading'>Registration Date</p>
                                        <p className='Deatils'>01-02-1997</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 mb-2">
                                    <div className="singleLine">
                                        <p className='MAinHeading'>Name</p>
                                        <p className='Deatils'>Honda</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 mb-2">
                                    <div className="singleLine">
                                        <p className='MAinHeading'>Insurance Validity</p>
                                        <p className='Deatils'>05-02-2023</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 mb-2">
                                    <div className="singleLine">
                                        <p className='MAinHeading'>Color</p>
                                        <p className='Deatils'>White</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 mb-2">
                                    <div className="singleLine">
                                        <p className='MAinHeading'>Engine No.</p>
                                        <p className='Deatils'>12345678910</p>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="singleLine">
                                        <p className='MAinHeading'>Driver Name</p>
                                        <p className='Deatils'>Driver Name</p>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="singleLine">
                                        <p className='MAinHeading'>Location</p>
                                        <p className='Deatils'>Vishtrantwadi, Kalas road, <br /> Pune, Maharashtara.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="DreiverDetails">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="CardDetails">
                                        <div className="Heading">Requested By</div>
                                        <div className="RequestedBy">
                                            <img src={ProfilePhoto} alt="" className='ProImg' />
                                            <div className="leftContain">
                                                <div className="singleRow">
                                                    <img src={Persone} alt="" />
                                                    <p>Rohit Sharma</p>
                                                </div>
                                                <div className="singleRow">
                                                    <img src={Drive} alt="" />
                                                    <p>Driver</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="CardDetails">
                                        <div className="Heading">Inspected By</div>
                                        <div className="RequestedBy">
                                            <img src={ProfilePhoto} alt="" className='ProImg' />
                                            <div className="leftContain">
                                                <div className="singleRow">
                                                    <img src={Persone} alt="" />
                                                    <p>Rohit Sharma</p>
                                                </div>
                                                <div className="singleRow">
                                                    <img src={Star} alt="" />
                                                    <p>+ 4yrs EXPERIENCE</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="DetailsSection">
                            <div className="Heading">Highlights</div>
                            <div className="row">
                                <div className="col-lg-6 mb-2">
                                    <div className="singleLine">
                                        <p className='MAinHeading'>Accident</p>
                                        <p className='Deatils'>YES</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 mb-2">
                                    <div className="singleLine">
                                        <p className='MAinHeading'>RC Copy</p>
                                        <p className='Deatils'>YES</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 mb-2">
                                    <div className="singleLine">
                                        <p className='MAinHeading'>Accessories Changed</p>
                                        <p className='Deatils'>YES</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 mb-2">
                                    <div className="singleLine">
                                        <p className='MAinHeading'>Insurance Copy</p>
                                        <p className='Deatils'>YES</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 mb-2">
                                    <div className="singleLine">
                                        <p className='MAinHeading'>Fine</p>
                                        <p className='Deatils'>YES,UNPAID</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 mb-2">
                                    <div className="singleLine">
                                        <p className='MAinHeading'>Pollution Certificate</p>
                                        <p className='Deatils'>YES</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 mb-2">
                                    <div className="singleLine">
                                        <p className='MAinHeading'>Maintenance</p>
                                        <p className='Deatils'>02-02-2023, 05:56PM</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 mb-2">
                                    <div className="singleLine">
                                        <p className='MAinHeading'>Odometer</p>
                                        <p className='Deatils'>76,000KMS</p>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="singleLine">
                                        <p className='MAinHeading'>Fuel Expense</p>
                                        <p className='Deatils'>$300/ MONTH</p>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="singleLine">
                                        <p className='MAinHeading'>Tyre Life</p>
                                        <p className='Deatils'>CHECKED AND OK</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="Footer">
                        <p className="webLink">www.vehicletrackingsystem.com</p>
                        <p className="PageIndicator">Page 01/13</p>
                    </div>
                </div>
                <div className='page-break ThridPage'>
                    <div className="header">
                        <img src={Logo} alt="" />
                        <div className="Name">Vehicle Inspection Report</div>
                        <img src={HeaderLeftLogo} alt="" />
                    </div>
                    <div className="body">
                        <div className="heading">Overview</div>
                        <div className="card_wrapper">
                            <div className="sigle_Card">
                                <div className="Overview_Card">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="progress_over">

                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="Overview_Contain">
                                                <div className="over_Heading">
                                                    Interiors
                                                </div>
                                                <div className="over_List">
                                                    <img src={red} alt="" />
                                                    10 Immediate Attention Required
                                                </div>
                                                <div className="over_List">
                                                    <img src={yellow} alt="" />
                                                    5 Attention Required
                                                </div>
                                                <div className="over_List">
                                                    <img src={green} alt="" />
                                                    60 Checked and OK
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="border_dash_Right"></div>
                            </div>
                            <div className="sigle_Card_Right">
                                <div className="Overview_Card">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="progress_over">

                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="Overview_Contain">
                                                <div className="over_Heading">
                                                    Exterior
                                                </div>
                                                <div className="over_List">
                                                    <img src={red} alt="" />
                                                    10 Immediate Attention Required
                                                </div>
                                                <div className="over_List">
                                                    <img src={yellow} alt="" />
                                                    5 Attention Required
                                                </div>
                                                <div className="over_List">
                                                    <img src={green} alt="" />
                                                    60 Checked and OK
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="border_dash_Left"></div>
                                </div>
                            </div>
                            <div className="sigle_Card">
                                <div className="Overview_Card">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="progress_over">

                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="Overview_Contain">
                                                <div className="over_Heading">
                                                    Other’s
                                                </div>
                                                <div className="over_List">
                                                    <img src={red} alt="" />
                                                    10 Immediate Attention Required
                                                </div>
                                                <div className="over_List">
                                                    <img src={yellow} alt="" />
                                                    5 Attention Required
                                                </div>
                                                <div className="over_List">
                                                    <img src={green} alt="" />
                                                    60 Checked and OK
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="border_dash_Right"></div>
                            </div>
                            <div className="sigle_Card_Right">
                                <div className="Overview_Card">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="progress_over">

                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="Overview_Contain">
                                                <div className="over_Heading">
                                                    Overall Performance
                                                </div>
                                                <div className="over_List">
                                                    <img src={red} alt="" />
                                                    10 Immediate Attention Required
                                                </div>
                                                <div className="over_List">
                                                    <img src={yellow} alt="" />
                                                    5 Attention Required
                                                </div>
                                                <div className="over_List">
                                                    <img src={green} alt="" />
                                                    60 Checked and OK
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="border_dash_Left"></div>
                                </div>
                            </div>
                            <div className="sigle_Card">
                                <div className="Overview_Card">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="Overview_Contain p-4">
                                                <div className="over_Heading">
                                                    Remark
                                                </div>
                                                <div className="over_List">
                                                    Vehicle is not advised to drive, Need immediate attention. Vehicle cannot be driven.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="Footer">
                        <p className="webLink">www.vehicletrackingsystem.com</p>
                        <p className="PageIndicator">Page 01/13</p>
                    </div>
                </div>
                <div className='page-break IntaropPDF'>
                    <div className="header">
                        <img src={Logo} alt="" />
                        <div className="Name">Vehicle Inspection Report</div>
                        <img src={HeaderLeftLogo} alt="" />
                    </div>
                    <div className="body">
                        <div className="headingMain">Interiors</div>
                        <div className="Interior">
                            <div className="heading">
                                <p>Brake System</p>
                                <div className="alert_tab">
                                    <div className="single_alert">
                                        <img src={yellow} alt="" />
                                        <label htmlFor="">1 Attention Required</label>
                                    </div>
                                    <div className="single_alert">
                                        <img src={red} alt="" />
                                        <label htmlFor="">1 Immediate Attention Required</label>
                                    </div>
                                </div>
                            </div>
                            <div className="Brake_Contain">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Name of Part / Particular</th>
                                            <th>Checked and OK</th>
                                            <th>May Require Attention</th>
                                            <th>Requires Immediate Attention</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Service Brakes</td>
                                            <td className='GreenOk'><input type="radio"  defaultChecked  value="" /></td>
                                            <td className='Yellow'><input type="radio" disabled  value="" /></td>
                                            <td className='RedNo'><input type="radio" disabled  value="" /></td>
                                        </tr>
                                        <tr>
                                            <td>Parking Brakes</td>
                                            <td className='GreenOk'><input type="radio" disabled  value="" /></td>
                                            <td className='Yellow'><input type="radio" defaultChecked  value="" /></td>
                                            <td className='RedNo'><input type="radio" disabled  value="" /></td>
                                        </tr>
                                        <tr>
                                            <td>Brake drums/ rotors</td>
                                            <td className='GreenOk'><input type="radio" disabled  value="" /></td>
                                            <td className='Yellow'><input type="radio" disabled  value="" /></td>
                                            <td className='RedNo'><input type="radio" defaultChecked  value="" /></td>
                                        </tr>
                                        <tr>
                                            <td>Brake hose</td>
                                            <td className='GreenOk'><input type="radio" disabled value="" /></td>
                                            <td className='Yellow'><input type="radio" defaultChecked value="" /></td>
                                            <td className='RedNo'><input type="radio" disabled value="" /></td>
                                        </tr>
                                        <tr>
                                            <td>Low pressure warning device</td>
                                            <td className='GreenOk'><input type="radio" defaultChecked  value="" /></td>
                                            <td className='Yellow'><input type="radio" disabled  value="" /></td>
                                            <td className='RedNo'><input type="radio" disabled  value="" /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="Interior">
                            <div className="heading">
                                <p>Brake System</p>
                                <div className="alert_tab">
                                    <div className="single_alert">
                                        <img src={yellow} alt="" />
                                        <label htmlFor="">1 Attention Required</label>
                                    </div>
                                    <div className="single_alert">
                                        <img src={red} alt="" />
                                        <label htmlFor="">1 Immediate Attention Required</label>
                                    </div>
                                </div>
                            </div>
                            <div className="Brake_Contain">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Name of Part / Particular</th>
                                            <th>Checked and OK</th>
                                            <th>May Require Attention</th>
                                            <th>Requires Immediate Attention</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Service Brakes</td>
                                            <td className='GreenOk'><input type="radio"  defaultChecked  value="" /></td>
                                            <td className='Yellow'><input type="radio" disabled  value="" /></td>
                                            <td className='RedNo'><input type="radio" disabled  value="" /></td>
                                        </tr>
                                        <tr>
                                            <td>Parking Brakes</td>
                                            <td className='GreenOk'><input type="radio" disabled  value="" /></td>
                                            <td className='Yellow'><input type="radio" defaultChecked  value="" /></td>
                                            <td className='RedNo'><input type="radio" disabled  value="" /></td>
                                        </tr>
                                        <tr>
                                            <td>Brake drums/ rotors</td>
                                            <td className='GreenOk'><input type="radio" disabled  value="" /></td>
                                            <td className='Yellow'><input type="radio" disabled  value="" /></td>
                                            <td className='RedNo'><input type="radio" defaultChecked  value="" /></td>
                                        </tr>
                                        <tr>
                                            <td>Brake hose</td>
                                            <td className='GreenOk'><input type="radio" disabled value="" /></td>
                                            <td className='Yellow'><input type="radio" defaultChecked value="" /></td>
                                            <td className='RedNo'><input type="radio" disabled value="" /></td>
                                        </tr>
                                        <tr>
                                            <td>Low pressure warning device</td>
                                            <td className='GreenOk'><input type="radio" defaultChecked  value="" /></td>
                                            <td className='Yellow'><input type="radio" disabled  value="" /></td>
                                            <td className='RedNo'><input type="radio" disabled  value="" /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="Interior">
                            <div className="heading">
                                <p>Brake System</p>
                                <div className="alert_tab">
                                    <div className="single_alert">
                                        <img src={yellow} alt="" />
                                        <label htmlFor="">1 Attention Required</label>
                                    </div>
                                    <div className="single_alert">
                                        <img src={red} alt="" />
                                        <label htmlFor="">1 Immediate Attention Required</label>
                                    </div>
                                </div>
                            </div>
                            <div className="Brake_Contain">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Name of Part / Particular</th>
                                            <th>Checked and OK</th>
                                            <th>May Require Attention</th>
                                            <th>Requires Immediate Attention</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Service Brakes</td>
                                            <td className='GreenOk'><input type="radio"  defaultChecked  value="" /></td>
                                            <td className='Yellow'><input type="radio" disabled  value="" /></td>
                                            <td className='RedNo'><input type="radio" disabled  value="" /></td>
                                        </tr>
                                        <tr>
                                            <td>Parking Brakes</td>
                                            <td className='GreenOk'><input type="radio" disabled  value="" /></td>
                                            <td className='Yellow'><input type="radio" defaultChecked  value="" /></td>
                                            <td className='RedNo'><input type="radio" disabled  value="" /></td>
                                        </tr>
                                        <tr>
                                            <td>Brake drums/ rotors</td>
                                            <td className='GreenOk'><input type="radio" disabled  value="" /></td>
                                            <td className='Yellow'><input type="radio" disabled  value="" /></td>
                                            <td className='RedNo'><input type="radio" defaultChecked  value="" /></td>
                                        </tr>
                                        <tr>
                                            <td>Brake hose</td>
                                            <td className='GreenOk'><input type="radio" disabled value="" /></td>
                                            <td className='Yellow'><input type="radio" defaultChecked value="" /></td>
                                            <td className='RedNo'><input type="radio" disabled value="" /></td>
                                        </tr>
                                        <tr>
                                            <td>Low pressure warning device</td>
                                            <td className='GreenOk'><input type="radio" defaultChecked  value="" /></td>
                                            <td className='Yellow'><input type="radio" disabled  value="" /></td>
                                            <td className='RedNo'><input type="radio" disabled  value="" /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="Footer">
                        <p className="webLink">www.vehicletrackingsystem.com</p>
                        <p className="PageIndicator">Page 01/13</p>
                    </div>
                </div>
            </PDFExport >
        </div >
    );
};


export default InspectionPDF