import { React, useContext, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import { Col, Modal, Nav } from "react-bootstrap";
import ReportCardContain from "../../../assets/images/ReportCardContain.svg";
import RunningReport from "../../../assets/images/Report/RunningReport.svg";
import ReportPluse from "../../../assets/images/Report/ReportPluse.svg";
import ReportClose from "../../../assets/images/Report/ReportClose.svg";
import RpoetSubCat from "../../../assets/images/Report/RpoetSubCat.svg";
import ReportEye from "../../../assets/images/Report/ReportEye.svg";
import VehReport from "../../../assets/images/Report/VehReport.svg";
import TripReport from "../../../assets/images/Report/TripReport.svg";
import TrickingReport from "../../../assets/images/Report/TrickingReport.svg";
import DriverReport from "../../../assets/images/Report/DriverReport.svg";
import MaintenceReport from "../../../assets/images/Report/MaintenceReport.svg";
import AdminReport from "../../../assets/images/Report/AdminReport.svg";
import { Dropdown, Tab, Tabs } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import Import from "../../../assets/images/ic-Import.svg";
import Pagenation from "../../../sharedComponent/Pagenation";
import { useTranslation } from "react-i18next";
import PreviousButton from "../../../sharedComponent/PreviousButton";
import NextButton from "../../../sharedComponent/Nextbutton";
import ExistButton from "../../../sharedComponent/ExistButton";
import InfoComponent from "../../../sharedComponent/SupportHelp/InfoComponent";

const Reports = ({ onClick }) => {
  const [running, setRunning] = useState(false);
  const [shudreport, setShudreport] = useState(false);
  const [customreport, setCustomreport] = useState(false);

  const { sidebar, setSidebar, Dark, setDark, slider, setSlider } =
    useContext(AppContext);
  const [startDate, setStartDate] = useState(new Date());
  const [deleteModal, setDeleteModal] = useState(false);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const MainRunnig = () => {
    setRunning(false);
    setCustomreport(false);
    setShudreport(false);
  };
  const RunningReportFun = () => {
    setRunning(true);
  };
  const shduleReport = () => {
    setShudreport(true);
  };
  const CutoRepoprt = () => {
    setCustomreport(true);
  };
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    navigate("/ScheduleReport");
  };
  const handleClickPrevious = () => {
    navigate("/Merchant");
  };
  return (
    <>
      <motion.div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }}
      >
        <div id="cx-wrapper">
          {/* Top inputs for instatnt search */}
          <div className="mainVehAccident" id="Report_head_reponsive">
            <div className="row d-flex justify-content-end">
              <div className="col-lg-4 d-flex justify-content-end mb-3">
                <Link to="/ScheduleReport">
                  <button className="cx-btn-3">+ Schedule Report</button>
                </Link>
                <Link to="/GenerateCustomReport">
                  <button className="cx-btn-3">+ Custom Report</button>
                </Link>
                <div style={{ marginRight: "10px" }} />
                <div className="right-export-btn-section-wrapper">
                  <div className="c-pointer me-2"></div>

                  <div className="md_dropdown">
                    <Dropdown>
                      <Dropdown.Toggle
                        id="dropdown-basic"
                        className="export-toggle"
                      >
                        <p className="Export_Text">{t("Export")}</p>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item>
                          <Link
                            // onClick={() => getExportChat()}
                            className="d-block"
                          >
                            {t("PDF")}
                          </Link>
                        </Dropdown.Item>

                        <Dropdown.Item>
                          <Link
                            // onClick={() => downLoadExcelSheet()}
                            className="d-block"
                          >
                            {t("Excel")}
                          </Link>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Vehicle table */}
          <div className="Vehcle-main-tabs" id="Report_responsive">
            <div className="main-master-wrapper mb-0 inner-tabs-section">
              <div id="scroll_insideThe_Padding83">
                <Tab.Container
                  id="left-tabs-example"
                  className="td-tab-wrapper"
                  defaultActiveKey="1"
                >
                  <Nav
                    variant="pills"
                    className="td-nav"
                    id="InnerTabNew_Three"
                  >
                    <Nav.Item className="td-tab">
                      <Nav.Link className="td-link" eventKey="1">
                        Default Reports
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="td-tab">
                      <Nav.Link className="td-link" eventKey="2">
                        Scheduled Reports
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="td-tab">
                      <Nav.Link className="td-link" eventKey="3">
                        Customized Reports
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>

                  <Col sm={12} className="">
                    <Tab.Content>
                      <Tab.Pane eventKey="1">
                        <div className="yauto">
                          <div className="all-vehical-head row vehicle-top-inputs">
                            <div className="input-section-wrapper">
                              <div className="row">
                                <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Report Name"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="right-export-btn-section-wrapper">
                              <div className="c-pointer"></div>
                              <div className="c-pointer">
                                {/* <img src={Import} alt="" /> */}
                              </div>
                            </div>
                          </div>
                          {running === false ? (
                            <>
                              <div
                                className="row main-cards-wrapper gx-3"
                                data-aos="zoom-in"
                                data-aos-duration="500"
                              >
                                <div
                                  className={
                                    sidebar
                                      ? "col-lg-4 col-md-6"
                                      : "col-lg-3 col-md-6"
                                  }
                                >
                                  <div className="common-vehical-card-inner">
                                    <div
                                      className="vehical-card-head"
                                      id="report-main"
                                    >
                                      <div className="heading">
                                        <div className="d-flex">
                                          <img src={RunningReport} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              Report Name
                                            </p>
                                            <p className="title">
                                              Vehicle Running Report
                                            </p>
                                          </div>
                                        </div>
                                        <img
                                          src={ReportPluse}
                                          alt=""
                                          className="add-icon"
                                          onClick={() => {
                                            RunningReportFun();
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="d-flex vehical-card-body customise_Report_Responsive justify-content-between">
                                      <div className="">
                                        <div className="card-contain ">
                                          <p className="sub-heading">
                                            No. of Reports
                                          </p>
                                          <p className="title">20</p>
                                        </div>
                                        <div className="card-contain">
                                          <p className="sub-heading">
                                            Last Updated On
                                          </p>
                                          <p className="title">20-01-2023</p>
                                        </div>
                                      </div>
                                      <div className="">
                                        <img src={ReportCardContain} alt="" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={
                                    sidebar
                                      ? "col-lg-4 col-md-6"
                                      : "col-lg-3 col-md-6"
                                  }
                                >
                                  <div className="common-vehical-card-inner">
                                    <div
                                      className="vehical-card-head"
                                      id="report-main"
                                    >
                                      <div className="heading">
                                        <div className="d-flex">
                                          <img src={RunningReport} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              Report Name
                                            </p>
                                            <p className="title">
                                              Vehicle Running Report
                                            </p>
                                          </div>
                                        </div>
                                        <img
                                          src={ReportPluse}
                                          alt=""
                                          className="add-icon"
                                          onClick={() => {
                                            RunningReportFun();
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="d-flex vehical-card-body customise_Report_Responsive justify-content-between">
                                      <div className="">
                                        <div className="card-contain ">
                                          <p className="sub-heading">
                                            No. of Reports
                                          </p>
                                          <p className="title">20</p>
                                        </div>
                                        <div className="card-contain">
                                          <p className="sub-heading">
                                            Last Updated On
                                          </p>
                                          <p className="title">20-01-2023</p>
                                        </div>
                                      </div>
                                      <div className="">
                                        <img src={ReportCardContain} alt="" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={
                                    sidebar
                                      ? "col-lg-4 col-md-6"
                                      : "col-lg-3 col-md-6"
                                  }
                                >
                                  <div className="common-vehical-card-inner">
                                    <div
                                      className="vehical-card-head"
                                      id="report-main"
                                    >
                                      <div className="heading">
                                        <div className="d-flex">
                                          <img src={RunningReport} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              Report Name
                                            </p>
                                            <p className="title">
                                              Vehicle Running Report
                                            </p>
                                          </div>
                                        </div>
                                        <img
                                          src={ReportPluse}
                                          alt=""
                                          className="add-icon"
                                          onClick={() => {
                                            RunningReportFun();
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="d-flex vehical-card-body customise_Report_Responsive justify-content-between">
                                      <div className="">
                                        <div className="card-contain ">
                                          <p className="sub-heading">
                                            No. of Reports
                                          </p>
                                          <p className="title">20</p>
                                        </div>
                                        <div className="card-contain">
                                          <p className="sub-heading">
                                            Last Updated On
                                          </p>
                                          <p className="title">20-01-2023</p>
                                        </div>
                                      </div>
                                      <div className="">
                                        <img src={ReportCardContain} alt="" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={
                                    sidebar
                                      ? "col-lg-4 col-md-6"
                                      : "col-lg-3 col-md-6"
                                  }
                                >
                                  <div className="common-vehical-card-inner">
                                    <div
                                      className="vehical-card-head"
                                      id="report-main"
                                    >
                                      <div className="heading">
                                        <div className="d-flex">
                                          <img src={RunningReport} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              Report Name
                                            </p>
                                            <p className="title">
                                              Vehicle Running Report
                                            </p>
                                          </div>
                                        </div>
                                        <img
                                          src={ReportPluse}
                                          alt=""
                                          className="add-icon"
                                          onClick={() => {
                                            RunningReportFun();
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="d-flex vehical-card-body customise_Report_Responsive justify-content-between">
                                      <div className="">
                                        <div className="card-contain ">
                                          <p className="sub-heading">
                                            No. of Reports
                                          </p>
                                          <p className="title">20</p>
                                        </div>
                                        <div className="card-contain">
                                          <p className="sub-heading">
                                            Last Updated On
                                          </p>
                                          <p className="title">20-01-2023</p>
                                        </div>
                                      </div>
                                      <div className="">
                                        <img src={ReportCardContain} alt="" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={
                                    sidebar
                                      ? "col-lg-4 col-md-6"
                                      : "col-lg-3 col-md-6"
                                  }
                                >
                                  <div className="common-vehical-card-inner">
                                    <div
                                      className="vehical-card-head"
                                      id="report-main"
                                    >
                                      <div className="heading">
                                        <div className="d-flex">
                                          <img src={RunningReport} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              Report Name
                                            </p>
                                            <p className="title">
                                              Vehicle Running Report
                                            </p>
                                          </div>
                                        </div>
                                        <img
                                          src={ReportPluse}
                                          alt=""
                                          className="add-icon"
                                          onClick={() => {
                                            RunningReportFun();
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="d-flex vehical-card-body customise_Report_Responsive justify-content-between">
                                      <div className="">
                                        <div className="card-contain ">
                                          <p className="sub-heading">
                                            No. of Reports
                                          </p>
                                          <p className="title">20</p>
                                        </div>
                                        <div className="card-contain">
                                          <p className="sub-heading">
                                            Last Updated On
                                          </p>
                                          <p className="title">20-01-2023</p>
                                        </div>
                                      </div>
                                      <div className="">
                                        <img src={ReportCardContain} alt="" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={
                                    sidebar
                                      ? "col-lg-4 col-md-6"
                                      : "col-lg-3 col-md-6"
                                  }
                                >
                                  <div className="common-vehical-card-inner">
                                    <div
                                      className="vehical-card-head"
                                      id="report-main"
                                    >
                                      <div className="heading">
                                        <div className="d-flex">
                                          <img src={RunningReport} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              Report Name
                                            </p>
                                            <p className="title">
                                              Vehicle Running Report
                                            </p>
                                          </div>
                                        </div>
                                        <img
                                          src={ReportPluse}
                                          alt=""
                                          className="add-icon"
                                          onClick={() => {
                                            RunningReportFun();
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="d-flex vehical-card-body customise_Report_Responsive justify-content-between">
                                      <div className="">
                                        <div className="card-contain ">
                                          <p className="sub-heading">
                                            No. of Reports
                                          </p>
                                          <p className="title">20</p>
                                        </div>
                                        <div className="card-contain">
                                          <p className="sub-heading">
                                            Last Updated On
                                          </p>
                                          <p className="title">20-01-2023</p>
                                        </div>
                                      </div>
                                      <div className="">
                                        <img src={ReportCardContain} alt="" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={
                                    sidebar
                                      ? "col-lg-4 col-md-6"
                                      : "col-lg-3 col-md-6"
                                  }
                                >
                                  <div className="common-vehical-card-inner">
                                    <div
                                      className="vehical-card-head"
                                      id="report-main"
                                    >
                                      <div className="heading">
                                        <div className="d-flex">
                                          <img src={RunningReport} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              Report Name
                                            </p>
                                            <p className="title">
                                              Vehicle Running Report
                                            </p>
                                          </div>
                                        </div>
                                        <img
                                          src={ReportPluse}
                                          alt=""
                                          className="add-icon"
                                          onClick={() => {
                                            RunningReportFun();
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="d-flex vehical-card-body customise_Report_Responsive justify-content-between">
                                      <div className="">
                                        <div className="card-contain ">
                                          <p className="sub-heading">
                                            No. of Reports
                                          </p>
                                          <p className="title">20</p>
                                        </div>
                                        <div className="card-contain">
                                          <p className="sub-heading">
                                            Last Updated On
                                          </p>
                                          <p className="title">20-01-2023</p>
                                        </div>
                                      </div>
                                      <div className="">
                                        <img src={ReportCardContain} alt="" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={
                                    sidebar
                                      ? "col-lg-4 col-md-6"
                                      : "col-lg-3 col-md-6"
                                  }
                                >
                                  <div className="common-vehical-card-inner">
                                    <div
                                      className="vehical-card-head"
                                      id="report-main"
                                    >
                                      <div className="heading">
                                        <div className="d-flex">
                                          <img src={RunningReport} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              Report Name
                                            </p>
                                            <p className="title">
                                              Vehicle Running Report
                                            </p>
                                          </div>
                                        </div>
                                        <img
                                          src={ReportPluse}
                                          alt=""
                                          className="add-icon"
                                          onClick={() => {
                                            RunningReportFun();
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="d-flex vehical-card-body customise_Report_Responsive justify-content-between">
                                      <div className="">
                                        <div className="card-contain ">
                                          <p className="sub-heading">
                                            No. of Reports
                                          </p>
                                          <p className="title">20</p>
                                        </div>
                                        <div className="card-contain">
                                          <p className="sub-heading">
                                            Last Updated On
                                          </p>
                                          <p className="title">20-01-2023</p>
                                        </div>
                                      </div>
                                      <div className="">
                                        <img src={ReportCardContain} alt="" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <div
                              variants={aninations}
                              initial="initial"
                              animate="animate"
                              exit="exit"
                              transition={{
                                duration: 0.3,
                              }}
                            >
                              <div
                                className=" row gx-3 main-cards-wrapper"
                                id="Report_scrol"
                                data-aos="zoom-in"
                                data-aos-duration="500"
                              >
                                <div
                                  className={
                                    sidebar
                                      ? "col-lg-4 col-md-6"
                                      : "col-lg-3 col-md-6"
                                  }
                                >
                                  <div className={"common-vehical-card-inner"}>
                                    <div
                                      className="vehical-card-head"
                                      id="report-main"
                                    >
                                      <div className="heading">
                                        <div className="d-flex">
                                          <img src={RunningReport} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              Report Name
                                            </p>
                                            <p className="title">
                                              Vehicle Running Report
                                            </p>
                                          </div>
                                        </div>
                                        <img
                                          src={ReportClose}
                                          alt=""
                                          className="add-icon"
                                          onClick={() => {
                                            MainRunnig();
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="d-flex vehical-card-body customise_Report_Responsive justify-content-between">
                                      <div className="">
                                        <div className="card-contain ">
                                          <p className="sub-heading">
                                            No. of Reports
                                          </p>
                                          <p className="title">20</p>
                                        </div>
                                        <div className="card-contain">
                                          <p className="sub-heading">
                                            Last Updated On
                                          </p>
                                          <p className="title">20-01-2023</p>
                                        </div>
                                      </div>
                                      <div className="">
                                        <img src={ReportCardContain} alt="" />
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div
                                  className={
                                    sidebar
                                      ? "col-lg-4 col-md-6"
                                      : "col-lg-3 col-md-6"
                                  }
                                >
                                  <div
                                    className={"common-vehical-card-inner "}
                                    id="inner-report-card"
                                  >
                                    <div
                                      className="vehical-card-head"
                                      id="report-main"
                                    >
                                      <div className="heading">
                                        <div className="d-flex">
                                          <img src={RpoetSubCat} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              Report Name
                                            </p>
                                            <p className="title">
                                              Vehicle Running Report
                                            </p>
                                          </div>
                                        </div>
                                        <Link to="/ReportView">
                                          <img
                                            src={ReportEye}
                                            alt=""
                                            className="add-icon"
                                          />
                                        </Link>
                                      </div>
                                    </div>
                                    <div className="d-flex vehical-card-body customise_Report_Responsive justify-content-between">
                                      <div className="">
                                        <div className="card-contain ">
                                          <p className="sub-heading">
                                            No. of Reports
                                          </p>
                                          <p className="title">20</p>
                                        </div>
                                        <div className="card-contain">
                                          <p className="sub-heading">
                                            Last Updated On
                                          </p>
                                          <p className="title">20-01-2023</p>
                                        </div>
                                      </div>
                                      <div className="">
                                        <img src={ReportCardContain} alt="" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={
                                    sidebar
                                      ? "col-lg-4 col-md-6"
                                      : "col-lg-3 col-md-6"
                                  }
                                >
                                  <div
                                    className={"common-vehical-card-inner "}
                                    id="inner-report-card"
                                  >
                                    <div
                                      className="vehical-card-head"
                                      id="report-main"
                                    >
                                      <div className="heading">
                                        <div className="d-flex">
                                          <img src={RpoetSubCat} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              Report Name
                                            </p>
                                            <p className="title">
                                              Vehicle Running Report
                                            </p>
                                          </div>
                                        </div>
                                        <Link to="/ReportView">
                                          <img
                                            src={ReportEye}
                                            alt=""
                                            className="add-icon"
                                          />
                                        </Link>
                                      </div>
                                    </div>
                                    <div className="d-flex vehical-card-body customise_Report_Responsive justify-content-between">
                                      <div className="">
                                        <div className="card-contain ">
                                          <p className="sub-heading">
                                            No. of Reports
                                          </p>
                                          <p className="title">20</p>
                                        </div>
                                        <div className="card-contain">
                                          <p className="sub-heading">
                                            Last Updated On
                                          </p>
                                          <p className="title">20-01-2023</p>
                                        </div>
                                      </div>
                                      <div className="">
                                        <img src={ReportCardContain} alt="" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={
                                    sidebar
                                      ? "col-lg-4 col-md-6"
                                      : "col-lg-3 col-md-6"
                                  }
                                >
                                  <div
                                    className={"common-vehical-card-inner "}
                                    id="inner-report-card"
                                  >
                                    <div
                                      className="vehical-card-head"
                                      id="report-main"
                                    >
                                      <div className="heading">
                                        <div className="d-flex">
                                          <img src={RpoetSubCat} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              Report Name
                                            </p>
                                            <p className="title">
                                              Vehicle Running Report
                                            </p>
                                          </div>
                                        </div>
                                        <Link to="/ReportView">
                                          <img
                                            src={ReportEye}
                                            alt=""
                                            className="add-icon"
                                          />
                                        </Link>
                                      </div>
                                    </div>
                                    <div className="d-flex vehical-card-body customise_Report_Responsive justify-content-between">
                                      <div className="">
                                        <div className="card-contain ">
                                          <p className="sub-heading">
                                            No. of Reports
                                          </p>
                                          <p className="title">20</p>
                                        </div>
                                        <div className="card-contain">
                                          <p className="sub-heading">
                                            Last Updated On
                                          </p>
                                          <p className="title">20-01-2023</p>
                                        </div>
                                      </div>
                                      <div className="">
                                        <img src={ReportCardContain} alt="" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={
                                    sidebar
                                      ? "col-lg-4 col-md-6"
                                      : "col-lg-3 col-md-6"
                                  }
                                >
                                  <div
                                    className={"common-vehical-card-inner "}
                                    id="inner-report-card"
                                  >
                                    <div
                                      className="vehical-card-head"
                                      id="report-main"
                                    >
                                      <div className="heading">
                                        <div className="d-flex">
                                          <img src={RpoetSubCat} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              Report Name
                                            </p>
                                            <p className="title">
                                              Vehicle Running Report
                                            </p>
                                          </div>
                                        </div>
                                        <Link to="/ReportView">
                                          <img
                                            src={ReportEye}
                                            alt=""
                                            className="add-icon"
                                          />
                                        </Link>
                                      </div>
                                    </div>
                                    <div className="d-flex vehical-card-body customise_Report_Responsive justify-content-between">
                                      <div className="">
                                        <div className="card-contain ">
                                          <p className="sub-heading">
                                            No. of Reports
                                          </p>
                                          <p className="title">20</p>
                                        </div>
                                        <div className="card-contain">
                                          <p className="sub-heading">
                                            Last Updated On
                                          </p>
                                          <p className="title">20-01-2023</p>
                                        </div>
                                      </div>
                                      <div className="">
                                        <img src={ReportCardContain} alt="" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={
                                    sidebar
                                      ? "col-lg-4 col-md-6"
                                      : "col-lg-3 col-md-6"
                                  }
                                >
                                  <div
                                    className={"common-vehical-card-inner "}
                                    id="inner-report-card"
                                  >
                                    <div
                                      className="vehical-card-head"
                                      id="report-main"
                                    >
                                      <div className="heading">
                                        <div className="d-flex">
                                          <img src={RpoetSubCat} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              Report Name
                                            </p>
                                            <p className="title">
                                              Vehicle Running Report
                                            </p>
                                          </div>
                                        </div>
                                        <Link to="/ReportView">
                                          <img
                                            src={ReportEye}
                                            alt=""
                                            className="add-icon"
                                          />
                                        </Link>
                                      </div>
                                    </div>
                                    <div className="d-flex vehical-card-body customise_Report_Responsive justify-content-between">
                                      <div className="">
                                        <div className="card-contain ">
                                          <p className="sub-heading">
                                            No. of Reports
                                          </p>
                                          <p className="title">20</p>
                                        </div>
                                        <div className="card-contain">
                                          <p className="sub-heading">
                                            Last Updated On
                                          </p>
                                          <p className="title">20-01-2023</p>
                                        </div>
                                      </div>
                                      <div className="">
                                        <img src={ReportCardContain} alt="" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={
                                    sidebar
                                      ? "col-lg-4 col-md-6"
                                      : "col-lg-3 col-md-6"
                                  }
                                >
                                  <div
                                    className={"common-vehical-card-inner "}
                                    id="inner-report-card"
                                  >
                                    <div
                                      className="vehical-card-head"
                                      id="report-main"
                                    >
                                      <div className="heading">
                                        <div className="d-flex">
                                          <img src={RpoetSubCat} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              Report Name
                                            </p>
                                            <p className="title">
                                              Vehicle Running Report
                                            </p>
                                          </div>
                                        </div>
                                        <Link to="/ReportView">
                                          <img
                                            src={ReportEye}
                                            alt=""
                                            className="add-icon"
                                          />
                                        </Link>
                                      </div>
                                    </div>
                                    <div className="d-flex vehical-card-body customise_Report_Responsive justify-content-between">
                                      <div className="">
                                        <div className="card-contain ">
                                          <p className="sub-heading">
                                            No. of Reports
                                          </p>
                                          <p className="title">20</p>
                                        </div>
                                        <div className="card-contain">
                                          <p className="sub-heading">
                                            Last Updated On
                                          </p>
                                          <p className="title">20-01-2023</p>
                                        </div>
                                      </div>
                                      <div className="">
                                        <img src={ReportCardContain} alt="" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={
                                    sidebar
                                      ? "col-lg-4 col-md-6"
                                      : "col-lg-3 col-md-6"
                                  }
                                >
                                  <div
                                    className={"common-vehical-card-inner "}
                                    id="inner-report-card"
                                  >
                                    <div
                                      className="vehical-card-head"
                                      id="report-main"
                                    >
                                      <div className="heading">
                                        <div className="d-flex">
                                          <img src={RpoetSubCat} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              Report Name
                                            </p>
                                            <p className="title">
                                              Vehicle Running Report
                                            </p>
                                          </div>
                                        </div>
                                        <Link to="/ReportView">
                                          <img
                                            src={ReportEye}
                                            alt=""
                                            className="add-icon"
                                          />
                                        </Link>
                                      </div>
                                    </div>
                                    <div className="d-flex vehical-card-body customise_Report_Responsive justify-content-between">
                                      <div className="">
                                        <div className="card-contain ">
                                          <p className="sub-heading">
                                            No. of Reports
                                          </p>
                                          <p className="title">20</p>
                                        </div>
                                        <div className="card-contain">
                                          <p className="sub-heading">
                                            Last Updated On
                                          </p>
                                          <p className="title">20-01-2023</p>
                                        </div>
                                      </div>
                                      <div className="">
                                        <img src={ReportCardContain} alt="" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={
                                    sidebar
                                      ? "col-lg-4 col-md-6"
                                      : "col-lg-3 col-md-6"
                                  }
                                >
                                  <div
                                    className={"common-vehical-card-inner "}
                                    id="inner-report-card"
                                  >
                                    <div
                                      className="vehical-card-head"
                                      id="report-main"
                                    >
                                      <div className="heading">
                                        <div className="d-flex">
                                          <img src={RpoetSubCat} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              Report Name
                                            </p>
                                            <p className="title">
                                              Vehicle Running Report
                                            </p>
                                          </div>
                                        </div>
                                        <Link to="/ReportView">
                                          <img
                                            src={ReportEye}
                                            alt=""
                                            className="add-icon"
                                          />
                                        </Link>
                                      </div>
                                    </div>
                                    <div className="d-flex vehical-card-body customise_Report_Responsive justify-content-between">
                                      <div className="">
                                        <div className="card-contain ">
                                          <p className="sub-heading">
                                            No. of Reports
                                          </p>
                                          <p className="title">20</p>
                                        </div>
                                        <div className="card-contain">
                                          <p className="sub-heading">
                                            Last Updated On
                                          </p>
                                          <p className="title">20-01-2023</p>
                                        </div>
                                      </div>
                                      <div className="">
                                        <img src={ReportCardContain} alt="" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="2">
                        <div className="yauto">
                          <div className="all-vehical-head row vehicle-top-inputs">
                            <div className="input-section-wrapper">
                              <div className="row">
                                <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Report Name"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="right-export-btn-section-wrapper">
                              <div className="c-pointer"></div>
                              <div className="c-pointer">
                                {/* <img src={Import} alt="" /> */}
                              </div>
                            </div>
                          </div>
                          {running === false ? (
                            <>
                              <div
                                className="row main-cards-wrapper gx-3"
                                data-aos="zoom-in"
                                data-aos-duration="500"
                              >
                                <div
                                  className={
                                    sidebar
                                      ? "col-lg-4 col-md-6"
                                      : "col-lg-3 col-md-6"
                                  }
                                >
                                  <div className="common-vehical-card-inner">
                                    <div
                                      className="vehical-card-head"
                                      id="report-main"
                                    >
                                      <div className="heading">
                                        <div className="d-flex">
                                          <img src={RunningReport} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              Report Name
                                            </p>
                                            <p className="title">
                                              Vehicle Running Report
                                            </p>
                                          </div>
                                        </div>
                                        <img
                                          src={ReportPluse}
                                          alt=""
                                          className="add-icon"
                                          onClick={() => {
                                            RunningReportFun();
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="d-flex vehical-card-body customise_Report_Responsive justify-content-between">
                                      <div className="">
                                        <div className="card-contain ">
                                          <p className="sub-heading">
                                            No. of Reports
                                          </p>
                                          <p className="title">20</p>
                                        </div>
                                        <div className="card-contain">
                                          <p className="sub-heading">
                                            Last Updated On
                                          </p>
                                          <p className="title">20-01-2023</p>
                                        </div>
                                      </div>
                                      <div className="">
                                        <img src={ReportCardContain} alt="" />
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div
                                  className={
                                    sidebar
                                      ? "col-lg-4 col-md-6"
                                      : "col-lg-3 col-md-6"
                                  }
                                >
                                  <div className="common-vehical-card-inner">
                                    <div
                                      className="vehical-card-head"
                                      id="report-main"
                                    >
                                      <div className="heading">
                                        <div className="d-flex">
                                          <img src={RunningReport} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              Report Name
                                            </p>
                                            <p className="title">
                                              Vehicle Running Report
                                            </p>
                                          </div>
                                        </div>
                                        <img
                                          src={ReportPluse}
                                          alt=""
                                          className="add-icon"
                                          onClick={() => {
                                            RunningReportFun();
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="d-flex vehical-card-body customise_Report_Responsive justify-content-between">
                                      <div className="">
                                        <div className="card-contain ">
                                          <p className="sub-heading">
                                            No. of Reports
                                          </p>
                                          <p className="title">20</p>
                                        </div>
                                        <div className="card-contain">
                                          <p className="sub-heading">
                                            Last Updated On
                                          </p>
                                          <p className="title">20-01-2023</p>
                                        </div>
                                      </div>
                                      <div className="">
                                        <img src={ReportCardContain} alt="" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={
                                    sidebar
                                      ? "col-lg-4 col-md-6"
                                      : "col-lg-3 col-md-6"
                                  }
                                >
                                  <div className="common-vehical-card-inner">
                                    <div
                                      className="vehical-card-head"
                                      id="report-main"
                                    >
                                      <div className="heading">
                                        <div className="d-flex">
                                          <img src={RunningReport} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              Report Name
                                            </p>
                                            <p className="title">
                                              Vehicle Running Report
                                            </p>
                                          </div>
                                        </div>
                                        <img
                                          src={ReportPluse}
                                          alt=""
                                          className="add-icon"
                                          onClick={() => {
                                            RunningReportFun();
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="d-flex vehical-card-body customise_Report_Responsive justify-content-between">
                                      <div className="">
                                        <div className="card-contain ">
                                          <p className="sub-heading">
                                            No. of Reports
                                          </p>
                                          <p className="title">20</p>
                                        </div>
                                        <div className="card-contain">
                                          <p className="sub-heading">
                                            Last Updated On
                                          </p>
                                          <p className="title">20-01-2023</p>
                                        </div>
                                      </div>
                                      <div className="">
                                        <img src={ReportCardContain} alt="" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={
                                    sidebar
                                      ? "col-lg-4 col-md-6"
                                      : "col-lg-3 col-md-6"
                                  }
                                >
                                  <div className="common-vehical-card-inner">
                                    <div
                                      className="vehical-card-head"
                                      id="report-main"
                                    >
                                      <div className="heading">
                                        <div className="d-flex">
                                          <img src={RunningReport} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              Report Name
                                            </p>
                                            <p className="title">
                                              Vehicle Running Report
                                            </p>
                                          </div>
                                        </div>
                                        <img
                                          src={ReportPluse}
                                          alt=""
                                          className="add-icon"
                                          onClick={() => {
                                            RunningReportFun();
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="d-flex vehical-card-body customise_Report_Responsive justify-content-between">
                                      <div className="">
                                        <div className="card-contain ">
                                          <p className="sub-heading">
                                            No. of Reports
                                          </p>
                                          <p className="title">20</p>
                                        </div>
                                        <div className="card-contain">
                                          <p className="sub-heading">
                                            Last Updated On
                                          </p>
                                          <p className="title">20-01-2023</p>
                                        </div>
                                      </div>
                                      <div className="">
                                        <img src={ReportCardContain} alt="" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={
                                    sidebar
                                      ? "col-lg-4 col-md-6"
                                      : "col-lg-3 col-md-6"
                                  }
                                >
                                  <div className="common-vehical-card-inner">
                                    <div
                                      className="vehical-card-head"
                                      id="report-main"
                                    >
                                      <div className="heading">
                                        <div className="d-flex">
                                          <img src={RunningReport} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              Report Name
                                            </p>
                                            <p className="title">
                                              Vehicle Running Report
                                            </p>
                                          </div>
                                        </div>
                                        <img
                                          src={ReportPluse}
                                          alt=""
                                          className="add-icon"
                                          onClick={() => {
                                            RunningReportFun();
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="d-flex vehical-card-body customise_Report_Responsive justify-content-between">
                                      <div className="">
                                        <div className="card-contain ">
                                          <p className="sub-heading">
                                            No. of Reports
                                          </p>
                                          <p className="title">20</p>
                                        </div>
                                        <div className="card-contain">
                                          <p className="sub-heading">
                                            Last Updated On
                                          </p>
                                          <p className="title">20-01-2023</p>
                                        </div>
                                      </div>
                                      <div className="">
                                        <img src={ReportCardContain} alt="" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={
                                    sidebar
                                      ? "col-lg-4 col-md-6"
                                      : "col-lg-3 col-md-6"
                                  }
                                >
                                  <div className="common-vehical-card-inner">
                                    <div
                                      className="vehical-card-head"
                                      id="report-main"
                                    >
                                      <div className="heading">
                                        <div className="d-flex">
                                          <img src={RunningReport} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              Report Name
                                            </p>
                                            <p className="title">
                                              Vehicle Running Report
                                            </p>
                                          </div>
                                        </div>
                                        <img
                                          src={ReportPluse}
                                          alt=""
                                          className="add-icon"
                                          onClick={() => {
                                            RunningReportFun();
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="d-flex vehical-card-body customise_Report_Responsive justify-content-between">
                                      <div className="">
                                        <div className="card-contain ">
                                          <p className="sub-heading">
                                            No. of Reports
                                          </p>
                                          <p className="title">20</p>
                                        </div>
                                        <div className="card-contain">
                                          <p className="sub-heading">
                                            Last Updated On
                                          </p>
                                          <p className="title">20-01-2023</p>
                                        </div>
                                      </div>
                                      <div className="">
                                        <img src={ReportCardContain} alt="" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={
                                    sidebar
                                      ? "col-lg-4 col-md-6"
                                      : "col-lg-3 col-md-6"
                                  }
                                >
                                  <div className="common-vehical-card-inner">
                                    <div
                                      className="vehical-card-head"
                                      id="report-main"
                                    >
                                      <div className="heading">
                                        <div className="d-flex">
                                          <img src={RunningReport} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              Report Name
                                            </p>
                                            <p className="title">
                                              Vehicle Running Report
                                            </p>
                                          </div>
                                        </div>
                                        <img
                                          src={ReportPluse}
                                          alt=""
                                          className="add-icon"
                                          onClick={() => {
                                            RunningReportFun();
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="d-flex vehical-card-body customise_Report_Responsive justify-content-between">
                                      <div className="">
                                        <div className="card-contain ">
                                          <p className="sub-heading">
                                            No. of Reports
                                          </p>
                                          <p className="title">20</p>
                                        </div>
                                        <div className="card-contain">
                                          <p className="sub-heading">
                                            Last Updated On
                                          </p>
                                          <p className="title">20-01-2023</p>
                                        </div>
                                      </div>
                                      <div className="">
                                        <img src={ReportCardContain} alt="" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={
                                    sidebar
                                      ? "col-lg-4 col-md-6"
                                      : "col-lg-3 col-md-6"
                                  }
                                >
                                  <div className="common-vehical-card-inner">
                                    <div
                                      className="vehical-card-head"
                                      id="report-main"
                                    >
                                      <div className="heading">
                                        <div className="d-flex">
                                          <img src={RunningReport} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              Report Name
                                            </p>
                                            <p className="title">
                                              Vehicle Running Report
                                            </p>
                                          </div>
                                        </div>
                                        <img
                                          src={ReportPluse}
                                          alt=""
                                          className="add-icon"
                                          onClick={() => {
                                            RunningReportFun();
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="d-flex vehical-card-body customise_Report_Responsive justify-content-between">
                                      <div className="">
                                        <div className="card-contain ">
                                          <p className="sub-heading">
                                            No. of Reports
                                          </p>
                                          <p className="title">20</p>
                                        </div>
                                        <div className="card-contain">
                                          <p className="sub-heading">
                                            Last Updated On
                                          </p>
                                          <p className="title">20-01-2023</p>
                                        </div>
                                      </div>
                                      <div className="">
                                        <img src={ReportCardContain} alt="" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <div
                              variants={aninations}
                              initial="initial"
                              animate="animate"
                              exit="exit"
                              transition={{
                                duration: 0.3,
                              }}
                            >
                              <div
                                className=" row gx-3 main-cards-wrapper"
                                id="Report_scrol"
                                data-aos="zoom-in"
                                data-aos-duration="500"
                              >
                                <div
                                  className={
                                    sidebar
                                      ? "col-lg-4 col-md-6"
                                      : "col-lg-3 col-md-6"
                                  }
                                >
                                  <div className={"common-vehical-card-inner"}>
                                    <div
                                      className="vehical-card-head"
                                      id="report-main"
                                    >
                                      <div className="heading">
                                        <div className="d-flex">
                                          <img src={RunningReport} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              Report Name
                                            </p>
                                            <p className="title">
                                              Vehicle Running Report
                                            </p>
                                          </div>
                                        </div>
                                        <img
                                          src={ReportClose}
                                          alt=""
                                          className="add-icon"
                                          onClick={() => {
                                            MainRunnig();
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="d-flex vehical-card-body customise_Report_Responsive justify-content-between">
                                      <div className="">
                                        <div className="card-contain ">
                                          <p className="sub-heading">
                                            No. of Reports
                                          </p>
                                          <p className="title">20</p>
                                        </div>
                                        <div className="card-contain">
                                          <p className="sub-heading">
                                            Last Updated On
                                          </p>
                                          <p className="title">20-01-2023</p>
                                        </div>
                                      </div>
                                      <div className="">
                                        <img src={ReportCardContain} alt="" />
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div
                                  className={
                                    sidebar
                                      ? "col-lg-4 col-md-6"
                                      : "col-lg-3 col-md-6"
                                  }
                                >
                                  <div
                                    className={"common-vehical-card-inner "}
                                    id="inner-report-card"
                                  >
                                    <div
                                      className="vehical-card-head"
                                      id="report-main"
                                    >
                                      <div className="heading">
                                        <div className="d-flex">
                                          <img src={RpoetSubCat} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              Report Name
                                            </p>
                                            <p className="title">
                                              Vehicle Running Report
                                            </p>
                                          </div>
                                        </div>
                                        <Link to="/ReportView">
                                          <img
                                            src={ReportEye}
                                            alt=""
                                            className="add-icon"
                                          />
                                        </Link>
                                      </div>
                                    </div>
                                    <div className="d-flex vehical-card-body customise_Report_Responsive justify-content-between">
                                      <div className="">
                                        <div className="card-contain ">
                                          <p className="sub-heading">
                                            No. of Reports
                                          </p>
                                          <p className="title">20</p>
                                        </div>
                                        <div className="card-contain">
                                          <p className="sub-heading">
                                            Last Updated On
                                          </p>
                                          <p className="title">20-01-2023</p>
                                        </div>
                                      </div>
                                      <div className="">
                                        <img src={ReportCardContain} alt="" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={
                                    sidebar
                                      ? "col-lg-4 col-md-6"
                                      : "col-lg-3 col-md-6"
                                  }
                                >
                                  <div
                                    className={"common-vehical-card-inner "}
                                    id="inner-report-card"
                                  >
                                    <div
                                      className="vehical-card-head"
                                      id="report-main"
                                    >
                                      <div className="heading">
                                        <div className="d-flex">
                                          <img src={RpoetSubCat} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              Report Name
                                            </p>
                                            <p className="title">
                                              Vehicle Running Report
                                            </p>
                                          </div>
                                        </div>
                                        <Link to="/ReportView">
                                          <img
                                            src={ReportEye}
                                            alt=""
                                            className="add-icon"
                                          />
                                        </Link>
                                      </div>
                                    </div>
                                    <div className="d-flex vehical-card-body customise_Report_Responsive justify-content-between">
                                      <div className="">
                                        <div className="card-contain ">
                                          <p className="sub-heading">
                                            No. of Reports
                                          </p>
                                          <p className="title">20</p>
                                        </div>
                                        <div className="card-contain">
                                          <p className="sub-heading">
                                            Last Updated On
                                          </p>
                                          <p className="title">20-01-2023</p>
                                        </div>
                                      </div>
                                      <div className="">
                                        <img src={ReportCardContain} alt="" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={
                                    sidebar
                                      ? "col-lg-4 col-md-6"
                                      : "col-lg-3 col-md-6"
                                  }
                                >
                                  <div
                                    className={"common-vehical-card-inner "}
                                    id="inner-report-card"
                                  >
                                    <div
                                      className="vehical-card-head"
                                      id="report-main"
                                    >
                                      <div className="heading">
                                        <div className="d-flex">
                                          <img src={RpoetSubCat} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              Report Name
                                            </p>
                                            <p className="title">
                                              Vehicle Running Report
                                            </p>
                                          </div>
                                        </div>
                                        <Link to="/ReportView">
                                          <img
                                            src={ReportEye}
                                            alt=""
                                            className="add-icon"
                                          />
                                        </Link>
                                      </div>
                                    </div>
                                    <div className="d-flex vehical-card-body customise_Report_Responsive justify-content-between">
                                      <div className="">
                                        <div className="card-contain ">
                                          <p className="sub-heading">
                                            No. of Reports
                                          </p>
                                          <p className="title">20</p>
                                        </div>
                                        <div className="card-contain">
                                          <p className="sub-heading">
                                            Last Updated On
                                          </p>
                                          <p className="title">20-01-2023</p>
                                        </div>
                                      </div>
                                      <div className="">
                                        <img src={ReportCardContain} alt="" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={
                                    sidebar
                                      ? "col-lg-4 col-md-6"
                                      : "col-lg-3 col-md-6"
                                  }
                                >
                                  <div
                                    className={"common-vehical-card-inner "}
                                    id="inner-report-card"
                                  >
                                    <div
                                      className="vehical-card-head"
                                      id="report-main"
                                    >
                                      <div className="heading">
                                        <div className="d-flex">
                                          <img src={RpoetSubCat} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              Report Name
                                            </p>
                                            <p className="title">
                                              Vehicle Running Report
                                            </p>
                                          </div>
                                        </div>
                                        <Link to="/ReportView">
                                          <img
                                            src={ReportEye}
                                            alt=""
                                            className="add-icon"
                                          />
                                        </Link>
                                      </div>
                                    </div>
                                    <div className="d-flex vehical-card-body customise_Report_Responsive justify-content-between">
                                      <div className="">
                                        <div className="card-contain ">
                                          <p className="sub-heading">
                                            No. of Reports
                                          </p>
                                          <p className="title">20</p>
                                        </div>
                                        <div className="card-contain">
                                          <p className="sub-heading">
                                            Last Updated On
                                          </p>
                                          <p className="title">20-01-2023</p>
                                        </div>
                                      </div>
                                      <div className="">
                                        <img src={ReportCardContain} alt="" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={
                                    sidebar
                                      ? "col-lg-4 col-md-6"
                                      : "col-lg-3 col-md-6"
                                  }
                                >
                                  <div
                                    className={"common-vehical-card-inner "}
                                    id="inner-report-card"
                                  >
                                    <div
                                      className="vehical-card-head"
                                      id="report-main"
                                    >
                                      <div className="heading">
                                        <div className="d-flex">
                                          <img src={RpoetSubCat} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              Report Name
                                            </p>
                                            <p className="title">
                                              Vehicle Running Report
                                            </p>
                                          </div>
                                        </div>
                                        <Link to="/ReportView">
                                          <img
                                            src={ReportEye}
                                            alt=""
                                            className="add-icon"
                                          />
                                        </Link>
                                      </div>
                                    </div>
                                    <div className="d-flex vehical-card-body customise_Report_Responsive justify-content-between">
                                      <div className="">
                                        <div className="card-contain ">
                                          <p className="sub-heading">
                                            No. of Reports
                                          </p>
                                          <p className="title">20</p>
                                        </div>
                                        <div className="card-contain">
                                          <p className="sub-heading">
                                            Last Updated On
                                          </p>
                                          <p className="title">20-01-2023</p>
                                        </div>
                                      </div>
                                      <div className="">
                                        <img src={ReportCardContain} alt="" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={
                                    sidebar
                                      ? "col-lg-4 col-md-6"
                                      : "col-lg-3 col-md-6"
                                  }
                                >
                                  <div
                                    className={"common-vehical-card-inner "}
                                    id="inner-report-card"
                                  >
                                    <div
                                      className="vehical-card-head"
                                      id="report-main"
                                    >
                                      <div className="heading">
                                        <div className="d-flex">
                                          <img src={RpoetSubCat} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              Report Name
                                            </p>
                                            <p className="title">
                                              Vehicle Running Report
                                            </p>
                                          </div>
                                        </div>
                                        <Link to="/ReportView">
                                          <img
                                            src={ReportEye}
                                            alt=""
                                            className="add-icon"
                                          />
                                        </Link>
                                      </div>
                                    </div>
                                    <div className="d-flex vehical-card-body customise_Report_Responsive justify-content-between">
                                      <div className="">
                                        <div className="card-contain ">
                                          <p className="sub-heading">
                                            No. of Reports
                                          </p>
                                          <p className="title">20</p>
                                        </div>
                                        <div className="card-contain">
                                          <p className="sub-heading">
                                            Last Updated On
                                          </p>
                                          <p className="title">20-01-2023</p>
                                        </div>
                                      </div>
                                      <div className="">
                                        <img src={ReportCardContain} alt="" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={
                                    sidebar
                                      ? "col-lg-4 col-md-6"
                                      : "col-lg-3 col-md-6"
                                  }
                                >
                                  <div
                                    className={"common-vehical-card-inner "}
                                    id="inner-report-card"
                                  >
                                    <div
                                      className="vehical-card-head"
                                      id="report-main"
                                    >
                                      <div className="heading">
                                        <div className="d-flex">
                                          <img src={RpoetSubCat} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              Report Name
                                            </p>
                                            <p className="title">
                                              Vehicle Running Report
                                            </p>
                                          </div>
                                        </div>
                                        <Link to="/ReportView">
                                          <img
                                            src={ReportEye}
                                            alt=""
                                            className="add-icon"
                                          />
                                        </Link>
                                      </div>
                                    </div>
                                    <div className="d-flex vehical-card-body customise_Report_Responsive justify-content-between">
                                      <div className="">
                                        <div className="card-contain ">
                                          <p className="sub-heading">
                                            No. of Reports
                                          </p>
                                          <p className="title">20</p>
                                        </div>
                                        <div className="card-contain">
                                          <p className="sub-heading">
                                            Last Updated On
                                          </p>
                                          <p className="title">20-01-2023</p>
                                        </div>
                                      </div>
                                      <div className="">
                                        <img src={ReportCardContain} alt="" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={
                                    sidebar
                                      ? "col-lg-4 col-md-6"
                                      : "col-lg-3 col-md-6"
                                  }
                                >
                                  <div
                                    className={"common-vehical-card-inner "}
                                    id="inner-report-card"
                                  >
                                    <div
                                      className="vehical-card-head"
                                      id="report-main"
                                    >
                                      <div className="heading">
                                        <div className="d-flex">
                                          <img src={RpoetSubCat} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              Report Name
                                            </p>
                                            <p className="title">
                                              Vehicle Running Report
                                            </p>
                                          </div>
                                        </div>
                                        <Link to="/ReportView">
                                          <img
                                            src={ReportEye}
                                            alt=""
                                            className="add-icon"
                                          />
                                        </Link>
                                      </div>
                                    </div>
                                    <div className="d-flex vehical-card-body customise_Report_Responsive justify-content-between">
                                      <div className="">
                                        <div className="card-contain ">
                                          <p className="sub-heading">
                                            No. of Reports
                                          </p>
                                          <p className="title">20</p>
                                        </div>
                                        <div className="card-contain">
                                          <p className="sub-heading">
                                            Last Updated On
                                          </p>
                                          <p className="title">20-01-2023</p>
                                        </div>
                                      </div>
                                      <div className="">
                                        <img src={ReportCardContain} alt="" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="3">
                        <div className="yauto">
                          <div className="all-vehical-head row vehicle-top-inputs">
                            <div className="input-section-wrapper">
                              <div className="row">
                                <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Report Name"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="right-export-btn-section-wrapper">
                              <div className="c-pointer"></div>
                              <div className="c-pointer">
                                {/* <img src={Import} alt="" /> */}
                              </div>
                            </div>
                          </div>
                          {running === false ? (
                            <>
                              <div
                                className="row main-cards-wrapper gx-3"
                                data-aos="zoom-in"
                                data-aos-duration="500"
                              >
                                <div
                                  className={
                                    sidebar
                                      ? "col-lg-4 col-md-6"
                                      : "col-lg-3 col-md-6"
                                  }
                                >
                                  <div className="common-vehical-card-inner">
                                    <div
                                      className="vehical-card-head"
                                      id="report-main"
                                    >
                                      <div className="heading">
                                        <div className="d-flex">
                                          <img src={RunningReport} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              Report Name
                                            </p>
                                            <p className="title">
                                              Vehicle Running Report
                                            </p>
                                          </div>
                                        </div>
                                        <img
                                          src={ReportPluse}
                                          alt=""
                                          className="add-icon"
                                          onClick={() => {
                                            RunningReportFun();
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="d-flex vehical-card-body customise_Report_Responsive justify-content-between">
                                      <div className="">
                                        <div className="card-contain ">
                                          <p className="sub-heading">
                                            No. of Reports
                                          </p>
                                          <p className="title">20</p>
                                        </div>
                                        <div className="card-contain">
                                          <p className="sub-heading">
                                            Last Updated On
                                          </p>
                                          <p className="title">20-01-2023</p>
                                        </div>
                                      </div>
                                      <div className="">
                                        <img src={ReportCardContain} alt="" />
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div
                                  className={
                                    sidebar
                                      ? "col-lg-4 col-md-6"
                                      : "col-lg-3 col-md-6"
                                  }
                                >
                                  <div className="common-vehical-card-inner">
                                    <div
                                      className="vehical-card-head"
                                      id="report-main"
                                    >
                                      <div className="heading">
                                        <div className="d-flex">
                                          <img src={RunningReport} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              Report Name
                                            </p>
                                            <p className="title">
                                              Vehicle Running Report
                                            </p>
                                          </div>
                                        </div>
                                        <img
                                          src={ReportPluse}
                                          alt=""
                                          className="add-icon"
                                          onClick={() => {
                                            RunningReportFun();
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="d-flex vehical-card-body customise_Report_Responsive justify-content-between">
                                      <div className="">
                                        <div className="card-contain ">
                                          <p className="sub-heading">
                                            No. of Reports
                                          </p>
                                          <p className="title">20</p>
                                        </div>
                                        <div className="card-contain">
                                          <p className="sub-heading">
                                            Last Updated On
                                          </p>
                                          <p className="title">20-01-2023</p>
                                        </div>
                                      </div>
                                      <div className="">
                                        <img src={ReportCardContain} alt="" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={
                                    sidebar
                                      ? "col-lg-4 col-md-6"
                                      : "col-lg-3 col-md-6"
                                  }
                                >
                                  <div className="common-vehical-card-inner">
                                    <div
                                      className="vehical-card-head"
                                      id="report-main"
                                    >
                                      <div className="heading">
                                        <div className="d-flex">
                                          <img src={RunningReport} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              Report Name
                                            </p>
                                            <p className="title">
                                              Vehicle Running Report
                                            </p>
                                          </div>
                                        </div>
                                        <img
                                          src={ReportPluse}
                                          alt=""
                                          className="add-icon"
                                          onClick={() => {
                                            RunningReportFun();
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="d-flex vehical-card-body customise_Report_Responsive justify-content-between">
                                      <div className="">
                                        <div className="card-contain ">
                                          <p className="sub-heading">
                                            No. of Reports
                                          </p>
                                          <p className="title">20</p>
                                        </div>
                                        <div className="card-contain">
                                          <p className="sub-heading">
                                            Last Updated On
                                          </p>
                                          <p className="title">20-01-2023</p>
                                        </div>
                                      </div>
                                      <div className="">
                                        <img src={ReportCardContain} alt="" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={
                                    sidebar
                                      ? "col-lg-4 col-md-6"
                                      : "col-lg-3 col-md-6"
                                  }
                                >
                                  <div className="common-vehical-card-inner">
                                    <div
                                      className="vehical-card-head"
                                      id="report-main"
                                    >
                                      <div className="heading">
                                        <div className="d-flex">
                                          <img src={RunningReport} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              Report Name
                                            </p>
                                            <p className="title">
                                              Vehicle Running Report
                                            </p>
                                          </div>
                                        </div>
                                        <img
                                          src={ReportPluse}
                                          alt=""
                                          className="add-icon"
                                          onClick={() => {
                                            RunningReportFun();
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="d-flex vehical-card-body customise_Report_Responsive justify-content-between">
                                      <div className="">
                                        <div className="card-contain ">
                                          <p className="sub-heading">
                                            No. of Reports
                                          </p>
                                          <p className="title">20</p>
                                        </div>
                                        <div className="card-contain">
                                          <p className="sub-heading">
                                            Last Updated On
                                          </p>
                                          <p className="title">20-01-2023</p>
                                        </div>
                                      </div>
                                      <div className="">
                                        <img src={ReportCardContain} alt="" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={
                                    sidebar
                                      ? "col-lg-4 col-md-6"
                                      : "col-lg-3 col-md-6"
                                  }
                                >
                                  <div className="common-vehical-card-inner">
                                    <div
                                      className="vehical-card-head"
                                      id="report-main"
                                    >
                                      <div className="heading">
                                        <div className="d-flex">
                                          <img src={RunningReport} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              Report Name
                                            </p>
                                            <p className="title">
                                              Vehicle Running Report
                                            </p>
                                          </div>
                                        </div>
                                        <img
                                          src={ReportPluse}
                                          alt=""
                                          className="add-icon"
                                          onClick={() => {
                                            RunningReportFun();
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="d-flex vehical-card-body customise_Report_Responsive justify-content-between">
                                      <div className="">
                                        <div className="card-contain ">
                                          <p className="sub-heading">
                                            No. of Reports
                                          </p>
                                          <p className="title">20</p>
                                        </div>
                                        <div className="card-contain">
                                          <p className="sub-heading">
                                            Last Updated On
                                          </p>
                                          <p className="title">20-01-2023</p>
                                        </div>
                                      </div>
                                      <div className="">
                                        <img src={ReportCardContain} alt="" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={
                                    sidebar
                                      ? "col-lg-4 col-md-6"
                                      : "col-lg-3 col-md-6"
                                  }
                                >
                                  <div className="common-vehical-card-inner">
                                    <div
                                      className="vehical-card-head"
                                      id="report-main"
                                    >
                                      <div className="heading">
                                        <div className="d-flex">
                                          <img src={RunningReport} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              Report Name
                                            </p>
                                            <p className="title">
                                              Vehicle Running Report
                                            </p>
                                          </div>
                                        </div>
                                        <img
                                          src={ReportPluse}
                                          alt=""
                                          className="add-icon"
                                          onClick={() => {
                                            RunningReportFun();
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="d-flex vehical-card-body customise_Report_Responsive justify-content-between">
                                      <div className="">
                                        <div className="card-contain ">
                                          <p className="sub-heading">
                                            No. of Reports
                                          </p>
                                          <p className="title">20</p>
                                        </div>
                                        <div className="card-contain">
                                          <p className="sub-heading">
                                            Last Updated On
                                          </p>
                                          <p className="title">20-01-2023</p>
                                        </div>
                                      </div>
                                      <div className="">
                                        <img src={ReportCardContain} alt="" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={
                                    sidebar
                                      ? "col-lg-4 col-md-6"
                                      : "col-lg-3 col-md-6"
                                  }
                                >
                                  <div className="common-vehical-card-inner">
                                    <div
                                      className="vehical-card-head"
                                      id="report-main"
                                    >
                                      <div className="heading">
                                        <div className="d-flex">
                                          <img src={RunningReport} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              Report Name
                                            </p>
                                            <p className="title">
                                              Vehicle Running Report
                                            </p>
                                          </div>
                                        </div>
                                        <img
                                          src={ReportPluse}
                                          alt=""
                                          className="add-icon"
                                          onClick={() => {
                                            RunningReportFun();
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="d-flex vehical-card-body customise_Report_Responsive justify-content-between">
                                      <div className="">
                                        <div className="card-contain ">
                                          <p className="sub-heading">
                                            No. of Reports
                                          </p>
                                          <p className="title">20</p>
                                        </div>
                                        <div className="card-contain">
                                          <p className="sub-heading">
                                            Last Updated On
                                          </p>
                                          <p className="title">20-01-2023</p>
                                        </div>
                                      </div>
                                      <div className="">
                                        <img src={ReportCardContain} alt="" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={
                                    sidebar
                                      ? "col-lg-4 col-md-6"
                                      : "col-lg-3 col-md-6"
                                  }
                                >
                                  <div className="common-vehical-card-inner">
                                    <div
                                      className="vehical-card-head"
                                      id="report-main"
                                    >
                                      <div className="heading">
                                        <div className="d-flex">
                                          <img src={RunningReport} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              Report Name
                                            </p>
                                            <p className="title">
                                              Vehicle Running Report
                                            </p>
                                          </div>
                                        </div>
                                        <img
                                          src={ReportPluse}
                                          alt=""
                                          className="add-icon"
                                          onClick={() => {
                                            RunningReportFun();
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="d-flex vehical-card-body customise_Report_Responsive justify-content-between">
                                      <div className="">
                                        <div className="card-contain ">
                                          <p className="sub-heading">
                                            No. of Reports
                                          </p>
                                          <p className="title">20</p>
                                        </div>
                                        <div className="card-contain">
                                          <p className="sub-heading">
                                            Last Updated On
                                          </p>
                                          <p className="title">20-01-2023</p>
                                        </div>
                                      </div>
                                      <div className="">
                                        <img src={ReportCardContain} alt="" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <div
                              variants={aninations}
                              initial="initial"
                              animate="animate"
                              exit="exit"
                              transition={{
                                duration: 0.3,
                              }}
                            >
                              <div
                                className=" row gx-3 main-cards-wrapper"
                                id="Report_scrol"
                                data-aos="zoom-in"
                                data-aos-duration="500"
                              >
                                <div
                                  className={
                                    sidebar
                                      ? "col-lg-4 col-md-6"
                                      : "col-lg-3 col-md-6"
                                  }
                                >
                                  <div className={"common-vehical-card-inner"}>
                                    <div
                                      className="vehical-card-head"
                                      id="report-main"
                                    >
                                      <div className="heading">
                                        <div className="d-flex">
                                          <img src={RunningReport} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              Report Name
                                            </p>
                                            <p className="title">
                                              Vehicle Running Report
                                            </p>
                                          </div>
                                        </div>
                                        <img
                                          src={ReportClose}
                                          alt=""
                                          className="add-icon"
                                          onClick={() => {
                                            MainRunnig();
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="d-flex vehical-card-body customise_Report_Responsive justify-content-between">
                                      <div className="">
                                        <div className="card-contain ">
                                          <p className="sub-heading">
                                            No. of Reports
                                          </p>
                                          <p className="title">20</p>
                                        </div>
                                        <div className="card-contain">
                                          <p className="sub-heading">
                                            Last Updated On
                                          </p>
                                          <p className="title">20-01-2023</p>
                                        </div>
                                      </div>
                                      <div className="">
                                        <img src={ReportCardContain} alt="" />
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div
                                  className={
                                    sidebar
                                      ? "col-lg-4 col-md-6"
                                      : "col-lg-3 col-md-6"
                                  }
                                >
                                  <div
                                    className={"common-vehical-card-inner "}
                                    id="inner-report-card"
                                  >
                                    <div
                                      className="vehical-card-head"
                                      id="report-main"
                                    >
                                      <div className="heading">
                                        <div className="d-flex">
                                          <img src={RpoetSubCat} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              Report Name
                                            </p>
                                            <p className="title">
                                              Vehicle Running Report
                                            </p>
                                          </div>
                                        </div>
                                        <Link to="/ReportView">
                                          <img
                                            src={ReportEye}
                                            alt=""
                                            className="add-icon"
                                          />
                                        </Link>
                                      </div>
                                    </div>
                                    <div className="d-flex vehical-card-body customise_Report_Responsive justify-content-between">
                                      <div className="">
                                        <div className="card-contain ">
                                          <p className="sub-heading">
                                            No. of Reports
                                          </p>
                                          <p className="title">20</p>
                                        </div>
                                        <div className="card-contain">
                                          <p className="sub-heading">
                                            Last Updated On
                                          </p>
                                          <p className="title">20-01-2023</p>
                                        </div>
                                      </div>
                                      <div className="">
                                        <img src={ReportCardContain} alt="" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={
                                    sidebar
                                      ? "col-lg-4 col-md-6"
                                      : "col-lg-3 col-md-6"
                                  }
                                >
                                  <div
                                    className={"common-vehical-card-inner "}
                                    id="inner-report-card"
                                  >
                                    <div
                                      className="vehical-card-head"
                                      id="report-main"
                                    >
                                      <div className="heading">
                                        <div className="d-flex">
                                          <img src={RpoetSubCat} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              Report Name
                                            </p>
                                            <p className="title">
                                              Vehicle Running Report
                                            </p>
                                          </div>
                                        </div>
                                        <Link to="/ReportView">
                                          <img
                                            src={ReportEye}
                                            alt=""
                                            className="add-icon"
                                          />
                                        </Link>
                                      </div>
                                    </div>
                                    <div className="d-flex vehical-card-body customise_Report_Responsive justify-content-between">
                                      <div className="">
                                        <div className="card-contain ">
                                          <p className="sub-heading">
                                            No. of Reports
                                          </p>
                                          <p className="title">20</p>
                                        </div>
                                        <div className="card-contain">
                                          <p className="sub-heading">
                                            Last Updated On
                                          </p>
                                          <p className="title">20-01-2023</p>
                                        </div>
                                      </div>
                                      <div className="">
                                        <img src={ReportCardContain} alt="" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={
                                    sidebar
                                      ? "col-lg-4 col-md-6"
                                      : "col-lg-3 col-md-6"
                                  }
                                >
                                  <div
                                    className={"common-vehical-card-inner "}
                                    id="inner-report-card"
                                  >
                                    <div
                                      className="vehical-card-head"
                                      id="report-main"
                                    >
                                      <div className="heading">
                                        <div className="d-flex">
                                          <img src={RpoetSubCat} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              Report Name
                                            </p>
                                            <p className="title">
                                              Vehicle Running Report
                                            </p>
                                          </div>
                                        </div>
                                        <Link to="/ReportView">
                                          <img
                                            src={ReportEye}
                                            alt=""
                                            className="add-icon"
                                          />
                                        </Link>
                                      </div>
                                    </div>
                                    <div className="d-flex vehical-card-body customise_Report_Responsive justify-content-between">
                                      <div className="">
                                        <div className="card-contain ">
                                          <p className="sub-heading">
                                            No. of Reports
                                          </p>
                                          <p className="title">20</p>
                                        </div>
                                        <div className="card-contain">
                                          <p className="sub-heading">
                                            Last Updated On
                                          </p>
                                          <p className="title">20-01-2023</p>
                                        </div>
                                      </div>
                                      <div className="">
                                        <img src={ReportCardContain} alt="" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={
                                    sidebar
                                      ? "col-lg-4 col-md-6"
                                      : "col-lg-3 col-md-6"
                                  }
                                >
                                  <div
                                    className={"common-vehical-card-inner "}
                                    id="inner-report-card"
                                  >
                                    <div
                                      className="vehical-card-head"
                                      id="report-main"
                                    >
                                      <div className="heading">
                                        <div className="d-flex">
                                          <img src={RpoetSubCat} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              Report Name
                                            </p>
                                            <p className="title">
                                              Vehicle Running Report
                                            </p>
                                          </div>
                                        </div>
                                        <Link to="/ReportView">
                                          <img
                                            src={ReportEye}
                                            alt=""
                                            className="add-icon"
                                          />
                                        </Link>
                                      </div>
                                    </div>
                                    <div className="d-flex vehical-card-body customise_Report_Responsive justify-content-between">
                                      <div className="">
                                        <div className="card-contain ">
                                          <p className="sub-heading">
                                            No. of Reports
                                          </p>
                                          <p className="title">20</p>
                                        </div>
                                        <div className="card-contain">
                                          <p className="sub-heading">
                                            Last Updated On
                                          </p>
                                          <p className="title">20-01-2023</p>
                                        </div>
                                      </div>
                                      <div className="">
                                        <img src={ReportCardContain} alt="" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={
                                    sidebar
                                      ? "col-lg-4 col-md-6"
                                      : "col-lg-3 col-md-6"
                                  }
                                >
                                  <div
                                    className={"common-vehical-card-inner "}
                                    id="inner-report-card"
                                  >
                                    <div
                                      className="vehical-card-head"
                                      id="report-main"
                                    >
                                      <div className="heading">
                                        <div className="d-flex">
                                          <img src={RpoetSubCat} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              Report Name
                                            </p>
                                            <p className="title">
                                              Vehicle Running Report
                                            </p>
                                          </div>
                                        </div>
                                        <Link to="/ReportView">
                                          <img
                                            src={ReportEye}
                                            alt=""
                                            className="add-icon"
                                          />
                                        </Link>
                                      </div>
                                    </div>
                                    <div className="d-flex vehical-card-body customise_Report_Responsive justify-content-between">
                                      <div className="">
                                        <div className="card-contain ">
                                          <p className="sub-heading">
                                            No. of Reports
                                          </p>
                                          <p className="title">20</p>
                                        </div>
                                        <div className="card-contain">
                                          <p className="sub-heading">
                                            Last Updated On
                                          </p>
                                          <p className="title">20-01-2023</p>
                                        </div>
                                      </div>
                                      <div className="">
                                        <img src={ReportCardContain} alt="" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={
                                    sidebar
                                      ? "col-lg-4 col-md-6"
                                      : "col-lg-3 col-md-6"
                                  }
                                >
                                  <div
                                    className={"common-vehical-card-inner "}
                                    id="inner-report-card"
                                  >
                                    <div
                                      className="vehical-card-head"
                                      id="report-main"
                                    >
                                      <div className="heading">
                                        <div className="d-flex">
                                          <img src={RpoetSubCat} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              Report Name
                                            </p>
                                            <p className="title">
                                              Vehicle Running Report
                                            </p>
                                          </div>
                                        </div>
                                        <Link to="/ReportView">
                                          <img
                                            src={ReportEye}
                                            alt=""
                                            className="add-icon"
                                          />
                                        </Link>
                                      </div>
                                    </div>
                                    <div className="d-flex vehical-card-body customise_Report_Responsive justify-content-between">
                                      <div className="">
                                        <div className="card-contain ">
                                          <p className="sub-heading">
                                            No. of Reports
                                          </p>
                                          <p className="title">20</p>
                                        </div>
                                        <div className="card-contain">
                                          <p className="sub-heading">
                                            Last Updated On
                                          </p>
                                          <p className="title">20-01-2023</p>
                                        </div>
                                      </div>
                                      <div className="">
                                        <img src={ReportCardContain} alt="" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={
                                    sidebar
                                      ? "col-lg-4 col-md-6"
                                      : "col-lg-3 col-md-6"
                                  }
                                >
                                  <div
                                    className={"common-vehical-card-inner "}
                                    id="inner-report-card"
                                  >
                                    <div
                                      className="vehical-card-head"
                                      id="report-main"
                                    >
                                      <div className="heading">
                                        <div className="d-flex">
                                          <img src={RpoetSubCat} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              Report Name
                                            </p>
                                            <p className="title">
                                              Vehicle Running Report
                                            </p>
                                          </div>
                                        </div>
                                        <Link to="/ReportView">
                                          <img
                                            src={ReportEye}
                                            alt=""
                                            className="add-icon"
                                          />
                                        </Link>
                                      </div>
                                    </div>
                                    <div className="d-flex vehical-card-body customise_Report_Responsive justify-content-between">
                                      <div className="">
                                        <div className="card-contain ">
                                          <p className="sub-heading">
                                            No. of Reports
                                          </p>
                                          <p className="title">20</p>
                                        </div>
                                        <div className="card-contain">
                                          <p className="sub-heading">
                                            Last Updated On
                                          </p>
                                          <p className="title">20-01-2023</p>
                                        </div>
                                      </div>
                                      <div className="">
                                        <img src={ReportCardContain} alt="" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={
                                    sidebar
                                      ? "col-lg-4 col-md-6"
                                      : "col-lg-3 col-md-6"
                                  }
                                >
                                  <div
                                    className={"common-vehical-card-inner "}
                                    id="inner-report-card"
                                  >
                                    <div
                                      className="vehical-card-head"
                                      id="report-main"
                                    >
                                      <div className="heading">
                                        <div className="d-flex">
                                          <img src={RpoetSubCat} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              Report Name
                                            </p>
                                            <p className="title">
                                              Vehicle Running Report
                                            </p>
                                          </div>
                                        </div>
                                        <Link to="/ReportView">
                                          <img
                                            src={ReportEye}
                                            alt=""
                                            className="add-icon"
                                          />
                                        </Link>
                                      </div>
                                    </div>
                                    <div className="d-flex vehical-card-body customise_Report_Responsive justify-content-between">
                                      <div className="">
                                        <div className="card-contain ">
                                          <p className="sub-heading">
                                            No. of Reports
                                          </p>
                                          <p className="title">20</p>
                                        </div>
                                        <div className="card-contain">
                                          <p className="sub-heading">
                                            Last Updated On
                                          </p>
                                          <p className="title">20-01-2023</p>
                                        </div>
                                      </div>
                                      <div className="">
                                        <img src={ReportCardContain} alt="" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Tab.Container>
              </div>
              <Pagenation />
            </div>
          </div>
          {slider ? <ExistButton onClose={() => setSlider(false)} /> : null}
          {!slider && <NextButton onClick={handleClick} />}
          {!slider && <InfoComponent />}
        </div>
      </motion.div>

      {/* Delete Modal Start */}
      <Modal
        Modal
        show={deleteModal}
        onHide={() => setDeleteModal(false)}
        centered
        className="common-model"
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Fleet Maintenance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to Delete this Fleet Maintenance ?
        </Modal.Body>
        <Modal.Footer className="pop-up-modal-footer">
          <div class="btn-wrapper">
            <button className="cx-btn-1" onClick={() => setDeleteModal(false)}>
              Cancel
            </button>
            <button className="cx-btn-2" onClick={() => setDeleteModal(false)}>
              Yes
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* Delete Modal End */}
    </>
  );
};

export default Reports;
