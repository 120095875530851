import { React, useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import DDlogo from "../../assets/images/DDlogo.png";
import DatePicker from "react-datepicker";
import DatePlus from "../../assets/images/Group 33597.svg";
import redCross from "../../assets/images/redCross.svg";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { Space, TimePicker } from "antd";
import { useTranslation } from "react-i18next";
const DispatchSetting = () => {
  const { sidebar, setSidebar, Dark, setDark } = useContext(AppContext);
  const [startDate, setStartDate] = useState(new Date());
  const [value, onChange] = useState("12:00");
  const { t, i18n } = useTranslation();

  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };

  return (
    <>
      <motion.div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }}
      >
        <div id="cx-wrapper" className="Dispatch_Setting">
          <div className="main-master-wrapper form_input_main">
            <div className="innerInputsGen" id="dispatch-setting">
              <div className="row">
                <div className="col-md-7">
                  <div className="innerSelectBox dispatchHead">
                    <label>{t("Dispatch Auto-routing Type")}</label>
                    <select
                      required
                      className="form-select"
                      aria-label="Default select example"
                    >
                      <option value="">Instant</option>
                      <option value="1">Perodically</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                </div>
                <div className="weekCounter col-md-12  form_input_main">
                  <label className="dispatchHead">
                    {t("Select Dispatch Trip days")}
                  </label>
                  <div className="d-flex align-items-center flex-wrap innerToggle">
                    <div className="form-check form-switch">
                      <div className="d-flex innerFlexTog">
                        <label
                          className="form-check-label  toggleLabel"
                          for="Monday"
                        >
                          {t("Monday")}
                        </label>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Monday"
                        />
                      </div>
                    </div>
                    <div className="form-check form-switch">
                      <div className="d-flex innerFlexTog">
                        <label
                          className="form-check-label  toggleLabel"
                          for="Tuesday"
                        >
                          {t("Tuesday")}
                        </label>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Tuesday"
                        />
                      </div>
                    </div>
                    <div className="form-check form-switch">
                      <div className="d-flex innerFlexTog">
                        <label
                          className="form-check-label  toggleLabel"
                          for="Wednesday"
                        >
                          {t("Wednesday")}
                        </label>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Wednesday"
                        />
                      </div>
                    </div>
                    <div className="form-check form-switch">
                      <div className="d-flex innerFlexTog">
                        <label
                          className="form-check-label  toggleLabel"
                          for="Thursday"
                        >
                          {t("Thursday")}
                        </label>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Thursday"
                        />
                      </div>
                    </div>
                    <div className="form-check form-switch">
                      <div className="d-flex innerFlexTog">
                        <label
                          className="form-check-label  toggleLabel"
                          for="Friday"
                        >
                          {t("Friday")}
                        </label>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Friday"
                        />
                      </div>
                    </div>
                    <div className="form-check form-switch">
                      <div className="d-flex innerFlexTog">
                        <label
                          className="form-check-label  toggleLabel"
                          for="Saturday"
                        >
                          {t("Saturday")}
                        </label>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Saturday"
                        />
                      </div>
                    </div>
                    <div className="form-check form-switch">
                      <div className="d-flex innerFlexTog">
                        <label
                          className="form-check-label  toggleLabel"
                          for="Sunday"
                        >
                          {t("Sunday")}
                        </label>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Sunday"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="innerSelectBox weekCounter">
                    <label className="dispatchHead">
                      {t("Periodic Trip Times")} <span>&#42;</span>
                    </label>
                    <Space>
                      <TimePicker
                        className="form-control carretClass"
                        size="large"
                      />
                    </Space>
                  </div>
                </div>
                <div className="col-md-2 weekCounter">
                  <button className="AddOnBtn">{t("Add")}</button>
                </div>
              </div>
              <div className="selectedDate">
                <p className="innerDate">05:30:00</p>
                <Link to="#">
                  <img src={redCross} alt="" />
                </Link>
              </div>
              <div className="selectedDate">
                <p className="innerDate">05:30:00</p>
                <Link to="#">
                  <img src={redCross} alt="" />
                </Link>
              </div>
              <div className="selectedDate">
                <p className="innerDate">05:30:00</p>
                <Link to="#">
                  <img src={redCross} alt="" />
                </Link>
              </div>
              <div className="selectedDate">
                <p className="innerDate">05:30:00</p>
                <Link to="#">
                  <img src={redCross} alt="" />
                </Link>
              </div>
              <div className="selectedDate">
                <p className="innerDate">05:30:00</p>
                <Link to="#">
                  <img src={redCross} alt="" />
                </Link>
              </div>
              <div className="selectedDate">
                <p className="innerDate">05:30:00</p>
                <Link to="#">
                  <img src={redCross} alt="" />
                </Link>
              </div>
              <div className="selectedDate">
                <p className="innerDate">05:30:00</p>
                <Link to="#">
                  <img src={redCross} alt="" />
                </Link>
              </div>
            </div>
          </div>
          {/* Two Bottom Buttons */}
          <div className="d-flex justify-content-end align-items-center btn-wrapper">
            <button className="cx-btn-1">{t("Cancel")}</button>
            <button className="cx-btn-2">{t("Submit")}</button>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default DispatchSetting;
