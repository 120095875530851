import { React, useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import DDlogo from "../../assets/images/DDlogo.png";
import Form from "react-bootstrap/Form";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

const IntegrationSetting = () => {
  const { sidebar, setSidebar, Dark, setDark } = useContext(AppContext);
  const [validated, setValidated] = useState(false);
  const { t, i18n } = useTranslation();

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  return (
    <>
      <motion.div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }}
      >
        <div id="cx-wrapper" className="Integration_Setting">

          <Form noValidate validated={validated} onSubmit={handleSubmit} >
            <div className="main-master-wrapper form_input_main ">
              <div className="innerInputsGen">
                <div className="switchMain">
                  <div className="form-check form-switch">
                    <div className="d-flex toggleBetween">
                      <label className="form-check-label" for="smsGateway">
                        {t("SMS Gateway")}
                      </label>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="smsGateway"
                      />
                    </div>
                  </div>
                  {/* Reacharge Button */}
                  <div className="RechargeButtonMain">
                    <button className="recharegBtn">{t("Recharge")}</button>
                  </div>
                </div>
                <div className="d-flex justify-content-end paraColor">
                  <p className="rechargelabel">{t("Available Msg")} : 123456</p>
                </div>
                <div className="integrationGrid">
                  <div className="row integrationRow">
                    {/* Default Notification*/}
                    <div className="col-md-12 col12 form_input_main">
                      <div className="mainBorder">
                        <label className="NotiLabel">
                          {t("No Account Found Please Sign Up to Continue")}
                        </label>
                      </div>
                    </div>
                    {/* Disable NOtification */}
                    <div className="col-md-12 col12 form_input_main">
                      <div className="mainBorder">
                        <label className="NotiLabel">
                          {t("SMS Gateway Status id Disable")}
                        </label>
                      </div>
                    </div>
                    {/* Dont Have sufficient balance Notification  */}
                    <div className="col-md-12 col12 form_input_main">
                      <div className="mainBorder">
                        <label className="NotiLabel">
                          {t("You Don't Have Sufficient Balance")}
                        </label>
                      </div>
                    </div>
                    {/* Enable Form Input */}
                    <div className="col-md-6 form_input_main">
                      <div className="innerSelectBox weekCounter">
                        <Form.Label className="common-labels">
                          {t("SMS Gateway ID")}
                        </Form.Label>
                        <Form.Select
                          required
                          as="select"
                          type="select"
                          name="Speed_limit"
                        >
                          <option value="">Open this select menu</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="150">150</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          Please Select Minutes.
                        </Form.Control.Feedback>
                      </div>
                    </div>
                    <div className="col-md-6 form_input_main">
                      <div className="innerSelectBox weekCounter">
                        <Form.Label className="common-labels">
                          {t("SMS Gateway Username")}
                        </Form.Label>
                        <Form.Control required type="text" placeholder=" SMS Gateway Username" />
                        <Form.Control.Feedback type="invalid">
                          Please Enter SMS Gateway Username.
                        </Form.Control.Feedback>
                      </div>
                    </div>
                    <div className="col-md-6 form_input_main">
                      <div className="innerSelectBox weekCounter">
                        <Form.Label className="common-labels">
                          {t("SMS Gateways Password")}
                        </Form.Label>
                        <Form.Control required type="text" placeholder="SMS Gateways Password" />
                        <Form.Control.Feedback type="invalid">
                          Please Enter SMS Gateway Password.
                        </Form.Control.Feedback>
                      </div>
                    </div>
                    <div className="col-md-6 form_input_main">
                      <div className="innerSelectBox weekCounter">
                        <Form.Label className="common-labels">
                          {t("SMS Gateway Sender ID")}
                        </Form.Label>
                        <Form.Control required type="text" placeholder="SMS Gateways Sender Id"/>
                        <Form.Control.Feedback type="invalid">
                          Please Enter SMS Gateway ID.
                        </Form.Control.Feedback>
                      </div>
                    </div>
                    {/* Signup Button */}
                    <div className="col-md-12 text-end">
                      <button className="integSignUpBtn">{t("Sign Up")}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End Two buttons */}
            <div className="d-flex justify-content-end align-items-center btn-wrapper">
              <button className="cx-btn-1">{t("Cancel")}</button>
              <button className="cx-btn-2">{t("Submit")}</button>
            </div>
          </Form>
        </div>

      </motion.div>
    </>
  );
};

export default IntegrationSetting;
