import { React, useContext } from "react";
import { AppContext } from "../../context/AppContext";
import profile from "../../assets/images/Customer-profile.png";
import option from "../../assets/images/option-three-dot.svg";
import edit from "../../assets/images/ic-edit.svg";
import viewall from "../../assets/images/view-all.svg";
import car from "../../assets/images/ic-car.svg";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import MapComponent from "../../sharedComponent/MapComponent";
import { useTranslation } from "react-i18next";
// import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

const CustomerProfile = () => {
  const { sidebar, setSidebar, Dark, setDark } = useContext(AppContext);
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };

  const renderTooltipForEdit = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Editable Item
    </Tooltip>
  );
  const { t, i18n } = useTranslation();

  return (
    <>
      <motion.div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
        //     variants={aninations}
        // initial="initial"
        // animate="animate"
        // exit="exit"
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }}
      >
        <div id="cx-wrapper" className="Customer_Profile">
          <div className="main-dashboard-wrapper CustomerProfile">
            <div className="CustomerProfile-head">
              <div className="porile-img">
                <img src={profile} alt="porfile" />
              </div>
              <div className="customer-option">
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic">
                    <img src={option} alt="" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <Link to="/UpdateCustomerProfile">
                        {t("Update Profile")}
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link to="/LogoUpdate">{t("Update Logo")}</Link>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className="information-card">
              <div className="information-head">
                <div className="imformation-heading">
                  <p>{t("Contact Information")}</p>
                </div>
                <div className="edit-information">
                  <OverlayTrigger
                    placement="left"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltipForEdit}
                  >
                    <Link to="#">
                      <div className="inconsIn">
                        <img src={edit} alt="" />
                      </div>
                    </Link>
                  </OverlayTrigger>
                </div>
              </div>
              <div className="information-contain row">
                <div className="information-discriptiopn col-lg-3 col-md-4 col-sm-6 form_input_main">
                  <p className="discription-heading">{t("Name")}</p>
                  <p className="discription-contain">Mark woods</p>
                </div>
                <div className="information-discriptiopn col-lg-3 col-md-4 col-sm-6 form_input_main">
                  <p className="discription-heading">{t("Email")}</p>
                  <p className="discription-contain">markwoods@gmail.com</p>
                </div>
                <div className="information-discriptiopn col-lg-3 col-md-4 col-sm-6 form_input_main">
                  <p className="discription-heading">{t("Contact Number")}</p>
                  <p className="discription-contain">99999 99999</p>
                </div>
              </div>
            </div>
            <div className="information-card">
              <div className="information-head">
                <div className="imformation-heading">
                  <p>{t("General Information")}</p>
                </div>
                <div className="edit-information">
                  <OverlayTrigger
                    placement="left"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltipForEdit}
                  >
                    <Link to="#">
                      <div className="inconsIn">
                        <img src={edit} alt="" />
                      </div>
                    </Link>
                  </OverlayTrigger>
                </div>
              </div>
              <div className="information-contain row">
                <div className="information-discriptiopn col-lg-3 col-md-4 col-sm-6 form_input_main">
                  <p className="discription-heading">{t("Address")}</p>
                  <p className="discription-contain">
                    100, Vishrantwadi, Kalas, Pune Vishrantwadi, Kalas,
                    Pune-411005
                  </p>
                </div>
                <div className="information-discriptiopn col-lg-3 col-md-4 col-sm-6 form_input_main">
                  <p className="discription-heading">{t("City")}</p>
                  <p className="discription-contain">Pune</p>
                </div>
                <div className="information-discriptiopn col-lg-3 col-md-4 col-sm-6 form_input_main">
                  <p className="discription-heading">{t("Country")}</p>
                  <p className="discription-contain">India</p>
                </div>
                <div className="information-discriptiopn col-lg-3 col-md-4 col-sm-6 form_input_main">
                  <p className="discription-heading">{t("Currency")}</p>
                  <p className="discription-contain">Rupees</p>
                </div>
                <div className="information-discriptiopn col-lg-3 col-md-4 col-sm-6 form_input_main">
                  <p className="discription-heading">{t("Timezone")}</p>
                  <p className="discription-contain">-</p>
                </div>
                <div className="information-discriptiopn col-lg-3 col-md-4 col-sm-6 form_input_main">
                  <p className="discription-heading">{t("Website")}</p>
                  <p className="discription-contain">
                    vehicletracking.fleetmanagement.ea
                  </p>
                </div>
                <div className="information-discriptiopn col-lg-12 form_input_main">
                  <p className="discription-heading">{t("Status")}</p>
                  <p className="discription-contain">Active</p>
                </div>
              </div>
            </div>
            <div className="information-card">
              <div className="information-head">
                <div className="imformation-heading">
                  <p>{t("Subscribe Information")}</p>
                </div>
              </div>
              <div className="information-contain row">
                <div className="information-discriptiopn col-lg-3 col-md-4 col-sm-6 form_input_main">
                  <p className="discription-heading">{t("Customer Plan")}</p>
                  <p className="discription-contain">
                    Image Based Surveillance
                  </p>
                </div>
                <div className="information-discriptiopn col-lg-3 col-md-4 col-sm-6 form_input_main">
                  <p className="discription-heading">{t("Account Type")}</p>
                  <p className="discription-contain">Real</p>
                </div>
                <div className="information-discriptiopn col-lg-3 col-md-4 col-sm-6 form_input_main">
                  <p className="discription-heading">{t("Expiry Date")}</p>
                  <p className="discription-contain">04-05-2042</p>
                </div>
              </div>
            </div>
            <div className="information-card">
              <div className="information-head">
                <div className="imformation-heading">
                  <p>{t("Server Information")}</p>
                </div>
              </div>
              <div className="information-contain row">
                <div className="information-discriptiopn col-lg-3 col-md-4 col-sm-6 form_input_main">
                  <p className="discription-heading">{t("Port")}</p>
                  <p className="discription-contain">6001</p>
                </div>
                <div className="information-discriptiopn col-lg-3 col-md-4 col-sm-6 form_input_main">
                  <p className="discription-heading">{t("IP")}</p>
                  <p className="discription-contain">
                    service.vehicletracking.qa
                  </p>
                </div>
              </div>
            </div>
            <div className="information-card">
              <div className="information-head">
                <div className="imformation-heading">
                  <p>{t("API Information")}</p>
                </div>
              </div>
              <div className="information-contain row">
                <div className="information-discriptiopn col-lg-3 col-md-4 col-sm-6 form_input_main">
                  <p className="discription-heading">{t("Customer ID")}</p>
                  <p className="discription-contain">
                    Image Based Surveillance
                  </p>
                </div>
                <div className="information-discriptiopn col-lg-3 col-md-4 col-sm-6 form_input_main">
                  <p className="discription-heading">{t("API Key")}</p>
                  <p className="discription-contain">abcdefghi123456789</p>
                </div>
              </div>
            </div>
          </div>

          <div className="transport-main row m-0 p-0">
            <div className="right-section col-lg-6">
              <div className="transport-head">
                <p>{t("Transport manager")}</p>
                <Link to="/TransportManager">{t("View All")}</Link>
              </div>
              <div className="common-table">
                <table>
                  <thead>
                    <tr>
                      <th id="twoTables">{t("Sr.no")}</th>
                      <th>{t("Transport Manager")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>Transport manager</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>Transport manager</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>Transport manager</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>Transport manager</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="left-section col-lg-6">
              <div className="transport-head">
                <p>{t("Vehicles")}</p>
                <Link to="/Vehicle">{t("View All")}</Link>
              </div>
              <div className="common-table">
                <table>
                  <thead>
                    <tr>
                      <th id="twoTables">{t("Sr.no")}</th>
                      <th>{t("Vehicle Name")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>Vehicle Name</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>Vehicle Name</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>Vehicle Name</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>Vehicle Name</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="road-map CustomerProfile">
            <MapComponent />
          </div>
          <div className="ApplicationStatistics">
            <div className="ApplicationStatistics-head">
              <p>{t("Application Statistics")}</p>
            </div>
            <div className="common-table">
              <table>
                <thead>
                  <tr>
                    <th id="FirstMiunWIdth">{t("Sr.no")}</th>
                    <th>{t("Application Statistics")}</th>
                    <th>{t("Value")}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>
                      {" "}
                      <img src={car} alt="" className="tableImg" /> Transport
                      Manager (Android)
                    </td>
                    <td>00</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>
                      <img src={car} alt="" className="tableImg" /> Transport
                      Manager (IOS)
                    </td>
                    <td>00</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>
                      <img src={car} alt="" className="tableImg" /> Driver
                      Console (Android)
                    </td>
                    <td>00</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>
                      <img src={car} alt="" className="tableImg" /> Delivery App
                      (Android)
                    </td>
                    <td>00</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
};
export default CustomerProfile;
