import React, { useContext, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { Col, Nav, Tab } from "react-bootstrap";
import Import from "../../../assets/images/ic-Import.svg";
import Export from "../../../assets/images/ic-Export.svg";
import ReactSelect from "../../../sharedComponent/ReactSelect";
import { useTranslation } from "react-i18next";

const AcessRole = () => {
  const { sidebar, setSidebar, Dark, setDark } = useContext(AppContext);

  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  const { t, i18n } = useTranslation();

  return (
    <motion.div
      className={sidebar ? "taskMain " : "cx-active taskMain"}
      id="cx-main"
      variants={aninations}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.1 }}
    >
      <div id="cx-wrapper">
        <div id="Access_right_new">
          <div className="all-vehical-head row vehicle-top-inputs">
            <div className="input-section-wrapper">
              <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                  <ReactSelect />
                </div>
              </div>
            </div>
            <div className="right-export-btn-section-wrapper">
              <Link to="/UserRole" className="d-flex justify-content-end mb-3">
                <button className="cx-btn-3 tbBtn">{t("User Roles")}</button>
              </Link>
              <div className="c-pointer me-2">
                <img src={Export} alt="" />
              </div>
              <div className="c-pointer">
                <img src={Import} alt="" />
              </div>
            </div>
          </div>
          <div className="header_tabel_top">
            <div className="row">
              <div className="col-lg-10 col-md-10 col-sm-10 col-10">
                <p>{t("Features")}</p>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-2 col-2">
                <p>{t("Action")}</p>
              </div>
            </div>
          </div>
          <div className="common_access_card">
            <div className="row">
              <div className="col-lg-10 col-md-10 col-sm-10 col-9">
                <div className="left">
                  <div className="title">{t("Customer Admin")}</div>
                  <div className="contain">
                    <ul>
                      <li>{t("View Admin")}</li>
                      <li>{t("Manage Admin")}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-2 col-3">
                <div className="right">
                  <ul>
                    <li>
                      <div
                        className="form-check form-switch"
                        id="custom_switch"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Tuesday"
                        />
                      </div>
                    </li>
                    <li>
                      <div
                        className="form-check form-switch"
                        id="custom_switch"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Tuesday"
                          defaultChecked
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="common_access_card">
            <div className="row">
              <div className="col-lg-10 col-md-10 col-sm-10 col-9">
                <div className="left">
                  <div className="title"> {t("Transport Manager")}</div>
                  <div className="contain">
                    <ul>
                      <li>{t("View Transport Manager")}</li>
                      <li>{t("Manage Transport Manager")}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-2 col-3">
                <div className="right">
                  <ul>
                    <li>
                      <div
                        className="form-check form-switch"
                        id="custom_switch"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Tuesday"
                        />
                      </div>
                    </li>
                    <li>
                      <div
                        className="form-check form-switch"
                        id="custom_switch"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Tuesday"
                          defaultChecked
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="common_access_card">
            <div className="row">
              <div className="col-lg-10 col-md-10 col-sm-10 col-9">
                <div className="left">
                  <div className="title">{t("Drivers")}</div>
                  <div className="contain">
                    <ul>
                      <li>{t("View Driver")}</li>
                      <li>{t("Manage Driver")}</li>
                      <li>{t("Delete Driver")}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-2 col-3">
                <div className="right">
                  <ul>
                    <li>
                      <div
                        className="form-check form-switch"
                        id="custom_switch"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Tuesday"
                        />
                      </div>
                    </li>
                    <li>
                      <div
                        className="form-check form-switch"
                        id="custom_switch"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Tuesday"
                        />
                      </div>
                    </li>
                    <li>
                      <div
                        className="form-check form-switch"
                        id="custom_switch"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Tuesday"
                          defaultChecked
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="common_access_card">
            <div className="row">
              <div className="col-lg-10 col-md-10 col-sm-10 col-9">
                <div className="left">
                  <div className="title">{t("Vehicle Assistant")}</div>
                  <div className="contain">
                    <ul>
                      <li>{t("View Vehicle Assistant")}</li>
                      <li>{t("Manage Vehicle Assistant")}</li>
                      <li>{t("Delete Vehicle Assistant")}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-2 col-3">
                <div className="right">
                  <ul>
                    <li>
                      <div
                        className="form-check form-switch"
                        id="custom_switch"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Tuesday"
                        />
                      </div>
                    </li>
                    <li>
                      <div
                        className="form-check form-switch"
                        id="custom_switch"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Tuesday"
                        />
                      </div>
                    </li>
                    <li>
                      <div
                        className="form-check form-switch"
                        id="custom_switch"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Tuesday"
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="common_access_card">
            <div className="row">
              <div className="col-lg-10 col-md-10 col-sm-10 col-9">
                <div className="left">
                  <div className="title">{t("Dashboard")}</div>
                  <div className="contain">
                    <ul>
                      <li>{t("Dashboard Vehicles")}</li>
                      <li>{t("Dashboard Map")}</li>
                      <li>{t("Dashboard Pending's")}</li>
                      <li>{t("Dashboard Statistics")}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-2 col-3">
                <div className="right">
                  <ul>
                    <li>
                      <div
                        className="form-check form-switch"
                        id="custom_switch"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Tuesday"
                          defaultChecked
                        />
                      </div>
                    </li>
                    <li>
                      <div
                        className="form-check form-switch"
                        id="custom_switch"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Tuesday"
                        />
                      </div>
                    </li>
                    <li>
                      <div
                        className="form-check form-switch"
                        id="custom_switch"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Tuesday"
                          defaultChecked
                        />
                      </div>
                    </li>
                    <li>
                      <div
                        className="form-check form-switch"
                        id="custom_switch"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Tuesday"
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          
          <div className="common_access_card">
            <div className="row">
              <div className="col-lg-10 col-md-10 col-sm-10 col-9">
                <div className="left">
                  <div className="title">{t("Master Settings")}</div>
                  <div className="contain sub-contain">
                    <ul>
                      <li>
                        <div className="head">{t("Customer Profile & Logo")}</div>
                        <ul>
                          <li>{t("View Customer Profile")}</li>
                          <li>{t("Manage Customer Profile")}</li>
                          <li>{t("Manage Logo")}</li>
                        </ul>
                      </li>
                      <li>
                        <div className="head">{t("Academic Settings")}</div>
                        <ul>
                          <li>{t("Manage Academic Settings")}</li>
                          
                        </ul>
                      </li>
                      <li className="single_Contain">
                        <div className="head">{t("Shift")}</div>
                        <ul>
                          <li>{t("Manage Shift")}</li>
                        </ul>
                      </li>
                      <li>
                        <div className="head">{t("Vehicle Types & Vehicles")}</div>
                        <ul>
                          <li>{t("View Vehicle type")}</li>
                          <li>{t("Manage Vehicle type")}</li>
                          <li>{t("View Vehicle")}</li>
                          <li>{t("Manage Vehicle")}</li>
                        </ul>
                      </li>
                      <li>
                        <div className="head">{t("Vehicle Assignment")}</div>
                        <ul>
                          <li>{t("View Vehicle Assignment")}</li>
                          <li>{t("Manage Vehicle Assignment")}</li>
                        </ul>
                      </li>
                      <li className="single_Contain">
                        <div className="head">{t("General Settings")}</div>
                        <ul>
                          <li>{t("Manage General Settings")}</li>
                        </ul>
                      </li>
                      <li className="single_Contain">
                        <div className="head">{t("Notification Settings")}</div>
                        <ul>
                          <li>{t("Manage Notification Settings")}</li>
                        </ul>
                      </li>
                      <li className="single_Contain">
                        <div className="head">{t("Configuration Checker")}</div>
                        <ul>
                          <li>{t("Manage Configuration Settings")}</li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-2 col-3">
                <div className="right">
                  <ul>
                    <li>
                      <div
                        className="form-check form-switch"
                        id="custom_switch"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Tuesday"
                          defaultChecked
                        />
                      </div>
                    </li>
                    <li>
                      <div
                        className="form-check form-switch"
                        id="custom_switch"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Tuesday"
                        />
                      </div>
                    </li>
                    <li>
                      <div
                        className="form-check form-switch"
                        id="custom_switch"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Tuesday"
                        />
                      </div>
                    </li>
                    <li>
                      <div
                        className="form-check form-switch"
                        id="custom_switch"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Tuesday"
                          defaultChecked
                        />
                      </div>
                    </li>
                    <li>
                      <div
                        className="form-check form-switch"
                        id="custom_switch"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Tuesday"
                        />
                      </div>
                    </li>
                    <li>
                      <div
                        className="form-check form-switch"
                        id="custom_switch"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Tuesday"
                        />
                      </div>
                    </li>
                    <li>
                      <div
                        className="form-check form-switch"
                        id="custom_switch"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Tuesday"
                          defaultChecked
                        />
                      </div>
                    </li>
                    <li>
                      <div
                        className="form-check form-switch"
                        id="custom_switch"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Tuesday"
                          defaultChecked
                        />
                      </div>
                    </li>
                    <li>
                      <div
                        className="form-check form-switch"
                        id="custom_switch"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Tuesday"
                        />
                      </div>
                    </li>
                    <li>
                      <div
                        className="form-check form-switch"
                        id="custom_switch"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Tuesday"
                          defaultChecked
                        />
                      </div>
                    </li>
                    <li>
                      <div
                        className="form-check form-switch"
                        id="custom_switch"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Tuesday"
                        />
                      </div>
                    </li>
                    <li>
                      <div
                        className="form-check form-switch"
                        id="custom_switch"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Tuesday"
                        />
                      </div>
                    </li>
                    <li>
                      <div
                        className="form-check form-switch"
                        id="custom_switch"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Tuesday"
                        />
                      </div>
                    </li>
                    <li>
                      <div
                        className="form-check form-switch"
                        id="custom_switch"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Tuesday"
                        />
                      </div>
                    </li>
                    <li>
                      <div
                        className="form-check form-switch"
                        id="custom_switch"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Tuesday"
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="common_access_card">
            <div className="row">
              <div className="col-lg-10 col-md-10 col-sm-10 col-9">
                <div className="left">
                  <div className="title">{t("Scheduled Pickup")}</div>
                  <div className="contain sub-contain">
                    <ul>
                      <li>
                        <div className="head">{t("Routes")}</div>
                        <ul>
                          <li>{t("View Route")}</li>
                          <li>{t("Manage Route")}</li>
                          <li>{t("Test Route")}</li>
                          <li>{t("Approve Route")}</li>
                        </ul>
                      </li>
                      <li>
                        <div className="head">{t("Pickup Points")}</div>
                        <ul>
                          <li>{t("View Pickup point")}</li>
                          <li>{t("Manage Pickup point")}</li>
                        </ul>
                      </li>
                      <li>
                        <div className="head">{t("Scheduled Trips")}</div>
                        <ul>
                          <li>{t("View Trips")}</li>
                          <li>{t("Manage Trips")}</li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-2 col-3">
                <div className="right">
                  <ul>
                    <li>
                      <div
                        className="form-check form-switch"
                        id="custom_switch"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Tuesday"
                          defaultChecked
                        />
                      </div>
                    </li>
                    <li>
                      <div
                        className="form-check form-switch"
                        id="custom_switch"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Tuesday"
                        />
                      </div>
                    </li>
                    <li>
                      <div
                        className="form-check form-switch"
                        id="custom_switch"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Tuesday"
                        />
                      </div>
                    </li>
                    <li>
                      <div
                        className="form-check form-switch"
                        id="custom_switch"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Tuesday"
                          defaultChecked
                        />
                      </div>
                    </li>
                    <li>
                      <div
                        className="form-check form-switch"
                        id="custom_switch"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Tuesday"
                        />
                      </div>
                    </li>
                    <li>
                      <div
                        className="form-check form-switch"
                        id="custom_switch"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Tuesday"
                        />
                      </div>
                    </li>
                    <li>
                      <div
                        className="form-check form-switch"
                        id="custom_switch"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Tuesday"
                          defaultChecked
                        />
                      </div>
                    </li>
                    <li>
                      <div
                        className="form-check form-switch"
                        id="custom_switch"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Tuesday"
                          defaultChecked
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="common_access_card">
            <div className="row">
              <div className="col-lg-10 col-md-10 col-sm-10 col-9">
                <div className="left">
                  <div className="title">{t("Instant Trips")}</div>
                  <div className="contain">
                    <ul>
                      <li>{t("View Instant Trips")}</li>
                      <li>{t("Manage Instant Trips")}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-2 col-3">
                <div className="right">
                  <ul>
                    <li>
                      <div
                        className="form-check form-switch"
                        id="custom_switch"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Tuesday"
                        />
                      </div>
                    </li>
                    <li>
                      <div
                        className="form-check form-switch"
                        id="custom_switch"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Tuesday"
                          defaultChecked
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="common_access_card">
            <div className="row">
              <div className="col-lg-10 col-md-10 col-sm-10 col-9">
                <div className="left single_Contain">
                  <div className="title">{t("Vehicle Expenses")}</div>
                  <div className="contain">
                    <ul>
                      <li>{t("Manage Vehicle Expense")}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-2 col-3">
                <div className="right">
                  <ul>
                    <li>
                      <div
                        className="form-check form-switch"
                        id="custom_switch"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Tuesday"
                          defaultChecked
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="common_access_card">
            <div className="row">
              <div className="col-lg-10 col-md-10 col-sm-10 col-9">
                <div className="left">
                  <div className="title">{t("Payment")}</div>
                  <div className="contain">
                    <ul>
                      <li>{t("Online Payment")}</li>
                      <li>{t("Manage Payment")}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-2 col-3">
                <div className="right">
                  <ul>
                    <li>
                      <div
                        className="form-check form-switch"
                        id="custom_switch"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Tuesday"
                        />
                      </div>
                    </li>
                    <li>
                      <div
                        className="form-check form-switch"
                        id="custom_switch"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Tuesday"
                          defaultChecked
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="common_access_card">
            <div className="row">
              <div className="col-lg-10 col-md-10 col-sm-10 col-9">
                <div className="left single_Contain">
                  <div className="title">{t("Announcement")}</div>
                  <div className="contain">
                    <ul>
                      <li>{t("Manage Announcement Vehicle")}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-2 col-3">
                <div className="right">
                  <ul>
                    <li>
                      <div
                        className="form-check form-switch"
                        id="custom_switch"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Tuesday"
                          defaultChecked
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="common_access_card">
            <div className="row">
              <div className="col-lg-10 col-md-10 col-sm-10 col-9">
                <div className="left">
                  <div className="title">{t("Notification")}</div>
                  <div className="contain">
                    <ul>
                      <li>{t("Manage Notification Admin")}</li>
                      <li>{t("Manage Notification T. Manager")}</li>
                      <li>{t("Manage Notification Driver")}</li>
                      <li>{t("Manage Notification Helper")}</li>
                      <li>{t("Manage Notification Accountant")}</li>
                      <li>{t("Manage Notification Tag Vendor")}</li>
                      <li>{t("Manage Notification Surveyor")}</li>
                      <li>{t("Manage Notification Route Planner")}</li>
                      <li>{t("Manage Notification Support")}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-2 col-3">
                <div className="right">
                  <ul>
                    <li>
                      <div
                        className="form-check form-switch"
                        id="custom_switch"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Tuesday"
                          defaultChecked
                        />
                      </div>
                    </li>
                    <li>
                      <div
                        className="form-check form-switch"
                        id="custom_switch"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Tuesday"
                        />
                      </div>
                    </li>
                    <li>
                      <div
                        className="form-check form-switch"
                        id="custom_switch"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Tuesday"
                        />
                      </div>
                    </li>
                    <li>
                      <div
                        className="form-check form-switch"
                        id="custom_switch"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Tuesday"
                        />
                      </div>
                    </li>
                    <li>
                      <div
                        className="form-check form-switch"
                        id="custom_switch"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Tuesday"
                        />
                      </div>
                    </li>
                    <li>
                      <div
                        className="form-check form-switch"
                        id="custom_switch"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Tuesday"
                        />
                      </div>
                    </li>
                    <li>
                      <div
                        className="form-check form-switch"
                        id="custom_switch"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Tuesday"
                        />
                      </div>
                    </li>
                    <li>
                      <div
                        className="form-check form-switch"
                        id="custom_switch"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Tuesday"
                        />
                      </div>
                    </li>
                    <li>
                      <div
                        className="form-check form-switch"
                        id="custom_switch"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Tuesday"
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="common_access_card">
            <div className="row">
              <div className="col-lg-10 col-md-10 col-sm-10 col-9">
                <div className="left single_Contain">
                  <div className="title">{t("Historical Data")}</div>
                  <div className="contain">
                    <ul>
                      <li>{t("Historical Data")}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-2 col-3">
                <div className="right">
                  <ul>
                    <li>
                      <div
                        className="form-check form-switch"
                        id="custom_switch"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Tuesday"
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="common_access_card">
            <div className="row">
              <div className="col-lg-10 col-md-10 col-sm-10 col-9">
                <div className="left">
                  <div className="title">{t("Reports")}</div>
                  <div className="contain">
                    <ul>
                      <li>{t("Transportation Report")}</li>
                      <li>{t("Driver Report")}</li>
                      <li>{t("Vehicle Report")}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-2 col-3">
                <div className="right">
                  <ul>
                    <li>
                      <div
                        className="form-check form-switch"
                        id="custom_switch"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Tuesday"
                        />
                      </div>
                    </li>
                    <li>
                      <div
                        className="form-check form-switch"
                        id="custom_switch"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Tuesday"
                        />
                      </div>
                    </li>
                    <li>
                      <div
                        className="form-check form-switch"
                        id="custom_switch"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Tuesday"
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="common_access_card noborder">
            <div className="row">
              <div className="col-lg-10 col-md-10 col-sm-10 col-9">
                <div className="left single_Contain">
                  <div className="title">{t("Messages")}</div>
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-2 col-3">
                <div className="right">
                  <ul>
                    <li>
                      <div
                        className="form-check form-switch"
                        id="custom_switch"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Tuesday"
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="common_access_card noborder">
            <div className="row">
              <div className="col-lg-10 col-md-10 col-sm-10 col-9">
                <div className="left single_Contain">
                  <div className="title">{t("Manage Tag Prints")}</div>
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-2 col-3">
                <div className="right">
                  <ul>
                    <li>
                      <div
                        className="form-check form-switch"
                        id="custom_switch"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Tuesday"
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default AcessRole;
