import { React, useContext, useState, useEffect } from "react";
import { AppContext } from "../../../context/AppContext";
import Form from "react-bootstrap/Form";
import Save from "../../../assets/images/save.svg";
import Inspect from "../../../assets/images/inspect.svg";
import Right from "../../../assets/images/right.svg";
import Invoice from "../../../assets/images/invoice.svg";
import DatePicker from "react-datepicker";
import Calendar from "../../../assets/images/calendar.svg";
import uploadIcon from "../../../assets/images/uploadIcon.svg";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PreviousButton from "../../../sharedComponent/PreviousButton";
import NextButton from "../../../sharedComponent/Nextbutton";
import ExistButton from "../../../sharedComponent/ExistButton";
// Types of files

const AddVehicleMaintenance = ({ onClick }) => {
  const { sidebar, setSidebar, Dark, setDark, slider, setSlider } =
    useContext(AppContext);
  const [validated, setValidated] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const { t, i18n } = useTranslation();

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
  };
  const navigate = useNavigate();
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    navigate("/FuelExpenses");
  };
  const handleClickPrevious = () => {
    navigate("/VehicleMaintenance");
  };
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  return (
    <>
      <motion.div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }}
      >
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <div id="cx-wrapper" className="AddFleet_Maintainence">
            <div className="main-master-wrapper mb-4">
              <div className="vehicleHead">
                <p>{t("Add New Details")}</p>
              </div>
              <div className="innerInputsGen mainVehAccident vehivleBody">
                <div className="">
                  <div className="addVehicleLeftSec">
                    <div className="row">
                      <div className="col-md-6 form_input_main">
                        <div className="innerSelectBox weekCounter">
                          <Form.Label className="common-labels">
                            {t("Vehicle Name")} <span>&#42;</span>
                          </Form.Label>
                          <Form.Control
                            required
                            type="text"
                            placeholder="Enter Your vehicle Name"
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter vehicle code.
                          </Form.Control.Feedback>
                        </div>
                      </div>
                      <div className="col-md-6 form_input_main">
                        <div className="innerSelectBox weekCounter">
                          <Form.Label className="common-labels">
                            {t("Service Task")} <span>&#42;</span>
                          </Form.Label>
                          <Form.Control
                            required
                            type="text"
                            placeholder="Enter Your Service Task"
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Service Task.
                          </Form.Control.Feedback>
                        </div>
                      </div>
                      <div className="col-md-6 form_input_main">
                        <div className="innerSelectBox weekCounter">
                          <div className="d-flex justify-content-between  align-items-center flex-wrap">
                            <Form.Label className="common-labels nextLabel ">
                              {t("Service Date")} <span>&#42;</span>
                            </Form.Label>
                          </div>
                          <DatePicker
                            selected={startDate}
                            placeholderText="Select Date"
                            onChange={(date) => setStartDate(date)}
                            className="form-control"
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Select Date.
                          </Form.Control.Feedback>
                          <img
                            src={Calendar}
                            className="addVehCalender addonCalneder"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="col-md-6 form_input_main">
                        <div className="innerSelectBox weekCounter">
                          <Form.Label className="common-labels">
                            {t("Kilometers Driven")} <span>&#42;</span>
                          </Form.Label>
                          <Form.Control
                            required
                            type="text"
                            placeholder="Enter Your Kilometers Driven"
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Kilometers Driven.
                          </Form.Control.Feedback>
                        </div>
                      </div>
                      <div className="col-md-6 form_input_main">
                        <div className="innerSelectBox weekCounter">
                          <Form.Label className="common-labels nextLabel">
                            {t("Next Service Date")}
                            <span>&#42;</span>
                          </Form.Label>
                          <DatePicker
                            // selected={startDate}
                            placeholderText="Select Date"
                            onChange={(date) => setStartDate(date)}
                            className="form-control"
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Select Date.
                          </Form.Control.Feedback>
                          <img
                            src={Calendar}
                            className="addVehCalender"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="col-md-6 form_input_main">
                        <div className="innerSelectBox weekCounter">
                          <div className="d-flex justify-content-between  align-items-center flex-wrap">
                            <Form.Label className="common-labels nextLabel ">
                              {t("Next Service Kilometers")} <span>&#42;</span>
                            </Form.Label>
                          </div>
                          <Form.Control
                            required
                            type="text"
                            placeholder="Enter Next Service Kilometers"
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Next Service Kilometers.
                          </Form.Control.Feedback>
                        </div>
                      </div>

                      <div className="col-md-12 form_input_main">
                        <div className="innerSelectBox weekCounter">
                          <Form.Label className="common-labels">
                            {t("Next Service Duration")} <span>&#42;</span>
                          </Form.Label>
                          <Form.Control
                            required
                            type="text"
                            placeholder="Enter Next Service Duration"
                          />
                          <Form.Control.Feedback type="invalid">
                            Enter Next Service Duration
                          </Form.Control.Feedback>
                        </div>
                      </div>
                      {/* <div className="col-md-12 form_input_main">
                        <div className="innerSelectBox weekCounter">
                          <div className="d-flex justify-content-between  align-items-center flex-wrap">
                            <Form.Label className="common-labels">
                              {t("Send Service Notification")} ?
                            </Form.Label>
                            <div id="customRadios">
                              <div class="form-check greenFlex me-2">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="yes"
                                  id="Yes"
                                />
                                <label
                                  class="form-check-label custLabel"
                                  for="Yes"
                                >
                                  {t("Yes")}
                                </label>
                              </div>
                              <div class="form-check  greenFlex">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="yes"
                                  id="No"
                                />
                                <label
                                  class="form-check-label custLabel"
                                  for="No"
                                >
                                  {t("No")}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end align-items-center belowBtns">
                <button className="cx-btn-1">{t("Cancel")}</button>
                <button className="cx-btn-2">{t("Submit")}</button>
              </div>
            </div>

            <div className="main-master-wrapper">
              <div className="vehicleHead">
                <p>{t("Service Settings")}</p>
              </div>
              <div className="innerInputsGen mainVehAccident vehivleBody ">
                <div className="">
                  <div className="addVehicleLeftSec">
                    <div className="row">
                      <div className="col-md-6 form_input_main">
                        <div className="innerSelectBox weekCounter">
                          <Form.Label className="common-labels">
                            {t("Service Task")} <span>&#42;</span>
                          </Form.Label>
                          <Form.Control
                            required
                            type="text"
                            placeholder="Enter Your Service Task"
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Service Task.
                          </Form.Control.Feedback>
                        </div>
                      </div>
                      <div className="col-md-6 form_input_main">
                        <div className="innerSelectBox weekCounter">
                          <div className="d-flex justify-content-between  align-items-center flex-wrap">
                            <Form.Label className="common-labels nextLabel ">
                              {t("Service Date")} <span>&#42;</span>
                            </Form.Label>
                          </div>
                          <DatePicker
                            selected={startDate}
                            placeholderText="Select Date"
                            onChange={(date) => setStartDate(date)}
                            className="form-control"
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Select Date.
                          </Form.Control.Feedback>
                          <img
                            src={Calendar}
                            className="addVehCalender addonCalneder"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="col-md-6 form_input_main">
                        <div className="innerSelectBox weekCounter">
                          <div className="d-flex justify-content-between  align-items-center flex-wrap">
                            <Form.Label className="common-labels">
                              {t("Service Mode")} ? <span>&#42;</span>
                            </Form.Label>
                            <div id="customRadios">
                              <div class="form-check greenFlex me-2">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="yes"
                                  id="Yes"
                                />
                                <label
                                  class="form-check-label custLabel"
                                  for="Yes"
                                >
                                  {t("Automatic")}
                                </label>
                              </div>
                              <div class="form-check  greenFlex">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="yes"
                                  id="No"
                                  defaultChecked
                                />
                                <label
                                  class="form-check-label custLabel"
                                  for="No"
                                >
                                  {t("Manual")}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 form_input_main">
                        <div className="innerSelectBox weekCounter">
                          <Form.Label className="common-labels">
                            {t("Kilometers Driven")} <span>&#42;</span>
                          </Form.Label>
                          <Form.Control
                            required
                            type="text"
                            placeholder="Enter Your Kilometers Driven"
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Kilometers Driven.
                          </Form.Control.Feedback>
                        </div>
                      </div>
                      <div className="col-lg-6"></div>
                      <div className="col-md-6 form_input_main">
                        <div className="innerSelectBox weekCounter">
                          <div className="d-flex justify-content-between  align-items-center flex-wrap">
                            <Form.Label className="common-labels nextLabel ">
                              {t("Service Duration")} <span>&#42;</span>
                            </Form.Label>
                          </div>
                          <Form.Control
                            required
                            type="text"
                            placeholder="Enter Service Duration"
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Service Duration.
                          </Form.Control.Feedback>
                        </div>
                      </div>

                      <div className="col-lg-6"></div>
                      <div className="col-md-6 form_input_main">
                        <div className="innerSelectBox weekCounter">
                          <div className="d-flex justify-content-between  align-items-center flex-wrap">
                            <Form.Label className="common-labels">
                              {t("Send Service  Notification")} ?
                            </Form.Label>
                            <div id="customRadios">
                              <div class="form-check greenFlex me-2">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="yes"
                                  id="Yes"
                                />
                                <label
                                  class="form-check-label custLabel"
                                  for="Yes"
                                >
                                  {t("Yes")}
                                </label>
                              </div>
                              <div class="form-check  greenFlex">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="yes"
                                  id="No"
                                />
                                <label
                                  class="form-check-label custLabel"
                                  for="No"
                                >
                                  {t("No")}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end align-items-center belowBtns">
                <button className="cx-btn-1">{t("Cancel")}</button>
                <button className="cx-btn-2">{t("Save")}</button>
              </div>
            </div>
          </div>
        </Form>
        {slider ? <ExistButton onClose={() => setSlider(false)} /> : null}
        {!slider && <PreviousButton onBack={handleClickPrevious} />}
        {!slider && <NextButton onClick={handleClick} />}
      </motion.div>
    </>
  );
};

export default AddVehicleMaintenance;
