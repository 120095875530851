import React, { Suspense,  useState } from "react";

import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import AppState from "./context/AppState";
import "./sharedComponent/MultiLang/i18n.js";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Suspense fallback={<div>Loading...</div>}>
    <AppState>
      <App />
    </AppState>
    </Suspense>
  </BrowserRouter>
);


