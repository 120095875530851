import { React, useContext, useState } from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
const ChangePasswordProfile = () => {
  const { sidebar, setSidebar, Dark, setDark } = useContext(AppContext);
  const { t, i18n } = useTranslation();

  const [validated, setValidated] = useState(false);
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  return (
    <>
      <motion.div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }}
      >
        <div id="cx-wrapper">
          <div className="main-master-wrapper" id="EditProfile_Reponsive">
            <div className="Heading">
              <p>{t("Change Password")}</p>
            </div>
            <div className="form-wrapper">
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group
                      className="form_input_main"
                      controlId="formBasicEmail"
                    >
                      <Form.Label>{t("Users Password")}</Form.Label>
                      <Form.Control
                        required
                        type="password"
                        placeholder="Enter Your Password"
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Current Password.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group
                      className="form_input_main"
                      controlId="formBasicEmail"
                    >
                      <Form.Label>{t("New Password")}</Form.Label>
                      <Form.Control
                        required
                        type="password"
                        placeholder="Enter New Password"
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter New Password.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group
                      className="form_input_main"
                      controlId="formBasicEmail"
                    >
                      <Form.Label>{t("Confirm Password")}</Form.Label>
                      <Form.Control
                        required
                        type="password"
                        placeholder="Re-enter Your New Password"
                      />
                      <Form.Control.Feedback type="invalid">
                        Password Does Not Match.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
                <div className="btns-main btn-wrapper">
                  <Link to="/MyProfile">
                    <button type="" className="cx-btn-1">
                      {t("Cancel")}
                    </button>
                  </Link>
                  <Link to="/MyProfile">
                    <button type="submit" className="cx-btn-2">
                      {t("Update")}
                    </button>
                  </Link>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default ChangePasswordProfile;
