import { Dropdown, Modal, Tab, Tabs } from "react-bootstrap";
import React, { useContext, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import option from "../../../assets/images/option-three-dot.svg";
import Import from "../../../assets/images/ic-Import.svg";
import Export from "../../../assets/images/ic-Export.svg";
import music from "../../../assets/images/music.svg";
import audio1 from "../../../assets/Audio/audio1.mp3";
import { Link } from "react-router-dom";
import Calendar from "../../../assets/images/calendar.svg";
import DatePicker from "react-datepicker";
import { motion } from "framer-motion";
const Announcement = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [show, setShow] = useState(false);

  const { sidebar, setSidebar, Dark, setDark } = useContext(AppContext);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };

  return (
    <motion.div
      className={sidebar ? "taskMain " : "cx-active taskMain"}
      id="cx-main"
      variants={aninations}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.1 }}
    >
      <div id="cx-wrapper">
        <div className="d-flex justify-content-end mb-3">
          <Link to="/Addaudio">
            <button className="cx-btn-3">+ Add Audio</button>
          </Link>
        </div>
        <div className="main-master-wrapper mb-0 inner-tabs-section">
          <div className="all-vehicle-main">
            <div className="all-vehical-head">
              <div className="row">
                <div id="Annousement_Responsive">
                  <div className="all-vehicle-main">
                    <div className="all-vehical-head row vehicle-top-inputs">
                      <div className="input-section-wrapper">
                        <div className="row">
                          <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Audio Title"
                            />
                          </div>
                          <div className="col-lg-3 col-md-6 col-sm-12 mb-3 custom-padding-left2">
                            <div className="innerSelectBox weekCounter datepicker-main">
                              <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                className="form-control"
                              />
                              <img
                                src={Calendar}
                                className="calendarLogo"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="right-export-btn-section-wrapper">
                        <div className="c-pointer me-2">
                          {/* <img src={Export} alt="" /> */}
                        </div>
                        <div className="c-pointer">
                          <img src={Import} alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="yauto">
                      <div className="row gx-3 main-cards-wrapper">
                        <div
                          className={
                            sidebar ? "col-lg-4 col-md-6" : "col-lg-3 col-md-6"
                          }
                        >
                          <div className={"common-vehical-card-inner "}>
                            <div className="vehical-card-head">
                              <div className="heading" id="custom-logo-music">
                                <img src={music} alt="" />
                                <div className="">
                                  <p className="sub-heading">Audio Title</p>
                                  <p className="title">Title of Audio</p>
                                </div>
                              </div>
                              <div className="option customer-option">
                                <Dropdown>
                                  <Dropdown.Toggle id="dropdown-basic">
                                    <img src={option} alt="" />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <Dropdown.Item>
                                      <Link to="/EditAudio">Edit</Link>
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={handleShow}>
                                      <Link to="#">Delete</Link>
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                            <div className="vehical-card-body row">
                              <div className="card-contain col-lg-12">
                                <p className="sub-heading">Audio Clip</p>
                                <div className="Audio_custom">
                                  <audio src={audio1} controls></audio>
                                </div>
                              </div>
                              <div className="card-contain col-lg-12">
                                <p className="sub-heading">Audio Date </p>
                                <p className="title2">20-01-2023</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className={
                            sidebar ? "col-lg-4 col-md-6" : "col-lg-3 col-md-6"
                          }
                        >
                          <div className={"common-vehical-card-inner "}>
                            <div className="vehical-card-head">
                              <div className="heading" id="custom-logo-music">
                                <img src={music} alt="" />
                                <div className="">
                                  <p className="sub-heading">Audio Title</p>
                                  <p className="title">Title of Audio</p>
                                </div>
                              </div>
                              <div className="option customer-option">
                                <Dropdown>
                                  <Dropdown.Toggle id="dropdown-basic">
                                    <img src={option} alt="" />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <Dropdown.Item>
                                      <Link to="/EditAudio">Edit</Link>
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={handleShow}>
                                      <Link to="#">Delete</Link>
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                            <div className="vehical-card-body row">
                              <div className="card-contain col-lg-12">
                                <p className="sub-heading">Audio Clip</p>
                                <div className="Audio_custom">
                                  <audio src={audio1} controls></audio>
                                </div>
                              </div>
                              <div className="card-contain col-lg-12">
                                <p className="sub-heading">Audio Date </p>
                                <p className="title2">20-01-2023</p>
                              </div>
                            </div>
                          </div>
                        </div><div
                          className={
                            sidebar ? "col-lg-4 col-md-6" : "col-lg-3 col-md-6"
                          }
                        >
                          <div className={"common-vehical-card-inner "}>
                            <div className="vehical-card-head">
                              <div className="heading" id="custom-logo-music">
                                <img src={music} alt="" />
                                <div className="">
                                  <p className="sub-heading">Audio Title</p>
                                  <p className="title">Title of Audio</p>
                                </div>
                              </div>
                              <div className="option customer-option">
                                <Dropdown>
                                  <Dropdown.Toggle id="dropdown-basic">
                                    <img src={option} alt="" />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <Dropdown.Item>
                                      <Link to="/EditAudio">Edit</Link>
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={handleShow}>
                                      <Link to="#">Delete</Link>
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                            <div className="vehical-card-body row">
                              <div className="card-contain col-lg-12">
                                <p className="sub-heading">Audio Clip</p>
                                <div className="Audio_custom">
                                  <audio src={audio1} controls></audio>
                                </div>
                              </div>
                              <div className="card-contain col-lg-12">
                                <p className="sub-heading">Audio Date </p>
                                <p className="title2">20-01-2023</p>
                              </div>
                            </div>
                          </div>
                        </div><div
                          className={
                            sidebar ? "col-lg-4 col-md-6" : "col-lg-3 col-md-6"
                          }
                        >
                          <div className={"common-vehical-card-inner "}>
                            <div className="vehical-card-head">
                              <div className="heading" id="custom-logo-music">
                                <img src={music} alt="" />
                                <div className="">
                                  <p className="sub-heading">Audio Title</p>
                                  <p className="title">Title of Audio</p>
                                </div>
                              </div>
                              <div className="option customer-option">
                                <Dropdown>
                                  <Dropdown.Toggle id="dropdown-basic">
                                    <img src={option} alt="" />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <Dropdown.Item>
                                      <Link to="/EditAudio">Edit</Link>
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={handleShow}>
                                      <Link to="#">Delete</Link>
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                            <div className="vehical-card-body row">
                              <div className="card-contain col-lg-12">
                                <p className="sub-heading">Audio Clip</p>
                                <div className="Audio_custom">
                                  <audio src={audio1} controls></audio>
                                </div>
                              </div>
                              <div className="card-contain col-lg-12">
                                <p className="sub-heading">Audio Date </p>
                                <p className="title2">20-01-2023</p>
                              </div>
                            </div>
                          </div>
                        </div><div
                          className={
                            sidebar ? "col-lg-4 col-md-6" : "col-lg-3 col-md-6"
                          }
                        >
                          <div className={"common-vehical-card-inner "}>
                            <div className="vehical-card-head">
                              <div className="heading" id="custom-logo-music">
                                <img src={music} alt="" />
                                <div className="">
                                  <p className="sub-heading">Audio Title</p>
                                  <p className="title">Title of Audio</p>
                                </div>
                              </div>
                              <div className="option customer-option">
                                <Dropdown>
                                  <Dropdown.Toggle id="dropdown-basic">
                                    <img src={option} alt="" />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <Dropdown.Item>
                                      <Link to="/EditAudio">Edit</Link>
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={handleShow}>
                                      <Link to="#">Delete</Link>
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                            <div className="vehical-card-body row">
                              <div className="card-contain col-lg-12">
                                <p className="sub-heading">Audio Clip</p>
                                <div className="Audio_custom">
                                  <audio src={audio1} controls></audio>
                                </div>
                              </div>
                              <div className="card-contain col-lg-12">
                                <p className="sub-heading">Audio Date </p>
                                <p className="title2">20-01-2023</p>
                              </div>
                            </div>
                          </div>
                        </div><div
                          className={
                            sidebar ? "col-lg-4 col-md-6" : "col-lg-3 col-md-6"
                          }
                        >
                          <div className={"common-vehical-card-inner "}>
                            <div className="vehical-card-head">
                              <div className="heading" id="custom-logo-music">
                                <img src={music} alt="" />
                                <div className="">
                                  <p className="sub-heading">Audio Title</p>
                                  <p className="title">Title of Audio</p>
                                </div>
                              </div>
                              <div className="option customer-option">
                                <Dropdown>
                                  <Dropdown.Toggle id="dropdown-basic">
                                    <img src={option} alt="" />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <Dropdown.Item>
                                      <Link to="/EditAudio">Edit</Link>
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={handleShow}>
                                      <Link to="#">Delete</Link>
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                            <div className="vehical-card-body row">
                              <div className="card-contain col-lg-12">
                                <p className="sub-heading">Audio Clip</p>
                                <div className="Audio_custom">
                                  <audio src={audio1} controls></audio>
                                </div>
                              </div>
                              <div className="card-contain col-lg-12">
                                <p className="sub-heading">Audio Date </p>
                                <p className="title2">20-01-2023</p>
                              </div>
                            </div>
                          </div>
                        </div><div
                          className={
                            sidebar ? "col-lg-4 col-md-6" : "col-lg-3 col-md-6"
                          }
                        >
                          <div className={"common-vehical-card-inner "}>
                            <div className="vehical-card-head">
                              <div className="heading" id="custom-logo-music">
                                <img src={music} alt="" />
                                <div className="">
                                  <p className="sub-heading">Audio Title</p>
                                  <p className="title">Title of Audio</p>
                                </div>
                              </div>
                              <div className="option customer-option">
                                <Dropdown>
                                  <Dropdown.Toggle id="dropdown-basic">
                                    <img src={option} alt="" />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <Dropdown.Item>
                                      <Link to="/EditAudio">Edit</Link>
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={handleShow}>
                                      <Link to="#">Delete</Link>
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                            <div className="vehical-card-body row">
                              <div className="card-contain col-lg-12">
                                <p className="sub-heading">Audio Clip</p>
                                <div className="Audio_custom">
                                  <audio src={audio1} controls></audio>
                                </div>
                              </div>
                              <div className="card-contain col-lg-12">
                                <p className="sub-heading">Audio Date </p>
                                <p className="title2">20-01-2023</p>
                              </div>
                            </div>
                          </div>
                        </div><div
                          className={
                            sidebar ? "col-lg-4 col-md-6" : "col-lg-3 col-md-6"
                          }
                        >
                          <div className={"common-vehical-card-inner "}>
                            <div className="vehical-card-head">
                              <div className="heading" id="custom-logo-music">
                                <img src={music} alt="" />
                                <div className="">
                                  <p className="sub-heading">Audio Title</p>
                                  <p className="title">Title of Audio</p>
                                </div>
                              </div>
                              <div className="option customer-option">
                                <Dropdown>
                                  <Dropdown.Toggle id="dropdown-basic">
                                    <img src={option} alt="" />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <Dropdown.Item>
                                      <Link to="/EditAudio">Edit</Link>
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={handleShow}>
                                      <Link to="#">Delete</Link>
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                            <div className="vehical-card-body row">
                              <div className="card-contain col-lg-12">
                                <p className="sub-heading">Audio Clip</p>
                                <div className="Audio_custom">
                                  <audio src={audio1} controls></audio>
                                </div>
                              </div>
                              <div className="card-contain col-lg-12">
                                <p className="sub-heading">Audio Date </p>
                                <p className="title2">20-01-2023</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Modal
                    show={show}
                    onHide={handleClose}
                    centered
                    className="common-model"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Delete Trip</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      Are you sure you want to delete this Trip ?
                    </Modal.Body>
                    <Modal.Footer>
                      <button className="cx-btn-1" onClick={handleClose}>
                        Close
                      </button>
                      <button className="cx-btn-2" onClick={handleClose}>
                        Yes
                      </button>
                    </Modal.Footer>
                  </Modal>
                </div>
              </div>
            </div>
          </div>

          <Modal
            show={show}
            onHide={handleClose}
            centered
            className="common-model"
          >
            <Modal.Header closeButton>
              <Modal.Title>Delete Audio</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to delete this Audio ?
            </Modal.Body>
            <Modal.Footer className="pop-up-modal-footer">
              <button className="cx-btn-1" onClick={handleClose}>
                Close
              </button>
              <button className="cx-btn-2" onClick={handleClose}>
                Yes
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </motion.div>
  );
};

export default Announcement;
