import { React, useContext, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import profile from "../../../assets/images/Customer-profile.png";
import GreenIcon from "../../../assets/images/Green-check.svg";
import camera from "../../../assets/images/prof_cam.svg";
import { Col, Dropdown, Modal, Nav, Tab, Tabs } from "react-bootstrap";
import option from "../../../assets/images/option-three-dot.svg";
import { Link, useNavigate } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import t1 from "../../../assets/images/t1.svg";
import t2 from "../../../assets/images/t2.svg";
import t3 from "../../../assets/images/t3.svg";
import t4 from "../../../assets/images/t4.svg";
import t5 from "../../../assets/images/t5.svg";
import t6 from "../../../assets/images/t6.svg";
import t7 from "../../../assets/images/t7.svg";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import PreviousButton from "../../../sharedComponent/PreviousButton";
import NextButton from "../../../sharedComponent/Nextbutton";
import ExistButton from "../../../sharedComponent/ExistButton";

const ViewDrivers = ({ onClick }) => {
  const { sidebar, setSidebar, Dark, setDark, slider, setSlider } =
    useContext(AppContext);
  const { t, i18n } = useTranslation();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };

  const navigate = useNavigate();
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    navigate("/Drivers");
  };
  const handleClickPrevious = () => {
    navigate("/EditDrivers");
  };

  return (
    <>
      <motion.div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }}
      >
        <div id="cx-wrapper" className="view_drivers">
          <div
            className="main-dashboard-wrapper CustomerProfile"
            id="viewAdministratorProfile"
          >
            <div className="CustomerProfile-head">
              <div className="porile-img prof-img">
                <img src={profile} alt="porfile" />
              </div>
              <div className="customer-option">
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic">
                    <img src={option} alt="" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <Link to="/EditDrivers" className="d-block">
                        {t("Edit")}
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item href="#" onClick={handleShow}>
                      <Link to="#" className="d-block">
                        {t("Delete")}
                      </Link>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className="information-card">
              <div className="information-head">
                <div className="imformation-heading">
                  <p>{t("Contact Information")}</p>
                </div>
              </div>
              <div className="information-contain row">
                <div className="information-discriptiopn col-lg-3 col-md-4 col-sm-6">
                  <p className="discription-heading">{t("Name")}</p>
                  <p className="discription-contain">Mark woods</p>
                </div>
                <div className="information-discriptiopn col-lg-3 col-md-4 col-sm-6">
                  <p className="discription-heading">{t("Contact Number")}</p>
                  <p className="discription-contain">99999 99999</p>
                </div>
                <div className="information-discriptiopn col-lg-3 col-md-4 col-sm-6">
                  <p className="discription-heading">{t("Email")}</p>
                  <p className="discription-contain">
                    markwoods@gmail.com <img src={GreenIcon} alt="" />{" "}
                  </p>
                </div>
                <div className="information-discriptiopn col-lg-3 col-md-4 col-sm-6">
                  <p className="discription-heading">
                    {t("Vehicle Assignements")}
                  </p>
                  <p className="discription-contain">
                    MH 12 AS 7807, Toyota Innova
                  </p>
                </div>
              </div>
            </div>
            <div className="information-card mt-4 pt-3">
              <div className="information-head">
                <div className="imformation-heading">
                  <p>{t("General Information")}</p>
                </div>
              </div>
              <div className="information-contain row">
                <div className="information-discriptiopn col-lg-3 col-md-4 col-sm-6 form_input_main">
                  <p className="discription-heading">{t("Address")}</p>
                  <p className="discription-contain">
                    100, Vishrantwadi, Kalas, Pune Vishrantwadi, Kalas,
                    Pune-411005
                  </p>
                </div>
                <div className="information-discriptiopn col-lg-3 col-md-4 col-sm-6 ">
                  <p className="discription-heading">{t("City")}</p>
                  <p className="discription-contain">Pune</p>
                </div>
                <div className="information-discriptiopn col-lg-3 col-md-4 col-sm-6">
                  <p className="discription-heading">{t("Nationality")}</p>
                  <p className="discription-contain">Indian</p>
                </div>
                <div className="information-discriptiopn col-lg-3 col-md-4 col-sm-6">
                  <p className="discription-heading">
                    {t("Experience")} ({t("Years")})
                  </p>
                  <p className="discription-contain">4.5 Years</p>
                </div>
                <div className="information-discriptiopn col-lg-3 col-md-4 col-sm-6">
                  <p className="discription-heading">
                    {t("Total Distance Travelled")}
                  </p>
                  <p className="discription-contain">5000 Kms</p>
                </div>
                <div className="information-discriptiopn col-lg-3 col-md-4 col-sm-6">
                  <p className="discription-heading">{t("Joining Date")}</p>
                  <p className="discription-contain">08-10-2022</p>
                </div>
                <div className="information-discriptiopn col-lg-3 col-md-4 col-sm-6">
                  <p className="discription-heading">{t("Date Of Birth")}</p>
                  <p className="discription-contain">08-10-2022</p>
                </div>
                <div className="information-discriptiopn col-lg-3 col-md-4 col-sm-6">
                  <p className="discription-heading">{t("Gender")}</p>
                  <p className="discription-contain">Male</p>
                </div>
                <div className="information-discriptiopn col-lg-3 col-md-4 col-sm-6 mt-3">
                  <p className="discription-heading">{t("Status")}</p>
                  <p className="discription-contain">Active</p>
                </div>
              </div>
            </div>
            <div className="information-card mt-4 pt-3">
              <div className="information-head">
                <div className="imformation-heading">
                  <p>{t("Licence Information")}</p>
                </div>
              </div>
              <div className="information-contain row">
                <div className="information-discriptiopn col-lg-3 col-md-4 col-sm-6 form_input_main">
                  <p className="discription-heading">
                    {t("Licence Information")}
                  </p>
                  <p className="discription-contain">Abcd12345</p>
                </div>
                <div className="information-discriptiopn col-lg-3 col-md-4 col-sm-6">
                  <p className="discription-heading">
                    {t("Licence Issue Date")}
                  </p>
                  <p className="discription-contain">05-05-2022</p>
                </div>
                <div className="information-discriptiopn col-lg-3 col-md-4 col-sm-6">
                  <p className="discription-heading">
                    {t("Licence Expiry Date")}
                  </p>
                  <p className="discription-contain">04-05-2042</p>
                </div>
                <div className="information-discriptiopn col-lg-3 col-md-4 col-sm-6">
                  <p className="discription-heading">{t("Licence Type")}</p>
                  <p className="discription-contain">Heavy Combination</p>
                </div>
                <div className="information-discriptiopn col-lg-3 col-md-4 col-sm-6">
                  <p className="discription-heading">{t("Licence Area")}</p>
                  <p className="discription-contain">Pune</p>
                </div>
              </div>
            </div>
          </div>
          <div className="tabs-main-details inner-tabs-section row m-0">
            <Tab.Container
              id="left-tabs-example"
              className="td-tab-wrapper"
              defaultActiveKey="0"
            >
              <Nav variant="pills" className="td-nav" id="InnerTabNew_Auto">
                <Nav.Item className="td-tab">
                  <Nav.Link className="td-link" eventKey="0">
                    {t("Recent Activity")}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="td-tab">
                  <Nav.Link className="td-link" eventKey="1">
                    {t("Scheduled Trips")}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="td-tab">
                  <Nav.Link className="td-link" eventKey="2">
                    {t("Completed Trips")}
                  </Nav.Link>
                </Nav.Item>
              </Nav>

              <Col sm={12} className="">
                <Tab.Content>
                  <Tab.Pane eventKey="0">
                    <div className="transport-main row m-0 p-0 mb-3">
                      <div className="common-table details-tabel-main vd-table">
                        <table>
                          <thead>
                            <tr>
                              <th id="spacingBetween">{t("Sr.No")}</th>
                              <th>{t("Vehicle Type")}</th>
                              <th>{t("Date")}</th>
                              <th>{t("Time")}</th>
                              <th>{t("From")}</th>
                              <th>{t("To")}</th>
                              <th>{t("Total Distance")}</th>
                              <th>{t("Status")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>1</td>
                              <td>Car</td>
                              <td>06-01-2023</td>
                              <td>01:00:00 pm</td>
                              <td>Vishrantwadi, Kalas Road , Pune</td>
                              <td>Ch, Shivaji Maharaj Terminal, Mumbai</td>
                              <td>120Kms</td>
                              <td>In Progress</td>
                            </tr>
                            <tr>
                              <td>2</td>
                              <td>Car</td>
                              <td>06-01-2023</td>
                              <td>01:00:00 pm</td>
                              <td>Vishrantwadi, Kalas Road , Pune</td>
                              <td>Ch, Shivaji Maharaj Terminal, Mumbai</td>
                              <td>120Kms</td>
                              <td>In Progress</td>
                            </tr>
                            <tr>
                              <td>3</td>
                              <td>Car</td>
                              <td>06-01-2023</td>
                              <td>01:00:00 pm</td>
                              <td>Vishrantwadi, Kalas Road , Pune</td>
                              <td>Ch, Shivaji Maharaj Terminal, Mumbai</td>
                              <td>120Kms</td>
                              <td>In Progress</td>
                            </tr>
                            <tr>
                              <td>4</td>
                              <td>Car</td>
                              <td>06-01-2023</td>
                              <td>01:00:00 pm</td>
                              <td>Vishrantwadi, Kalas Road , Pune</td>
                              <td>Ch, Shivaji Maharaj Terminal, Mumbai</td>
                              <td>120Kms</td>
                              <td>In Progress</td>
                            </tr>
                            <tr>
                              <td>5</td>
                              <td>Car</td>
                              <td>06-01-2023</td>
                              <td>01:00:00 pm</td>
                              <td>Vishrantwadi, Kalas Road , Pune</td>
                              <td>Ch, Shivaji Maharaj Terminal, Mumbai</td>
                              <td>120Kms</td>
                              <td>In Progress</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <p className="no-data">{t("No Recent Activity Found")}</p>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="1">
                    <div className="transport-main row m-0 p-0 mb-3">
                      <div className="common-table details-tabel-main vd-table">
                        <table>
                          <thead>
                            <tr>
                              <th id="spacingBetween">{t("Sr.No")}</th>
                              <th>{t("Vehicle Type")}</th>
                              <th>{t("Date")}</th>
                              <th>{t("E.T.D")}</th>
                              <th>{t("From")}</th>
                              <th>{t("To")}</th>
                              <th>{t("Total Distance")}</th>
                              <th>{t("Trip Type")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>1</td>
                              <td>Car</td>
                              <td>06-01-2023</td>
                              <td>01:00:00 pm</td>
                              <td>Vishrantwadi, Kalas Road , Pune</td>
                              <td>Ch, Shivaji Maharaj Terminal, Mumbai</td>
                              <td>120Kms</td>
                              <td>
                                <div className="type-wrapper">
                                  <OverlayTrigger
                                    key="bottom"
                                    placement="bottom"
                                    overlay={
                                      <Tooltip
                                        wrapperStyle={{
                                          backgroundColor: "#4A4A4A",
                                        }}
                                        id="tooltip-bottom"
                                        className="t1"
                                      >
                                        Sunday
                                      </Tooltip>
                                    }
                                  >
                                    <img src={t1} alt="" id="t1" />
                                  </OverlayTrigger>

                                  <OverlayTrigger
                                    key="bottom"
                                    placement="bottom"
                                    overlay={
                                      <Tooltip id="tooltip-bottom">
                                        Not Available
                                      </Tooltip>
                                    }
                                  >
                                    <img src={t2} alt="" id="t2" />
                                  </OverlayTrigger>

                                  <img src={t3} alt="" id="t3" />
                                  <img src={t4} alt="" id="t4" />

                                  <img src={t5} alt="" id="t5" />
                                  <OverlayTrigger
                                    key="bottom"
                                    placement="bottom"
                                    overlay={
                                      <Tooltip id="tooltip-bottom">
                                        No Trip Assign
                                      </Tooltip>
                                    }
                                  >
                                    <img src={t6} alt="" id="t6" />
                                  </OverlayTrigger>

                                  <img src={t7} alt="" id="t7" />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>2</td>
                              <td>Car</td>
                              <td>06-01-2023</td>
                              <td>01:00:00 pm</td>
                              <td>Vishrantwadi, Kalas Road , Pune</td>
                              <td>Ch, Shivaji Maharaj Terminal, Mumbai</td>
                              <td>120Kms</td>
                              <td>
                                <div className="type-wrapper">
                                  <OverlayTrigger
                                    key="bottom"
                                    placement="bottom"
                                    overlay={
                                      <Tooltip id="tooltip-bottom">
                                        Sunday
                                      </Tooltip>
                                    }
                                  >
                                    <img src={t1} alt="" id="t1" />
                                  </OverlayTrigger>

                                  <OverlayTrigger
                                    key="bottom"
                                    placement="bottom"
                                    overlay={
                                      <Tooltip id="tooltip-bottom">
                                        Not Available
                                      </Tooltip>
                                    }
                                  >
                                    <img src={t2} alt="" id="t2" />
                                  </OverlayTrigger>

                                  <img src={t3} alt="" id="t3" />
                                  <img src={t4} alt="" id="t4" />

                                  <img src={t5} alt="" id="t5" />
                                  <OverlayTrigger
                                    key="bottom"
                                    placement="bottom"
                                    overlay={
                                      <Tooltip id="tooltip-bottom">
                                        No Trip Assign
                                      </Tooltip>
                                    }
                                  >
                                    <img src={t6} alt="" id="t6" />
                                  </OverlayTrigger>

                                  <img src={t7} alt="" id="t7" />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>3</td>
                              <td>Car</td>
                              <td>06-01-2023</td>
                              <td>01:00:00 pm</td>
                              <td>Vishrantwadi, Kalas Road , Pune</td>
                              <td>Ch, Shivaji Maharaj Terminal, Mumbai</td>
                              <td>120Kms</td>
                              <td>
                                <div className="type-wrapper">
                                  <OverlayTrigger
                                    key="bottom"
                                    placement="bottom"
                                    overlay={
                                      <Tooltip id="tooltip-bottom">
                                        Sunday
                                      </Tooltip>
                                    }
                                  >
                                    <img src={t1} alt="" id="t1" />
                                  </OverlayTrigger>

                                  <OverlayTrigger
                                    key="bottom"
                                    placement="bottom"
                                    overlay={
                                      <Tooltip id="tooltip-bottom">
                                        Not Available
                                      </Tooltip>
                                    }
                                  >
                                    <img src={t2} alt="" id="t2" />
                                  </OverlayTrigger>

                                  <img src={t3} alt="" id="t3" />
                                  <img src={t4} alt="" id="t4" />

                                  <img src={t5} alt="" id="t5" />
                                  <OverlayTrigger
                                    key="bottom"
                                    placement="bottom"
                                    overlay={
                                      <Tooltip id="tooltip-bottom">
                                        No Trip Assign
                                      </Tooltip>
                                    }
                                  >
                                    <img src={t6} alt="" id="t6" />
                                  </OverlayTrigger>

                                  <img src={t7} alt="" id="t7" />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>4</td>
                              <td>Car</td>
                              <td>06-01-2023</td>
                              <td>01:00:00 pm</td>
                              <td>Vishrantwadi, Kalas Road , Pune</td>
                              <td>Ch, Shivaji Maharaj Terminal, Mumbai</td>
                              <td>120Kms</td>
                              <td>
                                <div className="type-wrapper">
                                  <OverlayTrigger
                                    key="bottom"
                                    placement="bottom"
                                    overlay={
                                      <Tooltip id="tooltip-bottom">
                                        Sunday
                                      </Tooltip>
                                    }
                                  >
                                    <img src={t1} alt="" id="t1" />
                                  </OverlayTrigger>

                                  <OverlayTrigger
                                    key="bottom"
                                    placement="bottom"
                                    overlay={
                                      <Tooltip id="tooltip-bottom">
                                        Not Available
                                      </Tooltip>
                                    }
                                  >
                                    <img src={t2} alt="" id="t2" />
                                  </OverlayTrigger>

                                  <img src={t3} alt="" id="t3" />
                                  <img src={t4} alt="" id="t4" />

                                  <img src={t5} alt="" id="t5" />
                                  <OverlayTrigger
                                    key="bottom"
                                    placement="bottom"
                                    overlay={
                                      <Tooltip id="tooltip-bottom">
                                        No Trip Assign
                                      </Tooltip>
                                    }
                                  >
                                    <img src={t6} alt="" id="t6" />
                                  </OverlayTrigger>

                                  <img src={t7} alt="" id="t7" />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <p className="no-data">{t("No Trips Scheduled now")}</p>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="2">
                    <div className="transport-main row m-0 p-0 mb-3">
                      <div className="common-table details-tabel-main vd-table">
                        <table>
                          <thead>
                            <tr>
                              <th id="spacingBetween">{t("Sr.No")}</th>
                              <th>{t("Vehicle Type")}</th>
                              <th>{t("Date")}</th>
                              <th>{t("E.T.D")}</th>
                              <th>{t("From")}</th>
                              <th>{t("To")}</th>
                              <th>{t("Total Distance")}</th>
                              <th>{t("Trip Type")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>1</td>
                              <td>Car</td>
                              <td>06-01-2023</td>
                              <td>01:00:00 pm</td>
                              <td>Vishrantwadi, Kalas Road , Pune</td>
                              <td>Ch, Shivaji Maharaj Terminal, Mumbai</td>
                              <td>120Kms</td>
                              <td>Completed</td>
                            </tr>
                            <tr>
                              <td>2</td>
                              <td>Car</td>
                              <td>06-01-2023</td>
                              <td>01:00:00 pm</td>
                              <td>Vishrantwadi, Kalas Road , Pune</td>
                              <td>Ch, Shivaji Maharaj Terminal, Mumbai</td>
                              <td>120Kms</td>
                              <td>Completed</td>
                            </tr>
                            <tr>
                              <td>3</td>
                              <td>Car</td>
                              <td>06-01-2023</td>
                              <td>01:00:00 pm</td>
                              <td>Vishrantwadi, Kalas Road , Pune</td>
                              <td>Ch, Shivaji Maharaj Terminal, Mumbai</td>
                              <td>120Kms</td>
                              <td>Completed</td>
                            </tr>
                            <tr>
                              <td>4</td>
                              <td>Car</td>
                              <td>06-01-2023</td>
                              <td>01:00:00 pm</td>
                              <td>Vishrantwadi, Kalas Road , Pune</td>
                              <td>Ch, Shivaji Maharaj Terminal, Mumbai</td>
                              <td>120Kms</td>
                              <td>Completed</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <p className="no-data">{t("No Trip Found")}</p>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Tab.Container>
          </div>
          {/* Delete Modal Start */}
          <Modal
            show={show}
            onHide={handleClose}
            centered
            className="common-model"
          >
            <Modal.Header closeButton>
              <Modal.Title>{t("Delete Driver")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {t("Are you sure you want to Delete this Driver")} ?
            </Modal.Body>
            <Modal.Footer className="pop-up-modal-footer">
              <div class="btn-wrapper">
                <button className="cx-btn-1" onClick={handleClose}>
                  {t("Cancel")}
                </button>
                <button className="cx-btn-2" onClick={handleClose}>
                  {t("Yes")}
                </button>
              </div>
            </Modal.Footer>
          </Modal>
          {/* Delete Modal End */}
          {slider ? <ExistButton onClose={() => setSlider(false)} /> : null}
          {!slider && <PreviousButton onBack={handleClickPrevious} />}
          {!slider && <NextButton onClick={handleClick} />}
        </div>
      </motion.div>
    </>
  );
};

export default ViewDrivers;
