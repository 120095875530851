import { React, useContext, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import option from "../../../assets/images/option-three-dot.svg";
import SideIc from "../../../assets/images/sideBar.svg";
import export_icon from "../../../assets/images/export_icon.svg";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { Dropdown, Modal } from "react-bootstrap";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

const ViewDispatch = () => {
  const { sidebar, setSidebar, Dark, setDark } = useContext(AppContext);
  const [deleteModal, setDeleteModal] = useState(false);
  const [show, setShow] = useState(false);
  const { t, i18n } = useTranslation();

  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  return (
    <>
      <motion.div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }} >
        <div id="cx-wrapper" className="View_Dispatch" >
          {/* Top inputs for instatnt search */}
          <div className="displayFlexInp" >
            <div className="innerSelectBox weekCounter selectForm form_input_main">
              <Form.Select
                required
                as="select"
                type="select"
                name="Speed_limit"
                className="innerCust"
              >
                <option value="">Vehicle Name</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="150">150</option>
              </Form.Select>
            </div>
            <div className="innerSelectBox weekCounter selectForm form_input_main">
              <Form.Control
                required
                type="text"
                placeholder="Customer Code"
                className="innerCust"
              />
            </div>
            <div className="innerSelectBox weekCounter selectForm form_input_main dNo">
            </div>
            <div className="innerSelectBox weekCounter selectForm form_input_main dNo">
            </div>
            <div className="innerSelectBox weekCounter selectForm form_input_main">
              <Link to="/AddDispatchCustomer" className="AddAccidentLink">
                <button className="innerCust">+ {t("Add Customer")}</button>
              </Link>
            </div>
            <div className="headerDivIc form_input_main">
              <Link to="#">
                <img src={SideIc} alt="" />
              </Link>
            </div>
            <div className="headerDivIc form_input_main">
              <Link to="#">
                <img src={export_icon} alt="" />
              </Link>
            </div>
          </div>
          <div className="main-master-wrapper form_input_main" id="View_Dispatch_main">
            <div className="headingDetails">
              <div className="headingTxt">
                <p className="heading">{t("Customer Details")}</p>
              </div>
              <div className="customer-option">
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic">
                    <img src={option} alt="" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <Link to="/EditCustomerDispatch" className="d-block">{t("Edit")}</Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link onClick={() => setDeleteModal(true)} className="d-block" to="#">{t("Delete")}</Link>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className="DetailsSec">
              <div className="row">
                <div className="col-md-3">
                  <label className="head">{t("Customer Name")}</label>
                  <p className="Value">Mr. John Doe</p>
                </div>
                <div className="col-md-3">
                  <label className="head">{t("Customer Code")}</label>
                  <p className="Value">100</p>
                </div>
                <div className="col-md-3">
                  <label className="head">{t("Customer Email")}</label>
                  <p className="Value">john@gmail.com</p>
                </div>
                <div className="col-md-3">
                  <label className="head">{t("Customer Mobile No.")}</label>
                  <p className="Value">+91 99 99 99 9999</p>
                </div>
              </div>
            </div>
          </div>
          {/* Address No 1 */}
          <div className="main-master-wrapper form_input_main" id="View_Dispatch_main">
            <div className="headingDetails">
              <div className="headingTxt">
                <p className="heading">{t("Address")} -1</p>
              </div>
            </div>
            <div className="DetailsSec">
              <div className="row">
                <div className="col-md-6">
                  <label className="head">{t("Dispatch Customer Address")}</label>
                  <p className="Value">
                    Chala, Thiruvananthapuram - 695001, Kerala, India
                  </p>
                </div>
                <div className="col-md-3">
                  <label className="head">{t("Dispatch Customer Country Code")}</label>
                  <p className="Value">521645763</p>
                </div>
                <div className="col-md-3">
                  <label className="head">{t("Dispatch Customer Country Code")}</label>
                  <p className="Value">IN</p>
                </div>
              </div>
            </div>
          </div>
          {/* Address No 2 */}
          <div className="main-master-wrapper form_input_main" id="View_Dispatch_main">
            <div className="headingDetails">
              <div className="headingTxt">
                <p className="heading">{t("Address")} -2</p>
              </div>
            </div>
            <div className="DetailsSec">
              <div className="row">
                <div className="col-md-6">
                  <label className="head">{t("Dispatch Customer Address")}</label>
                  <p className="Value">
                    Cahempaka Kindergarden, Subhash Nagar, Fort,
                    Thiruvananthapuram - 695001, Kerala, India
                  </p>
                </div>
                <div className="col-md-3">
                  <label className="head">{t("Dispatch Customer Country Code")}</label>
                  <p className="Value">521645763</p>
                </div>
                <div className="col-md-3">
                  <label className="head">{t("Dispatch Customer Country Code")}</label>
                  <p className="Value">IN</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
      <Modal
        show={deleteModal}
        onHide={() => setDeleteModal(false)}
        centered
        className="common-model"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Delete Customer Details")} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {t("Are you sure you want to delete this Details")} ?
        </Modal.Body>
        <Modal.Footer className="pop-up-modal-footer">
          <div class="btn-wrapper">
            <button className="cx-btn-1" onClick={() => setDeleteModal(false)}>
              {t("Cancel")}
            </button>
            <button className="cx-btn-2" onClick={() => setDeleteModal(false)}>
              {t("Yes")}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ViewDispatch;
