import { React, useContext, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import { motion } from "framer-motion";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PreviousButton from "../../../sharedComponent/PreviousButton";
import NextButton from "../../../sharedComponent/Nextbutton";
import ExistButton from "../../../sharedComponent/ExistButton";
import InfoComponent from "../../../sharedComponent/SupportHelp/InfoComponent";

const VehicleBooking = ({ onClick, onBack, handleClose }) => {
  const { sidebar, setSidebar, Dark, setDark, slider, setSlider } =
    useContext(AppContext);
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    navigate("/VehicleBookingList");
  };
  const handleClickPrevious = () => {
    navigate("/AddDispatchCustomer");
  };
  return (
    <>
      <motion.div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }}
      >
        <div id="cx-wrapper" className="vehicle_Booking">
          <div className="main-master-wrapper">
            {/* Header section */}
            <div className="header">
              <label className="headerTxt">{t("Add Vehicle Booking")}</label>
            </div>
            {/* COntent section */}
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <div className="row vehicleMainRow">
                <div className="col-md-6 col-sm-12 colForm">
                  <Form.Label>{t("Date")}</Form.Label>
                  <DatePicker
                    placeholderText="Select Date Range..."
                    className="form-control"
                  />
                  <Form.Control.Feedback>{t("Add Date")}</Form.Control.Feedback>
                </div>
                <div className="col-md-6 col-sm-12 colForm">
                  <Form.Label>{t("Time")}</Form.Label>
                  <input type="time" class="form-control" value="10:05 AM" />
                  <Form.Control.Feedback>{t("Add Time")}</Form.Control.Feedback>
                </div>
                <div className="col-md-6 col-sm-12 colForm">
                  <Form.Label className="common-labels">
                    {t("Vehicle Type")}
                  </Form.Label>
                  <Form.Select
                    required
                    as="select"
                    type="select"
                    name="Speed_limit"
                  >
                    <option value="">Choose Vehicle Type...</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="150">150</option>
                  </Form.Select>
                  <Form.Control.Feedback>
                    Please select Vehicle
                  </Form.Control.Feedback>
                </div>
                <div className="col-md-6 col-sm-12 colForm">
                  <Form.Label className="common-labels">
                    {t("Transportation Type")}
                  </Form.Label>
                  <Form.Select
                    required
                    as="select"
                    type="select"
                    name="Speed_limit"
                  >
                    <option value="">Select Transportation Type...</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="150">150</option>
                  </Form.Select>
                  <Form.Control.Feedback>
                    Please select Transportation
                  </Form.Control.Feedback>
                </div>
                <div className="col-md-6 col-sm-12 colForm">
                  <Form.Label>{t("Number of Vehicles")}</Form.Label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="How many vehicles you want ?"
                  />
                  <Form.Control.Feedback>
                    Please Enter Number of Vehicles
                  </Form.Control.Feedback>
                </div>
                <div className="col-md-6 col-sm-12"></div>
              </div>
              <div className="detailsSec">
                <div className="headerDet">
                  <label className="headerTxtDet">{t("Pick-Up Details")}</label>
                  <div className="defult_check_address">
                    <div
                      className="form-check form-switch custom_address"
                      id="custom_switch_address"
                    >
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="defult_Pick"
                        defaultChecked
                      />
                      <label className="defult-adrs-txt" htmlFor="defult_Pick">
                        {t("Use Default Address")}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-12 colFormDet">
                    <Form.Label className="common-labels">
                      {t("Merchant")}
                    </Form.Label>
                    <Form.Select
                      required
                      as="select"
                      type="select"
                      name="Speed_limit"
                    >
                      <option value="">Enter Merchant...</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="150">150</option>
                    </Form.Select>
                    <Form.Control.Feedback>
                      Please Enter Merchant...
                    </Form.Control.Feedback>
                  </div>
                  <div className="col-md-6 col-sm-12 colFormDet">
                    <Form.Label className="common-labels">
                      {t("Warehouse")}
                    </Form.Label>
                    <Form.Select
                      required
                      as="select"
                      type="select"
                      name="Speed_limit"
                    >
                      <option value="">Enter Warehouse...</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="150">150</option>
                    </Form.Select>
                    <Form.Control.Feedback>
                      Please Enter Warehouse...
                    </Form.Control.Feedback>
                  </div>
                  <div className="col-md-12 colFormDet">
                    <Form.Label className="common-labels">
                      {t("Pickup Address")}
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      required
                      type="text"
                      placeholder="Enter Pickup  Address..."
                    />
                    <Form.Control.Feedback type="invalid">
                      Please Enter Pickup Address......
                    </Form.Control.Feedback>
                  </div>
                </div>
              </div>
              <div className="detailsSec">
                <div className="headerDet">
                  <label className="headerTxtDet">{t("Drop Details")}</label>
                  <div className="defult_check_address">
                    <div
                      className="form-check form-switch custom_address"
                      id="custom_switch_address"
                    >
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="defult_Drop"
                        defaultChecked
                      />
                      <label className="defult-adrs-txt" htmlFor="defult_Drop">
                        {t("Use Default Address")}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-12 colFormDet">
                    <Form.Label className="common-labels">
                      {t("Customer")}
                    </Form.Label>
                    <Form.Select
                      required
                      as="select"
                      type="select"
                      name="Speed_limit"
                    >
                      <option value="">Select Customer..</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="150">150</option>
                    </Form.Select>
                    <Form.Control.Feedback>
                      Please Select customer...
                    </Form.Control.Feedback>
                  </div>
                  <div className="col-md-12 colFormDet">
                    <Form.Label className="common-labels">
                      {t("Delivery Address")}
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      required
                      type="text"
                      placeholder="Enter Delivery Address..."
                    />
                    <Form.Control.Feedback type="invalid">
                      Please Enter Delivery Address...
                    </Form.Control.Feedback>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <button className="cx-btn-1">{t("Cancel")}</button>
                <button className="cx-btn-2">{t("Submit")}</button>
              </div>
            </Form>
          </div>
          {slider ? <ExistButton onClose={() => setSlider(false)} /> : null}
          {!slider && <PreviousButton onBack={handleClickPrevious} />}
          {!slider && <NextButton onClick={handleClick} />}
          {!slider && <InfoComponent />}
        </div>
      </motion.div>
    </>
  );
};

export default VehicleBooking;
