import { React, useContext, useState } from "react";
// import { AppContext } from "../../../context/AppContext";
import { AppContext } from "../../context/AppContext";
import cart from "../../assets/images/cart.svg";
import noAddon from "../../assets/images/no_addon_icon.svg";
import { Tab, Tabs, Form } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Cart from "./AddOnSettingsCart";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

const AddOnSettings = () => {
  const { sidebar, setSidebar, Dark, setDark } = useContext(AppContext);
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  const { t, i18n } = useTranslation();

  return (
    <>
      <motion.div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }}
      >
        {/* Vehicle related Setting */}
        <div id="cx-wrapper" className="Add_on_setting">
          <div className="main-master-wrapper">
            <div className="Heading">
              <p>{t("Web Application Add-ons")}</p>
              <Link to="/AddOnSettingsCart">
                <button className="cx-btn-3">
                  <img src={cart} alt="" />
                  {t("My Cart")}
                </button>
              </Link>
            </div>
            <div className="add-on-tabs-wrapper">
              <Tab.Container
                id="left-tabs-example"
                className="aot-tab-wrapper"
                defaultActiveKey="0"
              >
                <Row>
                  <Col sm={12} className="outer-tab-add-on-setting">
                    <Nav variant="pills" className="aot-nav">
                      <Nav.Item className="aot-tab">
                        <Nav.Link className="aot-link" eventKey="0">
                          {t("My Add-ons")}
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="aot-tab">
                        <Nav.Link className="aot-link" eventKey="1">
                          {t("Premium Add-ons")}
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="aot-tab">
                        <Nav.Link className="aot-link" eventKey="2">
                          {t("Free Add-ons")}
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col sm={12} className="">
                    <Tab.Content>
                      <Tab.Pane eventKey="0">
                        <div className="add-on-card-wrapper">
                          <div className="row">
                            <div
                              className={
                                sidebar
                                  ? "col-md-6 col-lg-4"
                                  : "col-md-4 col-lg-3"
                              }
                            >
                              <div className="add-on-card">
                                <div className="ao-card-header">
                                  <p>{t("Add-on Dispatch")}</p>
                                  <button className="cx-btn-1 00">
                                    {t("Disable")}
                                  </button>
                                </div>
                                <div className="ao-card-table-wrapper">
                                  <table className="ao-card-table">
                                    <thead className="ao-card-head">
                                      <tr>
                                        <th>
                                          {t("Expected Monthly Subscription")}
                                        </th>
                                        <th>{t("Next Renewal")}</th>
                                      </tr>
                                    </thead>
                                    <tbody className="ao-card-body">
                                      <tr>
                                        <td>50$</td>
                                        <td>Feburaury 20, 2023</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                sidebar
                                  ? "col-md-6 col-lg-4"
                                  : "col-md-4 col-lg-3"
                              }
                            >
                              <div className="add-on-card">
                                <div className="ao-card-header">
                                  <p>{t("Add-on image Surveillance")}</p>
                                  <button className="cx-btn-1 hover-effect">
                                    {t("Disable")}
                                  </button>
                                </div>
                                <div className="ao-card-table-wrapper">
                                  <table className="ao-card-table">
                                    <thead className="ao-card-head">
                                      <tr>
                                        <th>
                                          {t("Expected Monthly Subscription")}
                                        </th>
                                        <th>{t("Next Renewal")}</th>
                                      </tr>
                                    </thead>
                                    <tbody className="ao-card-body">
                                      <tr>
                                        <td>50$</td>
                                        <td>Feburaury 20, 2023</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                sidebar
                                  ? "col-md-6 col-lg-4"
                                  : "col-md-4 col-lg-3"
                              }
                            >
                              <div className="add-on-card">
                                <div className="ao-card-header">
                                  <p>{t("Add-on Video Surveillance")}</p>
                                  <button className="cx-btn-1 hover-effect">
                                    {t("Disable")}
                                  </button>
                                </div>
                                <div className="ao-card-table-wrapper">
                                  <table className="ao-card-table">
                                    <thead className="ao-card-head">
                                      <tr>
                                        <th>
                                          {t("Expected Monthly Subscription")}
                                        </th>
                                        <th>{t("Next Renewal")}</th>
                                      </tr>
                                    </thead>
                                    <tbody className="ao-card-body">
                                      <tr>
                                        <td>50$</td>
                                        <td>Feburaury 20, 2023</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                sidebar
                                  ? "col-md-6 col-lg-4"
                                  : "col-md-4 col-lg-3"
                              }
                            >
                              <div className="add-on-card">
                                <div className="ao-card-header">
                                  <p>{t("Add-on Attendance")}</p>
                                  <button className="cx-btn-1 hover-effect">
                                    {t("Disable")}
                                  </button>
                                </div>
                                <div className="ao-card-table-wrapper">
                                  <table className="ao-card-table">
                                    <thead className="ao-card-head">
                                      <tr>
                                        <th>
                                          {t("Expected Monthly Subscription")}
                                        </th>
                                        <th>{t("Next Renewal")}</th>
                                      </tr>
                                    </thead>
                                    <tbody className="ao-card-body">
                                      <tr>
                                        <td>50$</td>
                                        <td>Feburaury 20, 2023</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                sidebar
                                  ? "col-md-6 col-lg-4"
                                  : "col-md-4 col-lg-3"
                              }
                            >
                              <div className="add-on-card">
                                <div className="ao-card-header">
                                  <p>{t("Add-on Auto-routing")}</p>
                                  <button className="cx-btn-1 hover-effect">
                                    {t("Disable")}
                                  </button>
                                </div>
                                <div className="ao-card-table-wrapper">
                                  <table className="ao-card-table">
                                    <thead className="ao-card-head">
                                      <tr>
                                        <th>
                                          {t("Expected Monthly Subscription")}
                                        </th>
                                        <th>{t("Next Renewal")}</th>
                                      </tr>
                                    </thead>
                                    <tbody className="ao-card-body">
                                      <tr>
                                        <td>50$</td>
                                        <td>Feburaury 20, 2023</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                sidebar
                                  ? "col-md-6 col-lg-4"
                                  : "col-md-4 col-lg-3"
                              }
                            >
                              <div className="add-on-card">
                                <div className="ao-card-header">
                                  <p>{t("Add-on Manual-routing")}</p>
                                  <button className="cx-btn-1 hover-effect">
                                    {t("Disable")}
                                  </button>
                                </div>
                                <div className="ao-card-table-wrapper">
                                  <table className="ao-card-table">
                                    <thead className="ao-card-head">
                                      <tr>
                                        <th>
                                          {t("Expected Monthly Subscription")}
                                        </th>
                                        <th>{t("Next Renewal")}</th>
                                      </tr>
                                    </thead>
                                    <tbody className="ao-card-body">
                                      <tr>
                                        <td>50$</td>
                                        <td>Feburaury 20, 2023</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="bottom-notes-section">
                          <div className="note-left">
                            <p>
                              <span className="red-star">* </span>{" "}
                              {t("Subscription cycle")} - First day of every
                              month
                            </p>
                          </div>
                          <div className="total-right">
                            <p>{t("Total monthly Subscription")} - 300$ </p>
                          </div>
                        </div>
                      </Tab.Pane>

                      {/* ======== If Customer Did not have any Addons then Show folloing screen (Start) ======== */}
                      {/* <Tab.Pane eventKey="0">
                      <div className="no-add-on">
                        <img src={noAddon} alt="" />
                        <h2>No Add-ons found...</h2>
                        <div className="new-addon">
                          <Link to="#">Click here</Link>
                          <p>to get new add-ons</p>
                        </div>
                      </div>
                    </Tab.Pane> */}
                      {/* ======== If Customer Did not have any Addons then Show folloing screen (End) ======== */}

                      <Tab.Pane eventKey="1">
                        <div className="add-on-card-wrapper">
                          <div className="row">
                            <div
                              className={
                                sidebar
                                  ? "col-md-6 col-lg-4"
                                  : "col-md-4 col-lg-3"
                              }
                            >
                              <div className="add-on-card">
                                <div className="ao-card-header">
                                  <p>{t("Add-on Dispatch")}</p>
                                  <button className="cx-btn-2">
                                    {t("Buy")}
                                  </button>
                                </div>
                                <div className="ao-card-table-wrapper">
                                  <table className="ao-card-table">
                                    <thead className="ao-card-head">
                                      <tr>
                                        <th>
                                          {t("Expected Monthly Subscription")}
                                        </th>
                                        <th>{t("Next Renewal")}</th>
                                      </tr>
                                    </thead>
                                    <tbody className="ao-card-body">
                                      <tr>
                                        <td>50$</td>
                                        <td>Feburaury 20, 2023</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                sidebar
                                  ? "col-md-6 col-lg-4"
                                  : "col-md-4 col-lg-3"
                              }
                            >
                              <div className="add-on-card">
                                <div className="ao-card-header">
                                  <p>{t("Add-on image Surveillance")}</p>
                                  <button className="cx-btn-1">
                                    {t("Added to cart")}
                                  </button>
                                </div>
                                <div className="ao-card-table-wrapper">
                                  <table className="ao-card-table">
                                    <thead className="ao-card-head">
                                      <tr>
                                        <th>
                                          {t("Expected Monthly Subscription")}
                                        </th>
                                        <th>{t("Next Renewal")}</th>
                                      </tr>
                                    </thead>
                                    <tbody className="ao-card-body">
                                      <tr>
                                        <td>50$</td>
                                        <td>Feburaury 20, 2023</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                sidebar
                                  ? "col-md-6 col-lg-4"
                                  : "col-md-4 col-lg-3"
                              }
                            >
                              <div className="add-on-card">
                                <div className="ao-card-header">
                                  <p>{t("Add-on Video Surveillance")}</p>
                                  <button className="cx-btn-1">
                                    {t("Added to cart")}
                                  </button>
                                </div>
                                <div className="ao-card-table-wrapper">
                                  <table className="ao-card-table">
                                    <thead className="ao-card-head">
                                      <tr>
                                        <th>
                                          {t("Expected Monthly Subscription")}
                                        </th>
                                        <th>{t("Next Renewal")}</th>
                                      </tr>
                                    </thead>
                                    <tbody className="ao-card-body">
                                      <tr>
                                        <td>50$</td>
                                        <td>Feburaury 20, 2023</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                sidebar
                                  ? "col-md-6 col-lg-4"
                                  : "col-md-4 col-lg-3"
                              }
                            >
                              <div className="add-on-card">
                                <div className="ao-card-header">
                                  <p>{t("Add-on Attendance")}</p>
                                  <button className="cx-btn-2">
                                    {t("Buy")}
                                  </button>
                                </div>
                                <div className="ao-card-table-wrapper">
                                  <table className="ao-card-table">
                                    <thead className="ao-card-head">
                                      <tr>
                                        <th>
                                          {t("Expected Monthly Subscription")}
                                        </th>
                                        <th>{t("Next Renewal")}</th>
                                      </tr>
                                    </thead>
                                    <tbody className="ao-card-body">
                                      <tr>
                                        <td>50$</td>
                                        <td>Feburaury 20, 2023</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                sidebar
                                  ? "col-md-6 col-lg-4"
                                  : "col-md-4 col-lg-3"
                              }
                            >
                              <div className="add-on-card">
                                <div className="ao-card-header">
                                  <p>{t("Add-on Auto-routing")}</p>
                                  <button className="cx-btn-2">
                                    {t("Buy")}
                                  </button>
                                </div>
                                <div className="ao-card-table-wrapper">
                                  <table className="ao-card-table">
                                    <thead className="ao-card-head">
                                      <tr>
                                        <th>
                                          {t("Expected Monthly Subscription")}
                                        </th>
                                        <th>{t("Next Renewal")}</th>
                                      </tr>
                                    </thead>
                                    <tbody className="ao-card-body">
                                      <tr>
                                        <td>50$</td>
                                        <td>Feburaury 20, 2023</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                sidebar
                                  ? "col-md-6 col-lg-4"
                                  : "col-md-4 col-lg-3"
                              }
                            >
                              <div className="add-on-card">
                                <div className="ao-card-header">
                                  <p>{t("Add-on Manual-routing")}</p>
                                  <button className="cx-btn-2">
                                    {t("Buy")}
                                  </button>
                                </div>
                                <div className="ao-card-table-wrapper">
                                  <table className="ao-card-table">
                                    <thead className="ao-card-head">
                                      <tr>
                                        <th>
                                          {t("Expected Monthly Subscription")}
                                        </th>
                                        <th>{t("Next Renewal")}</th>
                                      </tr>
                                    </thead>
                                    <tbody className="ao-card-body">
                                      <tr>
                                        <td>50$</td>
                                        <td>Feburaury 20, 2023</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                sidebar
                                  ? "col-md-6 col-lg-4"
                                  : "col-md-4 col-lg-3"
                              }
                            >
                              <div className="add-on-card">
                                <div className="ao-card-header">
                                  <p>{t("Add-on Manual-routing")}</p>
                                  <button className="cx-btn-2">
                                    {t("Buy")}
                                  </button>
                                </div>
                                <div className="ao-card-table-wrapper">
                                  <table className="ao-card-table">
                                    <thead className="ao-card-head">
                                      <tr>
                                        <th>
                                          {t("Expected Monthly Subscription")}
                                        </th>
                                        <th>{t("Next Renewal")}</th>
                                      </tr>
                                    </thead>
                                    <tbody className="ao-card-body">
                                      <tr>
                                        <td>50$</td>
                                        <td>Feburaury 20, 2023</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                sidebar
                                  ? "col-md-6 col-lg-4"
                                  : "col-md-4 col-lg-3"
                              }
                            >
                              <div className="add-on-card">
                                <div className="ao-card-header">
                                  <p>{t("Add-on Manual-routing")}</p>
                                  <button className="cx-btn-1">
                                    {t("Added to cart")}
                                  </button>
                                </div>
                                <div className="ao-card-table-wrapper">
                                  <table className="ao-card-table">
                                    <thead className="ao-card-head">
                                      <tr>
                                        <th>
                                          {t("Expected Monthly Subscription")}
                                        </th>
                                        <th>{t("Next Renewal")}</th>
                                      </tr>
                                    </thead>
                                    <tbody className="ao-card-body">
                                      <tr>
                                        <td>50$</td>
                                        <td>Feburaury 20, 2023</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                sidebar
                                  ? "col-md-6 col-lg-4"
                                  : "col-md-4 col-lg-3"
                              }
                            >
                              <div className="add-on-card">
                                <div className="ao-card-header">
                                  <p>{t("Add-on Manual-routing")}</p>
                                  <button className="cx-btn-2">
                                    {t("Buy")}
                                  </button>
                                </div>
                                <div className="ao-card-table-wrapper">
                                  <table className="ao-card-table">
                                    <thead className="ao-card-head">
                                      <tr>
                                        <th>
                                          {t("Expected Monthly Subscription")}
                                        </th>
                                        <th>{t("Next Renewal")}</th>
                                      </tr>
                                    </thead>
                                    <tbody className="ao-card-body">
                                      <tr>
                                        <td>50$</td>
                                        <td>Feburaury 20, 2023</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                sidebar
                                  ? "col-md-6 col-lg-4"
                                  : "col-md-4 col-lg-3"
                              }
                            >
                              <div className="add-on-card">
                                <div className="ao-card-header">
                                  <p>{t("Add-on Manual-routing")}</p>
                                  <button className="cx-btn-2">
                                    {t("Buy")}
                                  </button>
                                </div>
                                <div className="ao-card-table-wrapper">
                                  <table className="ao-card-table">
                                    <thead className="ao-card-head">
                                      <tr>
                                        <th>
                                          {t("Expected Monthly Subscription")}
                                        </th>
                                        <th>{t("Next Renewal")}</th>
                                      </tr>
                                    </thead>
                                    <tbody className="ao-card-body">
                                      <tr>
                                        <td>50$</td>
                                        <td>Feburaury 20, 2023</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                sidebar
                                  ? "col-md-6 col-lg-4"
                                  : "col-md-4 col-lg-3"
                              }
                            >
                              <div className="add-on-card">
                                <div className="ao-card-header">
                                  <p>{t("Add-on Manual-routing")}</p>
                                  <button className="cx-btn-1">
                                    {t("Added to cart")}
                                  </button>
                                </div>
                                <div className="ao-card-table-wrapper">
                                  <table className="ao-card-table">
                                    <thead className="ao-card-head">
                                      <tr>
                                        <th>
                                          {t("Expected Monthly Subscription")}
                                        </th>
                                        <th>{t("Next Renewal")}</th>
                                      </tr>
                                    </thead>
                                    <tbody className="ao-card-body">
                                      <tr>
                                        <td>50$</td>
                                        <td>Feburaury 20, 2023</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                sidebar
                                  ? "col-md-6 col-lg-4"
                                  : "col-md-4 col-lg-3"
                              }
                            >
                              <div className="add-on-card">
                                <div className="ao-card-header">
                                  <p>{t("Add-on Manual-routing")}</p>
                                  <button className="cx-btn-2">
                                    {t("Buy")}
                                  </button>
                                </div>
                                <div className="ao-card-table-wrapper">
                                  <table className="ao-card-table">
                                    <thead className="ao-card-head">
                                      <tr>
                                        <th>
                                          {t("Expected Monthly Subscription")}
                                        </th>
                                        <th>{t("Next Renewal")}</th>
                                      </tr>
                                    </thead>
                                    <tbody className="ao-card-body">
                                      <tr>
                                        <td>50$</td>
                                        <td>Feburaury 20, 2023</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                sidebar
                                  ? "col-md-6 col-lg-4"
                                  : "col-md-4 col-lg-3"
                              }
                            >
                              <div className="add-on-card">
                                <div className="ao-card-header">
                                  <p>{t("Add-on Manual-routing")}</p>
                                  <button className="cx-btn-2">
                                    {t("Buy")}
                                  </button>
                                </div>
                                <div className="ao-card-table-wrapper">
                                  <table className="ao-card-table">
                                    <thead className="ao-card-head">
                                      <tr>
                                        <th>
                                          {t("Expected Monthly Subscription")}
                                        </th>
                                        <th>{t("Next Renewal")}</th>
                                      </tr>
                                    </thead>
                                    <tbody className="ao-card-body">
                                      <tr>
                                        <td>50$</td>
                                        <td>Feburaury 20, 2023</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="bottom-notes-section">
                          <div className="note-left">
                            <p>
                              <span className="red-star">* </span>{" "}
                              {t("Subscription cycle")} - First day of every
                              month
                            </p>
                          </div>
                          <div className="total-right">
                            <p> </p>
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="2">
                        <div className="add-on-card-wrapper">
                          <div className="row">
                            <div
                              className={
                                sidebar
                                  ? "col-md-6 col-lg-4"
                                  : "col-md-4 col-lg-3"
                              }
                            >
                              <div className="add-on-card">
                                <div className="ao-card-header">
                                  <p>{t("Add-on image Surveillance")}</p>
                                  <button className="cx-btn-2">
                                    {t("Activate")}
                                  </button>
                                </div>
                                <div className="ao-card-table-wrapper">
                                  <table className="ao-card-table">
                                    <thead className="ao-card-head">
                                      <tr>
                                        <th>{t("Activation Price")}</th>
                                        <th>
                                          {t("Monthly Subscription Price")}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="ao-card-body">
                                      <tr>
                                        <td>Free</td>
                                        <td>Free</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                sidebar
                                  ? "col-md-6 col-lg-4"
                                  : "col-md-4 col-lg-3"
                              }
                            >
                              <div className="add-on-card">
                                <div className="ao-card-header">
                                  <p>{t("Add-on image Surveillance")}</p>
                                  <button className="cx-btn-2">
                                    {t("Activate")}
                                  </button>
                                </div>
                                <div className="ao-card-table-wrapper">
                                  <table className="ao-card-table">
                                    <thead className="ao-card-head">
                                      <tr>
                                        <th>{t("Activation Price")}</th>
                                        <th>
                                          {t("Monthly Subscription Price")}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="ao-card-body">
                                      <tr>
                                        <td>Free</td>
                                        <td>Free</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                sidebar
                                  ? "col-md-6 col-lg-4"
                                  : "col-md-4 col-lg-3"
                              }
                            >
                              <div className="add-on-card">
                                <div className="ao-card-header">
                                  <p>{t("Add-on image Surveillance")}</p>
                                  <button className="cx-btn-2">
                                    {t("Activate")}
                                  </button>
                                </div>
                                <div className="ao-card-table-wrapper">
                                  <table className="ao-card-table">
                                    <thead className="ao-card-head">
                                      <tr>
                                        <th>{t("Activation Price")}</th>
                                        <th>
                                          {t("Monthly Subscription Price")}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="ao-card-body">
                                      <tr>
                                        <td>Free</td>
                                        <td>Free</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                sidebar
                                  ? "col-md-6 col-lg-4"
                                  : "col-md-4 col-lg-3"
                              }
                            >
                              <div className="add-on-card">
                                <div className="ao-card-header">
                                  <p>{t("Add-on image Surveillance")}</p>
                                  <button className="cx-btn-2">
                                    {t("Activate")}
                                  </button>
                                </div>
                                <div className="ao-card-table-wrapper">
                                  <table className="ao-card-table">
                                    <thead className="ao-card-head">
                                      <tr>
                                        <th>{t("Activation Price")}</th>
                                        <th>
                                          {t("Monthly Subscription Price")}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="ao-card-body">
                                      <tr>
                                        <td>Free</td>
                                        <td>Free</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                sidebar
                                  ? "col-md-6 col-lg-4"
                                  : "col-md-4 col-lg-3"
                              }
                            >
                              <div className="add-on-card">
                                <div className="ao-card-header">
                                  <p>{t("Add-on image Surveillance")}</p>
                                  <button className="cx-btn-2">
                                    {t("Activate")}
                                  </button>
                                </div>
                                <div className="ao-card-table-wrapper">
                                  <table className="ao-card-table">
                                    <thead className="ao-card-head">
                                      <tr>
                                        <th>{t("Activation Price")}</th>
                                        <th>
                                          {t("Monthly Subscription Price")}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="ao-card-body">
                                      <tr>
                                        <td>Free</td>
                                        <td>Free</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                sidebar
                                  ? "col-md-6 col-lg-4"
                                  : "col-md-4 col-lg-3"
                              }
                            >
                              <div className="add-on-card">
                                <div className="ao-card-header">
                                  <p>{t("Add-on image Surveillance")}</p>
                                  <button className="cx-btn-2">
                                    {t("Activate")}
                                  </button>
                                </div>
                                <div className="ao-card-table-wrapper">
                                  <table className="ao-card-table">
                                    <thead className="ao-card-head">
                                      <tr>
                                        <th>{t("Activation Price")}</th>
                                        <th>
                                          {t("Monthly Subscription Price")}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="ao-card-body">
                                      <tr>
                                        <td>Free</td>
                                        <td>Free</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                sidebar
                                  ? "col-md-6 col-lg-4"
                                  : "col-md-4 col-lg-3"
                              }
                            >
                              <div className="add-on-card">
                                <div className="ao-card-header">
                                  <p>{t("Add-on image Surveillance")}</p>
                                  <button className="cx-btn-2">
                                    {t("Activate")}
                                  </button>
                                </div>
                                <div className="ao-card-table-wrapper">
                                  <table className="ao-card-table">
                                    <thead className="ao-card-head">
                                      <tr>
                                        <th>{t("Activation Price")}</th>
                                        <th>
                                          {t("Monthly Subscription Price")}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="ao-card-body">
                                      <tr>
                                        <td>Free</td>
                                        <td>Free</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                sidebar
                                  ? "col-md-6 col-lg-4"
                                  : "col-md-4 col-lg-3"
                              }
                            >
                              <div className="add-on-card">
                                <div className="ao-card-header">
                                  <p>{t("Add-on image Surveillance")}</p>
                                  <button className="cx-btn-2">
                                    {t("Activate")}
                                  </button>
                                </div>
                                <div className="ao-card-table-wrapper">
                                  <table className="ao-card-table">
                                    <thead className="ao-card-head">
                                      <tr>
                                        <th>{t("Activation Price")}</th>
                                        <th>
                                          {t("Monthly Subscription Price")}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="ao-card-body">
                                      <tr>
                                        <td>Free</td>
                                        <td>Free</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                sidebar
                                  ? "col-md-6 col-lg-4"
                                  : "col-md-4 col-lg-3"
                              }
                            >
                              <div className="add-on-card">
                                <div className="ao-card-header">
                                  <p>{t("Add-on image Surveillance")}</p>
                                  <button className="cx-btn-2">
                                    {t("Activate")}
                                  </button>
                                </div>
                                <div className="ao-card-table-wrapper">
                                  <table className="ao-card-table">
                                    <thead className="ao-card-head">
                                      <tr>
                                        <th>{t("Activation Price")}</th>
                                        <th>
                                          {t("Monthly Subscription Price")}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="ao-card-body">
                                      <tr>
                                        <td>Free</td>
                                        <td>Free</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                sidebar
                                  ? "col-md-6 col-lg-4"
                                  : "col-md-4 col-lg-3"
                              }
                            >
                              <div className="add-on-card">
                                <div className="ao-card-header">
                                  <p>{t("Add-on image Surveillance")}</p>
                                  <button className="cx-btn-2">
                                    {t("Activate")}
                                  </button>
                                </div>
                                <div className="ao-card-table-wrapper">
                                  <table className="ao-card-table">
                                    <thead className="ao-card-head">
                                      <tr>
                                        <th>{t("Activation Price")}</th>
                                        <th>
                                          {t("Monthly Subscription Price")}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="ao-card-body">
                                      <tr>
                                        <td>Free</td>
                                        <td>Free</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                sidebar
                                  ? "col-md-6 col-lg-4"
                                  : "col-md-4 col-lg-3"
                              }
                            >
                              <div className="add-on-card">
                                <div className="ao-card-header">
                                  <p>{t("Add-on image Surveillance")}</p>
                                  <button className="cx-btn-2">
                                    {t("Activate")}
                                  </button>
                                </div>
                                <div className="ao-card-table-wrapper">
                                  <table className="ao-card-table">
                                    <thead className="ao-card-head">
                                      <tr>
                                        <th>{t("Activation Price")}</th>
                                        <th>
                                          {t("Monthly Subscription Price")}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="ao-card-body">
                                      <tr>
                                        <td>Free</td>
                                        <td>Free</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                sidebar
                                  ? "col-md-6 col-lg-4"
                                  : "col-md-4 col-lg-3"
                              }
                            >
                              <div className="add-on-card">
                                <div className="ao-card-header">
                                  <p>{t("Add-on image Surveillance")}</p>
                                  <button className="cx-btn-2">
                                    {t("Activate")}
                                  </button>
                                </div>
                                <div className="ao-card-table-wrapper">
                                  <table className="ao-card-table">
                                    <thead className="ao-card-head">
                                      <tr>
                                        <th>{t("Activation Price")}</th>
                                        <th>
                                          {t("Monthly Subscription Price")}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="ao-card-body">
                                      <tr>
                                        <td>Free</td>
                                        <td>Free</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                sidebar
                                  ? "col-md-6 col-lg-4"
                                  : "col-md-4 col-lg-3"
                              }
                            >
                              <div className="add-on-card">
                                <div className="ao-card-header">
                                  <p>{t("Add-on image Surveillance")}</p>
                                  <button className="cx-btn-2">
                                    {t("Activate")}
                                  </button>
                                </div>
                                <div className="ao-card-table-wrapper">
                                  <table className="ao-card-table">
                                    <thead className="ao-card-head">
                                      <tr>
                                        <th>{t("Activation Price")}</th>
                                        <th>
                                          {t("Monthly Subscription Price")}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="ao-card-body">
                                      <tr>
                                        <td>Free</td>
                                        <td>Free</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="bottom-notes-section">
                          <div className="note-left">
                            <p>
                              <span className="red-star">* </span> {t("Subscription cycle")} - First day of every month
                            </p>
                          </div>
                          <div className="total-right">
                            <p> </p>
                          </div>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default AddOnSettings;
