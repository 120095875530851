import { React, useContext, useState } from "react";

import DDlogo from "../../assets/images/smallDD.svg";
import Calendar from "../../assets/images/calendar.svg";
import DatePicker from "react-datepicker";
import { Link, useNavigate } from "react-router-dom";
import SideIc from "../../assets/images/sideBar.svg";
import Delete from "../../assets/images/delete.svg";
import export_icon from "../../assets/images/export_icon.svg";
import View from "../../assets/images/Group.svg";
import EditIc from "../../assets/images/ic-edit.svg";
import { Dropdown, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { motion } from "framer-motion";

import Pagenation from "../../sharedComponent/Pagenation";
import { useTranslation } from "react-i18next";
import NextButton from "../../sharedComponent/Nextbutton";
import ExistButton from "../../sharedComponent/ExistButton";
import InfoComponent from "../../sharedComponent/SupportHelp/InfoComponent";

import { AppContext } from "../../context/AppContext";
import PreviousButton from "../../sharedComponent/PreviousButton";

const Immobiliserreports = ({ onClick }) => {
  const { sidebar, setSidebar, Dark, setDark, slider, setSlider } =
    useContext(AppContext);
  const [startDate, setStartDate] = useState(new Date());
  const [deleteModal, setDeleteModal] = useState(false);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    navigate("/Temprature");
  };
  const handleClickPrevious = () => {
    navigate("/Overspeed");
  };
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };

  const renderTooltipForEdit = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {t("Edit")}
    </Tooltip>
  );
  const renderTooltipForView = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {t("View")}
    </Tooltip>
  );
  const renderTooltipForDelete = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {t("Delete")}
    </Tooltip>
  );

  return (
    <>
      <motion.div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }}
      >
        <div id="cx-wrapper" className="Vehicle_Accident">
          <div className="displayFlexInp">
            <div className="innerSelectBox weekCounter selectForm form_input_main">
              <Form.Select
                required
                as="select"
                type="select"
                name="Speed_limit"
                className="innerCust"
              >
                <option value="">Driver Name</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="150">150</option>
              </Form.Select>
            </div>
            <div className="innerSelectBox weekCounter selectForm form_input_main">
              <Form.Select
                required
                as="select"
                type="select"
                name="Speed_limit"
                className="innerCust"
              >
                <option value="">Trip Name</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="150">150</option>
              </Form.Select>
            </div>
            <div className="innerSelectBox weekCounter selectForm form_input_main">
              <Form.Control
                required
                type="text"
                placeholder="Location, Description, Expenses..."
                className="innerCust"
              />
            </div>
            <div className="innerSelectBox weekCounter selectForm form_input_main datepicker-main">
              <DatePicker
                selected={startDate}
                // placeholderText="Select Date"
                onChange={(date) => setStartDate(date)}
                className="form-control innerCust"
              />
              <img src={Calendar} className="calendarLogo" alt="" />
            </div>
            <div className="innerSelectBox weekCounter selectForm form_input_main">
              {/* <Link to="/AddVehicleAccident" className="AddAccidentLink">
                <button className="innerCust">+ {t("Add Accident")}</button>
              </Link> */}
            </div>
            <div className="headerDivIc form_input_main">
              <Link to="#">
                <img src={SideIc} alt="" />
              </Link>
            </div>
            {/* <div className="headerDivIc form_input_main">
              <Link to="#">
                <img src={export_icon} alt="" />
              </Link>
            </div> */}

            <div style={{ marginRight: "10px" }} />
            <div className="right-export-btn-section-wrapper">
              <div className="c-pointer me-2"></div>

              <div className="md_dropdown">
                <Dropdown>
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    className="export-toggle"
                  >
                    <p className="Export_Text">{t("Export")}</p>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <Link
                        // onClick={() => getExportChat()}
                        className="d-block"
                      >
                        {t("PDF")}
                      </Link>
                    </Dropdown.Item>

                    <Dropdown.Item>
                      <Link
                        // onClick={() => downLoadExcelSheet()}
                        className="d-block"
                      >
                        {t("Excel")}
                      </Link>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
          {/* Top inputs for instatnt search */}

          {/* Vehicle table */}
          <div className="main-master-wrapper">
            <div id="scroll_insideThe_Padding_tabel">
              <div className="VA_table">
                <table className="table tableAdmin">
                  <thead className="tableHead">
                    <tr>
                      <th>{t("Sr.no")}</th>

                      <th>{t("Vehicle")}</th>
                      <th>{t("Immobilisation Status")}</th>
                      <th>{t("Enabled Date")}</th>
                      <th>{t("Enabled Time ")}</th>
                      <th>{t("Disabled Date")}</th>
                      <th>{t("Disabled Time")}</th>
                    </tr>
                  </thead>
                  <tbody className="tableBody">
                    <tr>
                      <td>1</td>
                      <td>SUFAIDULLAHMAQBALI</td>
                      <td>284598</td>
                      <td>june-2024</td>
                      <td>2024-06-12</td>
                      <td>0.03</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>SUFAIDULLAHMAQBALI</td>
                      <td>284598</td>
                      <td>june-2024</td>
                      <td>2024-06-12</td>
                      <td>0.03</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>SUFAIDULLAHMAQBALI</td>
                      <td>284598</td>
                      <td>june-2024</td>
                      <td>2024-06-12</td>
                      <td>0.03</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>SUFAIDULLAHMAQBALI</td>
                      <td>284598</td>
                      <td>june-2024</td>
                      <td>2024-06-12</td>
                      <td>0.03</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>SUFAIDULLAHMAQBALI</td>
                      <td>284598</td>
                      <td>june-2024</td>
                      <td>2024-06-12</td>
                      <td>0.03</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>SUFAIDULLAHMAQBALI</td>
                      <td>284598</td>
                      <td>june-2024</td>
                      <td>2024-06-12</td>
                      <td>0.03</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>SUFAIDULLAHMAQBALI</td>
                      <td>284598</td>
                      <td>june-2024</td>
                      <td>2024-06-12</td>
                      <td>0.03</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>SUFAIDULLAHMAQBALI</td>
                      <td>284598</td>
                      <td>june-2024</td>
                      <td>2024-06-12</td>
                      <td>0.03</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>SUFAIDULLAHMAQBALI</td>
                      <td>284598</td>
                      <td>june-2024</td>
                      <td>2024-06-12</td>
                      <td>0.03</td>
                      <td>0</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <Pagenation />
          </div>
          {slider ? <ExistButton onClose={() => setSlider(false)} /> : null}
          {!slider && <PreviousButton onBack={handleClickPrevious} />}
          {!slider && <NextButton onClick={handleClick} />}
        </div>
      </motion.div>

      {/* Delete Modal Start */}
      <Modal
        show={deleteModal}
        onHide={() => setDeleteModal(false)}
        centered
        className="common-model"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Delete Vehicle Accident")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("Are you sure you want to Delete this Vehicle Accident")} ?
        </Modal.Body>
        <Modal.Footer className="pop-up-modal-footer">
          <div class="btn-wrapper">
            <button className="cx-btn-1" onClick={() => setDeleteModal(false)}>
              {t("Cancel")}
            </button>
            <button className="cx-btn-2" onClick={() => setDeleteModal(false)}>
              {t("Yes")}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* Delete Modal End */}
    </>
  );
};

export default Immobiliserreports;
