import React, { useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import profile from "../../assets/images/Update-profile.svg";
import camera from "../../assets/images/ic-camera.svg";
import { Dropdown } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import Calendar from "../../assets/images/calendar.svg";
import DatePicker from "react-datepicker";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

const EditProfile = () => {
  const [startDate, setStartDate] = useState(new Date());
  const { sidebar, setSidebar, Dark, setDark } = useContext(AppContext);
  const [validated, setValidated] = useState(false);
  const { t, i18n } = useTranslation();

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };

  return (
    <motion.div
      className={sidebar ? "taskMain " : "cx-active taskMain"}
      id="cx-main"
      variants={aninations}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.1 }}
    >
      <div id="cx-wrapper">
        <div
          className="main-dashboard-wrapper CustomerProfile"
          id="EditProfile_Reponsive"
        >
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <div className="CustomerProfile-head">
              <label
                htmlFor="uploadPic"
                className="porile-img d-block c-pointer"
              >
                <Form.Control
                  required
                  type="file"
                  id="uploadPic"
                  className="d-none"
                />
                <Form.Control.Feedback type="invalid">
                  Please Choose Picture.
                </Form.Control.Feedback>

                <img src={profile} alt="porfile" />
                <img src={camera} alt="" className="cameraimg" />
              </label>
            </div>
            <div className="information-card row">
              <div className="col-md-6 form_input_main">
                <Form.Label className="common-labels">{t("First Name")}</Form.Label>
                <Form.Control required type="text" placeholder="Mark" />
                <Form.Control.Feedback type="invalid">
                  Please Enter First Name.
                </Form.Control.Feedback>
              </div>

              <div className="col-md-6 form_input_main">
                <Form.Label className="common-labels">{t("Gender")}</Form.Label>
                <Form.Select aria-label="Default select example">
                  <option>Male / Female</option>
                  <option value="1">Male</option>
                  <option value="2">Female</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Please Select Gender.
                </Form.Control.Feedback>
              </div>
              <div className="col-md-6 form_input_main">
                <Form.Label className="common-labels">{t("Last Name")}</Form.Label>
                <Form.Control type="text" placeholder="Wood" />
                <Form.Control.Feedback type="invalid">
                  Please Enter Last Name.
                </Form.Control.Feedback>
              </div>
              <div className="col-md-6 form_input_main">
                <Form.Label className="common-labels">{t("Nationality")}</Form.Label>
                <Form.Select aria-label="Default select example">
                  <option>India</option>
                  <option value="1">USA</option>
                  <option value="2">UAE</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Please Select Gender.
                </Form.Control.Feedback>
              </div>
              <div className="col-md-6 form_input_main">
                <Form.Label className="common-labels">{t("Email")}</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Markwood16@gmail.com"
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Valid mail id.
                </Form.Control.Feedback>
              </div>
              <div className="col-md-6 form_input_main">
                <Form.Label className="common-labels">{t("City")}</Form.Label>
                <Form.Control type="text" placeholder="Pune" />
                <Form.Control.Feedback type="invalid">
                  Please Enter City Name.
                </Form.Control.Feedback>
              </div>
              <div className="col-md-6 form_input_main">
                <Form.Label className="common-labels">
                  {t("Contact Number")}
                </Form.Label>
                <Form.Control required type="text" placeholder="9876543102" />
                <Form.Control.Feedback type="invalid">
                  Please Enter Contact Number.
                </Form.Control.Feedback>
              </div>
              <div className="col-md-6 form_input_main">
                <Form.Label className="common-labels">{t("Date Of Birth")}</Form.Label>
                <div className="innerSelectBox weekCounter datepicker-main">
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    className="form-control"
                  />
                  <img src={Calendar} className="calendarLogo" alt="" />
                </div>
                <Form.Control.Feedback type="invalid">
                  Please Enter D.O.B
                </Form.Control.Feedback>
              </div>
              <div className="col-lg-6 form_input_main">
                <Form.Label className="common-labels">{t("Address")}</Form.Label>
                <Form.Control as="textarea" rows={5} placeholder="Address" />
                <Form.Control.Feedback type="invalid">
                  Please Enter Address.
                </Form.Control.Feedback>
              </div>
              <div className="col-md-12 col-lg-6  form_input_main">
                <div className="displayBoth">
                  <div className="innerStartDate">
                    <Form.Label className="common-labels">
                      {t("Joining Date")}
                    </Form.Label>
                    <div className="innerSelectBox weekCounter datepicker-main form_input_main">
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        className="form-control"
                      />
                      <img src={Calendar} className="calendarLogo" alt="" />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      Please Enter Joining Date
                    </Form.Control.Feedback>
                  </div>
                  <div className="innerCnum">
                    <div className="form_input_main">
                      <Form.Label className="common-labels">
                        {t("Contact Number")}
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="9876543102"
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Contact Number.
                      </Form.Control.Feedback>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="btns-main btn-wrapper">
              {/* <Link to="#"> */}
              <button type="" className="cx-btn-1">
                {t("Cancel")}
              </button>
              {/* </Link> */}
              {/* <Link to="#"> */}
              <button type="submit" className="cx-btn-2">
                {t("Update")}
              </button>
              {/* </Link> */}
            </div>
          </Form>
        </div>
      </div>
    </motion.div>
  );
};

export default EditProfile;
