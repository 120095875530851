import { React, useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import delete_icon from "../../assets/images/delete.svg";
import { Tab, Tabs, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import Calendar from "../../assets/images/calendar.svg";
import import_icon from "../../assets/images/import_icon.svg";
import export_icon from "../../assets/images/export_icon.svg";

import edit_icon from "../../assets/images/ic-edit.svg";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { motion } from "framer-motion";
import Pagenation from "../../sharedComponent/Pagenation";
import ReactSelect from "../../sharedComponent/ReactSelect";
import { useTranslation } from "react-i18next";

const LogChanges = () => {
  const { sidebar, setSidebar, Dark, setDark } = useContext(AppContext);
  const [show, setShow] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [edit, setEdit] = useState(false);
  const [create, setCreate] = useState(false);
  const { t, i18n } = useTranslation();

  const handleCloseCreate = () => setCreate(false);
  const handleShowCreate = () => setCreate(true);
  const handleCloseEdit = () => setEdit(false);
  const handleShowEdit = () => setEdit(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  const renderTooltipForEdit = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Edit
    </Tooltip>
  );
  const renderTooltipForDelete = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Delete
    </Tooltip>
  );
  return (
    <>
      <motion.div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }}
      >
        <div id="cx-wrapper" className="Holidays_Main">
          <div className="holidays-filter-wrapper">
            <div className="left-wrapper">
              <div className="row g-0 d-flex justify-content-between">
                <div className="col-md-6">
                  <div className="search-input">
                    <Form className="">
                      <Form.Control
                        type="search"
                        placeholder="Search username ..."
                        aria-label="Search"
                      />
                    </Form>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="innerSelectBox weekCounter datepicker-main">
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      className="form-control"
                    />
                    <img src={Calendar} className="calendarLogo" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="right-wrapper">
              <Link to="#" className="import-icon">
                <img src={import_icon} alt="" />
              </Link>
              <Link to="#" className="export-icon">
                <img src={export_icon} alt="" />
              </Link>
            </div>
          </div>
          <div className="main-master-wrapper mt-3">
            <div id="scroll_insideThe_Padding_tabel">
              <table className="holiday-table">
                <thead className="ht-head logchange">
                  <tr>
                    <td>{t("Sr.No.")}</td>
                    <td>{t("User Name")}</td>
                    <td>{t("Date & Time")}</td>
                    <td>{t("Log Description")}</td>
                    <td>{t("Action")}</td>
                  </tr>
                </thead>
                <tbody className="ht-body">
                  <tr className="table-row-custom">
                    <td>1</td>
                    <td>Fleet Manager</td>
                    <td>01-01-2023, 04:58 AM</td>
                    <td>
                      <span className="blackcolor">John Doe </span> changed the
                      fleet settings
                    </td>
                    <td className="Viewchanges">View changes</td>
                  </tr>
                  <tr className="table-row-custom">
                    <td>2</td>
                    <td>Driver</td>
                    <td>01-01-2023, 04:58 AM</td>
                    <td>
                      <span className="blackcolor">Rohit Sharma </span>added new
                      driver.
                    </td>
                    <td className="Viewchanges">View changes</td>
                  </tr>
                  <tr className="table-row-custom">
                    <td>3</td>
                    <td>Accountant</td>
                    <td>01-01-2023, 04:58 AM</td>
                    <td>
                      <span className="blackcolor">Kriti Sanon </span>made a
                      payment.
                    </td>
                    <td className="Viewchanges">View changes</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <Pagenation />
          </div>
          <Modal
            show={show}
            onHide={handleClose}
            centered
            className="common-model"
          >
            <Modal.Header closeButton>
              <Modal.Title>{t("Delete User Role")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{t("Are you sure you want to delete this Role")} ?</Modal.Body>
            <Modal.Footer className="pop-up-modal-footer">
              <button className="cx-btn-1" onClick={handleClose}>
                {t("Close")}
              </button>
              <button className="cx-btn-2" onClick={handleClose}>
                {t("Yes")}
              </button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={edit}
            onHide={handleCloseEdit}
            centered
            className="common-model"
          >
            <Modal.Header closeButton>
              <Modal.Title>{t("Edit Role")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Label className="common-labels">
                {t("Role")} <span className="red-star">*</span>
              </Form.Label>
              <ReactSelect />
            </Modal.Body>
            <Modal.Footer className="pop-up-modal-footer">
              <button className="cx-btn-1" onClick={handleCloseEdit}>
                {t("Close")}
              </button>
              <button className="cx-btn-2" onClick={handleCloseEdit}>
                {t("Yes")}
              </button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={create}
            onHide={handleCloseCreate}
            centered
            className="common-model"
          >
            <Modal.Header closeButton>
              <Modal.Title>{t("Create Role")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Label className="common-labels">
                {t("Role")} <span className="red-star">*</span>
              </Form.Label>
              <ReactSelect />
            </Modal.Body>
            <Modal.Footer className="pop-up-modal-footer">
              <button className="cx-btn-1" onClick={handleCloseCreate}>
                {t("Close")}
              </button>
              <button className="cx-btn-2" onClick={handleCloseCreate}>
                {t("Yes")}
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      </motion.div>
    </>
  );
};

export default LogChanges;
