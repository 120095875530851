import React, { useContext, useRef } from "react";
import { AppContext } from "../../context/AppContext";
import Nav from "react-bootstrap/Nav";
import profile_img from "../../assets/images/Chating_images/profile_img.svg";
import ic_online from "../../assets/images/Chating_images/ic_online.svg";
import ic_minus_red from "../../assets/images/Chating_images/ic_minus_red.svg";
import ic_come_back from "../../assets/images/Chating_images/ic_come_back.svg";
import ic_on_call from "../../assets/images/Chating_images/ic_on_call.svg";
import ic_offline from "../../assets/images/Chating_images/ic_offline.svg";
import { Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import ChatBox from "./ChatBox";
import { useTranslation } from "react-i18next";

const MyMessages = () => {
  const { sidebar, setSidebar, Dark, setDark } = useContext(AppContext);
  const { t, i18n } = useTranslation();

  return (
    <>
      <main
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div id="cx-wrapper" className="mymessage-main-wrapper">
          <div className="compose-message-wrapper">
            <Link
              to="/ComposeMessage"
              className="cx-btn-3 form_input_main"
              variant="primary"
            >
              + {t("Compose Message")}
            </Link>
          </div>
          <div className="main-chat">
            <Tab.Container id="left-tabs-example" defaultActiveKey="1">
              <div className="row d-flex flex-wrap justify-content-around">
                <div className="col-lg-3 col-md-3 left-chat-section">
                  <div className="left-heading-chat">
                    <div className="left-head">
                      <input
                        type="search"
                        className="form-control"
                        placeholder="Search"
                      />
                    </div>
                  </div>
                  <div className="pinned-section">
                    <div className="left">
                      <label htmlFor="">{t("Messages")}</label>
                    </div>
                  </div>
                  <div className="chat-user-lists">
                    <Nav variant="pills" className="flex-column">
                      <Nav.Item>
                        <Nav.Link eventKey="1">
                          <div className="user-chat-tab">
                            <div className="left-profile-pic">
                              <Link to="">
                                <img src={profile_img} alt="" />
                              </Link>
                              <span className="indication-img">
                                <img src={ic_online} alt="" />
                              </span>
                            </div>

                            <div className="right-name-date ">
                              <div className="top-section ">
                                <div className="name ">
                                  <label htmlFor="">John Doe</label>
                                </div>
                                <div></div>
                                <div className="date">
                                  <label htmlFor="">11/1</label>
                                </div>
                              </div>

                              <div className="bottom-section ">
                                <label htmlFor="">
                                  You: Hello sir, Good Morning
                                </label>
                              </div>
                            </div>
                          </div>
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link eventKey="2">
                          <div className="user-chat-tab">
                            <div className="left-profile-pic">
                              <Link to="">
                                <img src={profile_img} alt="" />
                              </Link>
                              <span className="indication-img">
                                <img src={ic_minus_red} alt="" />
                              </span>
                            </div>

                            <div className="right-name-date ">
                              <div className="top-section ">
                                <div className="name ">
                                  <label htmlFor="">John Doe</label>
                                </div>
                                <div></div>
                                <div className="date">
                                  <label htmlFor="">11/1</label>
                                </div>
                              </div>

                              <div className="bottom-section ">
                                <label htmlFor="">
                                  You: Hello sir, Good Morning
                                </label>
                              </div>
                            </div>
                          </div>
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link eventKey="3">
                          <div className="user-chat-tab">
                            <div className="left-profile-pic">
                              <Link to="">
                                <img src={profile_img} alt="" />
                              </Link>
                              <span className="indication-img">
                                <img src={ic_on_call} alt="" />
                              </span>
                            </div>

                            <div className="right-name-date ">
                              <div className="top-section ">
                                <div className="name ">
                                  <label htmlFor="">John Doe</label>
                                </div>
                                <div></div>
                                <div className="date">
                                  <label htmlFor="">11/1</label>
                                </div>
                              </div>

                              <div className="bottom-section ">
                                <label htmlFor="">
                                  You: Hello sir, Good Morning
                                </label>
                              </div>
                            </div>
                          </div>
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link eventKey="4">
                          <div className="user-chat-tab">
                            <div className="left-profile-pic">
                              <Link to="">
                                <img src={profile_img} alt="" />
                              </Link>
                              <span className="indication-img">
                                <img src={ic_come_back} alt="" />
                              </span>
                            </div>

                            <div className="right-name-date ">
                              <div className="top-section ">
                                <div className="name ">
                                  <label htmlFor="">John Doe</label>
                                </div>
                                <div></div>
                                <div className="date">
                                  <label htmlFor="">11/1</label>
                                </div>
                              </div>

                              <div className="bottom-section ">
                                <label htmlFor="">
                                  You: Hello sir, Good Morning
                                </label>
                              </div>
                            </div>
                          </div>
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link eventKey="5">
                          <div className="user-chat-tab">
                            <div className="left-profile-pic">
                              <Link to="">
                                <img src={profile_img} alt="" />
                              </Link>
                              <span className="indication-img">
                                <img src={ic_offline} alt="" />
                              </span>
                            </div>

                            <div className="right-name-date ">
                              <div className="top-section ">
                                <div className="name ">
                                  <label htmlFor="">John Doe</label>
                                </div>
                                <div></div>
                                <div className="date">
                                  <label htmlFor="">11/1</label>
                                </div>
                              </div>

                              <div className="bottom-section ">
                                <label htmlFor="">
                                  You: Hello sir, Good Morning
                                </label>
                              </div>
                            </div>
                          </div>
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link eventKey="6">
                          <div className="user-chat-tab">
                            <div className="left-profile-pic">
                              <Link to="">
                                <img src={profile_img} alt="" />
                              </Link>
                              <span className="indication-img">
                                <img src={ic_online} alt="" />
                              </span>
                            </div>

                            <div className="right-name-date ">
                              <div className="top-section ">
                                <div className="name ">
                                  <label htmlFor="">John Doe</label>
                                </div>
                                <div></div>
                                <div className="date">
                                  <label htmlFor="">11/1</label>
                                </div>
                              </div>

                              <div className="bottom-section ">
                                <label htmlFor="">
                                  You: Hello sir, Good Morning
                                </label>
                              </div>
                            </div>
                          </div>
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link eventKey="7">
                          <div className="user-chat-tab">
                            <div className="left-profile-pic">
                              <Link to="">
                                <img src={profile_img} alt="" />
                              </Link>
                              <span className="indication-img">
                                <img src={ic_minus_red} alt="" />
                              </span>
                            </div>

                            <div className="right-name-date ">
                              <div className="top-section ">
                                <div className="name ">
                                  <label htmlFor="">John Doe</label>
                                </div>
                                <div></div>
                                <div className="date">
                                  <label htmlFor="">11/1</label>
                                </div>
                              </div>

                              <div className="bottom-section ">
                                <label htmlFor="">
                                  You: Hello sir, Good Morning
                                </label>
                              </div>
                            </div>
                          </div>
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link eventKey="8">
                          <div className="user-chat-tab">
                            <div className="left-profile-pic">
                              <Link to="">
                                <img src={profile_img} alt="" />
                              </Link>
                              <span className="indication-img">
                                <img src={ic_on_call} alt="" />
                              </span>
                            </div>

                            <div className="right-name-date ">
                              <div className="top-section ">
                                <div className="name ">
                                  <label htmlFor="">John Doe</label>
                                </div>
                                <div></div>
                                <div className="date">
                                  <label htmlFor="">11/1</label>
                                </div>
                              </div>

                              <div className="bottom-section ">
                                <label htmlFor="">
                                  You: Hello sir, Good Morning
                                </label>
                              </div>
                            </div>
                          </div>
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link eventKey="9">
                          <div className="user-chat-tab">
                            <div className="left-profile-pic">
                              <Link to="">
                                <img src={profile_img} alt="" />
                              </Link>
                              <span className="indication-img">
                                <img src={ic_come_back} alt="" />
                              </span>
                            </div>

                            <div className="right-name-date ">
                              <div className="top-section ">
                                <div className="name ">
                                  <label htmlFor="">John Doe</label>
                                </div>
                                <div></div>
                                <div className="date">
                                  <label htmlFor="">11/1</label>
                                </div>
                              </div>

                              <div className="bottom-section ">
                                <label htmlFor="">
                                  You: Hello sir, Good Morning
                                </label>
                              </div>
                            </div>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                </div>
                <ChatBox />
              </div>
            </Tab.Container>
          </div>
        </div>
      </main>
    </>
  );
};

export default MyMessages;
