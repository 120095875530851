import { React, useContext, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import share from "../../../assets/images/XMLID_1022_.svg";
import Delete from "../../../assets/images/delete.svg";
import View from "../../../assets/images/Group.svg";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import Pagenation from "../../../sharedComponent/Pagenation";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const Administrator = () => {
  const { sidebar, setSidebar, Dark, setDark } = useContext(AppContext);
  const [deleteModal, setDeleteModal] = useState(false);
  const { t, i18n } = useTranslation();

  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };

  const renderTooltipForView = (props) => (
    <Tooltip id="button-tooltip" {...props}>
       {t("View")}
    </Tooltip>
  );
  const renderTooltipForDelete = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {t("Delete")}
    </Tooltip>
  );
  return (
    <>
      <motion.div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }}
      >
        <div id="cx-wrapper" className="Administrator">
          <div className="transHeadingIcons form_input_main">
            <button className="addminBtn"> + {t("Add Administrator")}</button>
            <div className="iconBg">
              <img src={share} alt="" />
            </div>
          </div>
          <div className="main-master-wrapper">
            <table className="table tableAdmin">
              <thead className="tableHead">
                <tr>
                  <th>{t("Sr.no")}</th>
                  <th>{t("Image")}</th>
                  <th>{t("Name")}</th>
                  <th>{t("Email")}</th>
                  <th>{t("Contact Number")}</th>
                  <th>{t("Action")}</th>
                </tr>
              </thead>
              <tbody className="tableBody">
                <tr>
                  <td>1</td>
                  <td>National Day</td>
                  <td>Mark Woods</td>
                  <td>markwoods@gmail.com</td>
                  <td>9999999999</td>
                  <td>
                    <div className="innerFlex d-flex">
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipForView}
                      >
                        <Link to="/view">
                          <div className="inconsIn me-3">
                            <img src={View} alt="" />
                          </div>
                        </Link>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipForDelete}
                      >
                        <Link to="#" onClick={() => setDeleteModal(true)}>
                          <div className="inconsIn">
                            <img src={Delete} alt="" />
                          </div>
                        </Link>
                      </OverlayTrigger>
                   
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>National Day</td>
                  <td>Mark Woods</td>
                  <td>markwoods@gmail.com</td>
                  <td>9999999999</td>
                  <td>
                    <div className="innerFlex d-flex">
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipForView}
                      >
                        <Link to="/view">
                          <div className="inconsIn me-3">
                            <img src={View} alt="" />
                          </div>
                        </Link>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipForDelete}
                      >
                        <Link to="#" onClick={() => setDeleteModal(true)}>
                          <div className="inconsIn">
                            <img src={Delete} alt="" />
                          </div>
                        </Link>
                      </OverlayTrigger>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </motion.div>
      <Modal
        show={deleteModal}
        onHide={() => setDeleteModal(false)}
        centered
        className="common-model"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Delete Administrator")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("Are you sure you want to Delete this Administrator")} ?
        </Modal.Body>
        <Modal.Footer className="pop-up-modal-footer">
          <div class="btn-wrapper">
            <button className="cx-btn-1" onClick={() => setDeleteModal(false)}>
              {t("Cancel")}
            </button>
            <button className="cx-btn-2" onClick={() => setDeleteModal(false)}>
              {t("Yes")}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default Administrator;
