import { React, useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import delete_icon from "../../assets/images/delete.svg";
import { Tab, Tabs, Form, Tooltip, OverlayTrigger } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
import import_icon from "../../assets/images/import_icon.svg";
import export_icon from "../../assets/images/export_icon.svg";
import edit_icon from "../../assets/images/ic-edit.svg";
import { Button, Dropdown, Modal } from "react-bootstrap";
import Calendar from "../../assets/images/calendar.svg";
import DatePicker from "react-datepicker";
import { motion } from "framer-motion";
import Pagenation from "../../sharedComponent/Pagenation";
import { useTranslation } from "react-i18next";

const Vacations = () => {
  const { sidebar, setSidebar, Dark, setDark } = useContext(AppContext);
  const [show, setShow] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const { t, i18n } = useTranslation();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };

  const renderTooltipForEdit = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {t("Edit")}
    </Tooltip>
  );
  const renderTooltipForDelete = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {t("Delete")}
    </Tooltip>
  );
  return (
    <>
      <motion.div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }}
      >
        <div id="cx-wrapper" className="Vacations_Main">
          <div className="holidays-filter-wrapper">
            <div className="left-wrapper">
              <div className="row g-0 d-flex justify-content-between">
                <div className="col-md-4">
                  <div className="search-input">
                    <Form className="">
                      <Form.Control
                        type="search"
                        placeholder="Holiday Name"
                        aria-label="Search"
                      />
                    </Form>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="date-input">
                    {/* <Form.Control
                      type="date"
                      placeholder="Date"
                      aria-label="date"
                    /> */}
                    <div className="innerSelectBox weekCounter datepicker-main">
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        className="form-control"
                      />
                      <img src={Calendar} className="calendarLogo" alt="" />
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="date-input">
                    <div className="innerSelectBox weekCounter datepicker-main">
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        className="form-control"
                      />
                      <img src={Calendar} className="calendarLogo" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="right-wrapper">
              <Link
                to="/AddVacation"
                className="add-holiday-btn"
                variant="primary"
              >
                + {t("Add Vacation")}
              </Link>
              <Link to="#" className="import-icon">
                <img src={import_icon} alt="" />
              </Link>
              <Link to="#" className="export-icon">
                <img src={export_icon} alt="" />
              </Link>
            </div>
          </div>

          <div className="main-master-wrapper mt-3">
            <div id="scroll_insideThe_Padding_tabel">
              <table className="holiday-table">
                <thead className="ht-head">
                  <tr>
                    <td>{t("Sr.No.")}</td>
                    <td>{t("Vacation Name")}</td>
                    <td>{t("Start Date")}</td>
                    <td>{t("End Date")}</td>
                    <td>{t("Description")}</td>
                    <td>{t("Status")}</td>
                    <td>{t("Action")}</td>
                  </tr>
                </thead>
                <tbody className="ht-body">
                  <tr className="table-row-custom">
                    <td>1</td>
                    <td>National Day</td>
                    <td>01-01-2023</td>
                    <td>01-01-2023</td>
                    <td>This is the National Day</td>
                    <td>Inactive</td>
                    <td className="d-flex">
                      <OverlayTrigger
                        placement="left"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipForEdit}
                      >
                        <Link to="/EditVacation">
                          <div className="inconsIn">
                            <img src={edit_icon} alt="" />
                          </div>
                        </Link>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipForDelete}
                      >
                        <Link to="#" onClick={handleShow}>
                          <div className="inconsIn">
                            <img src={delete_icon} alt="" />
                          </div>
                        </Link>
                      </OverlayTrigger>
                    </td>
                  </tr>
                  <tr className="table-row-custom">
                    <td>2</td>
                    <td>National Day</td>
                    <td>01-01-2023</td>
                    <td>01-01-2023</td>
                    <td>This is the National Day</td>
                    <td>Inactive</td>
                    <td className="d-flex">
                      <OverlayTrigger
                        placement="left"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipForEdit}
                      >
                        <Link to="/EditVacation">
                          <div className="inconsIn">
                            <img src={edit_icon} alt="" />
                          </div>
                        </Link>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipForDelete}
                      >
                        <Link to="#" onClick={handleShow}>
                          <div className="inconsIn">
                            <img src={delete_icon} alt="" />
                          </div>
                        </Link>
                      </OverlayTrigger>
                    </td>
                  </tr>
                  <tr className="table-row-custom">
                    <td>3</td>
                    <td>National Day</td>
                    <td>01-01-2023</td>
                    <td>01-01-2023</td>
                    <td>This is the National Day</td>
                    <td>Inactive</td>
                    <td className="d-flex">
                      <OverlayTrigger
                        placement="left"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipForEdit}
                      >
                        <Link to="/EditVacation">
                          <div className="inconsIn">
                            <img src={edit_icon} alt="" />
                          </div>
                        </Link>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipForDelete}
                      >
                        <Link to="#" onClick={handleShow}>
                          <div className="inconsIn">
                            <img src={delete_icon} alt="" />
                          </div>
                        </Link>
                      </OverlayTrigger>
                    </td>
                  </tr>
                  <tr className="table-row-custom">
                    <td>4</td>
                    <td>National Day</td>
                    <td>01-01-2023</td>
                    <td>01-01-2023</td>
                    <td>This is the National Day</td>
                    <td>Inactive</td>
                    <td className="d-flex">
                      <OverlayTrigger
                        placement="left"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipForEdit}
                      >
                        <Link to="/EditVacation">
                          <div className="inconsIn">
                            <img src={edit_icon} alt="" />
                          </div>
                        </Link>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipForDelete}
                      >
                        <Link to="#" onClick={handleShow}>
                          <div className="inconsIn">
                            <img src={delete_icon} alt="" />
                          </div>
                        </Link>
                      </OverlayTrigger>
                    </td>
                  </tr>
                  <tr className="table-row-custom">
                    <td>5</td>
                    <td>National Day</td>
                    <td>01-01-2023</td>
                    <td>01-01-2023</td>
                    <td>This is the National Day</td>
                    <td>Inactive</td>
                    <td className="d-flex">
                      <OverlayTrigger
                        placement="left"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipForEdit}
                      >
                        <Link to="/EditVacation">
                          <div className="inconsIn">
                            <img src={edit_icon} alt="" />
                          </div>
                        </Link>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipForDelete}
                      >
                        <Link to="#" onClick={handleShow}>
                          <div className="inconsIn">
                            <img src={delete_icon} alt="" />
                          </div>
                        </Link>
                      </OverlayTrigger>
                    </td>
                  </tr>
                  <tr className="table-row-custom">
                    <td>6</td>
                    <td>National Day</td>
                    <td>01-01-2023</td>
                    <td>01-01-2023</td>
                    <td>This is the National Day</td>
                    <td>Inactive</td>
                    <td className="d-flex">
                      <OverlayTrigger
                        placement="left"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipForEdit}
                      >
                        <Link to="/EditVacation">
                          <div className="inconsIn">
                            <img src={edit_icon} alt="" />
                          </div>
                        </Link>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipForDelete}
                      >
                        <Link to="#" onClick={handleShow}>
                          <div className="inconsIn">
                            <img src={delete_icon} alt="" />
                          </div>
                        </Link>
                      </OverlayTrigger>
                    </td>
                  </tr>
                  <tr className="table-row-custom">
                    <td>7</td>
                    <td>National Day</td>
                    <td>01-01-2023</td>
                    <td>01-01-2023</td>
                    <td>This is the National Day</td>
                    <td>Inactive</td>
                    <td className="d-flex">
                      <OverlayTrigger
                        placement="left"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipForEdit}
                      >
                        <Link to="/EditVacation">
                          <div className="inconsIn">
                            <img src={edit_icon} alt="" />
                          </div>
                        </Link>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipForDelete}
                      >
                        <Link to="#" onClick={handleShow}>
                          <div className="inconsIn">
                            <img src={delete_icon} alt="" />
                          </div>
                        </Link>
                      </OverlayTrigger>
                    </td>
                  </tr>
                  <tr className="table-row-custom">
                    <td>8</td>
                    <td>National Day</td>
                    <td>01-01-2023</td>
                    <td>01-01-2023</td>
                    <td>This is the National Day</td>
                    <td>Inactive</td>
                    <td className="d-flex">
                      <OverlayTrigger
                        placement="left"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipForEdit}
                      >
                        <Link to="/EditVacation">
                          <div className="inconsIn">
                            <img src={edit_icon} alt="" />
                          </div>
                        </Link>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipForDelete}
                      >
                        <Link to="#" onClick={handleShow}>
                          <div className="inconsIn">
                            <img src={delete_icon} alt="" />
                          </div>
                        </Link>
                      </OverlayTrigger>
                    </td>
                  </tr>
                  <tr className="table-row-custom">
                    <td>9</td>
                    <td>National Day</td>
                    <td>01-01-2023</td>
                    <td>01-01-2023</td>
                    <td>This is the National Day</td>
                    <td>Inactive</td>
                    <td className="d-flex">
                      <OverlayTrigger
                        placement="left"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipForEdit}
                      >
                        <Link to="/EditVacation">
                          <div className="inconsIn">
                            <img src={edit_icon} alt="" />
                          </div>
                        </Link>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipForDelete}
                      >
                        <Link to="#" onClick={handleShow}>
                          <div className="inconsIn">
                            <img src={delete_icon} alt="" />
                          </div>
                        </Link>
                      </OverlayTrigger>
                    </td>
                  </tr>
                  <tr className="table-row-custom">
                    <td>10</td>
                    <td>National Day</td>
                    <td>01-01-2023</td>
                    <td>01-01-2023</td>
                    <td>This is the National Day</td>
                    <td>Inactive</td>
                    <td className="d-flex">
                      <OverlayTrigger
                        placement="left"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipForEdit}
                      >
                        <Link to="/EditVacation">
                          <div className="inconsIn">
                            <img src={edit_icon} alt="" />
                          </div>
                        </Link>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipForDelete}
                      >
                        <Link to="#" onClick={handleShow}>
                          <div className="inconsIn">
                            <img src={delete_icon} alt="" />
                          </div>
                        </Link>
                      </OverlayTrigger>
                    </td>
                  </tr>
                  <tr className="table-row-custom">
                    <td>11</td>
                    <td>National Day</td>
                    <td>01-01-2023</td>
                    <td>01-01-2023</td>
                    <td>This is the National Day</td>
                    <td>Inactive</td>
                    <td className="d-flex">
                      <OverlayTrigger
                        placement="left"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipForEdit}
                      >
                        <Link to="/EditVacation">
                          <div className="inconsIn">
                            <img src={edit_icon} alt="" />
                          </div>
                        </Link>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipForDelete}
                      >
                        <Link to="#" onClick={handleShow}>
                          <div className="inconsIn">
                            <img src={delete_icon} alt="" />
                          </div>
                        </Link>
                      </OverlayTrigger>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <Pagenation />
          </div>
        </div>
      </motion.div>
      <Modal show={show} onHide={handleClose} centered className="common-model">
        <Modal.Header closeButton>
          <Modal.Title>{t("Delete Vacation")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("Are you sure you want to delete this Vacation")} ?
        </Modal.Body>
        <Modal.Footer className="pop-up-modal-footer btn-wrapper">
          <button className="cx-btn-1" onClick={handleClose}>
            {t("Close")}
          </button>
          <button className="cx-btn-2" onClick={handleClose}>
            {t("Yes")}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Vacations;
