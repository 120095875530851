import React, { useState } from "react";

const ExistButton = ({ onClose }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      style={{
        flex: 1,
        alignSelf: "flex-end",
        zIndex: 1,
        bottom: "10px",
        position: "absolute",
        left: "200px",
        transition: "ease-in",
      }}
    >
      <div
        style={{
          backgroundColor: isHovered
            ? "rgba(143, 67, 0, 0.5)"
            : "rgba(143, 67, 0, 0.5)",
          borderStyle: "none",
          borderRadius: "15px",
          bottom: "20px",
          padding: "10px",
          position: "absolute",
          right: "30px",
          transition: "0.5s",
          zIndex: 1,
          boxShadow:
            "0 10px 20px rgba(0,0,0,.19),0 6px 6px rgba(0,0,0,.23) 0 10px 20px rgba(0,0,0,.19),0 6px 6px rgba(0,0,0,.23)",
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <button
          style={{
            backgroundColor: isHovered ? "#8f4300" : "#fff",
            borderStyle: "none",
            borderRadius: "15px",
            color: isHovered ? "#fff" : "#000",
            padding: "7px 20px",
            transition: "ease-in 0.3s",
            width: "120px",
            cursor: "pointer",
          }}
          onClick={onClose}
        >
          Exit
        </button>
      </div>
    </div>
  );
};

export default ExistButton;
