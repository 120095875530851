// Usama 09-02-2023

import React, { useContext, useState } from "react";
import { Dropdown, Modal, Tab, Tabs } from "react-bootstrap";
import { AppContext } from "../../context/AppContext";
import Gcar from "../../assets/images/Green-car-logo.svg";
import Gbike from "../../assets/images/green-bike-map.svg";
import Rbike from "../../assets/images/red-bike-map.svg";
import Bcar from "../../assets/images/Blue-car-logo.svg";
import Rcar from "../../assets/images/red-car-logo.svg";
import BKcar from "../../assets/images/black-car-logo.svg";
import GRbike from "../../assets/images/Grey-bike-logo.svg";
import GRcar from "../../assets/images/Grey-car-logo.svg";
import option from "../../assets/images/option-three-dot.svg";
import Grouplogo from "../../assets/images/Customer-profile.png";
import Export from "../../assets/images/ic-Export.svg";
import untracked_icon from "../../assets/images/untracked_icon.svg";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

const LuxuryCars = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const { t, i18n } = useTranslation();

  const { sidebar, setSidebar, Dark, setDark } = useContext(AppContext);

  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };

  return (
    <motion.div
      className={sidebar ? "taskMain " : "cx-active taskMain"}
      id="cx-main"
      variants={aninations}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.1 }}
    >
      <div id="cx-wrapper">
        <div className="main-master-wrapper mb-0 inner-tabs-section tabs-custom-width-25 ">
          <div className="yauto" id="arrange-paading">
            <div className="all-vehical-body main-master-wrapper row  g-0">
              <div className="common-vehical-card col-lg-4">
                <div className="vehical-card-head">
                  <div className="heading">
                    <img src={Gcar} alt="" />
                    <div className="">
                      <p className="sub-heading">{t("Vehicle Name")}</p>
                      <p className="title">Utility CAB</p>
                    </div>
                  </div>
                  <div className="option customer-option">
                    <Dropdown>
                      <Dropdown.Toggle id="dropdown-basic">
                        <img src={option} alt="" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item>
                          <Link to="/VehicleDetails">{t("View")}</Link>
                        </Dropdown.Item>
                        <Dropdown.Item>{t("Edit")}</Dropdown.Item>
                        <Dropdown.Item onClick={handleShow}>
                          {t("Delete")}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <div className="vehical-card-body row">
                  <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                    <p className="sub-heading">{t("Vehicle Number")}</p>
                    <p className="title">MH-12-5022</p>
                  </div>
                  <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                    <p className="sub-heading">{t("Driver Name")}</p>
                    <p className="title">Mr. John Doe</p>
                  </div>
                  <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                    <p className="sub-heading">{t("IMEI No.")}</p>
                    <p className="title">1234567894561230</p>
                  </div>
                  <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                    <p className="sub-heading">{t("Vehicle Type")}</p>
                    <p className="title">Car</p>
                  </div>
                  <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                    <p className="sub-heading">{t("Transportation Type")}</p>
                    <p className="title">Passenger</p>
                  </div>
                  <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                    <p className="sub-heading">{t("Vehicle Capacity / Passenger")}</p>
                    <p className="title">60</p>
                  </div>
                </div>
              </div>
              <div className="common-vehical-card col-lg-4">
                <div className="vehical-card-head">
                  <div className="heading">
                    <img src={Gcar} alt="" />
                    <div className="">
                    <p className="sub-heading">{t("Vehicle Name")}</p>
                      <p className="title">Volvo Transport</p>
                    </div>
                  </div>
                  <div className="option customer-option">
                    <Dropdown>
                      <Dropdown.Toggle id="dropdown-basic">
                        <img src={option} alt="" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item>
                          <Link to="/VehicleDetails">{t("View")}</Link>
                        </Dropdown.Item>
                        <Dropdown.Item>{t("Edit")}</Dropdown.Item>
                        <Dropdown.Item onClick={handleShow}>
                          {t("Delete")}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <div className="vehical-card-body row">
                  <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                    <p className="sub-heading">{t("Vehicle Number")}</p>
                    <p className="title">MH-12-5022</p>
                  </div>
                  <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                    <p className="sub-heading">{t("Driver Name")}</p>
                    <p className="title">Mr. John Doe</p>
                  </div>
                  <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                    <p className="sub-heading">{t("IMEI No.")}</p>
                    <p className="title">1234567894561230</p>
                  </div>
                  <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                    <p className="sub-heading">{t("Vehicle Type")}</p>
                    <p className="title">Car</p>
                  </div>
                  <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                    <p className="sub-heading">{t("Transportation Type")}</p>
                    <p className="title">Passenger</p>
                  </div>
                  <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                    <p className="sub-heading">{t("Vehicle Capacity / Passenger")}</p>
                    <p className="title">60</p>
                  </div>
                </div>
              </div>
              <div className="common-vehical-card col-lg-4">
                <div className="vehical-card-head">
                  <div className="heading">
                    <img src={Bcar} alt="" />
                    <div className="">
                    <p className="sub-heading">{t("Vehicle Name")}</p>
                      <p className="title">Volvo Transport</p>
                    </div>
                  </div>
                  <div className="option customer-option">
                    <Dropdown>
                      <Dropdown.Toggle id="dropdown-basic">
                        <img src={option} alt="" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item>
                          <Link to="/VehicleDetails">{t("View")}</Link>
                        </Dropdown.Item>
                        <Dropdown.Item>{t("Edit")}</Dropdown.Item>
                        <Dropdown.Item onClick={handleShow}>
                          {t("Delete")}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <div className="vehical-card-body row">
                  <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                    <p className="sub-heading">{t("Vehicle Number")}</p>
                    <p className="title">MH-12-5022</p>
                  </div>
                  <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                    <p className="sub-heading">{t("Driver Name")}</p>
                    <p className="title">Mr. John Doe</p>
                  </div>
                  <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                    <p className="sub-heading">{t("IMEI No.")}</p>
                    <p className="title">1234567894561230</p>
                  </div>
                  <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                    <p className="sub-heading">{t("Vehicle Type")}</p>
                    <p className="title">Car</p>
                  </div>
                  <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                    <p className="sub-heading">{t("Transportation Type")}</p>
                    <p className="title">Passenger</p>
                  </div>
                  <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                    <p className="sub-heading">{t("Vehicle Capacity / Passenger")}</p>
                    <p className="title">60</p>
                  </div>
                </div>
              </div>
              <div className="common-vehical-card col-lg-4">
                <div className="vehical-card-head">
                  <div className="heading">
                    <img src={Bcar} alt="" />
                    <div className="">
                    <p className="sub-heading">{t("Vehicle Name")}</p>
                      <p className="title">Mercedes Bus </p>
                    </div>
                  </div>
                  <div className="option customer-option">
                    <Dropdown>
                      <Dropdown.Toggle id="dropdown-basic">
                        <img src={option} alt="" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item>
                          <Link to="/VehicleDetails">{t("View")}</Link>
                        </Dropdown.Item>
                        <Dropdown.Item>{t("Edit")}</Dropdown.Item>
                        <Dropdown.Item onClick={handleShow}>
                          {t("Delete")}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <div className="vehical-card-body row">
                  <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                    <p className="sub-heading">{t("Vehicle Number")}</p>
                    <p className="title">MH-12-5022</p>
                  </div>
                  <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                    <p className="sub-heading">{t("Driver Name")}</p>
                    <p className="title">Mr. John Doe</p>
                  </div>
                  <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                    <p className="sub-heading">{t("IMEI No.")}</p>
                    <p className="title">1234567894561230</p>
                  </div>
                  <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                    <p className="sub-heading">{t("Vehicle Type")}</p>
                    <p className="title">Car</p>
                  </div>
                  <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                    <p className="sub-heading">{t("Transportation Type")}</p>
                    <p className="title">Passenger</p>
                  </div>
                  <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                    <p className="sub-heading">{t("Vehicle Capacity / Passenger")}</p>
                    <p className="title">60</p>
                  </div>
                </div>
              </div>
              <div className="common-vehical-card col-lg-4">
                <div className="vehical-card-head">
                  <div className="heading">
                    <img src={Bcar} alt="" />
                    <div className="">
                    <p className="sub-heading">{t("Vehicle Name")}</p>
                      <p className="title">Volvo Transport</p>
                    </div>
                  </div>
                  <div className="option customer-option">
                    <Dropdown>
                      <Dropdown.Toggle id="dropdown-basic">
                        <img src={option} alt="" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item>
                          <Link to="/VehicleDetails">{t("View")}</Link>
                        </Dropdown.Item>
                        <Dropdown.Item>{t("Edit")}</Dropdown.Item>
                        <Dropdown.Item onClick={handleShow}>
                          {t("Delete")}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <div className="vehical-card-body row">
                  <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                    <p className="sub-heading">{t("Vehicle Number")}</p>
                    <p className="title">MH-12-5022</p>
                  </div>
                  <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                    <p className="sub-heading">{t("Driver Name")}</p>
                    <p className="title">Mr. John Doe</p>
                  </div>
                  <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                    <p className="sub-heading">{t("IMEI No.")}</p>
                    <p className="title">1234567894561230</p>
                  </div>
                  <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                    <p className="sub-heading">{t("Vehicle Type")}</p>
                    <p className="title">Car</p>
                  </div>
                  <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                    <p className="sub-heading">{t("Transportation Type")}</p>
                    <p className="title">Passenger</p>
                  </div>
                  <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                    <p className="sub-heading">{t("Vehicle Capacity / Passenger")}</p>
                    <p className="title">60</p>
                  </div>
                </div>
              </div>
              <div className="common-vehical-card col-lg-4">
                <div className="vehical-card-head">
                  <div className="heading">
                    <img src={Rcar} alt="" />
                    <div className="">
                    <p className="sub-heading">{t("Vehicle Name")}</p>
                      <p className="title">Volvo Transport</p>
                    </div>
                  </div>
                  <div className="option customer-option">
                    <Dropdown>
                      <Dropdown.Toggle id="dropdown-basic">
                        <img src={option} alt="" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item>
                          <Link to="/VehicleDetails">{t("View")}</Link>
                        </Dropdown.Item>
                        <Dropdown.Item>{t("Edit")}</Dropdown.Item>
                        <Dropdown.Item onClick={handleShow}>
                          {t("Delete")}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <div className="vehical-card-body row">
                  <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                    <p className="sub-heading">{t("Vehicle Number")}</p>
                    <p className="title">MH-12-5022</p>
                  </div>
                  <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                    <p className="sub-heading">{t("Driver Name")}</p>
                    <p className="title">Mr. John Doe</p>
                  </div>
                  <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                    <p className="sub-heading">{t("IMEI No.")}</p>
                    <p className="title">1234567894561230</p>
                  </div>
                  <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                    <p className="sub-heading">{t("Vehicle Type")}</p>
                    <p className="title">Car</p>
                  </div>
                  <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                    <p className="sub-heading">{t("Transportation Type")}</p>
                    <p className="title">Passenger</p>
                  </div>
                  <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                    <p className="sub-heading">{t("Vehicle Capacity / Passenger")}</p>
                    <p className="title">60</p>
                  </div>
                </div>
              </div>
              <div className="common-vehical-card col-lg-4">
                <div className="vehical-card-head">
                  <div className="heading">
                    <img src={BKcar} alt="" />
                    <div className="">
                      <p className="sub-heading">{t("Vehicle Name")}</p>
                      <p className="title">Mercedes Bus </p>
                    </div>
                  </div>
                  <div className="option customer-option">
                    <Dropdown>
                      <Dropdown.Toggle id="dropdown-basic">
                        <img src={option} alt="" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item>
                          <Link to="/VehicleDetails">{t("View")}</Link>
                        </Dropdown.Item>
                        <Dropdown.Item>{t("Edit")}</Dropdown.Item>
                        <Dropdown.Item onClick={handleShow}>
                          {t("Delete")}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <div className="vehical-card-body row">
                  <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                    <p className="sub-heading">{t("Vehicle Number")}</p>
                    <p className="title">MH-12-5022</p>
                  </div>
                  <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                    <p className="sub-heading">{t("Driver Name")}</p>
                    <p className="title">Mr. John Doe</p>
                  </div>
                  <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                    <p className="sub-heading">{t("IMEI No.")}</p>
                    <p className="title">1234567894561230</p>
                  </div>
                  <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                    <p className="sub-heading">{t("Vehicle Type")}</p>
                    <p className="title">Car</p>
                  </div>
                  <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                    <p className="sub-heading">{t("Transportation Type")}</p>
                    <p className="title">Passenger</p>
                  </div>
                  <div className="card-contain col-lg-6 col-md-6 col-sm-6">
                    <p className="sub-heading">{t("Vehicle Capacity / Passenger")}</p>
                    <p className="title">60</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Delete Modal Start */}
        <Modal
          show={show}
          onHide={handleClose}
          centered
          className="common-model"
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Driver</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to Delete this Driver ?</Modal.Body>
          <Modal.Footer className="pop-up-modal-footer">
            <div class="btn-wrapper">
              <button className="cx-btn-1" onClick={handleClose}>
                Cancel
              </button>
              <button className="cx-btn-2" onClick={handleClose}>
                Yes
              </button>
            </div>
          </Modal.Footer>
        </Modal>
        {/* Delete Modal End */}

        {/* Block Modal Start */}
        <Modal
          show={show1}
          onHide={handleClose1}
          centered
          className="common-model"
        >
          <Modal.Header closeButton>
            <Modal.Title>Block</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to Block ?</Modal.Body>
          <Modal.Footer className="pop-up-modal-footer">
            <div class="btn-wrapper">
              <button className="cx-btn-1" onClick={handleClose1}>
                Cancel
              </button>
              <button className="cx-btn-2" onClick={handleClose1}>
                Yes
              </button>
            </div>
          </Modal.Footer>
        </Modal>
        {/* Block Modal End */}

        {/* Resign/retire Modal Start */}
        <Modal
          show={show2}
          onHide={handleClose2}
          centered
          className="common-model"
        >
          <Modal.Header closeButton>
            <Modal.Title>Resign / Retire </Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to Resign / Retire ?</Modal.Body>
          <Modal.Footer className="pop-up-modal-footer">
            <div class="btn-wrapper">
              <button className="cx-btn-1" onClick={handleClose2}>
                Cancel
              </button>
              <button className="cx-btn-2" onClick={handleClose2}>
                Yes
              </button>
            </div>
          </Modal.Footer>
        </Modal>
        {/* Resign/retire Modal End */}
      </div>
    </motion.div>
  );
};

export default LuxuryCars;
