import { React, useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import Form from "react-bootstrap/Form";
import DDlogo from "../../assets/images/DDlogo.png";
import whiteTrash from "../../assets/images/whiteTrash.svg";
import whiteHand from "../../assets/images/whiteHand.svg";
import whiteBox from "../../assets/images/whiteBox.svg";
import whiteBin from "../../assets/images/whiteBin.svg";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import MapComponent from "../../sharedComponent/MapComponent";
import { useTranslation } from "react-i18next";

const AddPointOfInterest = () => {
  const { sidebar, setSidebar, Dark, setDark } = useContext(AppContext);
  const [validated, setValidated] = useState(false);
  const { t, i18n } = useTranslation();

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  return (
    <>
      <motion.div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
        variants={aninations}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.1 }}
      >
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <div id="cx-wrapper" className="AddPointOfInterest">
            <div className="main-master-wrapper">
              <div className="Heading">
                <p>{t("Add Location")}</p>
              </div>
              <div className="innerInputsGen">
                <div className="row">
                  <div className="col-md-6 form_input_main ">
                    <div className="innerSelectBox weekCounter">
                      <Form.Label className="common-labels">
                        {t("Location Name")}
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Enter Location Name..."
                      />
                      <Form.Control.Feedback type="invalid">
                        Enter a Location Name
                      </Form.Control.Feedback>
                    </div>
                  </div>
                  <div className="col-md-6 form_input_main ">
                    <div className="innerSelectBox weekCounter">
                      <Form.Label className="common-labels">
                        {t("Location Address")}
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Enter Location Address"
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Location Address
                      </Form.Control.Feedback>
                    </div>
                  </div>
                  <div className="col-md-12 weekCounter form_input_main">
                    <div className="addParkingMap">
                    <MapComponent />
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-end align-items-center btn-wrapper">
                  <button className="cx-btn-1">{t("Cancel")}</button>
                  <button className="cx-btn-2">{t("Submit")}</button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </motion.div>
    </>
  );
};

export default AddPointOfInterest;
