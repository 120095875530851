import React, { useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import "../../assets/styles/main.scss";
import { Tab, Tabs, Form } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";

const VehicleActive1 = () => {
  const { sidebar, setSidebar, Dark, setDark } = useContext(AppContext);
  const currentRoute = useLocation().pathname;
  const { t, i18n } = useTranslation();

  return (
    <div className="dashboard-first-item">
      <div className="style-vehicle-btn2">

</div>
      <Tab.Container
        id="left-tabs-example"
        className="va-tab-wrapper"
        defaultActiveKey="0"
      >
        <Row>
          <Col sm={12}>
            <Nav variant="pills" className="va-nav tabs-custom-width-2-50">
              <Nav.Item className="va-tab">
                <Nav.Link className="va-link" eventKey="0">
                {t("Currently Untracked")} (45)
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="va-tab">
                <Nav.Link className="va-link" eventKey="1">
                  {t("Not Tracked Yet")} (55)
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={12}>
            <Tab.Content>
              <Tab.Pane eventKey="0">
              <div className="running-status-card-wrapper">
                <div className="status-card active">
                    <div className="row first-active-card-main">
                     <div className="col-6 col-md-4 data-content">
                        <label htmlFor="">{t("Driver Name")}</label>
                        <p>No Data Found...</p>
                      </div>
                     <div className="col-6 col-md-4 data-content">
                        <label htmlFor="">{t("Vehicle Type")}</label>
                        <p>Pickup Truck</p>
                      </div>
                     <div className="col-6 col-md-4 data-content">
                        <label htmlFor="">{t("Vehicle No")}.</label>
                        <p>MH12-2023</p>
                      </div>
                     <div className="col-6 col-md-4 data-content">
                     <label htmlFor="">{t("Date")}</label>
                        <p>No Data Found...</p>
                      </div>
                     <div className="col-6 col-md-4 data-content">
                        <label htmlFor="">{t("Time")}</label>
                        <p>No Data Found...</p>
                      </div>
                    </div>
                  </div>
                  <div className="status-card">
                  <div className="row first-active-card-main">
                     <div className="col-6 col-md-4 data-content">
                        <label htmlFor="">{t("Driver Name")}</label>
                        <p>No Data Found...</p>
                      </div>
                     <div className="col-6 col-md-4 data-content">
                        <label htmlFor="">{t("Vehicle Type")}</label>
                        <p>Pickup Truck</p>
                      </div>
                     <div className="col-6 col-md-4 data-content">
                        <label htmlFor="">{t("Vehicle No")}.</label>
                        <p>MH12-2023</p>
                      </div>
                     <div className="col-6 col-md-4 data-content">
                     <label htmlFor="">{t("Date")}</label>
                        <p>No Data Found...</p>
                      </div>
                     <div className="col-6 col-md-4 data-content">
                        <label htmlFor="">{t("Time")}</label>
                        <p>No Data Found...</p>
                      </div>
                    </div>
                  </div>
                  <div className="status-card">
                  <div className="row first-active-card-main">
                     <div className="col-6 col-md-4 data-content">
                        <label htmlFor="">{t("Driver Name")}</label>
                        <p>No Data Found...</p>
                      </div>
                     <div className="col-6 col-md-4 data-content">
                        <label htmlFor="">{t("Vehicle Type")}</label>
                        <p>Pickup Truck</p>
                      </div>
                     <div className="col-6 col-md-4 data-content">
                        <label htmlFor="">{t("Vehicle No")}.</label>
                        <p>MH12-2023</p>
                      </div>
                     <div className="col-6 col-md-4 data-content">
                     <label htmlFor="">{t("Date")}</label>
                        <p>No Data Found...</p>
                      </div>
                     <div className="col-6 col-md-4 data-content">
                        <label htmlFor="">{t("Time")}</label>
                        <p>No Data Found...</p>
                      </div>
                    </div>
                  </div> <div className="status-card">
                  <div className="row first-active-card-main">
                     <div className="col-6 col-md-4 data-content">
                        <label htmlFor="">{t("Driver Name")}</label>
                        <p>No Data Found...</p>
                      </div>
                     <div className="col-6 col-md-4 data-content">
                        <label htmlFor="">{t("Vehicle Type")}</label>
                        <p>Pickup Truck</p>
                      </div>
                     <div className="col-6 col-md-4 data-content">
                        <label htmlFor="">{t("Vehicle No")}.</label>
                        <p>MH12-2023</p>
                      </div>
                     <div className="col-6 col-md-4 data-content">
                     <label htmlFor="">{t("Date")}</label>
                        <p>No Data Found...</p>
                      </div>
                     <div className="col-6 col-md-4 data-content">
                        <label htmlFor="">{t("Time")}</label>
                        <p>No Data Found...</p>
                      </div>
                    </div>
                  </div> <div className="status-card">
                  <div className="row first-active-card-main">
                     <div className="col-6 col-md-4 data-content">
                        <label htmlFor="">{t("Driver Name")}</label>
                        <p>No Data Found...</p>
                      </div>
                     <div className="col-6 col-md-4 data-content">
                        <label htmlFor="">{t("Vehicle Type")}</label>
                        <p>Pickup Truck</p>
                      </div>
                     <div className="col-6 col-md-4 data-content">
                        <label htmlFor="">{t("Vehicle No")}.</label>
                        <p>MH12-2023</p>
                      </div>
                     <div className="col-6 col-md-4 data-content">
                     <label htmlFor="">{t("Date")}</label>
                        <p>No Data Found...</p>
                      </div>
                     <div className="col-6 col-md-4 data-content">
                        <label htmlFor="">{t("Time")}</label>
                        <p>No Data Found...</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="1">
              <div className="running-status-card-wrapper">
                <div className="status-card active">
                    <div className="row first-active-card-main">
                     <div className="col-6 col-md-4 data-content">
                        <label htmlFor="">{t("Driver Name")}</label>
                        <p>No Data Found...</p>
                      </div>
                     <div className="col-6 col-md-4 data-content">
                        <label htmlFor="">{t("Vehicle Type")}</label>
                        <p>Pickup Truck</p>
                      </div>
                     <div className="col-6 col-md-4 data-content">
                        <label htmlFor="">{t("Vehicle No")}.</label>
                        <p>MH12-2023</p>
                      </div>
                     <div className="col-6 col-md-4 data-content">
                     <label htmlFor="">{t("Date")}</label>
                        <p>No Data Found...</p>
                      </div>
                     <div className="col-6 col-md-4 data-content">
                        <label htmlFor="">{t("Time")}</label>
                        <p>No Data Found...</p>
                      </div>
                    </div>
                  </div>
                  <div className="status-card">
                  <div className="row first-active-card-main">
                     <div className="col-6 col-md-4 data-content">
                        <label htmlFor="">{t("Driver Name")}</label>
                        <p>No Data Found...</p>
                      </div>
                     <div className="col-6 col-md-4 data-content">
                        <label htmlFor="">{t("Vehicle Type")}</label>
                        <p>Pickup Truck</p>
                      </div>
                     <div className="col-6 col-md-4 data-content">
                        <label htmlFor="">{t("Vehicle No")}.</label>
                        <p>MH12-2023</p>
                      </div>
                     <div className="col-6 col-md-4 data-content">
                     <label htmlFor="">{t("Date")}</label>
                        <p>No Data Found...</p>
                      </div>
                     <div className="col-6 col-md-4 data-content">
                        <label htmlFor="">{t("Time")}</label>
                        <p>No Data Found...</p>
                      </div>
                    </div>
                  </div>
                  <div className="status-card">
                  <div className="row first-active-card-main">
                     <div className="col-6 col-md-4 data-content">
                        <label htmlFor="">{t("Driver Name")}</label>
                        <p>No Data Found...</p>
                      </div>
                     <div className="col-6 col-md-4 data-content">
                        <label htmlFor="">{t("Vehicle Type")}</label>
                        <p>Pickup Truck</p>
                      </div>
                     <div className="col-6 col-md-4 data-content">
                        <label htmlFor="">{t("Vehicle No")}.</label>
                        <p>MH12-2023</p>
                      </div>
                     <div className="col-6 col-md-4 data-content">
                     <label htmlFor="">{t("Date")}</label>
                        <p>No Data Found...</p>
                      </div>
                     <div className="col-6 col-md-4 data-content">
                        <label htmlFor="">{t("Time")}</label>
                        <p>No Data Found...</p>
                      </div>
                    </div>
                  </div> <div className="status-card">
                  <div className="row first-active-card-main">
                     <div className="col-6 col-md-4 data-content">
                        <label htmlFor="">{t("Driver Name")}</label>
                        <p>No Data Found...</p>
                      </div>
                     <div className="col-6 col-md-4 data-content">
                        <label htmlFor="">{t("Vehicle Type")}</label>
                        <p>Pickup Truck</p>
                      </div>
                     <div className="col-6 col-md-4 data-content">
                        <label htmlFor="">{t("Vehicle No")}.</label>
                        <p>MH12-2023</p>
                      </div>
                     <div className="col-6 col-md-4 data-content">
                     <label htmlFor="">{t("Date")}</label>
                        <p>No Data Found...</p>
                      </div>
                     <div className="col-6 col-md-4 data-content">
                        <label htmlFor="">{t("Time")}</label>
                        <p>No Data Found...</p>
                      </div>
                    </div>
                  </div> <div className="status-card">
                  <div className="row first-active-card-main">
                     <div className="col-6 col-md-4 data-content">
                        <label htmlFor="">{t("Driver Name")}</label>
                        <p>No Data Found...</p>
                      </div>
                     <div className="col-6 col-md-4 data-content">
                        <label htmlFor="">{t("Vehicle Type")}</label>
                        <p>Pickup Truck</p>
                      </div>
                     <div className="col-6 col-md-4 data-content">
                        <label htmlFor="">{t("Vehicle No")}.</label>
                        <p>MH12-2023</p>
                      </div>
                     <div className="col-6 col-md-4 data-content">
                     <label htmlFor="">{t("Date")}</label>
                        <p>No Data Found...</p>
                      </div>
                     <div className="col-6 col-md-4 data-content">
                        <label htmlFor="">{t("Time")}</label>
                        <p>No Data Found...</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  );
};

export default VehicleActive1;
