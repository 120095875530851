import { React, useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import delete_icon from "../../assets/images/delete.svg";
import { Tab, Tabs, Form, Button } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
import import_icon from "../../assets/images/import_icon.svg";
import export_icon from "../../assets/images/export_icon.svg";
import edit_icon from "../../assets/images/ic-edit.svg";
import Calendar from "../../assets/images/calendar.svg";
import DatePicker from "react-datepicker";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

const AddHolidays = () => {
  const { sidebar, setSidebar, Dark, setDark } = useContext(AppContext);
  const { t, i18n } = useTranslation();

  const [validated, setValidated] = useState(false);
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };
  const [startDate, setStartDate] = useState(new Date());

  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  return (
    <>
      <motion.div
      variants={aninations}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.1 }}
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div id="cx-wrapper">
          <div className="main-master-wrapper">
            <div className="Heading">
              <p>{t("Add Holiday")}</p>
            </div>
            <div className="form-wrapper">
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>{t("Holiday Name")}</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Enter Holiday Name"
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Holiday Name.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>{t("Holiday Date")}</Form.Label>
                      <div className="innerSelectBox weekCounter datepicker-main">
                  <DatePicker
                    selected={startDate}
                    // placeholderText="Select Date"
                    onChange={(date) => setStartDate(date)}
                    className="form-control"
                  />
                  <img src={Calendar} className="calendarLogo" alt="" />
                </div>
                      {/* <Form.Control
                        required
                        type="date"
                        placeholder="Select Date"
                      /> */}
                      <Form.Control.Feedback type="invalid">
                        Please Select Holiday Date.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col-md-12">
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>{t("Holiday Description")}</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={6}
                        required
                        placeholder="Enter Description..."
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="btn-wrapper">
                  <button type="" className="cx-btn-1">
                    {t("Cancel")}
                  </button>
                  <button type="submit" className="cx-btn-2">
                    {t("Submit")}
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default AddHolidays;
