import React, { useState, useContext, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import "../../assets/styles/main.scss";
import Multiselect from "multiselect-react-dropdown";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Map from "../../assets/images/dummy-map.png";
import Vehicle1 from "../../assets/images/vehicle-green.svg";
import Vehicle3 from "../../assets/images/vehicle-green-aqua.svg";
import Vehicle4 from "../../assets/images/vehicle-green-yellow.svg";
import Vehicle2 from "../../assets/images/vehicle-red.svg";
import route from "../../assets/images/route.svg";
import tool from "../../assets/images/tool.svg";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import redCar from "../../assets/images/red-car-map.svg";
import yellowCar from "../../assets/images/yellow-card-map.svg";
import greenCar from "../../assets/images/green-car-map.svg";
import blackCar from "../../assets/images/black-car-map.svg";
import blueCar from "../../assets/images/blue-car-map.svg";
import redBike from "../../assets/images/red-bike-map.svg";
import yellowBike from "../../assets/images/yellow-bike-map.svg";
import greenBike from "../../assets/images/green-bike-map.svg";
import blackBike from "../../assets/images/black-bike-map.svg";
import blueBike from "../../assets/images/blue-bike-map.svg";
import customer from "../../assets/images/customer.svg";
import running from "../../assets/images/running.svg";
import parked from "../../assets/images/parked.svg";
import untracked from "../../assets/images/untracked.svg";
import idle from "../../assets/images/idle.svg";
import popimg from "../../assets/images/pop-img.png";
import speed from "../../assets/images/speed.png";
import copy from "../../assets/images/Copy.svg";
import engine from "../../assets/images/engine.png";
import dish from "../../assets/images/dish.png";
import optionDot from "../../assets/images/optionDot.svg";
import search_icon from "../../assets/images/search_icon.svg";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Dropdown from "react-bootstrap/Dropdown";
import Table from "react-bootstrap/Table";
import Status1 from "./VehicleActive1";
import Status2 from "./VehicleActive2";
import { Tab, Tabs, Form, Modal } from "react-bootstrap";
// import { MultiSelect } from "react-multi-select-component";
import { MultiSelect } from "primereact/multiselect";
import MapComponent from "../../sharedComponent/MapComponent";
import { useTranslation } from "react-i18next";
import { Select, Space } from "antd";
import InfoComponent from "../../sharedComponent/SupportHelp/InfoComponent";
const { Option } = Select;
const Dashboard = () => {
  const { sidebar, setSidebar, language, setLanguage } = useContext(AppContext);
  const currentRoute = useLocation().pathname;
  const { t, i18n } = useTranslation();

  // Multi Select Component
  const [selectedCities, setSelectedCities] = useState(null);
  const [selectedCities1, setSelectedCities1] = useState(null);
  const [selectedCities2, setSelectedCities2] = useState(null);
  const cities = [
    { name: "New York", code: "NY" },
    { name: "Rome", code: "RM" },
    { name: "London", code: "LDN" },
    { name: "Istanbul", code: "IST" },
    { name: "Paris", code: "PRS" },
  ];

  // Details show / hide for Vehicles On Map
  const [isActive, setIsActive] = useState(false);
  const [isActive1, setIsActive1] = useState(false);
  const [isActive2, setIsActive2] = useState(false);

  // Overlay on click
  const [show, setShow] = useState(false);
  const target = useRef(null);

  const [selected1, setSelected1] = useState([]);
  // console.log("value", selected1);

  const [carBg, setCarBg] = useState(false);
  const [bikeBg, setBikeBg] = useState(false);

  const handleClick = () => {
    setIsActive(!isActive);
  };
  const handleClick1 = () => {
    setIsActive1(!isActive1);
  };
  const handleClick2 = () => {
    setIsActive2(!isActive2);
  };
  const handleChangenew = (newValue) => {
    console.log(newValue);
    // const inputValue = newValue.replace(/\W/g, "");
    // setSelected1(newValue);
  };
  // Right Tabs show / hide
  const [isShown, setIsShown] = useState(false);
  const [isShown1, setIsShown1] = useState(false);
  const [isShown2, setIsShown2] = useState(false);
  const [isShown3, setIsShown3] = useState(false);
  const [isShown4, setIsShown4] = useState(false);
  const [isShown5, setIsShown5] = useState(false);
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };

  const [shareLink, setShareLink] = useState(false);

  const [showTooltip, setShowTooltip] = useState(false);

  const handleOtherComponentClick = () => {
    setShowTooltip(false);
  };
  const [showTooltipYellow, setShowTooltipYellow] = useState(false);

  const handleOtherComponentClickYellow = () => {
    setShowTooltipYellow(false);
  };

  return (
    <motion.div
      className={sidebar ? "taskMain " : "cx-active taskMain"}
      id="cx-main"
      variants={aninations}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.1 }}
    >
      <div
        id="cx-wrapper"
        className="dashboard_responsive"
        key={"mainContainer"}
      >
        <div className="main-dashboard-wrapper">
          <div className="topsection">
            <div className="dropdown-wrapper" style={{ flex: 1 }}>
              <div
                className="row"
                style={{
                  width: "100%",
                  marginLeft: "-15px",
                }}
                key={"multiselctVAlues"}
              >
                <div className="col-md-4 " key={"multiselct001"}>
                  <div className="multi-select-1">
                    <Select
                      mode="multiple" // Enable multiple selection
                      style={{
                        width: "90%",
                        color: "rgba(156, 73, 0, 0.5)",
                      }}
                      placeholder="hello"
                      key={"selectedGroupData"}
                    >
                      <Option style={{ color: "rgba(156, 73, 0)" }}>
                        <Space>
                          <span role="group"></span>
                        </Space>
                      </Option>
                    </Select>
                  </div>
                </div>
                <div className="col-md-4" key={"multiselct002"}>
                  <div className="multi-select-1">
                    <Select
                      mode="multiple" // Enable multiple selection
                      style={{
                        width: "90%",
                        color: "rgba(156, 73, 0, 0.5)",
                      }}
                      placeholder="hello"
                      optionLabelProp="label"
                    >
                      <Option style={{ color: "rgba(156, 73, 0)" }}>
                        <Space>
                          <span role="vehicle"></span>
                        </Space>
                      </Option>
                    </Select>
                  </div>
                </div>
                <div className="col-md-4" key={"multiselct003"}>
                  <div className="multi-select-1">
                    <Select
                      mode="multiple" // Enable multiple selection
                      style={{
                        width: "90%",
                        color: "rgba(156, 73, 0, 0.5)",
                      }}
                      placeholder="Hello"
                      optionLabelProp="label"
                    >
                      <Option style={{ color: "rgba(156, 73, 0)" }}>
                        <Space>
                          <span role="status"></span>
                        </Space>
                      </Option>
                    </Select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="dashboard-main-map"
            style={{ height: "calc(100vh - 150px)" }}
            key={"mapContainerOuter"}
          >
            <div className="dashboard-inner-wrapper" key={"mapContainer"}>
              <div className="map-wrapper left">
                <MapComponent ComponentId={"dashboard"} />

                <div className="vehicles">
                  <div className="red-car-wrapper">
                    <OverlayTrigger
                      rootClose={true}
                      trigger="click"
                      key="bottom"
                      show={showTooltip}
                      onToggle={(newShow) => setShowTooltip(newShow)}
                      placement="bottom"
                      overlay={
                        <Popover
                          id="popover-positioned-bottom"
                          className="popover-main-wrapper"
                          style={{ width: "600px !important" }}
                        >
                          <Popover.Body className="pm-body">
                            <div className="popover-wrapper">
                              <div className="pw-top">
                                <span>
                                  <img src={speed} alt="" />
                                  25 Km/h
                                </span>
                                <span>
                                  <img src={engine} alt="" />
                                  ON
                                </span>
                                <span>
                                  <img src={dish} alt="" />9
                                </span>
                                <Dropdown className="pw-dropdown">
                                  <Dropdown.Toggle>
                                    <img src={optionDot} alt="" />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu className="pwd-menu">
                                    <Dropdown.Item href="#/action-1">
                                      {t("Track")}
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">
                                      {t("Playback")}
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">
                                      {t("Device Details")}
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">
                                      {t("Geofence")}
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">
                                      {t("Alerts")}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      href="#"
                                      onClick={() => {
                                        setShareLink(true);
                                        handleOtherComponentClick();
                                      }}
                                    >
                                      {t("Share")}
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                              <div className="pw-img-box">
                                <img src={popimg} alt="" />
                              </div>
                              <div className="pw-bottom">
                                <Table className="pwb-table">
                                  <tbody>
                                    <tr>
                                      <td>{t("Vehicle Status")}</td>
                                      <td>:</td>
                                      <td>Online / Running</td>
                                    </tr>
                                    <tr>
                                      <td>{t("Temparatue")}</td>
                                      <td>:</td>
                                      <td>
                                        0.00<sup>0</sup>C
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>{t("Last Signal Received")}</td>
                                      <td>:</td>
                                      <td>2023-01-06 21:05:05</td>
                                    </tr>
                                    <tr>
                                      <td>{t("Last Seen At")}</td>
                                      <td>:</td>
                                      <td>2023-01-06 21:05:05</td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </div>
                            </div>
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <Button>
                        <img
                          src={redCar}
                          alt=""
                          className={
                            !isActive ? "redcar" : "redcar vehicleClicked"
                          }
                          onClick={() => {
                            handleClick();
                            setIsActive1(false);
                            setIsActive2(false);
                          }}
                        />
                      </Button>
                    </OverlayTrigger>
                  </div>
                  <div className="yellow-car-wrapper">
                    <OverlayTrigger
                      rootClose={true}
                      trigger="click"
                      key="bottom"
                      show={showTooltipYellow}
                      onToggle={(newShow) => setShowTooltipYellow(newShow)}
                      placement="bottom"
                      overlay={
                        <Popover
                          id="popover-positioned-bottom"
                          className="popover-main-wrapper"
                          style={{ width: "600px !important" }}
                        >
                          <Popover.Body className="pm-body">
                            <div className="popover-wrapper">
                              <div className="pw-top">
                                <span>
                                  <img src={speed} alt="" />
                                  25 Km/h
                                </span>
                                <span>
                                  <img src={engine} alt="" />
                                  ON
                                </span>
                                <span>
                                  <img src={dish} alt="" />9
                                </span>
                                <Dropdown className="pw-dropdown">
                                  <Dropdown.Toggle>
                                    <img src={optionDot} alt="" />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu className="pwd-menu">
                                    <Dropdown.Item href="#/action-1">
                                      {t("Track")}
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">
                                      {t("Playback")}
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">
                                      {t("Device Details")}
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">
                                      {t("Geofence")}
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">
                                      {t("Alerts")}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      href="#"
                                      onClick={() => {
                                        setShareLink(true);
                                        handleOtherComponentClickYellow();
                                      }}
                                    >
                                      {t("Share")}
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                              <div className="pw-img-box">
                                <img src={popimg} alt="" />
                              </div>
                              <div className="pw-bottom">
                                <Table className="pwb-table">
                                  <tbody>
                                    <tr>
                                      <td>{t("Vehicle Status")}</td>
                                      <td>:</td>
                                      <td>Online / Running</td>
                                    </tr>
                                    <tr>
                                      <td>{t("Temparatue")}</td>
                                      <td>:</td>
                                      <td>
                                        0.00<sup>0</sup>C
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>{t("Last Signal Received")}</td>
                                      <td>:</td>
                                      <td>2023-01-06 21:05:05</td>
                                    </tr>
                                    <tr>
                                      <td>{t("Last Seen At")}</td>
                                      <td>:</td>
                                      <td>2023-01-06 21:05:05</td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </div>
                            </div>
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <Button className="">
                        <img
                          src={yellowCar}
                          alt=""
                          className={
                            !isActive1
                              ? "yellowcar"
                              : "yellowcar vehicleClicked"
                          }
                          onClick={() => {
                            handleClick1();
                            setIsActive(false);
                            setIsActive2(false);
                          }}
                        />
                      </Button>
                    </OverlayTrigger>
                  </div>
                  <div className="green-car-wrapper">
                    <OverlayTrigger
                      rootClose={true}
                      trigger="click"
                      key="bottom"
                      placement="top"
                      overlay={
                        <Popover
                          id="popover-positioned-bottom"
                          className="popover-main-wrapper"
                          style={{ width: "600px !important" }}
                        >
                          <Popover.Body className="pm-body">
                            <div className="popover-wrapper">
                              <div className="pw-top">
                                <span>
                                  <img src={speed} alt="" />
                                  25 Km/h
                                </span>
                                <span>
                                  <img src={engine} alt="" />
                                  ON
                                </span>
                                <span>
                                  <img src={dish} alt="" />9
                                </span>
                                <Dropdown className="pw-dropdown">
                                  <Dropdown.Toggle>
                                    <img src={optionDot} alt="" />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu className="pwd-menu">
                                    <Dropdown.Item href="#/action-1">
                                      {t("Track")}
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">
                                      {t("Playback")}
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">
                                      {t("Device Details")}
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">
                                      {t("Geofence")}
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">
                                      {t("Alerts")}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      href="#"
                                      onClick={() => {
                                        setShareLink(true);
                                      }}
                                    >
                                      {t("Share")}
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                              <div className="pw-img-box">
                                <img src={popimg} alt="" />
                              </div>
                              <div className="pw-bottom">
                                <Table className="pwb-table">
                                  <tbody>
                                    <tr>
                                      <td>{t("Vehicle Status")}</td>
                                      <td>:</td>
                                      <td>Online / Running</td>
                                    </tr>
                                    <tr>
                                      <td>{t("Temparatue")}</td>
                                      <td>:</td>
                                      <td>
                                        0.00<sup>0</sup>C
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>{t("Last Signal Received")}</td>
                                      <td>:</td>
                                      <td>2023-01-06 21:05:05</td>
                                    </tr>
                                    <tr>
                                      <td>{t("Last Seen At")}</td>
                                      <td>:</td>
                                      <td>2023-01-06 21:05:05</td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </div>
                            </div>
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <Button>
                        <img
                          src={greenCar}
                          alt=""
                          className={
                            !isActive2 ? "greencar" : "greencar vehicleClicked"
                          }
                          onClick={() => {
                            handleClick2();
                            setIsActive1(false);
                            setIsActive(false);
                          }}
                        />
                      </Button>
                    </OverlayTrigger>
                  </div>
                  <div className="black-car-wrapper">
                    <OverlayTrigger
                      rootClose={true}
                      trigger="click"
                      key="bottom"
                      placement="top"
                      overlay={
                        <Popover
                          id="popover-positioned-bottom"
                          className="popover-main-wrapper"
                          style={{ width: "600px !important" }}
                        >
                          <Popover.Body className="pm-body">
                            <div className="popover-wrapper">
                              <div className="pw-top">
                                <span>
                                  <img src={speed} alt="" />
                                  255 Km/h
                                </span>
                                <span>
                                  <img src={engine} alt="" />
                                  ON
                                </span>
                                <span>
                                  <img src={dish} alt="" />9
                                </span>
                                <Dropdown className="pw-dropdown">
                                  <Dropdown.Toggle>
                                    <img src={optionDot} alt="" />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu className="pwd-menu">
                                    <Dropdown.Item href="#/action-1">
                                      {t("Track")}
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">
                                      {t("Playback")}
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">
                                      {t("Device Details")}
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">
                                      {t("Geofence")}
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">
                                      {t("Alerts")}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      href="#"
                                      onClick={() => {
                                        setShareLink(true);
                                      }}
                                    >
                                      {t("Share")}
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                              <div className="pw-img-box">
                                <img src={popimg} alt="" />
                              </div>
                              <div className="pw-bottom">
                                <Table className="pwb-table">
                                  <tbody>
                                    <tr>
                                      <td>{t("Vehicle Status")}</td>
                                      <td>:</td>
                                      <td>Online / Running</td>
                                    </tr>
                                    <tr>
                                      <td>{t("Temparatue")}</td>
                                      <td>:</td>
                                      <td>
                                        0.00<sup>0</sup>C
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>{t("Last Signal Received")}</td>
                                      <td>:</td>
                                      <td>2023-01-06 21:05:05</td>
                                    </tr>
                                    <tr>
                                      <td>{t("Last Seen At")}</td>
                                      <td>:</td>
                                      <td>2023-01-06 21:05:05</td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </div>
                            </div>
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <Button>
                        <img
                          src={blackCar}
                          alt=""
                          className={
                            !isActive2 ? "greencar" : "greencar vehicleClicked"
                          }
                          onClick={() => {
                            handleClick2();
                            setIsActive1(false);
                            setIsActive(false);
                          }}
                        />
                      </Button>
                    </OverlayTrigger>
                  </div>
                  <div className="blue-car-wrapper">
                    <OverlayTrigger
                      rootClose={true}
                      trigger="click"
                      key="bottom"
                      placement="top"
                      overlay={
                        <Popover
                          id="popover-positioned-bottom"
                          className="popover-main-wrapper"
                          style={{ width: "600px !important" }}
                        >
                          <Popover.Body className="pm-body">
                            <div className="popover-wrapper">
                              <div className="pw-top">
                                <span>
                                  <img src={speed} alt="" />
                                  25 Km/h
                                </span>
                                <span>
                                  <img src={engine} alt="" />
                                  ON
                                </span>
                                <span>
                                  <img src={dish} alt="" />9
                                </span>
                                <Dropdown className="pw-dropdown">
                                  <Dropdown.Toggle>
                                    <img src={optionDot} alt="" />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu className="pwd-menu">
                                    <Dropdown.Item href="#/action-1">
                                      {t("Track")}
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">
                                      {t("Playback")}
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">
                                      {t("Device Details")}
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">
                                      {t("Geofence")}
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">
                                      {t("Alerts")}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      href="#"
                                      onClick={() => {
                                        setShareLink(true);
                                      }}
                                    >
                                      {t("Share")}
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                              <div className="pw-img-box">
                                <img src={popimg} alt="" />
                              </div>
                              <div className="pw-bottom">
                                <Table className="pwb-table">
                                  <tbody>
                                    <tr>
                                      <td>{t("Vehicle Status")}</td>
                                      <td>:</td>
                                      <td>Online / Running</td>
                                    </tr>
                                    <tr>
                                      <td>{t("Temparatue")}</td>
                                      <td>:</td>
                                      <td>
                                        0.00<sup>0</sup>C
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>{t("Last Signal Received")}</td>
                                      <td>:</td>
                                      <td>2023-01-06 21:05:05</td>
                                    </tr>
                                    <tr>
                                      <td>{t("Last Seen At")}</td>
                                      <td>:</td>
                                      <td>2023-01-06 21:05:05</td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </div>
                            </div>
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <Button className={carBg ? "roundedBg" : ""}>
                        <img
                          src={blueCar}
                          alt=""
                          className={
                            !isActive2 ? "greencar" : "greencar vehicleClicked"
                          }
                          onClick={() => {
                            setCarBg(!carBg);
                            setBikeBg(false);
                          }}
                        />
                      </Button>
                    </OverlayTrigger>
                  </div>
                  <div className="red-bike-wrapper">
                    <OverlayTrigger
                      rootClose={true}
                      trigger="click"
                      key="bottom"
                      placement="bottom"
                      overlay={
                        <Popover
                          id="popover-positioned-bottom"
                          className="popover-main-wrapper"
                          style={{ width: "600px !important" }}
                        >
                          <Popover.Body className="pm-body">
                            <div className="popover-wrapper">
                              <div className="pw-top">
                                <span>
                                  <img src={speed} alt="" />
                                  25 Km/h
                                </span>
                                <span>
                                  <img src={engine} alt="" />
                                  ON
                                </span>
                                <span>
                                  <img src={dish} alt="" />9
                                </span>
                                <Dropdown className="pw-dropdown">
                                  <Dropdown.Toggle>
                                    <img src={optionDot} alt="" />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu className="pwd-menu">
                                    <Dropdown.Item href="#/action-1">
                                      {t("Track")}
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">
                                      {t("Playback")}
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">
                                      {t("Device Details")}
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">
                                      {t("Geofence")}
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">
                                      {t("Alerts")}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      href="#"
                                      onClick={() => {
                                        setShareLink(true);
                                      }}
                                    >
                                      {t("Share")}
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                              <div className="pw-img-box">
                                <img src={popimg} alt="" />
                              </div>
                              <div className="pw-bottom">
                                <Table className="pwb-table">
                                  <tbody>
                                    <tr>
                                      <td>{t("Vehicle Status")}</td>
                                      <td>:</td>
                                      <td>Online / Running</td>
                                    </tr>
                                    <tr>
                                      <td>{t("Temparatue")}</td>
                                      <td>:</td>
                                      <td>
                                        0.00<sup>0</sup>C
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>{t("Last Signal Received")}</td>
                                      <td>:</td>
                                      <td>2023-01-06 21:05:05</td>
                                    </tr>
                                    <tr>
                                      <td>{t("Last Seen At")}</td>
                                      <td>:</td>
                                      <td>2023-01-06 21:05:05</td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </div>
                            </div>
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <Button>
                        <img
                          src={redBike}
                          alt=""
                          className={
                            !isActive2 ? "greencar" : "greencar vehicleClicked"
                          }
                          onClick={() => {
                            handleClick2();
                            setIsActive1(false);
                            setIsActive(false);
                          }}
                        />
                      </Button>
                    </OverlayTrigger>
                  </div>
                  <div className="yellow-bike-wrapper">
                    <OverlayTrigger
                      rootClose={true}
                      trigger="click"
                      key="bottom"
                      placement="top"
                      overlay={
                        <Popover
                          id="popover-positioned-bottom"
                          className="popover-main-wrapper"
                          style={{ width: "600px !important" }}
                        >
                          <Popover.Body className="pm-body">
                            <div className="popover-wrapper">
                              <div className="pw-top">
                                <span>
                                  <img src={speed} alt="" />
                                  25 Km/h
                                </span>
                                <span>
                                  <img src={engine} alt="" />
                                  ON
                                </span>
                                <span>
                                  <img src={dish} alt="" />9
                                </span>
                                <Dropdown className="pw-dropdown">
                                  <Dropdown.Toggle>
                                    <img src={optionDot} alt="" />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu className="pwd-menu">
                                    <Dropdown.Item href="#/action-1">
                                      {t("Track")}
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">
                                      {t("Playback")}
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">
                                      {t("Device Details")}
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">
                                      {t("Geofence")}
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">
                                      {t("Alerts")}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      href="#"
                                      onClick={() => {
                                        setShareLink(true);
                                      }}
                                    >
                                      {t("Share")}
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                              <div className="pw-img-box">
                                <img src={popimg} alt="" />
                              </div>
                              <div className="pw-bottom">
                                <Table className="pwb-table">
                                  <tbody>
                                    <tr>
                                      <td>{t("Vehicle Status")}</td>
                                      <td>:</td>
                                      <td>Online / Running</td>
                                    </tr>
                                    <tr>
                                      <td>{t("Temparatue")}</td>
                                      <td>:</td>
                                      <td>
                                        0.00<sup>0</sup>C
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>{t("Last Signal Received")}</td>
                                      <td>:</td>
                                      <td>2023-01-06 21:05:05</td>
                                    </tr>
                                    <tr>
                                      <td>{t("Last Seen At")}</td>
                                      <td>:</td>
                                      <td>2023-01-06 21:05:05</td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </div>
                            </div>
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <Button>
                        <img
                          src={yellowBike}
                          alt=""
                          className={
                            !isActive2 ? "greencar" : "greencar vehicleClicked"
                          }
                          onClick={() => {
                            handleClick2();
                            setIsActive1(false);
                            setIsActive(false);
                          }}
                        />
                      </Button>
                    </OverlayTrigger>
                  </div>
                  <div className="green-bike-wrapper">
                    <OverlayTrigger
                      rootClose={true}
                      trigger="click"
                      key="bottom"
                      placement="top"
                      overlay={
                        <Popover
                          id="popover-positioned-bottom"
                          className="popover-main-wrapper"
                          style={{ width: "600px !important" }}
                        >
                          <Popover.Body className="pm-body">
                            <div className="popover-wrapper">
                              <div className="pw-top">
                                <span>
                                  <img src={speed} alt="" />
                                  25 Km/h
                                </span>
                                <span>
                                  <img src={engine} alt="" />
                                  ON
                                </span>
                                <span>
                                  <img src={dish} alt="" />9
                                </span>
                                <Dropdown className="pw-dropdown">
                                  <Dropdown.Toggle>
                                    <img src={optionDot} alt="" />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu className="pwd-menu">
                                    <Dropdown.Item href="#/action-1">
                                      {t("Track")}
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">
                                      {t("Playback")}
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">
                                      {t("Device Details")}
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">
                                      {t("Geofence")}
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">
                                      {t("Alerts")}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      href="#"
                                      onClick={() => {
                                        setShareLink(true);
                                      }}
                                    >
                                      {t("Share")}
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                              <div className="pw-img-box">
                                <img src={popimg} alt="" />
                              </div>
                              <div className="pw-bottom">
                                <Table className="pwb-table">
                                  <tbody>
                                    <tr>
                                      <td>{t("Vehicle Status")}</td>
                                      <td>:</td>
                                      <td>Online / Running</td>
                                    </tr>
                                    <tr>
                                      <td>{t("Temparatue")}</td>
                                      <td>:</td>
                                      <td>
                                        0.00<sup>0</sup>C
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>{t("Last Signal Received")}</td>
                                      <td>:</td>
                                      <td>2023-01-06 21:05:05</td>
                                    </tr>
                                    <tr>
                                      <td>{t("Last Seen At")}</td>
                                      <td>:</td>
                                      <td>2023-01-06 21:05:05</td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </div>
                            </div>
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <Button>
                        <img
                          src={greenBike}
                          alt=""
                          className={
                            !isActive2 ? "greencar" : "greencar vehicleClicked"
                          }
                          onClick={() => {
                            handleClick2();
                            setIsActive1(false);
                            setIsActive(false);
                          }}
                        />
                      </Button>
                    </OverlayTrigger>
                  </div>
                  <div className="black-bike-wrapper">
                    <OverlayTrigger
                      target={target.current}
                      show={show}
                      rootClose={true}
                      trigger="click"
                      key="bottom"
                      placement="bottom"
                      overlay={
                        <Popover
                          id="popover-positioned-bottom"
                          className="popover-main-wrapper"
                          style={{ width: "600px !important" }}
                        >
                          <Popover.Body className="pm-body">
                            <div className="popover-wrapper">
                              <div className="pw-top">
                                <span>
                                  <img src={speed} alt="" />
                                  25 Km/h
                                </span>
                                <span>
                                  <img src={engine} alt="" />
                                  ON
                                </span>
                                <span>
                                  <img src={dish} alt="" />9
                                </span>
                                <Dropdown className="pw-dropdown">
                                  <Dropdown.Toggle>
                                    <img src={optionDot} alt="" />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu className="pwd-menu">
                                    <Dropdown.Item href="#/action-1">
                                      {t("Track")}
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">
                                      {t("Playback")}
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">
                                      {t("Device Details")}
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">
                                      {t("Geofence")}
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">
                                      {t("Alerts")}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      href="#"
                                      onClick={() => {
                                        setShareLink(true);
                                      }}
                                    >
                                      {t("Share")}
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                              <div className="pw-img-box">
                                <img src={popimg} alt="" />
                              </div>
                              <div className="pw-bottom">
                                <Table className="pwb-table">
                                  <tbody>
                                    <tr>
                                      <td>{t("Vehicle Status")}</td>
                                      <td>:</td>
                                      <td>Online / Running</td>
                                    </tr>
                                    <tr>
                                      <td>{t("Temparatue")}</td>
                                      <td>:</td>
                                      <td>
                                        0.00<sup>0</sup>C
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>{t("Last Signal Received")}</td>
                                      <td>:</td>
                                      <td>2023-01-06 21:05:05</td>
                                    </tr>
                                    <tr>
                                      <td>{t("Last Seen At")}</td>
                                      <td>:</td>
                                      <td>2023-01-06 21:05:05</td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </div>
                            </div>
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <Button
                        className={bikeBg ? "roundedBg" : ""}
                        onClick={() => {
                          setBikeBg(!bikeBg);
                          setCarBg(false);
                          setShow(!show);
                        }}
                        ref={target}
                      >
                        <img src={blackBike} alt="" />
                      </Button>
                    </OverlayTrigger>
                  </div>
                  <div className="blue-bike-wrapper">
                    <OverlayTrigger
                      rootClose={true}
                      trigger="click"
                      key="bottom"
                      placement="bottom"
                      overlay={
                        <Popover
                          id="popover-positioned-bottom"
                          className="popover-main-wrapper"
                          style={{ width: "600px !important" }}
                        >
                          import {useTranslation} from 'react-i18next';
                          <Popover.Body className="pm-body">
                            <div className="popover-wrapper">
                              <div className="pw-top">
                                <span>
                                  <img src={speed} alt="" />
                                  25 Km/h
                                </span>
                                <span>
                                  <img src={engine} alt="" />
                                  ON
                                </span>
                                <span>
                                  <img src={dish} alt="" />9
                                </span>
                                <Dropdown className="pw-dropdown">
                                  <Dropdown.Toggle>
                                    <img src={optionDot} alt="" />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu className="pwd-menu">
                                    <Dropdown.Item href="#/action-1">
                                      {t("Track")}
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">
                                      {t("Playback")}
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">
                                      {t("Device Details")}
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">
                                      {t("Geofence")}
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">
                                      {t("Alerts")}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      href="#"
                                      onClick={() => {
                                        setShareLink(true);
                                      }}
                                    >
                                      {t("Share")}
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                              <div className="pw-img-box">
                                <img src={popimg} alt="" />
                              </div>
                              <div className="pw-bottom">
                                <Table className="pwb-table">
                                  <tbody>
                                    <tr>
                                      <td>{t("Vehicle Status")}</td>
                                      <td>:</td>
                                      <td>Online / Running</td>
                                    </tr>
                                    <tr>
                                      <td>{t("Temparatue")}</td>
                                      <td>:</td>
                                      <td>
                                        0.00<sup>0</sup>C
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>{t("Last Signal Received")}</td>
                                      <td>:</td>
                                      <td>2023-01-06 21:05:05</td>
                                    </tr>
                                    <tr>
                                      <td>{t("Last Seen At")}</td>
                                      <td>:</td>
                                      <td>2023-01-06 21:05:05</td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </div>
                            </div>
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <Button>
                        <img
                          src={blueBike}
                          alt=""
                          className={
                            !isActive2 ? "greencar" : "greencar vehicleClicked"
                          }
                          onClick={() => {
                            handleClick2();
                            setIsActive1(false);
                            setIsActive(false);
                          }}
                        />
                      </Button>
                    </OverlayTrigger>
                  </div>
                </div>
              </div>
              <div className="right-vehicle-status-wrapper right">
                <Tab.Container id="right-tabs-example">
                  <Col sm={12}>
                    <Nav variant="pills" className="flex-column rvs-nav">
                      <Nav.Item
                        className="vehicle1 vehicle-tabs"
                        onClick={() => {
                          {
                            setIsShown(!isShown);
                            setIsShown1(false);
                            setIsShown2(false);
                            setIsShown3(false);
                            setIsShown4(false);
                            setIsShown5(false);
                          }
                        }}
                      >
                        <Nav.Link eventKey="first">
                          <img src={Vehicle1} alt="" />
                          <span>140</span>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item
                        className="vehicle1 vehicle-tabs"
                        onClick={() => {
                          {
                            setIsShown4(!isShown4);
                            setIsShown(false);
                            setIsShown1(false);
                            setIsShown2(false);
                            setIsShown3(false);
                            setIsShown5(false);
                          }
                        }}
                      >
                        <Nav.Link eventKey="first">
                          <img src={Vehicle4} alt="" />
                          <span>140</span>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item
                        className="vehicle1 vehicle-tabs"
                        onClick={() => {
                          {
                            setIsShown5(!isShown5);
                            setIsShown1(false);
                            setIsShown2(false);
                            setIsShown3(false);
                            setIsShown4(false);
                            setIsShown(false);
                          }
                        }}
                      >
                        <Nav.Link eventKey="first">
                          <img src={Vehicle3} alt="" />
                          <span>140</span>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item
                        className="vehicle1 vehicle-tabs"
                        onClick={() => {
                          setIsShown1(!isShown1);
                          setIsShown(false);
                          setIsShown2(false);
                          setIsShown3(false);
                          setIsShown4(false);
                          setIsShown5(false);
                        }}
                      >
                        <Nav.Link eventKey="second">
                          <img src={Vehicle2} alt="" />
                          <span>100</span>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item
                        className="vehicle1 vehicle-tabs"
                        onClick={() => {
                          {
                            setIsShown2(!isShown2);
                            setIsShown3(false);
                            setIsShown1(false);
                            setIsShown(false);
                            setIsShown4(false);
                            setIsShown5(false);
                          }
                        }}
                      >
                        <Nav.Link eventKey="third">
                          <img src={route} alt="" />
                          <span>100</span>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item
                        className="vehicle1 vehicle-tabs"
                        onClick={() => {
                          {
                            setIsShown3(!isShown3);
                            setIsShown1(false);
                            setIsShown2(false);
                            setIsShown(false);
                          }
                        }}
                      >
                        <Nav.Link eventKey="fourth">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 8 8"
                          >
                            <path
                              fill="#35a6bf"
                              d="M0 0v8h8V5.62a.5.5 0 0 0 0-.22V-.01H0zm1 1h6v4H5.5a.5.5 0 0 0-.09 0a.5.5 0 1 0 .09 1H7v1H1V1zm2.5 1C2.67 2 2 2.67 2 3.5C2 4.5 3.5 6 3.5 6S5 4.5 5 3.5C5 2.67 4.33 2 3.5 2zm0 1c.28 0 .5.22.5.5s-.22.5-.5.5s-.5-.22-.5-.5s.22-.5.5-.5z"
                            />
                          </svg>

                          <span>{t("Tools")}</span>
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col sm={12}>
                    <div className="outer-new">
                      <Tab.Content
                        className={"vehicle-main-tab"}
                        id={isShown && "vehicle-main-tab-active"}
                      >
                        <Tab.Pane eventKey="first">
                          <div className="dashboard-first-item">
                            <div className="style-vehicle-btn1"></div>
                            <Tab.Container
                              id="left-tabs-example"
                              className="va-tab-wrapper"
                              defaultActiveKey="0"
                            >
                              <Row className={isShown === false && "none"}>
                                <Col sm={12}>
                                  <Nav
                                    variant="pills"
                                    className="va-nav tabs-custom-width-2-33"
                                  >
                                    <Nav.Item className="va-tab" id="diffWidth">
                                      <Nav.Link
                                        className="va-link"
                                        eventKey="0"
                                      >
                                        {t("Running")} (40)
                                      </Nav.Link>
                                    </Nav.Item>
                                    {/* <Nav.Item className="va-tab">
                                      <Nav.Link
                                        className="va-link"
                                        eventKey="1"
                                      >
                                        {t("Idle")} (20)
                                      </Nav.Link>
                                    </Nav.Item> */}
                                    {/* <Nav.Item className="va-tab">
                                      <Nav.Link
                                        className="va-link"
                                        eventKey="2"
                                      >
                                        {t("Parked")} (40)
                                      </Nav.Link>
                                    </Nav.Item> */}
                                  </Nav>
                                </Col>
                                <Col sm={12} className="">
                                  <Tab.Content>
                                    <Tab.Pane eventKey="0">
                                      <div className="running-status-card-wrapper">
                                        <div className="status-card active">
                                          <div
                                            className="first-active-card-main row"
                                            onClick={() => {
                                              setBikeBg(!bikeBg);
                                              setShow(!show);
                                            }}
                                            ref={target}
                                          >
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Driver Name")}
                                              </label>
                                              <p>Mark Woods</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Vehicle Type")}
                                              </label>
                                              <p>Pickup Truck</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Vehicle No")}.
                                              </label>
                                              <p>MH12-2023</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("From")}
                                              </label>
                                              <p>Pune..</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Distance Travelled")}
                                              </label>
                                              <p>120Kms</p>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="status-card">
                                          <div className="first-active-card-main row">
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Driver Name")}
                                              </label>
                                              <p>Mark Woods</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Vehicle Type")}
                                              </label>
                                              <p>Pickup Truck</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Vehicle No")}.
                                              </label>
                                              <p>MH12-2023</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("From")}
                                              </label>
                                              <p>Pune..</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Distance Travelled")}
                                              </label>
                                              <p>120Kms</p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="status-card">
                                          <div className="first-active-card-main row">
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Driver Name")}
                                              </label>
                                              <p>Mark Woods</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Vehicle Type")}
                                              </label>
                                              <p>Pickup Truck</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Vehicle No")}.
                                              </label>
                                              <p>MH12-2023</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("From")}
                                              </label>
                                              <p>Pune..</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Distance Travelled")}
                                              </label>
                                              <p>120Kms</p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="status-card">
                                          <div className="first-active-card-main row">
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Driver Name")}
                                              </label>
                                              <p>Mark Woods</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Vehicle Type")}
                                              </label>
                                              <p>Pickup Truck</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Vehicle No")}.
                                              </label>
                                              <p>MH12-2023</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("From")}
                                              </label>
                                              <p>Pune..</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Distance Travelled")}
                                              </label>
                                              <p>120Kms</p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="status-card">
                                          <div className="first-active-card-main row">
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Driver Name")}
                                              </label>
                                              <p>Mark Woods</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Vehicle Type")}
                                              </label>
                                              <p>Pickup Truck</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Vehicle No")}.
                                              </label>
                                              <p>MH12-2023</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("From")}
                                              </label>
                                              <p>Pune..</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Distance Travelled")}
                                              </label>
                                              <p>120Kms</p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="status-card">
                                          <div className="first-active-card-main row">
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Driver Name")}
                                              </label>
                                              <p>Mark Woods</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Vehicle Type")}
                                              </label>
                                              <p>Pickup Truck</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Vehicle No")}.
                                              </label>
                                              <p>MH12-2023</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("From")}
                                              </label>
                                              <p>Pune..</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Distance Travelled")}
                                              </label>
                                              <p>120Kms</p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="status-card">
                                          <div className="first-active-card-main row">
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Driver Name")}
                                              </label>
                                              <p>Mark Woods</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Vehicle Type")}
                                              </label>
                                              <p>Pickup Truck</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Vehicle No")}.
                                              </label>
                                              <p>MH12-2023</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("From")}
                                              </label>
                                              <p>Pune..</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Distance Travelled")}
                                              </label>
                                              <p>120Kms</p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="1">
                                      <div className="running-status-card-wrapper">
                                        <div className="status-card active">
                                          <div className="first-active-card-main row">
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Driver Name")}
                                              </label>
                                              <p>Mark Woods</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Vehicle Type")}
                                              </label>
                                              <p>Pickup Truck</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Vehicle No")}.
                                              </label>
                                              <p>MH12-2023</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("From")}
                                              </label>
                                              <p>Pune..</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Distance Travelled")}
                                              </label>
                                              <p>120Kms</p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="status-card">
                                          <div className="first-active-card-main row">
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Driver Name")}
                                              </label>
                                              <p>Mark Woods</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Vehicle Type")}
                                              </label>
                                              <p>Pickup Truck</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Vehicle No")}.
                                              </label>
                                              <p>MH12-2023</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("From")}
                                              </label>
                                              <p>Pune..</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Distance Travelled")}
                                              </label>
                                              <p>120Kms</p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="status-card">
                                          <div className="first-active-card-main row">
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Driver Name")}
                                              </label>
                                              <p>Mark Woods</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Vehicle Type")}
                                              </label>
                                              <p>Pickup Truck</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Vehicle No")}.
                                              </label>
                                              <p>MH12-2023</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("From")}
                                              </label>
                                              <p>Pune..</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Distance Travelled")}
                                              </label>
                                              <p>120Kms</p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="status-card">
                                          <div className="first-active-card-main row">
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Driver Name")}
                                              </label>
                                              <p>Mark Woods</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Vehicle Type")}
                                              </label>
                                              <p>Pickup Truck</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Vehicle No")}.
                                              </label>
                                              <p>MH12-2023</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("From")}
                                              </label>
                                              <p>Pune..</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Distance Travelled")}
                                              </label>
                                              <p>120Kms</p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="status-card">
                                          <div className="first-active-card-main row">
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Driver Name")}
                                              </label>
                                              <p>Mark Woods</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Vehicle Type")}
                                              </label>
                                              <p>Pickup Truck</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Vehicle No")}.
                                              </label>
                                              <p>MH12-2023</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("From")}
                                              </label>
                                              <p>Pune..</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Distance Travelled")}
                                              </label>
                                              <p>120Kms</p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="status-card">
                                          <div className="first-active-card-main row">
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Driver Name")}
                                              </label>
                                              <p>Mark Woods</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Vehicle Type")}
                                              </label>
                                              <p>Pickup Truck</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Vehicle No")}.
                                              </label>
                                              <p>MH12-2023</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("From")}
                                              </label>
                                              <p>Pune..</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Distance Travelled")}
                                              </label>
                                              <p>120Kms</p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="status-card">
                                          <div className="first-active-card-main row">
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Driver Name")}
                                              </label>
                                              <p>Mark Woods</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Vehicle Type")}
                                              </label>
                                              <p>Pickup Truck</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Vehicle No")}.
                                              </label>
                                              <p>MH12-2023</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("From")}
                                              </label>
                                              <p>Pune..</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Distance Travelled")}
                                              </label>
                                              <p>120Kms</p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="2">
                                      <div className="running-status-card-wrapper">
                                        <div className="status-card active">
                                          <div className="first-active-card-main row">
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Driver Name")}
                                              </label>
                                              <p>Mark Woods</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Vehicle Type")}
                                              </label>
                                              <p>Pickup Truck</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Vehicle No")}.
                                              </label>
                                              <p>MH12-2023</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("From")}
                                              </label>
                                              <p>Pune..</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Distance Travelled")}
                                              </label>
                                              <p>120Kms</p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="status-card">
                                          <div className="first-active-card-main row">
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Driver Name")}
                                              </label>
                                              <p>Mark Woods</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Vehicle Type")}
                                              </label>
                                              <p>Pickup Truck</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Vehicle No")}.
                                              </label>
                                              <p>MH12-2023</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("From")}
                                              </label>
                                              <p>Pune..</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Distance Travelled")}
                                              </label>
                                              <p>120Kms</p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="status-card">
                                          <div className="first-active-card-main row">
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Driver Name")}
                                              </label>
                                              <p>Mark Woods</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Vehicle Type")}
                                              </label>
                                              <p>Pickup Truck</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Vehicle No")}.
                                              </label>
                                              <p>MH12-2023</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("From")}
                                              </label>
                                              <p>Pune..</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Distance Travelled")}
                                              </label>
                                              <p>120Kms</p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="status-card">
                                          <div className="first-active-card-main row">
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Driver Name")}
                                              </label>
                                              <p>Mark Woods</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Vehicle Type")}
                                              </label>
                                              <p>Pickup Truck</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Vehicle No")}.
                                              </label>
                                              <p>MH12-2023</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("From")}
                                              </label>
                                              <p>Pune..</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Distance Travelled")}
                                              </label>
                                              <p>120Kms</p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="status-card">
                                          <div className="first-active-card-main row">
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Driver Name")}
                                              </label>
                                              <p>Mark Woods</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Vehicle Type")}
                                              </label>
                                              <p>Pickup Truck</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Vehicle No")}.
                                              </label>
                                              <p>MH12-2023</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("From")}
                                              </label>
                                              <p>Pune..</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Distance Travelled")}
                                              </label>
                                              <p>120Kms</p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="status-card">
                                          <div className="first-active-card-main row">
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Driver Name")}
                                              </label>
                                              <p>Mark Woods</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Vehicle Type")}
                                              </label>
                                              <p>Pickup Truck</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Vehicle No")}.
                                              </label>
                                              <p>MH12-2023</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("From")}
                                              </label>
                                              <p>Pune..</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Distance Travelled")}
                                              </label>
                                              <p>120Kms</p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="status-card">
                                          <div className="first-active-card-main row">
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Driver Name")}
                                              </label>
                                              <p>Mark Woods</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Vehicle Type")}
                                              </label>
                                              <p>Pickup Truck</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Vehicle No")}.
                                              </label>
                                              <p>MH12-2023</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("From")}
                                              </label>
                                              <p>Pune..</p>
                                            </div>
                                            <div className="col-4 data-content">
                                              <label htmlFor="">
                                                {t("Distance Travelled")}
                                              </label>
                                              <p>120Kms</p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Tab.Pane>
                                  </Tab.Content>
                                </Col>
                              </Row>
                            </Tab.Container>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </div>
                    <Tab.Content
                      className={"vehicle-main-tab"}
                      id={isShown4 && "vehicle-main-tab-active"}
                    >
                      <Tab.Pane eventKey="first">
                        <div className="dashboard-first-item">
                          <div className="style-vehicle-btn23"></div>
                          <Tab.Container
                            id="left-tabs-example"
                            className="va-tab-wrapper"
                            defaultActiveKey="0"
                          >
                            <Row className={isShown4 === false && "none"}>
                              <Col sm={12}>
                                <Nav
                                  variant="pills"
                                  className="va-nav tabs-custom-width-2-33"
                                >
                                  <Nav.Item className="va-tab" id="diffWidth">
                                    <Nav.Link className="va-link" eventKey="0">
                                      {t("Idle")} (40)
                                    </Nav.Link>
                                  </Nav.Item>
                                  {/* <Nav.Item className="va-tab">
                                      <Nav.Link
                                        className="va-link"
                                        eventKey="1"
                                      >
                                        {t("Idle")} (20)
                                      </Nav.Link>
                                    </Nav.Item> */}
                                  {/* <Nav.Item className="va-tab">
                                      <Nav.Link
                                        className="va-link"
                                        eventKey="2"
                                      >
                                        {t("Parked")} (40)
                                      </Nav.Link>
                                    </Nav.Item> */}
                                </Nav>
                              </Col>
                              <Col sm={12} className="">
                                <Tab.Content>
                                  <Tab.Pane eventKey="0">
                                    <div className="running-status-card-wrapper">
                                      <div className="status-card active">
                                        <div
                                          className="first-active-card-main row"
                                          onClick={() => {
                                            setBikeBg(!bikeBg);
                                            setShow(!show);
                                          }}
                                          ref={target}
                                        >
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Driver Name")}
                                            </label>
                                            <p>Mark Woods</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle Type")}
                                            </label>
                                            <p>Pickup Truck</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle No")}.
                                            </label>
                                            <p>MH12-2023</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("From")}
                                            </label>
                                            <p>Pune..</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Distance Travelled")}
                                            </label>
                                            <p>120Kms</p>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="status-card">
                                        <div className="first-active-card-main row">
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Driver Name")}
                                            </label>
                                            <p>Mark Woods</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle Type")}
                                            </label>
                                            <p>Pickup Truck</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle No")}.
                                            </label>
                                            <p>MH12-2023</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("From")}
                                            </label>
                                            <p>Pune..</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Distance Travelled")}
                                            </label>
                                            <p>120Kms</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="status-card">
                                        <div className="first-active-card-main row">
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Driver Name")}
                                            </label>
                                            <p>Mark Woods</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle Type")}
                                            </label>
                                            <p>Pickup Truck</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle No")}.
                                            </label>
                                            <p>MH12-2023</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("From")}
                                            </label>
                                            <p>Pune..</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Distance Travelled")}
                                            </label>
                                            <p>120Kms</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="status-card">
                                        <div className="first-active-card-main row">
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Driver Name")}
                                            </label>
                                            <p>Mark Woods</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle Type")}
                                            </label>
                                            <p>Pickup Truck</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle No")}.
                                            </label>
                                            <p>MH12-2023</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("From")}
                                            </label>
                                            <p>Pune..</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Distance Travelled")}
                                            </label>
                                            <p>120Kms</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="status-card">
                                        <div className="first-active-card-main row">
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Driver Name")}
                                            </label>
                                            <p>Mark Woods</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle Type")}
                                            </label>
                                            <p>Pickup Truck</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle No")}.
                                            </label>
                                            <p>MH12-2023</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("From")}
                                            </label>
                                            <p>Pune..</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Distance Travelled")}
                                            </label>
                                            <p>120Kms</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="status-card">
                                        <div className="first-active-card-main row">
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Driver Name")}
                                            </label>
                                            <p>Mark Woods</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle Type")}
                                            </label>
                                            <p>Pickup Truck</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle No")}.
                                            </label>
                                            <p>MH12-2023</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("From")}
                                            </label>
                                            <p>Pune..</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Distance Travelled")}
                                            </label>
                                            <p>120Kms</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="status-card">
                                        <div className="first-active-card-main row">
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Driver Name")}
                                            </label>
                                            <p>Mark Woods</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle Type")}
                                            </label>
                                            <p>Pickup Truck</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle No")}.
                                            </label>
                                            <p>MH12-2023</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("From")}
                                            </label>
                                            <p>Pune..</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Distance Travelled")}
                                            </label>
                                            <p>120Kms</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Tab.Pane>
                                  <Tab.Pane eventKey="1">
                                    <div className="running-status-card-wrapper">
                                      <div className="status-card active">
                                        <div className="first-active-card-main row">
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Driver Name")}
                                            </label>
                                            <p>Mark Woods</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle Type")}
                                            </label>
                                            <p>Pickup Truck</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle No")}.
                                            </label>
                                            <p>MH12-2023</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("From")}
                                            </label>
                                            <p>Pune..</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Distance Travelled")}
                                            </label>
                                            <p>120Kms</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="status-card">
                                        <div className="first-active-card-main row">
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Driver Name")}
                                            </label>
                                            <p>Mark Woods</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle Type")}
                                            </label>
                                            <p>Pickup Truck</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle No")}.
                                            </label>
                                            <p>MH12-2023</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("From")}
                                            </label>
                                            <p>Pune..</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Distance Travelled")}
                                            </label>
                                            <p>120Kms</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="status-card">
                                        <div className="first-active-card-main row">
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Driver Name")}
                                            </label>
                                            <p>Mark Woods</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle Type")}
                                            </label>
                                            <p>Pickup Truck</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle No")}.
                                            </label>
                                            <p>MH12-2023</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("From")}
                                            </label>
                                            <p>Pune..</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Distance Travelled")}
                                            </label>
                                            <p>120Kms</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="status-card">
                                        <div className="first-active-card-main row">
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Driver Name")}
                                            </label>
                                            <p>Mark Woods</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle Type")}
                                            </label>
                                            <p>Pickup Truck</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle No")}.
                                            </label>
                                            <p>MH12-2023</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("From")}
                                            </label>
                                            <p>Pune..</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Distance Travelled")}
                                            </label>
                                            <p>120Kms</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="status-card">
                                        <div className="first-active-card-main row">
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Driver Name")}
                                            </label>
                                            <p>Mark Woods</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle Type")}
                                            </label>
                                            <p>Pickup Truck</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle No")}.
                                            </label>
                                            <p>MH12-2023</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("From")}
                                            </label>
                                            <p>Pune..</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Distance Travelled")}
                                            </label>
                                            <p>120Kms</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="status-card">
                                        <div className="first-active-card-main row">
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Driver Name")}
                                            </label>
                                            <p>Mark Woods</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle Type")}
                                            </label>
                                            <p>Pickup Truck</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle No")}.
                                            </label>
                                            <p>MH12-2023</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("From")}
                                            </label>
                                            <p>Pune..</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Distance Travelled")}
                                            </label>
                                            <p>120Kms</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="status-card">
                                        <div className="first-active-card-main row">
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Driver Name")}
                                            </label>
                                            <p>Mark Woods</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle Type")}
                                            </label>
                                            <p>Pickup Truck</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle No")}.
                                            </label>
                                            <p>MH12-2023</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("From")}
                                            </label>
                                            <p>Pune..</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Distance Travelled")}
                                            </label>
                                            <p>120Kms</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Tab.Pane>
                                  <Tab.Pane eventKey="2">
                                    <div className="running-status-card-wrapper">
                                      <div className="status-card active">
                                        <div className="first-active-card-main row">
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Driver Name")}
                                            </label>
                                            <p>Mark Woods</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle Type")}
                                            </label>
                                            <p>Pickup Truck</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle No")}.
                                            </label>
                                            <p>MH12-2023</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("From")}
                                            </label>
                                            <p>Pune..</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Distance Travelled")}
                                            </label>
                                            <p>120Kms</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="status-card">
                                        <div className="first-active-card-main row">
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Driver Name")}
                                            </label>
                                            <p>Mark Woods</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle Type")}
                                            </label>
                                            <p>Pickup Truck</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle No")}.
                                            </label>
                                            <p>MH12-2023</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("From")}
                                            </label>
                                            <p>Pune..</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Distance Travelled")}
                                            </label>
                                            <p>120Kms</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="status-card">
                                        <div className="first-active-card-main row">
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Driver Name")}
                                            </label>
                                            <p>Mark Woods</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle Type")}
                                            </label>
                                            <p>Pickup Truck</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle No")}.
                                            </label>
                                            <p>MH12-2023</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("From")}
                                            </label>
                                            <p>Pune..</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Distance Travelled")}
                                            </label>
                                            <p>120Kms</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="status-card">
                                        <div className="first-active-card-main row">
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Driver Name")}
                                            </label>
                                            <p>Mark Woods</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle Type")}
                                            </label>
                                            <p>Pickup Truck</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle No")}.
                                            </label>
                                            <p>MH12-2023</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("From")}
                                            </label>
                                            <p>Pune..</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Distance Travelled")}
                                            </label>
                                            <p>120Kms</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="status-card">
                                        <div className="first-active-card-main row">
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Driver Name")}
                                            </label>
                                            <p>Mark Woods</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle Type")}
                                            </label>
                                            <p>Pickup Truck</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle No")}.
                                            </label>
                                            <p>MH12-2023</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("From")}
                                            </label>
                                            <p>Pune..</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Distance Travelled")}
                                            </label>
                                            <p>120Kms</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="status-card">
                                        <div className="first-active-card-main row">
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Driver Name")}
                                            </label>
                                            <p>Mark Woods</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle Type")}
                                            </label>
                                            <p>Pickup Truck</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle No")}.
                                            </label>
                                            <p>MH12-2023</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("From")}
                                            </label>
                                            <p>Pune..</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Distance Travelled")}
                                            </label>
                                            <p>120Kms</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="status-card">
                                        <div className="first-active-card-main row">
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Driver Name")}
                                            </label>
                                            <p>Mark Woods</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle Type")}
                                            </label>
                                            <p>Pickup Truck</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle No")}.
                                            </label>
                                            <p>MH12-2023</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("From")}
                                            </label>
                                            <p>Pune..</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Distance Travelled")}
                                            </label>
                                            <p>120Kms</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Tab.Pane>
                                </Tab.Content>
                              </Col>
                            </Row>
                          </Tab.Container>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                    <Tab.Content
                      className={"vehicle-main-tab"}
                      id={isShown5 && "vehicle-main-tab-active"}
                    >
                      <Tab.Pane eventKey="first">
                        <div className="dashboard-first-item">
                          <div className="style-vehicle-btn24"></div>
                          <Tab.Container
                            id="left-tabs-example"
                            className="va-tab-wrapper"
                            defaultActiveKey="0"
                          >
                            <Row className={isShown5 === false && "none"}>
                              <Col sm={12}>
                                <Nav
                                  variant="pills"
                                  className="va-nav tabs-custom-width-2-33"
                                >
                                  <Nav.Item className="va-tab" id="diffWidth">
                                    <Nav.Link className="va-link" eventKey="0">
                                      {t("Parked")} (40)
                                    </Nav.Link>
                                  </Nav.Item>
                                  {/* <Nav.Item className="va-tab">
                                      <Nav.Link
                                        className="va-link"
                                        eventKey="1"
                                      >
                                        {t("Idle")} (20)
                                      </Nav.Link>
                                    </Nav.Item> */}
                                  {/* <Nav.Item className="va-tab">
                                      <Nav.Link
                                        className="va-link"
                                        eventKey="2"
                                      >
                                        {t("Parked")} (40)
                                      </Nav.Link>
                                    </Nav.Item> */}
                                </Nav>
                              </Col>
                              <Col sm={12} className="">
                                <Tab.Content>
                                  <Tab.Pane eventKey="0">
                                    <div className="running-status-card-wrapper">
                                      <div className="status-card active">
                                        <div
                                          className="first-active-card-main row"
                                          onClick={() => {
                                            setBikeBg(!bikeBg);
                                            setShow(!show);
                                          }}
                                          ref={target}
                                        >
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Driver Name")}
                                            </label>
                                            <p>Mark Woods</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle Type")}
                                            </label>
                                            <p>Pickup Truck</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle No")}.
                                            </label>
                                            <p>MH12-2023</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("From")}
                                            </label>
                                            <p>Pune..</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Distance Travelled")}
                                            </label>
                                            <p>120Kms</p>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="status-card">
                                        <div className="first-active-card-main row">
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Driver Name")}
                                            </label>
                                            <p>Mark Woods</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle Type")}
                                            </label>
                                            <p>Pickup Truck</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle No")}.
                                            </label>
                                            <p>MH12-2023</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("From")}
                                            </label>
                                            <p>Pune..</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Distance Travelled")}
                                            </label>
                                            <p>120Kms</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="status-card">
                                        <div className="first-active-card-main row">
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Driver Name")}
                                            </label>
                                            <p>Mark Woods</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle Type")}
                                            </label>
                                            <p>Pickup Truck</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle No")}.
                                            </label>
                                            <p>MH12-2023</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("From")}
                                            </label>
                                            <p>Pune..</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Distance Travelled")}
                                            </label>
                                            <p>120Kms</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="status-card">
                                        <div className="first-active-card-main row">
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Driver Name")}
                                            </label>
                                            <p>Mark Woods</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle Type")}
                                            </label>
                                            <p>Pickup Truck</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle No")}.
                                            </label>
                                            <p>MH12-2023</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("From")}
                                            </label>
                                            <p>Pune..</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Distance Travelled")}
                                            </label>
                                            <p>120Kms</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="status-card">
                                        <div className="first-active-card-main row">
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Driver Name")}
                                            </label>
                                            <p>Mark Woods</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle Type")}
                                            </label>
                                            <p>Pickup Truck</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle No")}.
                                            </label>
                                            <p>MH12-2023</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("From")}
                                            </label>
                                            <p>Pune..</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Distance Travelled")}
                                            </label>
                                            <p>120Kms</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="status-card">
                                        <div className="first-active-card-main row">
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Driver Name")}
                                            </label>
                                            <p>Mark Woods</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle Type")}
                                            </label>
                                            <p>Pickup Truck</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle No")}.
                                            </label>
                                            <p>MH12-2023</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("From")}
                                            </label>
                                            <p>Pune..</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Distance Travelled")}
                                            </label>
                                            <p>120Kms</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="status-card">
                                        <div className="first-active-card-main row">
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Driver Name")}
                                            </label>
                                            <p>Mark Woods</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle Type")}
                                            </label>
                                            <p>Pickup Truck</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle No")}.
                                            </label>
                                            <p>MH12-2023</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("From")}
                                            </label>
                                            <p>Pune..</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Distance Travelled")}
                                            </label>
                                            <p>120Kms</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Tab.Pane>
                                  <Tab.Pane eventKey="1">
                                    <div className="running-status-card-wrapper">
                                      <div className="status-card active">
                                        <div className="first-active-card-main row">
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Driver Name")}
                                            </label>
                                            <p>Mark Woods</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle Type")}
                                            </label>
                                            <p>Pickup Truck</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle No")}.
                                            </label>
                                            <p>MH12-2023</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("From")}
                                            </label>
                                            <p>Pune..</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Distance Travelled")}
                                            </label>
                                            <p>120Kms</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="status-card">
                                        <div className="first-active-card-main row">
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Driver Name")}
                                            </label>
                                            <p>Mark Woods</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle Type")}
                                            </label>
                                            <p>Pickup Truck</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle No")}.
                                            </label>
                                            <p>MH12-2023</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("From")}
                                            </label>
                                            <p>Pune..</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Distance Travelled")}
                                            </label>
                                            <p>120Kms</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="status-card">
                                        <div className="first-active-card-main row">
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Driver Name")}
                                            </label>
                                            <p>Mark Woods</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle Type")}
                                            </label>
                                            <p>Pickup Truck</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle No")}.
                                            </label>
                                            <p>MH12-2023</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("From")}
                                            </label>
                                            <p>Pune..</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Distance Travelled")}
                                            </label>
                                            <p>120Kms</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="status-card">
                                        <div className="first-active-card-main row">
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Driver Name")}
                                            </label>
                                            <p>Mark Woods</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle Type")}
                                            </label>
                                            <p>Pickup Truck</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle No")}.
                                            </label>
                                            <p>MH12-2023</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("From")}
                                            </label>
                                            <p>Pune..</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Distance Travelled")}
                                            </label>
                                            <p>120Kms</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="status-card">
                                        <div className="first-active-card-main row">
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Driver Name")}
                                            </label>
                                            <p>Mark Woods</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle Type")}
                                            </label>
                                            <p>Pickup Truck</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle No")}.
                                            </label>
                                            <p>MH12-2023</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("From")}
                                            </label>
                                            <p>Pune..</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Distance Travelled")}
                                            </label>
                                            <p>120Kms</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="status-card">
                                        <div className="first-active-card-main row">
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Driver Name")}
                                            </label>
                                            <p>Mark Woods</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle Type")}
                                            </label>
                                            <p>Pickup Truck</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle No")}.
                                            </label>
                                            <p>MH12-2023</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("From")}
                                            </label>
                                            <p>Pune..</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Distance Travelled")}
                                            </label>
                                            <p>120Kms</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="status-card">
                                        <div className="first-active-card-main row">
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Driver Name")}
                                            </label>
                                            <p>Mark Woods</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle Type")}
                                            </label>
                                            <p>Pickup Truck</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle No")}.
                                            </label>
                                            <p>MH12-2023</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("From")}
                                            </label>
                                            <p>Pune..</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Distance Travelled")}
                                            </label>
                                            <p>120Kms</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Tab.Pane>
                                  <Tab.Pane eventKey="2">
                                    <div className="running-status-card-wrapper">
                                      <div className="status-card active">
                                        <div className="first-active-card-main row">
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Driver Name")}
                                            </label>
                                            <p>Mark Woods</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle Type")}
                                            </label>
                                            <p>Pickup Truck</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle No")}.
                                            </label>
                                            <p>MH12-2023</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("From")}
                                            </label>
                                            <p>Pune..</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Distance Travelled")}
                                            </label>
                                            <p>120Kms</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="status-card">
                                        <div className="first-active-card-main row">
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Driver Name")}
                                            </label>
                                            <p>Mark Woods</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle Type")}
                                            </label>
                                            <p>Pickup Truck</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle No")}.
                                            </label>
                                            <p>MH12-2023</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("From")}
                                            </label>
                                            <p>Pune..</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Distance Travelled")}
                                            </label>
                                            <p>120Kms</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="status-card">
                                        <div className="first-active-card-main row">
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Driver Name")}
                                            </label>
                                            <p>Mark Woods</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle Type")}
                                            </label>
                                            <p>Pickup Truck</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle No")}.
                                            </label>
                                            <p>MH12-2023</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("From")}
                                            </label>
                                            <p>Pune..</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Distance Travelled")}
                                            </label>
                                            <p>120Kms</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="status-card">
                                        <div className="first-active-card-main row">
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Driver Name")}
                                            </label>
                                            <p>Mark Woods</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle Type")}
                                            </label>
                                            <p>Pickup Truck</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle No")}.
                                            </label>
                                            <p>MH12-2023</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("From")}
                                            </label>
                                            <p>Pune..</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Distance Travelled")}
                                            </label>
                                            <p>120Kms</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="status-card">
                                        <div className="first-active-card-main row">
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Driver Name")}
                                            </label>
                                            <p>Mark Woods</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle Type")}
                                            </label>
                                            <p>Pickup Truck</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle No")}.
                                            </label>
                                            <p>MH12-2023</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("From")}
                                            </label>
                                            <p>Pune..</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Distance Travelled")}
                                            </label>
                                            <p>120Kms</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="status-card">
                                        <div className="first-active-card-main row">
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Driver Name")}
                                            </label>
                                            <p>Mark Woods</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle Type")}
                                            </label>
                                            <p>Pickup Truck</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle No")}.
                                            </label>
                                            <p>MH12-2023</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("From")}
                                            </label>
                                            <p>Pune..</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Distance Travelled")}
                                            </label>
                                            <p>120Kms</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="status-card">
                                        <div className="first-active-card-main row">
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Driver Name")}
                                            </label>
                                            <p>Mark Woods</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle Type")}
                                            </label>
                                            <p>Pickup Truck</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Vehicle No")}.
                                            </label>
                                            <p>MH12-2023</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("From")}
                                            </label>
                                            <p>Pune..</p>
                                          </div>
                                          <div className="col-4 data-content">
                                            <label htmlFor="">
                                              {t("Distance Travelled")}
                                            </label>
                                            <p>120Kms</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Tab.Pane>
                                </Tab.Content>
                              </Col>
                            </Row>
                          </Tab.Container>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                    <Tab.Content
                      className={"vehicle-main-tab"}
                      id={isShown1 && "vehicle-main-tab-active"}
                    >
                      <Tab.Pane eventKey="second">
                        <div className={isShown1 === false && "none"}>
                          <div className="style-vehicle-btn25"></div>
                          <Status1 />
                        </div>
                      </Tab.Pane>
                    </Tab.Content>

                    <Tab.Content
                      className={"vehicle-main-tab"}
                      id={isShown2 && "vehicle-main-tab-active"}
                    >
                      <Tab.Pane eventKey="third">
                        <div className={isShown2 === false && "none"}>
                          <div className="style-vehicle-btn26"></div>
                          <Status2 />
                        </div>
                      </Tab.Pane>
                    </Tab.Content>

                    <div
                      className={
                        isShown3
                          ? "dashboard-first-item dashboard-first-item-active"
                          : "dashboard-first-item"
                      }
                    >
                      <div className="style-vehicle-btn4"></div>
                      <Tab.Content
                        className={
                          isShown3
                            ? "tools-main-tab tools-main-tab-active"
                            : "tools-main-tab"
                        }
                      >
                        <Tab.Pane eventKey="fourth">
                          <div className={isShown3 ? "tools-wrapper" : "none"}>
                            <ul>
                              <li>
                                <Link to="#">{t("Find Vehicle")}</Link>
                              </li>
                              <li>
                                <Link to="#">{t("Set Boundaries")}</Link>
                              </li>
                              <li>
                                <Link to="#">{t("Traffic")}</Link>
                              </li>
                            </ul>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </div>
                  </Col>
                </Tab.Container>
              </div>
              {/* Bottom Wrapper */}
            </div>

            {/* Right SideBar */}
          </div>
        </div>
        <InfoComponent />
      </div>

      {/* Delete Modal Start */}
      <Modal
        Modal
        show={shareLink}
        onHide={() => setShareLink(false)}
        centered
        size="md"
        className="common-model copy_Link"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Share Location")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-2">
          <p>{t("Link to share")}</p>
          <div className="copy_body">
            <input
              type="text"
              Value={"https://Vehicletrackingsystem.com/12345"}
              className="form-control"
            />
            <button className="cx-btn-2">
              <img src={copy} alt="" />
              {t("Copy Link")}
            </button>
          </div>
        </Modal.Body>
      </Modal>
      {/* Delete Modal End */}
    </motion.div>
  );
};

export default Dashboard;
