// Usama 09-02-2023
import React, { useContext, useState } from "react";
import { Col, Dropdown, Modal, Nav, Row, Tab, Tabs } from "react-bootstrap";
import { AppContext } from "../../context/AppContext";
import DDlogo from "../../assets/images/DDlogo.png";
import Export from "../../assets/images/ic-Export.svg";
import Import from "../../assets/images/ic-Import.svg";
import Gcar from "../../assets/images/Green-car-logo.svg";
import trip_icon from "../../assets/images/trip_icon.svg";
import option from "../../assets/images/option-three-dot.svg";
import stop_icon from "../../assets/images/stop_icon.svg";
import Gbike from "../../assets/images/green-bike-map.svg";
import Rbike from "../../assets/images/red-bike-map.svg";
import Bcar from "../../assets/images/Blue-car-logo.svg";
import Rcar from "../../assets/images/red-car-logo.svg";
import BKcar from "../../assets/images/black-car-logo.svg";
import GRbike from "../../assets/images/Grey-bike-logo.svg";
import GRcar from "../../assets/images/Grey-car-logo.svg";
import Ycar from "../../assets/images/yellow-car-logo.svg";
import Ybike from "../../assets/images/yellow-bike-map.svg";
import YQcar from "../../assets/images/yellow-qution-car.svg";
import YQbike from "../../assets/images/yellow-qution-bike.svg";
import Cat_ye_car from "../../assets/images/Catagiry_yellow_car.svg";
import Cat_ye_bike from "../../assets/images/Catagiry_yellow_bike.svg";
import Pump from "../../assets/images/Petrol-Pump.svg";
import route from "../../assets/images/Road_Route.svg";
import Seats from "../../assets/images/Seats.svg";
import copy from "../../assets/images/Copy.svg";
import Repair from "../../assets/images/Repair.svg";
import weight from "../../assets/images/weight.svg";
import EVlogo from "../../assets/images/EV-logo.svg";
import Qustiontracking from "../../assets/images/Qustion-tracking.svg";
import Grouplogo from "../../assets/images/Group-logo.svg";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PreviousButton from "../../sharedComponent/PreviousButton";
import NextButton from "../../sharedComponent/Nextbutton";
import ExistButton from "../../sharedComponent/ExistButton";
import InfoComponent from "../../sharedComponent/SupportHelp/InfoComponent";
const TripManagementnew = ({ onClick }) => {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [view, setView] = useState(false);

  const showView = () => {
    setView(true);
  };
  const hideView = () => {
    setView(false);
  };
  const handleClose = () => setShow(false);
  const handleClose2 = () => setShow2(false);
  const handleShow = () => setShow(true);
  const handleShow2 = () => setShow2(true);

  //category
  const [category, setCategory] = useState(false);
  const DeleteCategory = () => setShow(false);
  const showCategory = () => setShow(true);
  //group
  const [group, setGroup] = useState(false);
  const DeleteGroup = () => setShow(false);
  const showGroup = () => setShow(true);

  const { sidebar, setSidebar, Dark, setDark, slider, setSlider } =
    useContext(AppContext);
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    navigate("/AddDispatchTripnew");
  };
  const handleClickPrevious = () => {
    navigate("/DeliveryRequest");
  };
  const [shareLink, setShareLink] = useState(false);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  return (
    <motion.div
      className={sidebar ? "taskMain " : "cx-active taskMain"}
      id="cx-main"
      variants={aninations}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.1 }}
    >
      <div id="cx-wrapper" className="Trip_Management">
        <div className="Vehcle-main-tabs" id="cutomScroll_Tripmanagement">
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Nav variant="pills" id="newTabMai" className="">
              <Nav.Item>
                <Nav.Link eventKey="first">{t("Trip")}</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="second">{t("Stop")}</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="first">
                <Link
                  to="/AddDispatchTrip"
                  className="d-flex justify-content-end"
                >
                  <button className="cx-btn-1 tbBtn">+ {t("New Trip")}</button>
                </Link>
                <div className="main-master-wrapper mb-0 inner-tabs-section tabs-custom-width-33">
                  <div id="scroll_insideThe_Padding33">
                    <Tab.Container
                      id="left-tabs-example"
                      className="td-tab-wrapper"
                      defaultActiveKey="in1"
                    >
                      <Nav
                        variant="pills"
                        className="td-nav"
                        id="InnerTabNew_Three"
                      >
                        <Nav.Item className="td-tab">
                          <Nav.Link className="td-link" eventKey="in1">
                            {t("Planned")}
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="td-tab">
                          <Nav.Link className="td-link" eventKey="1">
                            {t("Unplanned")}
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="td-tab">
                          <Nav.Link className="td-link" eventKey="2">
                            {t("Completed")}
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <Col sm={12} className="">
                        <Tab.Content>
                          <Tab.Pane eventKey="in1">
                            <div className="all-vehicle-main">
                              <div className="all-vehical-head row vehicle-top-inputs">
                                <div className="input-section-wrapper">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Trip Name, Driver Name..."
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Email"
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Contact Number"
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Start Time & End Time"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="right-export-btn-section-wrapper">
                                  <div className="c-pointer me-2"></div>
                                  <div style={{ marginRight: "10px" }} />
                                  <div className="right-export-btn-section-wrapper">
                                    <div className="c-pointer me-2"></div>

                                    <div className="md_dropdown">
                                      <Dropdown>
                                        <Dropdown.Toggle
                                          id="dropdown-basic"
                                          className="export-toggle"
                                        >
                                          <p className="Export_Text">
                                            {t("Export")}
                                          </p>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          <Dropdown.Item>
                                            <Link
                                              // onClick={() => getExportChat()}
                                              className="d-block"
                                            >
                                              {t("PDF")}
                                            </Link>
                                          </Dropdown.Item>

                                          <Dropdown.Item>
                                            <Link
                                              // onClick={() => downLoadExcelSheet()}
                                              className="d-block"
                                            >
                                              {t("Excel")}
                                            </Link>
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="yauto"
                                id="TransportMananger_height"
                              >
                                <div className="row gx-3 main-cards-wrapper">
                                  <div
                                    className={
                                      sidebar
                                        ? "col-lg-4 col-md-6"
                                        : "col-lg-3 col-md-6"
                                    }
                                  >
                                    <div
                                      className={
                                        "common-vehical-card-inner cv-card p-0"
                                      }
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={trip_icon} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Trip Name")}
                                            </p>
                                            <p className="title">Pune Trip</p>
                                          </div>
                                        </div>
                                        <div className="option customer-option">
                                          <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                              <img src={option} alt="" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/ViewDispatchTrip"
                                                  className="d-block"
                                                >
                                                  {t("View")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/EditDispatchTrip"
                                                  className="d-block"
                                                >
                                                  {t("Edit")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={handleShow}
                                              >
                                                <Link
                                                  to="#"
                                                  className="d-block"
                                                >
                                                  {t("Delete")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={() => {
                                                  setShareLink(true);
                                                }}
                                              >
                                                <Link
                                                  to="#"
                                                  className="d-block"
                                                >
                                                  {t("Share")}
                                                </Link>
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Vehicle")}
                                          </p>
                                          <p className="title">Utility Cab</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Trip Category")}
                                          </p>
                                          <p className="title">Pick Up Trip</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Start Time")}
                                          </p>
                                          <p className="title">05:30:00 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("End Time")}
                                          </p>
                                          <p className="title">10:30:00 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-lg-4 col-md-6"
                                        : "col-lg-3 col-md-6"
                                    }
                                  >
                                    <div
                                      className={
                                        "common-vehical-card-inner cv-card p-0"
                                      }
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={trip_icon} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Trip Name")}
                                            </p>
                                            <p className="title">Pune Trip</p>
                                          </div>
                                        </div>
                                        <div className="option customer-option">
                                          <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                              <img src={option} alt="" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/ViewDispatchTrip"
                                                  className="d-block"
                                                >
                                                  {t("View")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/EditDispatchTrip"
                                                  className="d-block"
                                                >
                                                  {t("Edit")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={handleShow}
                                              >
                                                <Link
                                                  to="#"
                                                  className="d-block"
                                                >
                                                  {t("Delete")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={() => {
                                                  setShareLink(true);
                                                }}
                                              >
                                                <Link
                                                  to="#"
                                                  className="d-block"
                                                >
                                                  {t("Share")}
                                                </Link>
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Vehicle")}
                                          </p>
                                          <p className="title">Utility Cab</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Trip Category")}
                                          </p>
                                          <p className="title">Pick Up Trip</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Start Time")}
                                          </p>
                                          <p className="title">05:30:00 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("End Time")}
                                          </p>
                                          <p className="title">10:30:00 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-lg-4 col-md-6"
                                        : "col-lg-3 col-md-6"
                                    }
                                  >
                                    <div
                                      className={
                                        "common-vehical-card-inner cv-card p-0"
                                      }
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={trip_icon} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Trip Name")}
                                            </p>
                                            <p className="title">Pune Trip</p>
                                          </div>
                                        </div>
                                        <div className="option customer-option">
                                          <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                              <img src={option} alt="" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/ViewDispatchTrip"
                                                  className="d-block"
                                                >
                                                  {t("View")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/EditDispatchTrip"
                                                  className="d-block"
                                                >
                                                  {t("Edit")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={handleShow}
                                              >
                                                <Link
                                                  to="#"
                                                  className="d-block"
                                                >
                                                  {t("Delete")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={() => {
                                                  setShareLink(true);
                                                }}
                                              >
                                                <Link
                                                  to="#"
                                                  className="d-block"
                                                >
                                                  {t("Share")}
                                                </Link>
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Vehicle")}
                                          </p>
                                          <p className="title">Utility Cab</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Trip Category")}
                                          </p>
                                          <p className="title">Pick Up Trip</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Start Time")}
                                          </p>
                                          <p className="title">05:30:00 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("End Time")}
                                          </p>
                                          <p className="title">10:30:00 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-lg-4 col-md-6"
                                        : "col-lg-3 col-md-6"
                                    }
                                  >
                                    <div
                                      className={
                                        "common-vehical-card-inner cv-card p-0"
                                      }
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={trip_icon} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Trip Name")}
                                            </p>
                                            <p className="title">Pune Trip</p>
                                          </div>
                                        </div>
                                        <div className="option customer-option">
                                          <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                              <img src={option} alt="" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/ViewDispatchTrip"
                                                  className="d-block"
                                                >
                                                  {t("View")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/EditDispatchTrip"
                                                  className="d-block"
                                                >
                                                  {t("Edit")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={handleShow}
                                              >
                                                <Link
                                                  to="#"
                                                  className="d-block"
                                                >
                                                  {t("Delete")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={() => {
                                                  setShareLink(true);
                                                }}
                                              >
                                                <Link
                                                  to="#"
                                                  className="d-block"
                                                >
                                                  {t("Share")}
                                                </Link>
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Vehicle")}
                                          </p>
                                          <p className="title">Utility Cab</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Trip Category")}
                                          </p>
                                          <p className="title">Pick Up Trip</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Start Time")}
                                          </p>
                                          <p className="title">05:30:00 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("End Time")}
                                          </p>
                                          <p className="title">10:30:00 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-lg-4 col-md-6"
                                        : "col-lg-3 col-md-6"
                                    }
                                  >
                                    <div
                                      className={
                                        "common-vehical-card-inner cv-card p-0"
                                      }
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={trip_icon} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Trip Name")}
                                            </p>
                                            <p className="title">Pune Trip</p>
                                          </div>
                                        </div>
                                        <div className="option customer-option">
                                          <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                              <img src={option} alt="" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/ViewDispatchTrip"
                                                  className="d-block"
                                                >
                                                  {t("View")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/EditDispatchTrip"
                                                  className="d-block"
                                                >
                                                  {t("Edit")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={handleShow}
                                              >
                                                <Link
                                                  to="#"
                                                  className="d-block"
                                                >
                                                  {t("Delete")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={() => {
                                                  setShareLink(true);
                                                }}
                                              >
                                                <Link
                                                  to="#"
                                                  className="d-block"
                                                >
                                                  {t("Share")}
                                                </Link>
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Vehicle")}
                                          </p>
                                          <p className="title">Utility Cab</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Trip Category")}
                                          </p>
                                          <p className="title">Pick Up Trip</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Start Time")}
                                          </p>
                                          <p className="title">05:30:00 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("End Time")}
                                          </p>
                                          <p className="title">10:30:00 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-lg-4 col-md-6"
                                        : "col-lg-3 col-md-6"
                                    }
                                  >
                                    <div
                                      className={
                                        "common-vehical-card-inner cv-card p-0"
                                      }
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={trip_icon} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Trip Name")}
                                            </p>
                                            <p className="title">Pune Trip</p>
                                          </div>
                                        </div>
                                        <div className="option customer-option">
                                          <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                              <img src={option} alt="" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/ViewDispatchTrip"
                                                  className="d-block"
                                                >
                                                  {t("View")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/EditDispatchTrip"
                                                  className="d-block"
                                                >
                                                  {t("Edit")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={handleShow}
                                              >
                                                <Link
                                                  to="#"
                                                  className="d-block"
                                                >
                                                  {t("Delete")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={() => {
                                                  setShareLink(true);
                                                }}
                                              >
                                                <Link
                                                  to="#"
                                                  className="d-block"
                                                >
                                                  {t("Share")}
                                                </Link>
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Vehicle")}
                                          </p>
                                          <p className="title">Utility Cab</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Trip Category")}
                                          </p>
                                          <p className="title">Pick Up Trip</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Start Time")}
                                          </p>
                                          <p className="title">05:30:00 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("End Time")}
                                          </p>
                                          <p className="title">10:30:00 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-lg-4 col-md-6"
                                        : "col-lg-3 col-md-6"
                                    }
                                  >
                                    <div
                                      className={
                                        "common-vehical-card-inner cv-card p-0"
                                      }
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={trip_icon} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Trip Name")}
                                            </p>
                                            <p className="title">Pune Trip</p>
                                          </div>
                                        </div>
                                        <div className="option customer-option">
                                          <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                              <img src={option} alt="" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/ViewDispatchTrip"
                                                  className="d-block"
                                                >
                                                  {t("View")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/EditDispatchTrip"
                                                  className="d-block"
                                                >
                                                  {t("Edit")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={handleShow}
                                              >
                                                <Link
                                                  to="#"
                                                  className="d-block"
                                                >
                                                  {t("Delete")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={() => {
                                                  setShareLink(true);
                                                }}
                                              >
                                                <Link
                                                  to="#"
                                                  className="d-block"
                                                >
                                                  {t("Share")}
                                                </Link>
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Vehicle")}
                                          </p>
                                          <p className="title">Utility Cab</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Trip Category")}
                                          </p>
                                          <p className="title">Pick Up Trip</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Start Time")}
                                          </p>
                                          <p className="title">05:30:00 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("End Time")}
                                          </p>
                                          <p className="title">10:30:00 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="1">
                            <div className="all-vehicle-main">
                              <div className="all-vehical-head row vehicle-top-inputs">
                                <div className="input-section-wrapper">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Trip Name, Driver Name..."
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Email"
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Contact Number"
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Start Time & End Time"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="right-export-btn-section-wrapper">
                                  <div className="c-pointer me-2"></div>
                                  <div style={{ marginRight: "10px" }} />
                                  <div className="right-export-btn-section-wrapper">
                                    <div className="c-pointer me-2"></div>

                                    <div className="md_dropdown">
                                      <Dropdown>
                                        <Dropdown.Toggle
                                          id="dropdown-basic"
                                          className="export-toggle"
                                        >
                                          <p className="Export_Text">
                                            {t("Export")}
                                          </p>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          <Dropdown.Item>
                                            <Link
                                              // onClick={() => getExportChat()}
                                              className="d-block"
                                            >
                                              {t("PDF")}
                                            </Link>
                                          </Dropdown.Item>

                                          <Dropdown.Item>
                                            <Link
                                              // onClick={() => downLoadExcelSheet()}
                                              className="d-block"
                                            >
                                              {t("Excel")}
                                            </Link>
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="yauto"
                                id="TransportMananger_height"
                              >
                                <div className="row gx-3 main-cards-wrapper">
                                  <div
                                    className={
                                      sidebar
                                        ? "col-lg-4 col-md-6"
                                        : "col-lg-3 col-md-6"
                                    }
                                  >
                                    <div
                                      className={
                                        "common-vehical-card-inner cv-card p-0"
                                      }
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={trip_icon} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Trip Name")}
                                            </p>
                                            <p className="title">Pune Trip</p>
                                          </div>
                                        </div>
                                        <div className="option customer-option">
                                          <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                              <img src={option} alt="" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/ViewDispatchTrip"
                                                  className="d-block"
                                                >
                                                  {t("View")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/EditDispatchTrip"
                                                  className="d-block"
                                                >
                                                  {t("Edit")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={handleShow}
                                              >
                                                <Link
                                                  to="#"
                                                  className="d-block"
                                                >
                                                  {t("Delete")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={() => {
                                                  setShareLink(true);
                                                }}
                                              >
                                                <Link
                                                  to="#"
                                                  className="d-block"
                                                >
                                                  {t("Share")}
                                                </Link>
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Vehicle")}
                                          </p>
                                          <p className="title">Utility Cab</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Trip Category")}
                                          </p>
                                          <p className="title">Pick Up Trip</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Start Time")}
                                          </p>
                                          <p className="title">05:30:00 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("End Time")}
                                          </p>
                                          <p className="title">10:30:00 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-lg-4 col-md-6"
                                        : "col-lg-3 col-md-6"
                                    }
                                  >
                                    <div
                                      className={
                                        "common-vehical-card-inner cv-card p-0"
                                      }
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={trip_icon} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Trip Name")}
                                            </p>
                                            <p className="title">Pune Trip</p>
                                          </div>
                                        </div>
                                        <div className="option customer-option">
                                          <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                              <img src={option} alt="" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/ViewDispatchTrip"
                                                  className="d-block"
                                                >
                                                  {t("View")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/EditDispatchTrip"
                                                  className="d-block"
                                                >
                                                  {t("Edit")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={handleShow}
                                              >
                                                <Link
                                                  to="#"
                                                  className="d-block"
                                                >
                                                  {t("Delete")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={() => {
                                                  setShareLink(true);
                                                }}
                                              >
                                                <Link
                                                  to="#"
                                                  className="d-block"
                                                >
                                                  {t("Share")}
                                                </Link>
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Vehicle")}
                                          </p>
                                          <p className="title">Utility Cab</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Trip Category")}
                                          </p>
                                          <p className="title">Pick Up Trip</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Start Time")}
                                          </p>
                                          <p className="title">05:30:00 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("End Time")}
                                          </p>
                                          <p className="title">10:30:00 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-lg-4 col-md-6"
                                        : "col-lg-3 col-md-6"
                                    }
                                  >
                                    <div
                                      className={
                                        "common-vehical-card-inner cv-card p-0"
                                      }
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={trip_icon} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Trip Name")}
                                            </p>
                                            <p className="title">Pune Trip</p>
                                          </div>
                                        </div>
                                        <div className="option customer-option">
                                          <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                              <img src={option} alt="" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/ViewDispatchTrip"
                                                  className="d-block"
                                                >
                                                  {t("View")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/EditDispatchTrip"
                                                  className="d-block"
                                                >
                                                  {t("Edit")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={handleShow}
                                              >
                                                <Link
                                                  to="#"
                                                  className="d-block"
                                                >
                                                  {t("Delete")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={() => {
                                                  setShareLink(true);
                                                }}
                                              >
                                                <Link
                                                  to="#"
                                                  className="d-block"
                                                >
                                                  {t("Share")}
                                                </Link>
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Vehicle")}
                                          </p>
                                          <p className="title">Utility Cab</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Trip Category")}
                                          </p>
                                          <p className="title">Pick Up Trip</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Start Time")}
                                          </p>
                                          <p className="title">05:30:00 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("End Time")}
                                          </p>
                                          <p className="title">10:30:00 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-lg-4 col-md-6"
                                        : "col-lg-3 col-md-6"
                                    }
                                  >
                                    <div
                                      className={
                                        "common-vehical-card-inner cv-card p-0"
                                      }
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={trip_icon} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Trip Name")}
                                            </p>
                                            <p className="title">Pune Trip</p>
                                          </div>
                                        </div>
                                        <div className="option customer-option">
                                          <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                              <img src={option} alt="" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/ViewDispatchTrip"
                                                  className="d-block"
                                                >
                                                  {t("View")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/EditDispatchTrip"
                                                  className="d-block"
                                                >
                                                  {t("Edit")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={handleShow}
                                              >
                                                <Link
                                                  to="#"
                                                  className="d-block"
                                                >
                                                  {t("Delete")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={() => {
                                                  setShareLink(true);
                                                }}
                                              >
                                                <Link
                                                  to="#"
                                                  className="d-block"
                                                >
                                                  {t("Share")}
                                                </Link>
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Vehicle")}
                                          </p>
                                          <p className="title">Utility Cab</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Trip Category")}
                                          </p>
                                          <p className="title">Pick Up Trip</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Start Time")}
                                          </p>
                                          <p className="title">05:30:00 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("End Time")}
                                          </p>
                                          <p className="title">10:30:00 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-lg-4 col-md-6"
                                        : "col-lg-3 col-md-6"
                                    }
                                  >
                                    <div
                                      className={
                                        "common-vehical-card-inner cv-card p-0"
                                      }
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={trip_icon} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Trip Name")}
                                            </p>
                                            <p className="title">Pune Trip</p>
                                          </div>
                                        </div>
                                        <div className="option customer-option">
                                          <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                              <img src={option} alt="" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/ViewDispatchTrip"
                                                  className="d-block"
                                                >
                                                  {t("View")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/EditDispatchTrip"
                                                  className="d-block"
                                                >
                                                  {t("Edit")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={handleShow}
                                              >
                                                <Link
                                                  to="#"
                                                  className="d-block"
                                                >
                                                  {t("Delete")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={() => {
                                                  setShareLink(true);
                                                }}
                                              >
                                                <Link
                                                  to="#"
                                                  className="d-block"
                                                >
                                                  {t("Share")}
                                                </Link>
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Vehicle")}
                                          </p>
                                          <p className="title">Utility Cab</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Trip Category")}
                                          </p>
                                          <p className="title">Pick Up Trip</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Start Time")}
                                          </p>
                                          <p className="title">05:30:00 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("End Time")}
                                          </p>
                                          <p className="title">10:30:00 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-lg-4 col-md-6"
                                        : "col-lg-3 col-md-6"
                                    }
                                  >
                                    <div
                                      className={
                                        "common-vehical-card-inner cv-card p-0"
                                      }
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={trip_icon} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Trip Name")}
                                            </p>
                                            <p className="title">Pune Trip</p>
                                          </div>
                                        </div>
                                        <div className="option customer-option">
                                          <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                              <img src={option} alt="" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/ViewDispatchTrip"
                                                  className="d-block"
                                                >
                                                  {t("View")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/EditDispatchTrip"
                                                  className="d-block"
                                                >
                                                  {t("Edit")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={handleShow}
                                              >
                                                <Link
                                                  to="#"
                                                  className="d-block"
                                                >
                                                  {t("Delete")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={() => {
                                                  setShareLink(true);
                                                }}
                                              >
                                                <Link
                                                  to="#"
                                                  className="d-block"
                                                >
                                                  {t("Share")}
                                                </Link>
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Vehicle")}
                                          </p>
                                          <p className="title">Utility Cab</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Trip Category")}
                                          </p>
                                          <p className="title">Pick Up Trip</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Start Time")}
                                          </p>
                                          <p className="title">05:30:00 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("End Time")}
                                          </p>
                                          <p className="title">10:30:00 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-lg-4 col-md-6"
                                        : "col-lg-3 col-md-6"
                                    }
                                  >
                                    <div
                                      className={
                                        "common-vehical-card-inner cv-card p-0"
                                      }
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={trip_icon} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Trip Name")}
                                            </p>
                                            <p className="title">Pune Trip</p>
                                          </div>
                                        </div>
                                        <div className="option customer-option">
                                          <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                              <img src={option} alt="" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/ViewDispatchTrip"
                                                  className="d-block"
                                                >
                                                  {t("View")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/EditDispatchTrip"
                                                  className="d-block"
                                                >
                                                  {t("Edit")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={handleShow}
                                              >
                                                <Link
                                                  to="#"
                                                  className="d-block"
                                                >
                                                  {t("Delete")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={() => {
                                                  setShareLink(true);
                                                }}
                                              >
                                                <Link
                                                  to="#"
                                                  className="d-block"
                                                >
                                                  {t("Share")}
                                                </Link>
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Vehicle")}
                                          </p>
                                          <p className="title">Utility Cab</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Trip Category")}
                                          </p>
                                          <p className="title">Pick Up Trip</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Start Time")}
                                          </p>
                                          <p className="title">05:30:00 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("End Time")}
                                          </p>
                                          <p className="title">10:30:00 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="2">
                            <div className="all-vehicle-main">
                              <div className="all-vehical-head row vehicle-top-inputs">
                                <div className="input-section-wrapper">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Trip Name, Driver Name..."
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Email"
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Contact Number"
                                      />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Start Time & End Time"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="right-export-btn-section-wrapper">
                                  <div className="c-pointer me-2"></div>
                                  <div style={{ marginRight: "10px" }} />
                                  <div className="right-export-btn-section-wrapper">
                                    <div className="c-pointer me-2"></div>

                                    <div className="md_dropdown">
                                      <Dropdown>
                                        <Dropdown.Toggle
                                          id="dropdown-basic"
                                          className="export-toggle"
                                        >
                                          <p className="Export_Text">
                                            {t("Export")}
                                          </p>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          <Dropdown.Item>
                                            <Link
                                              // onClick={() => getExportChat()}
                                              className="d-block"
                                            >
                                              {t("PDF")}
                                            </Link>
                                          </Dropdown.Item>

                                          <Dropdown.Item>
                                            <Link
                                              // onClick={() => downLoadExcelSheet()}
                                              className="d-block"
                                            >
                                              {t("Excel")}
                                            </Link>
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="yauto"
                                id="TransportMananger_height"
                              >
                                <div className="row gx-3 main-cards-wrapper">
                                  <div
                                    className={
                                      sidebar
                                        ? "col-lg-4 col-md-6"
                                        : "col-lg-3 col-md-6"
                                    }
                                  >
                                    <div
                                      className={
                                        "common-vehical-card-inner cv-card p-0"
                                      }
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={trip_icon} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Trip Name")}
                                            </p>
                                            <p className="title">Pune Trip</p>
                                          </div>
                                        </div>
                                        <div className="option customer-option">
                                          <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                              <img src={option} alt="" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/ViewDispatchTrip"
                                                  className="d-block"
                                                >
                                                  {t("View")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/EditDispatchTrip"
                                                  className="d-block"
                                                >
                                                  {t("Edit")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={handleShow}
                                              >
                                                <Link
                                                  to="#"
                                                  className="d-block"
                                                >
                                                  {t("Delete")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={() => {
                                                  setShareLink(true);
                                                }}
                                              >
                                                <Link
                                                  to="#"
                                                  className="d-block"
                                                >
                                                  {t("Share")}
                                                </Link>
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Vehicle")}
                                          </p>
                                          <p className="title">Utility Cab</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Trip Category")}
                                          </p>
                                          <p className="title">Pick Up Trip</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Start Time")}
                                          </p>
                                          <p className="title">05:30:00 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("End Time")}
                                          </p>
                                          <p className="title">10:30:00 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-lg-4 col-md-6"
                                        : "col-lg-3 col-md-6"
                                    }
                                  >
                                    <div
                                      className={
                                        "common-vehical-card-inner cv-card p-0"
                                      }
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={trip_icon} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Trip Name")}
                                            </p>
                                            <p className="title">Pune Trip</p>
                                          </div>
                                        </div>
                                        <div className="option customer-option">
                                          <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                              <img src={option} alt="" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/ViewDispatchTrip"
                                                  className="d-block"
                                                >
                                                  {t("View")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/EditDispatchTrip"
                                                  className="d-block"
                                                >
                                                  {t("Edit")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={handleShow}
                                              >
                                                <Link
                                                  to="#"
                                                  className="d-block"
                                                >
                                                  {t("Delete")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={() => {
                                                  setShareLink(true);
                                                }}
                                              >
                                                <Link
                                                  to="#"
                                                  className="d-block"
                                                >
                                                  {t("Share")}
                                                </Link>
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Vehicle")}
                                          </p>
                                          <p className="title">Utility Cab</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Trip Category")}
                                          </p>
                                          <p className="title">Pick Up Trip</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Start Time")}
                                          </p>
                                          <p className="title">05:30:00 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("End Time")}
                                          </p>
                                          <p className="title">10:30:00 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-lg-4 col-md-6"
                                        : "col-lg-3 col-md-6"
                                    }
                                  >
                                    <div
                                      className={
                                        "common-vehical-card-inner cv-card p-0"
                                      }
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={trip_icon} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Trip Name")}
                                            </p>
                                            <p className="title">Pune Trip</p>
                                          </div>
                                        </div>
                                        <div className="option customer-option">
                                          <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                              <img src={option} alt="" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/ViewDispatchTrip"
                                                  className="d-block"
                                                >
                                                  {t("View")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/EditDispatchTrip"
                                                  className="d-block"
                                                >
                                                  {t("Edit")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={handleShow}
                                              >
                                                <Link
                                                  to="#"
                                                  className="d-block"
                                                >
                                                  {t("Delete")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={() => {
                                                  setShareLink(true);
                                                }}
                                              >
                                                <Link
                                                  to="#"
                                                  className="d-block"
                                                >
                                                  {t("Share")}
                                                </Link>
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Vehicle")}
                                          </p>
                                          <p className="title">Utility Cab</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Trip Category")}
                                          </p>
                                          <p className="title">Pick Up Trip</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Start Time")}
                                          </p>
                                          <p className="title">05:30:00 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("End Time")}
                                          </p>
                                          <p className="title">10:30:00 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-lg-4 col-md-6"
                                        : "col-lg-3 col-md-6"
                                    }
                                  >
                                    <div
                                      className={
                                        "common-vehical-card-inner cv-card p-0"
                                      }
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={trip_icon} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Trip Name")}
                                            </p>
                                            <p className="title">Pune Trip</p>
                                          </div>
                                        </div>
                                        <div className="option customer-option">
                                          <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                              <img src={option} alt="" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/ViewDispatchTrip"
                                                  className="d-block"
                                                >
                                                  {t("View")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/EditDispatchTrip"
                                                  className="d-block"
                                                >
                                                  {t("Edit")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={handleShow}
                                              >
                                                <Link
                                                  to="#"
                                                  className="d-block"
                                                >
                                                  {t("Delete")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={() => {
                                                  setShareLink(true);
                                                }}
                                              >
                                                <Link
                                                  to="#"
                                                  className="d-block"
                                                >
                                                  {t("Share")}
                                                </Link>
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Vehicle")}
                                          </p>
                                          <p className="title">Utility Cab</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Trip Category")}
                                          </p>
                                          <p className="title">Pick Up Trip</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Start Time")}
                                          </p>
                                          <p className="title">05:30:00 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("End Time")}
                                          </p>
                                          <p className="title">10:30:00 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-lg-4 col-md-6"
                                        : "col-lg-3 col-md-6"
                                    }
                                  >
                                    <div
                                      className={
                                        "common-vehical-card-inner cv-card p-0"
                                      }
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={trip_icon} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Trip Name")}
                                            </p>
                                            <p className="title">Pune Trip</p>
                                          </div>
                                        </div>
                                        <div className="option customer-option">
                                          <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                              <img src={option} alt="" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/ViewDispatchTrip"
                                                  className="d-block"
                                                >
                                                  {t("View")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/EditDispatchTrip"
                                                  className="d-block"
                                                >
                                                  {t("Edit")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={handleShow}
                                              >
                                                <Link
                                                  to="#"
                                                  className="d-block"
                                                >
                                                  {t("Delete")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={() => {
                                                  setShareLink(true);
                                                }}
                                              >
                                                <Link
                                                  to="#"
                                                  className="d-block"
                                                >
                                                  {t("Share")}
                                                </Link>
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Vehicle")}
                                          </p>
                                          <p className="title">Utility Cab</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Trip Category")}
                                          </p>
                                          <p className="title">Pick Up Trip</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Start Time")}
                                          </p>
                                          <p className="title">05:30:00 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("End Time")}
                                          </p>
                                          <p className="title">10:30:00 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-lg-4 col-md-6"
                                        : "col-lg-3 col-md-6"
                                    }
                                  >
                                    <div
                                      className={
                                        "common-vehical-card-inner cv-card p-0"
                                      }
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={trip_icon} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Trip Name")}
                                            </p>
                                            <p className="title">Pune Trip</p>
                                          </div>
                                        </div>
                                        <div className="option customer-option">
                                          <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                              <img src={option} alt="" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/ViewDispatchTrip"
                                                  className="d-block"
                                                >
                                                  {t("View")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/EditDispatchTrip"
                                                  className="d-block"
                                                >
                                                  {t("Edit")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={handleShow}
                                              >
                                                <Link
                                                  to="#"
                                                  className="d-block"
                                                >
                                                  {t("Delete")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={() => {
                                                  setShareLink(true);
                                                }}
                                              >
                                                <Link
                                                  to="#"
                                                  className="d-block"
                                                >
                                                  {t("Share")}
                                                </Link>
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Vehicle")}
                                          </p>
                                          <p className="title">Utility Cab</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Trip Category")}
                                          </p>
                                          <p className="title">Pick Up Trip</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Start Time")}
                                          </p>
                                          <p className="title">05:30:00 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("End Time")}
                                          </p>
                                          <p className="title">10:30:00 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      sidebar
                                        ? "col-lg-4 col-md-6"
                                        : "col-lg-3 col-md-6"
                                    }
                                  >
                                    <div
                                      className={
                                        "common-vehical-card-inner cv-card p-0"
                                      }
                                    >
                                      <div className="vehical-card-head">
                                        <div className="heading">
                                          <img src={trip_icon} alt="" />
                                          <div className="">
                                            <p className="sub-heading">
                                              {t("Trip Name")}
                                            </p>
                                            <p className="title">Pune Trip</p>
                                          </div>
                                        </div>
                                        <div className="option customer-option">
                                          <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                              <img src={option} alt="" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/ViewDispatchTrip"
                                                  className="d-block"
                                                >
                                                  {t("View")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item>
                                                <Link
                                                  to="/EditDispatchTrip"
                                                  className="d-block"
                                                >
                                                  {t("Edit")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={handleShow}
                                              >
                                                <Link
                                                  to="#"
                                                  className="d-block"
                                                >
                                                  {t("Delete")}
                                                </Link>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={() => {
                                                  setShareLink(true);
                                                }}
                                              >
                                                <Link
                                                  to="#"
                                                  className="d-block"
                                                >
                                                  {t("Share")}
                                                </Link>
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                      <div className="vehical-card-body row">
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Vehicle")}
                                          </p>
                                          <p className="title">Utility Cab</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Trip Category")}
                                          </p>
                                          <p className="title">Pick Up Trip</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Start Time")}
                                          </p>
                                          <p className="title">05:30:00 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("End Time")}
                                          </p>
                                          <p className="title">10:30:00 AM</p>
                                        </div>
                                        <div className="card-contain col-lg-6">
                                          <p className="sub-heading">
                                            {t("Driver Name")}
                                          </p>
                                          <p className="title">Mr. John Doe</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Tab.Pane>
                        </Tab.Content>
                      </Col>
                    </Tab.Container>
                  </div>
                  <p className="reg-color mt-3">{t("Showing")} 1 - 10 of 200</p>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <Link to="/AddStop">
                  <button className="cx-btn-1 tbBtn">+ {t("New Stop")}</button>
                </Link>
                <div className="main-master-wrapper mb-0 inner-tabs-section tabs-custom-width-33">
                  <div id="scroll_insideThe_Padding">
                    <div className="all-vehicle-main">
                      <div className="all-vehical-head row vehicle-top-inputs">
                        <div className="input-section-wrapper">
                          <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Pick-Up point Trip"
                              />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Pick-Up Point Name, Code..."
                              />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Pick-Up Point Priority"
                              />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Distance From Customer(Km)"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="right-export-btn-section-wrapper">
                          <div className="c-pointer me-2"></div>
                          <div style={{ marginRight: "10px" }} />
                          <div className="right-export-btn-section-wrapper">
                            <div className="c-pointer me-2"></div>

                            <div className="md_dropdown">
                              <Dropdown>
                                <Dropdown.Toggle
                                  id="dropdown-basic"
                                  className="export-toggle"
                                >
                                  <p className="Export_Text">{t("Export")}</p>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item>
                                    <Link
                                      // onClick={() => getExportChat()}
                                      className="d-block"
                                    >
                                      {t("PDF")}
                                    </Link>
                                  </Dropdown.Item>

                                  <Dropdown.Item>
                                    <Link
                                      // onClick={() => downLoadExcelSheet()}
                                      className="d-block"
                                    >
                                      {t("Excel")}
                                    </Link>
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="yauto" id="TransportMananger_height2">
                        <div className="row gx-3 main-cards-wrapper">
                          <div
                            className={
                              sidebar
                                ? "col-lg-4 col-md-6"
                                : "col-lg-3 col-md-6"
                            }
                          >
                            <div
                              className={
                                sidebar
                                  ? "common-vehical-card cv-card p-0"
                                  : "common-vehical-card-inner cv-card p-0"
                              }
                            >
                              <div className="vehical-card-head">
                                <div className="heading">
                                  <img src={stop_icon} alt="" />
                                  <div className="">
                                    <p className="sub-heading">
                                      {t("Stop Name")}
                                    </p>
                                    <p className="title">Pune PMC</p>
                                  </div>
                                </div>
                                <div className="option customer-option">
                                  <Dropdown>
                                    <Dropdown.Toggle id="dropdown-basic">
                                      <img src={option} alt="" />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      <Dropdown.Item>
                                        <Link
                                          to="/ViewStop"
                                          className="d-block"
                                        >
                                          {t("View")}
                                        </Link>
                                      </Dropdown.Item>
                                      <Dropdown.Item>
                                        <Link
                                          to="/EditStop"
                                          className="d-block"
                                        >
                                          {t("Edit")}
                                        </Link>
                                      </Dropdown.Item>
                                      <Dropdown.Item onClick={handleShow2}>
                                        <Link to="#" className="d-block">
                                          {t("Delete")}
                                        </Link>
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </div>
                              <div className="vehical-card-body row">
                                <div className="card-contain col-lg-6">
                                  <p className="sub-heading">
                                    {t("Pick-Up Point Trip")}
                                  </p>
                                  <p className="title">Utility Cab</p>
                                </div>
                                <div className="card-contain col-lg-6">
                                  <p className="sub-heading">
                                    {t("Pick-Up Point Code")}
                                  </p>
                                  <p className="title">Pick-Up Trip</p>
                                </div>
                                <div className="card-contain col-lg-6">
                                  <p className="sub-heading">
                                    {t("Pick-Up Point Priority")}
                                  </p>
                                  <p className="title">05:30:00 AM</p>
                                </div>
                                <div className="card-contain col-lg-6">
                                  <p className="sub-heading">
                                    {t("Distance From Customer")} (KM)
                                  </p>
                                  <p className="title">10:30:00 AM</p>
                                </div>
                                <div className="card-contain col-lg-6">
                                  <p className="sub-heading">
                                    {t("Stop Latitude")}
                                  </p>
                                  <p className="title">Mr. John Doe</p>
                                </div>
                                <div className="card-contain col-lg-6">
                                  <p className="sub-heading">
                                    {t("Stop Longitude")}
                                  </p>
                                  <p className="title">Mr. John Doe</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className={
                              sidebar
                                ? "col-lg-4 col-md-6"
                                : "col-lg-3 col-md-6"
                            }
                          >
                            <div
                              className={
                                sidebar
                                  ? "common-vehical-card cv-card p-0"
                                  : "common-vehical-card-inner cv-card p-0"
                              }
                            >
                              <div className="vehical-card-head">
                                <div className="heading">
                                  <img src={stop_icon} alt="" />
                                  <div className="">
                                    <p className="sub-heading">
                                      {t("Stop Name")}
                                    </p>
                                    <p className="title">Pune PMC</p>
                                  </div>
                                </div>
                                <div className="option customer-option">
                                  <Dropdown>
                                    <Dropdown.Toggle id="dropdown-basic">
                                      <img src={option} alt="" />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      <Dropdown.Item>
                                        <Link
                                          to="/ViewStop"
                                          className="d-block"
                                        >
                                          {t("View")}
                                        </Link>
                                      </Dropdown.Item>
                                      <Dropdown.Item>
                                        <Link
                                          to="/EditStop"
                                          className="d-block"
                                        >
                                          {t("Edit")}
                                        </Link>
                                      </Dropdown.Item>
                                      <Dropdown.Item onClick={handleShow2}>
                                        <Link to="#" className="d-block">
                                          {t("Delete")}
                                        </Link>
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </div>
                              <div className="vehical-card-body row">
                                <div className="card-contain col-lg-6">
                                  <p className="sub-heading">
                                    {t("Pick-Up Point Trip")}
                                  </p>
                                  <p className="title">Utility Cab</p>
                                </div>
                                <div className="card-contain col-lg-6">
                                  <p className="sub-heading">
                                    {t("Pick-Up Point Code")}
                                  </p>
                                  <p className="title">Pick-Up Trip</p>
                                </div>
                                <div className="card-contain col-lg-6">
                                  <p className="sub-heading">
                                    {t("Pick-Up Point Priority")}
                                  </p>
                                  <p className="title">05:30:00 AM</p>
                                </div>
                                <div className="card-contain col-lg-6">
                                  <p className="sub-heading">
                                    {t("Distance From Customer")} (KM)
                                  </p>
                                  <p className="title">10:30:00 AM</p>
                                </div>
                                <div className="card-contain col-lg-6">
                                  <p className="sub-heading">
                                    {t("Stop Latitude")}
                                  </p>
                                  <p className="title">Mr. John Doe</p>
                                </div>
                                <div className="card-contain col-lg-6">
                                  <p className="sub-heading">
                                    {t("Stop Longitude")}
                                  </p>
                                  <p className="title">Mr. John Doe</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className={
                              sidebar
                                ? "col-lg-4 col-md-6"
                                : "col-lg-3 col-md-6"
                            }
                          >
                            <div
                              className={
                                sidebar
                                  ? "common-vehical-card cv-card p-0"
                                  : "common-vehical-card-inner cv-card p-0"
                              }
                            >
                              <div className="vehical-card-head">
                                <div className="heading">
                                  <img src={stop_icon} alt="" />
                                  <div className="">
                                    <p className="sub-heading">
                                      {t("Stop Name")}
                                    </p>
                                    <p className="title">Pune PMC</p>
                                  </div>
                                </div>
                                <div className="option customer-option">
                                  <Dropdown>
                                    <Dropdown.Toggle id="dropdown-basic">
                                      <img src={option} alt="" />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      <Dropdown.Item>
                                        <Link
                                          to="/ViewStop"
                                          className="d-block"
                                        >
                                          {t("View")}
                                        </Link>
                                      </Dropdown.Item>
                                      <Dropdown.Item>
                                        <Link
                                          to="/EditStop"
                                          className="d-block"
                                        >
                                          {t("Edit")}
                                        </Link>
                                      </Dropdown.Item>
                                      <Dropdown.Item onClick={handleShow2}>
                                        <Link to="#" className="d-block">
                                          {t("Delete")}
                                        </Link>
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </div>
                              <div className="vehical-card-body row">
                                <div className="card-contain col-lg-6">
                                  <p className="sub-heading">
                                    {t("Pick-Up Point Trip")}
                                  </p>
                                  <p className="title">Utility Cab</p>
                                </div>
                                <div className="card-contain col-lg-6">
                                  <p className="sub-heading">
                                    {t("Pick-Up Point Code")}
                                  </p>
                                  <p className="title">Pick-Up Trip</p>
                                </div>
                                <div className="card-contain col-lg-6">
                                  <p className="sub-heading">
                                    {t("Pick-Up Point Priority")}
                                  </p>
                                  <p className="title">05:30:00 AM</p>
                                </div>
                                <div className="card-contain col-lg-6">
                                  <p className="sub-heading">
                                    {t("Distance From Customer")} (KM)
                                  </p>
                                  <p className="title">10:30:00 AM</p>
                                </div>
                                <div className="card-contain col-lg-6">
                                  <p className="sub-heading">
                                    {t("Stop Latitude")}
                                  </p>
                                  <p className="title">Mr. John Doe</p>
                                </div>
                                <div className="card-contain col-lg-6">
                                  <p className="sub-heading">
                                    {t("Stop Longitude")}
                                  </p>
                                  <p className="title">Mr. John Doe</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className={
                              sidebar
                                ? "col-lg-4 col-md-6"
                                : "col-lg-3 col-md-6"
                            }
                          >
                            <div
                              className={
                                sidebar
                                  ? "common-vehical-card cv-card p-0"
                                  : "common-vehical-card-inner cv-card p-0"
                              }
                            >
                              <div className="vehical-card-head">
                                <div className="heading">
                                  <img src={stop_icon} alt="" />
                                  <div className="">
                                    <p className="sub-heading">
                                      {t("Stop Name")}
                                    </p>
                                    <p className="title">Pune PMC</p>
                                  </div>
                                </div>
                                <div className="option customer-option">
                                  <Dropdown>
                                    <Dropdown.Toggle id="dropdown-basic">
                                      <img src={option} alt="" />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      <Dropdown.Item>
                                        <Link
                                          to="/ViewStop"
                                          className="d-block"
                                        >
                                          {t("View")}
                                        </Link>
                                      </Dropdown.Item>
                                      <Dropdown.Item>
                                        <Link
                                          to="/EditStop"
                                          className="d-block"
                                        >
                                          {t("Edit")}
                                        </Link>
                                      </Dropdown.Item>
                                      <Dropdown.Item onClick={handleShow2}>
                                        <Link to="#" className="d-block">
                                          {t("Delete")}
                                        </Link>
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </div>
                              <div className="vehical-card-body row">
                                <div className="card-contain col-lg-6">
                                  <p className="sub-heading">
                                    {t("Pick-Up Point Trip")}
                                  </p>
                                  <p className="title">Utility Cab</p>
                                </div>
                                <div className="card-contain col-lg-6">
                                  <p className="sub-heading">
                                    {t("Pick-Up Point Code")}
                                  </p>
                                  <p className="title">Pick-Up Trip</p>
                                </div>
                                <div className="card-contain col-lg-6">
                                  <p className="sub-heading">
                                    {t("Pick-Up Point Priority")}
                                  </p>
                                  <p className="title">05:30:00 AM</p>
                                </div>
                                <div className="card-contain col-lg-6">
                                  <p className="sub-heading">
                                    {t("Distance From Customer")} (KM)
                                  </p>
                                  <p className="title">10:30:00 AM</p>
                                </div>
                                <div className="card-contain col-lg-6">
                                  <p className="sub-heading">
                                    {t("Stop Latitude")}
                                  </p>
                                  <p className="title">Mr. John Doe</p>
                                </div>
                                <div className="card-contain col-lg-6">
                                  <p className="sub-heading">
                                    {t("Stop Longitude")}
                                  </p>
                                  <p className="title">Mr. John Doe</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className={
                              sidebar
                                ? "col-lg-4 col-md-6"
                                : "col-lg-3 col-md-6"
                            }
                          >
                            <div
                              className={
                                sidebar
                                  ? "common-vehical-card cv-card p-0"
                                  : "common-vehical-card-inner cv-card p-0"
                              }
                            >
                              <div className="vehical-card-head">
                                <div className="heading">
                                  <img src={stop_icon} alt="" />
                                  <div className="">
                                    <p className="sub-heading">
                                      {t("Stop Name")}
                                    </p>
                                    <p className="title">Pune PMC</p>
                                  </div>
                                </div>
                                <div className="option customer-option">
                                  <Dropdown>
                                    <Dropdown.Toggle id="dropdown-basic">
                                      <img src={option} alt="" />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      <Dropdown.Item>
                                        <Link
                                          to="/ViewStop"
                                          className="d-block"
                                        >
                                          {t("View")}
                                        </Link>
                                      </Dropdown.Item>
                                      <Dropdown.Item>
                                        <Link
                                          to="/EditStop"
                                          className="d-block"
                                        >
                                          {t("Edit")}
                                        </Link>
                                      </Dropdown.Item>
                                      <Dropdown.Item onClick={handleShow2}>
                                        <Link to="#" className="d-block">
                                          {t("Delete")}
                                        </Link>
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </div>
                              <div className="vehical-card-body row">
                                <div className="card-contain col-lg-6">
                                  <p className="sub-heading">
                                    {t("Pick-Up Point Trip")}
                                  </p>
                                  <p className="title">Utility Cab</p>
                                </div>
                                <div className="card-contain col-lg-6">
                                  <p className="sub-heading">
                                    {t("Pick-Up Point Code")}
                                  </p>
                                  <p className="title">Pick-Up Trip</p>
                                </div>
                                <div className="card-contain col-lg-6">
                                  <p className="sub-heading">
                                    {t("Pick-Up Point Priority")}
                                  </p>
                                  <p className="title">05:30:00 AM</p>
                                </div>
                                <div className="card-contain col-lg-6">
                                  <p className="sub-heading">
                                    {t("Distance From Customer")} (KM)
                                  </p>
                                  <p className="title">10:30:00 AM</p>
                                </div>
                                <div className="card-contain col-lg-6">
                                  <p className="sub-heading">
                                    {t("Stop Latitude")}
                                  </p>
                                  <p className="title">Mr. John Doe</p>
                                </div>
                                <div className="card-contain col-lg-6">
                                  <p className="sub-heading">
                                    {t("Stop Longitude")}
                                  </p>
                                  <p className="title">Mr. John Doe</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className={
                              sidebar
                                ? "col-lg-4 col-md-6"
                                : "col-lg-3 col-md-6"
                            }
                          >
                            <div
                              className={
                                sidebar
                                  ? "common-vehical-card cv-card p-0"
                                  : "common-vehical-card-inner cv-card p-0"
                              }
                            >
                              <div className="vehical-card-head">
                                <div className="heading">
                                  <img src={stop_icon} alt="" />
                                  <div className="">
                                    <p className="sub-heading">
                                      {t("Stop Name")}
                                    </p>
                                    <p className="title">Pune PMC</p>
                                  </div>
                                </div>
                                <div className="option customer-option">
                                  <Dropdown>
                                    <Dropdown.Toggle id="dropdown-basic">
                                      <img src={option} alt="" />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      <Dropdown.Item>
                                        <Link
                                          to="/ViewStop"
                                          className="d-block"
                                        >
                                          {t("View")}
                                        </Link>
                                      </Dropdown.Item>
                                      <Dropdown.Item>
                                        <Link
                                          to="/EditStop"
                                          className="d-block"
                                        >
                                          {t("Edit")}
                                        </Link>
                                      </Dropdown.Item>
                                      <Dropdown.Item onClick={handleShow2}>
                                        <Link to="#" className="d-block">
                                          {t("Delete")}
                                        </Link>
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </div>
                              <div className="vehical-card-body row">
                                <div className="card-contain col-lg-6">
                                  <p className="sub-heading">
                                    {t("Pick-Up Point Trip")}
                                  </p>
                                  <p className="title">Utility Cab</p>
                                </div>
                                <div className="card-contain col-lg-6">
                                  <p className="sub-heading">
                                    {t("Pick-Up Point Code")}
                                  </p>
                                  <p className="title">Pick-Up Trip</p>
                                </div>
                                <div className="card-contain col-lg-6">
                                  <p className="sub-heading">
                                    {t("Pick-Up Point Priority")}
                                  </p>
                                  <p className="title">05:30:00 AM</p>
                                </div>
                                <div className="card-contain col-lg-6">
                                  <p className="sub-heading">
                                    {t("Distance From Customer")} (KM)
                                  </p>
                                  <p className="title">10:30:00 AM</p>
                                </div>
                                <div className="card-contain col-lg-6">
                                  <p className="sub-heading">
                                    {t("Stop Latitude")}
                                  </p>
                                  <p className="title">Mr. John Doe</p>
                                </div>
                                <div className="card-contain col-lg-6">
                                  <p className="sub-heading">
                                    {t("Stop Longitude")}
                                  </p>
                                  <p className="title">Mr. John Doe</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className={
                              sidebar
                                ? "col-lg-4 col-md-6"
                                : "col-lg-3 col-md-6"
                            }
                          >
                            <div
                              className={
                                sidebar
                                  ? "common-vehical-card cv-card p-0"
                                  : "common-vehical-card-inner cv-card p-0"
                              }
                            >
                              <div className="vehical-card-head">
                                <div className="heading">
                                  <img src={stop_icon} alt="" />
                                  <div className="">
                                    <p className="sub-heading">
                                      {t("Stop Name")}
                                    </p>
                                    <p className="title">Pune PMC</p>
                                  </div>
                                </div>
                                <div className="option customer-option">
                                  <Dropdown>
                                    <Dropdown.Toggle id="dropdown-basic">
                                      <img src={option} alt="" />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      <Dropdown.Item>
                                        <Link
                                          to="/ViewStop"
                                          className="d-block"
                                        >
                                          {t("View")}
                                        </Link>
                                      </Dropdown.Item>
                                      <Dropdown.Item>
                                        <Link
                                          to="/EditStop"
                                          className="d-block"
                                        >
                                          {t("Edit")}
                                        </Link>
                                      </Dropdown.Item>
                                      <Dropdown.Item onClick={handleShow2}>
                                        <Link to="#" className="d-block">
                                          {t("Delete")}
                                        </Link>
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </div>
                              <div className="vehical-card-body row">
                                <div className="card-contain col-lg-6">
                                  <p className="sub-heading">
                                    {t("Pick-Up Point Trip")}
                                  </p>
                                  <p className="title">Utility Cab</p>
                                </div>
                                <div className="card-contain col-lg-6">
                                  <p className="sub-heading">
                                    {t("Pick-Up Point Code")}
                                  </p>
                                  <p className="title">Pick-Up Trip</p>
                                </div>
                                <div className="card-contain col-lg-6">
                                  <p className="sub-heading">
                                    {t("Pick-Up Point Priority")}
                                  </p>
                                  <p className="title">05:30:00 AM</p>
                                </div>
                                <div className="card-contain col-lg-6">
                                  <p className="sub-heading">
                                    {t("Distance From Customer")} (KM)
                                  </p>
                                  <p className="title">10:30:00 AM</p>
                                </div>
                                <div className="card-contain col-lg-6">
                                  <p className="sub-heading">
                                    {t("Stop Latitude")}
                                  </p>
                                  <p className="title">Mr. John Doe</p>
                                </div>
                                <div className="card-contain col-lg-6">
                                  <p className="sub-heading">
                                    {t("Stop Longitude")}
                                  </p>
                                  <p className="title">Mr. John Doe</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="reg-color mt-3">{t("Showing")} 1 - 10 of 200</p>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>

          <Modal
            show={show}
            onHide={handleClose}
            centered
            className="common-model"
          >
            <Modal.Header closeButton>
              <Modal.Title>{t("Delete Trip")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {t("Are you sure you want to delete this Trip")} ?
            </Modal.Body>
            <Modal.Footer className="pop-up-modal-footer">
              <button className="cx-btn-1" onClick={handleClose}>
                {t("Close")}
              </button>
              <button className="cx-btn-2" onClick={handleClose}>
                {t("Yes")}
              </button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={show2}
            onHide={handleClose2}
            centered
            className="common-model"
          >
            <Modal.Header closeButton>
              <Modal.Title>{t("Delete Stop")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {t("Are you sure you want to delete this Stop")}?
            </Modal.Body>
            <Modal.Footer className="pop-up-modal-footer">
              <button className="cx-btn-1" onClick={() => setShow2(false)}>
                {t("Close")}
              </button>
              <button className="cx-btn-2" onClick={() => setShow2(false)}>
                {t("Yes")}
              </button>
            </Modal.Footer>
          </Modal>
          {/* Delete Modal Start */}
          <Modal
            Modal
            show={shareLink}
            onHide={() => setShareLink(false)}
            centered
            size="md"
            className="common-model copy_Link"
          >
            <Modal.Header closeButton>
              <Modal.Title>{t("Share Trip")}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="pb-2">
              <p>{t("Link to share")}</p>
              <div className="copy_body">
                <input
                  type="text"
                  Value="https://Vehicletrackingsystem.com/12345"
                  className="form-control"
                />
                <button className="cx-btn-2">
                  <img src={copy} alt="" />
                  {t("Copy Link")}
                </button>
              </div>
            </Modal.Body>
          </Modal>
          {/* Delete Modal End */}
        </div>
        {slider ? <ExistButton onClose={() => setSlider(false)} /> : null}
        {!slider && <PreviousButton onBack={handleClickPrevious} />}
        {!slider && <NextButton onClick={handleClick} />}
        {!slider && <InfoComponent />}
      </div>
    </motion.div>
  );
};

export default TripManagementnew;
