import React, { useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import option from "../../assets/images/option-three-dot.svg";
import webLogo from "../../assets/images/Web-Application-Logo.svg";
import GreenCar from "../../assets/images/Green-car-logo.svg";
import BlueCar from "../../assets/images/Blue-car-logo.svg";
import RedCar from "../../assets/images/red-car-logo.svg";
import YellowCar from "../../assets/images/yellow-car-logo.svg";
import BlackCar from "../../assets/images/black-car-logo.svg";
import flgLogo from "../../assets/images/flagLine.svg";
import flgLogoFill from "../../assets/images/flagFill.svg";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

const LogoUpdate = () => {
  const [show, setShow] = useState(false);
  const { t, i18n } = useTranslation();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { sidebar, setSidebar, Dark, setDark } = useContext(AppContext);
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  return (
    <motion.div
      className={sidebar ? "taskMain " : "cx-active taskMain"}
      id="cx-main"
      variants={aninations}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.1 }}
    >
      <div id="cx-wrapper" className="Logo_Update">
        <div className="topUpdateBtn">
          <button className="cx-btn-3 form_input_main textend">
            <Link to="/UpdateCustomerLogo">{t("Upload")}</Link>
          </button>
        </div>
        <div className="main-dashboard-wrapper CustomerProfile">
          <div className="row update-logo-main">
            <div className="col-lg-4 col-md-6 col-sm-12  update-logo-card">
              <div className="update-logo-head">
                <p>{t("Web Application Logo")}</p>
                <div className="customer-option backNone">
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">
                      <img src={option} alt="" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item>
                        {" "}
                        <Link to="/UpdateCustomerLogo">{t("Change Logo")}</Link>
                      </Dropdown.Item>
                      <Dropdown.Item onClick={handleShow}>
                        <Link to="#">{t("Delete Logo")}</Link>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="update-logo-img">
                <img src={webLogo} alt="" />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12  update-logo-card">
              <div className="update-logo-head">
                <p>{t("Customer location in Google map")}</p>
                <div className="customer-option backNone">
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">
                      <img src={option} alt="" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item>
                        {" "}
                        <Link to="/UpdateCustomerLogo"> {t("Change Logo")} </Link>
                      </Dropdown.Item>
                      <Dropdown.Item onClick={handleShow}>
                        <Link to="#">{t("Delete Logo")}</Link>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="update-logo-img">
                <img src={GreenCar} alt="" />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12  update-logo-card">
              <div className="update-logo-head">
                <p>{t("Bus location in Google map")}</p>
              </div>
              <div className="update-logo-img">
                <img src={BlueCar} alt="" />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12  update-logo-card">
              <div className="update-logo-head">
                <p>{t("Parked Bus location In Google Map")}</p>
              </div>
              <div className="update-logo-img">
                <img src={RedCar} alt="" />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12  update-logo-card">
              <div className="update-logo-head">
                <p>{t("Untracked Bus location in Google map")}</p>
              </div>
              <div className="update-logo-img">
                <img src={YellowCar} alt="" />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12  update-logo-card">
              <div className="update-logo-head">
                <p>{t("Idle Bus location in Google map")}</p>
              </div>
              <div className="update-logo-img">
                <img src={BlackCar} alt="" />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12  update-logo-card">
              <div className="update-logo-head">
                <p>{t("Logo For Email Template")}</p>
                <div className="customer-option backNone">
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">
                      <img src={option} alt="" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item>
                        {" "}
                        <Link to="/UpdateCustomerLogo"> {t("Change Logo")} </Link>
                      </Dropdown.Item>
                      <Dropdown.Item onClick={handleShow}>
                        <Link to="#">{t("Delete Logo")}</Link>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="update-logo-img">
                <img src={webLogo} alt="" />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12  update-logo-card">
              <div className="update-logo-head">
                <p>{t("Logo For Email Banner")}</p>
                <div className="customer-option backNone">
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">
                      <img src={option} alt="" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item>{t("Change Logo")}</Dropdown.Item>
                      <Dropdown.Item onClick={handleShow}>
                        <Link to="#">{t("Delete Logo")}</Link>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="update-logo-img">
                <img src={webLogo} alt="" />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12  update-logo-card">
              <div className="update-logo-head">
                <p>{t("Logo Trip Start Point")}</p>
                <div className="customer-option backNone">
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">
                      <img src={option} alt="" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item>
                        {" "}
                        <Link to="/UpdateCustomerLogo"> {t("Change Logo")} </Link>
                      </Dropdown.Item>
                      <Dropdown.Item onClick={handleShow}>
                        <Link to="#">{t("Delete Logo")}</Link>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="update-logo-img">
                <img src={flgLogo} alt="" />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12  update-logo-card">
              <div className="update-logo-head">
                <p>{t("Logo Trip End Point")}</p>
                <div className="customer-option backNone">
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">
                      <img src={option} alt="" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item>
                        {" "}
                        <Link to="/UpdateCustomerLogo"> {t("Change Logo")} </Link>
                      </Dropdown.Item>
                      <Dropdown.Item onClick={handleShow}>
                        <Link to="#">{t("Delete Logo")}</Link>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="update-logo-img">
                <img src={flgLogoFill} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div>
          <Modal
            show={show}
            onHide={handleClose}
            centered
            className="common-model"
          >
            <Modal.Header closeButton>
              <Modal.Title>{t("Delete Customer Logo")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {t("Are you sure you want to delete this Customer Logo")} ?
            </Modal.Body>
            <Modal.Footer className="pop-up-modal-footer btn-wrapper">
              <button className="cx-btn-1" onClick={handleClose}>
                {t("Close")}
              </button>
              <button className="cx-btn-2" onClick={handleClose}>
                {t("Yes")}
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </motion.div>
  );
};

export default LogoUpdate;
