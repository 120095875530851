import { React, useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { Link, useNavigate } from "react-router-dom";
import Logout from "../../assets/images/import_icon.svg";
import Share from "../../assets/images/XMLID_1022_.svg";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Nav from "react-bootstrap/Nav";
import option from "../../assets/images/option-three-dot.svg";
import { Accordion, Col, Dropdown, Modal } from "react-bootstrap";
import { motion } from "framer-motion";
import MapComponent from "../../sharedComponent/MapComponent";
import playback from "../../assets/images/AudioControls/Playback.svg";
import backword from "../../assets/images/AudioControls/Backword.svg";
import Forword from "../../assets/images/AudioControls/Forword.svg";
import Forword1 from "../../assets/images/AudioControls/Forword1.svg";
import Pause from "../../assets/images/AudioControls/Pause.svg";
import Replay from "../../assets/images/AudioControls/Replay.svg";
import play from "../../assets/images/AudioControls/Play.svg";
import { useTranslation } from "react-i18next";
import PreviousButton from "../../sharedComponent/PreviousButton";
import NextButton from "../../sharedComponent/Nextbutton";
import ExistButton from "../../sharedComponent/ExistButton";

const RepleyplayBack = ({ onClick }) => {
  const { sidebar, setSidebar, Dark, setDark, slider, setSlider } =
    useContext(AppContext);
  const [tabClicked, setTabClicked] = useState(false);
  const [navClicked, setNavClicked] = useState(false);
  const [isView, setIsView] = useState(false);
  const [show, setShow] = useState(false);
  const { t, i18n } = useTranslation();

  const handleClose = () => setShow(false);
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  const navigate = useNavigate();
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    navigate("/ParkingManagement");
  };
  const handleClickPrevious = () => {
    navigate("/AddParkingSlot");
  };
  return (
    <>
      <motion.div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }}
      >
        <div id="cx-wrapper" className="Parking_Slot ReplayPlayback">
          <div className="upperMainSet">
            <div className="row top-content g-3">
              <div className="col-lg-10 col-md-12 arrange-margin left">
                <div className="row p-0">
                  <div className="col-md-3">
                    <div className="weekCounter">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Select Car"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="weekCounter">
                      <input
                        type="date"
                        className="form-control"
                        placeholder="date"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="weekCounter">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Time From"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="weekCounter">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Time To"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-md-12 mainCol4 right">
                <div className="leftSideSec">
                  <Link to="#">
                    <div className="inconMain">
                      <img src={Logout} alt="" />
                    </div>
                  </Link>
                  {/* <Link to="#">
                    <div className="inconMain left-margin me-0">
                      <img src={Share} alt="" />
                    </div>
                  </Link> */}

                  <div style={{ marginRight: "10px" }} />
                  <div className="right-export-btn-section-wrapper">
                    <div className="c-pointer me-2"></div>

                    <div className="md_dropdown">
                      <Dropdown>
                        <Dropdown.Toggle
                          id="dropdown-basic"
                          className="export-toggle"
                        >
                          <p className="Export_Text">{t("Export")}</p>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item>
                            <Link
                              // onClick={() => getExportChat()}
                              className="d-block"
                            >
                              {t("PDF")}
                            </Link>
                          </Dropdown.Item>

                          <Dropdown.Item>
                            <Link
                              // onClick={() => downLoadExcelSheet()}
                              className="d-block"
                            >
                              {t("Excel")}
                            </Link>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row body-content g-3">
              <div className="col-lg-8 col-md-12">
                <div className="mainMape" id="ReplayPlayback">
                  <div className="Custom_header">
                    <div className="heading">{t("Map Overview")}</div>
                    <div className="controls">
                      <button>
                        <img src={play} alt="" />
                      </button>
                      <button>
                        <img src={Pause} alt="" />
                      </button>
                      <button>
                        <img src={Replay} alt="" />
                      </button>
                      <button>
                        <img src={playback} alt="" />
                      </button>
                      <button>
                        <img src={Forword1} alt="" />
                      </button>
                      <button>
                        <img src={backword} alt="" />
                      </button>
                      <button>
                        <img src={Forword} alt="" />
                      </button>
                    </div>
                  </div>
                  <MapComponent />
                  {tabClicked ? (
                    // <div className="innerMapDetails " id="model-responsive-on-map parkinManager">
                    //     <div className="headingDetails">
                    //         <div className="headingTxt">
                    //             <p className="heading">Parking Station Details</p>
                    //         </div>
                    //         <div className="customer-option">
                    //             <Dropdown className={isView ? `d-none` : ""}>
                    //                 <Dropdown.Toggle id="dropdown-basic">
                    //                     <img src={option} alt="" />
                    //                 </Dropdown.Toggle>

                    //                 <Dropdown.Menu>
                    //                     <Dropdown.Item>
                    //                         {" "}
                    //                         <Link to="#" className="d-block">Edit</Link>
                    //                     </Dropdown.Item>
                    //                     <Dropdown.Item>
                    //                         <Link to="#" onClick={() => setShow(true)} className="d-block">Delete</Link>
                    //                     </Dropdown.Item>
                    //                 </Dropdown.Menu>
                    //             </Dropdown>
                    //         </div>
                    //     </div>
                    //     <div className="innerCOntent">
                    //         <div className="row">
                    //             <div className="col-md-3 dividedCol form_input_main">
                    //                 <p className="paraHead">Customer Name</p>
                    //                 <p className="paraVal">Mr. John Doe</p>
                    //             </div>
                    //             <div className="col-md-3 dividedCol form_input_main">
                    //                 <p className="paraHead">Slot Name</p>
                    //                 <p className="paraVal">Parking Slot 1</p>
                    //             </div>
                    //             <div className="col-md-3 dividedCol form_input_main">
                    //                 <p className="paraHead">Slot ID</p>
                    //                 <p className="paraVal">43311</p>
                    //             </div>
                    //             <div className="col-md-3 dividedCol form_input_main">
                    //                 <p className="paraHead">Slot Code</p>
                    //                 <p className="paraVal">#123</p>
                    //             </div>
                    //             <div className="col-md-12 dividedCol form_input_main">
                    //                 <p className="paraHead">Address</p>
                    //                 <p className="paraVal">
                    //                     SGRA 82, Shanti Gardens Third St, Thrimoorthy Nagar,
                    //                     Kamaleshwaram, Thiruvananthapuram, Kerala 695009,
                    //                     India
                    //                 </p>
                    //             </div>
                    //             <div className="col-md-3 dividedCol form_input_main">
                    //                 <p className="paraHead">Slot GPS Lattitude</p>
                    //                 <p className="paraVal">8.465844813567859</p>
                    //             </div>
                    //             <div className="col-md-3 dividedCol form_input_main">
                    //                 <p className="paraHead">Slot GPS Longitude</p>
                    //                 <p className="paraVal">76.9438642156782</p>
                    //             </div>
                    //             <div className="col-md-3 dividedCol form_input_main">
                    //                 <p className="paraHead">Slot Status</p>
                    //                 <p className="paraVal">Active / Inactive / Suggested</p>
                    //             </div>
                    //         </div>
                    //     </div>
                    // </div>
                    <></>
                  ) : null}
                </div>
              </div>
              <div className="col-lg-4 col-md-12">
                <div className="tabsCon">
                  <Tab.Container
                    id="left-tabs-example"
                    className="td-tab-wrapper"
                    defaultActiveKey="0"
                  >
                    <Col sm={12} className="">
                      <Tab.Content>
                        <Tab.Pane eventKey="0">
                          <div id="navPills">
                            {/* <input type="text" className="form-control mb-2" placeholder="Searched Results" /> */}
                            <div className="headGeo">
                              <p className="heading">{t("Searched Results")}</p>
                            </div>
                            <Nav variant="pills" className="flex-column" id="">
                              <Nav.Item
                                onClick={() => {
                                  setTabClicked(true);
                                  setNavClicked(false);
                                }}
                              >
                                <Nav.Link eventKey="first">
                                  <div className="AllFlexNav">
                                    <div>
                                      <p className="paraNav">
                                        {t("Driver Name")}
                                      </p>
                                      <p className="paraNavTxt">Mr. John Doe</p>
                                    </div>
                                    <div>
                                      <p className="paraNav">{t("From")}</p>
                                      <p className="paraNavTxt">02:03:58s PM</p>
                                    </div>
                                    <div>
                                      <p className="paraNav">{t("To")}</p>
                                      <p className="paraNavTxt">02:03:58s PM</p>
                                    </div>
                                  </div>
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item
                                onClick={() => {
                                  setTabClicked(true);
                                  setNavClicked(false);
                                }}
                              >
                                <Nav.Link eventKey="Second">
                                  <div className="AllFlexNav">
                                    <div>
                                      <p className="paraNav">
                                        {t("Driver Name")}
                                      </p>
                                      <p className="paraNavTxt">Mr. John Doe</p>
                                    </div>
                                    <div>
                                      <p className="paraNav">{t("From")}</p>
                                      <p className="paraNavTxt">02:03:58s PM</p>
                                    </div>
                                    <div>
                                      <p className="paraNav">{t("To")}</p>
                                      <p className="paraNavTxt">02:03:58s PM</p>
                                    </div>
                                  </div>
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item
                                onClick={() => {
                                  setTabClicked(true);
                                  setNavClicked(false);
                                }}
                              >
                                <Nav.Link eventKey="third">
                                  <div className="AllFlexNav">
                                    <div>
                                      <p className="paraNav">
                                        {t("Driver Name")}
                                      </p>
                                      <p className="paraNavTxt">Mr. John Doe</p>
                                    </div>
                                    <div>
                                      <p className="paraNav">{t("From")}</p>
                                      <p className="paraNavTxt">02:03:58s PM</p>
                                    </div>
                                    <div>
                                      <p className="paraNav">{t("To")}</p>
                                      <p className="paraNavTxt">02:03:58s PM</p>
                                    </div>
                                  </div>
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item
                                onClick={() => {
                                  setTabClicked(true);
                                  setNavClicked(false);
                                }}
                              >
                                <Nav.Link eventKey="foure">
                                  <div className="AllFlexNav">
                                    <div>
                                      <p className="paraNav">
                                        {t("Driver Name")}
                                      </p>
                                      <p className="paraNavTxt">Mr. John Doe</p>
                                    </div>
                                    <div>
                                      <p className="paraNav">{t("From")}</p>
                                      <p className="paraNavTxt">02:03:58s PM</p>
                                    </div>
                                    <div>
                                      <p className="paraNav">{t("To")}</p>
                                      <p className="paraNavTxt">02:03:58s PM</p>
                                    </div>
                                  </div>
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item
                                onClick={() => {
                                  setTabClicked(true);
                                  setNavClicked(false);
                                }}
                              >
                                <Nav.Link eventKey="five">
                                  <div className="AllFlexNav">
                                    <div>
                                      <p className="paraNav">
                                        {t("Driver Name")}
                                      </p>
                                      <p className="paraNavTxt">Mr. John Doe</p>
                                    </div>
                                    <div>
                                      <p className="paraNav">{t("From")}</p>
                                      <p className="paraNavTxt">02:03:58s PM</p>
                                    </div>
                                    <div>
                                      <p className="paraNav">{t("To")}</p>
                                      <p className="paraNavTxt">02:03:58s PM</p>
                                    </div>
                                  </div>
                                </Nav.Link>
                              </Nav.Item>
                            </Nav>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Tab.Container>
                </div>
              </div>
            </div>
          </div>
          {slider ? <ExistButton onClose={() => setSlider(false)} /> : null}
          {!slider && <PreviousButton onBack={handleClickPrevious} />}
          {!slider && <NextButton onClick={handleClick} />}
        </div>
      </motion.div>
    </>
  );
};

export default RepleyplayBack;
