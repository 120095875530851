import React, { useContext } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import View from "../../../assets/images/Group.svg";
import Export from "../../../assets/images/Edit-Camunication.svg";
import { motion } from "framer-motion";
import Pagenation from "../../../sharedComponent/Pagenation";
const Email = () => {
  const { sidebar, setSidebar, Dark, setDark } = useContext(AppContext);
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  return (
    <motion.div
      className={sidebar ? "taskMain " : "cx-active taskMain"}
      id="cx-main"
      variants={aninations}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.1 }}
    >
      <div id="cx-wrapper">
        <div className="Vehcle-main-tabs" id="Email_Responsive">
          <div className="outer-tab-vehicle">
            <Tabs
              defaultActiveKey="home"
              id="uncontrolled-tab-example"
              className="m-0 mr-0 communication-tabs"
            >
              <Tab eventKey="home" title="Administrator">
                <div className="main-master-wrapper">
                  <div id="scroll_insideThe_Padding_tabel">
                    <div className="all-vehical-head row vehicle-top-inputs">
                      <div className="input-section-wrapper">
                        <div className="row">
                          <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Trip Name"
                            />
                          </div>
                          <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                            <select
                              required
                              className="form-select"
                              aria-label="Default select example"
                              placeholder="Vehicle"
                            >
                              <option value="">Vehicle</option>
                              <option value="1">One</option>
                              <option value="2">Two</option>
                              <option value="3">Three</option>
                            </select>
                          </div>
                          <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                            <select
                              required
                              className="form-select"
                              aria-label="Default select example"
                              placeholder="Driver"
                            >
                              <option value="">Driver</option>
                              <option value="1">One</option>
                              <option value="2">Two</option>
                              <option value="3">Three</option>
                            </select>
                          </div>
                          <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Subject"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="right-export-btn-section-wrapper">
                        <div className="c-pointer">
                          <Link to="/ComposeEmail">
                            <img src={Export} alt="" />
                          </Link>
                        </div>
                        <div className="c-pointer">
                          {/* <img src={Import} alt="" /> */}
                        </div>
                      </div>
                    </div>
                    <div className="yauto">
                      <table className="table tableAdmin tabelheiht">
                        <thead className="tableHead">
                          <tr>
                            <th>Sr.no</th>
                            <th>Trip Name</th>
                            <th>Vehicle</th>
                            <th>Driver</th>
                            <th>Subject</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody className="tableBody">
                          <tr>
                            <td>1</td>
                            <td>Mr. John Doe</td>
                            <td>Mr. John Doe</td>
                            <td>john@gmail.com </td>
                            <td>+91 99 99 99 9999</td>
                            <td>
                              <div className="innerFlex">
                                <Link to="/EmailDetails">
                                  <img src={View} className="me-3" alt="" />
                                </Link>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>Mr. John Doe</td>
                            <td>Mr. John Doe</td>
                            <td>john@gmail.com </td>
                            <td>+91 99 99 99 9999</td>
                            <td>
                              <div className="innerFlex">
                                <Link to="/EmailDetails">
                                  <img src={View} className="me-3" alt="" />
                                </Link>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td>Mr. John Doe</td>
                            <td>Mr. John Doe</td>
                            <td>john@gmail.com </td>
                            <td>+91 99 99 99 9999</td>
                            <td>
                              <div className="innerFlex">
                                <Link to="/EmailDetails">
                                  <img src={View} className="me-3" alt="" />
                                </Link>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>4</td>
                            <td>Mr. John Doe</td>
                            <td>Mr. John Doe</td>
                            <td>john@gmail.com </td>
                            <td>+91 99 99 99 9999</td>
                            <td>
                              <div className="innerFlex">
                                <Link to="/EmailDetails">
                                  <img src={View} className="me-3" alt="" />
                                </Link>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>5</td>
                            <td>Mr. John Doe</td>
                            <td>Mr. John Doe</td>
                            <td>john@gmail.com </td>
                            <td>+91 99 99 99 9999</td>
                            <td>
                              <div className="innerFlex">
                                <Link to="/EmailDetails">
                                  <img src={View} className="me-3" alt="" />
                                </Link>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>6</td>
                            <td>Mr. John Doe</td>
                            <td>Mr. John Doe</td>
                            <td>john@gmail.com </td>
                            <td>+91 99 99 99 9999</td>
                            <td>
                              <div className="innerFlex">
                                <Link to="/EmailDetails">
                                  <img src={View} className="me-3" alt="" />
                                </Link>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <Pagenation />
                </div>
              </Tab>
              <Tab eventKey="Transport Manager" title="Transport Manager">
                <div className="main-master-wrapper">
                  <div id="scroll_insideThe_Padding_tabel">
                    <div className="all-vehical-head row vehicle-top-inputs">
                      <div className="input-section-wrapper">
                        <div className="row">
                          <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Trip Name"
                            />
                          </div>
                          <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                            <select
                              required
                              className="form-select"
                              aria-label="Default select example"
                              placeholder="Vehicle"
                            >
                              <option value="">Vehicle</option>
                              <option value="1">One</option>
                              <option value="2">Two</option>
                              <option value="3">Three</option>
                            </select>
                          </div>
                          <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                            <select
                              required
                              className="form-select"
                              aria-label="Default select example"
                              placeholder="Driver"
                            >
                              <option value="">Driver</option>
                              <option value="1">One</option>
                              <option value="2">Two</option>
                              <option value="3">Three</option>
                            </select>
                          </div>
                          <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Subject"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="right-export-btn-section-wrapper">
                        <div className="c-pointer">
                          <Link to="/ComposeEmail">
                            <img src={Export} alt="" />
                          </Link>
                        </div>
                        <div className="c-pointer">
                          {/* <img src={Import} alt="" /> */}
                        </div>
                      </div>
                    </div>
                    <div className="yauto">
                      <table className="table tableAdmin tabelheiht">
                        <thead className="tableHead">
                          <tr>
                            <th>Sr.no</th>
                            <th>Trip Name</th>
                            <th>Vehicle</th>
                            <th>Driver</th>
                            <th>Subject</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody className="tableBody">
                          <tr>
                            <td>1</td>
                            <td>Mr. John Doe</td>
                            <td>Mr. John Doe</td>
                            <td>john@gmail.com </td>
                            <td>+91 99 99 99 9999</td>
                            <td>
                              <div className="innerFlex">
                                <Link to="/EmailDetails">
                                  <img src={View} className="me-3" alt="" />
                                </Link>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>Mr. John Doe</td>
                            <td>Mr. John Doe</td>
                            <td>john@gmail.com </td>
                            <td>+91 99 99 99 9999</td>
                            <td>
                              <div className="innerFlex">
                                <Link to="/EmailDetails">
                                  <img src={View} className="me-3" alt="" />
                                </Link>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td>Mr. John Doe</td>
                            <td>Mr. John Doe</td>
                            <td>john@gmail.com </td>
                            <td>+91 99 99 99 9999</td>
                            <td>
                              <div className="innerFlex">
                                <Link to="/EmailDetails">
                                  <img src={View} className="me-3" alt="" />
                                </Link>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>4</td>
                            <td>Mr. John Doe</td>
                            <td>Mr. John Doe</td>
                            <td>john@gmail.com </td>
                            <td>+91 99 99 99 9999</td>
                            <td>
                              <div className="innerFlex">
                                <Link to="/EmailDetails">
                                  <img src={View} className="me-3" alt="" />
                                </Link>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>5</td>
                            <td>Mr. John Doe</td>
                            <td>Mr. John Doe</td>
                            <td>john@gmail.com </td>
                            <td>+91 99 99 99 9999</td>
                            <td>
                              <div className="innerFlex">
                                <Link to="/EmailDetails">
                                  <img src={View} className="me-3" alt="" />
                                </Link>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>6</td>
                            <td>Mr. John Doe</td>
                            <td>Mr. John Doe</td>
                            <td>john@gmail.com </td>
                            <td>+91 99 99 99 9999</td>
                            <td>
                              <div className="innerFlex">
                                <Link to="/EmailDetails">
                                  <img src={View} className="me-3" alt="" />
                                </Link>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <Pagenation />
                </div>
              </Tab>
              <Tab eventKey="Drivers" title="Drivers">
                <div className="main-master-wrapper">
                  <div id="scroll_insideThe_Padding_tabel">
                    <div className="all-vehical-head row vehicle-top-inputs">
                      <div className="input-section-wrapper">
                        <div className="row">
                          <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Trip Name"
                            />
                          </div>
                          <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                            <select
                              required
                              className="form-select"
                              aria-label="Default select example"
                              placeholder="Vehicle"
                            >
                              <option value="">Vehicle</option>
                              <option value="1">One</option>
                              <option value="2">Two</option>
                              <option value="3">Three</option>
                            </select>
                          </div>
                          <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                            <select
                              required
                              className="form-select"
                              aria-label="Default select example"
                              placeholder="Driver"
                            >
                              <option value="">Driver</option>
                              <option value="1">One</option>
                              <option value="2">Two</option>
                              <option value="3">Three</option>
                            </select>
                          </div>
                          <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Subject"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="right-export-btn-section-wrapper">
                        <div className="c-pointer">
                          <Link to="/ComposeEmail">
                            <img src={Export} alt="" />
                          </Link>
                        </div>
                        <div className="c-pointer">
                          {/* <img src={Import} alt="" /> */}
                        </div>
                      </div>
                    </div>
                    <div className="yauto">
                      <table className="table tableAdmin tabelheiht">
                        <thead className="tableHead">
                          <tr>
                            <th>Sr.no</th>
                            <th>Trip Name</th>
                            <th>Vehicle</th>
                            <th>Driver</th>
                            <th>Subject</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody className="tableBody">
                          <tr>
                            <td>1</td>
                            <td>Mr. John Doe</td>
                            <td>Mr. John Doe</td>
                            <td>john@gmail.com </td>
                            <td>+91 99 99 99 9999</td>
                            <td>
                              <div className="innerFlex">
                                <Link to="/EmailDetails">
                                  <img src={View} className="me-3" alt="" />
                                </Link>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>Mr. John Doe</td>
                            <td>Mr. John Doe</td>
                            <td>john@gmail.com </td>
                            <td>+91 99 99 99 9999</td>
                            <td>
                              <div className="innerFlex">
                                <Link to="/EmailDetails">
                                  <img src={View} className="me-3" alt="" />
                                </Link>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td>Mr. John Doe</td>
                            <td>Mr. John Doe</td>
                            <td>john@gmail.com </td>
                            <td>+91 99 99 99 9999</td>
                            <td>
                              <div className="innerFlex">
                                <Link to="/EmailDetails">
                                  <img src={View} className="me-3" alt="" />
                                </Link>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>4</td>
                            <td>Mr. John Doe</td>
                            <td>Mr. John Doe</td>
                            <td>john@gmail.com </td>
                            <td>+91 99 99 99 9999</td>
                            <td>
                              <div className="innerFlex">
                                <Link to="/EmailDetails">
                                  <img src={View} className="me-3" alt="" />
                                </Link>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>5</td>
                            <td>Mr. John Doe</td>
                            <td>Mr. John Doe</td>
                            <td>john@gmail.com </td>
                            <td>+91 99 99 99 9999</td>
                            <td>
                              <div className="innerFlex">
                                <Link to="/EmailDetails">
                                  <img src={View} className="me-3" alt="" />
                                </Link>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>6</td>
                            <td>Mr. John Doe</td>
                            <td>Mr. John Doe</td>
                            <td>john@gmail.com </td>
                            <td>+91 99 99 99 9999</td>
                            <td>
                              <div className="innerFlex">
                                <Link to="/EmailDetails">
                                  <img src={View} className="me-3" alt="" />
                                </Link>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <Pagenation />
                </div>
              </Tab>
              <Tab eventKey="Delivery Person" title="Delivery Person">
                <div className="main-master-wrapper">
                  <div id="scroll_insideThe_Padding_tabel">
                    <div className="all-vehical-head row vehicle-top-inputs">
                      <div className="input-section-wrapper">
                        <div className="row">
                          <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Trip Name"
                            />
                          </div>
                          <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                            <select
                              required
                              className="form-select"
                              aria-label="Default select example"
                              placeholder="Vehicle"
                            >
                              <option value="">Vehicle</option>
                              <option value="1">One</option>
                              <option value="2">Two</option>
                              <option value="3">Three</option>
                            </select>
                          </div>
                          <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                            <select
                              required
                              className="form-select"
                              aria-label="Default select example"
                              placeholder="Driver"
                            >
                              <option value="">Driver</option>
                              <option value="1">One</option>
                              <option value="2">Two</option>
                              <option value="3">Three</option>
                            </select>
                          </div>
                          <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Subject"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="right-export-btn-section-wrapper">
                        <div className="c-pointer">
                          <Link to="/ComposeEmail">
                            <img src={Export} alt="" />
                          </Link>
                        </div>
                        <div className="c-pointer">
                          {/* <img src={Import} alt="" /> */}
                        </div>
                      </div>
                    </div>
                    <div className="yauto">
                      <table className="table tableAdmin tabelheiht">
                        <thead className="tableHead">
                          <tr>
                            <th>Sr.no</th>
                            <th>Trip Name</th>
                            <th>Vehicle</th>
                            <th>Driver</th>
                            <th>Subject</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody className="tableBody">
                          <tr>
                            <td>1</td>
                            <td>Mr. John Doe</td>
                            <td>Mr. John Doe</td>
                            <td>john@gmail.com </td>
                            <td>+91 99 99 99 9999</td>
                            <td>
                              <div className="innerFlex">
                                <Link to="/EmailDetails">
                                  <img src={View} className="me-3" alt="" />
                                </Link>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>Mr. John Doe</td>
                            <td>Mr. John Doe</td>
                            <td>john@gmail.com </td>
                            <td>+91 99 99 99 9999</td>
                            <td>
                              <div className="innerFlex">
                                <Link to="/EmailDetails">
                                  <img src={View} className="me-3" alt="" />
                                </Link>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td>Mr. John Doe</td>
                            <td>Mr. John Doe</td>
                            <td>john@gmail.com </td>
                            <td>+91 99 99 99 9999</td>
                            <td>
                              <div className="innerFlex">
                                <Link to="/EmailDetails">
                                  <img src={View} className="me-3" alt="" />
                                </Link>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>4</td>
                            <td>Mr. John Doe</td>
                            <td>Mr. John Doe</td>
                            <td>john@gmail.com </td>
                            <td>+91 99 99 99 9999</td>
                            <td>
                              <div className="innerFlex">
                                <Link to="/EmailDetails">
                                  <img src={View} className="me-3" alt="" />
                                </Link>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>5</td>
                            <td>Mr. John Doe</td>
                            <td>Mr. John Doe</td>
                            <td>john@gmail.com </td>
                            <td>+91 99 99 99 9999</td>
                            <td>
                              <div className="innerFlex">
                                <Link to="/EmailDetails">
                                  <img src={View} className="me-3" alt="" />
                                </Link>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>6</td>
                            <td>Mr. John Doe</td>
                            <td>Mr. John Doe</td>
                            <td>john@gmail.com </td>
                            <td>+91 99 99 99 9999</td>
                            <td>
                              <div className="innerFlex">
                                <Link to="/EmailDetails">
                                  <img src={View} className="me-3" alt="" />
                                </Link>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <Pagenation />
                </div>
              </Tab>
              <Tab eventKey="Fleet Manager" title="Fleet Manager">
                <div className="main-master-wrapper">
                  <div id="scroll_insideThe_Padding_tabel">
                    <div className="all-vehical-head row vehicle-top-inputs">
                      <div className="input-section-wrapper">
                        <div className="row">
                          <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Trip Name"
                            />
                          </div>
                          <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                            <select
                              required
                              className="form-select"
                              aria-label="Default select example"
                              placeholder="Vehicle"
                            >
                              <option value="">Vehicle</option>
                              <option value="1">One</option>
                              <option value="2">Two</option>
                              <option value="3">Three</option>
                            </select>
                          </div>
                          <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                            <select
                              required
                              className="form-select"
                              aria-label="Default select example"
                              placeholder="Driver"
                            >
                              <option value="">Driver</option>
                              <option value="1">One</option>
                              <option value="2">Two</option>
                              <option value="3">Three</option>
                            </select>
                          </div>
                          <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Subject"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="right-export-btn-section-wrapper">
                        <div className="c-pointer">
                          <Link to="/ComposeEmail">
                            <img src={Export} alt="" />
                          </Link>
                        </div>
                        <div className="c-pointer">
                          {/* <img src={Import} alt="" /> */}
                        </div>
                      </div>
                    </div>
                    <div className="yauto">
                      <table className="table tableAdmin tabelheiht">
                        <thead className="tableHead">
                          <tr>
                            <th>Sr.no</th>
                            <th>Trip Name</th>
                            <th>Vehicle</th>
                            <th>Driver</th>
                            <th>Subject</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody className="tableBody">
                          <tr>
                            <td>1</td>
                            <td>Mr. John Doe</td>
                            <td>Mr. John Doe</td>
                            <td>john@gmail.com </td>
                            <td>+91 99 99 99 9999</td>
                            <td>
                              <div className="innerFlex">
                                <Link to="/EmailDetails">
                                  <img src={View} className="me-3" alt="" />
                                </Link>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>Mr. John Doe</td>
                            <td>Mr. John Doe</td>
                            <td>john@gmail.com </td>
                            <td>+91 99 99 99 9999</td>
                            <td>
                              <div className="innerFlex">
                                <Link to="/EmailDetails">
                                  <img src={View} className="me-3" alt="" />
                                </Link>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td>Mr. John Doe</td>
                            <td>Mr. John Doe</td>
                            <td>john@gmail.com </td>
                            <td>+91 99 99 99 9999</td>
                            <td>
                              <div className="innerFlex">
                                <Link to="/EmailDetails">
                                  <img src={View} className="me-3" alt="" />
                                </Link>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>4</td>
                            <td>Mr. John Doe</td>
                            <td>Mr. John Doe</td>
                            <td>john@gmail.com </td>
                            <td>+91 99 99 99 9999</td>
                            <td>
                              <div className="innerFlex">
                                <Link to="/EmailDetails">
                                  <img src={View} className="me-3" alt="" />
                                </Link>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>5</td>
                            <td>Mr. John Doe</td>
                            <td>Mr. John Doe</td>
                            <td>john@gmail.com </td>
                            <td>+91 99 99 99 9999</td>
                            <td>
                              <div className="innerFlex">
                                <Link to="/EmailDetails">
                                  <img src={View} className="me-3" alt="" />
                                </Link>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>6</td>
                            <td>Mr. John Doe</td>
                            <td>Mr. John Doe</td>
                            <td>john@gmail.com </td>
                            <td>+91 99 99 99 9999</td>
                            <td>
                              <div className="innerFlex">
                                <Link to="/EmailDetails">
                                  <img src={View} className="me-3" alt="" />
                                </Link>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <Pagenation />
                </div>
              </Tab>
              <Tab eventKey="Vehicle Assistant" title="Vehicle Assistant">
                <div className="main-master-wrapper">
                  <div id="scroll_insideThe_Padding_tabel">
                    <div className="all-vehical-head row vehicle-top-inputs">
                      <div className="input-section-wrapper">
                        <div className="row">
                          <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Trip Name"
                            />
                          </div>
                          <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                            <select
                              required
                              className="form-select"
                              aria-label="Default select example"
                              placeholder="Vehicle"
                            >
                              <option value="">Vehicle</option>
                              <option value="1">One</option>
                              <option value="2">Two</option>
                              <option value="3">Three</option>
                            </select>
                          </div>
                          <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                            <select
                              required
                              className="form-select"
                              aria-label="Default select example"
                              placeholder="Driver"
                            >
                              <option value="">Driver</option>
                              <option value="1">One</option>
                              <option value="2">Two</option>
                              <option value="3">Three</option>
                            </select>
                          </div>
                          <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Subject"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="right-export-btn-section-wrapper">
                        <div className="c-pointer">
                          <Link to="/ComposeEmail">
                            <img src={Export} alt="" />
                          </Link>
                        </div>
                        <div className="c-pointer">
                          {/* <img src={Import} alt="" /> */}
                        </div>
                      </div>
                    </div>
                    <div className="yauto">
                      <table className="table tableAdmin tabelheiht">
                        <thead className="tableHead">
                          <tr>
                            <th>Sr.no</th>
                            <th>Trip Name</th>
                            <th>Vehicle</th>
                            <th>Driver</th>
                            <th>Subject</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody className="tableBody">
                          <tr>
                            <td>1</td>
                            <td>Mr. John Doe</td>
                            <td>Mr. John Doe</td>
                            <td>john@gmail.com </td>
                            <td>+91 99 99 99 9999</td>
                            <td>
                              <div className="innerFlex">
                                <Link to="/EmailDetails">
                                  <img src={View} className="me-3" alt="" />
                                </Link>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>Mr. John Doe</td>
                            <td>Mr. John Doe</td>
                            <td>john@gmail.com </td>
                            <td>+91 99 99 99 9999</td>
                            <td>
                              <div className="innerFlex">
                                <Link to="/EmailDetails">
                                  <img src={View} className="me-3" alt="" />
                                </Link>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td>Mr. John Doe</td>
                            <td>Mr. John Doe</td>
                            <td>john@gmail.com </td>
                            <td>+91 99 99 99 9999</td>
                            <td>
                              <div className="innerFlex">
                                <Link to="/EmailDetails">
                                  <img src={View} className="me-3" alt="" />
                                </Link>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>4</td>
                            <td>Mr. John Doe</td>
                            <td>Mr. John Doe</td>
                            <td>john@gmail.com </td>
                            <td>+91 99 99 99 9999</td>
                            <td>
                              <div className="innerFlex">
                                <Link to="/EmailDetails">
                                  <img src={View} className="me-3" alt="" />
                                </Link>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>5</td>
                            <td>Mr. John Doe</td>
                            <td>Mr. John Doe</td>
                            <td>john@gmail.com </td>
                            <td>+91 99 99 99 9999</td>
                            <td>
                              <div className="innerFlex">
                                <Link to="/EmailDetails">
                                  <img src={View} className="me-3" alt="" />
                                </Link>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>6</td>
                            <td>Mr. John Doe</td>
                            <td>Mr. John Doe</td>
                            <td>john@gmail.com </td>
                            <td>+91 99 99 99 9999</td>
                            <td>
                              <div className="innerFlex">
                                <Link to="/EmailDetails">
                                  <img src={View} className="me-3" alt="" />
                                </Link>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <Pagenation />
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Email;
