import { React, useContext, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import { Dropdown, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import option from "../../../assets/images/option-three-dot.svg";
import Export from "../../../assets/images/export_icon.svg";
import View from "../../../assets/images/Group.svg";
import Delete from "../../../assets/images/delete.svg";
import BigSave from "../../../assets/images/bigSave.svg";
import BigInvoice from "../../../assets/images/bigOnvoice.svg";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
const ViewVehicleAccident = () => {
  const { sidebar, setSidebar, Dark, setDark } = useContext(AppContext);
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const { t, i18n } = useTranslation();

  const handleClose = () => setShowModal(false);
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  return (
    <>
      <motion.div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }}>
        <div id="cx-wrapper" className="ViewVehicle_Accident">
          <div className="main-master-wrapper">
            <div className="headingDetails">
              <div className="headingTxt">
                <p className="heading">{t("Vehicle Incident Details")}</p>
              </div>
              <div className="customer-option">
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic">
                    <img src={option} alt="" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item>
                      {" "}
                      <Link to="/EditVehicleAccident" className="d-block">{t("Edit")}</Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      {" "}
                      <Link onClick={() => setDeleteModal(true)} className="d-block" to="#">{t("Delete")}</Link>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className="DetailsSec">
              <div className="row detailsRow">
                <div className="col-md-3 ViewCol3 form_input_main">
                  <label className="head">{t("Vehicle Name")}</label>
                  <p className="Value">Toyota Innova</p>
                </div>
                <div className="col-md-3 ViewCol3 form_input_main">
                  <label className="head">{t("Location")}</label>
                  <p className="Value">Pune</p>
                </div>
                <div className="col-md-3 ViewCol3 form_input_main">
                  <label className="head">{t("Expenses")}</label>
                  <p className="Value">4500</p>
                </div>
                <div className="col-md-3 ViewCol3 form_input_main">
                  <label className="head">{t("Driver")}</label>
                  <p className="Value">MARK WOODS</p>
                </div>
                <div className="col-md-3 ViewCol3 form_input_main">
                  <label className="head">{t("Description")}</label>
                  <p className="Value">Mirror Broke, Headlight Damage</p>
                </div>
                <div className="col-md-3 ViewCol3 form_input_main">
                  <label className="head">{t("Date")}</label>
                  <p className="Value">08-10-2022</p>
                </div>
                <div className="col-md-6"></div>
                <div className="col-md-3 invoiceCOl">
                  <label className="head">{t("Invoice")}</label>
                  <div className="invoiceBox">
                    <div className="headingInvoi">
                      <p>Document No. 1</p>
                      <div className="viewLinks">
                        <Link to="#">
                          <img src={Export} className="me-2" alt="" />
                        </Link>
                        <Link to="#">
                          <img src={View} className="me-2" alt="" />
                        </Link>
                        <Link to="#" onClick={() => setDeleteModal(true)}>
                          <img src={Delete} alt="" />
                        </Link>
                      </div>
                    </div>
                    <div className="saveFile">
                      <Link to="#" onClick={() => setShowModal(true)}>
                        <img src={BigSave} alt="" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* invoice view Modal Start */}
      <Modal
        show={showModal}
        onHide={handleClose}
        centered
        size="l"
        className="invoice-model"
      >
        <Modal.Body>
          <img src={BigInvoice} className="invoiceBigImg" alt="" />
        </Modal.Body>
      </Modal>
      {/* invoice view  Modal End */}

      <Modal
        show={deleteModal}
        onHide={() => setDeleteModal(false)}
        centered
        className="common-model"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Delete Vehicle Accident")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("Are you sure you want to Delete this Vehicle Accident")} ?
        </Modal.Body>
        <Modal.Footer className="pop-up-modal-footer">
          <div class="btn-wrapper">
            <button className="cx-btn-1" onClick={() => setDeleteModal(false)}>
              {t("Cancel")}
            </button>
            <button className="cx-btn-2" onClick={() => setDeleteModal(false)}>
              {t("Yes")}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ViewVehicleAccident;
