import { React, useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import DDlogo from "../../assets/images/DDlogo.png";
import Form from "react-bootstrap/Form";
import { motion } from "framer-motion";
import ReactSelect from "../../sharedComponent/ReactSelect";
import { useTranslation } from "react-i18next";


const GeneralSetting = () => {
  const { sidebar, setSidebar, Dark, setDark } = useContext(AppContext);
  const [validated, setValidated] = useState(false);
  const { t, i18n } = useTranslation();

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };



  return (
    <>
      <motion.div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }}
      >
        <div id="cx-wrapper" className="General_Setting">
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <div className="main-master-wrapper form_input_main">
              <div className="Heading">
                <p>{t("Setting")}</p>
              </div>
              <div className="innerInputsGen" id="switchMain ">
                <div className="row">
                  <div className="weekCounter col-md-12 form_input_main">
                    <label className="headingText">
                      {t("Working Days")} <span>&#42;</span>
                    </label>
                    <div className="d-flex align-items-center flex-wrap innerToggle">
                      <div className="form-check form-switch">
                        <div className="d-flex innerFlexTog">
                          <label
                            className="form-check-label toggleLabel"
                            for="Monday"
                          >
                            {t("Monday")}
                          </label>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="Monday"
                          />
                        </div>
                      </div>
                      <div className="form-check form-switch">
                        <div className="d-flex innerFlexTog">
                          <label
                            className="form-check-label  toggleLabel"
                            for="Tuesday"
                          >
                            {t("Tuesday")}
                          </label>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="Tuesday"
                          />
                        </div>
                      </div>
                      <div className="form-check form-switch">
                        <div className="d-flex innerFlexTog">
                          <label
                            className="form-check-label  toggleLabel"
                            for="Wednesday"
                          >
                            {t("Wednesday")}
                          </label>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="Wednesday"
                          />
                        </div>
                      </div>
                      <div className="form-check form-switch">
                        <div className="d-flex innerFlexTog">
                          <label
                            className="form-check-label  toggleLabel"
                            for="Thursday"
                          >
                            {t("Thursday")}
                          </label>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="Thursday"
                          />
                        </div>
                      </div>
                      <div className="form-check form-switch">
                        <div className="d-flex innerFlexTog">
                          <label
                            className="form-check-label  toggleLabel"
                            for="Friday"
                          >
                            {t("Friday")}
                          </label>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="Friday"
                          />
                        </div>
                      </div>
                      <div className="form-check form-switch">
                        <div className="d-flex innerFlexTog">
                          <label
                            className="form-check-label  toggleLabel"
                            for="Saturday"
                          >
                            {t("Saturday")}
                          </label>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="Saturday"
                          />
                        </div>
                      </div>
                      <div className="form-check form-switch">
                        <div className="d-flex innerFlexTog">
                          <label
                            className="form-check-label  toggleLabel"
                            for="Sunday"
                          >
                            {t("Sunday")}
                          </label>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="Sunday"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 form_input_main">
                    <div className="innerSelectBox weekCounter">
                      <Form.Label className="common-labels">
                        {t("Speed Limit")} <span>&#42;</span>
                      </Form.Label>
                      <Form.Select
                        required
                        as="select"
                        type="select"
                        name="Speed_limit"
                      >
                        <option value="">Open this select menu</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="150">150</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Please Select Speed Limit.
                      </Form.Control.Feedback>
                    </div>
                  </div>
                  <div className="col-md-6 form_input_main">
                    <div className="innerSelectBox weekCounter">
                      <Form.Label className="common-labels">
                        {t("Customer Geofence Radius")} (Meter) <span>&#42;</span>{" "}
                      </Form.Label>
                      <Form.Select
                        required
                        as="select"
                        type="select"
                        name="Speed_limit"
                      >
                        <option value="">Open this select menu</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="150">150</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Please Select Geofence Radius.
                      </Form.Control.Feedback>
                    </div>
                  </div>
                  <div className="col-md-6 form_input_main">
                    <div className="innerSelectBox weekCounter">
                      <Form.Label className="common-labels">
                        {t("Pickup Point Geofence Radius")} (Meter) <span>&#42;</span>{" "}
                      </Form.Label>
                      <Form.Select
                        required
                        as="select"
                        type="select"
                        name="Speed_limit"
                      >
                        <option value="">Open this select menu</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="150">150</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Please Select Geofence Radius.
                      </Form.Control.Feedback>
                    </div>
                  </div>
                  <div className="col-md-6 form_input_main">
                    <div className="innerSelectBox weekCounter">
                      <Form.Label className="common-labels">
                        {t("Transportation Geofence")}(Km) <span>&#42;</span>
                      </Form.Label>
                      <Form.Select
                        required
                        as="select"
                        type="select"
                        name="Speed_limit"
                      >
                        <option value="">Open this select menu</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="150">150</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Please Select Geofence (Km).
                      </Form.Control.Feedback>
                    </div>
                  </div>
                  <div className="col-md-6 form_input_main">
                    <div className="innerSelectBox weekCounter">
                      <Form.Label className="common-labels">
                        {t("Date Record Per Page")} <span>&#42;</span>{" "}
                      </Form.Label>
                      <Form.Select
                        required
                        as="select"
                        type="select"
                        name="Speed_limit"
                      >
                        <option value="">Open this select menu</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="150">150</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Please Select Date record page.
                      </Form.Control.Feedback>
                    </div>
                  </div>
                  <div className="col-md-6 form_input_main">
                    <div className="innerSelectBox weekCounter">
                      <Form.Label className="common-labels">
                        {t("Date Format")}<span>&#42;</span>{" "}
                      </Form.Label>
                      <Form.Select
                        required
                        as="select"
                        type="select"
                        name="Speed_limit"
                      >
                        <option value="">Open this select menu</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="150">150</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Please Select Date.
                      </Form.Control.Feedback>
                    </div>
                  </div>
                  <div className="col-md-6 form_input_main">
                    <div className="innerSelectBox weekCounter">
                      <Form.Label className="common-labels">
                        {t("Duration To Consider Vehicle As Untracked")} (Minute)
                      </Form.Label>
                      <Form.Select
                        required
                        as="select"
                        type="select"
                        name="Speed_limit"
                      >
                        <option value="">Open this select menu</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="150">150</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Please Select Duration.
                      </Form.Control.Feedback>
                    </div>
                  </div>
                  <div className="col-md-6 form_input_main">
                    <div className="innerSelectBox weekCounter">
                      <Form.Label className="common-labels">
                        {t("Duration To Consider Vehicle As Idel")}({t("Parked")})
                        <span>&#42;</span>{" "}
                      </Form.Label>
                      <Form.Select
                        required
                        as="select"
                        type="select"
                        name="Speed_limit"
                      >
                        <option value="">Open this select menu</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="150">150</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Please Select Duration.
                      </Form.Control.Feedback>
                    </div>
                  </div>
                  <div className="col-md-6 form_input_main">
                    <div className="innerSelectBox weekCounter">
                      <Form.Label className="common-labels">
                        {t("Acceleration Threshold")}
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Enter Acceleration Threshold"
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Acceleration Threshold.
                      </Form.Control.Feedback>
                    </div>
                  </div>
                  <div className="col-md-6 form_input_main">
                    <div className="innerSelectBox weekCounter">
                      <Form.Label className="common-labels">
                        {t("Deacceleration Threshold")}
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Enter Deacceleration Threshold"
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Deacceleration Threshold.
                      </Form.Control.Feedback>
                    </div>
                  </div>
                  <div className="col-md-6 form_input_main">
                    <div className="innerSelectBox weekCounter">
                      <Form.Label className="common-labels">
                        {t("Historical Movement Map")}
                      </Form.Label>
                      <Form.Select
                        required
                        as="select"
                        type="select"
                        name="Speed_limit"
                      >
                        <option value="">Open this select menu</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="150">150</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Please Select Historical Movement Map.
                      </Form.Control.Feedback>
                    </div>
                  </div>
                  <div className="col-md-6 form_input_main">
                    <div className="innerSelectBox weekCounter">
                      <Form.Label className="common-labels">
                        {t("Minutes to Consider Trip Historical Movement")}{" "}
                      </Form.Label>
                      <Form.Select
                        required
                        as="select"
                        type="select"
                        name="Speed_limit"
                      >
                        <option value="">Open this select menu</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="150">150</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Please Select Minutes.
                      </Form.Control.Feedback>
                    </div>
                  </div>
                  <div className="col-md-6 form_input_main weekCounter">
                    <div className="form-check form-switch">
                      <div className="d-flex">
                        <label
                          className="form-check-label  switchLabel"
                          for="googleMap"
                        >
                          {t("Show Vehicle Label in Google Map")}
                        </label>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="googleMap"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Automation Setting */}
            <div className="main-master-wrapper form_input_main">
              <div className="Heading">
                <p>{t("Automation Setting")}</p>
              </div>
              <div className="innerInputsGen" id="dispatch-setting">
                <div className="row ">
                  <div className="col-md-6 weekCounter">
                    <div className="form-check form-switch align">
                      <div className="d-flex justify-content-between">
                        <label
                          className="form-check-label  switchLabel "
                          for="dataCollection"
                        >
                          {t("Automation Data Collection")}
                        </label>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="dataCollection"
                        />
                      </div>
                    </div>
                    <div className="form-check form-switch align ">
                      <div className="d-flex justify-content-between">
                        <label
                          className="form-check-label  switchLabel"
                          for="dataOptimise"
                        >
                          {t("Automation Data Optimise")}
                        </label>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="dataOptimise"
                        />
                      </div>
                    </div>
                    <div className="form-check form-switch align">
                      <div className="d-flex justify-content-between">
                        <label
                          className="form-check-label  switchLabel"
                          for="dataUpdate"
                        >
                          {t("Automation Data Update")}
                        </label>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="dataUpdate"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form_input_main weekCounter">
                      <Form.Label className="common-labels">
                        {t("No. of suggestions Or Anomali count for automatic slot creation")}
                      </Form.Label>
                      <Form.Control required type="text" />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Anomali count.
                      </Form.Control.Feedback>
                    </div>
                    <div className="form_input_main weekCounter">
                      <Form.Label className="common-labels">
                        {t("Minimum number of vehicles in an area to consider it as parking slot")}
                      </Form.Label>
                      <Form.Control required type="text" />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Minimum number vehicles.
                      </Form.Control.Feedback>
                    </div>
                    <div className="form_input_main weekCounter">
                      <Form.Label className="common-labels">
                        {t("Maximum Radiutexts for parking slot creation")} (Meters)
                      </Form.Label>
                      <Form.Control required type="text" />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Maximum Radiutexts.
                      </Form.Control.Feedback>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* ---------- NEW UI ---------- */}
            
           
            <div>
              <div className="main-master-wrapper form_input_main">
                <div className="Heading" id="help-setting-card">
                  <p>{t("Help Setting")}</p>

                  <div className="form-check form-switch" id="help-switch-main">
                    <div className="d-flex innerFlexTog">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="Wednesday"
                      />
                    </div>
                  </div>
                </div>

              </div>
            </div>
            {/* ----------END NEW UI ------------ */}

            {/* Two Bottom Buttons */}
            <div className="d-flex justify-content-end align-items-center btn-wrapper">
              <button className="cx-btn-1">{t("Cancel")}</button>
              <button className="cx-btn-2">{t("Submit")}</button>
            </div>
          </Form>
        </div>
      </motion.div>
    </>
  );
};

export default GeneralSetting;
