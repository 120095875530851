// Usama 09-02-2023

import React, { useContext, useState } from "react";
import { Col, Dropdown, Modal, Nav, Tab, Tabs } from "react-bootstrap";
import { AppContext } from "../../../context/AppContext";
import DDlogo from "../../../assets/images/DDlogo.png";
import Import from "../../../assets/images/ic-Import.svg";
import option from "../../../assets/images/option-three-dot.svg";
import Grouplogo from "../../../assets/images/Customer-profile.png";
import Export from "../../../assets/images/ic-Export.svg";
import untracked_icon from "../../../assets/images/untracked_icon.svg";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
const VehicleAssistants = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const { t, i18n } = useTranslation();

  const { sidebar, setSidebar, Dark, setDark } = useContext(AppContext);
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  return (
    <motion.div
      className={sidebar ? "taskMain " : "cx-active taskMain"}
      id="cx-main"
      variants={aninations}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.1 }}
    >
      <div id="cx-wrapper">
        <Link
          to="/AddVehicleAssistants"
          className="mb-3 d-flex justify-content-end"
        >
          <button className="cx-btn-3">+ {t("Vehicle Assistant")}</button>
        </Link>
        <div id="cutomScroll">
          <div className="main-master-wrapper mb-0 inner-tabs-section tabs-custom-width-33 ">
            <div id="scroll_insideThe_Padding">
              <Tab.Container
                id="left-tabs-example"
                className="td-tab-wrapper"
                defaultActiveKey="0"
              >
                <Nav variant="pills" className="td-nav" id="InnerTabNew_Three">
                  <Nav.Item className="td-tab">
                    <Nav.Link className="td-link" eventKey="0">
                      {t("All")}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="td-tab">
                    <Nav.Link className="td-link" eventKey="1">
                      {t("Active")}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="td-tab">
                    <Nav.Link className="td-link" eventKey="2">
                      {t("Inactive")}
                    </Nav.Link>
                  </Nav.Item>
                </Nav>

                <Col sm={12} className="">
                  <Tab.Content>
                    <Tab.Pane eventKey="0">
                      <div className="all-vehicle-main">
                        <div className="all-vehical-head row">
                          <div className="col-lg-11 row innerInputsGen">
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Fleet Assistant Name"
                              />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Email"
                              />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Contact Number"
                              />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                              <select
                                className="form-select"
                                aria-label="Default select example"
                                placeholder="Vehicles"
                                required
                              >
                                <option value="">Vehicles</option>
                                <option value="1">Vehicles One</option>
                                <option value="2">Vehicles Two</option>
                                <option value="3">Vehicles Three</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-1 export-btn g-0">
                            <div className="">
                              <img src={Export} alt="" />
                            </div>
                            <div className="">
                              <img src={Import} alt="" />
                            </div>
                          </div>
                        </div>
                        <div className="yauto" id="TransportMananger_height">
                          <div className="row gx-3 main-cards-wrapper">
                            <div
                              className={
                                sidebar
                                  ? "col-lg-4 col-md-6"
                                  : "col-lg-3 col-md-6"
                              }
                            >
                              <div
                                className={
                                  "common-vehical-card-inner cv-card p-0"
                                }
                              >
                                <div className="vehical-card-head vc-top">
                                  <div className="heading top-avatar-wrapper">
                                    <img
                                      src={Grouplogo}
                                      alt=""
                                      className="custom-Margin"
                                    />
                                    <div className="">
                                      <p className="sub-heading">{t("Driver Name")}</p>
                                      <p className="title">Mark S. Woods</p>
                                    </div>
                                  </div>
                                  <div className="option customer-option">
                                    <Dropdown>
                                      <Dropdown.Toggle id="dropdown-basic">
                                        <img src={option} alt="" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ViewVehicleAssistants"
                                            className="d-block"
                                          >
                                            {t("View")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/EditVehicleAssistants"
                                            className="d-block"
                                          >
                                            {t("Edit")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ChangeDelPass"
                                            className="d-block"
                                          >
                                            {t("Change Password")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow1}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Block")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow2}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Resign")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Delete")}
                                          </Link>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="vehical-card-body vc-body row g-0">
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Email")}</p>
                                    <p className="title">markwoods@gmail.com</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Contact No.")}</p>
                                    <p className="title">99999 99999</p>
                                  </div>
                                  <div className="card-contain col-lg-12">
                                    <p className="sub-heading">
                                      {t("Vehicle Assignments")}
                                    </p>
                                    <p className="title">
                                      Toyota Innova, Honda City
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                sidebar
                                  ? "col-lg-4 col-md-6"
                                  : "col-lg-3 col-md-6"
                              }
                            >
                              <div
                                className={
                                  "common-vehical-card-inner cv-card p-0"
                                }
                              >
                                <div className="vehical-card-head vc-top">
                                  <div className="heading top-avatar-wrapper">
                                    <img
                                      src={Grouplogo}
                                      alt=""
                                      className="custom-Margin"
                                    />
                                    <div className="">
                                      <p className="sub-heading">{t("Driver Name")}</p>
                                      <p className="title">Mark S. Woods</p>
                                    </div>
                                  </div>
                                  <div className="option customer-option">
                                    <Dropdown>
                                      <Dropdown.Toggle id="dropdown-basic">
                                        <img src={option} alt="" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ViewVehicleAssistants"
                                            className="d-block"
                                          >
                                            {t("View")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/EditVehicleAssistants"
                                            className="d-block"
                                          >
                                            {t("Edit")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ChangeDelPass"
                                            className="d-block"
                                          >
                                            {t("Change Password")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow1}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Block")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow2}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Resign")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Delete")}
                                          </Link>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="vehical-card-body vc-body row g-0">
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Email")}</p>
                                    <p className="title">markwoods@gmail.com</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Contact No.")}</p>
                                    <p className="title">99999 99999</p>
                                  </div>
                                  <div className="card-contain col-lg-12">
                                    <p className="sub-heading">
                                      {t("Vehicle Assignments")}
                                    </p>
                                    <p className="title">
                                      Toyota Innova, Honda City
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div><div
                              className={
                                sidebar
                                  ? "col-lg-4 col-md-6"
                                  : "col-lg-3 col-md-6"
                              }
                            >
                              <div
                                className={
                                  "common-vehical-card-inner cv-card p-0"
                                }
                              >
                                <div className="vehical-card-head vc-top">
                                  <div className="heading top-avatar-wrapper">
                                    <img
                                      src={Grouplogo}
                                      alt=""
                                      className="custom-Margin"
                                    />
                                    <div className="">
                                      <p className="sub-heading">{t("Driver Name")}</p>
                                      <p className="title">Mark S. Woods</p>
                                    </div>
                                  </div>
                                  <div className="option customer-option">
                                    <Dropdown>
                                      <Dropdown.Toggle id="dropdown-basic">
                                        <img src={option} alt="" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ViewVehicleAssistants"
                                            className="d-block"
                                          >
                                            {t("View")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/EditVehicleAssistants"
                                            className="d-block"
                                          >
                                            {t("Edit")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ChangeDelPass"
                                            className="d-block"
                                          >
                                            {t("Change Password")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow1}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Block")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow2}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Resign")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Delete")}
                                          </Link>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="vehical-card-body vc-body row g-0">
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Email")}</p>
                                    <p className="title">markwoods@gmail.com</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Contact No.")}</p>
                                    <p className="title">99999 99999</p>
                                  </div>
                                  <div className="card-contain col-lg-12">
                                    <p className="sub-heading">
                                      {t("Vehicle Assignments")}
                                    </p>
                                    <p className="title">
                                      Toyota Innova, Honda City
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div><div
                              className={
                                sidebar
                                  ? "col-lg-4 col-md-6"
                                  : "col-lg-3 col-md-6"
                              }
                            >
                              <div
                                className={
                                  "common-vehical-card-inner cv-card p-0"
                                }
                              >
                                <div className="vehical-card-head vc-top">
                                  <div className="heading top-avatar-wrapper">
                                    <img
                                      src={Grouplogo}
                                      alt=""
                                      className="custom-Margin"
                                    />
                                    <div className="">
                                      <p className="sub-heading">{t("Driver Name")}</p>
                                      <p className="title">Mark S. Woods</p>
                                    </div>
                                  </div>
                                  <div className="option customer-option">
                                    <Dropdown>
                                      <Dropdown.Toggle id="dropdown-basic">
                                        <img src={option} alt="" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ViewVehicleAssistants"
                                            className="d-block"
                                          >
                                            {t("View")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/EditVehicleAssistants"
                                            className="d-block"
                                          >
                                            {t("Edit")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ChangeDelPass"
                                            className="d-block"
                                          >
                                            {t("Change Password")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow1}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Block")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow2}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Resign")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Delete")}
                                          </Link>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="vehical-card-body vc-body row g-0">
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Email")}</p>
                                    <p className="title">markwoods@gmail.com</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Contact No.")}</p>
                                    <p className="title">99999 99999</p>
                                  </div>
                                  <div className="card-contain col-lg-12">
                                    <p className="sub-heading">
                                      {t("Vehicle Assignments")}
                                    </p>
                                    <p className="title">
                                      Toyota Innova, Honda City
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div><div
                              className={
                                sidebar
                                  ? "col-lg-4 col-md-6"
                                  : "col-lg-3 col-md-6"
                              }
                            >
                              <div
                                className={
                                  "common-vehical-card-inner cv-card p-0"
                                }
                              >
                                <div className="vehical-card-head vc-top">
                                  <div className="heading top-avatar-wrapper">
                                    <img
                                      src={Grouplogo}
                                      alt=""
                                      className="custom-Margin"
                                    />
                                    <div className="">
                                      <p className="sub-heading">{t("Driver Name")}</p>
                                      <p className="title">Mark S. Woods</p>
                                    </div>
                                  </div>
                                  <div className="option customer-option">
                                    <Dropdown>
                                      <Dropdown.Toggle id="dropdown-basic">
                                        <img src={option} alt="" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ViewVehicleAssistants"
                                            className="d-block"
                                          >
                                            {t("View")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/EditVehicleAssistants"
                                            className="d-block"
                                          >
                                            {t("Edit")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ChangeDelPass"
                                            className="d-block"
                                          >
                                            {t("Change Password")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow1}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Block")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow2}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Resign")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Delete")}
                                          </Link>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="vehical-card-body vc-body row g-0">
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Email")}</p>
                                    <p className="title">markwoods@gmail.com</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Contact No.")}</p>
                                    <p className="title">99999 99999</p>
                                  </div>
                                  <div className="card-contain col-lg-12">
                                    <p className="sub-heading">
                                      {t("Vehicle Assignments")}
                                    </p>
                                    <p className="title">
                                      Toyota Innova, Honda City
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div><div
                              className={
                                sidebar
                                  ? "col-lg-4 col-md-6"
                                  : "col-lg-3 col-md-6"
                              }
                            >
                              <div
                                className={
                                  "common-vehical-card-inner cv-card p-0"
                                }
                              >
                                <div className="vehical-card-head vc-top">
                                  <div className="heading top-avatar-wrapper">
                                    <img
                                      src={Grouplogo}
                                      alt=""
                                      className="custom-Margin"
                                    />
                                    <div className="">
                                      <p className="sub-heading">{t("Driver Name")}</p>
                                      <p className="title">Mark S. Woods</p>
                                    </div>
                                  </div>
                                  <div className="option customer-option">
                                    <Dropdown>
                                      <Dropdown.Toggle id="dropdown-basic">
                                        <img src={option} alt="" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ViewVehicleAssistants"
                                            className="d-block"
                                          >
                                            {t("View")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/EditVehicleAssistants"
                                            className="d-block"
                                          >
                                            {t("Edit")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ChangeDelPass"
                                            className="d-block"
                                          >
                                            {t("Change Password")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow1}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Block")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow2}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Resign")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Delete")}
                                          </Link>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="vehical-card-body vc-body row g-0">
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Email")}</p>
                                    <p className="title">markwoods@gmail.com</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Contact No.")}</p>
                                    <p className="title">99999 99999</p>
                                  </div>
                                  <div className="card-contain col-lg-12">
                                    <p className="sub-heading">
                                      {t("Vehicle Assignments")}
                                    </p>
                                    <p className="title">
                                      Toyota Innova, Honda City
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div><div
                              className={
                                sidebar
                                  ? "col-lg-4 col-md-6"
                                  : "col-lg-3 col-md-6"
                              }
                            >
                              <div
                                className={
                                  "common-vehical-card-inner cv-card p-0"
                                }
                              >
                                <div className="vehical-card-head vc-top">
                                  <div className="heading top-avatar-wrapper">
                                    <img
                                      src={Grouplogo}
                                      alt=""
                                      className="custom-Margin"
                                    />
                                    <div className="">
                                      <p className="sub-heading">{t("Driver Name")}</p>
                                      <p className="title">Mark S. Woods</p>
                                    </div>
                                  </div>
                                  <div className="option customer-option">
                                    <Dropdown>
                                      <Dropdown.Toggle id="dropdown-basic">
                                        <img src={option} alt="" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ViewVehicleAssistants"
                                            className="d-block"
                                          >
                                            {t("View")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/EditVehicleAssistants"
                                            className="d-block"
                                          >
                                            {t("Edit")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ChangeDelPass"
                                            className="d-block"
                                          >
                                            {t("Change Password")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow1}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Block")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow2}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Resign")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Delete")}
                                          </Link>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="vehical-card-body vc-body row g-0">
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Email")}</p>
                                    <p className="title">markwoods@gmail.com</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Contact No.")}</p>
                                    <p className="title">99999 99999</p>
                                  </div>
                                  <div className="card-contain col-lg-12">
                                    <p className="sub-heading">
                                      {t("Vehicle Assignments")}
                                    </p>
                                    <p className="title">
                                      Toyota Innova, Honda City
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="1">
                      <div className="all-vehicle-main">
                        <div className="all-vehical-head row">
                          <div className="col-lg-11 row innerInputsGen">
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Fleet Assistant Name"
                              />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Email"
                              />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Contact Number"
                              />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                              <select
                                className="form-select"
                                aria-label="Default select example"
                                placeholder="Transportation Type"
                                required
                              >
                                <option value="">Transportation Type</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-1 export-btn g-0">
                            <div className="">
                              <img src={Export} alt="" />
                            </div>
                            <div className="">
                              <img src={Import} alt="" />
                            </div>
                          </div>
                        </div>
                        <div className="yauto" id="TransportMananger_height">
                          <div className="row gx-3 main-cards-wrapper">
                          <div
                              className={
                                sidebar
                                  ? "col-lg-4 col-md-6"
                                  : "col-lg-3 col-md-6"
                              }
                            >
                              <div
                                className={
                                  "common-vehical-card-inner cv-card p-0"
                                }
                              >
                                <div className="vehical-card-head vc-top">
                                  <div className="heading top-avatar-wrapper">
                                    <img
                                      src={Grouplogo}
                                      alt=""
                                      className="custom-Margin"
                                    />
                                    <div className="">
                                      <p className="sub-heading">{t("Driver Name")}</p>
                                      <p className="title">Mark S. Woods</p>
                                    </div>
                                  </div>
                                  <div className="option customer-option">
                                    <Dropdown>
                                      <Dropdown.Toggle id="dropdown-basic">
                                        <img src={option} alt="" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ViewVehicleAssistants"
                                            className="d-block"
                                          >
                                            {t("View")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/EditVehicleAssistants"
                                            className="d-block"
                                          >
                                            {t("Edit")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ChangeDelPass"
                                            className="d-block"
                                          >
                                            {t("Change Password")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow1}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Block")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow2}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Resign")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Delete")}
                                          </Link>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="vehical-card-body vc-body row g-0">
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Email")}</p>
                                    <p className="title">markwoods@gmail.com</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Contact No.")}</p>
                                    <p className="title">99999 99999</p>
                                  </div>
                                  <div className="card-contain col-lg-12">
                                    <p className="sub-heading">
                                      {t("Vehicle Assignments")}
                                    </p>
                                    <p className="title">
                                      Toyota Innova, Honda City
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div><div
                              className={
                                sidebar
                                  ? "col-lg-4 col-md-6"
                                  : "col-lg-3 col-md-6"
                              }
                            >
                              <div
                                className={
                                  "common-vehical-card-inner cv-card p-0"
                                }
                              >
                                <div className="vehical-card-head vc-top">
                                  <div className="heading top-avatar-wrapper">
                                    <img
                                      src={Grouplogo}
                                      alt=""
                                      className="custom-Margin"
                                    />
                                    <div className="">
                                      <p className="sub-heading">{t("Driver Name")}</p>
                                      <p className="title">Mark S. Woods</p>
                                    </div>
                                  </div>
                                  <div className="option customer-option">
                                    <Dropdown>
                                      <Dropdown.Toggle id="dropdown-basic">
                                        <img src={option} alt="" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ViewVehicleAssistants"
                                            className="d-block"
                                          >
                                            {t("View")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/EditVehicleAssistants"
                                            className="d-block"
                                          >
                                            {t("Edit")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ChangeDelPass"
                                            className="d-block"
                                          >
                                            {t("Change Password")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow1}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Block")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow2}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Resign")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Delete")}
                                          </Link>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="vehical-card-body vc-body row g-0">
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Email")}</p>
                                    <p className="title">markwoods@gmail.com</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Contact No.")}</p>
                                    <p className="title">99999 99999</p>
                                  </div>
                                  <div className="card-contain col-lg-12">
                                    <p className="sub-heading">
                                      {t("Vehicle Assignments")}
                                    </p>
                                    <p className="title">
                                      Toyota Innova, Honda City
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div><div
                              className={
                                sidebar
                                  ? "col-lg-4 col-md-6"
                                  : "col-lg-3 col-md-6"
                              }
                            >
                              <div
                                className={
                                  "common-vehical-card-inner cv-card p-0"
                                }
                              >
                                <div className="vehical-card-head vc-top">
                                  <div className="heading top-avatar-wrapper">
                                    <img
                                      src={Grouplogo}
                                      alt=""
                                      className="custom-Margin"
                                    />
                                    <div className="">
                                      <p className="sub-heading">{t("Driver Name")}</p>
                                      <p className="title">Mark S. Woods</p>
                                    </div>
                                  </div>
                                  <div className="option customer-option">
                                    <Dropdown>
                                      <Dropdown.Toggle id="dropdown-basic">
                                        <img src={option} alt="" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ViewVehicleAssistants"
                                            className="d-block"
                                          >
                                            {t("View")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/EditVehicleAssistants"
                                            className="d-block"
                                          >
                                            {t("Edit")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ChangeDelPass"
                                            className="d-block"
                                          >
                                            {t("Change Password")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow1}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Block")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow2}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Resign")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Delete")}
                                          </Link>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="vehical-card-body vc-body row g-0">
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Email")}</p>
                                    <p className="title">markwoods@gmail.com</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Contact No.")}</p>
                                    <p className="title">99999 99999</p>
                                  </div>
                                  <div className="card-contain col-lg-12">
                                    <p className="sub-heading">
                                      {t("Vehicle Assignments")}
                                    </p>
                                    <p className="title">
                                      Toyota Innova, Honda City
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div><div
                              className={
                                sidebar
                                  ? "col-lg-4 col-md-6"
                                  : "col-lg-3 col-md-6"
                              }
                            >
                              <div
                                className={
                                  "common-vehical-card-inner cv-card p-0"
                                }
                              >
                                <div className="vehical-card-head vc-top">
                                  <div className="heading top-avatar-wrapper">
                                    <img
                                      src={Grouplogo}
                                      alt=""
                                      className="custom-Margin"
                                    />
                                    <div className="">
                                      <p className="sub-heading">{t("Driver Name")}</p>
                                      <p className="title">Mark S. Woods</p>
                                    </div>
                                  </div>
                                  <div className="option customer-option">
                                    <Dropdown>
                                      <Dropdown.Toggle id="dropdown-basic">
                                        <img src={option} alt="" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ViewVehicleAssistants"
                                            className="d-block"
                                          >
                                            {t("View")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/EditVehicleAssistants"
                                            className="d-block"
                                          >
                                            {t("Edit")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ChangeDelPass"
                                            className="d-block"
                                          >
                                            {t("Change Password")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow1}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Block")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow2}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Resign")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Delete")}
                                          </Link>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="vehical-card-body vc-body row g-0">
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Email")}</p>
                                    <p className="title">markwoods@gmail.com</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Contact No.")}</p>
                                    <p className="title">99999 99999</p>
                                  </div>
                                  <div className="card-contain col-lg-12">
                                    <p className="sub-heading">
                                      {t("Vehicle Assignments")}
                                    </p>
                                    <p className="title">
                                      Toyota Innova, Honda City
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div><div
                              className={
                                sidebar
                                  ? "col-lg-4 col-md-6"
                                  : "col-lg-3 col-md-6"
                              }
                            >
                              <div
                                className={
                                  "common-vehical-card-inner cv-card p-0"
                                }
                              >
                                <div className="vehical-card-head vc-top">
                                  <div className="heading top-avatar-wrapper">
                                    <img
                                      src={Grouplogo}
                                      alt=""
                                      className="custom-Margin"
                                    />
                                    <div className="">
                                      <p className="sub-heading">{t("Driver Name")}</p>
                                      <p className="title">Mark S. Woods</p>
                                    </div>
                                  </div>
                                  <div className="option customer-option">
                                    <Dropdown>
                                      <Dropdown.Toggle id="dropdown-basic">
                                        <img src={option} alt="" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ViewVehicleAssistants"
                                            className="d-block"
                                          >
                                            {t("View")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/EditVehicleAssistants"
                                            className="d-block"
                                          >
                                            {t("Edit")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ChangeDelPass"
                                            className="d-block"
                                          >
                                            {t("Change Password")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow1}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Block")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow2}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Resign")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Delete")}
                                          </Link>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="vehical-card-body vc-body row g-0">
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Email")}</p>
                                    <p className="title">markwoods@gmail.com</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Contact No.")}</p>
                                    <p className="title">99999 99999</p>
                                  </div>
                                  <div className="card-contain col-lg-12">
                                    <p className="sub-heading">
                                      {t("Vehicle Assignments")}
                                    </p>
                                    <p className="title">
                                      Toyota Innova, Honda City
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div><div
                              className={
                                sidebar
                                  ? "col-lg-4 col-md-6"
                                  : "col-lg-3 col-md-6"
                              }
                            >
                              <div
                                className={
                                  "common-vehical-card-inner cv-card p-0"
                                }
                              >
                                <div className="vehical-card-head vc-top">
                                  <div className="heading top-avatar-wrapper">
                                    <img
                                      src={Grouplogo}
                                      alt=""
                                      className="custom-Margin"
                                    />
                                    <div className="">
                                      <p className="sub-heading">{t("Driver Name")}</p>
                                      <p className="title">Mark S. Woods</p>
                                    </div>
                                  </div>
                                  <div className="option customer-option">
                                    <Dropdown>
                                      <Dropdown.Toggle id="dropdown-basic">
                                        <img src={option} alt="" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ViewVehicleAssistants"
                                            className="d-block"
                                          >
                                            {t("View")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/EditVehicleAssistants"
                                            className="d-block"
                                          >
                                            {t("Edit")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ChangeDelPass"
                                            className="d-block"
                                          >
                                            {t("Change Password")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow1}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Block")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow2}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Resign")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Delete")}
                                          </Link>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="vehical-card-body vc-body row g-0">
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Email")}</p>
                                    <p className="title">markwoods@gmail.com</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Contact No.")}</p>
                                    <p className="title">99999 99999</p>
                                  </div>
                                  <div className="card-contain col-lg-12">
                                    <p className="sub-heading">
                                      {t("Vehicle Assignments")}
                                    </p>
                                    <p className="title">
                                      Toyota Innova, Honda City
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div><div
                              className={
                                sidebar
                                  ? "col-lg-4 col-md-6"
                                  : "col-lg-3 col-md-6"
                              }
                            >
                              <div
                                className={
                                  "common-vehical-card-inner cv-card p-0"
                                }
                              >
                                <div className="vehical-card-head vc-top">
                                  <div className="heading top-avatar-wrapper">
                                    <img
                                      src={Grouplogo}
                                      alt=""
                                      className="custom-Margin"
                                    />
                                    <div className="">
                                      <p className="sub-heading">{t("Driver Name")}</p>
                                      <p className="title">Mark S. Woods</p>
                                    </div>
                                  </div>
                                  <div className="option customer-option">
                                    <Dropdown>
                                      <Dropdown.Toggle id="dropdown-basic">
                                        <img src={option} alt="" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ViewVehicleAssistants"
                                            className="d-block"
                                          >
                                            {t("View")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/EditVehicleAssistants"
                                            className="d-block"
                                          >
                                            {t("Edit")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ChangeDelPass"
                                            className="d-block"
                                          >
                                            {t("Change Password")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow1}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Block")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow2}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Resign")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Delete")}
                                          </Link>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="vehical-card-body vc-body row g-0">
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Email")}</p>
                                    <p className="title">markwoods@gmail.com</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Contact No.")}</p>
                                    <p className="title">99999 99999</p>
                                  </div>
                                  <div className="card-contain col-lg-12">
                                    <p className="sub-heading">
                                      {t("Vehicle Assignments")}
                                    </p>
                                    <p className="title">
                                      Toyota Innova, Honda City
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div><div
                              className={
                                sidebar
                                  ? "col-lg-4 col-md-6"
                                  : "col-lg-3 col-md-6"
                              }
                            >
                              <div
                                className={
                                  "common-vehical-card-inner cv-card p-0"
                                }
                              >
                                <div className="vehical-card-head vc-top">
                                  <div className="heading top-avatar-wrapper">
                                    <img
                                      src={Grouplogo}
                                      alt=""
                                      className="custom-Margin"
                                    />
                                    <div className="">
                                      <p className="sub-heading">{t("Driver Name")}</p>
                                      <p className="title">Mark S. Woods</p>
                                    </div>
                                  </div>
                                  <div className="option customer-option">
                                    <Dropdown>
                                      <Dropdown.Toggle id="dropdown-basic">
                                        <img src={option} alt="" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ViewVehicleAssistants"
                                            className="d-block"
                                          >
                                            {t("View")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/EditVehicleAssistants"
                                            className="d-block"
                                          >
                                            {t("Edit")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ChangeDelPass"
                                            className="d-block"
                                          >
                                            {t("Change Password")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow1}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Block")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow2}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Resign")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Delete")}
                                          </Link>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="vehical-card-body vc-body row g-0">
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Email")}</p>
                                    <p className="title">markwoods@gmail.com</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Contact No.")}</p>
                                    <p className="title">99999 99999</p>
                                  </div>
                                  <div className="card-contain col-lg-12">
                                    <p className="sub-heading">
                                      {t("Vehicle Assignments")}
                                    </p>
                                    <p className="title">
                                      Toyota Innova, Honda City
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="2">
                    
                      <div
                        className="all-vehicle-main"
                        id="opactiy_for_inactive_card"
                      >
                        <div className="all-vehical-head row">
                          <div className="col-lg-11 row innerInputsGen">
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Fleet Assistant Name"
                              />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Email"
                              />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Contact Number"
                              />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                              <select
                                className="form-select"
                                aria-label="Default select example"
                                placeholder="Transportation Type"
                                required
                              >
                                <option value="">Transportation Type</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-1 export-btn g-0">
                            <div className="">
                              <img src={Export} alt="" />
                            </div>
                            <div className="">
                              <img src={Import} alt="" />
                            </div>
                          </div>
                        </div>
                        <div className="yauto" id="TransportMananger_height">
                          <div className="row gx-3 main-cards-wrapper p-none">
                            <div
                              className={
                                sidebar
                                  ? "col-lg-4 col-md-6"
                                  : "col-lg-3 col-md-6"
                              }
                            >
                              <div
                                className={
                                  "common-vehical-card-inner cv-card p-0"
                                }
                              >
                                <div className="vehical-card-head vc-top">
                                  <div className="heading top-avatar-wrapper">
                                    <img
                                      src={Grouplogo}
                                      alt=""
                                      className="custom-Margin"
                                    />
                                    <div className="">
                                      <p className="sub-heading">{t("Driver Name")}</p>
                                      <p className="title">Mark S. Woods</p>
                                    </div>
                                  </div>
                                  <div className="option customer-option">
                                    <Dropdown>
                                      <Dropdown.Toggle id="dropdown-basic">
                                        <img src={option} alt="" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ViewVehicleAssistants"
                                            className="d-block"
                                          >
                                            {t("View")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/EditVehicleAssistants"
                                            className="d-block"
                                          >
                                            {t("Edit")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ChangeDelPass"
                                            className="d-block"
                                          >
                                            {t("Change Password")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow1}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Block")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow2}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Resign")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Delete")}
                                          </Link>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="vehical-card-body vc-body row g-0">
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Email")}</p>
                                    <p className="title">markwoods@gmail.com</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Contact No.")}</p>
                                    <p className="title">99999 99999</p>
                                  </div>
                                  <div className="card-contain col-lg-12">
                                    <p className="sub-heading">
                                      {t("Vehicle Assignments")}
                                    </p>
                                    <p className="title">
                                      Toyota Innova, Honda City
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                sidebar
                                  ? "col-lg-4 col-md-6"
                                  : "col-lg-3 col-md-6"
                              }
                            >
                              <div
                                className={
                                  "common-vehical-card-inner cv-card p-0"
                                }
                              >
                                <div className="vehical-card-head vc-top">
                                  <div className="heading top-avatar-wrapper">
                                    <img
                                      src={Grouplogo}
                                      alt=""
                                      className="custom-Margin"
                                    />
                                    <div className="">
                                      <p className="sub-heading">{t("Driver Name")}</p>
                                      <p className="title">Mark S. Woods</p>
                                    </div>
                                  </div>
                                  <div className="option customer-option">
                                    <Dropdown>
                                      <Dropdown.Toggle id="dropdown-basic">
                                        <img src={option} alt="" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ViewVehicleAssistants"
                                            className="d-block"
                                          >
                                            {t("View")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/EditVehicleAssistants"
                                            className="d-block"
                                          >
                                            {t("Edit")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ChangeDelPass"
                                            className="d-block"
                                          >
                                            {t("Change Password")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow1}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Block")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow2}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Resign")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Delete")}
                                          </Link>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="vehical-card-body vc-body row g-0">
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Email")}</p>
                                    <p className="title">markwoods@gmail.com</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Contact No.")}</p>
                                    <p className="title">99999 99999</p>
                                  </div>
                                  <div className="card-contain col-lg-12">
                                    <p className="sub-heading">
                                      {t("Vehicle Assignments")}
                                    </p>
                                    <p className="title">
                                      Toyota Innova, Honda City
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div> <div
                              className={
                                sidebar
                                  ? "col-lg-4 col-md-6"
                                  : "col-lg-3 col-md-6"
                              }
                            >
                              <div
                                className={
                                  "common-vehical-card-inner cv-card p-0"
                                }
                              >
                                <div className="vehical-card-head vc-top">
                                  <div className="heading top-avatar-wrapper">
                                    <img
                                      src={Grouplogo}
                                      alt=""
                                      className="custom-Margin"
                                    />
                                    <div className="">
                                      <p className="sub-heading">{t("Driver Name")}</p>
                                      <p className="title">Mark S. Woods</p>
                                    </div>
                                  </div>
                                  <div className="option customer-option">
                                    <Dropdown>
                                      <Dropdown.Toggle id="dropdown-basic">
                                        <img src={option} alt="" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ViewVehicleAssistants"
                                            className="d-block"
                                          >
                                            {t("View")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/EditVehicleAssistants"
                                            className="d-block"
                                          >
                                            {t("Edit")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ChangeDelPass"
                                            className="d-block"
                                          >
                                            {t("Change Password")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow1}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Block")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow2}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Resign")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Delete")}
                                          </Link>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="vehical-card-body vc-body row g-0">
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Email")}</p>
                                    <p className="title">markwoods@gmail.com</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Contact No.")}</p>
                                    <p className="title">99999 99999</p>
                                  </div>
                                  <div className="card-contain col-lg-12">
                                    <p className="sub-heading">
                                      {t("Vehicle Assignments")}
                                    </p>
                                    <p className="title">
                                      Toyota Innova, Honda City
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div> <div
                              className={
                                sidebar
                                  ? "col-lg-4 col-md-6"
                                  : "col-lg-3 col-md-6"
                              }
                            >
                              <div
                                className={
                                  "common-vehical-card-inner cv-card p-0"
                                }
                              >
                                <div className="vehical-card-head vc-top">
                                  <div className="heading top-avatar-wrapper">
                                    <img
                                      src={Grouplogo}
                                      alt=""
                                      className="custom-Margin"
                                    />
                                    <div className="">
                                      <p className="sub-heading">{t("Driver Name")}</p>
                                      <p className="title">Mark S. Woods</p>
                                    </div>
                                  </div>
                                  <div className="option customer-option">
                                    <Dropdown>
                                      <Dropdown.Toggle id="dropdown-basic">
                                        <img src={option} alt="" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ViewVehicleAssistants"
                                            className="d-block"
                                          >
                                            {t("View")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/EditVehicleAssistants"
                                            className="d-block"
                                          >
                                            {t("Edit")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ChangeDelPass"
                                            className="d-block"
                                          >
                                            {t("Change Password")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow1}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Block")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow2}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Resign")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Delete")}
                                          </Link>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="vehical-card-body vc-body row g-0">
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Email")}</p>
                                    <p className="title">markwoods@gmail.com</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Contact No.")}</p>
                                    <p className="title">99999 99999</p>
                                  </div>
                                  <div className="card-contain col-lg-12">
                                    <p className="sub-heading">
                                      {t("Vehicle Assignments")}
                                    </p>
                                    <p className="title">
                                      Toyota Innova, Honda City
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div> <div
                              className={
                                sidebar
                                  ? "col-lg-4 col-md-6"
                                  : "col-lg-3 col-md-6"
                              }
                            >
                              <div
                                className={
                                  "common-vehical-card-inner cv-card p-0"
                                }
                              >
                                <div className="vehical-card-head vc-top">
                                  <div className="heading top-avatar-wrapper">
                                    <img
                                      src={Grouplogo}
                                      alt=""
                                      className="custom-Margin"
                                    />
                                    <div className="">
                                      <p className="sub-heading">{t("Driver Name")}</p>
                                      <p className="title">Mark S. Woods</p>
                                    </div>
                                  </div>
                                  <div className="option customer-option">
                                    <Dropdown>
                                      <Dropdown.Toggle id="dropdown-basic">
                                        <img src={option} alt="" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ViewVehicleAssistants"
                                            className="d-block"
                                          >
                                            {t("View")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/EditVehicleAssistants"
                                            className="d-block"
                                          >
                                            {t("Edit")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ChangeDelPass"
                                            className="d-block"
                                          >
                                            {t("Change Password")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow1}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Block")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow2}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Resign")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Delete")}
                                          </Link>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="vehical-card-body vc-body row g-0">
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Email")}</p>
                                    <p className="title">markwoods@gmail.com</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Contact No.")}</p>
                                    <p className="title">99999 99999</p>
                                  </div>
                                  <div className="card-contain col-lg-12">
                                    <p className="sub-heading">
                                      {t("Vehicle Assignments")}
                                    </p>
                                    <p className="title">
                                      Toyota Innova, Honda City
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div> <div
                              className={
                                sidebar
                                  ? "col-lg-4 col-md-6"
                                  : "col-lg-3 col-md-6"
                              }
                            >
                              <div
                                className={
                                  "common-vehical-card-inner cv-card p-0"
                                }
                              >
                                <div className="vehical-card-head vc-top">
                                  <div className="heading top-avatar-wrapper">
                                    <img
                                      src={Grouplogo}
                                      alt=""
                                      className="custom-Margin"
                                    />
                                    <div className="">
                                      <p className="sub-heading">{t("Driver Name")}</p>
                                      <p className="title">Mark S. Woods</p>
                                    </div>
                                  </div>
                                  <div className="option customer-option">
                                    <Dropdown>
                                      <Dropdown.Toggle id="dropdown-basic">
                                        <img src={option} alt="" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ViewVehicleAssistants"
                                            className="d-block"
                                          >
                                            {t("View")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/EditVehicleAssistants"
                                            className="d-block"
                                          >
                                            {t("Edit")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ChangeDelPass"
                                            className="d-block"
                                          >
                                            {t("Change Password")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow1}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Block")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow2}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Resign")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Delete")}
                                          </Link>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="vehical-card-body vc-body row g-0">
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Email")}</p>
                                    <p className="title">markwoods@gmail.com</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Contact No.")}</p>
                                    <p className="title">99999 99999</p>
                                  </div>
                                  <div className="card-contain col-lg-12">
                                    <p className="sub-heading">
                                      {t("Vehicle Assignments")}
                                    </p>
                                    <p className="title">
                                      Toyota Innova, Honda City
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div> <div
                              className={
                                sidebar
                                  ? "col-lg-4 col-md-6"
                                  : "col-lg-3 col-md-6"
                              }
                            >
                              <div
                                className={
                                  "common-vehical-card-inner cv-card p-0"
                                }
                              >
                                <div className="vehical-card-head vc-top">
                                  <div className="heading top-avatar-wrapper">
                                    <img
                                      src={Grouplogo}
                                      alt=""
                                      className="custom-Margin"
                                    />
                                    <div className="">
                                      <p className="sub-heading">{t("Driver Name")}</p>
                                      <p className="title">Mark S. Woods</p>
                                    </div>
                                  </div>
                                  <div className="option customer-option">
                                    <Dropdown>
                                      <Dropdown.Toggle id="dropdown-basic">
                                        <img src={option} alt="" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ViewVehicleAssistants"
                                            className="d-block"
                                          >
                                            {t("View")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/EditVehicleAssistants"
                                            className="d-block"
                                          >
                                            {t("Edit")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ChangeDelPass"
                                            className="d-block"
                                          >
                                            {t("Change Password")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow1}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Block")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow2}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Resign")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Delete")}
                                          </Link>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="vehical-card-body vc-body row g-0">
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Email")}</p>
                                    <p className="title">markwoods@gmail.com</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Contact No.")}</p>
                                    <p className="title">99999 99999</p>
                                  </div>
                                  <div className="card-contain col-lg-12">
                                    <p className="sub-heading">
                                      {t("Vehicle Assignments")}
                                    </p>
                                    <p className="title">
                                      Toyota Innova, Honda City
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div> <div
                              className={
                                sidebar
                                  ? "col-lg-4 col-md-6"
                                  : "col-lg-3 col-md-6"
                              }
                            >
                              <div
                                className={
                                  "common-vehical-card-inner cv-card p-0"
                                }
                              >
                                <div className="vehical-card-head vc-top">
                                  <div className="heading top-avatar-wrapper">
                                    <img
                                      src={Grouplogo}
                                      alt=""
                                      className="custom-Margin"
                                    />
                                    <div className="">
                                      <p className="sub-heading">{t("Driver Name")}</p>
                                      <p className="title">Mark S. Woods</p>
                                    </div>
                                  </div>
                                  <div className="option customer-option">
                                    <Dropdown>
                                      <Dropdown.Toggle id="dropdown-basic">
                                        <img src={option} alt="" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ViewVehicleAssistants"
                                            className="d-block"
                                          >
                                            {t("View")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/EditVehicleAssistants"
                                            className="d-block"
                                          >
                                            {t("Edit")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <Link
                                            to="/ChangeDelPass"
                                            className="d-block"
                                          >
                                            {t("Change Password")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow1}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Block")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow2}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Resign")}
                                          </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={handleShow}
                                        >
                                          <Link to="#" className="d-block">
                                            {t("Delete")}
                                          </Link>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="vehical-card-body vc-body row g-0">
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Email")}</p>
                                    <p className="title">markwoods@gmail.com</p>
                                  </div>
                                  <div className="card-contain col-lg-6">
                                    <p className="sub-heading">{t("Contact No.")}</p>
                                    <p className="title">99999 99999</p>
                                  </div>
                                  <div className="card-contain col-lg-12">
                                    <p className="sub-heading">
                                      {t("Vehicle Assignments")}
                                    </p>
                                    <p className="title">
                                      Toyota Innova, Honda City
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Tab.Container>
            </div>
            <p className="reg-color mt-3">{t("Showing")} 1 - 10 of 200</p>
            {/* Delete Modal Start */}
            <Modal
              show={show}
              onHide={handleClose}
              centered
              className="common-model"
            >
              <Modal.Header closeButton>
                <Modal.Title>{t("Delete Assistant")}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {t("Are you sure you want to Delete this Assistant")} ?
              </Modal.Body>
              <Modal.Footer className="pop-up-modal-footer">
                <div class="btn-wrapper">
                  <button className="cx-btn-1" onClick={handleClose}>
                    {t("Cancel")}
                  </button>
                  <button className="cx-btn-2" onClick={handleClose}>
                    {t("Yes")}
                  </button>
                </div>
              </Modal.Footer>
            </Modal>
            {/* Delete Modal End */}

            {/* Block Modal Start */}
            <Modal
              show={show1}
              onHide={handleClose1}
              centered
              className="common-model"
            >
              <Modal.Header closeButton>
                <Modal.Title>{t("Block")}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{t("Are you sure you want to Block")} ?</Modal.Body>
              <Modal.Footer className="pop-up-modal-footer">
                <div class="btn-wrapper">
                  <button className="cx-btn-1" onClick={handleClose1}>
                    {t("Cancel")}
                  </button>
                  <button className="cx-btn-2" onClick={handleClose1}>
                    {t("Yes")}
                  </button>
                </div>
              </Modal.Footer>
            </Modal>
            {/* Block Modal End */}

            {/* Resign/retire Modal Start */}
            <Modal
              show={show2}
              onHide={handleClose2}
              centered
              className="common-model"
            >
              <Modal.Header closeButton>
                <Modal.Title>{t("Resign / Retire")} </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {t("Are you sure you want to Resign / Retire")} ?
              </Modal.Body>
              <Modal.Footer className="pop-up-modal-footer">
                <div class="btn-wrapper">
                  <button className="cx-btn-1" onClick={handleClose2}>
                    {t("Cancel")}
                  </button>
                  <button className="cx-btn-2" onClick={handleClose2}>
                    {t("Yes")}
                  </button>
                </div>
              </Modal.Footer>
            </Modal>
            {/* Resign/retire Modal End */}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default VehicleAssistants;
