import { React, useContext, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import DDlogo from "../../../assets/images/smallDD.svg";
import Calendar from "../../../assets/images/calendar.svg";
import DatePicker from "react-datepicker";
import { Link, useNavigate } from "react-router-dom";
import SideIc from "../../../assets/images/sideBar.svg";
import Delete from "../../../assets/images/delete.svg";
import View from "../../../assets/images/Group.svg";
import EditIc from "../../../assets/images/ic-edit.svg";
import { Dropdown, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import export_icon from "../../../assets/images/export_icon.svg";
import Form from "react-bootstrap/Form";
import { motion } from "framer-motion";
import Pagenation from "../../../sharedComponent/Pagenation";
import { useTranslation } from "react-i18next";
import PreviousButton from "../../../sharedComponent/PreviousButton";
import NextButton from "../../../sharedComponent/Nextbutton";
import ExistButton from "../../../sharedComponent/ExistButton";
import InfoComponent from "../../../sharedComponent/SupportHelp/InfoComponent";
const VehicleFine = ({ onClick }) => {
  const { sidebar, setSidebar, Dark, setDark, slider, setSlider } =
    useContext(AppContext);
  const [startDate, setStartDate] = useState(new Date());
  const [deleteModal, setDeleteModal] = useState(false);
  const { t, i18n } = useTranslation();

  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  const navigate = useNavigate();
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    navigate("/AddVehicleFine");
  };
  const handleClickPrevious = () => {
    navigate("/AddVehicleSpareParts");
  };
  const renderTooltipForEdit = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {t("Edit")}
    </Tooltip>
  );
  const renderTooltipForView = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {t("View")}
    </Tooltip>
  );
  const renderTooltipForDelete = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {t("Delete")}
    </Tooltip>
  );
  return (
    <>
      <motion.div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }}
      >
        <div id="cx-wrapper" className="Vehicle_Fine">
          {/* Top inputs for instatnt search */}
          <div className="displayFlexInp">
            <div className="innerSelectBox weekCounter selectForm form_input_main">
              <Form.Select
                required
                as="select"
                type="select"
                name="Speed_limit"
                className="innerCust"
              >
                <option value="">Vehicle Name</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="150">150</option>
              </Form.Select>
            </div>
            <div className="innerSelectBox weekCounter selectForm form_input_main">
              <Form.Control
                required
                type="text"
                placeholder="Reason, Status"
                className="innerCust"
              />
            </div>
            <div className="innerSelectBox weekCounter selectForm form_input_main">
              <Form.Control
                required
                type="text"
                placeholder="Amount"
                className="innerCust"
              />
            </div>
            <div className="innerSelectBox weekCounter selectForm form_input_main datepicker-main">
              <DatePicker
                selected={startDate}
                // placeholderText="Select Date"
                onChange={(date) => setStartDate(date)}
                className="form-control innerCust"
              />
              <img src={Calendar} className="calendarLogo" alt="" />
            </div>
            <div className="innerSelectBox weekCounter selectForm form_input_main">
              <Link to="/AddVehicleFine" className="AddAccidentLink">
                <button className="innerCust">+ {t("Add Fine")}</button>
              </Link>
            </div>
            <div className="headerDivIc form_input_main">
              <Link to="#">
                <img src={SideIc} alt="" />
              </Link>
            </div>
            {/* <div className="headerDivIc form_input_main">
              <Link to="#">
                <img src={export_icon} alt="" />
              </Link>
            </div> */}
            <div style={{ marginRight: "10px" }} />
            <div className="right-export-btn-section-wrapper">
              <div className="c-pointer me-2"></div>

              <div className="md_dropdown">
                <Dropdown>
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    className="export-toggle"
                  >
                    <p className="Export_Text">{t("Export")}</p>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <Link
                        // onClick={() => getExportChat()}
                        className="d-block"
                      >
                        {t("PDF")}
                      </Link>
                    </Dropdown.Item>

                    <Dropdown.Item>
                      <Link
                        // onClick={() => downLoadExcelSheet()}
                        className="d-block"
                      >
                        {t("Excel")}
                      </Link>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>

          {/* Vehicle table */}
          <div className="main-master-wrapper">
            <div id="scroll_insideThe_Padding_tabel">
              <div className="VA_table">
                <table className="table tableAdmin">
                  <thead className="tableHead">
                    <tr>
                      <th>{t("Sr.no")}</th>
                      <th>{t("Vehicle Name")}</th>
                      <th>{t("Reason")}</th>
                      <th>{t("Status")}</th>
                      <th>{t("Amount")}</th>
                      <th>{t("Date")}</th>
                      <th>{t("Action")}</th>
                    </tr>
                  </thead>
                  <tbody className="tableBody">
                    <tr>
                      <td>1</td>
                      <td>Toyota Innova</td>
                      <td>Signal Break</td>
                      <td>Bad</td>
                      <td>500</td>
                      <td>01-01-2023</td>
                      <td>
                        <div className="innerFlex d-flex">
                          <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltipForView}
                          >
                            <Link to="/ViewVehicleFine">
                              <div className="inconsIn me-3">
                                <img src={View} alt="" />
                              </div>
                            </Link>
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltipForEdit}
                          >
                            <Link to="/EditVehicleFine">
                              <div className="inconsIn me-3">
                                <img src={EditIc} alt="" />
                              </div>
                            </Link>
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltipForDelete}
                          >
                            <Link to="#" onClick={() => setDeleteModal(true)}>
                              <div className="inconsIn">
                                <img src={Delete} alt="" />
                              </div>
                            </Link>
                          </OverlayTrigger>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>Toyota Innova</td>
                      <td>Signal Break</td>
                      <td>Bad</td>
                      <td>500</td>
                      <td>01-01-2023</td>
                      <td>
                        <div className="innerFlex">
                          <Link to="/ViewVehicleFine">
                            <img src={View} className="me-3" alt="" />
                          </Link>
                          <Link to="/EditVehicleFine">
                            <img src={EditIc} alt="" className="me-3" />
                          </Link>
                          <Link to="#" onClick={() => setDeleteModal(true)}>
                            <img src={Delete} alt="" />
                          </Link>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>Toyota Innova</td>
                      <td>Signal Break</td>
                      <td>Bad</td>
                      <td>500</td>
                      <td>01-01-2023</td>
                      <td>
                        <div className="innerFlex">
                          <Link to="/ViewVehicleFine">
                            <img src={View} className="me-3" alt="" />
                          </Link>
                          <Link to="/EditVehicleFine">
                            <img src={EditIc} alt="" className="me-3" />
                          </Link>
                          <Link to="#" onClick={() => setDeleteModal(true)}>
                            <img src={Delete} alt="" />
                          </Link>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>Toyota Innova</td>
                      <td>Signal Break</td>
                      <td>Bad</td>
                      <td>500</td>
                      <td>01-01-2023</td>
                      <td>
                        <div className="innerFlex">
                          <Link to="/ViewVehicleFine">
                            <img src={View} className="me-3" alt="" />
                          </Link>
                          <Link to="/EditVehicleFine">
                            <img src={EditIc} alt="" className="me-3" />
                          </Link>
                          <Link to="#" onClick={() => setDeleteModal(true)}>
                            <img src={Delete} alt="" />
                          </Link>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>Toyota Innova</td>
                      <td>Signal Break</td>
                      <td>Bad</td>
                      <td>500</td>
                      <td>01-01-2023</td>
                      <td>
                        <div className="innerFlex">
                          <Link to="/ViewVehicleFine">
                            <img src={View} className="me-3" alt="" />
                          </Link>
                          <Link to="/EditVehicleFine">
                            <img src={EditIc} alt="" className="me-3" />
                          </Link>
                          <Link to="#" onClick={() => setDeleteModal(true)}>
                            <img src={Delete} alt="" />
                          </Link>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>Toyota Innova</td>
                      <td>Signal Break</td>
                      <td>Bad</td>
                      <td>500</td>
                      <td>01-01-2023</td>
                      <td>
                        <div className="innerFlex">
                          <Link to="/ViewVehicleFine">
                            <img src={View} className="me-3" alt="" />
                          </Link>
                          <Link to="/EditVehicleFine">
                            <img src={EditIc} alt="" className="me-3" />
                          </Link>
                          <Link to="#" onClick={() => setDeleteModal(true)}>
                            <img src={Delete} alt="" />
                          </Link>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>Toyota Innova</td>
                      <td>Signal Break</td>
                      <td>Bad</td>
                      <td>500</td>
                      <td>01-01-2023</td>
                      <td>
                        <div className="innerFlex">
                          <Link to="/ViewVehicleFine">
                            <img src={View} className="me-3" alt="" />
                          </Link>
                          <Link to="/EditVehicleFine">
                            <img src={EditIc} alt="" className="me-3" />
                          </Link>
                          <Link to="#" onClick={() => setDeleteModal(true)}>
                            <img src={Delete} alt="" />
                          </Link>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>Toyota Innova</td>
                      <td>Signal Break</td>
                      <td>Bad</td>
                      <td>500</td>
                      <td>01-01-2023</td>
                      <td>
                        <div className="innerFlex">
                          <Link to="/ViewVehicleFine">
                            <img src={View} className="me-3" alt="" />
                          </Link>
                          <Link to="/EditVehicleFine">
                            <img src={EditIc} alt="" className="me-3" />
                          </Link>
                          <Link to="#" onClick={() => setDeleteModal(true)}>
                            <img src={Delete} alt="" />
                          </Link>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>Toyota Innova</td>
                      <td>Signal Break</td>
                      <td>Bad</td>
                      <td>500</td>
                      <td>01-01-2023</td>
                      <td>
                        <div className="innerFlex">
                          <Link to="/ViewVehicleFine">
                            <img src={View} className="me-3" alt="" />
                          </Link>
                          <Link to="/EditVehicleFine">
                            <img src={EditIc} alt="" className="me-3" />
                          </Link>
                          <Link to="#" onClick={() => setDeleteModal(true)}>
                            <img src={Delete} alt="" />
                          </Link>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>Toyota Innova</td>
                      <td>Signal Break</td>
                      <td>Bad</td>
                      <td>500</td>
                      <td>01-01-2023</td>
                      <td>
                        <div className="innerFlex">
                          <Link to="/ViewVehicleFine">
                            <img src={View} className="me-3" alt="" />
                          </Link>
                          <Link to="/EditVehicleFine">
                            <img src={EditIc} alt="" className="me-3" />
                          </Link>
                          <Link to="#" onClick={() => setDeleteModal(true)}>
                            <img src={Delete} alt="" />
                          </Link>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>Toyota Innova</td>
                      <td>Signal Break</td>
                      <td>Bad</td>
                      <td>500</td>
                      <td>01-01-2023</td>
                      <td>
                        <div className="innerFlex">
                          <Link to="/ViewVehicleFine">
                            <img src={View} className="me-3" alt="" />
                          </Link>
                          <Link to="/EditVehicleFine">
                            <img src={EditIc} alt="" className="me-3" />
                          </Link>
                          <Link to="#" onClick={() => setDeleteModal(true)}>
                            <img src={Delete} alt="" />
                          </Link>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>Toyota Innova</td>
                      <td>Signal Break</td>
                      <td>Bad</td>
                      <td>500</td>
                      <td>01-01-2023</td>
                      <td>
                        <div className="innerFlex">
                          <Link to="/ViewVehicleFine">
                            <img src={View} className="me-3" alt="" />
                          </Link>
                          <Link to="/EditVehicleFine">
                            <img src={EditIc} alt="" className="me-3" />
                          </Link>
                          <Link to="#" onClick={() => setDeleteModal(true)}>
                            <img src={Delete} alt="" />
                          </Link>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>Toyota Innova</td>
                      <td>Signal Break</td>
                      <td>Bad</td>
                      <td>500</td>
                      <td>01-01-2023</td>
                      <td>
                        <div className="innerFlex">
                          <Link to="/ViewVehicleFine">
                            <img src={View} className="me-3" alt="" />
                          </Link>
                          <Link to="/EditVehicleFine">
                            <img src={EditIc} alt="" className="me-3" />
                          </Link>
                          <Link to="#" onClick={() => setDeleteModal(true)}>
                            <img src={Delete} alt="" />
                          </Link>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>Toyota Innova</td>
                      <td>Signal Break</td>
                      <td>Bad</td>
                      <td>500</td>
                      <td>01-01-2023</td>
                      <td>
                        <div className="innerFlex">
                          <Link to="/ViewVehicleFine">
                            <img src={View} className="me-3" alt="" />
                          </Link>
                          <Link to="/EditVehicleFine">
                            <img src={EditIc} alt="" className="me-3" />
                          </Link>
                          <Link to="#" onClick={() => setDeleteModal(true)}>
                            <img src={Delete} alt="" />
                          </Link>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <Pagenation />
          </div>
          {slider ? <ExistButton onClose={() => setSlider(false)} /> : null}
          {!slider && <PreviousButton onBack={handleClickPrevious} />}
          {!slider && <NextButton onClick={handleClick} />}
          {!slider && <InfoComponent />}
        </div>
      </motion.div>

      {/* Delete Modal Start */}
      <Modal
        show={deleteModal}
        onHide={() => setDeleteModal(false)}
        centered
        className="common-model"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Delete Vehicle Fine")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("Are you sure you want to Delete this Vehicle Fine")} ?
        </Modal.Body>
        <Modal.Footer className="pop-up-modal-footer">
          <div class="btn-wrapper">
            <button className="cx-btn-1" onClick={() => setDeleteModal(false)}>
              {t("Cancel")}
            </button>
            <button className="cx-btn-2" onClick={() => setDeleteModal(false)}>
              {t("Yes")}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* Delete Modal End */}
    </>
  );
};

export default VehicleFine;
