import React, { useContext } from "react";
import { Dropdown, Tab, Tabs } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import View from "../../../assets/images/Group.svg";
import Export from "../../../assets/images/Edit-Camunication.svg";
import { motion } from "framer-motion";
import ExistButton from "../../../sharedComponent/ExistButton";
import PreviousButton from "../../../sharedComponent/PreviousButton";
import NextButton from "../../../sharedComponent/Nextbutton";
const ReportView = ({ onClick }) => {
  const { sidebar, setSidebar, Dark, setDark, slider, setSlider } =
    useContext(AppContext);
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  const navigate = useNavigate();
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    navigate("/DeliveryRequest");
  };
  const handleClickPrevious = () => {
    navigate("/VehicleBooking");
  };
  return (
    <motion.div
      className={sidebar ? "taskMain " : "cx-active taskMain"}
      id="cx-main"
      variants={aninations}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.1 }}
    >
      <div id="cx-wrapper">
        <div className="report-viwe-head" id="Viwe_Report_Respoasive">
          <p>Vehicle Summary Report : January 2023</p>
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic">Sort By</Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="#/action-1" className="tolisthead">
                <p>Sort By</p>
                <p className="custom-save-btn">Save</p>
              </Dropdown.Item>
              <Dropdown.Item href="#/action-2">Annual</Dropdown.Item>
              <Dropdown.Item href="#/action-3">Semi-Annual</Dropdown.Item>
              <Dropdown.Item href="#/action-3">Quater</Dropdown.Item>
              <Dropdown.Item href="#/action-3">Month</Dropdown.Item>
              <Dropdown.Item href="#/action-3">Week</Dropdown.Item>
              <Dropdown.Item href="#/action-3">Day</Dropdown.Item>
              <Dropdown.Item href="#/action-3">Custom Range</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="Vehcle-main-tabs">
          <div className="main-master-wrapper" id="View_Report_Header">
            <div className="all-vehical-head row vehicle-top-inputs">
              <div className="input-section-wrapper">
                <div className="row">
                  <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Trip Name"
                    />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      placeholder="Vehicle"
                    >
                      <option selected>Vehicle</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      placeholder="Driver"
                    >
                      <option selected>Driver</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="right-export-btn-section-wrapper">
                <Link to="#">
                  <div className="c-pointer">
                    <img src={Export} alt="" />
                  </div>
                </Link>
                <div className="c-pointer">
                  {/* <img src={Import} alt="" /> */}
                </div>
              </div>
            </div>
            <div className="yauto">
              <div className="totalTabelHeight">
                <table className="table tableAdmin tabelheiht">
                  <thead className="tableHead">
                    <tr>
                      <th>Sr.no</th>
                      <th>Trip Name</th>
                      <th>Vehicle</th>
                      <th>Driver</th>
                      <th>Subject</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody className="tableBody">
                    <tr>
                      <td>1</td>
                      <td>Mr. John Doe</td>
                      <td>Mr. John Doe</td>
                      <td>john@gmail.com </td>
                      <td>+91 99 99 99 9999</td>
                      <td>
                        <div className="innerFlex">
                          <Link to="/NotificationDetails ">
                            <img src={View} className="me-3" alt="" />
                          </Link>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>Mr. John Doe</td>
                      <td>Mr. John Doe</td>
                      <td>john@gmail.com </td>
                      <td>+91 99 99 99 9999</td>
                      <td>
                        <div className="innerFlex">
                          <Link to="/NotificationDetails ">
                            <img src={View} className="me-3" alt="" />
                          </Link>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>Mr. John Doe</td>
                      <td>Mr. John Doe</td>
                      <td>john@gmail.com </td>
                      <td>+91 99 99 99 9999</td>
                      <td>
                        <div className="innerFlex">
                          <Link to="/NotificationDetails ">
                            <img src={View} className="me-3" alt="" />
                          </Link>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>Mr. John Doe</td>
                      <td>Mr. John Doe</td>
                      <td>john@gmail.com </td>
                      <td>+91 99 99 99 9999</td>
                      <td>
                        <div className="innerFlex">
                          <Link to="/NotificationDetails ">
                            <img src={View} className="me-3" alt="" />
                          </Link>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>Mr. John Doe</td>
                      <td>Mr. John Doe</td>
                      <td>john@gmail.com </td>
                      <td>+91 99 99 99 9999</td>
                      <td>
                        <div className="innerFlex">
                          <Link to="/NotificationDetails ">
                            <img src={View} className="me-3" alt="" />
                          </Link>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>Mr. John Doe</td>
                      <td>Mr. John Doe</td>
                      <td>john@gmail.com </td>
                      <td>+91 99 99 99 9999</td>
                      <td>
                        <div className="innerFlex">
                          <Link to="/NotificationDetails ">
                            <img src={View} className="me-3" alt="" />
                          </Link>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>Mr. John Doe</td>
                      <td>Mr. John Doe</td>
                      <td>john@gmail.com </td>
                      <td>+91 99 99 99 9999</td>
                      <td>
                        <div className="innerFlex">
                          <Link to="/NotificationDetails ">
                            <img src={View} className="me-3" alt="" />
                          </Link>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>Mr. John Doe</td>
                      <td>Mr. John Doe</td>
                      <td>john@gmail.com </td>
                      <td>+91 99 99 99 9999</td>
                      <td>
                        <div className="innerFlex">
                          <Link to="/NotificationDetails ">
                            <img src={View} className="me-3" alt="" />
                          </Link>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>Mr. John Doe</td>
                      <td>Mr. John Doe</td>
                      <td>john@gmail.com </td>
                      <td>+91 99 99 99 9999</td>
                      <td>
                        <div className="innerFlex">
                          <Link to="/NotificationDetails ">
                            <img src={View} className="me-3" alt="" />
                          </Link>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>Mr. John Doe</td>
                      <td>Mr. John Doe</td>
                      <td>john@gmail.com </td>
                      <td>+91 99 99 99 9999</td>
                      <td>
                        <div className="innerFlex">
                          <Link to="/NotificationDetails ">
                            <img src={View} className="me-3" alt="" />
                          </Link>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {slider ? <ExistButton onClose={() => setSlider(false)} /> : null}
        {!slider && <PreviousButton onBack={handleClickPrevious} />}
        {!slider && <NextButton onClick={handleClick} />}
      </div>
    </motion.div>
  );
};

export default ReportView;
