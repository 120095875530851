import React, { useContext, useState } from "react";
import arrow from "../../assets/images/ic_line_arrow_left.svg";
import { motion } from "framer-motion";

import logo from "../../assets/images/ic_lock.svg";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";

const ForgetPassword = () => {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };
  const aninations ={
    initial: {opacity:0, x:400},
    animate:{opacity:1, x:0},
    exit: {opacity:0, x: 100},
  }
  return (
    <motion.div className="main-auth"
    variants={aninations}
    initial="initial" animate="animate" exit="exit"  
    transition={{duration: 0.5}}
    >
      <div className="login-wrapper">
        <div className="row height-style">
          
          <div className="col-md-6 left">
            <div className="bg-img">
              <h3>
                A New Way <br /> To Track Your <br /> Vehicle
              </h3>
            </div>
          </div>

          <div className="col-md-6 right cx-relative">
            
            <div className="wrapper forgot-wrapper  row">
            
              <div>
              <div className="arrow ">
              <Link to="/"><img src={arrow} alt="" /></Link>
            </div>
              <div className="top-logo">
                <img src={logo} alt="" />
              </div>
              <h3>Lost your password ?</h3>
              <div className="auth-form">
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <div className="mb-4">
                    <Form.Label className="common-labels">
                    Enter your e-mail address below to reset your password.
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Enter you Email ID / Mobile Number"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please Enter Enter you Email ID / Mobile Number.
                    </Form.Control.Feedback>
                  </div>
                  
                 
                  <div className="btn-auth">
                    <Link to="#">
                      <button type="submit" className="filled-btn">
                      Recover
                      </button>
                    </Link>

                    <div className="link-style">
                      <Link to="#">2023 @ Vehicle Tracking</Link>
                    </div>
                  </div>
                </Form>
              </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default ForgetPassword;

