import React, { useContext, useState } from "react";
import { Form } from "react-bootstrap";
import { AppContext } from "../../context/AppContext";
import DDlogo from "../../assets/images/DDlogo.png";
import Multiselect from "multiselect-react-dropdown";
import Geofance from "../../assets/images/Geo-fance.svg";
import Rectangle from "../../assets/images/Rectangle.svg";
import plam from "../../assets/images/plam.svg";
import { Link } from "react-router-dom";
import whiteTrash from "../../assets/images/whiteTrash.svg";
import whiteHand from "../../assets/images/whiteHand.svg";
import whiteBox from "../../assets/images/whiteBox.svg";
import whiteBin from "../../assets/images/whiteBin.svg";
import { motion } from "framer-motion";
import MapComponent from "../../sharedComponent/MapComponent";
import { useTranslation } from "react-i18next";
const AddVehicleGroup = () => {
  const { sidebar, setSidebar, Dark, setDark } = useContext(AppContext);
  const [validated, setValidated] = useState(false);
  const { t, i18n } = useTranslation();

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  return (
    <motion.div
      className={sidebar ? "taskMain " : "cx-active taskMain"}
      id="cx-main"
      variants={aninations}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.1 }}
    >
      <div id="cx-wrapper" className="AddVehicle_Group">
        <div className="main-master-wrapper ">
          <div className="Heading">
            <p>{t("Add Vehicle Group")}</p>
          </div>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <div className="row vehicle_caregory">
              <div className="col-md-6 mb-3">
                <Form.Label className="common-labels" for="typeVi">
                  {t("Group Name")}
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  id="typeVi"
                  placeholder="Enter Group Name..."
                />
                <Form.Control.Feedback type="invalid">
                  Enter Group Name
                </Form.Control.Feedback>
              </div>
              <div className="col-md-6 mb-3 innerInputsGen">
                <div className="innerSelectBox">
                  <Form.Label className="common-labels me-0">
                    {t("Group Manager")}
                  </Form.Label>
                  <select
                  required
                    className="form-select"
                    aria-label="Default select example"
                    placeholder="Select Fuel Type..."
                  >
                    <option selected value="">Select Group Manager</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <Form.Label className="common-labels custom-label-title me-0">
                  <p>{t("Vehicle Group")}</p>
                </Form.Label>
                <div className="dropdown-wrapper w-100">
                  <div className="multi-select-1 w-100">
                    <Multiselect
                      className="multi-select-main"
                      displayValue="key"
                      onKeyPressFn={function noRefCheck() {}}
                      onRemove={function noRefCheck() {}}
                      onSearch={function noRefCheck() {}}
                      onSelect={function noRefCheck() {}}
                      options={[
                        {
                          cat: "Group 1",
                          key: "Car (20)",
                        },
                        {
                          cat: "Group 1",
                          key: "Pickup-Truck (10)",
                        },
                        {
                          cat: "Group 1",
                          key: "Pickup-Van (10)",
                        },
                        {
                          cat: "Group 2",
                          key: "Utility CAB (10)",
                        },
                        {
                          cat: "Group 2",
                          key: "Motorcycle (20)",
                        },
                        {
                          cat: "Group 2",
                          key: "Scooter (10)",
                        },
                        {
                          cat: "Group 2",
                          key: "Truck (10)",
                        },
                        {
                          cat: "Group 2",
                          key: "Sedan-Car (10)",
                        },
                      ]}
                      showCheckbox
                      placeholder="Select Vehicle Group ..."
                      style={{
                        chips: {
                          background: "#8f4300",
                          "border-radius": "20px",
                        },
                        input: {
                          "&::checked": {
                            color: "#8f4300",
                          },
                        },
                        searchBox: {},
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="addnew-map">
                <div className="road-map addParkingMap">
                  
                  <MapComponent />
                </div>
              </div>
              <div className="btn-common btn-wrapper">
                <Link to="/Vehicle">
                  {" "}
                  <button className="cx-btn-1">{t("Cancel")}</button>
                </Link>
                <Link to="/Vehicle">
                  {" "}
                  <button className="cx-btn-2">{t("Submit")}</button>
                </Link>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </motion.div>
  );
};

export default AddVehicleGroup;
