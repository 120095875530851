import React, { useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import profile from "../../assets/images/Update-profile.svg";
import camera from "../../assets/images/circleCamers.svg";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import whiteTrash from "../../assets/images/whiteTrash.svg";
import whiteHand from "../../assets/images/whiteHand.svg";
import whiteBox from "../../assets/images/whiteBox.svg";
import whiteBin from "../../assets/images/whiteBin.svg";
import { motion } from "framer-motion";
import MapComponent from "../../sharedComponent/MapComponent";
import { useTranslation } from "react-i18next";

const UpdateCustomerProfile = () => {
  const { sidebar, setSidebar, Dark, setDark } = useContext(AppContext);
  const [validated, setValidated] = useState(false);
  const { t, i18n } = useTranslation();

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  const renderTooltipForPoligon = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Draw Polygon
    </Tooltip>
  );
  const renderTooltipForRectangle = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Draw Rectangle
    </Tooltip>
  );
  const renderTooltipForPalm = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Move
    </Tooltip>
  );
  const renderTooltipForDelet = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Delete
    </Tooltip>
  );
  return (
    <motion.div
      className={sidebar ? "taskMain " : "cx-active taskMain"}
      id="cx-main"
      variants={aninations}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.1 }}
    >
      <div id="cx-wrapper" className="Update_Customer_Profile">
        <div className="main-dashboard-wrapper CustomerProfile ">
          <p className="main-page-heading customerHeading">
            {t("Update Customer Profile")}
          </p>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <div className="CustomerProfile-head">
              <label
                htmlFor="uploadPic"
                className="porile-img d-block c-pointer"
              >
                <Form.Control
                  required
                  type="file"
                  id="uploadPic"
                  className="d-none"
                />
                <Form.Control.Feedback type="invalid">
                  Please Choose Picture.
                </Form.Control.Feedback>

                <img src={profile} alt="porfile" />
                <img src={camera} alt="" className="cameraimg" />
              </label>
            </div>
            <div className="information-card row mb-0">
              <div className="col-md-6 form_input_main ">
                <Form.Label className="common-labels">
                  {t("Customer Name")}
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Customer Name"
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Customer Name.
                </Form.Control.Feedback>
              </div>

              <div className="col-md-6 form_input_main">
                <Form.Label className="common-labels">
                  {t("Contact Number")}
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Contact Number"
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Contact No.
                </Form.Control.Feedback>
              </div>

              <div className="col-lg-6 form_input_main">
                <Form.Label className="common-labels">
                  {t("Address")}
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={6}
                  placeholder="Address"
                  required
                  style={{ height: "200px" }}
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Address.
                </Form.Control.Feedback>
              </div>
              <div className="col-lg-6 ">
                <div className="form_input_main">
                  <Form.Label className="common-labels">
                    {t("Country")}
                  </Form.Label>
                  <Form.Control required type="text" placeholder="Country" />
                  <Form.Control.Feedback type="invalid">
                    Please Enter Country.
                  </Form.Control.Feedback>
                </div>
                <div className=" form_input_main">
                  <Form.Label className="common-labels">{t("City")}</Form.Label>
                  <Form.Control required type="text" placeholder="City" />
                  <Form.Control.Feedback type="invalid">
                    Please Enter City.
                  </Form.Control.Feedback>
                </div>
              </div>

              <div className="col-md-6 form_input_main">
                <div className="">
                  <Form.Label className="common-labels">
                    {t("Website")}
                  </Form.Label>
                  <Form.Control required type="text" placeholder="Website" />
                  <Form.Control.Feedback type="invalid">
                    Please Enter Website.
                  </Form.Control.Feedback>
                </div>
              </div>
              <div className="road-map addParkingMap">
                <div className="color-circle"></div>

                <MapComponent />
              </div>
            </div>
            <div className="btns-main btn-wrapper">
              <button type="" className="cx-btn-1">
                {t("Cancel")}
              </button>
              <button type="submit" className="cx-btn-2">
                {t("Update")}
              </button>
            </div>
          </Form>
        </div>
      </div>
    </motion.div>
  );
};

export default UpdateCustomerProfile;
