import React from 'react'
import { useTranslation } from 'react-i18next';

const Pagenation = () => {
  const { t, i18n } = useTranslation();

  return (
    <p className="reg-color mt-3">{t("Showing")} 1 - 10 of 200</p>
  )
}

export default Pagenation