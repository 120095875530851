import { React, useContext, useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { AppContext } from "../../context/AppContext";
import delete_icon from "../../assets/images/delete.svg";
import { Tab, Tabs, Form } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

const AddOnSettingsCart = () => {
  const { sidebar, setSidebar, Dark, setDark } = useContext(AppContext);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { t, i18n } = useTranslation();

  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  return (
    <>
      <motion.div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }}
      >
        {/* Vehicle related Setting */}
        <div id="cx-wrapper" className="AddOn_Settings_Cart">
          <div className="row">
            <div className="col-lg-8">
              <div className="main-cart-wrapper">
                <div className="Heading">
                  <p>{t("My Cart")}</p>
                  <p className="cart-count">4</p>
                </div>
                <div className="cart-cards-wrapper">
                  <div className="cart-card">
                    <div className="cc-heading">
                      <p>{t("Add-on Video Surveillance")}</p>
                      <img src={delete_icon} alt="" onClick={handleShow} />
                    </div>
                    <div className="cc-body">
                      <div className="ao-card-table-wrapper">
                        <table className="ao-card-table">
                          <thead className="ao-card-head">
                            <tr>
                              <th>{t("Activation Price")}</th>
                              <th>{t("Monthly Subscription Price")}</th>
                            </tr>
                          </thead>
                          <tbody className="ao-card-body">
                            <tr>
                              <td>50$</td>
                              <td>Free</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="cart-card">
                    <div className="cc-heading">
                      <p>{t("Add-on Video Surveillance")}</p>
                      <img src={delete_icon} alt="" onClick={handleShow} />
                    </div>
                    <div className="cc-body">
                      <div className="ao-card-table-wrapper">
                        <table className="ao-card-table">
                          <thead className="ao-card-head">
                            <tr>
                              <th>{t("Activation Price")}</th>
                              <th>{t("Monthly Subscription Price")}</th>
                            </tr>
                          </thead>
                          <tbody className="ao-card-body">
                            <tr>
                              <td>50$</td>
                              <td>Free</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="cart-card">
                    <div className="cc-heading">
                      <p>{t("Add-on Video Surveillance")}</p>
                      <img src={delete_icon} alt="" onClick={handleShow} />
                    </div>
                    <div className="cc-body">
                      <div className="ao-card-table-wrapper">
                        <table className="ao-card-table">
                          <thead className="ao-card-head">
                            <tr>
                              <th>{t("Activation Price")}</th>
                              <th>{t("Monthly Subscription Price")}</th>
                            </tr>
                          </thead>
                          <tbody className="ao-card-body">
                            <tr>
                              <td>50$</td>
                              <td>Free</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="cart-card">
                    <div className="cc-heading">
                      <p>{t("Add-on Video Surveillance")}</p>
                      <img src={delete_icon} alt="" onClick={handleShow} />
                    </div>
                    <div className="cc-body">
                      <div className="ao-card-table-wrapper">
                        <table className="ao-card-table">
                          <thead className="ao-card-head">
                            <tr>
                              <th>{t("Activation Price")}</th>
                              <th>{t("Monthly Subscription Price")}</th>
                            </tr>
                          </thead>
                          <tbody className="ao-card-body">
                            <tr>
                              <td>50$</td>
                              <td>Free</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="cart-card">
                    <div className="cc-heading">
                      <p>{t("Add-on Video Surveillance")}</p>
                      <img src={delete_icon} alt="" onClick={handleShow} />
                    </div>
                    <div className="cc-body">
                      <div className="ao-card-table-wrapper">
                        <table className="ao-card-table">
                          <thead className="ao-card-head">
                            <tr>
                              <th>{t("Activation Price")}</th>
                              <th>{t("Monthly Subscription Price")}</th>
                            </tr>
                          </thead>
                          <tbody className="ao-card-body">
                            <tr>
                              <td>50$</td>
                              <td>Free</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="cart-card">
                    <div className="cc-heading">
                      <p>{t("Add-on Video Surveillance")}</p>
                      <img src={delete_icon} alt="" onClick={handleShow} />
                    </div>
                    <div className="cc-body">
                      <div className="ao-card-table-wrapper">
                        <table className="ao-card-table">
                          <thead className="ao-card-head">
                            <tr>
                              <th>{t("Activation Price")}</th>
                              <th>{t("Monthly Subscription Price")}</th>
                            </tr>
                          </thead>
                          <tbody className="ao-card-body">
                            <tr>
                              <td>50$</td>
                              <td>Free</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="cart-card">
                    <div className="cc-heading">
                      <p>{t("Add-on Video Surveillance")}</p>
                      <img src={delete_icon} alt="" onClick={handleShow} />
                    </div>
                    <div className="cc-body">
                      <div className="ao-card-table-wrapper">
                        <table className="ao-card-table">
                          <thead className="ao-card-head">
                            <tr>
                              <th>{t("Activation Price")}</th>
                              <th>{t("Monthly Subscription Price")}</th>
                            </tr>
                          </thead>
                          <tbody className="ao-card-body">
                            <tr>
                              <td>50$</td>
                              <td>Free</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="cart-bottom-section">
                  <p>+ {t("Add more add-ons")} ?</p>
                  <Link to="/AddOnSettings">{t("Click here")}...</Link>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="main-summary-wrapper">
                <div className="order-summary">
                  <div className="Heading">
                    <p>{t("Order Summary")}</p>
                  </div>
                  <div className="order-table-wrapper">
                    <table className="order-table">
                      <thead>
                        <tr>
                          <th>{t("Add-on Video Surveillance")}</th>
                          <th>0$</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Activation fees - Free</td>
                        </tr>
                      </tbody>
                      <thead>
                        <tr>
                          <th>{t("Add-on Video Surveillance")}</th>
                          <th>25$</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Activation fees - Free</td>
                        </tr>
                      </tbody>
                      <thead>
                        <tr>
                          <th>{t("Add-on Video Surveillance")}</th>
                          <th>0$</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Activation fees - Free</td>
                        </tr>
                      </tbody>
                      <thead>
                        <tr>
                          <th>{t("Add-on Video Surveillance")}</th>
                          <th>0$</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Activation fees - Free</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="order-total">
                    <p>{t("Total")}</p>
                    <p>25$</p>
                  </div>
                  <div className="cx-btn-2">{t("Checkout Now")}</div>
                </div>
                <div className="subscription-summary">
                  <div className="order-summary">
                    <div className="Heading">
                      <p>{t("Expected Monthly Subscription")}</p>
                    </div>
                    <div className="order-table-wrapper">
                      <table className="order-table">
                        <thead>
                          <tr>
                            <th>{t("Add-on Video Surveillance")}</th>
                            <th>50$</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Activation fees - Free</td>
                          </tr>
                        </tbody>
                        <thead>
                          <tr>
                            <th>{t("Add-on Video Surveillance")}</th>
                            <th>50$</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Activation fees - Free</td>
                          </tr>
                        </tbody>
                        <thead>
                          <tr>
                            <th>{t("Add-on Video Surveillance")}</th>
                            <th>50$</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Activation fees - Free</td>
                          </tr>
                        </tbody>
                        <thead>
                          <tr>
                            <th>{t("Add-on Video Surveillance")}</th>
                            <th>50$</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Activation fees - Free</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="order-total">
                      <p>{t("Total")}</p>
                      <p>200$</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Delete Modal Start */}
        <Modal
          show={show}
          onHide={handleClose}
          centered
          className="common-model"
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("Delete Add-on")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{t("Are you sure you want to Delete this Add-on")} ?</Modal.Body>
          <Modal.Footer className="pop-up-modal-footer">
            <div class="btn-wrapper">
              <button className="cx-btn-1" onClick={handleClose}>
                {t("Cancel")}
              </button>
              <button className="cx-btn-2" onClick={handleClose}>
                {t("Yes")}
              </button>
            </div>
          </Modal.Footer>
        </Modal>
        {/* Delete Modal End */}
      </motion.div>
    </>
  );
};

export default AddOnSettingsCart;
