import React, { useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import Documantaion from "../../assets/images/Documantaion.svg";
import upload_dash from "../../assets/images/upload_dash.svg";
import uploadIcon from "../../assets/images/uploadIcon.svg";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

const UpdateCustomerLogo = () => {
  const { sidebar, setSidebar, Dark, setDark } = useContext(AppContext);
  const { t, i18n } = useTranslation();

  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  return (
    <motion.div className={sidebar ? "taskMain " : "cx-active taskMain"} id="cx-main" variants={aninations}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.1 }}>
      <div id="cx-wrapper" className="Update_Customer_Logo">
        <div className="main-dashboard-wrapper CustomerProfile">
          <p className="main-page-heading">{t("Update Customer Profile")}</p>
          <div className="row upload-customer-logo">
            <div className=" col-md-6 form_input_main  single-card-customer-logo">
              <span className="logo-name">{t("Web Application Logo")}</span>
              <span className="logo-size">(240px X 80px {t("Recommended Size")} )</span>
              <div className="logo-file-name">
                <div className="first">
                  <img src={Documantaion} alt="" />
                  <p>Example Document-Invoice.pdf</p>
                </div>
                <div className="last">
                  <img src={upload_dash} alt="" />
                </div>
              </div>
              <div className="fileDropper">
                <label htmlFor="file" className="imageHolder">
                  <input type="file" id="file" className="d-none" />
                  <div className="innerFlex">
                    <img src={uploadIcon} className="browseImg" alt="" />
                    <p className="innerTxt">Drag & Drop Your File</p>
                    <p className="innerTxt">Or</p>
                    <label htmlFor="file2" className="browseBtn">
                      <input type="file" id="file2" className="d-none" />
                      <p className="mt-1">Browse File</p>
                    </label>
                  </div>
                </label>
              </div>
            </div>
            <div className="col-md-6 form_input_main single-card-customer-logo">
              <span className="logo-name">{t("Customer location in Google map")}</span>
              <span className="logo-size">(40px X 40px {t("Recommended Size")} )</span>
              <div className="logo-file-name">
                <div className="first">
                  <img src={Documantaion} alt="" />
                  <p>Example Document-Invoice.pdf</p>
                </div>
                <div className="last">
                  <img src={upload_dash} alt="" />
                </div>
              </div>
              <div className="fileDropper">
                <label htmlFor="file" className="imageHolder">
                  <input type="file" id="file" className="d-none" />
                  <div className="innerFlex">
                    <img src={uploadIcon} className="browseImg" alt="" />
                    <p className="innerTxt">Drag & Drop Your File</p>
                    <p className="innerTxt">Or</p>
                    <label htmlFor="file2" className="browseBtn">
                      <input type="file" id="file2" className="d-none" />
                      <p className="mt-1">Browse File</p>
                    </label>
                  </div>
                </label>
              </div>
            </div>
            <div className=" col-md-6 form_input_main  single-card-customer-logo">
              <span className="logo-name">{t("Logo For Email Template")}</span>
              <span className="logo-size">(600px X 270px {t("Recommended Size")} )</span>
              <div className="logo-file-name">
                <div className="first">
                  <img src={Documantaion} alt="" />
                  <p>Example Document-Invoice.pdf</p>
                </div>
                <div className="last">
                  <img src={upload_dash} alt="" />
                </div>
              </div>
              <div className="fileDropper">
                <label htmlFor="file" className="imageHolder">
                  <input type="file" id="file" className="d-none" />
                  <div className="innerFlex">
                    <img src={uploadIcon} className="browseImg" alt="" />
                    <p className="innerTxt">Drag & Drop Your File</p>
                    <p className="innerTxt">Or</p>
                    <label htmlFor="file2" className="browseBtn">
                      <input type="file" id="file2" className="d-none" />
                      <p className="mt-1">Browse File</p>
                    </label>
                  </div>
                </label>
              </div>
            </div>
            <div className=" col-md-6 form_input_main single-card-customer-logo">
              <span className="logo-name">{t("Logo For Email Banner")}</span>
              <span className="logo-size">(600px X 270px {t("Recommended Size")} )</span>
              <div className="logo-file-name">
                <div className="first">
                  <img src={Documantaion} alt="" />
                  <p>Example Document-Invoice.pdf</p>
                </div>
                <div className="last">
                  <img src={upload_dash} alt="" />
                </div>
              </div>
              <div className="fileDropper">
                <label htmlFor="file" className="imageHolder">
                  <input type="file" id="file" className="d-none" />
                  <div className="innerFlex">
                    <img src={uploadIcon} className="browseImg" alt="" />
                    <p className="innerTxt">Drag & Drop Your File</p>
                    <p className="innerTxt">Or</p>
                    <label htmlFor="file2" className="browseBtn">
                      <input type="file" id="file2" className="d-none" />
                      <p className="mt-1">Browse File</p>
                    </label>
                  </div>
                </label>
              </div>
            </div>
            <div className=" col-md-6 form_input_main  single-card-customer-logo">
              <span className="logo-name">{t("Logo Trip Start Point")}</span>
              <span className="logo-size">(240px X 80px {t("Recommended Size")} )</span>
              <div className="logo-file-name">
                <div className="first">
                  <img src={Documantaion} alt="" />
                  <p>Example Document-Invoice.pdf</p>
                </div>
                <div className="last">
                  <img src={upload_dash} alt="" />
                </div>
              </div>
              <div className="fileDropper">
                <label htmlFor="file" className="imageHolder">
                  <input type="file" id="file" className="d-none" />
                  <div className="innerFlex">
                    <img src={uploadIcon} className="browseImg" alt="" />
                    <p className="innerTxt">Drag & Drop Your File</p>
                    <p className="innerTxt">Or</p>
                    <label htmlFor="file2" className="browseBtn">
                      <input type="file" id="file2" className="d-none" />
                      <p className="mt-1">Browse File</p>
                    </label>
                  </div>
                </label>
              </div>
            </div>
            <div className=" col-md-6 form_input_main  single-card-customer-logo">
              <span className="logo-name">{t("Logo Trip End Point")}</span>
              <span className="logo-size">(240px X 80px {t("Recommended Size")} )</span>
              <div className="logo-file-name">
                <div className="first">
                  <img src={Documantaion} alt="" />
                  <p>Example Document-Invoice.pdf</p>
                </div>
                <div className="last">
                  <img src={upload_dash} alt="" />
                </div>
              </div>
              <div className="fileDropper">
                <label htmlFor="file" className="imageHolder">
                  <input type="file" id="file" className="d-none" />
                  <div className="innerFlex">
                    <img src={uploadIcon} className="browseImg" alt="" />
                    <p className="innerTxt">Drag & Drop Your File</p>
                    <p className="innerTxt">Or</p>
                    <label htmlFor="file2" className="browseBtn">
                      <input type="file" id="file2" className="d-none" />
                      <p className="mt-1">Browse File</p>
                    </label>
                  </div>
                </label>
              </div>
            </div>
            <div className="btns-main btn-wrapper">
              <Link to="/LogoUpdate">
                <button type="" className="cx-btn-1">
                  {t("Cancel")}
                </button>
              </Link>
              <Link to="/LogoUpdate">
                <button type="submit" className="cx-btn-2">
                  {t("Update")}
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default UpdateCustomerLogo;
