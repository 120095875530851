import { React, useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import Form from "react-bootstrap/Form";
import DDlogo from "../../assets/images/DDlogo.png";
import whiteTrash from "../../assets/images/whiteTrash.svg";
import whiteHand from "../../assets/images/whiteHand.svg";
import whiteBox from "../../assets/images/whiteBox.svg";
import whiteBin from "../../assets/images/whiteBin.svg";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import MapComponent from "../../sharedComponent/MapComponent";
import { useTranslation } from "react-i18next";

const EditParkingSlot = () => {
  const { sidebar, setSidebar, Dark, setDark } = useContext(AppContext);
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  const { t, i18n } = useTranslation();

  const renderTooltipForPoligon = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Draw Polygon
    </Tooltip>
  );
  const renderTooltipForRectangle = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Draw Rectangle
    </Tooltip>
  );
  const renderTooltipForPalm = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Move
    </Tooltip>
  );
  const renderTooltipForDelet = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Delete
    </Tooltip>
  );
  return (
    <>
      <motion.div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }}
      >
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <div id="cx-wrapper" className="Add_Parking_Slot">
            <div className="main-master-wrapper">
              <div className="Heading">
                <p>{"Edit Parking Station"}</p>
              </div>
              <div className="innerInputsGen">
                <div className="row">
                  <div className="col-md-6 form_input_main">
                    <div className="innerSelectBox weekCounter">
                      <Form.Label className="common-labels">
                        {t("Station Code")}
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Enter a Station Code..."
                      />
                      <Form.Control.Feedback type="invalid">
                        Enter a Station Code
                      </Form.Control.Feedback>
                    </div>
                  </div>
                  <div className="col-md-6 form_input_main">
                    <div className="innerSelectBox weekCounter">
                      <Form.Label className="common-labels">
                        {t("Station Name")}
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Enter Station Name..."
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Station Name
                      </Form.Control.Feedback>
                    </div>
                  </div>
                  <div className="col-md-6 form_input_main">
                    <div className="innerSelectBox weekCounter">
                      <Form.Label className="common-labels">
                        {t("Address")}
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Enter Station Address."
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Station Address
                      </Form.Control.Feedback>
                    </div>
                  </div>
                  <div className="col-md-6 form_input_main">
                    <div className="innerSelectBox weekCounter">
                      <Form.Label className="common-labels">
                        {t("Parking Capacity")}
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Enter Parking Capacity."
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Parking Capacity
                      </Form.Control.Feedback>
                    </div>
                  </div>
                  <div className="col-md-6 form_input_main">
                    <div className="innerSelectBox weekCounter">
                      <Form.Label className="common-labels">
                        {t("Vehicles")}
                      </Form.Label>
                      <Form.Select
                        required
                        as="select"
                        type="select"
                        name="Speed_limit"
                      >
                        <option value="">Select Vehicles to assign...</option>
                        <option value="50">Vehicles 1</option>
                        <option value="100">Vehicles 2</option>
                        <option value="150">Vehicles 3</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Please Select Status.
                      </Form.Control.Feedback>
                    </div>
                  </div>
                  <div className="col-md-12 weekCounter mb-4">
                    <label className="common-labels">{t("Map")}</label>
                    <div className="addParkingMap">
                      <MapComponent />
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-end align-items-center btn-wrapper">
                  <button className="cx-btn-1">{t("Cancel")}</button>
                  <button className="cx-btn-2">{t("Submit")}</button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </motion.div>
    </>
  );
};

export default EditParkingSlot;
