import { React, useContext, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import DDlogo from "../../../assets/images/smallDD.svg";
import Calendar from "../../../assets/images/calendar.svg";
import DatePicker from "react-datepicker";
import { Link, useNavigate } from "react-router-dom";
import Delete from "../../../assets/images/delete.svg";
import View from "../../../assets/images/Group.svg";
import EditIc from "../../../assets/images/ic-edit.svg";
import { Col, Modal, Nav, OverlayTrigger, Tooltip } from "react-bootstrap";
import SideIc from "../../../assets/images/sideBar.svg";
import export_icon from "../../../assets/images/export_icon.svg";
import Form from "react-bootstrap/Form";
import { Dropdown, Tab, Tabs } from "react-bootstrap";
import { motion } from "framer-motion";
import Pagenation from "../../../sharedComponent/Pagenation";
import { useTranslation } from "react-i18next";
import PreviousButton from "../../../sharedComponent/PreviousButton";
import NextButton from "../../../sharedComponent/Nextbutton";
import ExistButton from "../../../sharedComponent/ExistButton";
import InfoComponent from "../../../sharedComponent/SupportHelp/InfoComponent";

const VehicleMaintenance = ({ onClick }) => {
  const { sidebar, setSidebar, Dark, setDark, slider, setSlider } =
    useContext(AppContext);
  const [startDate, setStartDate] = useState(new Date());
  const [deleteModal, setDeleteModal] = useState(false);
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    navigate("/AddVehicleMaintenance");
  };
  const handleClickPrevious = () => {
    navigate("/AddVehicleFine");
  };
  const renderTooltipForEdit = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Edit
    </Tooltip>
  );
  const renderTooltipForView = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      View
    </Tooltip>
  );
  const renderTooltipForDelete = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Delete
    </Tooltip>
  );
  return (
    <>
      <motion.div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }}
      >
        <div id="cx-wrapper" className="Fleet_Maintenance">
          {/* Top inputs for instatnt search */}
          <div className="displayFlexInp">
            <div className="innerSelectBox weekCounter selectForm form_input_main">
              <Form.Select
                required
                as="select"
                type="select"
                name="Speed_limit"
                className="innerCust"
              >
                <option value="">Vehicle Name</option>
                <option value="50">Safari</option>
                <option value="100">BMW</option>
                <option value="150">Maruti</option>
              </Form.Select>
            </div>
            <div className="innerSelectBox weekCounter selectForm form_input_main datepicker-main">
              <DatePicker
                selected={startDate}
                placeholderText="Due Date"
                onChange={(date) => setStartDate(date)}
                className="form-control innerCust"
              />
              <img src={Calendar} className="calendarLogo" alt="" />
            </div>
            <div className="innerSelectBox weekCounter selectForm form_input_main">
              <Form.Control
                required
                type="text"
                placeholder="Kilometers"
                className="innerCust"
              />
            </div>
            <div className="innerSelectBox weekCounter selectForm form_input_main datepicker-main">
              <DatePicker
                selected={startDate}
                // placeholderText="End Date"
                onChange={(date) => setStartDate(date)}
                className="form-control innerCust"
              />
              <img src={Calendar} className="calendarLogo" alt="" />
            </div>
            <div className="innerSelectBox weekCounter selectForm form_input_main">
              <Link to="/AddVehicleMaintenance" className="AddAccidentLink">
                <button className="innerCust">+ {t("Add New")}</button>
              </Link>
            </div>
            <div className="headerDivIc form_input_main">
              <Link to="#">
                <img src={SideIc} alt="" />
              </Link>
            </div>
            {/* <div className="headerDivIc form_input_main">
              <Link to="#">
                <img src={export_icon} alt="" />
              </Link>
            </div> */}
            <div style={{ marginRight: "10px" }} />
            <div className="right-export-btn-section-wrapper">
              <div className="c-pointer me-2"></div>

              <div className="md_dropdown">
                <Dropdown>
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    className="export-toggle"
                  >
                    <p className="Export_Text">{t("Export")}</p>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <Link
                        // onClick={() => getExportChat()}
                        className="d-block"
                      >
                        {t("PDF")}
                      </Link>
                    </Dropdown.Item>

                    <Dropdown.Item>
                      <Link
                        // onClick={() => downLoadExcelSheet()}
                        className="d-block"
                      >
                        {t("Excel")}
                      </Link>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>

          {/* Vehicle table */}
          <div className="Vehcle-main-tabs" id="Vehcle-main-tabs">
            <div className="main-master-wrapper mb-0 inner-tabs-section">
              <div id="scroll_insideThe_Padding_tabel">
                <div className="VA_table">
                  <Tab.Container
                    id="left-tabs-example"
                    className="td-tab-wrapper"
                    defaultActiveKey="0"
                  >
                    <Nav
                      variant="pills"
                      className="td-nav"
                      id="InnerTabNew_Foure"
                    >
                      <Nav.Item className="td-tab">
                        <Nav.Link className="td-link" eventKey="0">
                          All
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="td-tab">
                        <Nav.Link className="td-link" eventKey="1">
                          Dues
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="td-tab">
                        <Nav.Link className="td-link" eventKey="2">
                          Upcoming
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="td-tab">
                        <Nav.Link className="td-link" eventKey="3">
                          History
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Col sm={12} className="">
                      <Tab.Content>
                        <Tab.Pane eventKey="0">
                          <div className="">
                            <table className="table tableAdmin">
                              <thead className="tableHead">
                                <tr>
                                  <th>{t("Sr.no")}</th>
                                  <th>{t("Vehicle Name")}</th>
                                  <th>{t("Service Date")}</th>
                                  <th>{t("Kilometers Driven")}</th>
                                  <th>{t("Due Date")}</th>
                                  <th>{t("Service Cost")}</th>
                                  <th>{t("Action")}</th>
                                </tr>
                              </thead>
                              <tbody className="tableBody">
                                <tr>
                                  <td>1</td>
                                  <td>Toyota Innova</td>
                                  <td>01-01-2023</td>
                                  <td>500</td>
                                  <td>01-02-2023</td>
                                  <td>3000 /-</td>
                                  <td>
                                    <div className="innerFlex d-flex">
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltipForView}
                                      >
                                        <Link to="/ViewVehicleMaintenance">
                                          <div className="inconsIn me-3">
                                            <img src={View} alt="" />
                                          </div>
                                        </Link>
                                      </OverlayTrigger>
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltipForEdit}
                                      >
                                        <Link to="/EditVehicleMaintenance">
                                          <div className="inconsIn me-3">
                                            <img src={EditIc} alt="" />
                                          </div>
                                        </Link>
                                      </OverlayTrigger>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>1</td>
                                  <td>BMW</td>
                                  <td>01-01-2023</td>
                                  <td>500</td>
                                  <td>01-02-2023</td>
                                  <td>3000 /-</td>
                                  <td>
                                    <div className="innerFlex d-flex">
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltipForView}
                                      >
                                        <Link to="/ViewVehicleMaintenance">
                                          <div className="inconsIn me-3">
                                            <img src={View} alt="" />
                                          </div>
                                        </Link>
                                      </OverlayTrigger>
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltipForEdit}
                                      >
                                        <Link to="/EditVehicleMaintenance">
                                          <div className="inconsIn me-3">
                                            <img src={EditIc} alt="" />
                                          </div>
                                        </Link>
                                      </OverlayTrigger>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>1</td>
                                  <td>Maruti</td>
                                  <td>01-01-2023</td>
                                  <td>500</td>
                                  <td>01-02-2023</td>
                                  <td>3000 /-</td>
                                  <td>
                                    <div className="innerFlex d-flex">
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltipForView}
                                      >
                                        <Link to="/ViewVehicleMaintenance">
                                          <div className="inconsIn me-3">
                                            <img src={View} alt="" />
                                          </div>
                                        </Link>
                                      </OverlayTrigger>
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltipForEdit}
                                      >
                                        <Link to="/EditVehicleMaintenance">
                                          <div className="inconsIn me-3">
                                            <img src={EditIc} alt="" />
                                          </div>
                                        </Link>
                                      </OverlayTrigger>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>1</td>
                                  <td>Safari</td>
                                  <td>01-01-2023</td>
                                  <td>500</td>
                                  <td>01-02-2023</td>
                                  <td>3000 /-</td>
                                  <td>
                                    <div className="innerFlex d-flex">
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltipForView}
                                      >
                                        <Link to="/ViewVehicleMaintenance">
                                          <div className="inconsIn me-3">
                                            <img src={View} alt="" />
                                          </div>
                                        </Link>
                                      </OverlayTrigger>
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltipForEdit}
                                      >
                                        <Link to="/EditVehicleMaintenance">
                                          <div className="inconsIn me-3">
                                            <img src={EditIc} alt="" />
                                          </div>
                                        </Link>
                                      </OverlayTrigger>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>1</td>
                                  <td>Toyota Innova</td>
                                  <td>01-01-2023</td>
                                  <td>500</td>
                                  <td>01-02-2023</td>
                                  <td>3000 /-</td>
                                  <td>
                                    <div className="innerFlex d-flex">
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltipForView}
                                      >
                                        <Link to="/ViewVehicleMaintenance">
                                          <div className="inconsIn me-3">
                                            <img src={View} alt="" />
                                          </div>
                                        </Link>
                                      </OverlayTrigger>
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltipForEdit}
                                      >
                                        <Link to="/EditVehicleMaintenance">
                                          <div className="inconsIn me-3">
                                            <img src={EditIc} alt="" />
                                          </div>
                                        </Link>
                                      </OverlayTrigger>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>1</td>
                                  <td>BMW</td>
                                  <td>01-01-2023</td>
                                  <td>500</td>
                                  <td>01-02-2023</td>
                                  <td>3000 /-</td>
                                  <td>
                                    <div className="innerFlex d-flex">
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltipForView}
                                      >
                                        <Link to="/ViewVehicleMaintenance">
                                          <div className="inconsIn me-3">
                                            <img src={View} alt="" />
                                          </div>
                                        </Link>
                                      </OverlayTrigger>
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltipForEdit}
                                      >
                                        <Link to="/EditVehicleMaintenance">
                                          <div className="inconsIn me-3">
                                            <img src={EditIc} alt="" />
                                          </div>
                                        </Link>
                                      </OverlayTrigger>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>1</td>
                                  <td>Maruti</td>
                                  <td>01-01-2023</td>
                                  <td>500</td>
                                  <td>01-02-2023</td>
                                  <td>3000 /-</td>
                                  <td>
                                    <div className="innerFlex d-flex">
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltipForView}
                                      >
                                        <Link to="/ViewVehicleMaintenance">
                                          <div className="inconsIn me-3">
                                            <img src={View} alt="" />
                                          </div>
                                        </Link>
                                      </OverlayTrigger>
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltipForEdit}
                                      >
                                        <Link to="/EditVehicleMaintenance">
                                          <div className="inconsIn me-3">
                                            <img src={EditIc} alt="" />
                                          </div>
                                        </Link>
                                      </OverlayTrigger>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>1</td>
                                  <td>Safari</td>
                                  <td>01-01-2023</td>
                                  <td>500</td>
                                  <td>01-02-2023</td>
                                  <td>3000 /-</td>
                                  <td>
                                    <div className="innerFlex d-flex">
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltipForView}
                                      >
                                        <Link to="/ViewVehicleMaintenance">
                                          <div className="inconsIn me-3">
                                            <img src={View} alt="" />
                                          </div>
                                        </Link>
                                      </OverlayTrigger>
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltipForEdit}
                                      >
                                        <Link to="/EditVehicleMaintenance">
                                          <div className="inconsIn me-3">
                                            <img src={EditIc} alt="" />
                                          </div>
                                        </Link>
                                      </OverlayTrigger>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="1">
                          <div className="">
                            <table className="table tableAdmin">
                              <thead className="tableHead">
                                <tr>
                                  <th>{t("Sr.no")}</th>
                                  <th>{t("Vehicle Name")}</th>
                                  <th>{t("Service Date")}</th>
                                  <th>{t("Kilometers Driven")}</th>
                                  <th>{t("Due Date")}</th>
                                  <th>{t("Service Cost")}</th>
                                  <th>{t("Action")}</th>
                                </tr>
                              </thead>
                              <tbody className="tableBody">
                                <tr>
                                  <td>1</td>
                                  <td>Toyota Innova</td>
                                  <td>01-01-2023</td>
                                  <td>500</td>
                                  <td>01-02-2023</td>
                                  <td>3000 /-</td>
                                  <td>
                                    <div className="innerFlex d-flex">
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltipForView}
                                      >
                                        <Link to="/ViewVehicleMaintenance">
                                          <div className="inconsIn me-3">
                                            <img src={View} alt="" />
                                          </div>
                                        </Link>
                                      </OverlayTrigger>
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltipForEdit}
                                      >
                                        <Link to="/EditVehicleMaintenance">
                                          <div className="inconsIn me-3">
                                            <img src={EditIc} alt="" />
                                          </div>
                                        </Link>
                                      </OverlayTrigger>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>1</td>
                                  <td>BMW</td>
                                  <td>01-01-2023</td>
                                  <td>500</td>
                                  <td>01-02-2023</td>
                                  <td>3000 /-</td>
                                  <td>
                                    <div className="innerFlex d-flex">
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltipForView}
                                      >
                                        <Link to="/ViewVehicleMaintenance">
                                          <div className="inconsIn me-3">
                                            <img src={View} alt="" />
                                          </div>
                                        </Link>
                                      </OverlayTrigger>
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltipForEdit}
                                      >
                                        <Link to="/EditVehicleMaintenance">
                                          <div className="inconsIn me-3">
                                            <img src={EditIc} alt="" />
                                          </div>
                                        </Link>
                                      </OverlayTrigger>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>1</td>
                                  <td>Maruti</td>
                                  <td>01-01-2023</td>
                                  <td>500</td>
                                  <td>01-02-2023</td>
                                  <td>3000 /-</td>
                                  <td>
                                    <div className="innerFlex d-flex">
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltipForView}
                                      >
                                        <Link to="/ViewVehicleMaintenance">
                                          <div className="inconsIn me-3">
                                            <img src={View} alt="" />
                                          </div>
                                        </Link>
                                      </OverlayTrigger>
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltipForEdit}
                                      >
                                        <Link to="/EditVehicleMaintenance">
                                          <div className="inconsIn me-3">
                                            <img src={EditIc} alt="" />
                                          </div>
                                        </Link>
                                      </OverlayTrigger>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>1</td>
                                  <td>Safari</td>
                                  <td>01-01-2023</td>
                                  <td>500</td>
                                  <td>01-02-2023</td>
                                  <td>3000 /-</td>
                                  <td>
                                    <div className="innerFlex d-flex">
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltipForView}
                                      >
                                        <Link to="/ViewVehicleMaintenance">
                                          <div className="inconsIn me-3">
                                            <img src={View} alt="" />
                                          </div>
                                        </Link>
                                      </OverlayTrigger>
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltipForEdit}
                                      >
                                        <Link to="/EditVehicleMaintenance">
                                          <div className="inconsIn me-3">
                                            <img src={EditIc} alt="" />
                                          </div>
                                        </Link>
                                      </OverlayTrigger>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>1</td>
                                  <td>Toyota Innova</td>
                                  <td>01-01-2023</td>
                                  <td>500</td>
                                  <td>01-02-2023</td>
                                  <td>3000 /-</td>
                                  <td>
                                    <div className="innerFlex d-flex">
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltipForView}
                                      >
                                        <Link to="/ViewVehicleMaintenance">
                                          <div className="inconsIn me-3">
                                            <img src={View} alt="" />
                                          </div>
                                        </Link>
                                      </OverlayTrigger>
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltipForEdit}
                                      >
                                        <Link to="/EditVehicleMaintenance">
                                          <div className="inconsIn me-3">
                                            <img src={EditIc} alt="" />
                                          </div>
                                        </Link>
                                      </OverlayTrigger>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>1</td>
                                  <td>BMW</td>
                                  <td>01-01-2023</td>
                                  <td>500</td>
                                  <td>01-02-2023</td>
                                  <td>3000 /-</td>
                                  <td>
                                    <div className="innerFlex d-flex">
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltipForView}
                                      >
                                        <Link to="/ViewVehicleMaintenance">
                                          <div className="inconsIn me-3">
                                            <img src={View} alt="" />
                                          </div>
                                        </Link>
                                      </OverlayTrigger>
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltipForEdit}
                                      >
                                        <Link to="/EditVehicleMaintenance">
                                          <div className="inconsIn me-3">
                                            <img src={EditIc} alt="" />
                                          </div>
                                        </Link>
                                      </OverlayTrigger>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>1</td>
                                  <td>Maruti</td>
                                  <td>01-01-2023</td>
                                  <td>500</td>
                                  <td>01-02-2023</td>
                                  <td>3000 /-</td>
                                  <td>
                                    <div className="innerFlex d-flex">
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltipForView}
                                      >
                                        <Link to="/ViewVehicleMaintenance">
                                          <div className="inconsIn me-3">
                                            <img src={View} alt="" />
                                          </div>
                                        </Link>
                                      </OverlayTrigger>
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltipForEdit}
                                      >
                                        <Link to="/EditVehicleMaintenance">
                                          <div className="inconsIn me-3">
                                            <img src={EditIc} alt="" />
                                          </div>
                                        </Link>
                                      </OverlayTrigger>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>1</td>
                                  <td>Safari</td>
                                  <td>01-01-2023</td>
                                  <td>500</td>
                                  <td>01-02-2023</td>
                                  <td>3000 /-</td>
                                  <td>
                                    <div className="innerFlex d-flex">
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltipForView}
                                      >
                                        <Link to="/ViewVehicleMaintenance">
                                          <div className="inconsIn me-3">
                                            <img src={View} alt="" />
                                          </div>
                                        </Link>
                                      </OverlayTrigger>
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltipForEdit}
                                      >
                                        <Link to="/EditVehicleMaintenance">
                                          <div className="inconsIn me-3">
                                            <img src={EditIc} alt="" />
                                          </div>
                                        </Link>
                                      </OverlayTrigger>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="2">
                          <div className="">
                            <table className="table tableAdmin">
                              <thead className="tableHead">
                                <tr>
                                  <th>{t("Sr.no")}</th>
                                  <th>{t("Vehicle Name")}</th>
                                  <th>{t("Service Date")}</th>
                                  <th>{t("Kilometers Driven")}</th>
                                  <th>{t("Due Date")}</th>
                                  <th>{t("Service Cost")}</th>
                                  <th>{t("Action")}</th>
                                </tr>
                              </thead>
                              <tbody className="tableBody">
                                <tr>
                                  <td>1</td>
                                  <td>Toyota Innova</td>
                                  <td>01-01-2023</td>
                                  <td>500</td>
                                  <td>01-02-2023</td>
                                  <td>3000 /-</td>
                                  <td>
                                    <div className="innerFlex d-flex">
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltipForView}
                                      >
                                        <Link to="/ViewVehicleMaintenance">
                                          <div className="inconsIn me-3">
                                            <img src={View} alt="" />
                                          </div>
                                        </Link>
                                      </OverlayTrigger>
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltipForEdit}
                                      >
                                        <Link to="/EditVehicleMaintenance">
                                          <div className="inconsIn me-3">
                                            <img src={EditIc} alt="" />
                                          </div>
                                        </Link>
                                      </OverlayTrigger>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>1</td>
                                  <td>BMW</td>
                                  <td>01-01-2023</td>
                                  <td>500</td>
                                  <td>01-02-2023</td>
                                  <td>3000 /-</td>
                                  <td>
                                    <div className="innerFlex d-flex">
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltipForView}
                                      >
                                        <Link to="/ViewVehicleMaintenance">
                                          <div className="inconsIn me-3">
                                            <img src={View} alt="" />
                                          </div>
                                        </Link>
                                      </OverlayTrigger>
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltipForEdit}
                                      >
                                        <Link to="/EditVehicleMaintenance">
                                          <div className="inconsIn me-3">
                                            <img src={EditIc} alt="" />
                                          </div>
                                        </Link>
                                      </OverlayTrigger>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>1</td>
                                  <td>Maruti</td>
                                  <td>01-01-2023</td>
                                  <td>500</td>
                                  <td>01-02-2023</td>
                                  <td>3000 /-</td>
                                  <td>
                                    <div className="innerFlex d-flex">
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltipForView}
                                      >
                                        <Link to="/ViewVehicleMaintenance">
                                          <div className="inconsIn me-3">
                                            <img src={View} alt="" />
                                          </div>
                                        </Link>
                                      </OverlayTrigger>
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltipForEdit}
                                      >
                                        <Link to="/EditVehicleMaintenance">
                                          <div className="inconsIn me-3">
                                            <img src={EditIc} alt="" />
                                          </div>
                                        </Link>
                                      </OverlayTrigger>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>1</td>
                                  <td>Safari</td>
                                  <td>01-01-2023</td>
                                  <td>500</td>
                                  <td>01-02-2023</td>
                                  <td>3000 /-</td>
                                  <td>
                                    <div className="innerFlex d-flex">
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltipForView}
                                      >
                                        <Link to="/ViewVehicleMaintenance">
                                          <div className="inconsIn me-3">
                                            <img src={View} alt="" />
                                          </div>
                                        </Link>
                                      </OverlayTrigger>
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltipForEdit}
                                      >
                                        <Link to="/EditVehicleMaintenance">
                                          <div className="inconsIn me-3">
                                            <img src={EditIc} alt="" />
                                          </div>
                                        </Link>
                                      </OverlayTrigger>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>1</td>
                                  <td>Toyota Innova</td>
                                  <td>01-01-2023</td>
                                  <td>500</td>
                                  <td>01-02-2023</td>
                                  <td>3000 /-</td>
                                  <td>
                                    <div className="innerFlex d-flex">
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltipForView}
                                      >
                                        <Link to="/ViewVehicleMaintenance">
                                          <div className="inconsIn me-3">
                                            <img src={View} alt="" />
                                          </div>
                                        </Link>
                                      </OverlayTrigger>
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltipForEdit}
                                      >
                                        <Link to="/EditVehicleMaintenance">
                                          <div className="inconsIn me-3">
                                            <img src={EditIc} alt="" />
                                          </div>
                                        </Link>
                                      </OverlayTrigger>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>1</td>
                                  <td>BMW</td>
                                  <td>01-01-2023</td>
                                  <td>500</td>
                                  <td>01-02-2023</td>
                                  <td>3000 /-</td>
                                  <td>
                                    <div className="innerFlex d-flex">
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltipForView}
                                      >
                                        <Link to="/ViewVehicleMaintenance">
                                          <div className="inconsIn me-3">
                                            <img src={View} alt="" />
                                          </div>
                                        </Link>
                                      </OverlayTrigger>
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltipForEdit}
                                      >
                                        <Link to="/EditVehicleMaintenance">
                                          <div className="inconsIn me-3">
                                            <img src={EditIc} alt="" />
                                          </div>
                                        </Link>
                                      </OverlayTrigger>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>1</td>
                                  <td>Maruti</td>
                                  <td>01-01-2023</td>
                                  <td>500</td>
                                  <td>01-02-2023</td>
                                  <td>3000 /-</td>
                                  <td>
                                    <div className="innerFlex d-flex">
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltipForView}
                                      >
                                        <Link to="/ViewVehicleMaintenance">
                                          <div className="inconsIn me-3">
                                            <img src={View} alt="" />
                                          </div>
                                        </Link>
                                      </OverlayTrigger>
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltipForEdit}
                                      >
                                        <Link to="/EditVehicleMaintenance">
                                          <div className="inconsIn me-3">
                                            <img src={EditIc} alt="" />
                                          </div>
                                        </Link>
                                      </OverlayTrigger>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>1</td>
                                  <td>Safari</td>
                                  <td>01-01-2023</td>
                                  <td>500</td>
                                  <td>01-02-2023</td>
                                  <td>3000 /-</td>
                                  <td>
                                    <div className="innerFlex d-flex">
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltipForView}
                                      >
                                        <Link to="/ViewVehicleMaintenance">
                                          <div className="inconsIn me-3">
                                            <img src={View} alt="" />
                                          </div>
                                        </Link>
                                      </OverlayTrigger>
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltipForEdit}
                                      >
                                        <Link to="/EditVehicleMaintenance">
                                          <div className="inconsIn me-3">
                                            <img src={EditIc} alt="" />
                                          </div>
                                        </Link>
                                      </OverlayTrigger>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="3">
                          <div className="">
                            <table className="table tableAdmin">
                              <thead className="tableHead">
                                <tr>
                                  <th>{t("Sr.no")}</th>
                                  <th>{t("Vehicle Name")}</th>
                                  <th>{t("Service Date")}</th>
                                  <th>{t("Kilometers Driven")}</th>
                                  <th>{t("Due Date")}</th>
                                  <th>{t("Service Cost")}</th>
                                  <th>{t("Action")}</th>
                                </tr>
                              </thead>
                              <tbody className="tableBody">
                                <tr>
                                  <td>1</td>
                                  <td>Toyota Innova</td>
                                  <td>01-01-2023</td>
                                  <td>500</td>
                                  <td>01-02-2023</td>
                                  <td>3000 /-</td>
                                  <td>
                                    <div className="innerFlex d-flex">
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltipForView}
                                      >
                                        <Link to="/ViewVehicleMaintenance">
                                          <div className="inconsIn me-3">
                                            <img src={View} alt="" />
                                          </div>
                                        </Link>
                                      </OverlayTrigger>
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltipForEdit}
                                      >
                                        <Link to="/EditVehicleMaintenance">
                                          <div className="inconsIn me-3">
                                            <img src={EditIc} alt="" />
                                          </div>
                                        </Link>
                                      </OverlayTrigger>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>1</td>
                                  <td>BMW</td>
                                  <td>01-01-2023</td>
                                  <td>500</td>
                                  <td>01-02-2023</td>
                                  <td>3000 /-</td>
                                  <td>
                                    <div className="innerFlex d-flex">
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltipForView}
                                      >
                                        <Link to="/ViewVehicleMaintenance">
                                          <div className="inconsIn me-3">
                                            <img src={View} alt="" />
                                          </div>
                                        </Link>
                                      </OverlayTrigger>
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltipForEdit}
                                      >
                                        <Link to="/EditVehicleMaintenance">
                                          <div className="inconsIn me-3">
                                            <img src={EditIc} alt="" />
                                          </div>
                                        </Link>
                                      </OverlayTrigger>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>1</td>
                                  <td>Maruti</td>
                                  <td>01-01-2023</td>
                                  <td>500</td>
                                  <td>01-02-2023</td>
                                  <td>3000 /-</td>
                                  <td>
                                    <div className="innerFlex d-flex">
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltipForView}
                                      >
                                        <Link to="/ViewVehicleMaintenance">
                                          <div className="inconsIn me-3">
                                            <img src={View} alt="" />
                                          </div>
                                        </Link>
                                      </OverlayTrigger>
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltipForEdit}
                                      >
                                        <Link to="/EditVehicleMaintenance">
                                          <div className="inconsIn me-3">
                                            <img src={EditIc} alt="" />
                                          </div>
                                        </Link>
                                      </OverlayTrigger>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>1</td>
                                  <td>Safari</td>
                                  <td>01-01-2023</td>
                                  <td>500</td>
                                  <td>01-02-2023</td>
                                  <td>3000 /-</td>
                                  <td>
                                    <div className="innerFlex d-flex">
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltipForView}
                                      >
                                        <Link to="/ViewVehicleMaintenance">
                                          <div className="inconsIn me-3">
                                            <img src={View} alt="" />
                                          </div>
                                        </Link>
                                      </OverlayTrigger>
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltipForEdit}
                                      >
                                        <Link to="/EditVehicleMaintenance">
                                          <div className="inconsIn me-3">
                                            <img src={EditIc} alt="" />
                                          </div>
                                        </Link>
                                      </OverlayTrigger>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>1</td>
                                  <td>Toyota Innova</td>
                                  <td>01-01-2023</td>
                                  <td>500</td>
                                  <td>01-02-2023</td>
                                  <td>3000 /-</td>
                                  <td>
                                    <div className="innerFlex d-flex">
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltipForView}
                                      >
                                        <Link to="/ViewVehicleMaintenance">
                                          <div className="inconsIn me-3">
                                            <img src={View} alt="" />
                                          </div>
                                        </Link>
                                      </OverlayTrigger>
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltipForEdit}
                                      >
                                        <Link to="/EditVehicleMaintenance">
                                          <div className="inconsIn me-3">
                                            <img src={EditIc} alt="" />
                                          </div>
                                        </Link>
                                      </OverlayTrigger>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>1</td>
                                  <td>BMW</td>
                                  <td>01-01-2023</td>
                                  <td>500</td>
                                  <td>01-02-2023</td>
                                  <td>3000 /-</td>
                                  <td>
                                    <div className="innerFlex d-flex">
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltipForView}
                                      >
                                        <Link to="/ViewVehicleMaintenance">
                                          <div className="inconsIn me-3">
                                            <img src={View} alt="" />
                                          </div>
                                        </Link>
                                      </OverlayTrigger>
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltipForEdit}
                                      >
                                        <Link to="/EditVehicleMaintenance">
                                          <div className="inconsIn me-3">
                                            <img src={EditIc} alt="" />
                                          </div>
                                        </Link>
                                      </OverlayTrigger>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>1</td>
                                  <td>Maruti</td>
                                  <td>01-01-2023</td>
                                  <td>500</td>
                                  <td>01-02-2023</td>
                                  <td>3000 /-</td>
                                  <td>
                                    <div className="innerFlex d-flex">
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltipForView}
                                      >
                                        <Link to="/ViewVehicleMaintenance">
                                          <div className="inconsIn me-3">
                                            <img src={View} alt="" />
                                          </div>
                                        </Link>
                                      </OverlayTrigger>
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltipForEdit}
                                      >
                                        <Link to="/EditVehicleMaintenance">
                                          <div className="inconsIn me-3">
                                            <img src={EditIc} alt="" />
                                          </div>
                                        </Link>
                                      </OverlayTrigger>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>1</td>
                                  <td>Safari</td>
                                  <td>01-01-2023</td>
                                  <td>500</td>
                                  <td>01-02-2023</td>
                                  <td>3000 /-</td>
                                  <td>
                                    <div className="innerFlex d-flex">
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltipForView}
                                      >
                                        <Link to="/ViewVehicleMaintenance">
                                          <div className="inconsIn me-3">
                                            <img src={View} alt="" />
                                          </div>
                                        </Link>
                                      </OverlayTrigger>
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltipForEdit}
                                      >
                                        <Link to="/EditVehicleMaintenance">
                                          <div className="inconsIn me-3">
                                            <img src={EditIc} alt="" />
                                          </div>
                                        </Link>
                                      </OverlayTrigger>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Tab.Container>
                </div>
              </div>
              <Pagenation />
            </div>
          </div>
          {slider ? <ExistButton onClose={() => setSlider(false)} /> : null}
          {!slider && <PreviousButton onBack={handleClickPrevious} />}
          {!slider && <NextButton onClick={handleClick} />}
          {!slider && <InfoComponent />}
        </div>
      </motion.div>

      {/* Delete Modal Start */}
      <Modal
        show={deleteModal}
        onHide={() => setDeleteModal(false)}
        centered
        className="common-model"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Delete Fleet Maintenance")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("Are you sure you want to Delete this Fleet Maintenance")} ?
        </Modal.Body>
        <Modal.Footer className="pop-up-modal-footer">
          <div class="btn-wrapper">
            <button className="cx-btn-1" onClick={() => setDeleteModal(false)}>
              {t("Cancel")}
            </button>
            <button className="cx-btn-2" onClick={() => setDeleteModal(false)}>
              {t("Yes")}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* Delete Modal End */}
    </>
  );
};

export default VehicleMaintenance;
