import { React, useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { Link } from "react-router-dom";
import Logout from "../../assets/images/import_icon.svg";
import Share from "../../assets/images/XMLID_1022_.svg";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Nav from "react-bootstrap/Nav";
import option from "../../assets/images/option-three-dot.svg";
import { Dropdown, Modal } from "react-bootstrap";
import { motion } from "framer-motion";
import MapComponent from "../../sharedComponent/MapComponent";
import { useTranslation } from "react-i18next";

const PointOfIntrest = () => {
  const { sidebar, setSidebar, Dark, setDark } = useContext(AppContext);
  const [tabClicked, setTabClicked] = useState(false);
  const [navClicked, setNavClicked] = useState(false);
  const { t, i18n } = useTranslation();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  return (
    <>
      <motion.div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }}
      >
        <div id="cx-wrapper" className="Point_Of_Intrest">
          <div className="upperMainSet">
            <div className="row">
              <div className="col-md-8 form_input_main">
                <div className="row PMINg">
                  <div className="col-md-4 col-sm-12">
                    <div className="weekCounter">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Location Name"
                      />
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <div className="weekCounter">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Latitude, Longitude"
                      />
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <div className="weekCounter">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Address"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mainCol4">
                <div className="leftSideSec">
                  <Link to="/AddPointOfInterest" className="addParkBtn">
                    <button>+ {t("Add Location")}</button>
                  </Link>
                  <Link to="#">
                    <div className="inconMain">
                      <img src={Logout} alt="" />
                    </div>
                  </Link>
                  <Link to="#">
                    <div className="inconMain m-0">
                      <img src={Share} alt="" />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="row body-content">
              <div className="col-md-8">
                <div className="mainMape">
                  <MapComponent />
                  {tabClicked ? (
                    <div
                      className="innerMapDetails"
                      id="model-responsive-on-map"
                    >
                      <div className="headingDetails">
                        <div className="headingTxt">
                          <p className="heading">
                            {t("Point Of Interest Details")}
                          </p>
                        </div>
                        <div className="customer-option">
                          <Dropdown>
                            <Dropdown.Toggle id="dropdown-basic">
                              <img src={option} alt="" />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item>
                                <Link
                                  to="/EditPointOfInterest"
                                  className="d-block"
                                >
                                  {t("Edit")}
                                </Link>
                              </Dropdown.Item>
                              <Dropdown.Item>
                                <Link
                                  to="#"
                                  onClick={() => setShow(true)}
                                  className="d-block"
                                >
                                  {t("Delete")}
                                </Link>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                      <div className="innerCOntent">
                        <div className="row">
                          <div className="col-md-4 dividedCol form_input_main">
                            <p className="paraHead">{t("Location Name")}</p>
                            <p className="paraVal">Mr. John Doe</p>
                          </div>
                          <div className="col-md-4 dividedCol form_input_main">
                            <p className="paraHead">{t("Latitude")}</p>
                            <p className="paraVal">19.000407273160583</p>
                          </div>
                          <div className="col-md-4 dividedCol form_input_main">
                            <p className="paraHead">{t("Longitude")}</p>
                            <p className="paraVal">74.19544560668241</p>
                          </div>
                          <div className="col-md-12 dividedCol form_input_main">
                            <p className="paraHead">{t("Address")}</p>
                            <p className="paraVal">
                              SGRA 82, Shanti Gardens Third St, Thrimoorthy
                              Nagar, Kamaleshwaram, Thiruvananthapuram, Kerala
                              695009, India
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-md-4">
                <div className="tabsCon">
                  <Nav variant="pills" id="navPills">
                    <Nav.Item onClick={() => setTabClicked(true)}>
                      <Nav.Link eventKey="first">
                        <div className="AllFlexNav">
                          <div className="row">
                            <div className="col-md-6">
                              <p className="paraNav">{t("Location Name")}</p>
                              <p className="paraNavTxt">Pune</p>
                            </div>
                            <div className="col-md-6">
                              <p className="paraNav">{t("Address")}</p>
                              <p className="paraNavTxt">Test1234</p>
                            </div>
                            <div className="col-md-6">
                              <p className="paraNav">{t("Latitude")}</p>
                              <p className="paraNavTxt">18.5726054</p>
                            </div>
                            <div className="col-md-6">
                              <p className="paraNav">{t("Longitude")}</p>
                              <p className="paraNavTxt">73.8782079</p>
                            </div>
                          </div>
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item onClick={() => setTabClicked(true)}>
                      <Nav.Link eventKey="second">
                        <div className="AllFlexNav">
                          <div className="row">
                            <div className="col-md-6">
                              <p className="paraNav">{t("Location Name")}</p>
                              <p className="paraNavTxt">Pune</p>
                            </div>
                            <div className="col-md-6">
                              <p className="paraNav">{t("Address")}</p>
                              <p className="paraNavTxt">Test1234</p>
                            </div>
                            <div className="col-md-6">
                              <p className="paraNav">{t("Latitude")}</p>
                              <p className="paraNavTxt">18.5726054</p>
                            </div>
                            <div className="col-md-6">
                              <p className="paraNav">{t("Longitude")}</p>
                              <p className="paraNavTxt">73.8782079</p>
                            </div>
                          </div>
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item onClick={() => setTabClicked(true)}>
                      <Nav.Link eventKey="third">
                        <div className="AllFlexNav">
                          <div className="row">
                            <div className="col-md-6">
                              <p className="paraNav">{t("Location Name")}</p>
                              <p className="paraNavTxt">Pune</p>
                            </div>
                            <div className="col-md-6">
                              <p className="paraNav">{t("Address")}</p>
                              <p className="paraNavTxt">Test1234</p>
                            </div>
                            <div className="col-md-6">
                              <p className="paraNav">{t("Latitude")}</p>
                              <p className="paraNavTxt">18.5726054</p>
                            </div>
                            <div className="col-md-6">
                              <p className="paraNav">{t("Longitude")}</p>
                              <p className="paraNavTxt">73.8782079</p>
                            </div>
                          </div>
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item onClick={() => setTabClicked(true)}>
                      <Nav.Link eventKey="four">
                        <div className="AllFlexNav">
                          <div className="row">
                            <div className="col-md-6">
                              <p className="paraNav">{t("Location Name")}</p>
                              <p className="paraNavTxt">Pune</p>
                            </div>
                            <div className="col-md-6">
                              <p className="paraNav">{t("Address")}</p>
                              <p className="paraNavTxt">Test1234</p>
                            </div>
                            <div className="col-md-6">
                              <p className="paraNav">{t("Latitude")}</p>
                              <p className="paraNavTxt">18.5726054</p>
                            </div>
                            <div className="col-md-6">
                              <p className="paraNav">{t("Longitude")}</p>
                              <p className="paraNavTxt">73.8782079</p>
                            </div>
                          </div>
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item onClick={() => setTabClicked(true)}>
                      <Nav.Link eventKey="five">
                        <div className="AllFlexNav">
                          <div className="row">
                            <div className="col-md-6">
                              <p className="paraNav">{t("Location Name")}</p>
                              <p className="paraNavTxt">Pune</p>
                            </div>
                            <div className="col-md-6">
                              <p className="paraNav">{t("Address")}</p>
                              <p className="paraNavTxt">Test1234</p>
                            </div>
                            <div className="col-md-6">
                              <p className="paraNav">{t("Latitude")}</p>
                              <p className="paraNavTxt">18.5726054</p>
                            </div>
                            <div className="col-md-6">
                              <p className="paraNav">{t("Longitude")}</p>
                              <p className="paraNavTxt">73.8782079</p>
                            </div>
                          </div>
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* Delete Modal Start */}
      <Modal show={show} onHide={handleClose} centered className="common-model">
        <Modal.Header closeButton>
          <Modal.Title>{t("Delete Location")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("Are you sure you want to delete this Location")} ?
        </Modal.Body>
        <Modal.Footer className="pop-up-modal-footer">
          <div class="btn-wrapper">
            <button className="cx-btn-1" onClick={handleClose}>
              {t("Cancel")}
            </button>
            <button className="cx-btn-2" onClick={handleClose}>
              {t("Yes")}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* Delete Modal End */}
    </>
  );
};

export default PointOfIntrest;
