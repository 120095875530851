import React, { useContext } from "react";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { motion } from "framer-motion";
import PreviousButton from "../../../sharedComponent/PreviousButton";
import NextButton from "../../../sharedComponent/Nextbutton";
import ExistButton from "../../../sharedComponent/ExistButton";
const ScheduleReport = ({ onClick }) => {
  const { sidebar, setSidebar, Dark, setDark, slider, setSlider } =
    useContext(AppContext);
  const navigate = useNavigate();
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    navigate("/GenerateCustomReport");
  };
  const handleClickPrevious = () => {
    navigate("/Reports");
  };

  return (
    <motion.div
      className={sidebar ? "taskMain " : "cx-active taskMain"}
      id="cx-main"
      variants={aninations}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.1 }}
    >
      <div id="cx-wrapper">
        <div className="main-master-wrapper" id="ScheduleReoprt_Responsive">
          <div className="Heading">
            <p>Generate Schedule Report</p>
          </div>
          <div className="row ScheduleReoprt_From">
            <div className="col-md-6 form_input_main">
              <div className="innerSelectBox weekCounter">
                <Form.Label className="common-labels custom-label-title me-0">
                  <p>Parent Area</p>
                </Form.Label>
                <select
                  required
                  className="form-select"
                  aria-label="Default select example"
                  placeholder="Select Fuel Type..."
                >
                  <option value="">Select Area...</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </div>
            </div>
            <div className="col-md-6 form_input_main">
              <div className="innerSelectBox weekCounter">
                <Form.Label className="common-labels">
                  {" "}
                  Geofence Areas<span>&#42;</span>{" "}
                </Form.Label>
                <Form.Control required type="text" placeholder="Enter Area.." />
              </div>
            </div>
            <div className="col-md-6 form_input_main">
              <div className="innerSelectBox weekCounter">
                <Form.Label className="common-labels">
                  {" "}
                  Address <span>&#42;</span>{" "}
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Address..."
                />
              </div>
            </div>
            <div className="col-md-6 form_input_main">
              <div className="innerSelectBox weekCounter">
                <Form.Label className="common-labels"> Landmark</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Landmark..."
                />
              </div>
            </div>
            <div className="col-md-6 form_input_main">
              <div className="innerSelectBox weekCounter">
                <Form.Label className="common-labels">
                  {" "}
                  City / State <span>&#42;</span>{" "}
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter City / State..."
                />
              </div>
            </div>
            <div className="col-md-6 form_input_main">
              <div className="innerSelectBox weekCounter">
                <Form.Label className="common-labels"> Country</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Country..."
                />
              </div>
            </div>
            <div className="col-md-6 form_input_main">
              <div className="innerSelectBox weekCounter">
                <Form.Label className="common-labels">
                  {" "}
                  Repeat Reports Type<span>&#42;</span>{" "}
                </Form.Label>
                <div className="custom-radio-btn">
                  <label htmlFor="Day">Day</label>
                  <input
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="Day"
                  />
                  <label htmlFor="Week">Week</label>
                  <input
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="Week"
                  />
                  <label htmlFor="Month">Month</label>
                  <input
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="Month"
                  />
                </div>
              </div>
            </div>
            <div className="Heading">
              <p>Recipients </p>
            </div>
            <div className="row">
              <div className="col-md-12 form_input_main">
                <div className="innerSelectBox weekCounter">
                  <Form.Label className="common-labels"> Users</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Select Users..."
                  />
                </div>
              </div>
            </div>
            <div className="btns-main">
              <Link to="/Reports">
                <button className="cx-btn-1">Cencel</button>
              </Link>
              <Link to="/Reports">
                <button className="cx-btn-2">Submit</button>
              </Link>
            </div>
          </div>
        </div>

        {slider ? <ExistButton onClose={() => setSlider(false)} /> : null}
        {!slider && <PreviousButton onBack={handleClickPrevious} />}
        {!slider && <NextButton onClick={handleClick} />}
      </div>
    </motion.div>
  );
};

export default ScheduleReport;
