import React, { useContext } from 'react'
import Form from "react-bootstrap/Form";
import { Link } from 'react-router-dom';
import { AppContext } from '../../../context/AppContext';
import { motion } from "framer-motion";
const AddPushNotification = () => {
    const { sidebar, setSidebar, Dark, setDark } = useContext(AppContext);
    const aninations = {
        initial: { opacity: 0, x: 400 },
        animate: { opacity: 1, x: 0 },
        exit: { opacity: 0, x: 100 },
    };
    return (
        <motion.div className={sidebar ? "taskMain " : "cx-active taskMain"} id="cx-main" variants={aninations}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={{ duration: 0.1 }}>
            <div id='cx-wrapper'>
                <div className="main-master-wrapper">
                    <div className="Heading">
                        <p>Add Notification</p>
                    </div>
                    <div className="row">
                        <div className="col-md-12 mb-4">
                            <div className="innerSelectBox weekCounter">
                                <Form.Label className="common-labels">
                                    {" "}
                                    Trip <span>&#42;</span>{" "}
                                </Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Select Trip..."
                                />
                            </div>
                        </div>
                        <div className="col-md-12 mb-4">
                            <div className="innerSelectBox weekCounter">
                                <Form.Label className="common-labels">
                                    {" "}
                                    Driver <span>&#42;</span>{" "}
                                </Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Select Driver..."
                                />
                            </div>
                        </div>
                        <div className="col-md-12 mb-4">
                            <div className="innerSelectBox weekCounter">
                                <Form.Label className="common-labels">
                                    {" "}
                                    Vehicle <span>&#42;</span>{" "}
                                </Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Select vehicle..."
                                />
                            </div>
                        </div>
                        <div className="col-md-12 mb-4">
                            <div className="innerSelectBox weekCounter">
                                <Form.Label className="common-labels">
                                    Message <span>&#42;</span>
                                </Form.Label>
                                <textarea className='form-control' rows="11" placeholder='Enter your message here...'></textarea>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end btn-wrapper">
                            <Link to="/PushNotification" ><button className="cx-btn-1">Cencel</button></Link>
                            <Link to="/PushNotification" ><button className="cx-btn-2">Send</button></Link>
                        </div>
                    </div>
                </div>
            </div>
        </motion.div>
    )
}

export default AddPushNotification