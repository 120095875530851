import { React, useContext } from "react";
import { AppContext } from "../../context/AppContext";
import blue_box from "../../assets/images/blue_box.svg";
import green_box from "../../assets/images/green_box.svg";
import VectorHand from "../../assets/images/Vector6.svg";
import VectorBin from "../../assets/images/Vector7.svg";
import Gcustomer from "../../assets/images/customer.svg";
import untracked from "../../assets/images/untracked.svg";
import idle from "../../assets/images/idle.svg";
import { Link } from "react-router-dom";
import whiteTrash from "../../assets/images/whiteTrash.svg";
import whiteHand from "../../assets/images/whiteHand.svg";
import whiteBox from "../../assets/images/whiteBox.svg";
import whiteBin from "../../assets/images/whiteBin.svg";
import { motion } from "framer-motion";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import MapComponent from "../../sharedComponent/MapComponent";
import { useTranslation } from "react-i18next";

const TransportationSetting = () => {
  const { sidebar, setSidebar, Dark, setDark } = useContext(AppContext);
  const { t, i18n } = useTranslation();

  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  const renderTooltipForPoligon = (props) => (
    <Tooltip id="button-tooltip" {...props}>
     Draw Polygon
    </Tooltip>
  );
  const renderTooltipForRectangle = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Draw Rectangle
    </Tooltip>
  );
  const renderTooltipForPalm = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Move
    </Tooltip>
  );
  const renderTooltipForDelet = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Delete
    </Tooltip>
  );
  return (

    <>
      <motion.div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }}
      >
        <div id="cx-wrapper" className="Transportation_Setting">
          {/* Main Heading icons */}

          {/* Map COntent */}
          <div className="transportMap">
            <div className="innerMap addParkingMap">
              {/* <div className="mapIcons">
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltipForPoligon}
                >
                  <Link to="#">
                    <div className="inconsIn">
                      <img src={whiteTrash} alt="" />
                    </div>
                  </Link>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltipForRectangle}
                >
                  <Link to="#">
                    <div className="inconsIn">
                      <img src={whiteBox} alt="" />
                    </div>
                  </Link>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltipForPalm}
                >
                  <Link to="#">
                    <div className="inconsIn">
                      <img src={whiteHand} alt="" />
                    </div>
                  </Link>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltipForDelet}
                >
                  <Link to="#">
                    <div className="inconsIn">
                      <img src={whiteBin} alt="" />
                    </div>
                  </Link>
                </OverlayTrigger>
              </div> */}
              <div className="">
              <MapComponent />
              </div>
              <div className="geofence-area">
                <div className="transport-area">
                  <div className="dot-top-left"></div>
                  <div className="dot-top-right"></div>
                  <div className="dot-bottom-left"></div>
                  <div className="dot-bottom-right"></div>
                </div>
              </div>
            </div>
            <div className="belowContent">
              <div className="notific">
                <img src={green_box} alt="" />
                <label>{t("Transportation Coverage Area")}</label>
              </div>
              <div className="notific">
                <img src={blue_box} alt="" className="" />
                <label>{t("Geofence")}</label>
              </div>
              <div className="notific">
                <img src={idle} alt="" />
                <label>{t("Icon")}</label>
              </div>
            </div>

          </div>
          <div className="d-flex justify-content-end align-items-center btn-wrapper">
            <button className="cx-btn-1">{t("Cancel")}</button>
            <button className="cx-btn-2">{t("Submit")}</button>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default TransportationSetting;
