import React, { useState, useContext, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../assets/images/logo.png";
import { Accordion, Button, Dropdown, Modal } from "react-bootstrap";

import big_Logo from "../assets/images/Web-Application-Logo.svg";

import reports_icon from "../assets/images/sidebar/icons/reports.svg";
import reports_icon_active from "../assets/images/sidebar/icons/reports_active.svg";

import double_arrow from "../assets/images/double_arrow.svg";
import HeaderStepper1 from "../assets/images/HeaderStepper1.svg";
import HeaderStepper2 from "../assets/images/HeaderStepper2.svg";
import HeaderStepper3 from "../assets/images/HeaderStepper3.svg";
import DropDownTopArrow from "../assets/images/DropDownTopArrow.svg";
import DropdownLanguage from "./MultiLang/DropdownLanguage";
import bell from "../assets/images/bell.svg";
import profile from "../assets/images/profile.png";
import online from "../assets/images/online.svg";
import { useMediaQuery } from "react-responsive";
import { AppContext } from "../context/AppContext";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

const Slidereports = () => {
  const { t, i18n } = useTranslation();

  const [masterSetting, setMasterSetting] = useState(false);
  const [masterSetting1, setMasterSetting1] = useState(false);

  const [masterData, setMasterData] = useState(false);
  const [masterData1, setMasterData1] = useState(false);

  const [Users, setUsers] = useState(false);
  const [Users1, setUsers1] = useState(false);

  const [NewUser, setNewUser] = useState(false);
  const [NewUser1, setNewUser1] = useState(false);

  const [featureSet, setFeatureSet] = useState(false);
  const [featureSet1, setFeatureSet1] = useState(false);

  const [TripManagement, setTripManagement] = useState(false);
  const [TripManagement1, setTripManagement1] = useState(false);

  const [VehicleExpenses, setVehicleExpenses] = useState(false);
  const [VehicleExpenses1, setVehicleExpenses1] = useState(false);

  const [DispatchManagement, setDispatchManagement] = useState(false);
  const [DispatchManagement1, setDispatchManagement1] = useState(false);

  const [Payment, setPayment] = useState(false);
  const [Payment1, setPayment1] = useState(false);

  const [Inspection, setInspection] = useState(false);
  const [Inspection1, setInspection1] = useState(false);

  const [MarketPlace, setMarketPlace] = useState(false);
  const [MarketPlace1, setMarketPlace1] = useState(false);

  const [Communication, setCommunication] = useState(false);
  const [Communication1, setCommunication1] = useState(false);

  const [Reports, setReports] = useState(false);
  const [Reports1, setReports1] = useState(false);

  const [ConfigurationChecker, setConfigurationChecker] = useState(false);
  const [ConfigurationChecker1, setConfigurationChecker1] = useState(false);

  const [ParkingManagement, setParkingManagement] = useState(false);
  const [ParkingManagement1, setParkingManagement1] = useState(false);

  const [userDash, setUserDash] = useState(false);
  const [userDash1, setUserDash1] = useState(false);

  const [ReplayPlayback, setReplayPlayback] = useState(false);
  const [ReplayPlayback1, setReplayPlayback1] = useState(false);

  const [FuelMana, setFuelMana] = useState(false);
  const [FuelMana1, setFuelMana1] = useState(false);

  const navigate = useNavigate();
  const currentRoute = useLocation().pathname;
  const [activeSidebar, setActiveSidebar] = useState("");
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });

  useEffect(() => {
    isMobile ? setSidebar(false) : setSidebar(false);
    handleOpenMenu();
    return () => {};
  }, [isMobile]);

  const mobileSidebarOff = () => {
    isMobile ? setSidebar(false) : setSidebar(false);
  };

  const [Purchasemenue, setPurchasemenue] = useState();

  const handleOpenMenu = () => {
    if (currentRoute === "/" || currentRoute === "/") {
      setDashboard(true);
    } else if (currentRoute === "/" || currentRoute === "/") {
      setPurchasemenue(true);
    }
  };

  const [Dashboard, setDashboard] = useState(false);
  const [Dashboard1, setDashboard1] = useState(false);

  const handleDashboard = () => {
    setDashboard(!Dashboard);
  };

  const [Announcements, setAnnouncements] = useState(false);
  const handleAnnouncements = () => setAnnouncements(!Announcements);

  const [Email, setEmail] = useState(false);
  const handleEmail = () => setEmail(!Email);

  const [PushNotifications, setPushNotifications] = useState(false);
  const handlePushNotifications = () =>
    setPushNotifications(!PushNotifications);

  const { sidebar, setSidebar } = useContext(AppContext);
  const handleSidebar = () => {
    setSidebar(!sidebar);
  };
  const { slider, setSlider } = useContext(AppContext);
  console.log(slider, "slider");
  const [isActive, setIsActive] = useState(false);
  const handleClick = (Event) => {
    // 👇️ toggle isActive state on click
    setIsActive((current) => !current);
  };
  const aninations = {
    initial: { opacity: 0, y: -40 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 40 },
  };
  const [isChecked, setIsChecked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [pageIndex, setPageIndex] = useState(0); // State to keep track of the index
  const [duration, setDuration] = useState(50);
  const [tempDuration, setTempDuration] = useState(10); // Default duration in seconds
  const [initialDurationSet, setInitialDurationSet] = useState(false);
  const handleToggle = () => {
    setSlider((prev) => {
      const newSliderState = !prev;
      if (newSliderState) {
        setShowModal(true);
      }
      return newSliderState;
    });
  };
  const handleSet = () => {
    setDuration(tempDuration);
    setInitialDurationSet(true); // Mark the initial duration as set
    setShowModal(false);
  };

  const handleClose = () => {
    setShowModal(false);
    if (!slider) {
      setSlider(false); // This line might be unnecessary as `slider` would already be false
    }
  };
  const handleDurationChange = (e) => {
    setTempDuration(Number(e.target.value));
  };
  useEffect(() => {
    let interval;
    if (slider) {
      const pages = [
        "/Reports",
        "/ScheduleReport",
        "/GenerateCustomReport",
        "/ReportView",
      ];

      interval = setInterval(() => {
        setPageIndex((prevIndex) => (prevIndex + 1) % pages.length);
        navigate(pages[pageIndex]);
      }, duration * 1000); // Use duration state value for the interval
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [slider, duration, pageIndex, navigate]);

  useEffect(() => {
    if (slider) {
      const pages = [
        "/Reports",
        "/ScheduleReport",
        "/GenerateCustomReport",
        "/ReportView",
      ];

      navigate(pages[pageIndex]); // Navigate based on the pageIndex
    }
  }, [slider, pageIndex, navigate]);
  useEffect(() => {
    if (showModal) {
      setTempDuration(10); // Reset tempDuration to 10 seconds whenever the modal is opened
    }
  }, [showModal]);
  return (
    <div className="cx-sidebar">
      <div className="cx-header">
        <div
          className={
            sidebar
              ? "cx-header-wrapper "
              : "cx-header-wrapper cx-active-header"
          }
        >
          {(currentRoute === "/Dashboard" && (
            <div className="main-heading">
              <p></p>
              <p>{t("Dashboard")}</p>
            </div>
          )) ||
            /*Master Setting Routes  */
            (currentRoute === "/CustomerProfile" && (
              <div className="main-heading">
                <p></p>
                <p>{t("Customer Profile")}</p>
              </div>
            )) ||
            (currentRoute === "/UpdateCustomerProfile" && (
              <div className="main-heading">
                <Link to="/CustomerProfile">
                  <p>{t("Customer Setting")}</p>
                </Link>
                <p>
                  <span className="mx-1">/</span> {t("Update Profile")}
                </p>
              </div>
            )) ||
            (currentRoute === "/LogoUpdate" && (
              <div className="main-heading">
                <Link to="/CustomerProfile">
                  <p>{t("Customer Setting")}</p>
                </Link>
                <p>
                  {" "}
                  <span className="mx-1">/</span>
                  {t("Logo")}
                </p>
              </div>
            )) ||
            (currentRoute === "/UpdateCustomerLogo" && (
              <div className="main-heading">
                <Link to="/CustomerProfile">
                  <p>{t("Customer Setting")}</p>
                </Link>
                <p>
                  {" "}
                  <span className="mx-1">/</span>
                  {t("Update Logo")}
                </p>
              </div>
            )) ||
            (currentRoute === "/GeneralSetting" && (
              <div className="main-heading">
                <p></p>
                <p>{t("General Setting")}</p>
              </div>
            )) ||
            (currentRoute === "/IntegrationSetting" && (
              <div className="main-heading">
                <p></p>
                <p>{t("Integration Setting")}</p>
              </div>
            )) ||
            (currentRoute === "/TransportationSetting" && (
              <div className="main-heading">
                <p></p>
                <p>{t("Transportation Setting")}</p>
              </div>
            )) ||
            (currentRoute === "/NotificationSetting" && (
              <div className="main-heading">
                <p></p>
                <p>{t("Notification Setting")}</p>
              </div>
            )) ||
            (currentRoute === "/DispatchSetting" && (
              <div className="main-heading">
                <p></p>
                <p>{t("Dispatch Setting")}</p>
              </div>
            )) ||
            (currentRoute === "/AcessRole" && (
              <div className="main-heading">
                <p></p>
                <p>{t("Access Rights")}</p>
              </div>
            )) ||
            (currentRoute === "/UserRole" && (
              <div className="main-heading">
                <Link to="/AcessRole">
                  <p>{t("Access Rights")}</p>
                </Link>
                <p>
                  {" "}
                  <span className="mx-1">/</span>
                  {t("Users Roles")}
                </p>
              </div>
            )) ||
            (currentRoute === "/UserAlert" && (
              <div className="main-heading">
                <Link to="/UserDashboard">
                  <p>{t("User's Insight")}</p>
                </Link>
                <p>
                  {" "}
                  <span className="mx-1">/</span>
                  {t("User Alert's")}
                </p>
              </div>
            )) ||
            (currentRoute === "/UserDashboard" && (
              <div className="main-heading">
                <p></p>
                <p>{t("User's Insight")}</p>
              </div>
            )) ||
            (currentRoute === "/AddOnSettings" && (
              <div className="main-heading">
                <p></p>
                <p>{t("Add-on Setting")}</p>
              </div>
            )) ||
            /*Parking Slot routes  */
            (currentRoute === "/ParkingSlot" && (
              <div className="main-heading">
                <p></p>
                <p>{t("Parking Station")}</p>
              </div>
            )) ||
            (currentRoute === "/AddOnSettingsCart" && (
              <div className="main-heading">
                <Link to="/AddOnSettings">
                  <p>{t("Addon Setting")}</p>
                </Link>
                <p>
                  <span className="mx-1">/</span>
                  {t("My Cart")}
                </p>
              </div>
            )) ||
            (currentRoute === "/Vehicle" && (
              <div className="main-heading">
                <p></p>
                <p>{t("Vehicle")}</p>
              </div>
            )) ||
            (currentRoute === "/administrator" ||
            currentRoute === "/administrator" ? (
              <div className="main-heading">
                <Link to="/administrator">
                  <p></p>
                  <p>{t("Administrator")}</p>
                </Link>
              </div>
            ) : (
              ""
            )) ||
            (currentRoute === "/view" && (
              <div className="main-heading">
                <Link to="/administrator">
                  <p>{t("Administrator")}</p>
                </Link>
                <p>
                  {" "}
                  <span className="mx-1">/</span>
                  {t("view")}
                </p>
              </div>
            )) ||
            (currentRoute === "/TransportManager" && (
              <div className="main-heading">
                <p></p>
                <p>{t("Transport Manager")}</p>
              </div>
            )) ||
            (currentRoute === "/AddTransportManager" && (
              <div className="main-heading">
                <Link to="/TransportManager">
                  <p>{t("Transport Manager")}</p>
                </Link>
                <p>
                  {" "}
                  <span className="mx-1">/</span>
                  {t("Add Transport Manager")}
                </p>
              </div>
            )) ||
            (currentRoute === "/ViewTransportManager" && (
              <div className="main-heading">
                <Link to="/TransportManager">
                  <p>{t("Transport Manager")}</p>
                </Link>
                <p>
                  {" "}
                  <span className="mx-1">/</span>
                  {t("View Transport Manager")}
                </p>
              </div>
            )) ||
            (currentRoute === "/EditTransportManager" && (
              <div className="main-heading">
                <Link to="/TransportManager">
                  <p>{t("Transport Manager")}</p>
                </Link>
                <p>
                  <span className="mx-1">/</span>
                  {t("Edit Transport Manager")}
                </p>
              </div>
            )) ||
            (currentRoute === "/ChangePassword" && (
              <div className="main-heading">
                <Link to="/TransportManager">
                  <p>{t("Transport Manager")}</p>
                </Link>
                <p>
                  <span className="mx-1">/</span>
                  {t("Change Password")}
                </p>
              </div>
            )) ||
            // ===================================
            (currentRoute === "/Drivers" || currentRoute === "/Drivers" ? (
              <div className="main-heading">
                <p></p>
                <p>{t("Drivers")}</p>
              </div>
            ) : (
              ""
            )) ||
            (currentRoute === "/ViewDrivers" && (
              <div className="main-heading">
                <Link to="/Drivers">
                  <p>{t("Drivers")}</p>
                </Link>
                <p>
                  <span className="mx-1">/</span>
                  {t("View Drivers")}
                </p>
              </div>
            )) ||
            (currentRoute === "/ReportDistributionContactsDetails" && (
              <div className="main-heading">
                <Link to="/AllUsers">
                  <p>{t("Report Distribution Contacts")}</p>
                </Link>
                <p>
                  <span className="mx-1">/</span>
                  {t("Contact Details")}
                </p>
              </div>
            )) ||
            (currentRoute === "/AddDrivers" && (
              <div className="main-heading">
                <Link to="/Drivers">
                  <p>{t("Drivers")}</p>
                </Link>
                <p>
                  <span className="mx-1">/</span>
                  {t("Add Drivers Details")}
                </p>
              </div>
            )) ||
            (currentRoute === "/EditDrivers" && (
              <div className="main-heading">
                <Link to="/Drivers">
                  <p>{t("Drivers")}</p>
                </Link>
                <p>
                  <span className="mx-1">/</span>
                  {t("Edit Drivers")}
                </p>
              </div>
            )) ||
            (currentRoute === "/ChangeDriverPassword" && (
              <div className="main-heading">
                <Link to="/Drivers">
                  <p>{t("Drivers")}</p>
                </Link>
                <p>
                  <span className="mx-1">/</span>
                  {t("Change Password")}
                </p>
              </div>
            )) ||
            // ===========================
            (currentRoute === "/DeliveryPerson" && (
              <div className="main-heading">
                <p></p>
                <p>{t("Delivery Person")}</p>
              </div>
            )) ||
            (currentRoute === "/AddDeliveryPerson" && (
              <div className="main-heading">
                <Link to="/DeliveryPerson">
                  <p>{t("Delivery Person")}</p>
                </Link>
                <p>
                  {" "}
                  <span className="mx-1">/</span>
                  {t("Add Delivery Person Details")}
                </p>
              </div>
            )) ||
            (currentRoute === "/EditDeliveryPerson" && (
              <div className="main-heading">
                <Link to="/DeliveryPerson">
                  <p>{t("Delivery Person")}</p>
                </Link>
                <p>
                  <span className="mx-1">/</span>
                  {t("Edit Delivery Person Details")}
                </p>
              </div>
            )) ||
            (currentRoute === "/ViewDeliveryPerson" && (
              <div className="main-heading">
                <Link to="/DeliveryPerson">
                  <p>{t("Delivery Person")}</p>
                </Link>
                <p>
                  <span className="mx-1">/</span>
                  {t("View Delivery Person")}
                </p>
              </div>
            )) ||
            (currentRoute === "/ChangePassDelivery" && (
              <div className="main-heading">
                <Link to="/DeliveryPerson">
                  <p>{t("Delivery Person")}</p>
                </Link>
                <p>
                  <span className="mx-1">/</span>
                  {t("Change Password")}
                </p>
              </div>
            )) ||
            (currentRoute === "/DeliveryPerson" && (
              <div className="main-heading">
                <p></p>
                <p>{t("Delivery Person")}</p>
              </div>
            )) ||
            /*FLEET MANAGER*/
            (currentRoute === "/FleetManager" ||
            currentRoute === "/ViewFleetManager" ? (
              <div className="main-heading">
                <p></p>
                <p>{t("Fleet Manager")}</p>
              </div>
            ) : null) ||
            (currentRoute === "/AddFleetManager" && (
              <div className="main-heading">
                <Link to="/FleetManager">
                  <p>{t("Fleet Manager")}</p>
                </Link>
                <p>
                  {" "}
                  <span className="mx-1">/</span>
                  {t("Add Fleet Manager Details")}
                </p>
              </div>
            )) ||
            (currentRoute === "/EditFleetManager" && (
              <div className="main-heading">
                <Link to="/FleetManager">
                  <p>{t("Fleet Manager")}</p>
                </Link>
                <p>
                  {" "}
                  <span className="mx-1">/</span>
                  {t("Edit Fleet Manager Details")}
                </p>
              </div>
            )) ||
            (currentRoute === "/ChangeFleetPass" && (
              <div className="main-heading">
                <Link to="/FleetManager">
                  <p>{t("Fleet Manager")}</p>
                </Link>
                <p>
                  {" "}
                  <span className="mx-1">/</span>
                  {t("Change Password")}
                </p>
              </div>
            )) ||
            /*VEHICLE ASSISTANT*/
            (currentRoute === "/VehicleAssistants" && (
              <div className="main-heading">
                <p></p>
                <p>{t("Vehicle Assistants")}</p>
              </div>
            )) ||
            (currentRoute === "/ViewVehicleAssistants" ||
            currentRoute === "/ViewVehicleAssistants" ? (
              <div className="main-heading">
                <Link to="/VehicleAssistants">
                  <p>{t("Vehicle Assistant")}</p>
                </Link>
                <p>
                  <span className="mx-1">/</span>
                  {t("View Vehicle Assistants")}
                </p>
              </div>
            ) : null) ||
            (currentRoute === "/AddVehicleAssistants" && (
              <div className="main-heading">
                <Link to="/VehicleAssistants">
                  <p>{t("Vehicle Assistant")}</p>
                </Link>
                <p>
                  <span className="mx-1">/</span>
                  {t("Add Vehicle Assistant Details")}
                </p>
              </div>
            )) ||
            (currentRoute === "/DispatchCustomer" && (
              <div className="main-heading">
                <p></p>
                <p>{t("Dispatch Customer")}</p>
              </div>
            )) ||
            (currentRoute === "/EditCustomerDispatch" && (
              <div className="main-heading">
                <Link to="/DispatchCustomer">
                  <p>{t("Dispatch Customer")}</p>
                </Link>
                <p>
                  <span className="mx-1">/</span>
                  {t("Edit Customer Dispatch")}
                </p>
              </div>
            )) ||
            (currentRoute === "/EditVehicleAssistants" && (
              <div className="main-heading">
                <Link to="/VehicleAssistants">
                  <p>{t("Vehicle Assistant")}</p>
                </Link>
                <p>
                  <span className="mx-1"></span>/ {t("Edit Vehicle Assistant")}
                </p>
              </div>
            )) ||
            (currentRoute === "/ChangeDelPass" && (
              <div className="main-heading">
                <Link to="/VehicleAssistants">
                  <p>{t("Vehicle Assistant")}</p>
                </Link>
                <p>
                  <span className="mx-1">/</span>
                  {t("Change Password")}
                </p>
              </div>
            )) ||
            // New Users Routes
            (currentRoute === "/AllUsers" && (
              <div className="main-heading">
                <p></p>
                <p>{t("Report Distribution Contacts")}</p>
              </div>
            )) ||
            (currentRoute === "/CreateUser" && (
              <div className="main-heading">
                <Link to="/AllUsers">
                  <p>{t("Report Distribution Contacts")}</p>
                </Link>
                <p>
                  <span className="mx-1">/</span>
                  {t("Add Contact")}
                </p>
              </div>
            )) ||
            (currentRoute === "/EditUser" && (
              <div className="main-heading">
                <Link to="/AllUsers">
                  <p>{t("Report Distribution Contacts")}</p>
                </Link>
                <p>
                  <span className="mx-1">/</span>
                  {t("Edit Contact")}
                </p>
              </div>
            )) ||
            /*TRIP MANAGEMENT*/
            (currentRoute === "/TripManagement" && (
              <div className="main-heading">
                <p></p>
                <p>{t("Trip Management")}</p>
              </div>
            )) ||
            (currentRoute === "/AddDispatchTrip" && (
              <div className="main-heading">
                <Link to="/TripManagement">
                  <p>{t("Trip Management")}</p>
                </Link>
                <p>
                  <span className="mx-1">/</span>
                  {t("Add Scheduled Trip")}
                </p>
              </div>
            )) ||
            (currentRoute === "/ViewDispatchTrip" && (
              <div className="main-heading">
                <Link to="/TripManagement">
                  <p>{t("Trip Management")}</p>
                </Link>
                <p>
                  <span className="mx-1">/</span>
                  {t("View Dispatch Trips")}
                </p>
              </div>
            )) ||
            (currentRoute === "/ViewStop" && (
              <div className="main-heading">
                <Link to="/TripManagement">
                  <p>{t("Trip Management")}</p>
                </Link>
                <p>
                  <span className="mx-1">/</span>
                  {t("View Stop")}
                </p>
              </div>
            )) ||
            (currentRoute === "/EditDispatchTrip" && (
              <div className="main-heading">
                <Link to="/TripManagement">
                  <p>{t("Trip Management")}</p>
                </Link>
                <p>
                  <span className="mx-1">/</span>
                  {t("Edit Dispatch Trips")}
                </p>
              </div>
            )) ||
            (currentRoute === "/AddStop" && (
              <div className="main-heading">
                <Link to="/TripManagement">
                  <p>{t("Trip Management")}</p>
                </Link>
                <p>
                  <span className="mx-1">/</span>
                  {t("Add Stop")}
                </p>
              </div>
            )) ||
            (currentRoute === "/EditStop" && (
              <div className="main-heading">
                <Link to="/TripManagement">
                  <p>{t("Trip Management")}</p>
                </Link>
                <p>
                  <span className="mx-1">/</span>
                  {t("Edit Stop")}
                </p>
              </div>
            )) ||
            (currentRoute === "/ViewStop" && (
              <div className="main-heading">
                <Link to="/TripManagement">
                  <p>{t("Trip Management")}</p>
                </Link>
                <p>
                  <span className="mx-1">/</span>
                  {t("Stop Details")}
                </p>
              </div>
            )) ||
            /*Vehicle Accident*/

            (currentRoute === "/VehicleAccident" ||
            currentRoute === "/ViewVehicleAccident" ? (
              <div className="main-heading">
                <p></p>
                <p>{t("Vehicle Accident")}</p>
              </div>
            ) : null) ||
            (currentRoute === "/AddVehicleAccident" && (
              <div className="main-heading">
                <Link to="/VehicleAccident">
                  <p>{t("Vehicle Accident")}</p>
                </Link>
                <p>
                  {" "}
                  <span className="mx-1">/</span>
                  {t("Add Vehicle Incident Details")}
                </p>
              </div>
            )) ||
            (currentRoute === "/EditVehicleAccident" && (
              <div className="main-heading">
                <Link to="/VehicleAccident">
                  <p>{t("Vehicle Accident")}</p>
                </Link>
                <p>
                  <span className="mx-1">/</span>
                  {t("Edit Vehicle Incident Details")}
                </p>
              </div>
            )) ||
            /*Vehicle Accesroy*/
            (currentRoute === "/VehicleAccesory" ||
            currentRoute === "/ViewVehicleAccesory" ? (
              <div className="main-heading">
                <p></p>
                <p>{t("Vehicle Accessory")}</p>
              </div>
            ) : null) ||
            (currentRoute === "/AddVehicleAccesory" && (
              <div className="main-heading">
                <Link to="/VehicleAccesory">
                  <p>{t("Vehicle Accessory")}</p>
                </Link>
                <p>
                  {" "}
                  <span className="mx-1">/</span>
                  {t("Add Vehicle Accesory Details")}
                </p>
              </div>
            )) ||
            (currentRoute === "/EditVehicleAccesory" && (
              <div className="main-heading">
                <Link to="/VehicleAccesory">
                  <p>{t("Vehicle Accessory")}</p>
                </Link>
                <p>
                  <span className="mx-1">/</span>
                  {t("Edit Vehicle Accessory Details")}
                </p>
              </div>
            )) ||
            /*Vehicle Spare parts*/
            (currentRoute === "/VehicleSpareParts" ||
            currentRoute === "/ViewVehicleSpareParts" ? (
              <div className="main-heading">
                <p></p>
                <p>{t("Vehicle Spare Parts")}</p>
              </div>
            ) : null) ||
            (currentRoute === "/AddVehicleSpareParts" && (
              <div className="main-heading">
                <Link to="/VehicleSpareParts">
                  <p>{t("Vehicle Spare Parts")}</p>
                </Link>
                <p>
                  <span className="mx-1">/</span>
                  {t("Add Vehicle Spare Details")}
                </p>
              </div>
            )) ||
            (currentRoute === "/EditVehicleSpareParts" && (
              <div className="main-heading">
                <Link to="/VehicleSpareParts">
                  <p>{t("Vehicle Spare Parts")}</p>
                </Link>
                <p>
                  <span className="mx-1">/</span>
                  {t("Edit Vehicle Spare Details")}
                </p>
              </div>
            )) ||
            /*Vehicle Fine*/
            (currentRoute === "/VehicleFine" ||
            currentRoute === "/ViewVehicleFine" ? (
              <div className="main-heading">
                <p></p>
                <p>{t("Vehicle Fine")}</p>
              </div>
            ) : null) ||
            (currentRoute === "/AddVehicleFine" && (
              <div className="main-heading">
                <Link to="/VehicleFine">
                  <p>{t("Vehicle Fine")}</p>
                </Link>
                <p>
                  <span className="mx-1">/</span>
                  {t("Add Vehicle Fine Details")}
                </p>
              </div>
            )) ||
            (currentRoute === "/EditVehicleFine" && (
              <div className="main-heading">
                <Link to="/VehicleFine">
                  <p>{t("Vehicle Fine")}</p>
                </Link>
                <p>
                  <span className="mx-1">/</span>
                  {t("Edit Vehicle Fine Details")}
                </p>
              </div>
            )) ||
            /*Fleet Maintenance*/
            (currentRoute === "/FleetMaintenance" ||
            currentRoute === "/EditFleetMaintainence" ||
            currentRoute === "/ViewFleetMaintainence" ? (
              <div className="main-heading">
                <p></p>
                <p>{t("Vehicle Maintenance")}</p>
              </div>
            ) : null) ||
            (currentRoute === "/AddFleetMaintainence" && (
              <div className="main-heading">
                <Link to="/FleetMaintenance">
                  <p>{t("Vehicle Maintenance")}</p>
                </Link>
                <p>
                  <span className="mx-1">/</span>
                  {t("Add New Details")}
                </p>
              </div>
            )) ||
            /*Fuel Expenses*/
            (currentRoute === "/FuelExpenses" ||
            currentRoute === "/ViewFuelExpenses" ? (
              <div className="main-heading">
                <p></p>
                <p>{t("Fuel Expenses")}</p>
              </div>
            ) : null) ||
            (currentRoute === "/AddFuelExpenses" && (
              <div className="main-heading">
                <Link to="/FuelExpenses">
                  <p>{t("Fuel Expenses")}</p>
                </Link>
                <p>
                  <span className="mx-1">/</span>
                  {t("Add Fuel Expenses")}
                </p>
              </div>
            )) ||
            (currentRoute === "/EditFuelExpenses" && (
              <div className="main-heading">
                <Link to="/FuelExpenses">
                  <p>{t("Fuel Expenses")}</p>
                </Link>
                <p>
                  <span className="mx-1">/</span>
                  {t("Edit Fuel Expenses")}
                </p>
              </div>
            )) ||
            (currentRoute === "/VehicleInspectionDashboard" && (
              <div className="main-heading">
                <Link to="/VehicleInspectionDashboard">
                  <p>{t("VehicleInspectionDashboard")}</p>
                </Link>
                {/* <p>
                <span className="mx-1">/</span>
                {t("New Inspection")}
              </p> */}
              </div>
            )) ||
            (currentRoute === "/NewInspection" && (
              <div className="main-heading">
                <Link to="/VehicleInspection">
                  <p>{t("Vehicle Inspection")}</p>
                </Link>
                <p>
                  <span className="mx-1">/</span>
                  {t("New Inspection")}
                </p>
              </div>
            )) ||
            (currentRoute === "/NewVehicleInspection" && (
              <div className="main-heading">
                <Link to="/NewInspection">
                  <p>{t("Vehicle Inspection")}</p>
                </Link>
                <p>
                  <span className="mx-1">/</span>
                  {t("New Inspection")}
                </p>
              </div>
            )) ||
            (currentRoute === "/ViewInspectionDetails" && (
              <div className="main-heading">
                <Link to="/VehicleInspection">
                  <p>{t("Vehicle Inspection")}</p>
                </Link>
                <p>
                  <span className="mx-1">/</span>
                  {t("View Inspection Details")}
                </p>
              </div>
            )) ||
            /*Dispatch Module*/
            (currentRoute === "/DispatchCustomer" && (
              <div className="main-heading">
                <p></p>
                <p>{t("Dispatch Customer")}</p>
              </div>
            )) ||
            (currentRoute === "/VehicleInspection" && (
              <div className="main-heading">
                <p></p>
                <p>{t("Vehicle Inspection")}</p>
              </div>
            )) ||
            (currentRoute === "/ViewDispatch" && (
              <div className="main-heading">
                <Link to="/DispatchCustomer">
                  <p>{t("Dispatch Customer")}</p>
                </Link>
                <p>
                  <span className="mx-1">/</span>
                  {t("Customer Details")}
                </p>
              </div>
            )) ||
            (currentRoute === "/AddDispatchCustomer" && (
              <div className="main-heading">
                <Link to="/DispatchCustomer">
                  <p>{t("Dispatch Customer")}</p>
                </Link>
                <p>
                  <span className="mx-1">/</span>
                  {t("Add Customer Details")}
                </p>
              </div>
            )) ||
            (currentRoute === "/EditCustomerDispatch" && (
              <div className="main-heading">
                <Link to="/DispatchCustomer">
                  <p>{t("Dispatch Customer")}</p>
                </Link>
                <p>
                  <span className="mx-1">/</span>
                  {t("Edit Customer Details")}
                </p>
              </div>
            )) ||
            /*Dispatch Order*/
            (currentRoute === "/DispatchOrder" && (
              <div className="main-heading">
                <p></p>
                <p>{t("Orders")}</p>
              </div>
            )) ||
            (currentRoute === "/ViewOrders" && (
              <div className="main-heading">
                <Link to="/DispatchOrder">
                  <p>{t("Orders")}</p>
                </Link>
                <p>
                  <span className="mx-1">/</span>
                  {t("Order Details")}
                </p>
              </div>
            )) ||
            (currentRoute === "/EditCustomerDispatch" && (
              <div className="main-heading">
                <Link to="/DispatchOrder">
                  <p>{t("Dispatch Customer")}</p>
                </Link>
                <p>
                  <span className="mx-1">/</span>
                  {t("Edit Customer Details")}
                </p>
              </div>
            )) ||
            (currentRoute === "/ManualRouting" && (
              <div className="main-heading">
                <Link to="/DispatchOrder">
                  <p>
                    {t("Order")}
                    <span className="mx-1">/</span>
                  </p>
                </Link>
                <p>{t("Manual Routing")}</p>
              </div>
            )) ||
            /*Payment Module Invoiced*/
            (currentRoute === "/Invoiced" && (
              <div className="main-heading">
                <p></p>
                <p>{t("Invoiced")}</p>
              </div>
            )) ||
            (currentRoute === "/InvoiceDetails" && (
              <div className="main-heading">
                <Link to="/Invoiced">
                  <p>{t("Invoiced")}</p>
                </Link>
                <p>
                  <span className="mx-1">/</span>
                  {t("Invoice Details")}
                </p>
              </div>
            )) ||
            /*Payment Module Paid*/
            (currentRoute === "/Paid" && (
              <div className="main-heading">
                <p></p>
                <p>{t("Paid Invoices")}</p>
              </div>
            )) ||
            (currentRoute === "/PaidPaymentReceipt" && (
              <div className="main-heading">
                <Link to="/Paid">
                  <p>{t("Paid Invoices")}</p>
                </Link>
                <p>
                  <span className="mx-1">/</span>
                  {t("Receipt")}
                </p>
              </div>
            )) ||
            (currentRoute === "/PaidPaymentInvoice" && (
              <div className="main-heading">
                <Link to="/Paid">
                  <p>{t("Paid Invoices")}</p>
                </Link>
                <p>
                  <span className="mx-1">/</span>
                  {t("Invoice")}
                </p>
              </div>
            )) ||
            /*Announcement*/
            (currentRoute === "/Announcement" && (
              <div className="main-heading">
                <p></p>
                <p>{t("Announcement")}</p>
              </div>
            )) ||
            (currentRoute === "/Addaudio" && (
              <div className="main-heading">
                <Link to="/Announcement">
                  <p>{t("Announcement")}</p>
                </Link>
                <p>
                  <span className="mx-1">/</span>
                  {t("Add Audio")}
                </p>
              </div>
            )) ||
            (currentRoute === "/EditAudio" && (
              <div className="main-heading">
                <Link to="/Announcement">
                  <p>{t("Announcement")}</p>
                </Link>
                <p>
                  <span className="mx-1">/</span>
                  {t("Edit Audio")}
                </p>
              </div>
            )) ||
            /*Email*/
            (currentRoute === "/Email" && (
              <div className="main-heading">
                <p></p>
                <p>{t("Email")}</p>
              </div>
            )) ||
            (currentRoute === "/ComposeEmail" && (
              <div className="main-heading">
                <Link to="/Email">
                  <p>{t("Email")}</p>
                </Link>
                <p>
                  <span className="mx-1">/</span>
                  {t("Compose Email")}
                </p>
              </div>
            )) ||
            (currentRoute === "/EmailDetails" && (
              <div className="main-heading">
                <Link to="/Email">
                  <p>{t("Email")}</p>
                </Link>
                <p>
                  <span className="mx-1">/</span>
                  {t("Email Details")}
                </p>
              </div>
            )) ||
            /*Push Notification*/
            (currentRoute === "/PushNotification" && (
              <div className="main-heading">
                <p></p>
                <p>{t("Push Notification")}</p>
              </div>
            )) ||
            (currentRoute === "/AddPushNotification" && (
              <div className="main-heading">
                <Link to="/PushNotification">
                  <p>{t("Push Notification")}</p>
                </Link>
                <p>
                  <span className="mx-1">/</span>
                  {t("Add Push Notofication")}
                </p>
              </div>
            )) ||
            (currentRoute === "/NotificationDetails" && (
              <div className="main-heading">
                <Link to="/PushNotification">
                  <p>{t("Push Notification")}</p>
                </Link>
                <p>
                  <span className="mx-1">/</span>
                  {t("Notification Details")}
                </p>
              </div>
            )) ||
            /*Reports*/
            (currentRoute === "/Reports" && (
              <div className="main-heading">
                <p></p>
                <p>{t("Reports")}</p>
              </div>
            )) ||
            (currentRoute === "/ScheduleReport" && (
              <div className="main-heading">
                <Link to="/Reports">
                  <p>{t("Reports")}</p>
                </Link>
                <p>
                  <span className="mx-1">/</span>
                  {t("Schedule Report")}
                </p>
              </div>
            )) ||
            (currentRoute === "/GenerateCustomReport" && (
              <div className="main-heading">
                <Link to="/Reports">
                  <p>{t("Reports")}</p>
                </Link>
                <p>
                  <span className="mx-1">/</span>
                  {t("Generate Custom Report")}
                </p>
              </div>
            )) ||
            (currentRoute === "/ReportView" && (
              <div className="main-heading">
                <Link to="/Reports">
                  <p>
                    {t("Reports")}
                    <span className="mx-1">/</span>{" "}
                    {t("Vehicle Running Report")}
                    <span className="mx-1">/</span>
                  </p>
                </Link>
                <p>{t("Vehicle Running Summary Reports")}</p>
              </div>
            )) ||
            (currentRoute === "/AddParkingSlot" && (
              <>
                <div className="main-heading">
                  <Link to="/ParkingSlot">
                    {/* <p>{t("Parking Station")}</p> */}
                  </Link>
                  <p>
                    {/* <span className="mx-1">/</span> */}
                    {t("Add Parking Station")}
                  </p>
                </div>
              </>
            )) ||
            (currentRoute === "/ConfigurationChecker" && (
              <div className="main-heading">
                <p></p>
                <p>{t("Application Configuration Checker")}</p>
              </div>
            )) ||
            (currentRoute === "/AddParkingManagement" && (
              <div className="main-heading">
                <Link to="/ParkingManagement">
                  <p>
                    {t("Parking Management")}
                    <span className="mx-1">/</span>
                  </p>
                </Link>
                <p>{t("Add Parking Station")}</p>
              </div>
            )) ||
            (currentRoute === "/ParkingManagement" && (
              <div className="main-heading">
                <p></p>
                <p>{t("Parking Management")}</p>
              </div>
            )) ||
            (currentRoute === "/FeatureSet" && (
              <div className="main-heading">
                <p></p>
                <p>{t("Hardware Feature Set")}</p>
              </div>
            )) ||
            (currentRoute === "/FuelManagementDashbord" && (
              <div className="main-heading">
                <p></p>
                <p>{t("Fuel Management")}</p>
              </div>
            )) ||
            (currentRoute === "/FuelManagementDetails" && (
              <div className="main-heading">
                <p></p>
                <p>{t("Fuel Management")}</p>
              </div>
            )) ||
            (currentRoute === "/FuelAlerts" && (
              <div className="main-heading">
                <p></p>
                <p>{t("Fuel Alerts")}</p>
              </div>
            )) ||
            /*Master Setting Routes  */
            (currentRoute === "/MyProfile" && (
              <div className="main-heading">
                <p></p>
                <p>{t("My Profile")}</p>
              </div>
            )) ||
            (currentRoute === "/ReplayPlayback" && (
              <div className="main-heading">
                <p></p>
                <p>{t("Playback's")}</p>
              </div>
            )) ||
            (currentRoute === "/VehicleBookingList" && (
              <div className="main-heading">
                <p></p>
                <p>{t("VehicleBookingList")}</p>
              </div>
            )) ||
            (currentRoute === "/Dispatchcustumor" && (
              <div className="main-heading">
                <p></p>
                <p>{t("DispatchCustomer")}</p>
              </div>
            )) ||
            (currentRoute === "/Adddispatchedcustomer" && (
              <div className="main-heading">
                <p></p>
                <p>{t("Add Dispatch Customer")}</p>
              </div>
            )) ||
            (currentRoute === "/RepleyplayBack" && (
              <div className="main-heading">
                <p></p>
                <p>{t("Playback's")}</p>
              </div>
            )) ||
            ((currentRoute === "/ViewReport" ||
              currentRoute === "/ReportIssue") && (
              <div className="main-heading">
                <Link to="/CustomerSupport">
                  <p>
                    {t("Customer Support")}
                    <span className="mx-1">/</span>
                  </p>
                </Link>
                <Link to="/CustomerSupport">
                  <p>
                    {t("Email Support")}
                    <span className="mx-1">/</span>
                  </p>
                </Link>
                <p>{t("Report Issue")}</p>
              </div>
            )) ||
            (currentRoute === "/CustomerSupport" && (
              <div className="main-heading">
                <Link to="/CustomerSupport">
                  <p>
                    {t("Customer Support")}
                    <span className="mx-1">/</span>
                  </p>
                </Link>
                <p>{t("Email Support")}</p>
              </div>
            )) ||
            (currentRoute === "/EditProfile" && (
              <div className="main-heading">
                <Link to="/MyProfile">
                  <p>
                    {t("My Profile")} <span className="mx-1">/</span>
                  </p>
                </Link>
                <p>{t("Update Profile")}</p>
              </div>
            )) ||
            (currentRoute === "/ChangePasswordProfile" && (
              <div className="main-heading">
                <Link to="/MyProfile">
                  <p>
                    {t("My Profile")} <span className="mx-1">/</span>
                  </p>
                </Link>
                <p>{t("Change Password")}</p>
              </div>
            )) ||
            (currentRoute === "/DeliveryRequest" && (
              <div className="main-heading">
                <p></p>
                <p>{t("Delivery Request")}</p>
              </div>
            )) ||
            (currentRoute === "/AddMerchant" && (
              <div className="main-heading">
                <Link to="/Merchant">
                  <p>
                    {t("Merchant")} <span className="mx-1">/</span>
                  </p>
                </Link>
                <p>{t("Add Merchant")}</p>
              </div>
            )) ||
            (currentRoute === "/EditMerchant" && (
              <div className="main-heading">
                <Link to="/Merchant">
                  <p>
                    {t("Merchant")} <span className="mx-1">/</span>
                  </p>
                </Link>
                <p>{t("Edit Merchant")}</p>
              </div>
            )) ||
            (currentRoute === "/ViewMerchant" && (
              <div className="main-heading">
                <Link to="/Merchant">
                  <p>
                    {t("Merchant")} <span className="mx-1">/</span>
                  </p>
                </Link>
                <p>{t("Merchant Details")}</p>
              </div>
            )) ||
            (currentRoute === "/Merchant" && (
              <div className="main-heading">
                <p></p>
                <p>{t("Merchant")}</p>
              </div>
            )) ||
            (currentRoute === "/VehicleBooking" && (
              <div className="main-heading">
                <p></p>
                <p>{t("Vehicle Booking Request")}</p>
              </div>
            )) ||
            // ============= Market Place ==========

            (currentRoute === "/OfferVehicleMarketPlace" && (
              <div className="main-heading">
                <p></p>
                <p>{t("Offer Vehicle")}</p>
              </div>
            )) ||
            (currentRoute === "/OfferDriverMarketPlace" && (
              <div className="main-heading">
                <p></p>
                <p>{t("Offer Driver")}</p>
              </div>
            )) ||
            (currentRoute === "/DirectOrderMarketPlace" && (
              <div className="main-heading">
                <p></p>
                <p>{t("Direct Order")}</p>
              </div>
            )) ||
            (currentRoute === "/DirectOrderOfferLoad" && (
              <div className="main-heading">
                <Link to="/DirectOrderMarketPlace">
                  <p>
                    {t("Direct Order")} <span className="mx-1">/</span>
                  </p>
                </Link>
                <p>{t("Offer Load Details")}</p>
              </div>
            )) ||
            (currentRoute === "/DirectOrderVehicleDetails" && (
              <div className="main-heading">
                <Link to="/DirectOrderMarketPlace">
                  <p>
                    {t("Direct Order")} <span className="mx-1">/</span>
                  </p>
                </Link>
                <p>{t("Vehicle Details")}</p>
              </div>
            )) ||
            (currentRoute === "/DirectOrderReferenceDetails" && (
              <div className="main-heading">
                <Link to="/DirectOrderMarketPlace">
                  <p>
                    {t("Direct Order")} <span className="mx-1">/</span>
                  </p>
                </Link>
                <p>{t("Reference Details")}</p>
              </div>
            )) ||
            (currentRoute === "/DirectOrderConfirmation" && (
              <div className="main-heading">
                <Link to="/DirectOrderMarketPlace">
                  <p>
                    {t("Direct Order")} <span className="mx-1">/</span>
                  </p>
                </Link>
                <p>{t("Order Confirmation")}</p>
              </div>
            )) ||
            (currentRoute === "/OnlineAuction" && (
              <div className="main-heading">
                <p></p>
                <p>{t("Online Auction")}</p>
              </div>
            )) ||
            (currentRoute === "/BidAuctionDetails" && (
              <div className="main-heading">
                <Link to="/OnlineAuction">
                  <p>
                    {t("Online Auction")} <span className="mx-1">/</span>
                  </p>
                </Link>
                <p>{t("Bid Auction Details")}</p>
              </div>
            )) ||
            (currentRoute === "/AuctionOfferDetails" && (
              <div className="main-heading">
                <Link to="/OnlineAuction">
                  <p>
                    {t("Online Auction")} <span className="mx-1">/</span>
                  </p>
                </Link>
                <p>{t("Offer Details")}</p>
              </div>
            )) ||
            (currentRoute === "/OnlineAuctionVehicleDetails" && (
              <div className="main-heading">
                <Link to="/OnlineAuction">
                  <p>
                    {t("Online Auction")} <span className="mx-1">/</span>
                  </p>
                </Link>
                <p>{t("Vehicle Details")}</p>
              </div>
            )) ||
            (currentRoute === "/AuctionReferenceDetails" && (
              <div className="main-heading">
                <Link to="/OnlineAuction">
                  <p>
                    {t("Online Auction")} <span className="mx-1">/</span>
                  </p>
                </Link>
                <p>{t("Reference Details")}</p>
              </div>
            )) ||
            (currentRoute === "/OrderConfDetails" && (
              <div className="main-heading">
                <Link to="/OnlineAuction">
                  <p>
                    {t("Online Auction")} <span className="mx-1">/</span>
                  </p>
                </Link>
                <p>{t("Order Confirmation")}</p>
              </div>
            )) ||
            // My messages
            (currentRoute === "/MyMessages" ||
            currentRoute === "/ComposeMessage" ? (
              <div className="main-heading">
                <p></p>
                <Link to="/MyMessages">
                  <p>{t("My Messages")}</p>
                </Link>
              </div>
            ) : (
              ""
            ))}
          <header className="d-flex">
            <div
              className="d-flex"
              style={{ display: "flex", alignItems: "center" }}
            >
              <p className="Automation_label" style={{ marginRight: "15px" }}>
                SlideShow
              </p>
              <div
                className="on-off-toggle"
                style={{
                  display: "inline-block",
                  height: "24px",
                  position: "relative",
                  width: "56px",
                }}
              >
                <input
                  className="on-off-toggle__input"
                  style={{ opacity: 0, position: "absolute" }}
                  type="checkbox"
                  id="bopis"
                  checked={slider}
                  onChange={handleToggle}
                />
                <label
                  htmlFor="bopis"
                  className="on-off-toggle__slider"
                  style={{
                    backgroundColor: slider ? "#9c4900" : "#d8d8d8",
                    borderRadius: "34px",
                    display: "block",
                    height: "24px",
                    transition: "background-color 0.4s",
                    width: "56px",
                    position: "relative",
                    cursor: "pointer",
                  }}
                >
                  <span
                    style={{
                      backgroundColor: "#fff",
                      borderRadius: "100%",
                      bottom: "3px",
                      boxShadow: "0 0 0 1px #949494",
                      content: '""',
                      display: "block",
                      height: "18px",
                      position: "absolute",
                      transition: "0.4s",
                      width: "18px",
                      left: slider ? "32px" : "3px",
                      zIndex: 5,
                    }}
                  />
                  <span
                    style={{
                      color: slider ? "#fff" : "hwb(0 28% 72%)",
                      content: '""',
                      display: "block",
                      fontSize: "12px",
                      fontWeight: 700,
                      lineHeight: "24px",
                      paddingLeft: "26px",
                      textTransform: "uppercase",
                      transition: "all 0.4s",
                    }}
                  >
                    {/* {isChecked ? "On" : "Off"} */}
                  </span>
                </label>
              </div>
            </div>
            {showModal && (
              <Modal show={showModal} onHide={handleClose} centered>
                <div className="modalbox">
                  <Modal.Header className="modal-content-hd" closeButton>
                    <Modal.Title className="modal-title-hd">
                      Automatic Demo
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="modalbody">
                    <div className="autoHeading">
                      Screens will auto-change after the set duration
                      <span>(Time in Sec)</span>
                    </div>
                    <div className="inputTime">
                      <input
                        type="number"
                        className="form-control"
                        min="0"
                        max="10"
                        value={tempDuration}
                        onChange={handleDurationChange}
                      />
                    </div>
                  </Modal.Body>
                  <Modal.Footer className="modal-footer-hd">
                    <Button onClick={handleSet} className="setbtn">
                      Set
                    </Button>
                  </Modal.Footer>
                </div>
              </Modal>
            )}
          </header>
          <header className="d-flex">
            <label htmlFor="">
              <span>
                <img src={online} alt="" />
              </span>
              100 {t("Vehicles Online")}
            </label>

            <Dropdown
              className="notification-header"
              id="notification_header_responsive"
            >
              <Dropdown.Toggle
                variant="success"
                id="dropdown-basic"
                className="header-prfl-img"
              >
                <img src={bell} className="bellyIcon" alt="" />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <div className="main-wrapper">
                  <img src={DropDownTopArrow} className="toparrow" alt="" />
                  <div className="stepper"></div>
                  <div className="contain">
                    <img src={HeaderStepper1} alt="" />
                    <div className="body">
                      <p className="heading">Monday, 6th Apirl 2020</p>
                      <p className="sunHeading">Book for General Service</p>
                      <p className="action">COMPLETED</p>
                    </div>
                  </div>
                </div>
                <div className="main-wrapper">
                  <div className="stepper"></div>
                  <div className="contain">
                    <img src={HeaderStepper2} alt="" />
                    <div className="body">
                      <p className="heading">Thursday, 24th October 2021</p>
                      <p className="sunHeading">
                        Vehicle LV 001 has been marked for recall.
                      </p>
                      <p className="dedline">14:07-21/11/2021</p>
                    </div>
                  </div>
                </div>
                <div className="main-wrapper">
                  <div className="stepper2"></div>
                  <div className="contain">
                    <img src={HeaderStepper3} alt="" />
                    <div className="body">
                      <p className="heading">Monday, 13th August 2018</p>
                      <p className="sunHeading">
                        Maintenance Completed, Collect
                      </p>
                      <p className="dedline">14:07-21/11/2021</p>
                    </div>
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>

            <Accordion id="inner_header">
              <Dropdown className="header-dropDown">
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  className="header-prfl-img"
                >
                  <img src={profile} className="bellyIcon" alt="" />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <img src={DropDownTopArrow} className="toparrow" alt="" />
                  <Dropdown.Item>
                    <Link to="/MyProfile">{t("My Profile")}</Link>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Link to="/MyMessages">{t("My Messages")}</Link>
                  </Dropdown.Item>

                  <Accordion.Item eventKey="0">
                    <Accordion.Header>{t("Customer Support")}</Accordion.Header>
                    <Accordion.Body>
                      <Link to="/CustomerSupport">{t("Email")}</Link>
                      <Link to="/MyMessages">{t("Chat")}</Link>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>{t("My Language")}</Accordion.Header>
                    <Accordion.Body>
                      {/* <LangDropDown/> */}
                      <DropdownLanguage />
                    </Accordion.Body>
                  </Accordion.Item>
                  <Dropdown.Item>
                    <Link to="/LockScreen">{t("Lock Screen")}</Link>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Link to="/">{t("Logout")}</Link>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Accordion>
          </header>
          <div
            className={
              sidebar
                ? "cx-sidebar-wrapper "
                : "cx-sidebar-wrapper sidebarActive"
            }
          >
            <aside>
              <div className="outer-main-menu">
                <div className="menus-main">
                  {sidebar ? (
                    <div className="top-logo-big">
                      <div className="left">
                        <Link to="/">
                          <img src={big_Logo} alt="Logo" />
                        </Link>
                      </div>
                    </div>
                  ) : (
                    <div className="top-logo-small">
                      <div className="left ">
                        <Link to="/">
                          <img src={Logo} alt="Logo" />
                        </Link>
                      </div>
                    </div>
                  )}

                  <div className="arrow-sidebar">
                    <Link
                      to="#"
                      className="sidebar-arrows"
                      onClick={() => {
                        handleSidebar();
                        setConfigurationChecker(false);
                        setCommunication(false);
                        setReports(false);
                        setDispatchManagement(false);
                        setPayment(false);
                        setUsers(false);
                        setVehicleExpenses(false);
                        setMasterData(false);
                        setMasterSetting(false);
                        setParkingManagement(false);
                        setReplayPlayback(false);
                      }}
                    >
                      <img src={double_arrow} alt="" />
                    </Link>
                  </div>

                  <div className="sidebar-menu-wrapper">
                    <Accordion>
                      <Accordion.Item eventKey="9" id="single_accordian_item">
                        <Accordion.Header>
                          <div className="main-menus">
                            <div
                              className={
                                Reports ? "menus-items  " : "menus-items"
                              }
                            >
                              <Link
                                to="#"
                                className="icon-menu-name"
                                onMouseOver={() => setReports1(true)}
                                onMouseOut={() => setReports1(false)}
                                onClick={() => {
                                  setCommunication(false);
                                  setReports(!Reports);
                                  setDispatchManagement(false);
                                  setParkingManagement(false);
                                  setReplayPlayback(false);
                                  setPayment(false);
                                  setUsers(false);
                                  setVehicleExpenses(false);
                                  setMasterData(false);
                                  setMasterSetting(false);
                                  isMobile
                                    ? setSidebar(false)
                                    : setSidebar(true);
                                }}
                              >
                                <div className="icon-left">
                                  {Reports === true ||
                                  Reports1 === true ||
                                  currentRoute === "/Reports" ||
                                  currentRoute === "/ScheduleReport" ||
                                  currentRoute === "/GenerateCustomReport" ||
                                  currentRoute === "/ReportView" ? (
                                    <img src={reports_icon_active} alt="" />
                                  ) : (
                                    <img src={reports_icon} alt="" />
                                  )}
                                </div>

                                <div
                                  className={
                                    Reports1 === true ||
                                    Reports === true ||
                                    currentRoute === "/Reports" ||
                                    currentRoute === "/ScheduleReport" ||
                                    currentRoute === "/GenerateCustomReport" ||
                                    currentRoute === "/ReportView"
                                      ? "menu-name activeColorBrown"
                                      : "menu-name"
                                  }
                                >
                                  <Link
                                    to="/Reports"
                                    onClick={() => {
                                      isMobile
                                        ? setSidebar(false)
                                        : setSidebar(true);
                                    }}
                                  >
                                    {t("Reports")}
                                  </Link>
                                </div>
                              </Link>
                            </div>
                          </div>
                        </Accordion.Header>
                      </Accordion.Item>

                      {/* ================> Market Place End <============== */}
                    </Accordion>
                  </div>
                </div>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slidereports;
