// Usama 09-02-2023
import React, { useContext, useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Carousel, Col, Dropdown, Modal, Tab, Tabs } from "react-bootstrap";
import { AppContext } from "../../context/AppContext";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import DDlogo from "../../assets/images/DDlogo.png";
import Export from "../../assets/images/ic-Export.svg";
import Import from "../../assets/images/ic-Import.svg";
import Gcar from "../../assets/images/Green-car-logo.svg";
import option from "../../assets/images/option-three-dot.svg";
import { motion } from "framer-motion";
import Nav from "react-bootstrap/Nav";
import Gbike from "../../assets/images/green-bike-map.svg";
import Rbike from "../../assets/images/red-bike-map.svg";
import Bcar from "../../assets/images/Blue-car-logo.svg";
import Rcar from "../../assets/images/red-car-logo.svg";
import BKcar from "../../assets/images/black-car-logo.svg";
import GRbike from "../../assets/images/Grey-bike-logo.svg";
import GRcar from "../../assets/images/Grey-car-logo.svg";
import Ycar from "../../assets/images/yellow-car-logo.svg";
import Ybike from "../../assets/images/yellow-bike-map.svg";
import YQcar from "../../assets/images/yellow-qution-car.svg";
import YQbike from "../../assets/images/yellow-qution-bike.svg";
import Cat_ye_car from "../../assets/images/Catagiry_yellow_car.svg";
import Cat_ye_bike from "../../assets/images/Catagiry_yellow_bike.svg";
import Feature_CarLock_active from "../../assets/images/Feature_CarLock_active.svg";
import Feature_Fuel_active from "../../assets/images/Feature_Fule_active.svg";
import Feature_Fuel from "../../assets/images/Feature_Fule.svg";
import Feature_temp_active from "../../assets/images/Feature_temp_active.svg";
import Feature_temp from "../../assets/images/Feature_temp1.svg";
import Feature_I_active from "../../assets/images/Feature_i_active.svg";
import Feature_I from "../../assets/images/Feature_I.svg";
import Feature_Echo_active from "../../assets/images/Feature_Echo_active.svg";
import Feature_Echo from "../../assets/images/Feture_echo.svg";
import Feature_Seat_active from "../../assets/images/Feature_Seat_active.svg";
import Feature_Seat from "../../assets/images/Feature_Seat.svg";
import Feature_IVMS_active from "../../assets/images/Feature_IVMS_active.svg";
import Feature_IVMS from "../../assets/images/Feature_IVMS.svg";
import Feature_Card_active from "../../assets/images/Feature_Card_active.svg";
import Feature_Card from "../../assets/images/Feature_Card.svg";
import Feature_Overspeed_active from "../../assets/images/Feature_Overspeed_active.svg";
import Feature_Overspeed from "../../assets/images/Feature_overspeed.svg";
import Feature_Crash_active from "../../assets/images/Feature_Crash_active.svg";
import Feature_Crash from "../../assets/images/Feature_creash.svg";
import Feature_Towing_active from "../../assets/images/Feature_Towing_active.svg";
import Feature_Towing from "../../assets/images/Feature_Towing.svg";
import Feature_Unplag_active from "../../assets/images/Feature_Unplag_active.svg";
import Feature_Unplag from "../../assets/images/Feature_Unplag.svg";
import Feature_Exicess_active from "../../assets/images/Feature_Exicess_active.svg";
import Feature_Exicess from "../../assets/images/Feature_Exicess.svg";
import Feature_CarLock from "../../assets/images/Feature_CarLock.svg";
import Pump from "../../assets/images/Petrol-Pump.svg";
import route from "../../assets/images/Road_Route.svg";
import Seats from "../../assets/images/Seats.svg";
import Repair from "../../assets/images/Repair.svg";
import weight from "../../assets/images/weight.svg";
import EVlogo from "../../assets/images/EV-logo.svg";
import Qustiontracking from "../../assets/images/Qustion-tracking.svg";
import arrow from "../../assets/images/ic_line_arrow_left.svg";
import Grouplogo from "../../assets/images/Group-logo.svg";
import { Link, useNavigate } from "react-router-dom";
import { useNavItem } from "@restart/ui/esm/NavItem";
import idle from "../../assets/images/idle.svg";
import whiteTrash from "../../assets/images/whiteTrash.svg";
import whiteHand from "../../assets/images/whiteHand.svg";
import whiteBox from "../../assets/images/whiteBox.svg";
import whiteBin from "../../assets/images/whiteBin.svg";
import blue_box from "../../assets/images/blue_box.svg";
import green_box from "../../assets/images/green_box.svg";
import Pagenation from "../../sharedComponent/Pagenation";
import MapComponent from "../../sharedComponent/MapComponent";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import ExistButton from "../../sharedComponent/ExistButton";
import PreviousButton from "../../sharedComponent/PreviousButton";
import NextButton from "../../sharedComponent/Nextbutton";

const OfferVehicleMarketPlace = ({ onClick }) => {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [view, setView] = useState(false);
  const [vCars, setVCars] = useState(false);
  const [vBike, setVBike] = useState(false);
  const [state, setState] = useState(false);

  const showView = () => {
    setView(true);
  };
  const hideView = () => {
    setView(false);
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const handleClickPrevious = () => {
    navigate("/VehicleBookingList");
  };
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    navigate("/OfferDriverMarketPlace ");
  };
  //category
  const [category, setCategory] = useState(false);
  const DeleteCategory = () => setShow(false);
  const showCategory = () => setShow(true);
  //group
  const [group, setGroup] = useState(false);
  const DeleteGroup = () => setShow(false);
  const showGroup = () => setShow(true);

  const { sidebar, setSidebar, Dark, setDark, slider, setSlider } =
    useContext(AppContext);

  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  const [Immobilization, setImmobilization] = useState(true);
  const [Immobilization1, setImmobilization1] = useState(true);
  const [Tempr, setTempr] = useState(true);
  const [Fuel, setFuel] = useState(true);
  const [ibtn, setIbtn] = useState(true);
  const [seat, setSeat] = useState(true);
  const [echo, setEcho] = useState(true);
  const [ivms, setIVMS] = useState(true);
  const [card, setCard] = useState(true);
  const [speed, setSpeed] = useState(true);
  const [cresh, setCresh] = useState(true);
  const [exicess, setExicess] = useState(true);
  const [towing, setTowing] = useState(true);
  const [plug, setPlug] = useState(true);

  return (
    <motion.div
      variants={aninations}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.1 }}
      className={sidebar ? "taskMain " : "cx-active taskMain"}
      id="cx-main"
    >
      <div id="cx-wrapper" className="Vehicle_Main">
        <div
          className="Vehcle-main-tabs cx-marketPlace-main"
          id="cx-marketPlace"
        >
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Nav variant="pills" id="newTabMai" className="tob_nav_pills">
              <Nav.Item>
                <Nav.Link eventKey="first">{t("All Vehicles (100)")}</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="second">{t("Offered (50)")}</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="three">
                  {t("Assigned Vehicles (15)")}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="four">{t("Available (35)")}</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Five">{t("Blocked (0)")}</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="first">
                <div className="main-master-wrapper ">
                  <div className="inner-tabs-section overflow-hidden ">
                    <div id="scroll_insideThe_Padding">
                      <div className="all-vehicle-main">
                        <div className="all-vehical-head row vehicle-top-inputs">
                          <div className="input-section-wrapper">
                            <div className="row">
                              <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Vehicle Name,No., Reg. No, IMEI..."
                                />
                              </div>
                              <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Vehicle Category"
                                />
                              </div>
                              <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Vehicle Capacity"
                                />
                              </div>
                              <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                                <select
                                  required
                                  className="form-select"
                                  aria-label="Default select example"
                                  placeholder="Transportation Type"
                                >
                                  <option value="">Transportation Type</option>
                                  <option value="1">One</option>
                                  <option value="2">Two</option>
                                  <option value="3">Three</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="right-export-btn-section-wrapper">
                            <div className="c-pointer me-2">
                              <img src={Export} alt="" />
                            </div>
                            <div className="c-pointer">
                              <img src={Import} alt="" />
                            </div>
                          </div>
                        </div>

                        <div className="yauto" id="arrange-paading">
                          <div className="row main-cards-wrapper gx-3">
                            <div
                              className={
                                sidebar
                                  ? "col-lg-6 col-md-6"
                                  : "col-lg-4 col-md-6"
                              }
                            >
                              <div className={"common-cat-vehical-card-inner"}>
                                <div className="cat-body w-100">
                                  <div
                                    className="head-with-img"
                                    id="head-with-img-switch"
                                  >
                                    <div id="head-with-img">
                                      <div className="left img">
                                        <img src={Cat_ye_car} alt="" />
                                      </div>
                                      <div className="right v-name cat-body-discription">
                                        <label htmlFor="">
                                          {t("Vehicle Name")}
                                        </label>
                                        <p>Volvo Transport</p>
                                      </div>
                                    </div>

                                    <div
                                      className="form-check form-switch"
                                      id="custom_switch"
                                    >
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="Tuesday"
                                      />
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-lg-6 cat-body-discription mt-2">
                                      <label htmlFor="">
                                        {t("Vehicle Number")}
                                      </label>
                                      <p>MH-12-5022</p>
                                    </div>
                                    <div className="col-lg-6 cat-body-discription mt-2">
                                      <label htmlFor="">
                                        {t("Driver Name")}
                                      </label>
                                      <p>Mr. John Doe</p>
                                    </div>
                                    <div className="col-lg-6 cat-body-discription mt-2">
                                      <label htmlFor="">{t("IMEI No.")}</label>
                                      <p>1234567894561230</p>
                                    </div>
                                    <div className="col-lg-6 cat-body-discription mt-2">
                                      <label htmlFor="">
                                        {t("Vehicle Type")}
                                      </label>
                                      <p>Truck</p>
                                    </div>
                                    <div className="col-lg-6 cat-body-discription mt-2">
                                      <label htmlFor="">
                                        {t("Vehicle Capacity")}
                                      </label>
                                      <p>60</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                sidebar
                                  ? "col-lg-6 col-md-6"
                                  : "col-lg-4 col-md-6"
                              }
                            >
                              <div className={"common-cat-vehical-card-inner"}>
                                <div className="cat-body w-100">
                                  <div
                                    className="head-with-img"
                                    id="head-with-img-switch"
                                  >
                                    <div id="head-with-img">
                                      <div className="left img">
                                        <img src={Cat_ye_car} alt="" />
                                      </div>
                                      <div className="right v-name cat-body-discription">
                                        <label htmlFor="">
                                          {t("Vehicle Name")}
                                        </label>
                                        <p>Volvo Transport</p>
                                      </div>
                                    </div>

                                    <div
                                      className="form-check form-switch"
                                      id="custom_switch"
                                    >
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="Tuesday"
                                      />
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-lg-6 cat-body-discription mt-2">
                                      <label htmlFor="">
                                        {t("Vehicle Number")}
                                      </label>
                                      <p>MH-12-5022</p>
                                    </div>
                                    <div className="col-lg-6 cat-body-discription mt-2">
                                      <label htmlFor="">
                                        {t("Driver Name")}
                                      </label>
                                      <p>Mr. John Doe</p>
                                    </div>
                                    <div className="col-lg-6 cat-body-discription mt-2">
                                      <label htmlFor="">{t("IMEI No.")}</label>
                                      <p>1234567894561230</p>
                                    </div>
                                    <div className="col-lg-6 cat-body-discription mt-2">
                                      <label htmlFor="">
                                        {t("Vehicle Type")}
                                      </label>
                                      <p>Truck</p>
                                    </div>
                                    <div className="col-lg-6 cat-body-discription mt-2">
                                      <label htmlFor="">
                                        {t("Vehicle Capacity")}
                                      </label>
                                      <p>60</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                sidebar
                                  ? "col-lg-6 col-md-6"
                                  : "col-lg-4 col-md-6"
                              }
                            >
                              <div className={"common-cat-vehical-card-inner"}>
                                <div className="cat-body w-100">
                                  <div
                                    className="head-with-img"
                                    id="head-with-img-switch"
                                  >
                                    <div id="head-with-img">
                                      <div className="left img">
                                        <img src={Cat_ye_car} alt="" />
                                      </div>
                                      <div className="right v-name cat-body-discription">
                                        <label htmlFor="">
                                          {t("Vehicle Name")}
                                        </label>
                                        <p>Volvo Transport</p>
                                      </div>
                                    </div>

                                    <div
                                      className="form-check form-switch"
                                      id="custom_switch"
                                    >
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="Tuesday"
                                      />
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-lg-6 cat-body-discription mt-2">
                                      <label htmlFor="">
                                        {t("Vehicle Number")}
                                      </label>
                                      <p>MH-12-5022</p>
                                    </div>
                                    <div className="col-lg-6 cat-body-discription mt-2">
                                      <label htmlFor="">
                                        {t("Driver Name")}
                                      </label>
                                      <p>Mr. John Doe</p>
                                    </div>
                                    <div className="col-lg-6 cat-body-discription mt-2">
                                      <label htmlFor="">{t("IMEI No.")}</label>
                                      <p>1234567894561230</p>
                                    </div>
                                    <div className="col-lg-6 cat-body-discription mt-2">
                                      <label htmlFor="">
                                        {t("Vehicle Type")}
                                      </label>
                                      <p>Truck</p>
                                    </div>
                                    <div className="col-lg-6 cat-body-discription mt-2">
                                      <label htmlFor="">
                                        {t("Vehicle Capacity")}
                                      </label>
                                      <p>60</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                sidebar
                                  ? "col-lg-6 col-md-6"
                                  : "col-lg-4 col-md-6"
                              }
                            >
                              <div className={"common-cat-vehical-card-inner"}>
                                <div className="cat-body w-100">
                                  <div
                                    className="head-with-img"
                                    id="head-with-img-switch"
                                  >
                                    <div id="head-with-img">
                                      <div className="left img">
                                        <img src={Cat_ye_car} alt="" />
                                      </div>
                                      <div className="right v-name cat-body-discription">
                                        <label htmlFor="">
                                          {t("Vehicle Name")}
                                        </label>
                                        <p>Volvo Transport</p>
                                      </div>
                                    </div>

                                    <div
                                      className="form-check form-switch"
                                      id="custom_switch"
                                    >
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="Tuesday"
                                      />
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-lg-6 cat-body-discription mt-2">
                                      <label htmlFor="">
                                        {t("Vehicle Number")}
                                      </label>
                                      <p>MH-12-5022</p>
                                    </div>
                                    <div className="col-lg-6 cat-body-discription mt-2">
                                      <label htmlFor="">
                                        {t("Driver Name")}
                                      </label>
                                      <p>Mr. John Doe</p>
                                    </div>
                                    <div className="col-lg-6 cat-body-discription mt-2">
                                      <label htmlFor="">{t("IMEI No.")}</label>
                                      <p>1234567894561230</p>
                                    </div>
                                    <div className="col-lg-6 cat-body-discription mt-2">
                                      <label htmlFor="">
                                        {t("Vehicle Type")}
                                      </label>
                                      <p>Truck</p>
                                    </div>
                                    <div className="col-lg-6 cat-body-discription mt-2">
                                      <label htmlFor="">
                                        {t("Vehicle Capacity")}
                                      </label>
                                      <p>60</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                sidebar
                                  ? "col-lg-6 col-md-6"
                                  : "col-lg-4 col-md-6"
                              }
                            >
                              <div className={"common-cat-vehical-card-inner"}>
                                <div className="cat-body w-100">
                                  <div
                                    className="head-with-img"
                                    id="head-with-img-switch"
                                  >
                                    <div id="head-with-img">
                                      <div className="left img">
                                        <img src={Cat_ye_car} alt="" />
                                      </div>
                                      <div className="right v-name cat-body-discription">
                                        <label htmlFor="">
                                          {t("Vehicle Name")}
                                        </label>
                                        <p>Volvo Transport</p>
                                      </div>
                                    </div>

                                    <div
                                      className="form-check form-switch"
                                      id="custom_switch"
                                    >
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="Tuesday"
                                      />
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-lg-6 cat-body-discription mt-2">
                                      <label htmlFor="">
                                        {t("Vehicle Number")}
                                      </label>
                                      <p>MH-12-5022</p>
                                    </div>
                                    <div className="col-lg-6 cat-body-discription mt-2">
                                      <label htmlFor="">
                                        {t("Driver Name")}
                                      </label>
                                      <p>Mr. John Doe</p>
                                    </div>
                                    <div className="col-lg-6 cat-body-discription mt-2">
                                      <label htmlFor="">{t("IMEI No.")}</label>
                                      <p>1234567894561230</p>
                                    </div>
                                    <div className="col-lg-6 cat-body-discription mt-2">
                                      <label htmlFor="">
                                        {t("Vehicle Type")}
                                      </label>
                                      <p>Truck</p>
                                    </div>
                                    <div className="col-lg-6 cat-body-discription mt-2">
                                      <label htmlFor="">
                                        {t("Vehicle Capacity")}
                                      </label>
                                      <p>60</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="reg-color">
                    {t("Showing")} 1 - 10 {t("of")} 200
                  </p>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <div className="main-master-wrapper mb-0 inner-tabs-section overflow-hidden">
                  <div id="scroll_insideThe_Padding">
                    <div className="all-vehicle-main">
                      <div className="all-vehical-head row vehicle-top-inputs">
                        <div className="input-section-wrapper">
                          <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Vehicle Name,No., Reg. No, IMEI..."
                              />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Vehicle Category"
                              />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Vehicle Capacity"
                              />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                              <select
                                required
                                className="form-select"
                                aria-label="Default select example"
                                placeholder="Transportation Type"
                              >
                                <option value="">Transportation Type</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="right-export-btn-section-wrapper">
                          <div className="c-pointer me-2">
                            <img src={Export} alt="" />
                          </div>
                          <div className="c-pointer">
                            <img src={Import} alt="" />
                          </div>
                        </div>
                      </div>

                      <div className="yauto" id="arrange-paading">
                        <div className="row main-cards-wrapper gx-3">
                          <div
                            className={
                              sidebar
                                ? "col-lg-6 col-md-6"
                                : "col-lg-4 col-md-6"
                            }
                          >
                            <div className={"common-cat-vehical-card-inner"}>
                              <div className="cat-body w-100">
                                <div className="head-with-img">
                                  <div className="left img">
                                    <img src={Cat_ye_car} alt="" />
                                  </div>
                                  <div className="right v-name cat-body-discription">
                                    <label htmlFor="">
                                      {t("Vehicle Name")}
                                    </label>
                                    <p>Volvo Transport</p>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Number")}
                                    </label>
                                    <p>MH-12-5022</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("Driver Name")}</label>
                                    <p>Mr. John Doe</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("IMEI No.")}</label>
                                    <p>1234567894561230</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Type")}
                                    </label>
                                    <p>Truck</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Capacity")}
                                    </label>
                                    <p>60</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className={
                              sidebar
                                ? "col-lg-6 col-md-6"
                                : "col-lg-4 col-md-6"
                            }
                          >
                            <div className={"common-cat-vehical-card-inner"}>
                              <div className="cat-body w-100">
                                <div className="head-with-img">
                                  <div className="left img">
                                    <img src={Cat_ye_car} alt="" />
                                  </div>
                                  <div className="right v-name cat-body-discription">
                                    <label htmlFor="">
                                      {t("Vehicle Name")}
                                    </label>
                                    <p>Volvo Transport</p>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Number")}
                                    </label>
                                    <p>MH-12-5022</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("Driver Name")}</label>
                                    <p>Mr. John Doe</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("IMEI No.")}</label>
                                    <p>1234567894561230</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Type")}
                                    </label>
                                    <p>Truck</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Capacity")}
                                    </label>
                                    <p>60</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>{" "}
                          <div
                            className={
                              sidebar
                                ? "col-lg-6 col-md-6"
                                : "col-lg-4 col-md-6"
                            }
                          >
                            <div className={"common-cat-vehical-card-inner"}>
                              <div className="cat-body w-100">
                                <div className="head-with-img">
                                  <div className="left img">
                                    <img src={Cat_ye_car} alt="" />
                                  </div>
                                  <div className="right v-name cat-body-discription">
                                    <label htmlFor="">
                                      {t("Vehicle Name")}
                                    </label>
                                    <p>Volvo Transport</p>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Number")}
                                    </label>
                                    <p>MH-12-5022</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("Driver Name")}</label>
                                    <p>Mr. John Doe</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("IMEI No.")}</label>
                                    <p>1234567894561230</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Type")}
                                    </label>
                                    <p>Truck</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Capacity")}
                                    </label>
                                    <p>60</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>{" "}
                          <div
                            className={
                              sidebar
                                ? "col-lg-6 col-md-6"
                                : "col-lg-4 col-md-6"
                            }
                          >
                            <div className={"common-cat-vehical-card-inner"}>
                              <div className="cat-body w-100">
                                <div className="head-with-img">
                                  <div className="left img">
                                    <img src={Cat_ye_car} alt="" />
                                  </div>
                                  <div className="right v-name cat-body-discription">
                                    <label htmlFor="">
                                      {t("Vehicle Name")}
                                    </label>
                                    <p>Volvo Transport</p>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Number")}
                                    </label>
                                    <p>MH-12-5022</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("Driver Name")}</label>
                                    <p>Mr. John Doe</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("IMEI No.")}</label>
                                    <p>1234567894561230</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Type")}
                                    </label>
                                    <p>Truck</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Capacity")}
                                    </label>
                                    <p>60</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>{" "}
                          <div
                            className={
                              sidebar
                                ? "col-lg-6 col-md-6"
                                : "col-lg-4 col-md-6"
                            }
                          >
                            <div className={"common-cat-vehical-card-inner"}>
                              <div className="cat-body w-100">
                                <div className="head-with-img">
                                  <div className="left img">
                                    <img src={Cat_ye_car} alt="" />
                                  </div>
                                  <div className="right v-name cat-body-discription">
                                    <label htmlFor="">
                                      {t("Vehicle Name")}
                                    </label>
                                    <p>Volvo Transport</p>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Number")}
                                    </label>
                                    <p>MH-12-5022</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("Driver Name")}</label>
                                    <p>Mr. John Doe</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("IMEI No.")}</label>
                                    <p>1234567894561230</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Type")}
                                    </label>
                                    <p>Truck</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Capacity")}
                                    </label>
                                    <p>60</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="reg-color mt-3">
                    {t("Showing")} 1 - 10 {t("of")} 200
                  </p>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="three">
                <div className="main-master-wrapper mb-0 inner-tabs-section overflow-hidden">
                  <div id="scroll_insideThe_Padding">
                    <div className="all-vehicle-main">
                      <div className="all-vehical-head row vehicle-top-inputs">
                        <div className="input-section-wrapper">
                          <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Vehicle Name,No., Reg. No, IMEI..."
                              />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Vehicle Category"
                              />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Vehicle Capacity"
                              />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                              <select
                                required
                                className="form-select"
                                aria-label="Default select example"
                                placeholder="Transportation Type"
                              >
                                <option value="">Transportation Type</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="right-export-btn-section-wrapper">
                          <div className="c-pointer me-2">
                            <img src={Export} alt="" />
                          </div>
                          <div className="c-pointer">
                            <img src={Import} alt="" />
                          </div>
                        </div>
                      </div>

                      <div className="yauto" id="arrange-paading">
                        <div className="row main-cards-wrapper gx-3">
                          <div
                            className={
                              sidebar
                                ? "col-lg-6 col-md-6"
                                : "col-lg-4 col-md-6"
                            }
                          >
                            <div className={"common-cat-vehical-card-inner"}>
                              <div className="cat-body w-100">
                                <div className="head-with-img">
                                  <div className="left img">
                                    <img src={Cat_ye_car} alt="" />
                                  </div>
                                  <div className="right v-name cat-body-discription">
                                    <label htmlFor="">
                                      {t("Vehicle Name")}
                                    </label>
                                    <p>Volvo Transport</p>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Number")}
                                    </label>
                                    <p>MH-12-5022</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("Driver Name")}</label>
                                    <p>Mr. John Doe</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("IMEI No.")}</label>
                                    <p>1234567894561230</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Type")}
                                    </label>
                                    <p>Truck</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Capacity")}
                                    </label>
                                    <p>60</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className={
                              sidebar
                                ? "col-lg-6 col-md-6"
                                : "col-lg-4 col-md-6"
                            }
                          >
                            <div className={"common-cat-vehical-card-inner"}>
                              <div className="cat-body w-100">
                                <div className="head-with-img">
                                  <div className="left img">
                                    <img src={Cat_ye_car} alt="" />
                                  </div>
                                  <div className="right v-name cat-body-discription">
                                    <label htmlFor="">
                                      {t("Vehicle Name")}
                                    </label>
                                    <p>Volvo Transport</p>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Number")}
                                    </label>
                                    <p>MH-12-5022</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("Driver Name")}</label>
                                    <p>Mr. John Doe</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("IMEI No.")}</label>
                                    <p>1234567894561230</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Type")}
                                    </label>
                                    <p>Truck</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Capacity")}
                                    </label>
                                    <p>60</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>{" "}
                          <div
                            className={
                              sidebar
                                ? "col-lg-6 col-md-6"
                                : "col-lg-4 col-md-6"
                            }
                          >
                            <div className={"common-cat-vehical-card-inner"}>
                              <div className="cat-body w-100">
                                <div className="head-with-img">
                                  <div className="left img">
                                    <img src={Cat_ye_car} alt="" />
                                  </div>
                                  <div className="right v-name cat-body-discription">
                                    <label htmlFor="">
                                      {t("Vehicle Name")}
                                    </label>
                                    <p>Volvo Transport</p>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Number")}
                                    </label>
                                    <p>MH-12-5022</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("Driver Name")}</label>
                                    <p>Mr. John Doe</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("IMEI No.")}</label>
                                    <p>1234567894561230</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Type")}
                                    </label>
                                    <p>Truck</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Capacity")}
                                    </label>
                                    <p>60</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>{" "}
                          <div
                            className={
                              sidebar
                                ? "col-lg-6 col-md-6"
                                : "col-lg-4 col-md-6"
                            }
                          >
                            <div className={"common-cat-vehical-card-inner"}>
                              <div className="cat-body w-100">
                                <div className="head-with-img">
                                  <div className="left img">
                                    <img src={Cat_ye_car} alt="" />
                                  </div>
                                  <div className="right v-name cat-body-discription">
                                    <label htmlFor="">
                                      {t("Vehicle Name")}
                                    </label>
                                    <p>Volvo Transport</p>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Number")}
                                    </label>
                                    <p>MH-12-5022</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("Driver Name")}</label>
                                    <p>Mr. John Doe</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("IMEI No.")}</label>
                                    <p>1234567894561230</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Type")}
                                    </label>
                                    <p>Truck</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Capacity")}
                                    </label>
                                    <p>60</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>{" "}
                          <div
                            className={
                              sidebar
                                ? "col-lg-6 col-md-6"
                                : "col-lg-4 col-md-6"
                            }
                          >
                            <div className={"common-cat-vehical-card-inner"}>
                              <div className="cat-body w-100">
                                <div className="head-with-img">
                                  <div className="left img">
                                    <img src={Cat_ye_car} alt="" />
                                  </div>
                                  <div className="right v-name cat-body-discription">
                                    <label htmlFor="">
                                      {t("Vehicle Name")}
                                    </label>
                                    <p>Volvo Transport</p>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Number")}
                                    </label>
                                    <p>MH-12-5022</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("Driver Name")}</label>
                                    <p>Mr. John Doe</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("IMEI No.")}</label>
                                    <p>1234567894561230</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Type")}
                                    </label>
                                    <p>Truck</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Capacity")}
                                    </label>
                                    <p>60</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="reg-color mt-3">
                    {t("Showing")} 1 - 10 {t("of")} 200
                  </p>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="four">
                <div className="main-master-wrapper mb-0 inner-tabs-section overflow-hidden">
                  <div id="scroll_insideThe_Padding">
                    <div className="all-vehicle-main">
                      <div className="all-vehical-head row vehicle-top-inputs">
                        <div className="input-section-wrapper">
                          <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Vehicle Name,No., Reg. No, IMEI..."
                              />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Vehicle Category"
                              />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Vehicle Capacity"
                              />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                              <select
                                required
                                className="form-select"
                                aria-label="Default select example"
                                placeholder="Transportation Type"
                              >
                                <option value="">Transportation Type</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="right-export-btn-section-wrapper">
                          <div className="c-pointer me-2">
                            <img src={Export} alt="" />
                          </div>
                          <div className="c-pointer">
                            <img src={Import} alt="" />
                          </div>
                        </div>
                      </div>

                      <div className="yauto" id="arrange-paading">
                        <div className="row main-cards-wrapper gx-3">
                          <div
                            className={
                              sidebar
                                ? "col-lg-6 col-md-6"
                                : "col-lg-4 col-md-6"
                            }
                          >
                            <div className={"common-cat-vehical-card-inner"}>
                              <div className="cat-body w-100">
                                <div className="head-with-img">
                                  <div className="left img">
                                    <img src={Cat_ye_car} alt="" />
                                  </div>
                                  <div className="right v-name cat-body-discription">
                                    <label htmlFor="">
                                      {t("Vehicle Name")}
                                    </label>
                                    <p>Volvo Transport</p>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Number")}
                                    </label>
                                    <p>MH-12-5022</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("Driver Name")}</label>
                                    <p>Mr. John Doe</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("IMEI No.")}</label>
                                    <p>1234567894561230</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Type")}
                                    </label>
                                    <p>Truck</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Capacity")}
                                    </label>
                                    <p>60</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className={
                              sidebar
                                ? "col-lg-6 col-md-6"
                                : "col-lg-4 col-md-6"
                            }
                          >
                            <div className={"common-cat-vehical-card-inner"}>
                              <div className="cat-body w-100">
                                <div className="head-with-img">
                                  <div className="left img">
                                    <img src={Cat_ye_car} alt="" />
                                  </div>
                                  <div className="right v-name cat-body-discription">
                                    <label htmlFor="">
                                      {t("Vehicle Name")}
                                    </label>
                                    <p>Volvo Transport</p>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Number")}
                                    </label>
                                    <p>MH-12-5022</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("Driver Name")}</label>
                                    <p>Mr. John Doe</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("IMEI No.")}</label>
                                    <p>1234567894561230</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Type")}
                                    </label>
                                    <p>Truck</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Capacity")}
                                    </label>
                                    <p>60</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>{" "}
                          <div
                            className={
                              sidebar
                                ? "col-lg-6 col-md-6"
                                : "col-lg-4 col-md-6"
                            }
                          >
                            <div className={"common-cat-vehical-card-inner"}>
                              <div className="cat-body w-100">
                                <div className="head-with-img">
                                  <div className="left img">
                                    <img src={Cat_ye_car} alt="" />
                                  </div>
                                  <div className="right v-name cat-body-discription">
                                    <label htmlFor="">
                                      {t("Vehicle Name")}
                                    </label>
                                    <p>Volvo Transport</p>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Number")}
                                    </label>
                                    <p>MH-12-5022</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("Driver Name")}</label>
                                    <p>Mr. John Doe</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("IMEI No.")}</label>
                                    <p>1234567894561230</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Type")}
                                    </label>
                                    <p>Truck</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Capacity")}
                                    </label>
                                    <p>60</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>{" "}
                          <div
                            className={
                              sidebar
                                ? "col-lg-6 col-md-6"
                                : "col-lg-4 col-md-6"
                            }
                          >
                            <div className={"common-cat-vehical-card-inner"}>
                              <div className="cat-body w-100">
                                <div className="head-with-img">
                                  <div className="left img">
                                    <img src={Cat_ye_car} alt="" />
                                  </div>
                                  <div className="right v-name cat-body-discription">
                                    <label htmlFor="">
                                      {t("Vehicle Name")}
                                    </label>
                                    <p>Volvo Transport</p>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Number")}
                                    </label>
                                    <p>MH-12-5022</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("Driver Name")}</label>
                                    <p>Mr. John Doe</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("IMEI No.")}</label>
                                    <p>1234567894561230</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Type")}
                                    </label>
                                    <p>Truck</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Capacity")}
                                    </label>
                                    <p>60</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>{" "}
                          <div
                            className={
                              sidebar
                                ? "col-lg-6 col-md-6"
                                : "col-lg-4 col-md-6"
                            }
                          >
                            <div className={"common-cat-vehical-card-inner"}>
                              <div className="cat-body w-100">
                                <div className="head-with-img">
                                  <div className="left img">
                                    <img src={Cat_ye_car} alt="" />
                                  </div>
                                  <div className="right v-name cat-body-discription">
                                    <label htmlFor="">
                                      {t("Vehicle Name")}
                                    </label>
                                    <p>Volvo Transport</p>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Number")}
                                    </label>
                                    <p>MH-12-5022</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("Driver Name")}</label>
                                    <p>Mr. John Doe</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("IMEI No.")}</label>
                                    <p>1234567894561230</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Type")}
                                    </label>
                                    <p>Truck</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Capacity")}
                                    </label>
                                    <p>60</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="reg-color mt-3">
                    {t("Showing")} 1 - 10 {t("of")} 200
                  </p>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="Five">
                <div className="main-master-wrapper mb-0 inner-tabs-section overflow-hidden">
                  <div id="scroll_insideThe_Padding">
                    <div className="all-vehicle-main">
                      <div className="all-vehical-head row vehicle-top-inputs">
                        <div className="input-section-wrapper">
                          <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Vehicle Name,No., Reg. No, IMEI..."
                              />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Vehicle Category"
                              />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Vehicle Capacity"
                              />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                              <select
                                required
                                className="form-select"
                                aria-label="Default select example"
                                placeholder="Transportation Type"
                              >
                                <option value="">Transportation Type</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="right-export-btn-section-wrapper">
                          <div className="c-pointer me-2">
                            <img src={Export} alt="" />
                          </div>
                          <div className="c-pointer">
                            <img src={Import} alt="" />
                          </div>
                        </div>
                      </div>

                      <div className="yauto" id="arrange-paading">
                        <div className="row main-cards-wrapper gx-3">
                          <div
                            className={
                              sidebar
                                ? "col-lg-6 col-md-6"
                                : "col-lg-4 col-md-6"
                            }
                          >
                            <div className={"common-cat-vehical-card-inner"}>
                              <div className="cat-body w-100">
                                <div className="head-with-img">
                                  <div className="left img">
                                    <img src={Cat_ye_car} alt="" />
                                  </div>
                                  <div className="right v-name cat-body-discription">
                                    <label htmlFor="">
                                      {t("Vehicle Name")}
                                    </label>
                                    <p>Volvo Transport</p>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Number")}
                                    </label>
                                    <p>MH-12-5022</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("Driver Name")}</label>
                                    <p>Mr. John Doe</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("IMEI No.")}</label>
                                    <p>1234567894561230</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Type")}
                                    </label>
                                    <p>Truck</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Capacity")}
                                    </label>
                                    <p>60</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className={
                              sidebar
                                ? "col-lg-6 col-md-6"
                                : "col-lg-4 col-md-6"
                            }
                          >
                            <div className={"common-cat-vehical-card-inner"}>
                              <div className="cat-body w-100">
                                <div className="head-with-img">
                                  <div className="left img">
                                    <img src={Cat_ye_car} alt="" />
                                  </div>
                                  <div className="right v-name cat-body-discription">
                                    <label htmlFor="">
                                      {t("Vehicle Name")}
                                    </label>
                                    <p>Volvo Transport</p>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Number")}
                                    </label>
                                    <p>MH-12-5022</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("Driver Name")}</label>
                                    <p>Mr. John Doe</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("IMEI No.")}</label>
                                    <p>1234567894561230</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Type")}
                                    </label>
                                    <p>Truck</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Capacity")}
                                    </label>
                                    <p>60</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>{" "}
                          <div
                            className={
                              sidebar
                                ? "col-lg-6 col-md-6"
                                : "col-lg-4 col-md-6"
                            }
                          >
                            <div className={"common-cat-vehical-card-inner"}>
                              <div className="cat-body w-100">
                                <div className="head-with-img">
                                  <div className="left img">
                                    <img src={Cat_ye_car} alt="" />
                                  </div>
                                  <div className="right v-name cat-body-discription">
                                    <label htmlFor="">
                                      {t("Vehicle Name")}
                                    </label>
                                    <p>Volvo Transport</p>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Number")}
                                    </label>
                                    <p>MH-12-5022</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("Driver Name")}</label>
                                    <p>Mr. John Doe</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("IMEI No.")}</label>
                                    <p>1234567894561230</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Type")}
                                    </label>
                                    <p>Truck</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Capacity")}
                                    </label>
                                    <p>60</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>{" "}
                          <div
                            className={
                              sidebar
                                ? "col-lg-6 col-md-6"
                                : "col-lg-4 col-md-6"
                            }
                          >
                            <div className={"common-cat-vehical-card-inner"}>
                              <div className="cat-body w-100">
                                <div className="head-with-img">
                                  <div className="left img">
                                    <img src={Cat_ye_car} alt="" />
                                  </div>
                                  <div className="right v-name cat-body-discription">
                                    <label htmlFor="">
                                      {t("Vehicle Name")}
                                    </label>
                                    <p>Volvo Transport</p>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Number")}
                                    </label>
                                    <p>MH-12-5022</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("Driver Name")}</label>
                                    <p>Mr. John Doe</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("IMEI No.")}</label>
                                    <p>1234567894561230</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Type")}
                                    </label>
                                    <p>Truck</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Capacity")}
                                    </label>
                                    <p>60</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>{" "}
                          <div
                            className={
                              sidebar
                                ? "col-lg-6 col-md-6"
                                : "col-lg-4 col-md-6"
                            }
                          >
                            <div className={"common-cat-vehical-card-inner"}>
                              <div className="cat-body w-100">
                                <div className="head-with-img">
                                  <div className="left img">
                                    <img src={Cat_ye_car} alt="" />
                                  </div>
                                  <div className="right v-name cat-body-discription">
                                    <label htmlFor="">
                                      {t("Vehicle Name")}
                                    </label>
                                    <p>Volvo Transport</p>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Number")}
                                    </label>
                                    <p>MH-12-5022</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("Driver Name")}</label>
                                    <p>Mr. John Doe</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("IMEI No.")}</label>
                                    <p>1234567894561230</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Type")}
                                    </label>
                                    <p>Truck</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Capacity")}
                                    </label>
                                    <p>60</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="reg-color mt-3">
                    {t("Showing")} 1 - 10 {t("of")} 200
                  </p>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>

          <Modal
            show={show}
            onHide={handleClose}
            centered
            className="common-model"
          >
            <Modal.Header closeButton>
              <Modal.Title>{t("Delete Vehicle")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {t("Are you sure you want to delete this vehicle")} ?
            </Modal.Body>
            <Modal.Footer className="pop-up-modal-footer btn-wrapper">
              <button className="cx-btn-1" onClick={handleClose}>
                {t("Close")}
              </button>
              <button className="cx-btn-2" onClick={handleClose}>
                {t("Yes")}
              </button>
            </Modal.Footer>
          </Modal>
        </div>
        {slider ? <ExistButton onClose={() => setSlider(false)} /> : null}
        {/* <ExistButton onClose={() => setSlider(false)} /> */}
        {!slider && <PreviousButton onBack={handleClickPrevious} />}
        {!slider && <NextButton onClick={handleClick} />}
      </div>
    </motion.div>
  );
};

export default OfferVehicleMarketPlace;
