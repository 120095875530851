import { React, useContext, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import ShareImp from "../../../assets/images/whiteTrash.svg";
import Box from "../../../assets/images/whiteBox.svg";
import hand from "../../../assets/images/whiteHand.svg";
import Bin from "../../../assets/images/whiteBin.svg";
import Red from "../../../assets/images/redHotspot.svg";
import popimg from "../../../assets/images/pop-img.png";
import Yellow from "../../../assets/images/yellowHotspot.svg";
import { Link, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import DatePlus from "../../../assets/images/Group 33597.svg";
import { motion } from "framer-motion";
import { Space, TimePicker } from "antd";
import { Tooltip } from "react-bootstrap";
import MapComponent from "../../../sharedComponent/MapComponent";
import { useTranslation } from "react-i18next";
import PreviousButton from "../../../sharedComponent/PreviousButton";
import NextButton from "../../../sharedComponent/Nextbutton";
import ExistButton from "../../../sharedComponent/ExistButton";

const ManualRouting = ({ onClick }) => {
  const { sidebar, setSidebar, Dark, setDark, slider, setSlider } =
    useContext(AppContext);
  const [startDate, setStartDate] = useState(new Date());
  const { t, i18n } = useTranslation();

  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  const renderTooltipForPoligon = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {t("Draw Polygon")}
    </Tooltip>
  );
  const renderTooltipForRectangle = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {t("Draw Rectangle")}
    </Tooltip>
  );
  const renderTooltipForPalm = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {t("Move")}
    </Tooltip>
  );
  const renderTooltipForDelet = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {t("Delete")}
    </Tooltip>
  );
  const navigate = useNavigate();
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    navigate("/DeliveryRequestone");
  };
  const handleClickPrevious = () => {
    navigate("/DispatchOrder");
  };
  return (
    <>
      <motion.div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }}
      >
        <div id="cx-wrapper" className="Manual_Routing">
          <div className="manualROuting">
            <div className="mainMape" id="mapManualRouting">
              <MapComponent />
              {/* <div className="headingIcons">
                  <div className="backColoricon">
                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderTooltipForPoligon}
                    >
                      <Link to="#">
                        <div className="inconsIn">
                          <img src={ShareImp} alt="" />
                        </div>
                      </Link>
                    </OverlayTrigger>
                  </div>
                  <div className="backColoricon">
                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderTooltipForRectangle}
                    >
                      <Link to="#">
                        <div className="inconsIn">
                          <img src={Box} alt="" />
                        </div>
                      </Link>
                    </OverlayTrigger>
                  </div>
                  <div className="backColoricon">
                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderTooltipForPalm}
                    >
                      <Link to="#">
                        <div className="inconsIn">
                          <img src={hand} alt="" />
                        </div>
                      </Link>
                    </OverlayTrigger>
                  </div>
                  <div className="backColoricon">
                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderTooltipForDelet}
                    >
                      <Link to="#">
                        <div className="inconsIn">
                          <img src={Bin} alt="" />
                        </div>
                      </Link>
                    </OverlayTrigger>
                  </div>
                </div> */}
              <div className="firstHotspot" id="firstHotspot">
                <OverlayTrigger
                  trigger="click"
                  key="bottom"
                  placement="bottom"
                  className="firstpopUp"
                  overlay={
                    <Popover
                      id="popover-positioned-bottom"
                      className="popover-main-wrapperHot"
                      style={{ width: "600px !important" }}
                    >
                      <Popover.Body className="pm-body">
                        <div className="headingHot">
                          <p>{t("Create Trip")}</p>
                        </div>
                        <div className="row">
                          <div className="col-md-12 form_input_main mt-3">
                            <div className="innerSelectBox weekCounter">
                              <Form.Label className="common-labels">
                                {t("Vehicle")}
                              </Form.Label>
                              <Form.Select
                                required
                                as="select"
                                type="select"
                                name="Speed_limit"
                              >
                                <option value="">Mr. John Doe</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="150">150</option>
                              </Form.Select>
                            </div>
                          </div>
                          <div className="col-md-12 col-lg-6 form_input_main">
                            <div className="innerSelectBox weekCounter">
                              <Form.Label className="common-labels">
                                {t("Start Time")}
                              </Form.Label>
                              <Space>
                                <TimePicker
                                  className="form-control carretClass"
                                  placeholder="Start Time"
                                  size="large"
                                />
                              </Space>
                            </div>
                          </div>
                          <div className="col-md-12 col-lg-6 form_input_main">
                            <div className="innerSelectBox weekCounter">
                              <Form.Label className="common-labels">
                                {t("End Time")}
                              </Form.Label>
                              <Space>
                                <TimePicker
                                  className="form-control carretClass"
                                  placeholder="End Time"
                                  size="large"
                                />
                              </Space>
                            </div>
                          </div>
                        </div>
                        <div className="popupRow"></div>
                        {/* Two Bottom Buttons */}
                        <div className="d-flex justify-content-end align-items-center belowBtns btn-wrapper">
                          <button className="cx-btn-1">{t("Cancel")}</button>
                          <button className="cx-btn-2">
                            {t("Create Trip")}
                          </button>
                        </div>
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <Button className="hotSpotBtn">
                    <img src={Red} className="RedHot1" alt="" />
                  </Button>
                </OverlayTrigger>
              </div>
              <div className="hotspotImgs">
                <img src={Red} className="RedHot2" alt="" />
                <img src={Red} className="RedHot3" alt="" />
                <img src={Red} className="RedHot4" alt="" />
                <img src={Yellow} className="YellowHot1" alt="" />
                <img src={Yellow} className="YellowHot2" alt="" />
                <img src={Yellow} className="YellowHot3" alt="" />
                <img src={Yellow} className="YellowHot4" alt="" />
              </div>
            </div>
          </div>
          {slider ? <ExistButton onClose={() => setSlider(false)} /> : null}
          {!slider && <PreviousButton onBack={handleClickPrevious} />}
          {!slider && <NextButton onClick={handleClick} />}
        </div>
      </motion.div>
    </>
  );
};

export default ManualRouting;
