import React, { useContext, useState } from "react";
import { Form, Modal, Tab, Tabs } from "react-bootstrap";
import { AppContext } from "../../context/AppContext";
import Gcustomer from "../../assets/images/comp_icon.svg";
import untracked from "../../assets/images/start_icon.svg";
import idle from "../../assets/images/end_icon.svg";
import car from "../../assets/images/Catagiry_yellow_car.svg";
import driver_img from "../../assets/images/Customer-profile.png";
import { Link } from "react-router-dom";
import Chart from "react-apexcharts";
import error_icon from "../../assets/images/error_icon.svg";
import { Dropdown } from "react-bootstrap";
import option from "../../assets/images/option-three-dot.svg";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import step1 from "../../assets/images/step1.svg";
import step2 from "../../assets/images/step2.svg";
import laststep from "../../assets/images/laststep.svg";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { motion } from "framer-motion";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import MapComponent from "../../sharedComponent/MapComponent";
import { useTranslation } from "react-i18next";

const ViewDispatchTripnew = () => {
  const { sidebar, setSidebar, Dark, setDark } = useContext(AppContext);
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  const { t, i18n } = useTranslation();

  const [state, setState] = useState({
    series: [
      {
        name: "series1",
        data: [0, 30, 50, 90, 60, 70, 30, 50],
      },
    ],
    options: {
      chart: {
        height: 440,
        type: "area",
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#FF7800"],
      fill: {
        type: "gradient",
        gradient: {
          shade: "#8F430080",
          type: "vertical",
          shadeIntensity: 0.5,
          // gradientToColors: "#FF7800",
          opacityFrom: 0.8,
          opacityTo: 0.1,
          stops: [0, 90, 100],
          colorStops: [],
        },
      },
      stroke: {
        curve: "smooth",
        width: 2,
        colors: ["#8F430080"],
      },
      xaxis: {
        type: "time",
        categories: [
          "7 am",
          "9 am",
          "11 am",
          "1 pm",
          "3 pm",
          "5 pm",
          "7 pm",
          "9 pm",
        ],
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
    },
  });
  return (
    <motion.div
      className={sidebar ? "taskMain " : "cx-active taskMain"}
      id="cx-main"
      variants={aninations}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.1 }}
    >
      <div id="cx-wrapper">
        <div className="main-master-wrapper form_input_main">
          <div className="trip-details-wrapper ">
            <p>{t("Trip Details")}</p>
            <Link to="#">
              <button className="end-trip-btn">{t("End Trip")}</button>
            </Link>
          </div>
          <div className="trip-details-timeline">
            <div className="row">
              <div className="col-md-2">
                <div className="td-left-section">
                  <label htmlFor="">{t("Pick-Up Location")}</label>
                  <p>Chala, Thiruvananthapuram - 695001, Kerala, India.</p>
                  <p>{t("Start Date & Time")}: 22-01-2023, 04:04:58 AM</p>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="td-middle-section">
                  <div class="horizontal timeline">
                    <div class="steps">
                      <div class="step">
                        <OverlayTrigger
                          rootClose={true}
                          trigger="hover"
                          key="bottom"
                          placement="bottom"
                          overlay={
                            <Popover
                              id="popover-positioned-bottom"
                              className="trip-popover"
                              style={{ width: "600px !important" }}
                            >
                              <Popover.Body className="tp-body">
                                <div className="trip-popover-wrapper">
                                  Package Arrived at XYZ.
                                </div>
                              </Popover.Body>
                            </Popover>
                          }
                        >
                          <img
                            src={step1}
                            alt=""
                            className=""
                            onClick={() => {}}
                          />
                        </OverlayTrigger>
                      </div>
                      <div class="step">
                        <OverlayTrigger
                          rootClose={true}
                          trigger="hover"
                          key="top"
                          placement="top"
                          overlay={
                            <Popover
                              id="popover-positioned-bottom"
                              className="trip-popover"
                              style={{ width: "600px !important" }}
                            >
                              <Popover.Body className="tp-body">
                                <div className="trip-popover-wrapper">
                                  {t("Package Arrived")} at XYZ.
                                </div>
                              </Popover.Body>
                            </Popover>
                          }
                        >
                          <img
                            src={step2}
                            alt=""
                            className=""
                            onClick={() => {}}
                          />
                        </OverlayTrigger>
                      </div>
                      <div class="step current">
                        <OverlayTrigger
                          rootClose={true}
                          trigger="hover"
                          key="top"
                          placement="top"
                          overlay={
                            <Popover
                              id="popover-positioned-bottom"
                              className="trip-popover"
                              style={{ width: "600px !important" }}
                            >
                              <Popover.Body className="tp-body">
                                <div className="trip-popover-wrapper">
                                  {t("Package Arrived")} at XYZ.
                                </div>
                              </Popover.Body>
                            </Popover>
                          }
                        >
                          <img
                            src={step2}
                            alt=""
                            className=""
                            onClick={() => {}}
                          />
                        </OverlayTrigger>
                      </div>
                      <div class="step">
                        <OverlayTrigger
                          rootClose={true}
                          trigger="hover"
                          key="top"
                          placement="top"
                          overlay={
                            <Popover
                              id="popover-positioned-bottom"
                              className="trip-popover"
                              style={{ width: "600px !important" }}
                            >
                              <Popover.Body className="tp-body">
                                <div className="trip-popover-wrapper">
                                  {t("Package Arrived")} at XYZ.
                                </div>
                              </Popover.Body>
                            </Popover>
                          }
                        >
                          <img
                            src={step2}
                            alt=""
                            className=""
                            onClick={() => {}}
                          />
                        </OverlayTrigger>
                      </div>
                      <div class="step">
                        <OverlayTrigger
                          rootClose={true}
                          trigger="hover"
                          key="bottom"
                          placement="bottom"
                          overlay={
                            <Popover
                              id="popover-positioned-bottom"
                              className="trip-popover"
                              style={{ width: "600px !important" }}
                            >
                              <Popover.Body className="tp-body">
                                <div className="trip-popover-wrapper">
                                  {t("Package Arrived")} at XYZ.
                                </div>
                              </Popover.Body>
                            </Popover>
                          }
                        >
                          <img
                            src={laststep}
                            alt=""
                            className=""
                            onClick={() => {}}
                          />
                        </OverlayTrigger>
                      </div>
                    </div>

                    <div class="line"></div>
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <div className="td-right-section">
                  <label htmlFor="">{t("Drop Location")}</label>
                  <p>Chala, Thiruvananthapuram - 695001, Kerala, India.</p>
                  <p>{t("End Date & Time")} 25-01-2023</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <div className="main-master-wrapper form_input_main">
              <div className="transportMap trip-map">
                <div className="innerMap">
                  <p>{t("Map Overview")}</p>
                  <MapComponent />
                </div>
                <div className="belowContent">
                  <div className="notific">
                    <img src={Gcustomer} alt="" />
                    <label>{t("Company")}</label>
                  </div>
                  <div className="notific">
                    <img src={untracked} alt="" />
                    <label>{t("Start Point")}</label>
                  </div>
                  <div className="notific">
                    <img src={idle} alt="" />
                    <label>{t("End Point")}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="main-master-wrapper form_input_main">
              <div className="vehicle-speed-chart">
                <div className="vsc-heading">
                  <p>{t("Vehicle Speed")}</p>
                </div>
                <div className="vsc-chart-area">
                  <Chart
                    options={state.options}
                    series={state.series}
                    type="area"
                    width="100%"
                    height="420"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main-master-wrapper form_input_main">
          <Tab.Container
            id="left-tabs-example"
            className="td-tab-wrapper"
            defaultActiveKey="0"
          >
            <Row>
              <Col sm={12}>
                <Nav variant="pills" className="td-nav">
                  <Nav.Item className="td-tab">
                    <Nav.Link className="td-link" eventKey="0">
                      {t("Vehicle")}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="td-tab">
                    <Nav.Link className="td-link" eventKey="1">
                      {t("Driver")}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="td-tab">
                    <Nav.Link className="td-link" eventKey="2">
                      {t("Analytics")}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="td-tab">
                    <Nav.Link className="td-link" eventKey="3">
                      {t("Errors")}
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={12} className="">
                <Tab.Content>
                  <Tab.Pane eventKey="0">
                    <div className="row g-0 mb-3">
                      <div className="col-md-3 mt-3 d-flex justify-content-center align-items-center">
                        <img src={car} alt="" />
                      </div>
                      <div className="col-md-9 mt-3">
                        <div className="vd-wrapper">
                          <div className="vd-inner">
                            <div className="row">
                              <div className="col-md-4 form_input_main">
                                <label htmlFor="">{t("Vehicle Name")}</label>
                                <p>Volvo Transport</p>
                              </div>
                              <div className="col-md-4 form_input_main">
                                <label htmlFor="">{t("Vehicle Number")}</label>
                                <p>MH-12-5022</p>
                              </div>
                              <div className="col-md-4 form_input_main">
                                <label htmlFor="">
                                  {t("Registration Number")}
                                </label>
                                <p>789456123104567</p>
                              </div>
                              <div className="col-md-4 form_input_main">
                                <label htmlFor="">{t("IMEI No.")}</label>
                                <p>1234567894561230</p>
                              </div>
                              <div className="col-md-4 form_input_main">
                                <label htmlFor="">{t("Vehicle Type")}.</label>
                                <p>Pick-Up Truck</p>
                              </div>
                              <div className="col-md-4 form_input_main">
                                <label htmlFor="">
                                  {t("Transportation Type")}
                                </label>
                                <p>Goods</p>
                              </div>
                              <div className="col-md-4 form_input_main">
                                <label htmlFor="">
                                  {t("Vehicle Capacity / Load")}
                                </label>
                                <p>1 Ton</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="1">
                    <div className="row g-0 mb-3">
                      <div className="col-md-3 d-flex justify-content-center">
                        <div className="driver-profile">
                          <img src={driver_img} alt="" />
                        </div>
                      </div>
                      <div className="col-md-9 mt-3">
                        <div className="vd-wrapper">
                          <div className="vd-inner">
                            <div className="row">
                              <div className="col-md-4">
                                <label htmlFor="">{t("Driver Name")}</label>
                                <p>Mark S. Woods</p>
                              </div>
                              <div className="col-md-4">
                                <label htmlFor="">{t("Contact No.")}</label>
                                <p>99999 99999</p>
                              </div>
                              <div className="col-md-4">
                                <label htmlFor="">{t("Email")}</label>
                                <p>markwoods@gmail.com</p>
                              </div>
                              <div className="col-md-4">
                                <label htmlFor="">{t("Vehicle Number")}.</label>
                                <p>MH-12-2023</p>
                              </div>
                              <div className="col-md-4">
                                <label htmlFor="">{t("Vehicle Type")}.</label>
                                <p>Toyota Innova</p>
                              </div>
                              <div className="col-md-4">
                                <label htmlFor="">{t("Address")}</label>
                                <p>Vishrantwadi, Kalas Road, Pune- 411030.</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="2">
                    <div className="progress-wrapper">
                      <div style={{ width: "170px" }}>
                        <CircularProgressbarWithChildren value={66}>
                          <div style={{ fontSize: 16 }}>
                            <strong className="progress-text">66</strong>
                          </div>
                        </CircularProgressbarWithChildren>
                        <p>{t("Driver Score")}</p>
                      </div>
                      <div style={{ width: "170px" }}>
                        <CircularProgressbarWithChildren value={66}>
                          <div style={{ fontSize: 16 }}>
                            <strong className="progress-text">66</strong>
                          </div>
                        </CircularProgressbarWithChildren>
                        <p>{t("Accidents Saved")}</p>
                      </div>
                      <div style={{ width: "170px" }}>
                        <CircularProgressbarWithChildren value={66}>
                          <div style={{ fontSize: 16 }}>
                            <strong className="progress-text">66</strong>
                          </div>
                        </CircularProgressbarWithChildren>
                        <p>{t("Braking Frequency")}</p>
                      </div>
                      <div style={{ width: "170px" }}>
                        <CircularProgressbarWithChildren value={66}>
                          <div style={{ fontSize: 16 }}>
                            <strong className="progress-text">66</strong>
                          </div>
                        </CircularProgressbarWithChildren>
                        <p>{t("Incentives")}</p>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="3">
                    <div className="error-list-wrapper">
                      <div className="error-list">
                        <img src={error_icon} alt="" />
                        <div className="error-text">
                          <label htmlFor="">
                            {t("Vehicle error description goes here")}...{" "}
                          </label>
                          <p>31-01-2023, 04:05:58 PM</p>
                        </div>
                      </div>
                      <div className="error-list">
                        <img src={error_icon} alt="" />
                        <div className="error-text">
                          <label htmlFor="">
                            {t("Vehicle error description goes here")}...{" "}
                          </label>
                          <p>31-01-2023, 04:05:58 PM</p>
                        </div>
                      </div>
                      <div className="error-list">
                        <img src={error_icon} alt="" />
                        <div className="error-text">
                          <label htmlFor="">
                            {t("Vehicle error description goes here")}...{" "}
                          </label>
                          <p>31-01-2023, 04:05:58 PM</p>
                        </div>
                      </div>
                      <div className="error-list">
                        <img src={error_icon} alt="" />
                        <div className="error-text">
                          <label htmlFor="">
                            {t("Vehicle error description goes here")}...
                          </label>
                          <p>31-01-2023, 04:05:58 PM</p>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
        <div className="main-master-wrapper">
          <Tab.Container
            id="left-tabs-example"
            className="td-tab-wrapper"
            defaultActiveKey="0"
          >
            <div className="trip-details-wrapper">
              <p>{t("Alerts")}</p>
            </div>
            <Row>
              <Col sm={12}>
                <Nav variant="pills" className="td-nav">
                  <Nav.Item className="td-tab">
                    <Nav.Link className="td-link" eventKey="0">
                      {t("Alert Name")}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="td-tab">
                    <Nav.Link className="td-link" eventKey="1">
                      {t("Alert Name")}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="td-tab">
                    <Nav.Link className="td-link" eventKey="2">
                      {t("Alert Name")}
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={12} className="">
                <Tab.Content>
                  <Tab.Pane eventKey="0">
                    <div className="alert-table-wrapper">
                      <table className="alert-table">
                        <thead className="ht-head">
                          <tr>
                            <td>{t("Sr.No.")}</td>
                            <td>{t("Event")}</td>
                            <td>{t("Value")}</td>
                          </tr>
                        </thead>
                        <tbody className="ht-body">
                          <tr className="table-row-custom">
                            <td>1</td>
                            <td>Alert - 1</td>
                            <td>1</td>
                          </tr>
                          <tr className="table-row-custom">
                            <td>2</td>
                            <td>Alert - 2</td>
                            <td>10</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="1">
                    <div className="alert-table-wrapper">
                      <table className="alert-table">
                        <thead className="ht-head">
                          <tr>
                            <td>{t("Sr.No.")}</td>
                            <td>{t("Event")}</td>
                            <td>{t("Value")}</td>
                          </tr>
                        </thead>
                        <tbody className="ht-body">
                          <tr className="table-row-custom">
                            <td>1</td>
                            <td>Alert - 1</td>
                            <td>1</td>
                          </tr>
                          <tr className="table-row-custom">
                            <td>2</td>
                            <td>Alert - 2</td>
                            <td>10</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="2">
                    <div className="alert-table-wrapper">
                      <table className="alert-table">
                        <thead className="ht-head">
                          <tr>
                            <td>{t("Sr.No.")}</td>
                            <td>{t("Event")}</td>
                            <td>{t("Value")}</td>
                          </tr>
                        </thead>
                        <tbody className="ht-body">
                          <tr className="table-row-custom">
                            <td>1</td>
                            <td>Alert - 1</td>
                            <td>1</td>
                          </tr>
                          <tr className="table-row-custom">
                            <td>2</td>
                            <td>Alert - 2</td>
                            <td>10</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </div>
    </motion.div>
  );
};
export default ViewDispatchTripnew;
