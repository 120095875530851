import { React, useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import DDlogo from "../../assets/images/vector199.svg";
import Form from "react-bootstrap/Form";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

const NotificationSetting = () => {
  const { sidebar, setSidebar, Dark, setDark } = useContext(AppContext);
  const [validated, setValidated] = useState(false);
  const { t, i18n } = useTranslation();

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  return (
    <>
      <motion.div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
        variants={aninations}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.1 }}
      >
        <div id="cx-wrapper" className="Notification_Setting">
          {/* Table*/}
          <div className="main-master-wrapper form_input_main">
            <div className="tableBorders" id="customizeTable">
              <table className="table">
                <thead>
                  <tr>
                    <th>{t("Sr.No.")}</th>
                    <th>{t("Type")}</th>
                    <th>{t("Push Notification")}</th>
                    <th className="insideTd">{t("Email")}</th>
                    <th>{t("SMS")}</th>
                  </tr>
                </thead>
                <tbody className="tableBody">
                  <tr>
                    <td>1</td>
                    <td>1Notifications related announcements</td>
                    <td>
                      {" "}
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                      />
                    </td>
                    <td>
                      {" "}
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                      />
                    </td>
                    <td>
                      {" "}
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>Notifications related holidays & vacations</td>
                    <td>
                      {" "}
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                      />
                    </td>
                    <td>
                      {" "}
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                      />
                    </td>
                    <td>
                      {" "}
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>Notifications related pickup points</td>
                    <td>
                      {" "}
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                      />
                    </td>
                    <td>
                      {" "}
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                      />
                    </td>
                    <td>
                      {" "}
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>Notify while vehicle breakes speed limit</td>
                    <td>
                      {" "}
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                      />
                    </td>
                    <td>
                      {" "}
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                      />
                    </td>
                    <td>
                      {" "}
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>Notify while vehicle breakes geofence boundary</td>
                    <td>
                      {" "}
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                      />
                    </td>
                    <td>
                      {" "}
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                      />
                    </td>
                    <td>
                      {" "}
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>Notify while vehicle tracker external power on</td>
                    <td>
                      {" "}
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                      />
                    </td>
                    <td>
                      {" "}
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                      />
                    </td>
                    <td>
                      {" "}
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          {/* Vehicle related Setting */}
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <div className="main-master-wrapper form_input_main">
              <div className="Heading">
                <p>{t("Vehicle Related Notification Setting")}</p>
              </div>
              <div className="innerInputsGen">
                <div className="row">
                  <div className="col-md-6 form_input_main">
                    <div className="innerSelectBox weekCounter">
                      <Form.Label className="common-labels">
                        {t("Vehicle Maintenance Notification")} (Prior Milage (km) )
                      </Form.Label>
                      <Form.Select
                        required
                        as="select"
                        type="select"
                        name="Speed_limit"
                      >
                        <option value="">Open this select menu</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="150">150</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Please Select Notification (Prior Milage (km) )
                      </Form.Control.Feedback>
                    </div>
                  </div>
                  <div className="col-md-6 form_input_main">
                    <div className="innerSelectBox weekCounter">
                      <Form.Label className="common-labels">
                        {t("Vehicle Maintenance Notification")} ( Prior Days )
                      </Form.Label>
                      <Form.Select
                        required
                        as="select"
                        type="select"
                        name="Speed_limit"
                      >
                        <option value="">Open this select menu</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="150">150</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Please Select Notification ( Prior Days )
                      </Form.Control.Feedback>
                    </div>
                  </div>
                  <div className="col-md-6 form_input_main">
                    <div className="innerSelectBox weekCounter">
                      <Form.Label className="common-labels">
                        {t("Notification of Vehicle Insurance Expiry")} ( Prior Day )
                      </Form.Label>
                      <Form.Select
                        required
                        as="select"
                        type="select"
                        name="Speed_limit"
                      >
                        <option value="">Open this select menu</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="150">150</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Please Select Vehicle Insurance Expiry ( Prior Day )
                      </Form.Control.Feedback>
                    </div>
                  </div>
                  <div className="col-md-6 form_input_main">
                    <div className="innerSelectBox weekCounter">
                      <Form.Label className="common-labels">
                        {t("Notification of Vehicle Permit Expiry")} ( Prior Day )
                      </Form.Label>
                      <Form.Select
                        required
                        as="select"
                        type="select"
                        name="Speed_limit"
                      >
                        <option value="">Open this select menu</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="150">150</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {t("Please Select Vehicle Permit Expiry")} ( Prior Day )
                      </Form.Control.Feedback>
                    </div>
                  </div>
                  <div className="col-md-6 form_input_main">
                    <div className="innerSelectBox weekCounter">
                      <Form.Label className="common-labels">
                        {t("Vehicle Tax Expiry Notification")} ( Prior Day )
                      </Form.Label>
                      <Form.Select
                        required
                        as="select"
                        type="select"
                        name="Speed_limit"
                      >
                        <option value="">Open this select menu</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="150">150</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Please Select Notification ( Prior Day )
                      </Form.Control.Feedback>
                    </div>
                  </div>
                  <div className="col-md-6 form_input_main">
                    <div className="innerSelectBox weekCounter">
                      <Form.Label className="common-labels">
                        {t("Notification of Vehicle Next Inspection Date")} ( Prior
                        Days )
                      </Form.Label>
                      <Form.Select
                        required
                        as="select"
                        type="select"
                        name="Speed_limit"
                      >
                        <option value="">Open this select menu</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="150">150</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {t("Please Select Vehicle Next Inspection Date")} ( Prior Days
                        )
                      </Form.Control.Feedback>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Two Bottom Buttons */}
            <div className="d-flex justify-content-end align-items-center btn-wrapper">
              <button className="cx-btn-1">{t("Cancel")}</button>
              <button className="cx-btn-2">{t("Submit")}</button>
            </div>
          </Form>
        </div>
      </motion.div>
    </>
  );
};

export default NotificationSetting;
