import React, { useEffect } from "react";

const RedirectComponent = () => {
  useEffect(() => {
    const isPastedURL = document.referrer === "";
    if (isPastedURL) {
      window.location.href = "https://www.vehicletracking.qa/";
    }
  }, []);

  return null; // Since this component only handles the redirect, it doesn't render anything
};

export default RedirectComponent;
